function configsController (
  $q, $http, $log, $scope, ConfigURLs, SortFunctions, Toast
) {
  var $ctrl = this;
  $ctrl.configsAltered = false;
  $ctrl.searchFilter = '';
  $ctrl.dirtyEditor = false;
  $ctrl.editorError = '';
  $ctrl.showEditor = true;
  $ctrl.specificityOrder = ['default', 'system', 'account', 'device'];
  $ctrl.diffLevels = $ctrl.specificityOrder.slice(
    1, $ctrl.specificityOrder.length);
  $ctrl.editableLevels = $ctrl.specificityOrder.slice(1, -1);
  $ctrl.configs = false;

  $ctrl._accountsArray = [];


  $ctrl.deferredServerPuts = [];

  $ctrl.capitalise = _.capitalize;

  $ctrl.setModified = () => {
    $ctrl.configsAltered = true;
  };


  $ctrl.requestConfigs = () => {
    $ctrl.requestingConfigs = true;
    $http.get(ConfigURLs.configs)
      .then((response) => {
        $ctrl.configs = response.data;
        // Transform object to array then sort by name for the accounts dropdown
        $ctrl._accountsArray = [];
        _.each($ctrl.configs.accounts, (account, id) => {
          account._id = id;
          $ctrl._accountsArray.push(account);
        });
        $ctrl._accountsArray.sort(
          (a, b) => SortFunctions.stringAsc(a.name, b.name)
        );
      })
      .catch((err) => { $log.warn('Error fetching configs', err); })
      .then(()=> { $ctrl.requestingConfigs = false; });
  };
  // request configs to init.
  $ctrl.requestConfigs();
  function createPUTRequest ({ level, configPath, targetId }) {
    return $http.put(
      ConfigURLs.configs,
      {
        level, targetId,
        config: _.get($ctrl.configs, configPath),
      }
    )
    .then(res => {
      const resConfig = res.data.config;
      _.set($ctrl.configs, configPath, resConfig);
    })
    .catch((err)=> {
      $log.error(err);
    });
  }

  $ctrl.saveConfigs = () => {
    // clone the array first.
    const puts = $ctrl.deferredServerPuts.splice(0);
    return $q.all(puts.map(createPUTRequest))
      .then(()=> {
        Toast.makeSuccess('Configs saved successfully');
        $ctrl.deferredServerPuts = [];
      })
      .catch(Toast.makeError)
      .finally(()=> { $ctrl.configsAltered = false; });
  };

  $ctrl.deferPut = (configPath, level, targetId) => {
    const put = { configPath, level, targetId };
    if (
      !$ctrl.deferredServerPuts.map(JSON.stringify)
      .includes(JSON.stringify(put))
    ) {
      $ctrl.deferredServerPuts.push(put);
    }
  };

  function isObject (thing) {
    // function to check if thing is a basic object and not an array.
    return thing !== null && typeof thing === 'object' && !Array.isArray(thing);
  }

  $ctrl.getPaths = function (config) {
    // returns array containing all the paths to values contained in an object
    const paths = [];
    function _findPaths (obj, cumPath = '') {
      Object.keys(obj)
      .filter(key => { return key !== '$$hashKey'; })
      .forEach((key) => {
        const newPath = cumPath.length ? `${cumPath}.${key}` : `${key}`;
        const value = obj[key];
        if (!isObject(value)) {
          paths.push(newPath);
        } else {
          _findPaths(value, newPath);
        }
      });
    }
    _findPaths(config);
    paths.sort(); // sorted for readability in config overrides table
    return paths;
  };

  $ctrl.countLevelOverrides = function (path, level) {
    // returns total count of overrides for a path at a level,
    // device IDs, account IDs
    const out = { total: 0 };
    switch (level) {
      case 'system':
        if (_.has($ctrl.configs[level].config, path)) out.total = 1;
        break;
      case 'account':
        out.accounts = [];
        Object.keys($ctrl.configs.accounts)
        .filter(accountId => { return accountId !== '$$hashKey'; })
        .forEach(accountId => {
          const account = $ctrl.configs.accounts[accountId];
          if (_.has(account.config, path)) {
            out.accounts.push(accountId);
            out.total += 1;
          }
        });
        break;
      case 'device':
        out.accounts = {};
        Object.keys($ctrl.configs.accounts).forEach(accountId => {
          const account = $ctrl.configs.accounts[accountId];
          out.accounts[accountId] = {};
          if (accountId === '$$hashKey') return 0;
          out.accounts[accountId].deviceIds = [];
          let devCount = 0;
          devCount += Object.keys(account.devices).map(deviceId => {
            const device = account.devices[deviceId].config;
            out.accounts[accountId].deviceIds.push(deviceId);
            return _.has(device, path) ? 1 : 0;
          }).reduce((acc, val) => acc + val, 0);

          out.accounts[accountId].count = devCount;
          out.total += devCount;
        });
        break;
      default:
        break;
    }
    return out;
  };

  function getFleshedOutConfig () {
    // used for creating the config overrides table
    // returns a config containing all paths found in all config levels
    const devConfigs = [].concat(...Object.keys($ctrl.configs.accounts)
    .map(accountId => {
      if (accountId === '$$hashKey') return [];
      const account = $ctrl.configs.accounts[accountId];
      return Object.keys(account.devices).map(deviceId => {
        return account.devices[deviceId].config;
      });
    }));

    const accountConfigs = Object.keys($ctrl.configs.accounts)
    .filter(accountId => { return accountId !== '$$hashKey'; })
    .map(accountId => {
      return $ctrl.configs.accounts[accountId].config;
    });

    return _.merge(
      {}, $ctrl.configs.system.config, ...accountConfigs, ...devConfigs);
  }

  $ctrl.doesPathPassFilter = function (path) {
    // used for search filter
    // returns whether the search filter string is contained in a given path
    if ($ctrl.searchFilter.length) {
      if (path.toLowerCase().includes($ctrl.searchFilter.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  $ctrl.createOverridesObject = function () {
    if (!$ctrl.configs || !Object.keys($ctrl.configs).length) return;
    // create object bound to config overrides table
    const fleshedOutConfig = getFleshedOutConfig();
    $ctrl.overridesObj = {};
    $ctrl.getPaths(fleshedOutConfig)
    .filter(path => { return path !== '$$hashKey'; })
    .forEach(path => {
      if ($ctrl.doesPathPassFilter(path)) {
        $ctrl.overridesObj[path] = {};
        $ctrl.diffLevels.forEach(level => {
          $ctrl.overridesObj[path][level]
            = $ctrl.countLevelOverrides(path, level);
        });
      }
    });
  };

  $ctrl.getSelectorDiffCounter = function (level, account, device) {
    // returns selector suffix for config levels
    var na = '';
    if (device) {
      if (device === 'default') {
        level = 'account';
      } else {
        level = 'device';
      }
    } else if (level === 'account') {
      if (!account) return na;
      device = 'default';
    }
    var config = $ctrl.applyLevel({}, level, account, device);
    return ` (+${$ctrl.getPaths(config).length})`;
  };

  $ctrl.isSelectedOverride = (path, level) => {
    return path === $ctrl.diffViewerPath && level === $ctrl.diffViewerLevel;
  };

  $ctrl.setDiffViewer = (path, level) => {
    $ctrl.diffViewerPath = path;
    $ctrl.diffViewerLevel = level;
  };

  $ctrl.getDiffViewValue = (accountId, deviceId) => {
    if ($ctrl.diffViewerLevel === 'system') {
      return _.get(
        $ctrl.configs.system.config,
        $ctrl.diffViewerPath
      );
    } else if ($ctrl.diffViewerLevel === 'account') {
      return _.get(
        $ctrl.configs.accounts[accountId].config,
        $ctrl.diffViewerPath);
    } else {
      return _.get(
        $ctrl.configs.accounts[accountId].devices[deviceId].config,
        $ctrl.diffViewerPath);
    }
  };

  function unsetPath (obj, path) {
    if (isObject(obj) && path && path.length && typeof path === 'string') {
      let parent;
      let toDelete;
      if (path.includes('.')) {
        const pathBits = path.split('.');
        toDelete = pathBits.pop();
        const parentPath = pathBits.join('.');
        parent = _.get(obj, parentPath);
      } else {
        parent = obj;
        toDelete = path;
      }
      delete parent[toDelete];
    }
  }

  $ctrl.deleteConfigOverride = (path, level, accountId, deviceId) => {
    if (level === 'account') {
      unsetPath($ctrl.configs.accounts[accountId].config, path);
      $ctrl.deferPut(`accounts.${accountId}.config`,
        'account', accountId);
    } else if (level === 'device') {
      unsetPath(
        $ctrl.configs.accounts[accountId].devices[deviceId].config, path);
      $ctrl.deferPut(`accounts.${accountId}.devices.${
        deviceId}.config`, 'device', deviceId);
    } else {
      unsetPath($ctrl.configs.system.config, path);
      $ctrl.deferPut('system.config', 'system', undefined);
    }
    $ctrl.createOverridesObject();
    $ctrl.setCurrent(level, accountId, deviceId);
  };

  $ctrl.configHasPath = (path, level, accountId, deviceId) => {
    // check whether config has path for use in diff viewer
    if (level === 'account') {
      return _.has($ctrl.configs.accounts[accountId].config, path);
    } else if (level === 'device') {
      return _.has(
        $ctrl.configs.accounts[accountId].devices[deviceId].config, path);
    } else {
      return _.has($ctrl.configs.system.config, path);
    }
  };

  $ctrl.applyLevel = function (obj, level, account, deviceId) {
    // apply a config level to a config
    // used in deriveFullConfig
    let out;
    if (Object.keys($ctrl.configs).length) {
      switch (level) {
        case 'default':
          out = _.merge(obj, $ctrl.configs.default);
          break;

        case 'system':
          out = _.merge(obj, $ctrl.configs.system.config);
          break;

        case 'account':
          out = _.merge(obj, $ctrl.configs.accounts[account].config);
          break;

        case 'device':
          out = _.merge(
            obj, $ctrl.configs.accounts[account].devices[deviceId].config);
          break;

        default:
          break;
      }
    } else {
      out = {};
    }
    return out;
  };

  $ctrl.deriveFullConfig = function (level, account, device) {
    // for deriving resultant configs
    if (!level) {
      level = $ctrl.currentLevel;
      account = $ctrl.currentAccount;
      device = $ctrl.currentDevice || 'default';
    }
    if (level === 'account' && device !== 'default') {
      level = 'device';
    }
    var appliedLevel = '';
    var config = {};
    while ($ctrl.specificityOrder.indexOf(level)
        > $ctrl.specificityOrder.indexOf(appliedLevel)) {
      appliedLevel = $ctrl.specificityOrder[$ctrl.specificityOrder
        .indexOf(appliedLevel) + 1];
      config = $ctrl.applyLevel(config, appliedLevel, account, device);
    }
    return config;
  };

  $ctrl.setDeviceList = function () {
    // set a list of devices for an account
    // used in device selector
    if (!$ctrl.currentAccount) return;
    $ctrl.deviceList = Object.keys(
      $ctrl.configs.accounts[$ctrl.currentAccount].devices);
    if ($ctrl.configs.accounts[$ctrl.currentAccount]) {
      $ctrl.deviceList.unshift('default');
    }
  };

  $ctrl.writeCurrent = function () {
    // copy config being edited back into the main configs object
    $ctrl.dirtyEditor = false;
    if ($ctrl.currentDevice) {
      if ($ctrl.currentDevice === 'default') {
        $ctrl.configs.accounts[$ctrl.currentAccount].config
          = $ctrl.currentObj;
        $ctrl.deferPut(`accounts.${$ctrl.currentAccount}.config`,
          'account', $ctrl.currentAccount);
      } else {
        $ctrl.configs.accounts[$ctrl.currentAccount]
          .devices[$ctrl.currentDevice].config = $ctrl.currentObj;
        $ctrl.deferPut(`accounts.${$ctrl.currentAccount}.devices.${
          $ctrl.currentDevice}.config`, 'device', $ctrl.currentDevice);
      }
    } else {
      $ctrl.deferPut('system.config', 'system', undefined);
      $ctrl.configs.system.config = $ctrl.currentObj;
    }
  };

  $ctrl.setCurrent = function (level, accountId, deviceId) {
    // There isn't a 'device' level.
    level = level === 'device' ? 'account' : level;
    $ctrl.editorError = '';
    $ctrl.dirtyEditor = false;
    $scope.selectedLevel = level;
    $scope.selectedAccount = accountId;
    $ctrl.currentAccount = accountId;
    $ctrl.currentLevel = level;
    $ctrl.setDeviceList();
    $scope.selectedDevice = deviceId;
    $ctrl.currentDevice = deviceId;
    if (level === 'account') {
      if (deviceId) {
        if (deviceId === 'default') {
          $ctrl.currentObj = _.cloneDeep(
            $ctrl.configs.accounts[accountId].config);
        } else {
          $ctrl.currentObj = _.cloneDeep(
            $ctrl.configs.accounts[accountId].devices[deviceId].config);
        }
      } else {
        $ctrl.currentObj = undefined;
      }
    } else if (level === 'system') {
      $ctrl.currentObj = _.cloneDeep($ctrl.configs.system.config);
    }
  };

  $ctrl.getDeviceObject = (level, accountId, deviceId) => {
    let out;
    if (level === 'account' && deviceId === 'default') {
      out = $ctrl.configs.accounts[accountId];
    } else if (level === 'account') {
      out = $ctrl.configs.accounts[accountId].devices[deviceId];
    } else {
      out = { name: 'Error', config: {} };
    }
    return out;
  };

  $ctrl.getDeviceName = (level, accountId, deviceId) => {
    if (deviceId === 'default') return '- All screens -';
    else return $ctrl.getDeviceObject(level, accountId, deviceId).name;
  };

  $ctrl.deviceNameOrderFn = (deviceId) => {
    return $ctrl.getDeviceName('account', $ctrl.currentAccount, deviceId);
  }

  $ctrl.getToolTip = (path, level) => {
    let toolTipArray = [];
    let accounts;
    switch (level) {
      case 'system':
        break;
      case 'account':
        accounts = $ctrl.overridesObj[path][level].accounts.map(accountId => {
          return $ctrl.configs.accounts[accountId].name;
        });
        toolTipArray = accounts;
        break;
      case 'device':
        accounts = $ctrl.overridesObj[path][level].accounts;
        Object.keys(accounts).forEach(accountId => {
          const count = accounts[accountId].count;
          if (count) {
            toolTipArray.push(
              `${$ctrl.configs.accounts[accountId].name} (${count})`);
          }
        });
        break;
      default:
        break;
    }
    return toolTipArray.join(' , ');
  };
}

angular
  .module('sender')
  .controller('configsController', configsController);
