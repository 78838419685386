angular.module('flare.charts', ['angular-chartist'])

.component('pxnGaugeChart', {

  bindings: {
    filled: '<',
    total: '<',
  },

  template: ['$attrs', function chartistTemplate ($attrs) {

    var options = {
      donut: true,
      donutWidth: 14,
      startAngle: 225,
      total: 360,
      showLabel: false
    };

    if ($attrs.chartistOptions) {
      options = _.defaults(
        JSON.parse($attrs.chartistOptions),
        options
      );
    }

    return [
      '<chartist class="ct-chart" chartist-chart-type="Pie" ',
      'chartist-chart-options=\'',
      JSON.stringify(options),
      '\' chartist-data="$ctrl.data"',
      '>',
      '</chartist>',
      '<h1 class="screens-online-text">{{ $ctrl.filled }}/{{ $ctrl.total }}</h1>',
      '<h5 class="screens-online-text">', $attrs.title, '</h5>'
    ].join('');
  }],

  controller: function chartistController ($scope) {
    var ctrl = this;
    ctrl.data = {
      series: [135, 135]
    };

    function updateSeriesData () {
      if (!ctrl.total) {
        ctrl.data.series = [0, 270];
      } else {
        ctrl.data.series = [
          (ctrl.filled / ctrl.total) * 270,
          (270 - (ctrl.filled / ctrl.total * 270))
        ];
      }
    }

    $scope.$watchGroup(['$ctrl.filled', '$ctrl.total'], updateSeriesData);

    ctrl.$onInit = updateSeriesData();

  }

});
