angular.module('flare.receiver.messaging', [])

.component('onScreenMessage', {
  template: `<h2 class="onScreenMessage" ng-if="$ctrl.show">
    {{::$ctrl.message}}
  </h2>`,
  controller: class IdentifyController {
    constructor (Player, $timeout, $scope) {
      this.show = false;
      Player.ipc.on('displayMessage', (sender, { msg, t })=> {
        $scope.$apply(()=> {
          this.message = msg;
          this.show = true;
          $timeout(()=> { this.show = false; }, t * 1000);
        });
      });
    }
  }
});