angular.module('flare.groups', [])

.factory('Groups', function (ConfigURLs, ResourceItem, GenericResource, Session, $q) {

  function Group () {
    ResourceItem.apply(this, arguments);
  }

  Group.prototype = Object.create(ResourceItem.prototype);
  Group.prototype._basePermission = 'groups';
  Group.prototype.resourceType = 'group';
  Group.prototype._defaults = {
    name: '',
    description: '',
    tags: [],
    members: [],

    contributors: [],
  };
  Group.prototype._maintainRefs = [
    'members',
    'contributors',
  ];
  var Groups = new GenericResource(ConfigURLs.groups, Group);

  Groups.ownGroupIds = function () {
    // Check if we have data in a cache
    if (!Groups.myGroups) {
      // Defer a promise
      var deferred = $q.defer();
      // Get all groups
      var allGroups;
      if (Session.hasPermission('groups.read')) {
        allGroups = Groups.all();
      } else {
        allGroups = [];
        allGroups.promise = $q.resolvedPromise();
      }
      // Once we have groups, filter by oens that are owned by my account
      var myGroups = [];
      allGroups.promise.then(function () {
        _.each(allGroups, function (group) {
          if (group.account === Session.current.account._id) {
            myGroups.push(group._id);
          }
        });
        // Resolve the promise with the array of IDs
        deferred.resolve(myGroups);
        // Create a cache so we don't keep making the same request
        Groups.myGroups = $q.resolve(myGroups);
      });

      // Return the promise.
      return deferred.promise;
    } else {
      // Return cached data
      return Groups.myGroups;
    }
  };
  return Groups;
})

.controller('GroupManagerController', function (
  Groups,
  Accounts,
  Modal,
  Toast,
  SaveCheck,
  $scope,
  Session,
  Sidebar,
  Onboarding,
  $timeout,
  SortFunctions,
  $q
) {
  var vm = this;

  // Open secondary sidebar by default
  Sidebar.set({
    sidebarExpanded: false,
    secondarySidebarExpanded: true
  }, false);

  if (Session.hasPermission('groups.read')) {
    vm.groups = Groups.all();
  } else {
    vm.groups = [];
    vm.groups.promise = $q.resolvedPromise();
  }

  vm.sortGroups = () => {
    vm.groups.sort((a, b) => SortFunctions.stringAsc(a.name, b.name));
  };

  vm.groupPermission = () => {
    return vm.group.isLocal() ? 'groups.create' : 'groups.edit';
  };
  vm.lists = {};
  vm.groups.promise.then(function () {
    // Do things after the groups promise has resolved.
    SaveCheck.register({
      hasChanges: function () {
        if (vm.group === null) return false;
        return vm.group.isLocal() ||
          vm.group.isModified() ||
          (vm.groupManagerForm && vm.groupManagerForm.$dirty);
      },
      discardChanges: function () {
        vm.group.reset();
      },
    });
    vm.sortGroups();
  });

  // This is a list of accounts that we're allowed to add to sharing groups.
  if (Session.hasPermission('account.auth')) {
    Accounts.getFlattenedChildren(Session.current.account._id)
    .then(function (children) {
      vm.accounts = children;
      // Add the creating account as an option
      vm.accounts.unshift(Session.current.account);
    });
  } else {
    vm.accounts = [];
    // Add the creating account as an option
    vm.accounts.unshift(Session.current.account);
    // Sort the accounts
    vm.accounts.sort((a, b) => SortFunctions.stringAsc(a.name, b.name));
  }

  // Currently selected group init
  vm.group = null;

  vm.createGroup = function () {
    // Ensure we deselect any existing group so permission can be reworked.
    vm.group = null;
    $timeout(() => {
      SaveCheck.checkNow(false)
        .then(function () {
          // Deletes any previously created local group from array so we only have 1
          if (vm.group && vm.group.isLocal()) {
            vm.group.reset();
          }
          vm.group = Groups.create();
          vm.lists = {
            members: vm.group.members,
            contributors: vm.group.contributors,
          };
          vm.lists.contributors.push(Session.current.account._id);
        })
        .catch((err) => {
          Toast.makeError(err);
        });
    });
  };

  $scope.$watch('vm.group', function (newVal, oldVal) {
    if (newVal === oldVal || !newVal || !vm.groupManagerForm) return;
    vm.groupManagerForm.$setPristine();
    vm.groupManagerForm.$setUntouched();
  });

  /**
   * Select a new group in the ui. Note that groups that are shared with
   * us are *not* editable, so we don't need to do a dirty check if the
   * group we are moving from is shared. Similarly, there will be no
   * groupManagerForm on the page yet for us to clear.
   * @param  {group} group The group to selectGroup
   * @return {undefined}
   */
  vm.selectGroup = function (group) {
    vm.group = null;
    if (!group) {
      return;
    }

    $timeout(()=> {
      if (!vm.group || vm.group._sharedWithMe) {
        vm.group = group;
        setLists();
        $timeout(function () {
          Onboarding.triggerOverviewResourceAvailable();
        });
        return;
      }
      SaveCheck.checkNow(false)
        .then(function () {
          // Reset the group manager form (it may not exist if a group wasn't
          // previously selected).

          vm.group = group;
          setLists();
          if (vm.groupManagerForm) {
            vm.groupManagerForm.$setPristine();
          }
        })
        .catch((err) => {
          Toast.makeError(err);
        });
    });
  };

  function setLists () {
    vm.lists = {
      members: vm.group.members,
      contributors: vm.group.contributors,
    };
  }

  vm.saveGroup = function () {
    // Reassign the result from the multilist selector.
    vm.group.members = vm.lists.members;
    vm.group.contributors = vm.lists.contributors;
    // Save the group.
    Toast.saveAndNotify(vm.group).then(function () {
      // Set the form to pristine again, ready to detect further changes.
      vm.groupManagerForm.$setPristine();
    });
  };

  vm.resetGroup = function () {
    let modalOpts = {
      templateUrl: 'utility/modal-templates/modal-areyousure.jade',
      scopeData: {
        action: 'revert changes to',
        itemType: 'group',
        dismissable: { backButton: false, escape: true, backgroundClick: false }
      },
    };

    new Modal(modalOpts)
      .show()
      .then(function () {
        Toast.resetAndNotify(vm.group).then(function () {
          vm.groupManagerForm.$setPristine();
          vm.group = null;
          vm.sortGroups();
        });
      })
      .catch((err) => {
        Toast.makeError(err);
      });
  };

  vm.deleteGroup = function (thenMessage) {
    var additionalMessage = '**WARNING**: Content shared with this group will \
    become unavailable to its members.';
    if (!vm.group.isLocal()) {
      var modalOpts = {
        templateUrl: 'utility/modal-templates/modal-areyousure.jade',
        scopeData:   {
          action:     'delete',
          itemType:   'group',
          additionalMessage: additionalMessage,
        },
      };
      new Modal(modalOpts)
        .show()
        .then(function () {
          Toast.deleteAndNotify(vm.group).then(function () {
            vm.group = null;
            // Clear the cache after removing a group so we make fresh request
            // when next viewing resources
            // (fixes resources with references to deleted groups).
            $scope.$emit("pxnGenericResource::CLEAR_RESOURCE_CACHE");
            if (Session.hasPermission("groups.read")) {
              vm.groups = Groups.all();
            } else {
              vm.groups = [];
              vm.groups.promise = $q.resolvedPromise();
            }
          });
        })
        .catch((err) => {
          Toast.makeError(err);
        });
    } else {
      Toast.deleteAndNotify(vm.group, thenMessage).then(function () {
        vm.group = null;
      });
    }
  };

});
