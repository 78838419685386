angular
  .module("flare.screensavers", [])

  .factory("VirtualDevice", (ResourceItem, LocaleTree) => {
    class VirtualDevice extends ResourceItem {
      constructor() {
        super(...arguments);
        this._channelOrPlaylist = !!this.channel
          ? `channel:${this.channel}`
          : !!this.playlist
          ? `playlist:${this.playlist}`
          : null;
        this.setLocaleProps();
      }

      save(overwrite, fields) {
        if (this._channelOrPlaylist) {
          const [contentType, contentId] = this._channelOrPlaylist.split(":");
          if (contentType === "channel") {
            this.channel = contentId;
            this.playlist = null;
          } else if (contentType === "playlist") {
            this.playlist = contentId;
            this.channel = null;
          }
        }
        return super.save(overwrite, fields);
      }

      disable() {
        this.enabled = false;
        return this.save();
      }

      enable() {
        this.enabled = true;
        return this.save();
      }

      setLocaleProps() {
        if (!this.hasOwnProperty("locale")) return;
        LocaleTree.getLocalisationData().then(() => {
          const locale = LocaleTree.getLocaleById(this.locale);
          this._localeName = !!locale && locale.name;
          this._localePath = LocaleTree.getLocalePath(locale);
        });
      }
    }

    // Things we need on the client that won't be sent to the server.
    VirtualDevice.prototype._locals = [
      "_channelOrPlaylist",
      "_localePath",
      "_localeName",
    ];

    // Default properties when creating a new screensaver
    VirtualDevice.prototype._defaults = {};

    return VirtualDevice;
  })

  .factory("Screensavers", (GenericResource, VirtualDevice, ConfigURLs) => {
    // base permission for front end filtering when previewing role
    class Screensaver extends VirtualDevice {}
    Screensaver.prototype._basePermission = "screensavers";
    return new GenericResource(ConfigURLs.screensavers, Screensaver);
  })

  .factory("WebEmbeds", (GenericResource, VirtualDevice, ConfigURLs) => {
    // base permission for front end filtering when previewing role
    class WebEmbed extends VirtualDevice {}
    WebEmbed.prototype._basePermission = "webembeds";
    return new GenericResource(ConfigURLs.webembeds, WebEmbed);
  })

  .controller(
    "ScreensaversController",
    function (
      SortFunctions,
      Screensavers,
      WebEmbeds,
      Channels,
      Playlists,
      Tickers,
      Modal,
      Session,
      LocaleTree,
      Toast,
      $log,
      $q,
      $state
    ) {
      const ctrl = this;
      ctrl.isWebEmbedPage = $state.current.name === "web-embeds";
      if (
        !Session.current.account.virtualDevices[
          ctrl.isWebEmbedPage ? "allowWebEmbeds" : "allowScreensavers"
        ]
      ) {
        $state.go("dashboard");
      }
      ctrl.playlistsAndChannels = [];
      // Fetch resources that we have permissions for.
      ctrl.screensavers = Session.hasPermission(
        `${ctrl.isWebEmbedPage ? "webembeds" : "screensavers"}.read`
      )
        ? ctrl.isWebEmbedPage
          ? WebEmbeds.all()
          : Screensavers.all()
        : [];
      ctrl.channels = Session.hasPermission("content.channels.read")
        ? Channels.all()
        : [];
      ctrl.playlists = Session.hasPermission("content.playlists.read")
        ? Playlists.all()
        : [];
      ctrl.tickers = Session.hasPermission("content.tickers.read")
        ? Tickers.all()
        : [];

      $q.all([ctrl.channels.promise, ctrl.playlists.promise]).then(() => {
        _.each([ctrl.channels, ctrl.playlists], (o)=> {
          o.sort((a, b) => SortFunctions.stringAsc(a.name, b.name))
        });
        ctrl.playlistsAndChannels = ctrl.channels.concat(ctrl.playlists);
        ctrl.playlistAndChannelNames = ctrl.playlistsAndChannels.reduce(
          (lookup, next) => {
            lookup[next._id] = next.name;
            return lookup;
          },
          {}
        );
      });

      // Use $q.all because we may not have permissions at which point tickers
      // is just an empty array (with no promise property).
      $q.all([ctrl.tickers.promise]).then(() => {
        ctrl.tickerNames = ctrl.tickers.reduce((lookup, next) => {
          lookup[next._id] = next.name;
          return lookup;
        }, {});
      });

      const selectScreensaverLocale = (screensaverGroup, form) => {
        let selectedLocale = null;
        new Modal({
          templateUrl: "utility/modal-templates/locale-manager.jade",
          scopeData: {
            localeSelector: true,
            selected: null,
            device: screensaverGroup,
            tree: LocaleTree.getUnflattenedTree(),
            runFilter: function () {
              this.$parent.filterVal = this.filterVal;
            },
            selectLocale: function (locale) {
              selectedLocale = locale.selected ? locale : null;
              this.selected = selectedLocale;
            },
            toggleAll: function (val) {
              this.$broadcast(
                `angular-ui-tree:${val ? "collapse" : "expand"}-all`
              );
            },
            initFunction: function () {
              let id = screensaverGroup.locale;
              this.$on("locale-tree-linked", () => {
                this.$broadcast("angular-ui-tree:select-with-id", id);
              });
            },
          },
          dismissable: {
            backButton: false,
            escape: true,
            backgroundClick: true,
          },
        })
          .show()
          .then(function (locale) {
            screensaverGroup.locale = locale.id;
            screensaverGroup._localePath = LocaleTree.getLocalePath(locale);
            form.$setDirty();
          })
          .catch((err) => {
            Toast.makeError(err);
          });
      };

      ctrl.editScreensaverGroup = (screensaverGroup) => {
        screensaverGroup = !!screensaverGroup
          ? screensaverGroup
          : ctrl.isWebEmbedPage
          ? WebEmbeds.create()
          : Screensavers.create();
        // Auto-created screensavers will be owned by Flare - the
        // reference will have been deleted so we need to default
        // to the current user.
        if (!screensaverGroup.ownedBy) {
          screensaverGroup.ownedBy = Session.current.user;
        }
        const modal = new Modal({
          templateUrl: "screensavers/modal-create-edit-screensaver.jade",
          scopeData: {
            type: ctrl.isWebEmbedPage ? "webembed" : "screensaver",
            screensaver: screensaverGroup,
            playlistsAndChannels: ctrl.playlistsAndChannels,
            tickers: ctrl.tickers,
            localesEnabled: LocaleTree.showLocaleFields(),
            selectScreensaverLocale: selectScreensaverLocale,
            save: () => {
              screensaverGroup.save().then(() => {
                Toast.makeSuccess("Screensaver group created successfully");
                modal.resolve();
              });
              // NOTE: No catch because the toaster will display the error.
            },
          },
        });
        modal.show().catch(() => screensaverGroup.reset());
      };

      ctrl.deleteScreensaverGroup = (screensaverGroup) => {
        new Modal({
          templateUrl: "utility/modal-templates/modal-areyousure.jade",
          scopeData: {
            action: "delete",
            itemType: "screensaver group",
            location: screensaverGroup.name,
          },
          dismissable: {
            backButton: false,
            escape: true,
            backgroundClick: false,
          },
        })
          .show()
          .then(() => {
            Toast.deleteAndNotify(screensaverGroup);
          })
          .catch((err) => {
            Toast.makeError(err);
          });
      };
      ctrl.disableEnableScreensaverGroup = (screensaverGroup, type) => {
        const action = screensaverGroup.enabled ? "disable" : "enable";
        new Modal({
          templateUrl: "utility/modal-templates/modal-generic-message.jade",
          scopeData: {
            title: `${action} ${type} group`,
            message1: `Please confirm that you wish to ${action} ${type}s in the '${screensaverGroup.name}' group.`,
            message2: `${action.slice(
              0,
              -1
            )}ing this group will take effect immediately - ${type}s will ${
              action === "enable"
                ? "display the content from the selected slideshow"
                : 'stop displaying content from the slideshow and display the configured "no slides" image instead'
            }`,
          },
          dismissable: {
            backButton: false,
            escape: true,
            backgroundClick: false,
          },
        })
          .show()
          .then(async () => {
            try {
              await screensaverGroup[action]();
              Toast.makeSuccess(
                `${screensaverGroup.name} has been successfully ${action}d`
              );
            } catch (e) {
              $log.error(
                `Error ${action.slice(0, -1)}ing screensaver group: ${
                  e.message
                }`
              );
            }
          })
          .catch((err) => {
            Toast.makeError(err);
          });
      };

      ctrl.deleteWebEmbed = (webEmbedGroup) => {
        new Modal({
          templateUrl: "utility/modal-templates/modal-generic-message.jade",
          scopeData: {
            title: `Delete web embed group`,
            message1: `Please confirm that you wish to delete this web embed group.`,
            message2: `Any embedded slideshows using this group's identifier will stop working immediately`,
          },
          dismissable: {
            backButton: false,
            escape: true,
            backgroundClick: false,
          },
        })
          .show()
          .then(async () => {
            try {
              await webEmbedGroup.delete();
              Toast.makeSuccess(
                `${webEmbedGroup.name} has been successfully deleted`
              );
            } catch (e) {
              $log.error(`Error deleting web embed group: ${e.message}`);
            }
          })
          .catch((err) => {
            Toast.makeError(err);
          });
      };

      ctrl.copyEmbedLink = (webEmbed) => {
        const link =
          `${window.location.origin}/receiver?web-embed=1` +
          `&identifier=${webEmbed.identifier}&token=` +
          Session.current.account.virtualDevices.tokens[0];
        const data = [
          new ClipboardItem({
            "text/plain": new Blob([link], { type: "text/plain" }),
          }),
        ];
        navigator.clipboard.write(data).then(
          function () {
            Toast.makeSuccess(`Embed link copied to clipboard`);
          },
          function () {
            Toast.makeError(
              "Failed to copy embed link - please copy it manually:" + link
            );
          }
        );
      };
    }
  );
