angular.module('pxn-resources', [])

.component('pxnResourceSelector', {
  bindings: {
    tabs: '=',
    ngModel: '<',
    textFunction: '='
  },
  require: {
    ngModelCtrl: 'ngModel',
    formCtrl: '^?form'
  },
  templateUrl: 'utility/inputs/pxn-resource-select.jade',
  controller: function pxnResourceSelectorController (
    $q,
    $scope,
    $element,
    $templateCache,
    $compile,
    Measure,
    $timeout,
    $attrs
  ) {
    // Some things can only be done once we're ready
    this.$onInit = () => {
      // Make sure we update the friendly text when the model value changes.
      this.ngModelCtrl.$render = () => {
        this.buildState()
          .then(this.updateSelectionIndicator)
          .catch((err)=> {
            if (err !== 'Already building state') {
              $log.error(err);
            }
          });
      };
    };

    this.isItemSelected = (id) => {
      let selected = false;
      _.each(this.ngModel, (resource) => {
        if (_.contains(resource, id)) {
          selected = true;
          return false;
        }
        return true;
      });
      return selected;
    };

    // Builds a state object that is used in the jade.
    this.buildState = () => {
      if (this.state && Object.keys(this.ngModel).length !== this.state.length && this.state.length) {
        // Already building a state.
        return $q.rejectedPromise('Already building state');
      }
      this.state = [];
      let promises = [];
      this.tabLabels = [];
      this.tabs.forEach(tab => {
        this.tabLabels.push(tab.label);
        let items = [];
        if (tab.items.promise) {
          promises.push(tab.items.promise.then(() => {
            tab.items.forEach(item => {
              let nItem = angular.copy(item);
              nItem.selected = this.isItemSelected(nItem._id);
              if (!nItem._id.startsWith('$')) {
                items.push(nItem);
              }
            });
            if ($attrs.sort) {
              items.sort((a, b)=> {
                let textA = a.name.toUpperCase();
                let textB = b.name.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
              });
            }
            // This adds an all devices option that is used for takeovers
            if (tab.key === 'searches') {
              items = [
                {
                  _id: 'ALL',
                  name: '-- All screens --',
                  selected: this.ngModel.searches.includes('ALL')
                },
                ...items
              ];
            }
            this.state.push({
              selected: false,
              label: tab.label,
              key: tab.key,
              items: items,
              disableAll: tab.disableAll,
              allSelected: _.all(items, item => item.selected),
              noneSelected: _.all(items, item => !item.selected)
            });
          }));
        } else {
          // We shoudln't hit this as we're likely to use GenericResources.
          tab.items.forEach(item => {
            let nItem = angular.copy(item);
            nItem.selected = this.isItemSelected(nItem._id);
            items.push(nItem);
          });
          if ($attrs.sort) {
            items.sort((a, b)=> {
              let textA = a.name.toUpperCase();
              let textB = b.name.toUpperCase();
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
          }
          this.state.push({
            selected: false,
            label: tab.label,
            key: tab.key,
            items: items,
            disableAll: tab.disableAll,
            allSelected: _.all(items, item => item.selected),
            noneSelected: _.all(items, item => !item.selected)
          });
          promises.push($q.resolvedPromise());
        }
      });
      return $q.all(promises).then(() => {
        // We now have all the resources required to build the input.
        // First check for selected items, we will use this result to select the
        // inital tab (the tab with the most items selected or the first tab
        // with items).
        let selectedTabIndex = null;
        _.each(this.state, (tab, tabIndex) => {
          let filtered = tab.items.filter((item) => {
            return item.selected;
          });
          if (filtered.length) {
            selectedTabIndex = tabIndex;
          }
          return !filtered.length;
        });
        if (typeof selectedTabIndex === 'number') {
          // We found a good result, use it.
          this.state[selectedTabIndex].selected = true;
          return this.state;
        }
        // Nothing has been selected, select the first tab with items.
        _.each(this.state, (tab, tabIndex) => {
          if (tab.items.length) {
            this.state[tabIndex].selected = true;
            return false;
          }
          return true;
        });
      });
    };

    this.updateSelectionIndicator = () => {
      let promise = $scope.$ctrl.textFunction(this.ngModelCtrl.$viewValue);
      this.friendlyValue = 'Loading...';
      return promise.then((text) => {
        this.friendlyValue = text;
      });
    };

    // Default value for input.
    this.friendlyValue = '-- Select --';
    // Default to closed.
    this.menuClosed = true;

    // Create a list element from template and link to our scope.
    let $listElem = angular.element(
      $templateCache.get('utility/inputs/pxn-resource-select-menu.jade')
    );
    $listElem = $compile($listElem)($scope);

    // RONSEAL - "It does exactly what it says on the tin"
    function setMenuPosition () {
      Measure.place(
        $listElem,
        $element,
        {
          addToDOM: true,
          position: { bottom: -0.2 },
          match: ['width', 'left'],
          preserveBoundary: true,
          minHeight: 10, // REM
          cssPosition: 'absolute'
        }
      );
    }

    let backgroundClickListener = (e) => {
      $scope.$apply(this.toggleMenuVisibility);
    };

    // RONSEAL
    let deregisterScrollListener = angular.noop;
    this.toggleMenuVisibility = (e) => {
      this.menuClosed = !this.menuClosed;
      // Only set poisiton if we're opening.
      let parentModal = $element[0].closest('flare-modal-container');
      let parentModalChildren;
      if (parentModal) {
        parentModalChildren = angular.element(parentModal).children();
      }
      if (!this.menuClosed) {
        // Timeout so that we delay whilst te menu opens (prevents immediate
        // close due to click)
        $timeout(() => {
          setMenuPosition();
          deregisterScrollListener = $scope.$on('ps-scroll-y', backgroundClickListener);
          // Close the menu when we click anywhere that isn't on it.
          window.addEventListener('click', backgroundClickListener);
          if (parentModal) {
            parentModal.addEventListener('click', backgroundClickListener);
            if (parentModalChildren) {
              _.each(parentModalChildren, (elem) => {
                elem.addEventListener('click', backgroundClickListener);
              });
            }
          }
        });
      } else {
        deregisterScrollListener();
        window.removeEventListener('click', backgroundClickListener);
        if (parentModal) {
          parentModal.removeEventListener('click', backgroundClickListener);
          if (parentModalChildren) {
            _.each(parentModalChildren, (elem) => {
              elem.removeEventListener('click', backgroundClickListener);
            });
          }
        }
      }
    };

    // RONSEAL
    this.selectTab = (e, tabIndex) => {
      // Stop the click bubbling
      e.stopPropagation();
      // Check that we haven't got the tab already selected.
      if (this.state[tabIndex].selected) {
        return;
      }
      // Only one list at a time so deselect everything.
      _.each(this.state, (resource) => {
        resource.selected = false;
      });
      // Select the tab that was just clicked.
      this.state[tabIndex].selected = true;
    };

    // RONSEAL
    this.toggleItem = (e, item, tab, forceState, updateIndicator = true) => {
      // Stop the click bubbling
      e.stopPropagation();

      if (typeof forceState === 'boolean') {
        // We have a forceState and always want to action it.
        item.selected = forceState;
      } else {
        // Just toggling not forcing.
        item.selected = !item.selected;
      }
      this.ngModelCtrl.$setViewValue({
        searches: this.state.find((s) => s.key === 'searches').items.filter((i) => i.selected),
        devices: this.state.find((d) => d.key === 'devices').items.filter((i) => i.selected)
      });
      if (this.formCtrl) {
        this.formCtrl.$setDirty();
      }
      if (updateIndicator) {
        this.updateSelectionIndicator();
      }
      // Update the allSelected and noneSelected indicators.
      tab.allSelected = _.all(tab.items, itm => itm.selected);
      tab.noneSelected = _.all(tab.items, itm => !itm.selected);
    };

    this.toggleAll = (event, listIndex, active) => {
      // Stop the click bubbling
      event.stopPropagation();
      // If we are toggling them all active we should start with an empty list
      if (active) {
        this.ngModel[this.state[listIndex].key] = [];
      }
      _.each(this.state[listIndex].items, (item, index) => {
        this.toggleItem(
          { stopPropagation: angular.noop },
          item,
          this.state[listIndex],
          active,
          (index === (this.state[listIndex].items.length - 1))
        );
      });
    };

    $scope.$on('$destroy', () => {
      // Ensure the menu doesn't remain after the scope was destroyed.
      $listElem[0].parentNode.removeChild($listElem[0]);
      deregisterScrollListener();
      window.removeEventListener('click', backgroundClickListener);
    });
  }
});
