angular.module('flare.lists', ['angularMoment'])

.filter('addFilterProps', function (moment) {

  return function (inputArray, props, opts) {
    // Make sure props is an array.
    if (!props instanceof Array) props = [props];
    // Initialise empty options if none given.
    opts = opts || {};

    _.each(inputArray, function (input) {
      // Make sure input is an object
      if (typeof input !== 'object') return;

      _.each(props, function (prop) {
        if (input.hasOwnProperty(prop)) return;
        var val;
        switch (prop) {
          case '$createdMonth':
            var createdProp = opts.created || 'created';
            var createdMoment = moment(input[createdProp]);
            var format = moment().year() === createdMoment.year() ?
                                             'MMMM' :
                                             'MMMM YYYY';
            val = createdMoment.format(format);
            break;

          case '$firstLetter':
            var nameProp = opts.name || 'name';
            if (typeof input[nameProp] == 'string' && input[nameProp].length) {
              val = input[nameProp][0].toLowerCase();
            } else {
              val = 'Unknown';
            }
            break;

          default:
            break;
        }
        input[prop] = val;
      });

      return;
    });

    return inputArray;

  };

})

.filter('orderGroup', function () {

  function dateSortFunction (item) {
    var dateStr = item.name;
    var dateFormat = 'MMMM YYYY';
    var currentYear = moment().year();
    if (/^[a-z]{3,9}$/.test(dateStr)) {
      dateStr += ' ' + currentYear;
    }
    return -1 * moment(dateStr, dateFormat).valueOf();
  }

  return function orderGroup (input, dir) {
    // Accepts object containing groups groupName:[groupItems] and returns
    // an array of objects with name and items properties.
    if (!angular.isObject(input) || angular.isArray(input)) return input;

    var ascending = (dir === 'asc');
    var isDates = false;
    // Check if our names are dates.
    if (
      _.every(input, function (v, k) {
        if (k === '$$ordered') return true;
        return /^[a-z]{3,9}(?: \d{4})?$/gmi.test(k);
      })
    ) {
      isDates = true;
    }

    var output = [];
    _.each(input, function (v, k) {
      if (k === '$$ordered') return;
      output.push({
        name: k,
        items: v
      });
    });
    if (isDates) output = _.sortBy(output, dateSortFunction);
    else output = _.sortBy(output, 'name');

    if (!ascending) output.reverse();

    // hack to stop infinite digests
    if (!input.$$ordered || !angular.equals(output, input.$$ordered)) {
      input.$$ordered = output;
      return output;
    }
    return input.$$ordered;

  };
})

/**
 * pxnResourceGrouper creates a UI that sets a `group by object` with two
 * properties, 'property' - a string indicating which property to group a list
 * of items by, and 'direction', whether the groups should be sorted in
 * ascending or descending order.
 */
.component('pxnResourceGrouper', {
  templateUrl: '',
  bindings: {
    groupBy: '=groupByObject',
    onChange: '&onChange'
  },

  template: ['$log', '$attrs', function resourceGrouperTemplate ($log, $attrs) {
    var properties;

    var icons = {
      $createdMonth: 'entypo-clock',
      category: 'entypo-tag',
      'createdBy.name': 'entypo-user',
      $firstLetter: 'entypo-language'
    };

    if ($attrs.properties) {
      properties = $attrs.properties.split(' ');
    } else {
      properties = [
        '$createdMonth',
        'createdBy.name',
        '$firstLetter'
      ];
    }

    function filterButton (property, iconClass, background) {
      return [
        '<pxn-button',
        ' type="naked icon"',
        ' class="pxn-resource-grouper"',
        ' background="', background, '"',
        ' icon-class="', iconClass, '"',
        ' is-active="$ctrl.groupBy.property === \'', property, '\'"',
        ' ng-click="$ctrl.toggleProp(\'', property, '\')"',
        ' modifiers="[ \'filter\', {\'filter-up\': ',
        '($ctrl.groupBy.property === \'', property, '\' && ',
        '$ctrl.groupBy.direction ===\'asc\'), \'filter-down\': ',
        '($ctrl.groupBy.property === \'', property, '\' && ',
        '$ctrl.groupBy.direction ===\'desc\')',
        '}]"',
        '></pxn-button>'
      ].join('');
    }

    var tpl = [];
    _.each(properties, function (property) {
      tpl.push(filterButton(property, icons[property], $attrs.background));
    });

    return tpl.join('');

  }],

  controller: function ($log, $attrs) {
    var $ctrl = this;
    var properties;
    if ($attrs.properties) {
      properties = $attrs.properties.split(' ');
    } else {
      properties = [
        '$createdMonth',
        'createdBy.name',
        '$firstLetter'
      ];
    }

    // This maps groupBy properties to their associated orderBy properties.
    var orderByMappings = {
      $createdMonth: 'created',
      $firstLetter: 'name',
      'createdBy.name': 'created',
    };

    $ctrl.$onInit = function () {
      if (!angular.isObject($ctrl.groupBy)) {
        $log.warn('[Resource Grouping] No groupBy object provided');
        return;
      }

      if (!$ctrl.groupBy.hasOwnProperty('direction')) {
        $ctrl.groupBy.direction = 'asc';
      }

      if (!$ctrl.groupBy.hasOwnProperty('')) {
        $ctrl.groupBy.property = properties[0];
      }
    }

    function emitChangeEvent (direction, property) {
      if (!$ctrl.onChange) return;
      $ctrl.onChange({ $event: { direction, property } });
    }

    $ctrl.toggleProp = function toggleProp (prop) {
      if ($ctrl.groupBy.property === prop) {
        if ($ctrl.groupBy.direction === 'asc') {
          $ctrl.groupBy.direction = 'desc';
        } else {
          $ctrl.groupBy.direction = 'asc';
        }
        emitChangeEvent($ctrl.groupBy.direction);
      } else {
        $ctrl.groupBy.property = prop;
        $ctrl.groupBy.orderBy = orderByMappings[prop] || null;
        $ctrl.groupBy.direction = 'asc';
        emitChangeEvent($ctrl.groupBy.direction, $ctrl.groupBy.orderBy);
      }
    };
  }
})

.constant('BlankOptionReplacer', function (mutationsList) {
  // Find added childnodes with no innerHTML and set them to 'loading...'.
  try {
    mutationsList.forEach(m => {
      if (m.type === 'childList') {
        m.addedNodes.forEach(n => {
          if (n.innerHTML === '') n.innerHTML = 'Loading...';
        });
      }
    });
  } catch (e) {
    // Some/all functionality probably not supported.
  }
})

/**
 * To use pxn-loading component on a select element:
 * Use condition and for-select attribute with the ID you choose to give to the
 * select element.
 *
 * When the promise resolves or the boolean value changes the content of the
 * component will be removed from the DOM.
 * @Author: Laszlo Fulop
 * @Date: 2018-02-28 17:01:02
 */
.component('pxnLoading', {
  bindings: {
    condition: '<'
  },
  controllerAs: 'vm',
  controller: function pxnLoadingCtrl (
    $rootScope, $scope, $element, $attrs, BlankOptionReplacer, $log
  ) {
    var vm = this;

    vm.$onInit = function () {
      if (this.condition === undefined) {
        $log.warn('[pxnLoading] Loading condition is undefined');
        return;
      }

      // CREATING TEMPLATES
      // isLoading will evaluate true if templates needs to be created based on
      // the state of the promise otherwise false
      var isLoading = (
        // If vm.condition has a .promise property indicates that it is a generic
        // resource $$state of 0 means the promise is pending
        (vm.condition.promise && vm.condition.promise.$$state.status === 0) ||
        // If vm.condition has a .then property and it is type of function
        // indicates that it is a promise
        // $$state of 0 means the promise is pending
        (typeof vm.condition.then === 'function' &&
          vm.condition.$$state.status === 0) ||
        // If vm.condition is a boolean expression it's type should be boolean or
        // number (can be 0 or 1)
        ((typeof vm.condition === 'boolean' || typeof vm.condition === 'number')
        // eslint-disable-next-line eqeqeq
        && vm.condition == true)
      );

      // Configure adding/removing spinner base don provided condition.
      if (vm.condition.promise) {
        addSpinner();
        vm.condition.promise.then(whenFinished);
      } else if (typeof vm.condition.then === 'function') {
        addSpinner();
        vm.condition.then(whenFinished);
      } else if (typeof vm.condition === 'boolean' ||
                 typeof vm.condition === 'number') {
        // Watch for changes to the value
        $scope.$watch('vm.condition', function (newValue, oldValue) {
          if (initialised && newValue === oldValue) {
            // If the value hasn't changed then we dont need to do anything.
            return;
          } else if (newValue) {
            // We are loading..
            addSpinner();
          } else if (!newValue) {
            // We are not loading..
            whenFinished();
          }
        });
      }

      function addSpinner() {
        // Append or prepend templates to the DOM.
        if (selectElem && isLoading) {
          observer = new MutationObserver(BlankOptionReplacer);
          observer.observe(selectElem, { childList: true });
          vm.registerLoadingIndicator(true);
        } else if (!selectElem && isLoading) {
          var spinnerTemplate = document.createElement("div");
          angular.element(spinnerTemplate).addClass("loading");
          spinnerTemplate.innerHTML = `
          <icon class="loading--spinner"></icon>
          <h6 class="loading--text">Loading...</h6>
        `;
          $element.append(spinnerTemplate);
          vm.registerLoadingIndicator(true);
        }
        initialised = true;
      }
    }

    // If component used for option in select
    let selectElem = document.getElementById($attrs.forSelect);

    // Emits a message when a loading indicator gets applied to the page and
    // another when removed.
    vm.registerLoadingIndicator = function (loading) {
      $rootScope.$emit('pxn-loading-register', loading);
    };

    vm.removeSpinner = function () {
      let spinner = $element[0].querySelector('.loading');
      if (spinner) {
        vm.registerLoadingIndicator(false);
      }
      $element.addClass('hidden');
      $element[0].innerHTML = '';
    };

    // This is used to reset the "blank" element created by ng-options to
    // blank (we previously set it to 'loading...'). This is only used if no
    // value has been set (e.g. if we have just created a new slide for the
    // feeds dropdown).
    function resetBlankElem () {
      var options = selectElem.querySelectorAll('option');
      options.forEach((o) => {
        if (o.innerHTML === 'Loading...') {
          o.innerHTML = '';
          vm.registerLoadingIndicator(false);
          return;
        }
      });
    }

    var observer;
    let initialised = false;

    function whenFinished () {
      if (!selectElem) {
        vm.removeSpinner();
      } else if (observer) {
        observer.disconnect();
        resetBlankElem();
      }
    }

  },
})

.factory('SortFunctions', () => {
  const stringAsc = (a, b) =>{
    let val1 = a.toLowerCase();
    let val2 = b.toLowerCase();

    if (val1 < val2) return -1;
    else if (val1 > val2) return 1;
    else return 0;
  };

  return {
    stringAsc: stringAsc
  };
});
