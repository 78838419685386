angular.module('flare.encoding', ['ngSanitize'])

.factory('Encoder', function ($sce) {

  var hiddenPre = document.createElement('pre');

  /**
   * decodes html entities into regular strings
   * @param value
   * @returns {string} A string with decoded entities.
   */
  return {

    decodeHTMLEntities: function decodeEntities (value) {

      if (!value) return '';

      hiddenPre.innerHTML = value.replace(/</g, '&lt;');

      return hiddenPre.textContent;

    },

    getTextContent: function safeTextContent (value) {

      if (!value)
        return '';

      var trusted;

      try {
        trusted = $sce.getTrustedHtml(value);
      } catch (e) {
        trusted = $sce.getTrustedHtml(value.replace(/[<>]/g, ''));
      }

      hiddenPre.innerHTML = trusted;

      // Note that the text content could now contain characters that were
      // encoded (& safe) before, but have been decoded by the page and
      // therefore potentially unsafe, as such we have to get a trusted version
      // again.
      try {
        return $sce.getTrustedHtml(hiddenPre.textContent);
      } catch (e) {
        return $sce.getTrustedHtml(hiddenPre.textContent.replace(/[<>]/g, ''));
      }

    },
  };
});
