function pxnScheduleInputController () {
  var $ctrl = this;
  $ctrl.minDuration = 30;
  $ctrl.$onInit = function () {
    $ctrl.newSchedule = {
      start: 0,
      end: $ctrl.minDuration,
      days: [],
      enabled: true
    };
  };
  $ctrl.deleteSchedule = function (item, index) {
    $ctrl.schedules.splice(index, 1);
    if ($ctrl.formCtrl) {  // need to check that formCtrl actually exists
      $ctrl.formCtrl.$setDirty();
    }
  };
  $ctrl.addSchedule = function () {
    $ctrl.schedules.push($ctrl.newSchedule);
    if ($ctrl.formCtrl) {
      $ctrl.formCtrl.$setDirty();
    }
    $ctrl.newSchedule = {
      start: 0,
      end: $ctrl.minDuration,
      days: [],
      enabled: true
    };
  };
}

angular
  .module('sender')
  .controller('pxnScheduleInputController', pxnScheduleInputController);