angular.module('flare.tickers.ticker', ['genericResource'])

.factory('Tickers', (
  GenericResource, ResourceItem, ConfigURLs, Session, Devices, $q, $timeout
) => {

  // Init all our generic resources here.
  let devices;
  if (Session.hasPermission('devices.read')) {
    devices = Devices.all();
  } else {
    devices = [];
    devices.promise = $q.resolvedPromise();
  }

  class Ticker extends ResourceItem {
    constructor () {
      super(...arguments);

      // Initialise with a blank item if we don't have any.
      if (!this.items || !this.items.length) {
        this.items = [''];
      }

      this.updateDeviceCount();
    }

    delete () {
      if (this.assignedTo > 0) {
        Devices.clearCache();
      }
      return super.delete();
    }

    updateDeviceCount () {
      return devices.promise.then(() => {
        let count = 0;
        let string = '';
        _.each(devices, (device, i) => {
          if (device.ticker && device.ticker === this._id) {
            count += 1;
            string += `\n- ${device.name}`;
          }
        });
        this.assignedTo = count;
        this.assignedToHelpText = string;
      });
    }

    addItem () {
      this.items.push('');
      this.setModified();
      // we want to focus the new input but cannot use request-focus as this
      // conflicts with it's use to focus the name input.
      // NOTE: There are two pxn-ticker-detailed's on the page one is hidden, so
      // we have double inputs.
      $timeout(() => {
        let inputs = document.getElementsByClassName('ticker-content--input');
        // We try to select both the new inputs whichever is on the page will
        // get focus.
        inputs[inputs.length / 2 - 1].focus();
        inputs[inputs.length - 1].focus();
      });
    }

    removeItem (index) {
      this.items.splice(index, 1);
      this.setModified();
    }

  }

  Ticker.prototype._locals = ['selected', 'assignedTo', 'assignedToHelpText'];
  Ticker.prototype._defaults = {
    name: '',
    config: {
      divider: '●',
      messageDuration: 8, // Seconds
      continuous: true,
      hideFor: 60, // 1 minutes (in seconds).
      position: 'top',
      animation: 'individual'
    }
  };

  return new GenericResource(ConfigURLs.tickers, Ticker);
})

.controller('TickerListController', function (
  Session, Tickers, $q, $timeout, Toast, $scope, SaveCheck, $filter, Devices,
  Onboarding, Modal
) {
  let unsquashAnimDuration = 300;

  // Request the users viewable tickers
  if (Session.hasPermission('content.tickers.read')) {
    this.tickers = Tickers.all();
    this.tickers.promise.then(() => {
      this.tickersLoaded = true;
    });
  } else {
    this.tickers = [];
    this.tickers.promise = $q.resolvedPromise();
    this.tickersLoaded = true;
  }

  // Request the users viewable devices
  if (Session.hasPermission('devices.read')) {
    this.devices = Devices.all();
  } else {
    this.devices = [];
    this.devices.promise = $q.resolvedPromise();
  }

  // De-selects a ticker including savecheck & notification if required (auto)
  this.deselectTicker = (refTicker) => {
    if (!this.ticker) return $q.resolvedPromise();
    return SaveCheck.checkNow(false).then(()=> {
      this.ticker.selected = false;
      return Toast.resetAndNotify(this.ticker).then(() => {
        // If we provided a refTicker and it is still the selected ticker then
        // deselect it. (also deselect if there is no refTicker)
        if ((refTicker && refTicker === this.ticker) || !refTicker) {
          this.ticker = null;
        }
      });
    })
    .catch((err) => {
      Toast.makeError(err);
      return $q.rejectedPromise(err);
    });
  };

  // As the user can click tickers in fast succession, we keep reference to the
  // most recently selected and use it to cancel history selections.
  let selecting;
  this.selectTicker = (selectedTicker) => {
    selecting = selectedTicker;
    // Return if we have already selected this ticker.
    if (this.ticker && (this.ticker._id === selectedTicker._id)) {
      return $q.resolvedPromise();
    }
    let exit = !!this.ticker;
    // SaveCheck performed in deselectTicker
    return this.deselectTicker(this.ticker)
      .then(() => {
        // Select the chosen ticker after a delay if we're doing an exit animation.
        return $timeout(() => {
          if (selecting === selectedTicker) {
            this.ticker = selectedTicker
            this.ticker.selected = true;
            $timeout(()=> Onboarding.triggerOverviewResourceAvailable());
          }
        }, exit ? unsquashAnimDuration : 0);
      })
      .catch((err) => {
        Toast.makeError(err);
      });
  };

  // Perform savecheck, deselect then create a new ticker & select.
  this.createNewTicker = () => {
    return this.deselectTicker()
      .then(() => {
        let ticker = Tickers.create({}, false);
        // Select the newly created ticker.
        this.selectTicker(ticker);
      })
      .catch((err) => {
        Toast.makeError(err);
      });
  };

  // A ticker can only be deleted whilst selected as the delete option is within
  // pxn-ticker-detailed.
  this.deleteTicker = () => {
    let modalOpts = {
      templateUrl: 'utility/modal-templates/modal-areyousure.jade',
      scopeData: {
        action: 'delete',
        itemType: 'ticker',
        location: this.ticker.name,
      },
      dismissable: { backButton: false, escape: true, backgroundClick: false }
    };
    if (this.ticker.isLocal()) {
      // Ask the user if they're sure
      new Modal(modalOpts)
        .show()
        .then(() => {
          return Toast.deleteAndNotify(this.ticker).then(() => {
            // Can't use deselectTicker here as it tries to reset the resource
            // that has already been deleted.
            this.ticker = null;
          });
        })
        .catch((err) => {
          Toast.makeError(err);
        });
    } else {
      SaveCheck.checkNow(false)
        .then((hadChanges) => {
          if (hadChanges) {
            return Toast.deleteAndNotify(this.ticker).then(() => {
              // Can't use deselectTicker here as it tries to reset the resource
              // that has already been deleted.
              this.ticker = null;
            });
          } else {
            // we still havent checked with the user.
            new Modal(modalOpts)
              .show()
              .then(() => {
                return Toast.deleteAndNotify(this.ticker).then(() => {
                  // Can't use deselectTicker here as it tries to reset the resource
                  // that has already been deleted.
                  this.ticker = null;
                });
              })
              .catch((err) => {
                Toast.makeError(err);
              });
          }
        })
        .catch((err) => {
          Toast.makeError(err);
        });
    }
  };

  this.saveTicker = () => {
    return Toast.saveAndNotify(this.ticker);
  };


  this.resetTicker = () => {
    return SaveCheck.checkNow(false)
      .then((hadChanges) => {
        if (hadChanges) {
          return Toast.resetAndNotify(this.ticker).then(() => {
            // Can't use deselectTicker here as it tries to reset the resource
            // that has already been deleted.
            this.ticker = null;
          });
        } else {
          // Can't use deselectTicker here as it tries to reset the resource
          // that has already been deleted.
          this.ticker = null;
        }
      })
      .catch((err) => {
        Toast.makeError(err);
      });
  };

  this.filters = {
    tags: [],
    hideAssigned: false,
    hideUnassigned: false,
    ownedByMe: false
  };

  this.toggleTagFilter = (tag, e) => {
    let tags = this.filters.tags;

    if (tags.includes(tag)) {
      // Tag was already selected, deselect.
      this.filters.tags = _.without(tags, tag);
    } else {
      // Tag wasn't selected, select.
      this.filters.tags = tags.concat([tag]);
    }
  };

  // NOTE: this Fn is called when filters are changed, that can be removed/added
  // or updated. We don't know what has changed which is why we don't deselect
  // when the filters no longer match the selected ticker (if the user cancelled
  // on a SaveCheck we couldn't undo their last action).
  let filterFn = ticker => {
    if (
      this.filters.name &&
      !ticker.name.toLowerCase().includes(this.filters.name.toLowerCase())
    ) return false;

    if (
      this.filters.ownedByMe && ticker.ownedBy._id !== Session.current.user._id
    ) return false;

    if (this.filters.hideUnassigned && ticker.assignedTo === 0) return false;

    if (this.filters.hideAssigned && ticker.assignedTo > 0) return false;

    if (this.filters.tags.length) {
      let hasAnyTags = false;
      _.each(this.filters.tags, (tag, i) => {
        hasAnyTags = ticker.tags.includes(tag)
        // Exit loop as soon as we find one
        return !hasAnyTags;
      });
      if (!hasAnyTags) return false;
    }

    if (this.filters.device) {
      if (this.filters.device.ticker !== ticker._id) return false;
    }
    return true;
  };

  this.sortObj = {};

  this.filteredTickers = [];

  this.resetSearchForm = () => {
    this.filters.tags = [];
    this.filters.hideAssigned = false;
    this.filters.hideUnassigned = false;
    this.filters.ownedByMe = false;
    this.filters.name = '';
    this.filters.device = null;
  };

  this.tickers.promise.then(() => {
    $scope.$watch('vm.filters', (nVal, oVal) => {
      if (!nVal || nVal === oVal) return;
      this.filteredTickers = this.tickers.filter(filterFn);
      this.filtering =
        nVal.tags.length ||
        nVal.hideAssigned ||
        nVal.hideUnassigned ||
        nVal.ownedByMe ||
        nVal.name ||
        nVal.device;
      this.filteredTickers = $filter('orderBy')(
        this.filteredTickers,
        this.sortObj.property,
        !this.sortObj.ascending
      );
    }, true);

    $scope.$watch('vm.sortObj', (nVal, oVal) => {
      if (!nVal) return;
      this.filteredTickers = $filter('orderBy')(
        this.filteredTickers,
        this.sortObj.property,
        !this.sortObj.ascending
      );
    }, true);

    $scope.$watchGroup(['vm.ticker.name', 'vm.tickers.length'],
      (nVal, oVal) => {
        if (!nVal[0] && !nVal[1]) return; // Return if everything undefined.
        if (nVal[0] === oVal[0]) return;
        if (nVal[1] === oVal[1]) return;
        let filteredTickers = this.tickers.filter(filterFn);
        this.filteredTickers = $filter('orderBy')(
          filteredTickers,
          this.sortObj.property,
          !this.sortObj.ascending
        );
      }
    );

    // Run the filters through once to initialise.
    this.filteredTickers = this.tickers.filter(filterFn);
    // NOTE: local tickers are not added to the list so we need to also check
    // the current selected ticker (if we have a local that will be it).
    SaveCheck.register({
      hasChanges: () =>
        _.any(this.tickers, (t, i) => t.isModified()) || this.ticker && this.ticker.isModified()
      ,
      discardChanges: () => {
        _.each(this.tickers, (ticker) => {
          if (ticker.isModified()) {
            ticker.reset();
          }
        });
      }
    });
  });

  $scope.$on('$destroy', () => {
    SaveCheck.checkNow();
  });
})

.component('pxnTickerListItem', {
  templateUrl: 'tickers/pxn-ticker.jade',
  bindings: {
    ticker: '=',
    selectedTags: '=',
    toggleTagFilter: '='
  },
  controllerAs: '$ctrl'
})

.component('pxnTickerDetailed', {
  templateUrl: 'tickers/pxn-ticker-detailed.jade',
  bindings: {
    ticker: '=',
    saveFn: '&',
    deleteFn: '&',
    resetFn: '&',
    deselectFn: '&'
  },
  controllerAs: '$ctrl',
  controller: function ($scope, Modal, Toast, $attrs, Tickers) {
    this.$onInit = function () {
      this.requiredPermission =
        this.ticker.isLocal() ?
          'content.tickers.create' :
          'content.tickers.update';
      this.showDeselect = $attrs.showDeselect;

      this.tickerMessagesValid = () => {
        let validity = true;
        if (!this.ticker || !this.ticker.items) {
          return false;
        }
        _.each(this.ticker.items, (message, i) => {
          if (!message.length) {
            validity = false;
            return false; // end loop
          }
          return true;
        });
        return validity;
      };

      this.detailedSaveTicker = () => {
        this.saveFn(this.editTicker).then(() => {
          // We saved, reset the form
          this.editTicker.$setPristine();
        });
      };

      let movedItemIndex;
      // We use the dnd-drop call back to get the index to insert at. We store
      // this value and use in itemMoved (below). This callback also provides the
      // item we are moving so we return this to add it to the list.
      this.dndDropCallback = (index, item) => {
        movedItemIndex = index;
        // The returned value is added to the list unless it is a boolean.
        return item;
      };

      // In this call back we recieve the index the item came from, if we have
      // moved up we need to account for the newly added item.
      this.itemMoved = (index) => {
        // Compare the new & old indexs to determine the drag direction.
        let movedUp = movedItemIndex < index;

        this.ticker.removeItem(movedUp ? index + 1 : index);
      };

      this.canSave = () => {
        if (!this.ticker) return false;
        if (!this.ticker.items.length || !this.ticker.name) {
          return false;
        }
        let itemsValid = true;
        _.each(this.ticker.items, (item) => {
          if (!item.length) {
            itemsValid = false;
          }
          return itemsValid; // End early if this becomes false.
        });
        if (!itemsValid) {
          return false;
        }
        if (!this.ticker.isModified() && !this.editTicker.$dirty) {
          return false;
        }
        if (
          this.ticker.config.animation === 'combined' &&
          !this.ticker.config.divider
        ) {
          // Divider required if we're combined
          return false;
        }
        if (this.ticker.config.animation === 'individual' && (
          !this.ticker.config.messageDuration ||
          this.ticker.config.messageDuration < 3 ||
          this.ticker.config.messageDuration > 3600
        )) {
          // Duration required if we're individual
          return false;
        }
        if (!this.ticker.config.continuous && (
          !this.ticker.config.hideFor ||
          this.ticker.config.hideFor < 1 ||
          this.ticker.config.hideFor > 3600
        )) {
          // We require a duration if you plan to hide the ticker.
          return false;
        }
        return true;
      };

      $scope.suggestedTags = Tickers.getTags();

      $scope.$on('$destroy', () => {
        if (this.ticker) {
          this.ticker.selected = false;
        }
      });
    }
  }
})

.component('pxnTickerPreview', {
  templateUrl: 'tickers/pxn-ticker-preview.jade',
  bindings: {
    visible: '='
  },
  controllerAs: '$ctrl',
  controller: ($rootScope, $element, $scope, $log) => {
    const TICKER_FSS_VALUE = 56;

    // At the moment the FTP is the immediate sibling of the ticker preview but
    // it might not be the case in the future. This function finds the right
    // FTP element for the ticker preview as long as they are siblings.
    const findFtp = () =>
      Array.from($element.parent().children())
        .find(e => e.tagName === 'FLARE-TEMPLATE-PREVIEW');

    let ftpElem = findFtp();
    let templateScale, templateFontSize, fontScale;

    let setFontSize = () => {
      // NOTE: If no template is selected this won't work!
      try {
        templateScale = parseFloat(
          ftpElem.children[0].children[0].getAttribute('flare-slide-scale')
        );
        templateFontSize =
          parseFloat(ftpElem.children[0].children[0].style.fontSize);
        fontScale = templateScale / templateFontSize;
        if (!fontScale) return;
        $element[0].style.fontSize = '' + (TICKER_FSS_VALUE / fontScale) + 'px';
      } catch (e) {
        $log.warn(
          '[pxnTickerPreview] - ' +
          'Unable to set font size, have you selected a template?'
        );
      }
    };

    // `setAnimationToggle` is emitted just after a template loads.
    let deregisterAnimationListener =
      $rootScope.$on('setAnimationToggle', setFontSize);

    // Update the size on resize.
    window.addEventListener('resize', setFontSize);

    $scope.$on('$destroy', () => {
      deregisterAnimationListener();
      window.removeEventListener('resize', setFontSize);
    });
  }
});
