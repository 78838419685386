angular.module('flare.media-cleaner', [])
.factory('Duplicates', function ($http, $log) {
  // Note - we don't use ConfigURLs to minimise the footprint of this feature.
  return {
    getAll: function () {
      return $http.get('/api/duplicates/metadata')
      .then(function (response) {
        return response.data;
      })
      .catch(function (err) {
        $log.error(err);
      });
    },
    addMetadata: function (upload) {
      return $http.get('/api/duplicates/' + upload._id + '/metadata')
      .then(function (response) {
        return response.data;
      })
      .catch(function (err) {
        $log.error(err);
      });
    },
    linkAndRemove: function (originalUpload, upload) {
      return $http.get(
        '/api/duplicates/' + upload._id + '/linkTo/' + originalUpload._id
      )
      .then(function (response) {
        return response.data;
      })
      .catch(function (err) {
        $log.error(err);
      });
    }
  };
})
.controller('MediaCleanerController', function (Uploads, Duplicates, Session, $timeout) {
  // Useful contants and vm.
  var MD5_PAGE_COUNT = 125;
  var METADATA_STAGGER_MS = 2500;
  var MILLION = 1000000;
  var vm = this;
  // Set a property on an upload for displaying the file size
  // in a human-readable format.
  vm.setFriendlySize = function (upload) {
    if (upload.originalSize > MILLION) {
      upload.friendlySize = (upload.originalSize / MILLION).toFixed(1) + ' MB';
    } else if (upload.originalSize > 1000) {
      upload.friendlySize = (upload.originalSize / 1000).toFixed(0) + ' KB';
    } else {
      upload.friendlySize = '1 KB';
    }
  };
  // Lookup metadata for all uploads and find those that don't
  // have metadata yet.
  vm.findUploadsWithoutMd5 = function () {
    _.remove(vm.uploads.all, function (upload) {
      return (upload.account._id || upload.account) !== Session.current.account._id;
    });
    vm.uploads.noMd5 = [];
    Duplicates.getAll().then(function (mUploads) {
      _.each(mUploads, function (mUpload) {
        var upload = _.find(vm.uploads.all, { _id: mUpload._id });
        if (upload) {
          upload.originalSize = mUpload.originalSize;
          upload.originalMd5 = mUpload.originalMd5;
          upload.linkedTo = mUpload.linkedTo;
        }
      });
      vm.uploads.noMd5 = _.sortBy(
        _.filter(vm.uploads.all, function (upload) {
          return !upload.originalMd5;
        }),
        'created'
      );
      vm.uploads.noMd5Page = vm.uploads.noMd5.slice(0, MD5_PAGE_COUNT);
      vm.findDuplicates();
    });
  };
  // Scan the list of uploads that have metadata for duplicates
  // (where original size and md5 match) then create a group for
  // each distinct md5 if there are unlinked uploads.
  vm.findDuplicates = function () {
    vm.uploads.duplicates = [];
    var withMd5 = _.sortBy(
      _.filter(vm.uploads.all, function (upload) {
        return upload.originalMd5;
      }),
      'created'
    );
    while (withMd5.length) {
      var original = withMd5.shift();
      var matching = _.remove(withMd5, function (upload) {
        return upload.originalMd5 === original.originalMd5 &&
          upload.originalSize === original.originalSize;
      });
      if (matching.length) {
        var unlinked = [];
        _.each(matching, function (match) {
          if (!match.linkedTo) {
            unlinked.push(match);
          }
        });
        if (unlinked.length) {
          vm.setFriendlySize(original);
          vm.uploads.duplicates.push({
            original: original,
            unlinked: unlinked
          });
        }
      }
    }
  };
  // Request that the server adds and returns metadata for an upload.
  vm.addMetadata = function (upload) {
    return Duplicates.addMetadata(upload).then(function (data) {
      _.extend(upload, data);
      vm.setFriendlySize(upload);
      vm.findDuplicates();
    });
  };
  // Used for bulk mode - adds metadata to the next upload in the list.
  vm.addMetadataToNext = function () {
    if (!vm.bulkMetadataMode) return;
    var next = _.find(vm.uploads.noMd5Page, function (upload) {
      return !upload.originalMd5 || !upload.originalSize;
    });
    if (next) {
      vm.addMetadata(next)
      .then(function () {
        if (vm.bulkMetadataMode) {
          $timeout(vm.addMetadataToNext, METADATA_STAGGER_MS);
        }
      });
    } else {
      vm.bulkMetadataMode = false;
    }
  };
  // Enabled/disables bulk mode for adding metadata to uploads.
  vm.addMetadataToAll = function (enabled) {
    if (!enabled) {
      $timeout.cancel(vm.bulkMetadataTimer);
      vm.bulkMetadataMode = false;
    } else {
      vm.bulkMetadataMode = true;
      $timeout(vm.addMetadataToNext, METADATA_STAGGER_MS);
    }

  };
  // Request that the server links an upload to the original upload
  // and deletes the corresponding files.
  vm.linkAndRemove = function (originalUpload, upload) {
    Duplicates.linkAndRemove(originalUpload, upload).then(function (data) {
      _.extend(upload, data);
    });
  };
  // Instantiate data.
  vm.uploads = {
    all: Uploads.all(vm.findUploadsWithoutMd5),
    noMd5: [],
    noMd5Page: [],
    duplicates: []
  };
  vm.bulkMetadataMode = false;
  vm.bulkMetadataTimer = null;
});