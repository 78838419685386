angular
  .module('flare.licensing', [])

  .factory('Licences', (ConfigURLs, $http, $q, $log) => {
    let cached;
    let inFlightRequest;

    function fetchCounts (forceRefresh) {
      if (forceRefresh || !cached) {
        if (!inFlightRequest) {
          inFlightRequest = $http.get(ConfigURLs.licences())
            .then((response)=> {
              cached = response.data;
              inFlightRequest = null;
              return cached;
            })
            .catch((err)=> {
              $log.error(err);
            });
        }
        return inFlightRequest;
      } else {
        return $q.resolvedPromise(cached);
      }
    }

    function fetchCountsForAccount (accountId) {
      return $http.get(ConfigURLs.licences(accountId))
        .then((response)=> {
          return response.data;
        })
        .catch((err)=> {
          $log.error(err);
        });
    }

    function getCounts () {
      if (!cached) {
        return { available: undefined, total: undefined };
      }
      return cached;
    }

    return {
      fetchCounts, getCounts, fetchCountsForAccount
    };
  })

  .component('licenceStatusBar', {
    bindings: {
      accountId: '@'
    },
    controllerAs: '$ctrl',
    template: /* html*/ `<div class="row" ng-if="$ctrl.usingLicences">
    <div class="col-xs-12 vgu-bottom-margin text-align__center text__normal">
      <div class="horizontal-xs justify-center-xs">
        <icon
          ng-if="$ctrl.isOverSubscribed"
          class="inline-help inline-help__warning \
          inline-help__warning--strong vgu-right-margin \
          negative-vgu-left-margin"></icon>
        <span>
          {{$ctrl.used}} of {{$ctrl.total}} licences used
        </span>
      </div>
      <div
        pxn-progress
        modifiers="small {{$ctrl.isOverSubscribed ? 'warning' : ''}}"
        progress="{{$ctrl.usedPercent}}"></div>
    </div>
  </div>`,
    controller: function (Licences, $scope) {

      const setCounts = ({ total, available, used }) => {
        this.total = total;
        this.available = available;
        this.used = used;
        this.usingLicences = total !== -1;
        this.usedPercent = (used / total) * 100;
        this.isOverSubscribed = this.usingLicences && (used > total);
      };

      this.$onInit = () => {
        if (this.accountId) {
          Licences.fetchCountsForAccount(this.accountId).then((data) =>{
            setCounts(data);
          });
        } else {
          Licences.fetchCounts();
          // Cancel the watch if licences aren't being used
          let cancelWatch = $scope.$watch(
            () => Licences.getCounts().used,
            () => {
              setCounts(Licences.getCounts());
              if (!this.usingLicences) cancelWatch();
            }
          );
        }
      };
    },
  });
