angular.module('flare.feeds', ['flare.receiver.utils.social'])

.factory('Feeds', function (
  $log, GenericResource, ResourceItem, ConfigURLs, Providers
) {

  var iconClasses = {
    beekeeper: 'pxn-icon-beekeeper',
    'bg-call-stats': 'pxn-icon-british-gas',
    'bg-verbatim': 'entypo-chat',
    'centrica-achievers': 'entypo-users',
    'centrica-share-price': 'entypo-chart-area',
    'centrica-sharepoint': 'entypo-newspaper',
    facebook: 'entypo-facebook-squared',
    finance: 'entypo-chart-line',
    'heathrow-sharepoint': 'entypo-newspaper',
    instagram: 'pxn-icon-instagram',
    justgiving: 'entypo-credit-card',
    'national-rail': 'pxn-icon-national-rail',
    pinterest: 'entypo-pinterest',
    powerbi: 'entypo-chart-bar',
    rss: 'entypo-rss',
    'server-monitoring': 'entypo-chart-line',
    twitter: 'entypo-twitter',
    'vf-heartbeat': 'entypo-heart',
    weather: 'entypo-cloud',
    workday: 'entypo-layout',
    workplace: 'pxn-icon-workplace',
    yammer: 'pxn-icon-yammer'
  };

  class Feed extends ResourceItem {
    constructor (data) {
      super(data);
    }

    updateEndpoint () {
      if (this._provider && this.endpoint) {
        this._endpoint = _.find(
          this._provider.endpoints,
          { name: this.endpoint }
        );
      }
    }

    reset () {
      if (!this.isLocal()) {
        return super.reset().then(()=>{
          this.updateEndpoint();
        });
      } else {
        return super.reset();
      }
    }
  }

  Feed.prototype._defaults = {
    name: '',
    domain: null,
    endpoint: null,
    curated: true,
    items: [],
    itemType: null,
    integration: null,
    query: {},
    filters: {},
    amendments: {},
    _provider: null,
    _endpoint: null,
    searchResults: [],
    filteredResults: [],
    deletedResults: [],
    _castFields: {
      _provider: Providers
    }
  };

  Feed.prototype.resourceType = 'feed';
  Feed.prototype._basePermission = 'content.feeds';

  Feed.prototype._locals = [
    '_provider',
    '_endpoint',
    '_type',
    'itemType',
    'searchResults',
    'filteredResults',
    'deletedResults',
    '_needsAttention',
    '_needsIntegrationReconnect'
  ];

  var Feeds = new GenericResource(ConfigURLs.feeds, Feed);

  Feeds.getIconForDomain = function getIconForDomain (domain) {
    if (iconClasses.hasOwnProperty(domain)) {
      return iconClasses[domain];
    } else {
      return 'entypo-air';
    }
  };

  return Feeds;
})

.controller('FeedsController', function (
  $scope,
  $rootScope,
  $timeout,
  $http,
  $log,
  $templateCache,
  $q,
  $state,
  ConfigURLs,
  Feeds,
  Providers,
  Modal,
  SaveCheck,
  Toast,
  Integrations,
  Session,
  Android,
  $window,
  $localForage,
  Onboarding,
  Sidebar
) {
  var SOCIALSERVICES = [
    'beekeeper',
    'facebook',
    'instagram',
    'pinterest',
    'twitter',
    'workplace',
    'yammer'
  ];

  // Open secondary sidebar by default
  Sidebar.set({
    sidebarExpanded: false,
    secondarySidebarExpanded: true
  }, false);

  var vm = this;

  // Check if the user has sufficient permisison to view/use integrations.
  var integrationsPermission = Session.hasPermission('integrations.read');
  var integrations = integrationsPermission ? Integrations.all() : [];

  if (integrationsPermission) {
    integrations.promise.then(filterIntegrations);
  }

  /**
   * Create `vm.integrations` containing only integrations that will work with
   * the currently selected feed.
   * @return {undefined} undefined - sets `vm.integrations`.
   */
  function filterIntegrations () {
    if (integrations.length && vm.feed) {
      vm.integrations = _.filter(integrations, { provider: vm.feed.domain });
    }
  }

  // Check if the user has sufficient permisison to view/use feeds.
  if (Session.hasPermission('content.feeds.read')) {
    vm.feeds = Feeds.all();
  } else {
    vm.feeds = [];
    vm.feeds.promise = $q.resolvedPromise();
  }

  // Define default state of filters
  vm.filters = {
    showShared: true,
    showHidden: false,
  };

  vm.providers = Providers.all();
  vm.providersPromiseSettled = false;

  vm.providers.promise.then((providers) => {
    vm.provisionedProviders = Providers.getProvisionedProvidersForAccount(
      providers, Session.current.account
    );
    vm.providersPromiseSettled = true;
  });

  // Sets the _provider & _endpoint for feeds and adds the needs attention flag
  // if the associated integration has expired.
  if (Session.hasPermission('content.feeds.read')) {
    $q.all(
      [vm.feeds.promise, integrations.promise, vm.providers.promise]
    ).then(function () {
      _.each(vm.feeds, function (feed) {
        // Find the provider for each feed so that we can check that feeds have
        // integrations if they need them.
        feed._provider = _.find(vm.provisionedProviders, {
          domain: feed.domain,
        });
        if (!feed._provider || !feed._provider._authRequired) return;

        // second argument true means fetch from cache only, so can be -1 if
        // integration doesn't exist for some reason.
        let integration =
          feed.integration && Integrations.byId(feed.integration, true);
        feed._needsAttention =
          (!integration || integration === -1 || integration.needsReconnect);
        // This flag is for the integration select.
        feed._needsIntegrationReconnect =
          integration && integration.needsReconnect;
      });
    });
  }

  vm.pageManagement = {
    accordionShown: {
      feedItems: true,
      filteredItems: false,
      rejectedItems: false,
      resultItems: false,
      searchFor:    false,
    },
    searching: false,
  };

  vm.manageProviders = function () {
    let usedProviders = [];
    _.each(vm.feeds, (feed) => {
      usedProviders.push(feed.domain);
    });
    vm.providers = vm.providers.map((p) => {
      if (_.contains(usedProviders, p.domain)) {
        p._inUse = true;
        p._disabledMessage = 'Provider in use';
      }
      if (p.private) p._disabledMessage = 'Private provider';
      if (_.contains(Session.current.account.providers, p._id)) {
        p._provisioned = true;
      }
      return p;
    });
    let modalOptions = {
      templateUrl: 'utility/modal-templates/manage-providers.jade',
      scopeData: {
        provisionedProviders: Session.current.account.providers.slice(),
        toggleProvider: (provider, provisionedProviders) => {
          let provisionedIndex = provisionedProviders.indexOf(provider._id);
          if (provisionedIndex === -1) {
            provisionedProviders.push(provider._id);
            provider._provisioned = true;
          } else {
            provisionedProviders.splice(provisionedIndex, 1);
            delete provider._provisioned;
          }
        },
        providers: vm.providers
      },
      dismissable: {
        backButton: false,
        escape: true,
        backgroundClick: false
      }
    };
    new Modal(modalOptions).show()
    .then(enabledProviders => {
      const data = enabledProviders;
      $http.put(
        ConfigURLs.setProviderForAccount(Session.current.account._id), data
      ).then(res => {
        Session.current.account.providers = res.data.providers;
        Toast.makeSuccess('Enabled providers on account updated successfully.');
        // We need to repolulate `vm.provisionedProviders` as this is what we
        // use to generate the lsit of providers in teh create modal.
        vm.provisionedProviders = [];
        _.each(vm.providers, (p) => {
          if (p._provisioned) {
            vm.provisionedProviders.push(p);
          }
        });

      }).catch(e=> $log.log(e));
    }).catch(e=> {
      _.each(vm.providers, (p) => {
        if (p._provisioned) delete p._provisioned;
        if (p._inUse) delete p._inUse;
      });
    });
  };

  function checkIfProviderConnectionsExpired (provider) {
    // Return undefined if we are still waiting for integrations so we can
    // OTB
    if (Integrations._cache.loading) return undefined;
    // Provider on an integration is actually the requried domain of a provider.
    let matchingIntegrations = _.filter(integrations, { provider: provider.domain });
    return _.every(matchingIntegrations, (i) => i.needsReconnect);
  }

  function checkIfProviderHasAccount (provider) {
    // Return undefined if we are still waiting for integrations so we can
    // OTB (angular doesn't consider undefined to be a value that unbinds a OTB)
    if (Integrations._cache.loading) return undefined;
    // Provider on an integration is actually the requried domain of a provider.
    return _.find(integrations, { provider: provider.domain });
  }

  vm.feedsLoaded = false;
  vm.feeds.promise.then(function () {
    vm.feedsLoaded = true;
    // Get suggested tags for feeds
    vm.suggestedTags = Feeds.getTags();

    SaveCheck.register({
      hasChanges: function () {
        return _.any(vm.feeds, function (value, index) {
          if (value.isLocal()) {
            return true;
          }
          return value.isModified();
        });
      },
      discardChanges: function () {
        _.each(vm.feeds, function (feed) {
          if (feed.isModified()) {
            feed.reset().then(()=> {
              vm.onIntegrationChanged(feed, true);
            });
          }
        });
      },
    });

    $localForage.getItem('inProgressFeedPartial')
      .then(function (feed) {
        if (feed) {
          var partial = JSON.parse(feed);
          vm.createFeed(partial);
          $localForage.removeItem('inProgressFeedPartial');
          if (partial._hasRedirectedToProvider) {
            var integration =
              _.where(integrations, { provider: partial._provider.domain });
            if (integration[0]) {
              $timeout(function () {
                // Timeout so it shows in the modal
                Toast.makeSuccess(
                  `${partial._provider.name} account ${integration[0].name}` +
                  ' successfully connected.'
                );
              }, 0);
            } else {
              $timeout(function () {
                Toast.makeError(
                  'Unable to authenticate ' +
                  partial._provider.name +
                  ' account'
                );
              });
            }
          }
        }
      })
      .catch((err)=> {
        $log.error(err);
      });
  });

  vm.createFeed = function (partial) {
    // If we already have a feed we should check for changes.
    var promise = SaveCheck.checkNow(false);

    promise.then(function () {
      var createdFeed = Feeds.create(partial);

      vm.selectedItem = null;
      var modal = new Modal({
        templateUrl: 'utility/modal-templates/createFeed.jade',
        scopeData:   {
          feed:       createdFeed,
          providers:    vm.provisionedProviders,
          selectFeed: vm.selectFeed,
          allConnectedAccountsAreExpired: checkIfProviderConnectionsExpired,
          hasConnectedAccount: checkIfProviderHasAccount,
          reconnectAccount: function (feed) {
            SaveCheck.deregister();
            // Store the feed we're creating locally.
            $localForage.setItem(
              'inProgressFeedPartial',
              JSON.stringify(feed.toPlainObject(null,
                ['name', 'description', 'tags', 'ownedBy', '_provider']
              ))
            );
            createdFeed.reset();
            modal.reject().then(function () {
              $state.go('settings');
            });
          },
          createIntegration: function (feed) {
            SaveCheck.deregister();
            // Store the feed we're creating locally.
            feed._hasRedirectedToProvider = true;
            $localForage.setItem(
              'inProgressFeedPartial',
              JSON.stringify(feed.toPlainObject(null,
                [
                  'name', 'description', 'tags', 'ownedBy',
                  '_provider', '_hasRedirectedToProvider'
                ]
              ))
            ).finally(function () {
              if (feed._provider.manualAuth) {
                // Create and open the manual auth modal.
                let authModal = new Modal({
                  templateUrl:
                    'utility/modal-templates/manualAuthIntegration.jade',
                  scopeData: {
                    name: feed._provider.name,
                    title: 'Integration details',
                    message: feed._provider.manualAuth.helpText,
                    fields: feed._provider.manualAuth.fields,
                    loading: false,
                    authDetails: {},
                    positiveButton: 'Done',
                    negativeButton: 'Cancel',
                    checkAuth: function checkAuth (auth) {
                      authModal.setScopeData({ loading: true });

                      $http({
                        url: feed._provider.manualAuth.callback,
                        method: 'GET',
                        params: auth
                      })
                      .then((res)=> { authModal.resolve(res.data); })
                      .catch((e)=> {
                        Toast.makeError(e);
                        authModal.setScopeData({
                          loading: false
                        });
                      });
                    }
                  }
                });

                authModal.show()
                  .then(function (auth) {
                    Toast.makeSuccess(
                      `${feed._provider.name} integration created succesfully`
                    );
                    Integrations.create(auth);
                  })
                  .catch((err)=> {
                    Toast.makeError(err);
                  });
              } else {
                // Link out to external auth provider.
                $window.open(feed._provider.getAuthUrl('/feeds'), '_self');
              }
            });
          }
        },
        preventUnloadEvent: true
      });

      modal.show().then(function () {
        vm.selectFeed(createdFeed);
        createdFeed.setModified();
      }).catch(function (reason) {
        createdFeed.reset();
      });
    })
    .catch((err)=> {
      Toast.makeError(err);
    });
  };

  vm.onIntegrationChanged = function (feed, noModify) {
    if (feed.integration) {
      let integration =
        Integrations.byId(feed.integration, true);
      if (integration !== -1) {
        feed._needsIntegrationReconnect = integration.needsReconnect;
        feed._needsAttention = integration.needsReconnect;
      }
    }
    if (!noModify) feed.setModified();
  };

  vm.editFeed = function (feed) {
    let q = $q.defer();

    let modalOpts = {};

    if (feed._$modified) {
      modalOpts = {
        templateUrl: 'utility/modal-templates/modal-pending-changes.jade',
        scopeData:   {
          resource: 'feed',
        },
      };

      new Modal(modalOpts).show()
        .then(function () {
          Toast.saveAndNotify(feed);
          q.resolve();
        })
        .catch((err)=> {
          Toast.makeError(err);
        });
    } else {
      q.resolve();
    }

    q.promise.then(function () {
      modalOpts = {
        templateUrl: 'utility/modal-templates/editFeed.jade',
        scopeData:   {
          feed: feed,
        },
      };

      new Modal(modalOpts)
        .show()
        .then(function () {
          Toast.saveAndNotify(feed);
        })
        .catch((err) => {
          Toast.makeError(err);
        });
    });
  };

  vm.selectFeed = function (feed) {
    if (!feed) {
      vm.feed = null;
      return;
    }
    if (feed === vm.feed) {
      return;
    }

    $timeout(function () {
      Onboarding.triggerOverviewResourceAvailable();
    });

    // If we've just created a new feed, don't do a savecheck.
    var promise = feed.isLocal()
      ? $q.resolvedPromise()
      : SaveCheck.checkNow(false);

    // Perform SaveCheck when switching feed.
    promise.then(function changSelectedFeed () {
      $rootScope.$emit('hide-sidebar');
      vm.feed = feed;
      // Select the first item in the feed.
      if (vm.feed.items.length > 0) {
        vm.selectItem(vm.feed.items[0], 'items');
      } else {
        vm.selectedItem = null;
      }
      if (!vm.feed.domain) {
        if (vm.feed._provider) {
          vm.feed.domain = vm.feed._provider.domain;
        }
      }
      // Reset the scroll position of the search column.
      let scrollElem = document.querySelector('.feeds-right');
      if (scrollElem) {
        $scope.$emit('scroll-to-top', scrollElem);
      }
    })
    .catch((err)=> {
      Toast.makeError(err);
    });
  };

  vm.performSearch = function () {
    vm.pageManagement.searching = true;
    vm.pageManagement.accordionShown.searchFor = false;
    vm.pageManagement.accordionShown.resultItems = true;

    var feed = vm.feed;
    var searchParams = {
      provider:   feed._provider._id,
      endpoint:   feed._endpoint.name,
      query:      feed.query,
      filters:    feed.filters,
      amendments: feed.amendments,
      integration: feed.integration,
    };

    // Clear the search and filtered results, and hide the detailed view.
    feed.searchResults = [];
    feed.filteredResults = [];
    vm.selectItem(null);

    feed._provider.makeRequest(searchParams)
    .then(function (response) {
      // ** This function's comments kindly sponsored by the server team. **
      // In case the feed is curated, we need to filter out any curated and
      // deleted items from the response to make sure we don't show duplicates.
      var idLocation = feed._type.uniqueKeyPath;
      var curatedItemIds = _.map(feed.items, 'data.' + idLocation);
      var deletedItemIds = _.map(feed.deletedResults, 'data.' + idLocation);
      var existingIds = curatedItemIds.concat(deletedItemIds);
      var newItems = _.filter(response, function (item) {
        return !_.includes(existingIds, item.data[idLocation]);
      });
      // For each new item, add it to either the search or filters results
      // depending on whether there are filter analysis items.
      _.each(newItems, function (item) {
        if (angular.isArray(item.data.__analysis) && item.data.__analysis.length) {
          // Create tooltip for filter reasons and add to filtered items.
          item.analysisMarkdown = '**Filters matched:** \n\n';
          _.forEach(item.data.__analysis, function (reason) {
            item.analysisMarkdown += '- ' + reason + '\n';
          });
          feed.filteredResults.push(item);
        } else {
          // Add to search items.
          feed.searchResults.push(item);
        }
      });
      // Add the response metadata (not really sure what this is?).
      vm.feed.meta = response.metadata;
      if (vm.feed.searchResults.length) {
        vm.selectItem(vm.feed.searchResults[0], 'searchResults');
      } else if (vm.feed.filteredResults.length) {
        Toast.make('None of the returned search results match your filters, ' +
        'please check the filtered results section or modify your filters and' +
        ' try again.');
      } else {
        Toast.makeError('Your query returned no results , please amend the ' +
            'request parameters and try again.');
      }
    })
    .finally(function () {
      // Set flag to indicate that we are no longer searching.
      vm.pageManagement.searching = false;
    });
  };

  vm.selectItem = function (item, collection) {
    vm.selectedItem = item;
    if (vm.selectedItem) {
      vm.selectedItem.collection = collection;
    }
  };

  vm.approveItem = function (item) {
    var feed = vm.feed;
    var uniqueKeyPath = vm.feed._type.uniqueKeyPath;
    if (!_.includes(feed.items, item)) {
      feed.items.unshift(item);
      var index = _.findIndex(feed[item.collection], function (fItem) {
        return fItem.data[uniqueKeyPath] === item.data[uniqueKeyPath];
      });
      feed[item.collection].splice(index, 1);
      if (index < (feed[item.collection].length - 1)) {
        vm.selectItem(feed[item.collection][index], [item.collection][0]);
      } else if (feed[item.collection].length) {
        vm.selectItem(feed[item.collection][0], [item.collection][0]);
      } else if (feed.searchResults.length) {
        vm.selectItem(feed.searchResults[0], 'searchResults');
      } else {
        vm.selectItem(feed.items[0], 'items');
      }
      feed.setModified();
    }
  };

  vm.rejectItem = function (item) {
    var feed = vm.feed;
    var uniqueKeyPath = vm.feed._type.uniqueKeyPath;
    if (!_.includes(feed.deletedResults, item)) {
      feed.deletedResults.unshift(item);
      var index = _.findIndex(feed[item.collection], function (fItem) {
        return fItem.data[uniqueKeyPath] === item.data[uniqueKeyPath];
      });
      feed[item.collection].splice(index, 1);
      if (index < (feed[item.collection].length - 1)) {
        vm.selectItem(feed[item.collection][index], [item.collection][0]);
      } else if (feed[item.collection].length) {
        vm.selectItem(feed[item.collection][0], [item.collection][0]);
      } else if (feed.searchResults.length) {
        vm.selectItem(feed.searchResults[0], 'searchResults');
      } else {
        vm.selectItem(null, null);
      }
    }
    feed.setModified();
  };

  vm.saveFeed = function () {
    if (
      (vm.feed.isLocal() && Session.hasPermission('content.feeds.create')) ||
      (!vm.feed.isLocal() && Session.hasPermission('content.feeds.update'))
    ) {
      Toast.saveAndNotify(vm.feed);
    }
  };

  vm.disableSave = () => {

    let disabled = false;
    if (
      (vm.feed.isLocal() && !Session.hasPermission('content.feeds.create')) ||
      (!vm.feed.isLocal() && !Session.hasPermission('content.feeds.update'))
    ) {
      disabled = true;
    }
    return disabled;
  };

  vm.discardChanges = function (feed) {
    let modalOpts = {
      templateUrl: 'utility/modal-templates/modal-areyousure.jade',
      scopeData: {
        action: 'revert changes to',
        itemType: 'feed',
        dismissable: { backButton: false, escape: true, backgroundClick: false }
      },
    };

    new Modal(modalOpts)
      .show()
      .then(function () {
        feed.reset().then(() => {
          vm.onIntegrationChanged(feed, true);
        });
      })
      .catch((err) => {
        Toast.makeError(err);
      });
  };

  vm.deleteFeed = function (feed) {
    var modalOpts = {
      templateUrl: 'utility/modal-templates/modal-areyousure.jade',
      scopeData: {
        action: 'delete',
        itemType: 'feed',
        location: feed.name,
      },
      dismissable: { backButton: false, escape: true, backgroundClick: false }
    };

    new Modal(modalOpts)
      .show()
      .then(function () {
        Toast.deleteAndNotify(feed);
        vm.selectFeed();
      })
      .catch((err) => {
        Toast.makeError(err);
      });
  };

  vm.isSocialFeed = function isSocialFeed (feedDomain) {
    return _.contains(SOCIALSERVICES, feedDomain);
  };

  vm.filterFunction = function (value, index, array) {
    var filters = vm.filters;
    if (filters.name != null) {
      if (fuzzy.match(filters.name, value.name) === null) {
        return false;
      }
    }
    if (!filters.showShared && value._sharedWithMe) {
      return false;
    }
    if ((!filters.showShared || !filters.showHidden) && value.hidden) {
      return false;
    }
    return true;
  };

  function updateItemType (endpoint, oldVal) {
    var feed = vm.feed;
    if (!feed) {
      return;
    }

    if (endpoint) {
      feed.endpoint = endpoint.name;
      feed.itemType = endpoint.type;
      feed._type = feed._provider.types[feed.itemType];
      // Check we have set the value of "curated" correctly.
      if (!feed._endpoint.canBeLive || !feed._endpoint.canBeCurated) {
        if (feed._endpoint.canBeLive) vm.feed.curated = false;
        else vm.feed.curated = true;
      }
    } else {
      feed.itemType = null;
      feed._type = null;
    }

    if (endpoint === oldVal) {
      return;
    }

    // If the feed isn't modified we have reset with the server and want to
    // persist the returned value.
    if (feed.isModified()) {
      feed.query = null;
    }
  }

  function clearModifiers (newVal, oldVal) {
    if (newVal === oldVal) {
      return;
    }
    var feed = vm.feed;
    if (!vm.feed) {
      return;
    }
    feed.amendments = null;
  }

  var cancelFunctions = [];

  $scope.$watch('vm.feed', function (feed) {
    if (!feed) {
      return;
    }

    while (cancelFunctions.length) {
      (cancelFunctions.pop())();
    }

    // If we have a domain, a provider must have been selected
    if (feed.domain) {
      // filter our integrations when the feed changes.
      filterIntegrations();
      feed.updateEndpoint();
    }

    if (feed.searchResults.length > 0) {
      vm.pageManagement.accordionShown.searchFor = false;
    } else {
      vm.pageManagement.accordionShown.searchFor = true;
    }

    $timeout(function () {
      cancelFunctions.push($scope.$watch('vm.feed._endpoint', updateItemType));
      cancelFunctions.push($scope.$watch('vm.feed.itemType', clearModifiers));
    }, 0);

    // If vm.feed._endpoint hasn't been set and there is only one endpoint
    // available automatically selects it
    if (
      vm.feed._endpoint === null && vm.feed._provider.endpoints.length === 1
    ) {
      vm.feed._endpoint = vm.feed._provider.endpoints[0];
    }
  });


  // Watch for items being added to the selectedFeed
  $scope.$watch('vm.feed.items.length', function (newVal) {
    if (!newVal) {
      return;
    }

    if (newVal > 0) {
      if (!vm.pageManagement.accordionShown.feedItems) {
        vm.pageManagement.accordionShown.feedItems = true;
      }
    }
  });

  // Opens a modal to display a larger preview of images.
  vm.enlargedView = function (url) {
    var modalOpts = {
      templateUrl: 'utility/modal-templates/enlarged-view.jade',
      scopeData:   {
        url: url,
      },
    };

    new Modal(modalOpts).show();
  };

  vm.resetFilters = function () {
    vm.filters.name = '';
    vm.filters.ownedBy = '';
    vm.filters.tags = [];
    vm.filters.showShared = true;
    vm.filters.showHidden = false;
  };

})

.directive('feedMenuClasses', function (Feeds) {
  return {
    restrict: 'ACE',
    scope: {
      feed: '='
    },
    link: function (scope, element) {

      scope.$watch('feed.domain', function (newVal, oldVal) {
        // Check if a feed is defined & is local
        if (scope.feed && scope.feed.isLocal()) {
          // Set default icon
          element.addClass(Feeds.getIconForDomain(newVal));
        }
        if (!newVal) {
          return;
        }
        element
          .removeClass(Feeds.getIconForDomain(oldVal))
          .addClass(Feeds.getIconForDomain(newVal));
      });
    }
  };
})

.directive('feedItem', function ($templateCache, $compile, Normalise) {
  return {
    restrict: 'E',
    scope: {
      service: '=',
      item:    '=',
      shared:  '='
    },
    link: function (scope, element) {

      var SOCIAL_SERVICES = [
        'beekeeper',
        'facebook',
        'instagram',
        'pinterest',
        'twitter',
        'workplace',
        'yammer'
      ];

      var THUMB_LIKES = [
        'facebook',
        'workplace',
        'yammer'
      ];

      function compileDirective () {
        var service = scope.service;

        if (_.contains(SOCIAL_SERVICES, service)) {
          scope.item.data._normalised = Normalise.socialMessage(service, scope.item.data);
          service = 'social';
        } else if (service === 'centrica-sharepoint' || service === 'heathrow-sharepoint') {
          service = 'rss';
        } else if (service === 'server-monitoring') {
          service = 'powerbi';
          scope.item.data.displayName = scope.item.data.server;
        }

        // Get template from cache
        var template = $templateCache.get(
          'feeds/feed-item-templates/' + service + '.jade'
        );

        // If we couldn't find a template get the generic one instead.
        if (template === undefined) {
          template =
            $templateCache.get('feeds/feed-item-templates/generic.jade');
        }

        // Covert template to angular element.
        var $template = angular.element(template);

        // Empty the element of any previous content
        element.html('');

        // Apply template
        element.append($compile($template)(scope));

        scope.likeIcon = function likeIcon () {
          if (_.contains(THUMB_LIKES, scope.service)) {
            return 'entypo-thumbs-up';
          } else {
            return 'entypo-heart';
          }
        };
      }

      // Watch for changes to the provider.
      scope.$watch('item', function (newVal) {
        if (!newVal) { return; }
        compileDirective();
      });

    },
  };
})
.directive('feedItemDetailed', function ($templateCache, $compile, Normalise) {
  return {
    restrict: 'E',
    scope:    {
      service:         '=',
      item:            '=',
      approveFunction: '&',
      approveEnabled: '&',
      rejectFunction:  '&',
      shared: '='
    },
    controller: function ($scope, Modal) {
      // Opens a modal to display a larger preview of images.
      $scope.enlargedView = function (url) {
        var modalOpts = {
          templateUrl: 'utility/modal-templates/enlarged-view.jade',
          scopeData:   {
            url: url,
          },
        };

        new Modal(modalOpts).show();
      };
    },
    link: function (scope, element) {

      var SOCIAL_SERVICES = [
        'beekeeper',
        'facebook',
        'instagram',
        'pinterest',
        'twitter',
        'workplace',
        'yammer'
      ];

      var THUMB_LIKES = [
        'facebook',
        'workplace',
        'yammer'
      ];

      scope.likeIcon = function likeIcon () {
        if (_.contains(THUMB_LIKES, scope.service)) {
          return 'entypo-thumbs-up';
        } else {
          return 'entypo-heart';
        }
      };

      // Directive Compile Function
      function directiveCompile () {
        // Get template from cache
        var service = scope.service;

        if (_.contains(SOCIAL_SERVICES, service)) {
          scope.item.data._normalised = Normalise.socialMessage(service, scope.item.data);
          service = 'social';
        } else if (service === 'centrica-sharepoint' || service === 'heathrow-sharepoint') {
          service = 'rss';
        } else if (service === 'server-monitoring') {
          service = 'powerbi';
          scope.item.data.displayName = scope.item.data.server;
        }

        var template = $templateCache.get(
          'feeds/feed-item-templates/' + service + '-detailed.jade'
        );
        // If we couldn't find a template get the generic one instead.
        if (template === undefined) {
          template = $templateCache.get(
            'feeds/feed-item-templates/generic-detailed.jade'
          );
        }

        // Covert template to angular element.
        var $template = angular.element(template);

        // Empty the element of any previous content
        element.html('');

        // Apply template
        element.append($compile($template)(scope));
      }

      directiveCompile();

      // Watch for changes to the provider.
      scope.$watch('service', function (newVal) {
        if (!newVal) { return; }
        directiveCompile();
      });

      // Watch for changes to the provider.
      scope.$watch('item', function (newVal) {
        if (!newVal) { return; }
        directiveCompile();
      });
    },
  };
})

.component('weatherIcon', {
  bindings: {
    weatherCode: '@'
  },
  controller: function ($attrs, $templateCache, $element) {
    this.$onInit = function () {
      var weatherCodes = {
        extreme: 900,
        clouds: 801,
        clear: 800,
        atmosphere: 700,
        snow: 600,
        rain: 400,
        thunderstorm: 200
      };

      var weatherCode = parseInt(this.weatherCode);
      var tpl;

      if (weatherCode >= weatherCodes.extreme) {
        tpl = $templateCache.get(
          'feeds/weather-icons/animated/thunder.jade'
        );
      } else if (weatherCode >= weatherCodes.clouds) {
        // Weather group is clouds
        tpl = $templateCache.get(
          'feeds/weather-icons/animated/cloud.jade'
        );
      } else if (weatherCode >= weatherCodes.clear) {
        // Weather group is clear
        tpl = $templateCache.get(
          'feeds/weather-icons/animated/sun.jade'
        );
      } else if (weatherCode >= weatherCodes.atmosphere) {
        tpl = $templateCache.get(
          'feeds/weather-icons/animated/cloud.jade'
        );
      } else if (weatherCode >= weatherCodes.snow) {
        // Weather group is snow
        tpl = $templateCache.get(
          'feeds/weather-icons/animated/snow.jade'
        );
      } else if (weatherCode >= weatherCodes.rain) {
        // Weather group is drizzle or rain
        tpl = $templateCache.get(
          'feeds/weather-icons/animated/rain.jade'
        );
      } else if (weatherCode >= weatherCodes.thunderstorm) {
        // Weather group is Thunderstorm
        tpl = $templateCache.get(
          'feeds/weather-icons/animated/thunder.jade'
        );
      } else {
        // NOTE: Should never get here but just incase returned default icon
        tpl = $templateCache.get(
          'feeds/weather-icons/animated/cloud.jade'
        );
      }

      $element.html(tpl);
    };


  }
});
