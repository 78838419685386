angular.module('utcFunctions', [])
.factory('UtcFunctions', function utcFunctions () {
  return {
    // This function is used when displaying slide lifetime on the edit
    // slide page, and for scheduling checks made by the receiver.
    // Converts a slide's lifetime rules to local time.
    convertLifetimeToLocal: function convertLifetimeToLocal (slide) {
      if (
        slide.$localLifetime ||
        !slide.lifetime.rules ||
        slide.lifetime.rules.length === 0
      ) return;

      _.each(slide.lifetime.rules, function (rule) {
        _.each(['start', 'end'], function (key) {
          if (!rule[key]) {
            rule[key] = null;
          } else {
            // If schedule basis is GLOBAL - we need to account for the selected
            // timezone's UTC offset as well to get the correct UTC time.
            // If LOCAL - just need to add the local offset to get the UTC time.
            let localOffsetMins = moment(rule[key]).utcOffset();
            let globalOffsetMins = slide.lifetime.isGlobalBasis ?
              moment.tz(rule[key], slide.lifetime.timezone).utcOffset() : 0;
            let totalUtcOffsetMins = localOffsetMins - globalOffsetMins
            rule[key] =
              moment(rule[key]).subtract(totalUtcOffsetMins, 'minutes').valueOf();
          }
        });
      }, this);
      slide.$localLifetime = true;
    },
    // This function is used when saving slides, and to ensure all library
    // slides are reset before generating banner messages.
    // Converts a slide's lifetime rules to UTC.
    convertLifetimeToUTC: function convertLifetimeToUTC (slide) {
      if (
        !slide.$localLifetime ||
        !slide.lifetime.rules ||
        slide.lifetime.rules.length === 0
      ) return;

      _.each(slide.lifetime.rules, function (rule) {
        _.each(['start', 'end'], function (key) {
          if (!rule[key]) {
            rule[key] = null;
          } else {
            // If schedule basis is GLOBAL - we need to account for the selected
            // timezone's UTC offset as well to get the correct UTC time.
            // If LOCAL - just need to add the local offset to get the UTC time.
            let localOffsetMins = moment(rule[key]).utcOffset();
            let globalOffsetMins = slide.lifetime.isGlobalBasis ?
              moment.tz(rule[key], slide.lifetime.timezone).utcOffset() : 0;
            let totalUtcOffsetMins = localOffsetMins - globalOffsetMins
            rule[key] =
              moment(rule[key]).add(totalUtcOffsetMins, 'minutes').valueOf();
          }
        });
      }, this);
      slide.$localLifetime = false;
    }
  }
});
