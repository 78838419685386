angular.module('onboarding.pageOverviews', [])
.factory('PageOverviews', function (
  $log, OnboardingTexts, Breakpoints
) {

  /*
  `sidebar`:
  To make sure sidebar is open for a step add:
  {
    sidebar: {
      sidebarExpanded: true/false,
      secondarySidebarExpanded: true/false // this can be omitted if the page
    }                                      // doesn't have secondary sidebar
  }

  `hideOnMobile` or hideOnDesktop:
  When an element only displays on mobile or large screens use these properties
  to not add those elements to the intro when they are not visible or available.

  `delay`:
  To have any amount of delay between steps add delay: X to the step, where X is
  a number in milliseconds.

  `selector`:
  To select different elements on (xs, sm, md) and (lg, xl) screens set the step
  selector property to an object: {mobile: 'selector', desktop: 'selector'}

  `clone`:
  If an element can't be highlighted because of the stacking context (the
  element appears behind the helper layer), use `clone: true` when you define
  the step. This will clone the HTML element and append it into the helper layer
  and then will be removed on step change.

  `screenSmallerThan`:
  To make Cloning more specific use `screenSmallerThan: 'lg'`
  Assign a brakepoint as a value to the `screenSmallerThan` property if cloning
  only needed on mobile screens.

  `requiresElement`:
  When an element is required to highlight another (e.g. Don't show an empty
  list, only if it has at least one item in it.) use `requiresElement: selector`
  with a CSS selector of that element it depends on.

  `modal`:
  When a modal is highlighted use `modal: true`. On resize the intro will exit
  if the current step is a modal.

  `onlyShowOnceSteps`:
  Steps such as bulk-options and create-button are the same on multiple pages.
  If a step needs to be added throughout the interface but highlighting it once
  would be enough just use the same key for the step everywhere and add that key
  to the onlyShowOnceSteps array.

  `doubleCheckPosition`:
  If this is added to a step after the element is highlighted positioning
  functions will be called again to make sure -e.g. animations- will not lead to
  misplaced layers. Every elements position will be checked by default if it
  requires the sidebar to be opened.

  `addStylesTo`:
  Add this if you want add inline CSS styles to any element.
  Syntax is:
    addStylesTo: {
      // Name is just a key, with this added more than one element can be
      // selected to add inline styles to.
      name: {
        selector: String CSS selector of target element,
        styles: {
          camelCasePropName1: String property value,
          camelCasePropName2: String property value,
          ...
        }
      }
    }
  */

  let defaults = {
    options: {
      exitOnEsc: false,
      exitOnOverlayClick: false,
      disableInteraction: true,
      helperElementPadding: 10,
      hidePrev: true,
      hideNext: true,
      overlayOpacity: 0.5,
      scrollToElement: true,
      scrollPadding: '30px',
      showBullets: true,
      showStepNumbers: false,
    },
    friendlyNames: {
      mainOverview: 'Show me around this page'
    },
    onlyShowOnceSteps: [
      'bulk-options',
      'create-button',
    ],
  };

  let seenSteps = []; // Seen steps should only be displayed once.

  let helpers = {
    dashboard: {
      mainOverview: {
        steps: [
          { key: 'intro' },
          { key: 'overview-button', selector: '.page-overview-button' },
          { key: 'profile-image',
            selector: '.gravatar',
            sidebar: {
              sidebarExpanded: true,
            } },
          { key: 'account-name',
            selector: '.profile-block--company',
            sidebar: {
              sidebarExpanded: true,
            } },
          { key: 'logout-button',
            selector: '.profile-block--logout',
            sidebar: {
              sidebarExpanded: true,
            } },
          { key: 'user-preferences',
            selector: '.profile-block--user-settings',
            sidebar: {
              sidebarExpanded: true,
            } },
          { key: 'switch-account',
            selector: '.onboarding-switch-account',
            sidebar: {
              sidebarExpanded: true,
            } },
          {
            key: 'switched-account',
            selector: '.profile-block--company__delegate',
            sidebar: {
              sidebarExpanded: true,
            } },
          { key: 'account-activity', selector: '.activity--card' },
          { key: 'change-log', selector: '.onboarding-change-log' },
          { key: 'login-history', selector: '.onboarding-login-history' },
          { key: 'account-health', selector: '.health-status' },
          { key: 'approvals-center', selector: '.slide-approvals' },
        ],
      },
    },
    devices: {
      // friendlyNames: {
      //   mainOverview: 'Screens page overview'
      // },
      mainOverview: {
        steps: [
          { key: 'intro' },
          { key: 'register-button', selector: '.register-screen-button' },
          {
            key: 'screen-list',
            selector: '.device-list',
          },
          { key: 'screen-list-entry', selector: '.device-list--item' },
          {
            key: 'screen-details',
            selector: '.device-info--info-card',
            delay: 350,
            hideOnMobile: true,
          },
          {
            key: 'screen-comments',
            selector: '.device-info--comment-card',
            delay: 350,
            hideOnMobile: true,
          },
          {
            key: 'screen-bulk-actions',
            selector: '.device-info--bulk-actions',
            delay: 350,
            hideOnMobile: true,
          },
          { key: 'screen-filters', selector: '.search-form' },
          { key: 'save-search', selector: '.onboarding-save-search-prompt' },
          {
            key: 'saved-searches',
            selector: '.onboarding-saved-searches',
            clone: true,
            screenSmallerThan: 'lg',
            sidebar: {
              sidebarExpanded: true,
              secondarySidebarExpanded: true
            }
          },
          {
            key: 'online-guage',
            selector: 'pxn-gauge-chart',
            clone: true,
            screenSmallerThan: 'lg',
            sidebar: {
              sidebarExpanded: true,
              secondarySidebarExpanded: true
            }
          },
        ]
      },
    },
    'template-browser': {
      mainOverview: {
        steps: [
          { key: 'intro' },
          { key: 'carousel', selector: '.template-browser-content' },
          { key: 'details', selector: '.template-info' },
          { key: 'details-info', selector: '.onboarding-details-info' },
          { key: 'actions-button', selector: '.onboarding-actions-button' },
          { key: 'remove-button', selector: '.remove-from-account' },
          { key: 'template-filters', selector: '.filters-card' },
          { key: 'set-all-seen', selector: '.set-all-seen' },
        ],
      }
    },
    playlists: {
      mainOverview: {
        steps: [
          { key: 'intro' },
          { key: 'create-button', selector: '.create-playlist-button' },
          {
            key: 'interleave',
            selector: '.modal--container .onboarding-interleave label',
            clone: true,
            hideOnMobile: true,
            delay: 350,
            addStylesTo: {
              switch: {
                selector: '.checkbox--switch',
                styles: {
                  alignItems: 'center',
                  backgroundColor: '#eee',
                  borderWidth: '0',
                  borderLeftWidth: '1px'
                }
              }
            }
          },
          { key: 'playlist-list', selector: '.playlist.card' },
          { key: 'playlist-filters', selector: '.playlist-helper--filters' },
          { key: 'bulk-options', selector: '.bulk-option-button--playlists' },
          { key: 'playlist-help', selector: '.playlist-helper--about' },
        ],
      }
    },
    channels: {
      mainOverview: {
        steps: [
          { key: 'intro' },
          { key: 'create-button', selector: '.create-channel-button' },
          { key: 'channel-list', selector: '.channel.card' },
          { key: 'channel-filters', selector: '.channel-helper--filters' },
          { key: 'bulk-options', selector: '.bulk-option-button--channels' },
          { key: 'channel-help', selector: '.channel-helper--about' },
        ],
      }
    },
    slides: {
      mainOverview: {
        steps: [
          { key: 'intro' },
          { key: 'create-button', selector: '.create-slide-button' },
          {
            key: 'slide-list',
            selector: '.slides-list',
            requiresElement: '.slide.card'
          },
          { key: 'slide-filters', selector: '.slide-helper--filters' },
          { key: 'bulk-options', selector: '.bulk-option-button--slides' },
          { key: 'slide-help', selector: '.slide-helper--about' },
        ],
      }
    },
    'edit-slide': {
      mainOverview: {
        steps: [
          { key: 'intro' },
          { key: 'slide-details', selector: '.onboarding-slide-details' },
          {
            key: 'slide-localisation',
            selector: '.onboarding-slide-localisation'
          },
          {
            key: 'slide-manage-locales',
            selector: '.modal__localeManager',
            modal: true
          },
          {
            key: 'slide-manage-locales-behaviour',
            selector: '.onboarding-manage-locales-behaviour',
            clone: true,
            delay: 350
          },
          {
            key: 'slide-reference-locale',
            selector: '.onboarding-reference-locale',
            delay: 300
          },
          {
            key: 'slide-locale-status',
            selector: '.onboarding-slide-locale-status',
            delay: 300
          },
          { key: 'preview', selector: '.onboarding-edit-slide-preview' },
          {
            key: 'layout-edit-button',
            selector: '.onboarding-layout-edit-button'
          },
          {
            key: 'layout-edit-mode',
            requiresElement: '.onboarding-layout-editing-mode',
            selector: '.slide-edit--preview'
          },
          {
            key: 'layout-revert-button',
            selector: '.onboarding-revert-layout-button'
          },
          {
            key: 'slide-lifetime',
            selector: '.onboarding-slide-lifetime',
            requiresElement: '.onboarding-slide-lifetime pxn-date-time-input',
            delay: 300
          },
          {
            key: 'display-periods',
            selector: '.onboarding-display-periods',
            requiresElement: '.onboarding-display-periods .slide-recurrence',
            delay: 300 // Due to unsquash animation
          },
          {
            key: 'slide-content',
            selector: '.onboarding-content-fields',
            delay: 300
          },
          {
            key: 'repositionable-image',
            selector: 'pxn-content-field[field-name*=image]',
            delay: 300
          },
          {
            key: 'slide-usage',
            selector: '.onboarding-slide-usage'
          },
        ],
      }
    },
    feeds: {
      friendlyNames: {
        mainOverview: 'Show me around this page'
      },
      mainOverview: {
        steps: [
          { key: 'intro' },
          { key: 'manage-button', selector: '.manage-providers' },
          { key: 'create-button', selector: '.create-feed-button' },
          {
            key: 'feed-list',
            selector: '.onboarding-feeds-list',
            clone: true,
            screenSmallerThan: 'lg',
            sidebar: {
              sidebarExpanded: true,
              secondarySidebarExpanded: true
            }
          },
          {
            key: 'feed-filters',
            selector: '.filter-saved-feeds-button',
            clone: true,
            screenSmallerThan: 'lg',
            sidebar: {
              sidebarExpanded: true,
              secondarySidebarExpanded: true
            }
          },
          { key: 'feed-help', selector: '.about-feeds' },
          {
            key: 'edit-view',
            selector: '.feeds-container',
            requiresElement: '.feeds-left',
            sidebar: {
              sidebarExpanded: false,
              secondarySidebarExpanded: false
            }
          },
          { key: 'edit-view-left', selector: '.feeds-left' },
          { key: 'edit-view-properties', selector: '.feeds-left--feed-meta' },
          { key: 'edit-view-curated', selector: '.feeds-right--feed' },
          { key: 'edit-view-right', selector: '.feeds-right' },
          { key: 'edit-view-search', selector: '.feeds-left--search' },
          { key: 'edit-view-new', selector: '.feeds-right--results' },
          { key: 'edit-view-filtered', selector: '.feeds-right--filtered' },
          { key: 'edit-view-rejected', selector: '.feeds-right--rejected' },
        ],
      }
    },
    media: {
      mainOverview: {
        steps: [
          { key: 'intro' },
          { key: 'media-filters', selector: '.media-library--filters' },
          { key: 'media-sorting', selector: '.onboarding-media-sorting' },
          {
            key: 'edit-modal',
            selector: '.modal--container',
            modal: true,
            hideOnMobile: true,
          },
        ],
      }
    },
    settings: {
      mainOverview: {
        steps: [
          { key: 'intro' },
          {
            key: 'general-settings',
            selector: '.onboarding-general-settings-left-column' },
          {
            key: 'slideshow-preferences',
            selector: '.onboarding-general-settings-right-column'
          },
          { key: 'connect-accounts', selector: '.external-account--buttons' },
          {
            key: 'connected-accounts',
            selector: '.onboarding-connected-accounts'
          },
        ],
      }
    },
    users: {
      mainOverview: {
        steps: [
          { key: 'intro' },
          { key: 'user-list', selector: '.user-list--card' },
          {
            key: 'user-details',
            selector: '.user-detail--info > .card',
            delay: 350,
          },
          { key: 'create-user-button', selector: '.create-user-button' },
          { key: 'roles', selector: '.onboarding-role-management' },
        ],
      }
    },
    localisation: {
      mainOverview: {
        steps: [
          { key: 'intro' },
          { key: 'default-locale', selector: '.default-locale' },
          { key: 'locale-buttons', selector: '.item--buttons' },
          { key: 'locale-drag', selector: '[ui-tree-handle]' },
          { key: 'locale-search', selector: '.onboarding-locale-search' }
        ],
      }
    },
    'group-manager': {
      mainOverview: {
        steps: [
          { key: 'intro' },
          { key: 'create-button', selector: '.create-group-button' },
          {
            key: 'group-list',
            selector: '.onboarding-group-list',
            clone: true,
            screenSmallerThan: 'lg',
            doubleCheckPosition: true,
            sidebar: {
              sidebarExpanded: true,
              secondarySidebarExpanded: true
            },
          },
          { key: 'group-help', selector: '.about--card' },
          {
            key: 'edit-group',
            selector: '.manage-group--card',
            sidebar: {
              sidebarExpanded: false,
              secondarySidebarExpanded: false
            },
          },
        ],
      }
    },
    takeovers: {
      mainOverview: {
        steps: [
          { key: 'intro', accordion: true },
          {
            key: 'create-button',
            selector: '.onboarding-new-takeover',
            accordion: true
          },
          {
            key: 'quick-content',
            selector: '.onboarding-quick-content',
            clone: true,
            modal: true,
            hideOnMobile: true,
            elementOnModal: true
          },
          {
            key: 'takeover-list',
            selector: '.onboarding-first-takeover-card',
            accordion: true
          },
          {
            key: 'takeover-slide-content',
            selector: '.onboarding-takeover-slide-content',
            accordion: true
          },
          {
            key: 'takeover-target-duration',
            selector: '.tt--targets',
            accordion: true
          },
          {
            key: 'takeover-reset-button',
            selector: '.onboarding-takeover-reset-button',
            accordion: true
          },
          {
            key: 'takeover-trigger-button',
            selector: '.onboarding-takeover-trigger-button',
            accordion: true
          },
          {
            key: 'takeover-options-button',
            selector: '.onboarding-takeover-options-button .button',
            accordion: true
          },
          { key: 'active-takeovers', selector: '.active-takeovers' },
          {
            key: 'takeover-webhook',
            selector: '.modal__takeoverWebhook',
            modal: true,
            hideOnMobile: true
          },
        ],
      }
    },
    tickers: {
      mainOverview: {
        steps: [
          { key: 'intro' },
          {
            key: 'create-button',
            selector: '.new-resource-btn'
          },
          {
            key: 'display-type',
            selector: '.onboarding--ticker-display-type',
          },
          {
            key: 'continuous-display',
            selector: '.onboarding--ticker-continuous-display',
          },
          {
            key: 'reorder-messages',
            selector: '.cursor--grab',
          },
        ]
      }
    }
  };

  // Adding steps to intro object.
  function addStepsToIntro (intro, step, element, text) {
    intro.addSteps([ {
      element: element,
      intro: text,
      key: step.key,
      delay: step.delay || 0, // milliseconds
      selector: step.selector,
      sidebar: step.sidebar || false,
      accordion: step.accordion || false,
      cloneElement: step.clone || false,
      screenSmallerThan: step.screenSmallerThan || false,
      hideOnMobile: step.hideOnMobile || false,
      hideOnDesktop: step.hideOnDesktop || false,
      isModal: step.modal || false,
      elementOnModal: step.elementOnModal || false,
      doubleCheckPosition: step.doubleCheckPosition || step.sidebar || false,
      addStylesTo: step.addStylesTo || null,
    } ]);
  }

  function stepNotCompleted (page, stepKey, guides) {
    // If the user seen this steps key and it should only be displayed once.
    if (seenSteps.indexOf(stepKey) !== -1) return false;
    for (let guide in guides) {
      if (_.contains(guides[guide].name, page)) {
        if (_.contains(guides[guide].progress.completed, stepKey)) {
          return false;
        }
      }
    }
    return true;
  }

  return {
    setStepSeen: function (key) {
      seenSteps.push(key);
    },

    getOnlyShowOnceSteps: function () {
      return defaults.onlyShowOnceSteps;
    },

    getPageOverview: function (overview, pageName, guides, forced = false) {
      let stepsCount = 0;
      let helper = helpers;
      if (typeof helper[pageName] !== 'object') {
        // Page doesn't have an overview specified yet.
        $log.debug(`[${pageName}] page has no overview`);
        return null;
      }
      helper = helper[pageName];
      if (typeof helper[overview] !== 'object') {
        // Overview name doesn't exist on page.
        $log.debug(`[${pageName}] page has no overview: [${overview}]`);
        return null;
      }
      helper = helper[overview];

      let intro = introJs();
      intro.firstStepSidebarState = false;

      if (helper.steps) {
        let modalContainer =
          document.querySelector('flare-modal-container.modal');
        // Adding steps to the intro
        _.each(helper.steps, function (step) { // eslint-disable-line complexity
          // hideOnMobile/hideOnDesktop properties hide steps on relevant
          // screen sizes.
          if (step.hideOnMobile) {
            if (Breakpoints.smallerThan('lg')) return;
          }
          if (step.hideOnDesktop) {
            if (!Breakpoints.smallerThan('lg')) return;
          }

          if (step.requiresElement) {
            if (!document.querySelector(step.requiresElement)) return;
          }

          let text, element;
          let path = [pageName, overview, step.key];
          path = path.join('.');
          if (step.key && OnboardingTexts.getText(path) !== 'MISSING TEXT') {
            text = OnboardingTexts.getText(path);
          } else {
            // If we don't have text for the step from onboarding-texts, exit
            // early and logs which step hasn't got a text.
            $log.debug(
              `CHECK STEPS FOR OVERVIEW: ${pageName}.${overview}.${step.key}`
            );
            return false; // eslint-disable-line consistent-return
          }

          let notCompleted = true;
          if (!forced) {
            notCompleted =
              stepNotCompleted(pageName, step.key, guides);
          }
          if (!notCompleted) return; // not forced & this step is already seen

          // If we have an element to highlight for this step, select it here.
          if (step.selector && angular.isObject(step.selector)) {
            if (Breakpoints.smallerThan('lg')) {
              // Select mobile version.
              modalContainer.children.length ?
                // If we have a modal displayed only select those elements to
                // highlight that are on the modal.
                element = modalContainer.querySelector(step.selector.mobile) :
                element = document.querySelector(step.selector.mobile);
            } else {
              // Select desktop version.
              modalContainer.children.length ?
                element = modalContainer.querySelector(step.selector.desktop) :
                element = document.querySelector(step.selector.desktop);
            }
          } else if (step.selector && typeof step.selector === 'string') {
            modalContainer.children.length ?
              element = modalContainer.querySelector(step.selector) :
              element = document.querySelector(step.selector);
            if (!element) {
              $log.debug(`No element returned by selector: ${step.selector}`);
              return;
            }
          }

          // Set flag if the sidebar have to be open for the first step.
          if (step.sidebar && stepsCount === 0) {
            intro.firstStepSidebarState = step.sidebar;
          }
          // Set flag if the sidebar have to be open for the first step.
          if (step.delay && stepsCount === 0) {
            intro.firstStepDelay = step.delay;
          }
          // Text has to be specified for a step, if it wasn't logs a message.
          if (element) {
            addStepsToIntro(intro, step, element, text);
            stepsCount++;
          } else if (
            !element && !step.selector
          ) { // this is a 'general' step not corresponding to an element.
            addStepsToIntro(intro, step, element, text);
            stepsCount++;
          } else { // This element is not visible yet, do nothing.
            $log.debug(
              `UNABLE TO ADD STEP: [${step.key}], TO INTRO:` +
              `[${pageName}.${overview}]`
            );
          }
          if (step.clone && stepsCount === 1) intro.cloneAtFirstStep = true;
          if (step.accordion && stepsCount === 1) {
            intro.openFirstAccordion = true;
          }
        });
      } else {
        // We didn't have steps for our intro.
        $log.debug(`NO STEPS PROVIDED FOR OVERVIEW: ${overview}`);
      }

      // If we have steps to show return intro object.
      if (stepsCount) {
        // Set options
        helper.options = helper.options || {};
        intro.setOptions(_.defaults(helper.options, defaults.options));
        // Store page and overview properties on intro obj as a reference.
        intro.page = pageName;
        intro.overview = overview;

        return intro;
      } else { return null; }
    },

    getOverviewNames: function (stateName) {
      // Null if no overviews are defined for state.
      if (!helpers.hasOwnProperty(stateName)) return null;
      return helpers[stateName].friendlyNames ?
        _.defaults(helpers[stateName].friendlyNames, defaults.friendlyNames) :
        defaults.friendlyNames;
    },
  };
})
.directive('pageOverviewButton', function (CtrlOf) {
  return {
    restrict: "E",
    templateUrl: "onboarding/page-overview-button.jade",
    scope: {},
    link: function (scope, element, attrs) {
      // Put UI controller on scope to access guides for pages
      function setCtrl () {
        scope.uiCtrl = CtrlOf.getUiCtrl();
      }
      setCtrl();
    },
  };
});
