angular.module(
  'flare.receiver.orientation',
  []
)
// Orientation compares the devices width and height to determine which
// orientation the screen is currently. Orientation also exposes the
// getOrientation method which returns a string representation of the current
// orientation. Orientation also exposes the getScopeData method, this will,
// when passed the slides content, return an object which should be assigned
// to scope.content to ensure that data for the correct orientation is
// displayed.
.factory('Orientation', function ($window) {
  function getOrientation (element) {
    var deviceHeight;
    var deviceWidth;
    if (element) {
      deviceHeight = element[0].getBoundingClientRect().height;
      deviceWidth = element[0].getBoundingClientRect().width;
    } else {
      deviceHeight = $window.innerHeight;
      deviceWidth = $window.innerWidth;
    }
    if (deviceHeight > deviceWidth) {
      return 'portrait';
    } else {
      return 'landscape';
    }
  }

  function getScopeData (allContent, element, locale) {
    return getScopeDataForOrientation(
      allContent, getOrientation(element), locale
    );
  }

  function getScopeDataForOrientation (allContent, selector, locale) {
    if (!allContent[locale]) return null;
    return allContent[locale].data[selector];
  }

  // This function removes anyOrientation, based on splitstates which should
  // be used before saving a slide.
  function getContentDataToSave (
    contentDataWithAnyOrientation, contentFields, splitStates
  ) {
    const contentIn = contentDataWithAnyOrientation || {};
    if (!contentIn.hasOwnProperty('anyOrientation')) return contentIn;

    // `contentDataWithAnyOrientation` is the `allContent` object from slide
    // creation which may or may not have had landscape/portrait
    // objects created based on the type of content required by the slide.
    if (!contentIn.hasOwnProperty('landscape')) {
      contentIn.landscape = {};
    }
    if (!contentIn.hasOwnProperty('portrait')) {
      contentIn.portrait = {};
    }

    const contentData = {
      portrait: {},
      landscape: {}
    };
    // Some props (e.g. p_) will not be on anyO.
    let allKeys =
      Object.keys(contentIn.anyOrientation || {})
      .concat(Object.keys(contentIn.landscape || {}))
      .concat(Object.keys(contentIn.portrait || {}));

    allKeys = _.uniq(allKeys);

    _.each(allKeys, function (key) {
      if (isFieldOrientationSpecific(splitStates, contentFields[key], key)) {
        contentData.landscape[key] = contentIn.landscape[key];
        contentData.portrait[key] = contentIn.portrait[key];
      } else {
        contentData.landscape[key] = contentIn.anyOrientation[key];
        contentData.portrait[key] = contentIn.anyOrientation[key];
      }
    });

    return contentData;
  }

  function isFieldOrientationSpecific (splitStates, contentField, fieldName) {
    if (isFieldAlwaysOrientationSpecific(contentField, fieldName)) {
      return true;
    }
    const splitName = fieldName.split('_');
    if (splitName.length === 1) {
      return splitStates[fieldName];
    } else {
      return splitStates[splitName[1]];
    }
  }

  function isFieldAlwaysOrientationSpecific (contentField, fieldName) {
    if (contentField) {
      return contentField.type === 'hidden';
    }
    // Position is effectively a hidden field, so it's always specific.
    if (_.startsWith(fieldName, 'p_')) {
      return true;
    }
    // FontSize is effectively a hidden field, so it's always specific.
    if (_.startsWith(fieldName, 'f_')) {
      return true;
    }
    return false;
  }

  function getInitialSplitStates (allContent) {
    const splitStates = {};
    if (allContent) {
      // Loop through the enabled locales
      _.each(allContent, (locale, localeId)=> {
        if (allContent[localeId].data) {
          // Checks landscape vs portrait values and sets SS if !same.
          _.each(allContent[localeId].data.landscape, (val, key)=>{
            // Init as false and update later if split.
            splitStates[key] = false;
            const portraitVal = allContent[localeId].data.portrait[key]
            if (portraitVal !== val) {
              splitStates[key] = true;
            }
          });
        }
      });
    }
    return splitStates;
  }

  return {
    current: getOrientation(),
    getOrientation: getOrientation,
    getScopeData: getScopeData,
    getScopeDataForOrientation: getScopeDataForOrientation,
    getContentDataToSave: getContentDataToSave,
    alwaysSpecific: isFieldAlwaysOrientationSpecific,
    isSpecific: isFieldOrientationSpecific,
    getInitialSplitStates: getInitialSplitStates
  };
})


// This directive sets meta viewport tags for the benefit of our android devices
// which have screen density quirks.
// For the electron based clients, this isn't required.

.directive('orientationMeta', function (Player, $log, $injector) {
  return {
    restrict: 'A',
    link: function (scope, element, attributes, controller) {
      if (!Player.isAndroid) return;

      var Orientation = $injector.get('Orientation');
      var orientation = {};
      if (Orientation.getOrientation() === 'portrait') {
        orientation.width = 1080;
        orientation.height = 1920;
      } else {
        orientation.width = 1920;
        orientation.height = 1080;
      }

      var newElement =
        angular.element(
          '<meta name="viewport" content="height=' +
          orientation.height + ', width=' +
          orientation.width + ', user-scalable=no" >'
        );
      element.prepend(newElement);
    }
  };
});
