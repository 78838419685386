angular.module('pxn-validator', [])
.factory('Validator', function Validator ($log) {

  /**
   * Checks whether 2 arrays have same objects in the same order.
   *
   * @param {array} value1
   * @param {array} value2
   * @returns {boolean}
   */
  function equalsArray (value1, value2) {
    if (!value1 || !value2) {
      $log.warn('You are trying to compare undefined arrays: ', value1, value2);
      return false;
    }
    if (value1.length !== value2.length) {
      return false;
    }
    for (var i = 0; i < value1.length; i++) {
      if (value1[i] !== value2[i]) {
        return false;
      }
    }
    return true;
  }

  /**
   * Check whether a string is empty
   *
   * @param {string} value
   * @returns
   */
  function isEmptyString (value) {
    if (typeof value !== 'string') {
      return false;
    }
    if (value.length !== 0) {
      return false;
    }
    return true;
  }

  function isImageUrl (value) {
    var urlRegex = /^https?:\/\/.+\/.+(?:jpe?g|png|[gt]if?f|bmp)/i;
    return !!urlRegex.exec(value);
  }

  function isVideoUrl (value) {
    var urlRegex = /^https?:\/\/.+\/.+(?:webm|ogv|mp4|avi|mov|wmv)/i;
    return !!urlRegex.exec(value);
  }

  function isInterpolation (value) {
    var interpolationRegex = /{{/;
    return !!interpolationRegex.exec(value);
  }

  return {
    equalsArray: equalsArray,
    isEmptyString: isEmptyString,
    isImageUrl: isImageUrl,
    isVideoUrl: isVideoUrl,
    isInterpolation: isInterpolation
  };
});
