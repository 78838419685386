angular.module('sender')

/**
 * pxnCalloutBanner
 *
 * This component creates a simple callout banner with an icon, message and
 * action button.
 *
 * The component receives its config object as an attribute.
 *
 * Example config object: {
 *   type: 'info',                // Currently supported: 'info' and 'warning'
 *   message: 'Callout message',  // Message to display
 *   noIcon: true                 // If TRUE icon will be omitted for message
 *   actionButton: {                             // Config for action button
 *     text: 'Button text',                      // Text
 *     background: 'positive-1',                 // Background
 *     iconClass: 'entypo-check',                // Icon
 *     clickFn: () => clickHandlerFunction(),    // click fn for `ngClick`
 *     isDisabled: () => !resource.isModified()  // expression for `ngDisabled`
 *   }
 * }
 *
 * The action button can be omitted by simply not providing it in the config
 * object.
 *
 * The message of the callout can be passed through the config object, see
 * example above OR the children of the callout is transcluded as the message
 * to be able to create more complex messages.
 *
 * Example transcluded message:
 *   pxn-callout-banner(
 *     ng-if="vm.localisationData.inheritedFrom"
 *     config="{ type: 'warning', noIcon: true }"
 *   )
 *     span Localisation data is being inherited from the <b>{{XY}}</b> account.
 *
 * @Author: Laszlo Fulop
 */
.component('pxnCalloutBanner', {
  bindings: {
    config: '='
  },
  transclude: true,
  templateUrl: 'utility/pxnCalloutBanner.jade',
  controllerAs: '$ctrl',
  controller: function ($scope) {
    let $ctrl = this;

    $ctrl.$onInit = function init () {
      if (!$ctrl.config) return;
      $ctrl.noIcon = !!$ctrl.config.noIcon;
      if (!$ctrl.config || !$ctrl.config.actionButton) return;
      $ctrl.iconClass = $ctrl.config.actionButton.iconClass;
    };

    $scope.$watch('$ctrl.config', function (n) {
      if (n) $ctrl.$onInit();
    }, true);
  }
});