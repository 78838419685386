angular.module('flare.integrations', [])

.factory('Integrations',
  function IntegrationsFactory (GenericResource, ResourceItem, ConfigURLs) {
    function Integration () {
      ResourceItem.apply(this, arguments);
    }
    Integration.prototype = Object.create(ResourceItem.prototype);
    var Integrations = new GenericResource(
      ConfigURLs.integrations, Integration
    );
    return Integrations;
  }
)

.factory('Providers',
  function ($http, $log, GenericResource, ResourceItem, ConfigURLs) {

    function Provider () {
      ResourceItem.apply(this, arguments);
      // Authentication is *required* if every endpoint needs auth
      this._authRequired = _.every(this.endpoints, (e)=> e.authentication);
      // Authentication is *optional* if any endpoint needs auth
      this._authOptional =
        !this._authRequired && _.any(this.endpoints, (e)=> e.authentication);
    }

    Provider.prototype = Object.create(ResourceItem.prototype);
    Provider.prototype._locals = ['_authRequired', '_authOptional'];

    Provider.prototype.makeRequest = function (searchParams) {
      var url = ConfigURLs.providerRequest(searchParams);
      return $http.post(url, searchParams).then(function (response) {
        return response.data;
      })
      .catch(function (err) {
        $log.error(err);
      });
    };

    Provider.prototype.getAuthUrl = function (redirect) {
      return ConfigURLs.authProvider(this, redirect);
    };

    var Providers = new GenericResource(ConfigURLs.providers, Provider);

    Providers.getProvisionedProvidersForAccount = (providers, account) => {
      if (account.name === 'Pixelnebula') return providers;
      let provisionedProviders = [];
      _.each(providers, (provider) => {
        if (_.includes(account.providers, provider._id)) {
          provisionedProviders.push(provider);
        }
      });
      return provisionedProviders;
    };

    return Providers;
  }
)

.component('integration', {
  bindings: {
    integration: '<',
    provider: '<',
  },
  templateUrl: 'settings/templates/integration.jade',
  controller: function (
    $attrs,
    $window,
    $http,
    $rootScope,
    Toast,
    Feeds,
    Providers,
    Modal,
    Integrations
  ) {
    var $ctrl = this;

    $ctrl.$onInit = function init () {
      $ctrl.isCreateButton = !!$attrs.provider;

      if ($ctrl.integration) {
        $ctrl.iconClass = Feeds.getIconForDomain($ctrl.integration.provider);
        Providers.where(
          { domain: $ctrl.integration.provider },
           true
        ).then(function (provider) {
          $ctrl.provider = provider[0];
          $ctrl.detail = {
            name: $ctrl.provider.name,
            username: $ctrl.integration.name,
          };
        });

        $ctrl.reconnectIntegration =
          function reconnectIntegration () {
            let url = $ctrl.provider.getAuthUrl('/settings',);
            url += '&integration=' +  $ctrl.integration._id;
            $window.open(url, '_self');
          };

        $ctrl.deleteIntegration = function () {
          new Modal({
            templateUrl: 'utility/modal-templates/modal-remove-integration.jade',
            scopeData: {
              provider: $ctrl.detail.name,
              integrationName: $ctrl.detail.username
            }
          })
            .show()
            .then(function () {
              Feeds.clearCache();
              return $ctrl.integration.delete()
            })
            .then(function () {
              $rootScope.$broadcast('update-nav-decorators');
            })
            .catch((err) => {
              Toast.makeError(err);
            });
        };

      } else if ($ctrl.provider) {
        // Do create things here.
        $ctrl.iconClass = Feeds.getIconForDomain($ctrl.provider.domain);
        $ctrl.detail = {
          name: $ctrl.provider.domain,
          username: $ctrl.provider.name,
        };
        if ($ctrl.provider.manualAuth) {
          // This integration requires manual authentication
          let { fields, helpText } = $ctrl.provider.manualAuth;

          $ctrl.createIntegration = function () {

            let authModal = new Modal({
              templateUrl: 'utility/modal-templates/manualAuthIntegration.jade',
              scopeData: {
                name: $ctrl.provider.name,
                title: 'Integration details',
                message: helpText,
                fields,
                loading: false,
                authDetails: {},
                positiveButton: 'Done',
                negativeButton: 'Cancel'
              }
            });

            authModal.setScopeData({
              checkAuth: $ctrl.checkAuth.bind(null, $ctrl.provider, authModal),
            });

            authModal
              .show()
              .then(function (auth) {
                Toast.makeSuccess(
                  `${$ctrl.provider.name} integration created succesfully`
                );
                Integrations.create(auth);
              })
              .catch((err) => {
                Toast.makeError(err);
              });
          };

        } else {
          $ctrl.createIntegration = function (redirect) {
            $window.open($ctrl.provider.getAuthUrl(redirect), '_self');
          };
        }

      }

    };

    $ctrl.checkAuth = function checkAuth (provider, modal, auth) {
      modal.setScopeData({ loading: true });

      $http({
        url: provider.manualAuth.callback,
        method: 'GET',
        params: auth
      })
      .then((res)=> { modal.resolve(res.data); })
      .catch((e)=> {
        Toast.makeError(e);
        modal.setScopeData({
          loading: false
        });
      });
    };
  },
});
