angular.module('onboarding', ['ui.router'])

.factory('Sidebar', function ($rootScope, $timeout, CtrlOf, Breakpoints) {
  let uiCtrl;

  /**
   * Sets the sidebar and secondary sidebar state.
   *
   * @param {Object} settings - The state of the sidebars defined in a js obj,
   * if TRUE hide both sidebar and secondary sidebar.
   * @param {Boolean} triggerDigest - Boolean to decide whether to trigger a
   * digest after the sidebar update.
   * @returns {void}
   */
  function setSidebar (settings, triggerDigest = true) {
    if (!uiCtrl) uiCtrl = CtrlOf.getUiCtrl();
    if (settings === true) {
      $rootScope.$emit('hide-sidebar'); // Hide sidebars
    } else if (angular.isObject(settings)) {
      uiCtrl.toggleSidebar(false, settings); // Set sidebars to...
    }
    if (Breakpoints.smallerThan('lg') && triggerDigest) {
      $rootScope.$digest(); // Update scope variables
    }
  }

  return {
    set: setSidebar
  };
})

.factory('CtrlOf', function ($rootScope) {
  let uiCtrl, devicesCtrl;

  // Store a reference to uiCtrl
  $rootScope.$on('getCtrlOf:uiCtrl', function (e, controller) {
    uiCtrl = controller;
  });

  $rootScope.$on('getCtrlOf:device-controller', function (e, controller) {
    devicesCtrl = controller;
  });

  /**
   * Returns a reference for uiCtrl's scope.
   *
   * @returns {Object} - uiCtrl scope.
   */
  function getUiCtrl () {
    return uiCtrl;
  }

  function getDevicesCtrl () {
    return devicesCtrl;
  }

  return {
    getUiCtrl: getUiCtrl,
    getDevicesCtrl: getDevicesCtrl,
  };
})

.factory('Onboarding', function (
  $rootScope, $http, $state, $localForage, $log, $timeout, $q,
  PageOverviews, Session, ConfigURLs, Toast, Breakpoints, CtrlOf, Sidebar
) {
  let accordionState;
  let activeLoading = 0;
  let guides = {};
  let runningGuide = {};
  let onlyShowOnceSteps;
  let guidesProgressReceived = false;
  let guidesProgressRequested = false;
  const SIDEBAR_TOGGLE_TIME = 300;
  const ACCORDION_OPEN_DELAY = 350;

  getAccordionStates();

  /**
   * Exits from the current running guide.
   *
   * @returns {Undefined} - Nothing is returned.
   */
  function exitIntro () {
    if (Object.keys(runningGuide).length) runningGuide.exit();
  }

  // On state change start exit the running guide if there is any.
  $rootScope.$on('$stateChangeStart', function () {
    exitIntro();
  });

  // When navigating away exit the running guide if there is any.
  window.onbeforeunload = function () {
    exitIntro();
  };

  $rootScope.$on(
    'onBoarding:triggerOverviewResourceAvailable',
    triggerOverviewResourceAvailable
  );

  /**
   * Updates activeLoading variable which holds the number of active
   * pxn-loading components on the current page.
   *
   * @param {Boolean} register - True if pxn-loading has been added to the page
   * False if removed.
   * @returns {Undefined} - Nothing is returned.
   */
  function updateCount (register) {
    register ? activeLoading++ : activeLoading--;
  }

  /**
   * Returns the number of active pxn-loading components.
   *
   * @returns {Number} - Pxn-loading count.
   */
  function activeCount () {
    return activeLoading;
  }

  /**
   * Resets activeLoading variable.
   *
   * @returns {Undefined} - Nothing is returned.
   */
  function resetActiveCount () {
    activeLoading = 0;
  }

   /**
   * Get accordion states for current page.
   *
   * @returns {Undefined} - Nothing is returned.
   */
  function getAccordionStates () {
    return $localForage.getItem(`${$state.current.name}-accordions`)
    .then(function (val) {
      accordionState = val != null && typeof value === 'object' ? val : {};
    })
    .catch((err)=> {
      $log.error(err);
    });
  }

  /**
   * Send a request to the server to update the overview progress.
   *
   * @param {Object} intro - Intro object.
   * @returns {Undefined} - Nothing is returned.
   */
  function setStatus (intro) {
    // Only send request if there is something to update.
    if (Object.keys(intro.updateOnServer).length) {
      $http.put(
        ConfigURLs.intro(Session.current.user._id), intro.updateOnServer
      ).then((response) => {
        guides = response.data;
      }).catch((e) => {
        $log.warn('Failed to save overview progress', e);
      });
    }

    if (intro.disableOverviews) {
      let data = {
        preferences: {
          guidePrompts: Session.current.user.preferences.guidePrompts,
        },
        __v: 'any',
      };
      // Update the guidePrompts preference.
      $http.put(
        ConfigURLs.updateGuidePrompts(Session.current.user._id) +
        '?force=true', data
      )
      .then(function (res) {
        Session.current.user = res.data;
        Toast.makeSuccess('Page overviews disabled.');
      }).catch(function (e) {
        $log.warn(e);
      });
    }
  }

  /**
   * Scrolls the page to the top.
   * @returns {Undefined} - Nothing is returned.
   */
  function scrollToTop () {
    let contentContainer = document.querySelector('main#content');
    // Workaround for Edge
    contentContainer.scrollTo ?
      contentContainer.scrollTo(0, 0) : contentContainer.scrollTop = 0;
  }

  /**
   * Adds Snooze and Disable overview buttons and event listeners to the
   * intro tooltip card.
   *
   * @param {Object} intro - intro object.
   * @returns {Undefined} - Nothing is returned.
   */
  function addButtons (intro) {
    let tooltip = document.querySelector('.introjs-tooltip');
    let btnArea = document.querySelector('.introjs-tooltipbuttons');

    let disableOverviewsDiv = document.createElement('div');
    let disableOverviews = document.createElement('a');
    let snooze = document.createElement('button');

    disableOverviewsDiv.classList.add('justify-end-xs');

    disableOverviews.innerHTML = 'Disable in-app guide prompts';
    disableOverviews.setAttribute('role', 'button');
    disableOverviews.classList.add('disable-overviews');

    // Disable overviews clicked
    disableOverviews.addEventListener('click', function () {
      // Set it on the current user object, update on server after updated
      // the guides object
      Session.current.user.preferences.guidePrompts = false;
      intro.disableOverviews = true;
      scrollToTop();
      intro.exit();
    });

    snooze.innerHTML = 'Snooze';
    snooze.classList.add('introjs-button', 'introjs-button--snooze');

    // Snooze button clicked
    snooze.addEventListener('click', function () {
      scrollToTop();

      // Set all overviews snoozed until next day 6am.
      // eslint-disable-next-line no-magic-numbers
      snoozeGuidesUntil(moment().add(1, 'days').hours(6).startOf('hour')._d);

      intro.exit();
    });

    tooltip.insertBefore(disableOverviewsDiv, tooltip.childNodes[0]);
    disableOverviewsDiv.appendChild(disableOverviews);
    btnArea.insertBefore(snooze, btnArea.childNodes[0]);
  }

  /**
   * Adds classes to the tooltip buttons to style them, removes bullets
   * when they are not needed.
   *
   * @param {Object} intro - intro object.
   * @returns {Undefined} - Nothing is returned.
   */
  function styleButtons (intro) {
    let bullets = document.querySelectorAll('li[role="presentation"]');
    let introBtns = document.querySelectorAll('.introjs-button');

    // Minimum number of steps for the bulletpoints to be kept.
    const BULLETS_NEEDED = 3;

    if (bullets.length < BULLETS_NEEDED) {
      intro.setOption('showBullets', false);
      try {
        document.querySelector('.introjs-bullets').innerHTML = '';
      } catch (e) { angular.noop(); }
    }

    _.each(introBtns, function (btn) {
      btn.classList.add('button', 'vgu-top-margin');
      btn.classList.remove('introjs-fullbutton');
    });
  }

  /**
   * On step change adds the number of the previous completed step stored
   * in intro.addIfComplete.
   *
   * @param {Object} overview - The intro object.
   * @returns {Undefined} - Nothing is returned.
   */
  function addPreviousStepToCompleted (overview) {
    if ( // Greater than or equal to since step 0 exists.
      overview.addIfComplete >= 0 &&
      // only add if we don't have it.
      !_.includes(overview.completedSteps, overview.addIfComplete)
    ) {
      overview.completedSteps.push(overview.addIfComplete);
    }
  }

  /**
   * Clone and append the current steps element into the helper layer.
   *
   * @param {Object} intro - The intro object.
   * @returns {Undefined} - Nothing is returned.
   */
  function cloneCurrentElement (intro) {
    let currentStep = intro._introItems[intro._currentStep];
    if (currentStep.screenSmallerThan) {
      if (
        !Breakpoints.smallerThan(
          currentStep.screenSmallerThan
        )
      ) return;
    }

    $timeout(function () {
      if (!currentStep) return;
      let helperLayer = document.querySelector('.introjs-helperLayer');
      let dup = currentStep.element.cloneNode(true);
      let modalContainer = document.querySelector('flare-modal-container');

      // Add styles to the first level children.
      _.each(dup.children, function (child) {
        child.classList.add('flex-no-wrap');
        child.style.maxWidth = '100%';
      });

      // Add styles to the duplicate element.
      dup.classList.add('flex-1');
      dup.style.padding = '0.36em';

      helperLayer.innerHTML = '';
      helperLayer.appendChild(dup);
      helperLayer.style.backgroundColor = '#fff';
      modalContainer.classList.remove('introjs-fixParent');
    }, 400); // 400, to let all animation finish first.
  }

  /**
   * Modify the overview layers to avoid not showing the modal on the page due
   * to stacking context.
   *
   * @param {Object} intro - The intro object.
   * the user
   * @returns {Undefined} - Nothing is returned.
   */
  function modifyLayersIfModal (intro) {
    let currentStep = intro._introItems[intro._currentStep];
    if (!currentStep.isModal) return;

    $timeout(function () {
      let modalContainer = document.querySelector('flare-modal-container');

      let helperLayer = document.querySelector('.introjs-helperLayer');
      let introOverlay = document.querySelector('.introjs-overlay');

      if (!currentStep.isModal) {
        // Reset changed styles
        helperLayer.style.opacity = '';
        introOverlay.style.opacity = intro._options.overlayOpacity;
        return;
      }

      // Current step is modal, make it visible.
      modalContainer.classList.remove('introjs-fixParent');
      if (currentStep.elementOnModal) {
        helperLayer.style.opacity = '';
        introOverlay.style.opacity = intro._options.overlayOpacity;
      } else {
        helperLayer.style.opacity = '0';
        introOverlay.style.opacity = '0';
      }
    }, 100);
  }

  /**
   * Adds CSS styles to the current element if needed.
   *
   * @param {Object} intro - The intro object.
   * the user
   * @returns {Undefined} - Nothing is returned.
   */
  function addStylesToStep (intro) {
    let currentStep = intro._introItems[intro._currentStep];
    if (!currentStep.addStylesTo) return;
    let elementsToMutate = Object.keys(currentStep.addStylesTo);
    // eslint-disable-next-line consistent-return
    _.each(elementsToMutate, function (e) {
      let element = document.querySelector(currentStep.addStylesTo[e].selector);
      if (!element) return true;
      _.each(currentStep.addStylesTo[e].styles, function (value, key) {
        element.style[key] = value;
      });
    });
  }

  /**
   * Opens the first accordion if needed.
   *
   * @returns {Undefined} - Nothing is returned.
   */
  function openAccordionIfNeeded () {
    let key = // get the key for first accordion
      document.querySelector('pxn-accordion').getAttribute('unique-key');
    if ( // Open if necessary.
      typeof accordionState[key] !== 'boolean' ||
      accordionState[key] === false
    ) {
      accordionState[key] = true;
      $rootScope.$emit('onBoarding:open-accordion', key);
    }
  }

  /**
   * Starts a guide.
   *
   * @param {String} overviewName - The name of the overview to start.
   * @param {Boolean} forced - False if overview prompted, True if triggered by
   * the user
   * @returns {Undefined} - Nothing is returned.
   */
  function startPageOverview (overviewName, forced = false) {
    let intro = // Get the requested intro.
      PageOverviews.getPageOverview(
        overviewName, $state.current.name, guides, forced
      );
    // If we didn't get back an intro object don't continue.
    if (intro == null) return;

    let uiCtrl = CtrlOf.getUiCtrl();

    let deferred = $q.defer();
    let readyToStart = deferred.promise;
    let sidebarToggled = false;
    let delay = 0;

    // If the first accordion needs to be opened do it before starting intro.
    if (intro.openFirstAccordion) {
      openAccordionIfNeeded();
      delay = ACCORDION_OPEN_DELAY;
    }

    // If we need delay to open sidebar for the first step
    if (intro.firstStepSidebarState || intro.firstStepDelay) {
      if (intro.firstStepSidebarState) {
        Sidebar.set(intro.firstStepSidebarState);
        delay = SIDEBAR_TOGGLE_TIME;
      }

      if (intro.firstStepDelay) {
        delay = intro.firstStepDelay > delay ? intro.firstStepDelay : delay;
      }

      $timeout(function () {
        // Rerequest intro object in case some elements get removed during delay
        intro = PageOverviews.getPageOverview(
          overviewName, $state.current.name, guides, forced
        );
        deferred.resolve(); // Resolve promise after the required amount of time
      }, delay); // Delay required before intro could start.
    } else if (delay) {
      // If we need to wait resolve promise after delay.
      $timeout(function () {
        intro = PageOverviews.getPageOverview(
          overviewName, $state.current.name, guides, forced
        );
        deferred.resolve();
      }, delay);
    } else {
      deferred.resolve(); // Don't need to wait, resolve promise.
    }

    readyToStart.then(function () {
      if (Object.keys(runningGuide).length || !intro) return;
      intro.start(); // Start intro when the promise is resolved.
      runningGuide = intro;
      if (intro.openFirstAccordion) rerunStepSelectors();

      if (intro.cloneAtFirstStep) cloneCurrentElement(intro);

      if (
        intro.firstStepSidebarState && Breakpoints.smallerThan('lg') &&
        intro.firstStepSidebarState.secondarySidebarExpanded
      ) {

        $timeout(function () { // Let intro elements into DOM first
          // When setting the secondary sidebar state to be open we need to
          // remove this class so the sidebar can stay visible as we wanted.
          let el = document.querySelector('.introjs-fixParent');
          if (el) el.classList.remove('introjs-fixParent');
        });
      }
      // Added properties to keep record of the progress.
      intro.completedSteps = [];
      intro.updateOnServer = {};
      intro.addIfComplete = intro._currentStep;
      intro.forced = forced;

      sidebarToggled = false;

      $timeout(function () {
        // Add extra buttons for prompted overviews.
        if (!forced) addButtons(intro);

        // Style buttons and remove bulletpoints if not needed.
        styleButtons(intro);
        modifyLayersIfModal(intro);
      });
      $timeout(function () {
        if (intro._introItems[intro._currentStep].addStylesTo) {
          addStylesToStep(intro);
        }
      }, 400);

      // Callback before next step.
      intro.onchange(function () {
        let currentStep = intro._introItems[intro._currentStep];
        let helperLayer = document.querySelector('.introjs-helperLayer');
        helperLayer.innerHTML = '';

        if (currentStep.sidebarDelay) {
          // Delay has been added to the step to wait for the sidebar to open,
          // remove SIDEBAR_TOGGLE_TIME from the delay property.
          currentStep.delay -= SIDEBAR_TOGGLE_TIME;
          // Reset sidebar delay flag.
          currentStep.sidebarDelay = false;
        }

        // When delay isn't required but the sidebar is open
        if (
          !currentStep.sidebar &&
          (uiCtrl.sidebarExpanded || uiCtrl.secondarySidebarExpanded)
        ) {
          // close sidebar
          Sidebar.set(true);
          sidebarToggled = true;
        // We need delay but the sidebar is closed
        } else if (
          currentStep.sidebar &&
          (uiCtrl.sidebarExpanded !== currentStep.sidebar.sidebarExpanded ||
          uiCtrl.secondarySidebarExpanded !==
          currentStep.sidebar.secondarySidebarExpanded)
        ) {
          // open sidebar
          Sidebar.set(currentStep.sidebar);
          sidebarToggled = true;
        }

        if (sidebarToggled) {
          // Sidebar has been toggled, add delay to the step.
          currentStep.delay += SIDEBAR_TOGGLE_TIME;
          // Extra time added, set flag.
          currentStep.sidebarDelay = true;
          sidebarToggled = false;
        }

        // Open accordion between steps as well
        if (currentStep.accordion) {
          openAccordionIfNeeded();
          currentStep.delay = currentStep.delay > ACCORDION_OPEN_DELAY ?
            currentStep.delay : ACCORDION_OPEN_DELAY;
        }
      });

      // When next or previous button clicked:
      intro.onafterchange(function () {
        let currentStep = intro._introItems[intro._currentStep];
        if (currentStep.accordion) rerunStepSelectors();
        if (
          currentStep.sidebar.secondarySidebarExpanded
          && Breakpoints.smallerThan('lg')
        ) {
          // If next or previous step has a delay caused by setting the sidebars
          // state, in order to keep the sidebars as we set them remove this
          // class.
          $timeout(function () {
            let el = document.querySelector('.introjs-fixParent');
            if (el) el.classList.remove('introjs-fixParent');
          }, currentStep.sidebarDelay ? SIDEBAR_TOGGLE_TIME : 0);
        }

        // styling the buttons on the next tooltip as well
        styleButtons(intro);
        modifyLayersIfModal(intro);
        if (currentStep.addStylesTo) addStylesToStep(intro);

        // Add completed step to array
        addPreviousStepToCompleted(intro);
        // Set current step to be marked completed next.
        intro.addIfComplete = intro._currentStep;

        let helperLayer = document.querySelector('.introjs-helperLayer');
        helperLayer.innerHTML = '';

        if (currentStep.cloneElement) cloneCurrentElement(intro);
      });

      // When skip button clicked
      intro.onskip(function () {
        // Done and skip buttons are the same, when there is only one intro item
        // on cliking the done button this function gets called. If we have the
        // .introjs-donebutton class on the page do nothing here.
        let doneBtn = document.querySelector('.introjs-donebutton');
        if (!doneBtn && !intro.forced) intro.skipped = true;
      });

      // Before closing the overview:
      intro.onbeforeexit(function () {
        if (intro.completedSteps.length) {
          // We have steps completed
          _.each(intro.completedSteps, function (stepNum) {
            // Create step status
            intro.updateOnServer[
            `${intro.page}/${intro.overview}/${intro._introItems[stepNum].key}`
            ] = 'completed';
          });

          if (
            Object.keys(
              intro.updateOnServer
            ).length === intro._introItems.length
          ) {
            intro.updateOnServer[
              `${intro.page}/${intro.overview}`] = 'completed';
          }
        }

        // Set overview status if it has been skipped.
        if (intro.skipped) {
          intro.updateOnServer[`${intro.page}/${intro.overview}`] = 'skipped';
        }

        setStatus(intro); // Update progress on the server
        scrollToTop(); // Make sure to scroll to top of the page
        runningGuide = {};

        if (uiCtrl.sidebarExpanded || uiCtrl.secondarySidebarExpanded) {
          // Close sidebar on intro exit.
          Sidebar.set(true);

          $rootScope.$digest(); // Update scope variables.
        }
      });


      intro.oncomplete(function () { // On done button click
        addPreviousStepToCompleted(intro); // Add last step to completed array.
      });
    });
  }

  /**
   * Try to reinitiate the mainOverview intro.
   *
   * @returns {Undefined} - Nothing is returned.
   */
  function triggerOverviewResourceAvailable () {
    if (
      !guidesProgressReceived || !Session.current.user.preferences.guidePrompts
    )  return;
    let display = notSnoozedOrSkipped(guides);
    if (display) startPageOverview('mainOverview');
  }

  /**
   * Resets the overview progress of the user.
   *
   * @param {String} id - The logged in users Id.
   * @returns {Undefined} - Nothing is returned.
   */
  function reset (id) {
    $http.delete(ConfigURLs.intro(id)).then(function () {
      guides = {}; // Empty our guides object
      Toast.makeSuccess('Your progress has been reset successfully.');
    })
    .catch(function (e) {
      Toast.makeError(e);
    });

    snoozeGuidesUntil();
  }

  /**
   * Snoozes all guides until the specified time.
   *
   * @param {Object} time - Time to snooze all guides until.
   * @returns {Undefined} - Nothing is returned.
   */
  function snoozeGuidesUntil (time = null) {
    let data = {
      // eslint-disable-next-line no-magic-numbers
      snoozeGuidesUntil: time,
      __v: Session.current.user.__v
    };
    $http.put(
      ConfigURLs.updateGuidePrompts(Session.current.user._id), data)
    .then(function (res) {
      Session.current.user = res.data;
    }).catch(function (err) {
      $log.warn(err);
    });
  }

  /**
   * Determine if the page overview has to be prompted to the user.
   *
   * @param {Object} guideStatuses - The current user guides object.
   * @returns {Boolean} - True if the overview needs to be prompted.
   */
  function notSnoozedOrSkipped (guideStatuses) {
    // Overviews has been snoozed, don't show any until specified times up.
    if (
      Session.current.user.snoozeGuidesUntil &&
      Date.now() -
      new Date(Session.current.user.snoozeGuidesUntil).getTime() < 0
    ) {
      return false;
    }

    let guideState;

    // Find the current guide
    // eslint-disable-next-line consistent-return
    _.each(guideStatuses, function (guide) {
      if (guide.name.split('/')[0] === $state.current.name) {
        guideState = guide;
        return false;
      }
    });

    // If the overview hasn't been shown return true early.
    if (!guideState) return true;

    // The overview has been skipped return false.
    if (guideState.progress.skipped.length) return false;

    return true; // Guide can pop up.
  }


  // When a user logs in or reloads the page get our progress object.
  $rootScope.$on('onBoarding:setGuides', function () {
    setGuides();
  });

  /**
   * Requests and stores the users guide progress then emits an event which
   * starts the overview if it can be started.
   *
   * @returns {Undefined} - Nothing is returned.
   */
  function setGuides () {
    $http.get(ConfigURLs.intro(Session.current.user._id))
    .then(function (response) {
      guides = response.data;
      // We have the users progress, intro can be started.
      $rootScope.$emit('onBoarding:guides-progress-received');
      guidesProgressReceived = true;
      // Get the array of those stepKeys which should only be displayed once.
      onlyShowOnceSteps = PageOverviews.getOnlyShowOnceSteps();
      setSeenSteps(guides);
    })
    .catch(function (e) { $log.warn(e); });
    guidesProgressRequested = true;
  }

  /**
   * Returns the user guide progress.
   *
   * @returns {Object} - The user guide object.
   */
  function getGuides () {
    return guides;
  }

  /**
   * Returns wether or not the guides has been requested from the server.
   *
   * @returns {Boolean} - True if the guides has been requested.
   */
  function isRequested () {
    return guidesProgressRequested;
  }

  let currentBreakpoint = Breakpoints.current;

  /**
   * Re-run all step selectors.
   *
   * @returns {Undefined} - Nothing is returned.
   */
  function rerunStepSelectors () {
    _.each(runningGuide._introItems, function (i) {
      // Step has a selector which is an object
      if (i.selector && angular.isObject(i.selector)) {
        Breakpoints.smallerThan('lg') ?
          // Smaller than large screen use mobile selector.
          i.element = document.querySelector(i.selector.mobile) :
          // Use desktop selector.
          i.element = document.querySelector(i.selector.desktop);
        if (i.step - 1 === runningGuide._currentStep) {
          // We have the element for the current step.
          // Remove the old element's highlighter classes.
          document.querySelector('.introjs-showElement').classList.remove(
            'introjs-showElement', 'introjs-relativePosition'
          );
          // Add highlighting class to the new element.
          i.element.classList.add(
            'introjs-showElement', 'introjs-relativePosition'
          );
        }
      // Step has a selector which is a string
      } else if (i.selector && typeof i.selector === 'string') {
        i.element = document.querySelector(i.selector);
      } else {
        $log.debug('Something went wrong...'); // Just in case...
      }
      // If the current step needs the sidebar to be open and we are on a
      // smaller screen open the sidebar.
      if (
        Breakpoints.smallerThan('lg') &&
        i.sidebar && i.step - 1 === runningGuide._currentStep
      ) {
        Sidebar.set(i.sidebar); // Show sidebar
      }
    });
  }

  // Refreshing elements on screen resize.
  window.addEventListener('resize', function () {
    // When the breakpoint hasn't changed return early.
    if (Breakpoints.current === currentBreakpoint) return;
    currentBreakpoint = Breakpoints.current; // Update currentBreakpoint var.

    if (!Object.keys(runningGuide).length) return; // No running guide

    let currentStep = runningGuide._introItems[runningGuide._currentStep];

    if (currentStep.hideOnMobile && Breakpoints.smallerThan('lg')) {
      runningGuide.exit();

      if ($state.current.name === 'devices') {
        let devicesCtrl = CtrlOf.getDevicesCtrl();
        devicesCtrl.deselectAll();
      }
      return;
    }
    if (currentStep.hideOnDesktop && !Breakpoints.smallerThan('lg')) {
      runningGuide.exit();
      return;
    }

    // Refresh every intro element
    rerunStepSelectors();

    $timeout(function () {
      if (currentStep.isModal && !currentStep.elementOnModal) {
        // Current step highlights a modal, set layers to keep the modal visible
        // during resize as well.
        let elms = Array.from(document.querySelectorAll(
          '.introjs-helperLayer, .introjs-overlay'
        ));
        elms.forEach(function (elm) {
          elm.style.opacity = 0;
        });
      }
    }, 400);
  });

  /**
   * Sets the seen steps array with those stepKeys that should only be dispayed
   * once and the user has already seen.
   *
   * @param {Object} userGuides - The current user guides object.
   * @returns {Undefined} - Nothing is returned.
   */
  function setSeenSteps (userGuides) {
    _.each(onlyShowOnceSteps, function (step) {
      _.each(userGuides, function (guide) {
        if (guide.progress.completed.indexOf(step) !== -1) {
          PageOverviews.setStepSeen(step);
        }
      });
    });
  }

  return {
    reset: reset,
    updateLoading: updateCount,
    getCount: activeCount,
    resetCounter: resetActiveCount,
    start: startPageOverview,
    setStatus: setStatus,
    triggerOverviewResourceAvailable: triggerOverviewResourceAvailable,
    notSnoozedOrSkipped: notSnoozedOrSkipped,
    setGuides: setGuides,
    getGuides: getGuides,
    setSeenSteps: setSeenSteps,
    isRequested: isRequested,
  };
})

.run(function (
  $rootScope, $state, $timeout, Onboarding, Session
) {
  let onBoardingState = {
    loadingCounter: 0,
    popupOverview: 'mainOverview',
    loadingRegistered: false,
    guides: null,
    started: false,
    guidesReceived: false,
  };

  // Response received from the server now
  $rootScope.$on(
    'onBoarding:guides-progress-received', function () {
      onBoardingState.guidesReceived = true;
      showPopupGuideIfAvailable();
    });

  /**
   * Calls the function to start the overview if it needs to be prompted.
   *
   * @returns {Undefined} - Nothing is returned.
   */
  function showPopupGuideIfAvailable () {
    // Update guides
    onBoardingState.guides = Onboarding.getGuides();
    // Users guide preference is false, return early.
    if (
      !Session.current.user.preferences.guidePrompts ||
      $state.current.noOnboarding
    ) return;
    let shouldPopup = Onboarding.notSnoozedOrSkipped(onBoardingState.guides);
    if (
      shouldPopup && onBoardingState.guides && onBoardingState.guidesReceived &&
      !onBoardingState.loadingCounter && !onBoardingState.started
    ) {
      // Loading indicators finished, we have our guides and the overview needs
      // to be prompted.
      $timeout(function () {
        // Before intro start: make sure to set the seenSteps in PageOverviews
        Onboarding.setSeenSteps(onBoardingState.guides);
        Onboarding.start(onBoardingState.popupOverview);
        // eslint-disable-next-line no-magic-numbers
      }, 300); // timeout to let the sidebar close first on smaller screens.
      onBoardingState.started = true;
    }
  }

  $rootScope.$on('$stateChangeStart', function () {
    // Resetting the loading indicator counter and loadingRegistered flag.
    Onboarding.resetCounter();
    onBoardingState.loadingRegistered = false;
    onBoardingState.started = false;
  });

  $rootScope.$on('$viewContentLoaded', function () {
    $timeout(function () {
      if (!$state.current.noOnboarding) { // $state has a popup guide.
        if (!Onboarding.isRequested()) {
          Onboarding.setGuides();
        }
        // If we need to wait for loading resources, call
        // showPopupGuideIfAvailable function when the loading indicator
        // deregister.
        if (!onBoardingState.loadingRegistered) {
          showPopupGuideIfAvailable();
        }
      }
    });
  });

  // pxnLoading has been added or removed from page. V is a boolean argument,
  // if true we are registering a pxnLoading component, deregister if false.
  $rootScope.$on('pxn-loading-register', function (e, v) {
    // Set flag if pxnLoading is on the page.
    if (!onBoardingState.loadingRegistered) {
      onBoardingState.loadingRegistered = true;
    }
    // Notify factory
    Onboarding.updateLoading(v);
    // Update our loadingcounter.
    onBoardingState.loadingCounter = Onboarding.getCount();
    // When a loading indicator gets removed (v = false) and there is no more on
    // the page call showPopupGuideIfAvailable function.
    if (!v && onBoardingState.loadingCounter === 0) {
      showPopupGuideIfAvailable();
    }
  });
});
