angular.module('pxn-drag-and-drop', [])

.directive('pxnDragNDrop', function (
  $rootScope, $window, $document, $timeout, $log, CustomFields, Onboarding
) {
  return {
    restrict: 'A',
    require: '^flareTemplatePreview',
    controller: function ($scope, $element, $attrs) {
      /* eslint-disable func-style */
      let getDropParent = (el) => $scope.getDropParent(el);
      let dragstart = (event) => $scope.dragstart(event);
      let dragover = (event) => $scope.dragover(event);
      let drop = (event) => $scope.drop(event);
      let previewLoaded = () => $scope.previewLoaded;
      let templateChange = () => $scope.templateChange;
      let animateElement = (el, from, where) => {
        $scope.animateElement(el, from, where);
      };
      /* eslint-enable func-style */

      return {
        getDropParent: getDropParent,
        dragstart: dragstart,
        dragover: dragover,
        drop: drop,
        previewLoaded: previewLoaded,
        templateChange: templateChange,
        animateElement: animateElement
      };
    },
    link: function (scope, element, attributes, previewCtrl) {
      const ANIM_DURATION = 200;
      let deregisterArr = [];

      const layoutClasses = [
        'row-layout',
        'row-reverse-layout',
        'column-layout',
        'column-reverse-layout'
      ];

      // Opacity values we use on drop zones whilst dragging.
      let DROP_AREA = 0.7; // Drop zones where the element can be placed.
      let DROP_AREA_HOVER = 0.4; // Same size drop zone as element when hover.

      let currentLocale = 0;

      // The currently dragged elements size.
      scope.elementSize = null;
      scope.layoutBeforeEdit = null;
      scope.dragElement = null;
      scope.previewLoaded = false;

      /**
       * Emits an event to reset flare image background position.
       *
       * @returns {Undefined} - Nothing is returned.
       */
      function resetFlareImageBackground () {
        scope.$broadcast('reset-flare-image-background');
      }

      previewCtrl.readyPromise.then(function () {
        if (!isLayoutEditable(previewCtrl.previewScope.template)) return;

        if (!previewCtrl.previewScope.slide._tempLayouts) {
          previewCtrl.previewScope.slide._tempLayouts = {
            landscape: {}, portrait: {}
          };
        }

        let template = element[0].querySelector('[dnd-container]');
        let layout = previewCtrl.templateScope.content.layout;
        if (!layout) layout = {};
        layout.template = previewCtrl.previewScope.template.name;

        if (!scope.previewLoaded) {
          scope.startingLayout = layout;
          previewCtrl.previewScope.slide._tempLayouts[getCurrentOrientation()][
            previewCtrl.previewScope.template.name
          ] = layout;
        }

        if (layout.template !== previewCtrl.previewScope.template.name) {
          layout.template = previewCtrl.previewScope.template.name;
          scope.$broadcast('pxn-DND:reset-dividers');
        }

        if (
          template && layout && layout.direction &&
          previewCtrl.previewScope.slide._layoutRotatable
        ) { // Set flex direction.
          setLayoutDirectionClass(template, layout.direction);
          // If we change the layout tell the dividers
          if (layout.dividers) {
            scope.$broadcast(
              'pxn-DND:reset-dividers',
              scope.startingLayout.template === layout.template ?
                scope.startingLayout.dividers : null
            );
          }
        }

        scope.layoutBeforeEdit = layout;

        previewCtrl.previewScope.slide.layoutBeforeEdit = angular.copy(layout);

        if (!layout.elements) layout.elements = {};
        scope.previewLoaded = true;

        previewCtrl.templateScope.content.layout = layout;

        if (
          previewCtrl.previewScope.slide._layoutRotatable &&
          !previewCtrl.templateScope.content.layout.direction
        ) {
          // Layout is rotatable but we don't have an initial value set.
          previewCtrl.templateScope.content.layout.direction =
            $window.getComputedStyle(template).flexDirection;
          setLayoutDirectionClass(
            template, previewCtrl.templateScope.content.layout.direction
          );
        }

        // Orientations the template supports
        let orientations = [];
        if (previewCtrl.previewScope.template.orientations.landscape) {
          orientations.push('landscape');
        }
        if (previewCtrl.previewScope.template.orientations.portrait) {
          orientations.push('portrait');
        }

        currentLocale = previewCtrl.previewScope.currentLocale;

        _.each(orientations, (o) => {
          if (
          previewCtrl.previewScope.template.orientations[o] &&
          !_.keys(
            previewCtrl.previewScope.allContent[currentLocale].data[o].layout
            ).length
          ) {
            // Default not previewed layouts to an empty object
            previewCtrl.previewScope.allContent[currentLocale].data[o].layout =
              {};
          }
        });

        $rootScope.$emit('pxn-DND:preview-layout-reordered');
        resetFlareImageBackground();
      })
      .catch(function (err) {
        $log.error(err);
      });

      scope.$watch('previewCtrl.previewScope.currentLocale', function (n, o) {
        if (n !== o) currentLocale = n;
      });

      /**
       * Set the slide _layoutChanged property to the given value.
       *
       * @param {Boolean} val - Value to set _layoutChanged property to.
       * @returns {Undefined} - Nothing is returned.
       */
      function setLayoutChanged (val) {
        previewCtrl.previewScope.slide._layoutChanged = val;
        if (val) {
          setSlideModified();
          // Check if intro should be started for revert changes button.
          $timeout(Onboarding.triggerOverviewResourceAvailable);
        }
      }

      /**
       * Set the provided elements layout direction via removing all other
       * direction classes and apply the right one.
       *
       * @param {HTMLElement} template - Element to set direction class
       * @param {String} direction - FlexDirection property's value.
       * @returns {Undefined} - Nothing is returned.
       */
      function setLayoutDirectionClass (template, direction) {
        template.classList.remove(...layoutClasses);
        template.classList.add(`${direction}-layout`);
      }

      /**
       * Updates content layout for child in zone.
       *
       * @param {HTMLElement} zone - Zone element that needs to be updated.
       * @param {HTMLElement} child - Child element to update.
       * @returns {Undefined} - Nothing is returned.
       */
      function updateLayout (zone, child) {
        previewCtrl.templateScope.content.layout.elements[
          zone.getAttribute('drag-id')
        ] = child ? child.getAttribute('drag-id') : null;
      }

      /**
       * Check if the current target element's children will fit in the drag
       * item's source zone.
       *
       * @param {HTMLElement} sourceZone - Zone to check children will fit.
       * @param {HTMLElement} children - Children of current target zone.
       * @returns {Boolean} - True if the target zones child element will fit in
       * the source zone.
       */
      function willFit (sourceZone, children) {
        return sourceZone.getAttribute('drop-zone-size') ===
               children.getAttribute('drag-size') || _.contains(
                 sourceZone.getAttribute('drop-zone-size').split(' '),
                 children.getAttribute('drag-size')
               );
      }

      /**
       * Animates the passed element from one drop zone to another.
       *
       * @param {HTMLElement} dragItem - The drag item.
       * @param {HTMLElement} sourceZone - Drop zone the drag item grabbed from.
       * @param {HTMLElement} targetZone - Drop zone target.
       * @returns {Undefined} - Nothing is returned.
       */ // eslint-disable-next-line complexity
      scope.animateElement = function (dragItem, sourceZone, targetZone) {
        if (
          sourceZone === targetZone || !dragItem || !sourceZone || !targetZone
        ) return;
        let child = null;
        scope.animating = true;

        let childStartPos, childTargetPos, childInvert;
        let movedElement, movedElementsParent, movedElementsPlace,
          moveBackInvert, moveBackStartPos, moveBackTargetPos;

        if (scope.moveBackIfNotDropped) {
          movedElement = scope.moveBackIfNotDropped.el;
          movedElementsParent =
            scope.getDropParent(scope.moveBackIfNotDropped.el);
          movedElementsPlace = scope.moveBackIfNotDropped.parentEl;

          if (movedElementsParent !== movedElementsPlace) {
            scope.moveBackIfNotDropped = null;
            moveBackStartPos = movedElement.getBoundingClientRect();
          }
        }

        let startPos = dragItem.getBoundingClientRect();
        if (targetZone.children.length) {
          child = targetZone.children[0];
          childStartPos = child.getBoundingClientRect();

          if (!willFit(sourceZone, child)) {
            const possibleZones = document.querySelectorAll(
              `[drop-zone-size*="${child.getAttribute('drag-size')}"]`
            ); // eslint-disable-next-line consistent-return
            _.each(possibleZones, zone => {
              if (!zone.children.length) {
                updateLayout(sourceZone, null);
                sourceZone = zone;
                return false;
              }
            });
          }

          sourceZone.appendChild(child);
          movedElement = null;
        }
        updateLayout(sourceZone, child ? child : null);
        targetZone.appendChild(dragItem);
        updateLayout(targetZone, dragItem);
        if (movedElement && movedElementsParent !== movedElementsPlace) {
          movedElementsPlace.appendChild(movedElement);
          updateLayout(movedElementsParent, null);
          updateLayout(movedElementsPlace, movedElement);
          moveBackTargetPos = movedElement.getBoundingClientRect();
        } else {
          movedElement = null;
        }

        dragItem.classList.add('disable-animations');
        /* eslint-disable no-void */
        // triggering reflow (https://css-tricks.com/restart-css-animation/)
        void dragItem.offsetWidth;
        if (child) void child.offsetWidth;
        if (movedElement) void movedElement.offsetWidth;
        /* eslint-enable no-void */

        let targetPos = dragItem.getBoundingClientRect();
        if (child) childTargetPos = child.getBoundingClientRect();

        dragItem.style.position = 'absolute';
        if (child) child.style.position = 'absolute';

        let invert = {
          top: startPos.top - targetPos.top,
          left: startPos.left - targetPos.left
        };

        if (child) {
          childInvert = {
            top: childStartPos.top - childTargetPos.top,
            left: childStartPos.left - childTargetPos.left
          };

          child.style.transform =
           `translateY(${childInvert.top}px) translateX(${childInvert.left}px)`;
          child.style.width = `${childStartPos.width}px`;
          child.style.height = `${childStartPos.height}px`;
        }

        if (movedElement) {
          moveBackInvert = {
            top: moveBackStartPos.top - moveBackTargetPos.top,
            left: moveBackStartPos.left - moveBackTargetPos.left
          };
          movedElement.style.transform =
          `translateY(${
            moveBackInvert.top
          }px) translateX(${
            moveBackInvert.left
          }px)`;
          movedElement.style.width = `${moveBackStartPos.width}px`;
          movedElement.style.height = `${moveBackStartPos.height}px`;
        }

        dragItem.style.transform =
          `translateY(${invert.top}px) translateX(${invert.left}px)`;
        dragItem.style.width = `${startPos.width}px`;
        dragItem.style.height = `${startPos.height}px`;

        requestAnimationFrame(function () {
          dragItem.style.transition = `all ${ANIM_DURATION / 1000}s ease`;
          if (child) {
            child.style.transition = `all ${ANIM_DURATION / 1000}s ease`;
          }
          if (movedElement) {
            movedElement.style.transition = `all ${ANIM_DURATION / 1000}s ease`;
          }
          /* eslint-disable no-void */
          // triggering reflow (https://css-tricks.com/restart-css-animation/)
          void dragItem.offsetWidth;
          if (child) void child.offsetWidth;
          if (movedElement) void movedElement.offsetWidth;
          /* eslint-enable no-void */

          dragItem.style.transform = '';
          dragItem.style.width = '';
          dragItem.style.height = '';
          if (child) {
            child.style.transform = '';
            child.style.width = '';
            child.style.height = '';
          }
          if (movedElement) {
            movedElement.style.transform = '';
            movedElement.style.width = '';
            movedElement.style.height = '';
          }
          $timeout(function () {
            scope.animating = false;
          }, ANIM_DURATION);
        });

        dragItem.classList.remove('disable-animations');
        dragItem.style.position = '';
        dragItem.style.transition = '';

        if (child) {
          child.classList.remove('disable-animations');
          child.style.position = '';
          child.style.transition = '';
        }
        if (movedElement) {
          movedElement.classList.remove('disable-animations');
          movedElement.style.position = '';
          movedElement.style.transition = '';
        }
        /* eslint-disable no-void */
        // triggering reflow (https://css-tricks.com/restart-css-animation/)
        void dragItem.offsetWidth;
        if (child) void child.offsetWidth;
        if (movedElement) void movedElement.offsetWidth;
        /* eslint-enable no-void */

        scope.sourceElement = targetZone; // Reset the source elements ref.

        setLayoutChanged(scope.hasLayoutChanged());
        scope.elementSize = dragItem.getAttribute('drag-size');
        resetFlareImageBackground();
      };

      deregisterArr.push( // When the user selects a different template.
        $rootScope.$on('pxn-DND:template-changed', function (e, newTemplate) {
          if (!isLayoutEditable(newTemplate)) {
            previewCtrl.previewScope.slide._layoutEditable = false;
            previewCtrl.previewScope.slide._layoutRotatable = false;
            return;
          }
          scope.templateChange = true;
          previewCtrl.previewScope.slide._editLayout = false;
          $rootScope.$emit('pxn-DND:edit-preview-layout', false);
          if (!scope.previewLoaded) return;
          // Get previous template's layout
          let layout = angular.copy(previewCtrl.templateScope.content.layout);

          if (!previewCtrl.previewScope.slide._tempLayouts) {
            // If we don't have temp layouts create object now
            previewCtrl.previewScope.slide._tempLayouts = {
              landscape: {}, portrait: {}
            };
          }
          // Save current layout changes in case the user comes back to use
          // this template.
          previewCtrl.previewScope.slide._tempLayouts[
            getCurrentOrientation()
          ][previewCtrl.previewScope.template.name] = angular.copy(layout);

          // If newTemplate doesn't have landscape or portrait orientation clear
          // it now.
          if (!newTemplate.orientations.landscape) {
            previewCtrl.previewScope.allContent[
              previewCtrl.previewScope.currentLocale
            ].data.landscape.layout = {};
          } else if (!newTemplate.orientations.portrait) {
            previewCtrl.previewScope.allContent[
              previewCtrl.previewScope.currentLocale
            ].data.portrait.layout = {};
          }
          $timeout(()=> {
            $rootScope.$emit('pxn-DND:preview-layout-reordered');
          });
        }));

      deregisterArr.push(
        $rootScope.$on('pxn-DND:rotate-preview-layout', function () {
          let changeDirections = {
            column: 'row',
            'column-reverse': 'row-reverse',
            row: 'column',
            'row-reverse': 'column-reverse'
          };
          let contentLayout = previewCtrl.templateScope.content.layout;
          let template = element[0].querySelector('[dnd-container]');

          contentLayout.direction =
            changeDirections[$window.getComputedStyle(template).flexDirection];
          if (scope.startingLayout) {
            scope.startingLayout.direction = contentLayout.direction;
          }
          setLayoutDirectionClass(template, contentLayout.direction);

          // Set layoutChanged flag to toggle revert changes button.
          setLayoutChanged(
            scope.hasLayoutChanged() ||
            previewCtrl.templateScope.content.layout.direction !==
            scope.layoutBeforeEdit.direction
          );
          resetFlareImageBackground();
        }));

      deregisterArr.push( // When editing state toggled.
        $rootScope.$on('pxn-DND:edit-preview-layout', function (
          e, editingState
        ) {
          // Start intro for preview if required.
          $timeout(Onboarding.triggerOverviewResourceAvailable);
          let template = element[0].querySelector('[dnd-container]');
          if (!template) return;

          scope.elementSize = null;
          scope.editingState = editingState;
          scope.layoutBeforeEdit =
            angular.copy(previewCtrl.templateScope.content.layout);

          setLayoutChanged(false);

          if (editingState) {
            $document.on('dragover', previewDragover);
            $document.on('dragleave', previewDragleave);
            $document.on('dragend', previewDragend);
            $document.on('mousedown', hasBgColor);
          } else {
            $document.off('dragover', previewDragover);
            $document.off('dragleave', previewDragleave);
            $document.off('dragend', previewDragend);
            $document.off('mousedown', hasBgColor);
          }
        }));

      deregisterArr.push(
        $rootScope.$on('pxn-DND:revert-layout-changes', function (e, revertTo) {
          let template = element[0].querySelector('[dnd-container]');

          setLayoutChanged(false); // Reset flag
          toggleEmptyOptions();
          // Only set direction if it existed before.
          if (previewCtrl.templateScope.content.layout.direction) {
            previewCtrl.templateScope.content.layout.direction =
              revertTo.layout.direction || null;
            if (previewCtrl.previewScope.slide._layoutRotatable) {
              // Set direction if layout currently rotatable.
              setLayoutDirectionClass(template, revertTo.layout.direction);
            }
          }
          resetFlareImageBackground();
        }));

      /**
       * Sets the slide modified.
       *
       * @returns {Undefined} - Nothing is returned.
       */
      function setSlideModified () {
        previewCtrl.previewScope.slide.setModified();
        if (previewCtrl.setEditorDirty) {
          previewCtrl.setEditorDirty();
        }
      }

      /**
       * Adds drag elements a class if they have no background color.
       *
       * @param {EventObject} e - The event object.
       * @returns {Undefined} - Nothing is returned.
       */
      function hasBgColor (e) {
        if (!previewCtrl.previewScope.slide._editLayout) return;
        let dragItem = e.target;
        if (!dragItem.hasAttribute('drag-size')) {
          dragItem = getDragItemParent(dragItem);
          if (!dragItem) return;
        }

        let targetStyles = $window.getComputedStyle(dragItem);

        // Set drag elements background color during drag.
        if (targetStyles.backgroundColor.endsWith('0)')) {
          // Drag item doesn't have background color set one for visibility.
          dragItem.classList.add('dragImageNoBackgroundColor');
          scope.noBackgroundColor = true;
        }

        // Remove class on mouseup.
        dragItem.addEventListener('mouseup', function () {
          dragItem.classList.remove('dragImageNoBackgroundColor');
        });
      }

      /**
       * Checks if the layout is editable.
       *
       * @param {Object} newTemplate - The new template.
       * @returns {Boolean} - True if the layout is editable.
       */
      function isLayoutEditable (newTemplate) {
        if (!newTemplate) return false;
        let hasCustomFields = CustomFields.customFields.all[newTemplate.name];
        let hasContentFields = newTemplate && newTemplate.contentFields;

        let template = element[0].querySelector("[dnd-container]");

        // If we have no selected template or DND not available return early.
        if (
          // No D'n'D container
          !template ||
          // No contentFields no D'n'D layout
          !hasContentFields ||
          // No selected template or layout contentField
          !newTemplate || !newTemplate.contentFields ||
          !newTemplate.contentFields.layout &&
          // No customFields, custom contentFields or layout customField
          (
            !hasCustomFields ||
            !hasCustomFields.contentFields ||
            !hasCustomFields.contentFields.layout
          )
        ) {
          previewCtrl.previewScope.slide._layoutEditable = false;
          $rootScope.$emit('pxn-DND:preview-layout-reordered');
          return false;
        }

        previewCtrl.previewScope.slide._layoutEditable = true;
        $timeout(()=> { // Start intro if needed.
          Onboarding.triggerOverviewResourceAvailable();
          // eslint-disable-next-line no-magic-numbers
        }, 300); // 300ms required due to animation.

        if (template.hasAttribute('layout-not-rotatable')) {
          previewCtrl.previewScope.slide._layoutRotatable = false;
          return true;
        }

        if ( // Check content and custom fields as well for rotatable layout.
          previewCtrl.previewScope.slide.template.contentFields.layout &&
          previewCtrl.previewScope.slide.template.contentFields.layout.rotate ||
          CustomFields.customFields.all[
            previewCtrl.previewScope.slide.template.name
          ] &&
          CustomFields.customFields.all[
            previewCtrl.previewScope.slide.template.name
          ].contentFields.layout.rotate
        ) { // Layout is rotatable, set flag
          previewCtrl.previewScope.slide._layoutRotatable = true;
          let templateStyles = $window.getComputedStyle(template);
          setLayoutDirectionClass(template, templateStyles.flexDirection);
        }

        return true;
      }

      /**
       * Returns the current template orientation.
       *
       * @returns {String} - Current template orientation.
       */
      function getCurrentOrientation () {
        return  previewCtrl.previewScope.isPortrait ? 'portrait' : 'landscape';
      }

      // When a template contains multiple DND containers
      // (i.e. branded-message has no-content area and with-content-area)
      deregisterArr.push(
        scope.$on('pxn-DND:template-container-changed', function () {
          if (
            !isLayoutEditable(previewCtrl.previewScope.template) ||
            !scope.previewLoaded
          ) { return; }
          if (!previewCtrl.previewScope.slide._tempLayouts) {
            previewCtrl.previewScope.slide._tempLayouts = {
              landscape: {}, portrait: {}
            };
          }

          let layout = previewCtrl.previewScope.slide._tempLayouts[
            getCurrentOrientation()
          ][previewCtrl.previewScope.template.name];

          // Create empty layout for newTemplate if it doesn't have one already
          if (!layout) {
            if (
              previewCtrl.templateScope.content.layout.template ===
              previewCtrl.previewScope.template.name
            ) {
              layout = previewCtrl.templateScope.content.layout;
            } else {
              layout = {
                direction: null,
                dividers: {},
                elements: {},
                template: previewCtrl.previewScope.template.name
              };
            }
          }

          let template = element[0].querySelector('[dnd-container]');

          if (
            previewCtrl.previewScope.slide._layoutRotatable && !layout.direction
          ) {
            // The layout is rotatable but we have no initial value set.
            layout.direction = $window.getComputedStyle(template).flexDirection;
          }

          if (
            previewCtrl.previewScope.slide._layoutRotatable && layout.direction
          ) {
            // Layout is rotatable, set style.
            setLayoutDirectionClass(template, layout.direction);
          }

          if (layout && layout.dividers) {
            scope.$broadcast('pxn-DND:reset-dividers', layout.dividers);
          }

          previewCtrl.templateScope.content.layout = angular.copy(layout);
          let revertObj = {
            layout: layout,
            animate: false
          };
          $rootScope.$emit('pxn-DND:revert-layout-changes', revertObj);

          // Turn editing mode off.
          previewCtrl.previewScope.slide._editLayout = false;
          $rootScope.$emit('pxn-DND:edit-preview-layout', false);

          scope.templateChange = false;
          scope.layoutBeforeEdit = angular.copy(layout);
          previewCtrl.previewScope.slide.layoutBeforeEdit =
            angular.copy(layout);

          resetFlareImageBackground();
        }));

      // Cleanup editing state if no longer editable after removed dndContainer
      deregisterArr.push(
        scope.$on('pxn-DND:template-container-removed', function () {
          $timeout(() => {
            if (!isLayoutEditable(previewCtrl.previewScope.template)) {
              previewCtrl.previewScope.slide._editLayout = false;
              $rootScope.$emit("pxn-DND:edit-preview-layout", false);
            }
          });
        })
      );

      deregisterArr.push(
        scope.$on('locale-change', function () {
          if (
            !isLayoutEditable(previewCtrl.previewScope.template) ||
            !scope.previewLoaded
          ) { return; }

          let template = element[0].querySelector('[dnd-container]');
          // Remove every layout classes from the template
          _.each(layoutClasses, (layoutClass)=> {
            angular.element(template).removeClass(layoutClass);
          });

          scope.$broadcast('pxn-DND:re-register-layout');

          let layout = previewCtrl.templateScope.content.layout;

          // Create empty layout for newTemplate if it doesn't have one already
          if (!layout) {
            layout = {
              direction: null,
              dividers: {},
              elements: {},
              template: previewCtrl.previewScope.template.name
            };
          }

          if (
            previewCtrl.previewScope.slide._layoutRotatable && !layout.direction
          ) {
            // The layout is rotatable but we have no initial value set.
            layout.direction = $window.getComputedStyle(template).flexDirection;
          }

          if (
            previewCtrl.previewScope.slide._layoutRotatable && layout.direction
          ) {
            // Layout is rotatable, set style.
            setLayoutDirectionClass(template, layout.direction);
          }

          if (layout && layout.dividers) {
            scope.$broadcast('pxn-DND:reset-dividers', layout.dividers);
          }

          previewCtrl.templateScope.content.layout = angular.copy(layout);
          let revertObj = {
            layout: layout,
            animate: false
          };
          $rootScope.$emit('pxn-DND:revert-layout-changes', revertObj);

          // Turn editing mode off.
          previewCtrl.previewScope.slide._editLayout = false;
          $rootScope.$emit('pxn-DND:edit-preview-layout', false);

          scope.templateChange = false;
          scope.layoutBeforeEdit = angular.copy(layout);
          previewCtrl.previewScope.slide.layoutBeforeEdit =
            angular.copy(layout);

          resetFlareImageBackground();
        }));

      /**
       * Toggle a subtle background color on those elements where the dragged
       * item can be droppped.
       *
       * @param {HTMLElement} sourceZone - The dragged item's source zone.
       * @returns {Undefined} - Nothing is returned.
       */
      function toggleEmptyOptions (sourceZone) {
        if (!scope.elementSize && !scope.sourceElement) return;
        let propsNeeded = {};
        if (sourceZone) {
          // Select all zones our drag element will fit.
          let sameSizeZones = document.querySelectorAll(`[drop-zone-size*="${
            scope.dragElement.getAttribute('drag-size')
          }"]`);
          // All drag items that has the exact same drag-size value.
          let SameSizeElements = document.querySelectorAll(
            `[drag-size="${scope.dragElement.getAttribute('drag-size')}"]`);
          let hasSameSizEmptyZone = false;
          // Arrays to store exact same size `drop zones` heights and widths.
          let exactH = [];
          let exactW = [];
          // Arrays to store drop zones heights and widths where the item fits.
          let zHeights = [];
          let zWidths = [];
          // Store same size `drag items` heights and widths, they may differ.
          let eHeights = [];
          let eWidths = [];
          _.each(sameSizeZones, z => {
            if (!hasSameSizEmptyZone && !z.children.length) {
              hasSameSizEmptyZone = true; // We have empty zone option.
            }
            // Store heights and widths of possible options.
            zHeights.push(z.offsetHeight);
            zWidths.push(z.offsetWidth);

            if (
              sourceZone &&
              z.getAttribute('drop-zone-size') ===
              scope.dragElement.getAttribute('drag-size')
            ) {
              // This zone only accept our size of elements, store the actual
              // drag item's dimension or the current zone's size if it's bigger
              exactW.push(
                Math.max(scope.dragElement.offsetWidth, z.offsetWidth)
              );
              exactH.push(
                Math.max(scope.dragElement.offsetHeight, z.offsetHeight)
              );
            }
          });
          _.each(SameSizeElements, e => {
            // Consider that other drag items can be larger than the one we're
            // currently dragging.
            eHeights.push(e.offsetHeight);
            eWidths.push(e.offsetWidth);
          });
          if (hasSameSizEmptyZone && sourceZone) {
            // Only set any zones height or width if we have empty options.
            propsNeeded.minWidth = Math.max(...zWidths);
            propsNeeded.minHeight = Math.max(...zHeights);
            propsNeeded.exactHeight = Math.max(...exactH, ...eHeights);
            propsNeeded.exactWidth = Math.max(...exactW, ...eWidths);
            propsNeeded.elementSize =
              scope.dragElement.getAttribute('drag-size');
          }
        }
        $rootScope.$broadcast( // Make same size empty drop zones visible
          'pxn-DND:toggle-empty-options', sourceZone ? propsNeeded : null
        );
        if (!sourceZone) scope.elementSize = null;
      }

      /**
       * Set the opacity of the drop zone to the passed visibility argument.
       *
       * @param {String} dropZone - Target zone.
       * @param {Number} visibility - Opacity value.
       * @returns {Undefined} - Nothing is returned.
       */
      function setDropZoneOpacity (dropZone, visibility) {
        if (!dropZone) return;
        if (
          dropZone.getAttribute('drop-zone-size') === scope.elementSize ||
          _.contains(
            dropZone.getAttribute('drop-zone-size').split(' '),
            scope.elementSize)
          ) {
          dropZone.style.opacity = visibility;
        }
      }

      /**
       * Returns the parent drop zone of the given element.
       *
       * @param {HTMLElement} el - HTML element.
       * @returns {HTMLElement} - The given HTML elements drop zone parent.
       */
      scope.getDropParent = function (el) {
        let isDropZone;
        do {
          isDropZone = el.hasAttribute('drop-zone-size');

          // Not looking for drop parent outside of flare.
          if (
            el.classList.contains('flare-body') || el.parentElement === null
          ) { return null; }

          if (isDropZone) { // Found a drop zone
            if (!scope.elementSize) {
              // No elementsize specified, return the first drop parent
              return el;
            } else if (
              // Drag item fits in this container, return it.
              el.getAttribute('drop-zone-size') === scope.elementSize ||
              _.contains(
                el.getAttribute('drop-zone-size').split(' '), scope.elementSize)
            ) { return el; }
            // We looking for a specific size drop parent, this isn't the one,
            // set isDropZone to false so we don't exit the loop.
            isDropZone = false;
          }
          el = el.parentElement;
        }
        while (!isDropZone || el === document);
        return el;
      };

      /**
       * Returns the parent drag item of the given element.
       *
       * @param {HTMLElement} el - HTML element.
       * @returns {HTMLElement} - The given HTML elements drop zone parent.
       */
      function getDragItemParent (el) {
        let isDragItem;
        do {
          isDragItem = el.hasAttribute('drag-size');

          if (
            el.classList.contains('flare-body') || el.parentElement === null
          ) { return null; }

          if (isDragItem) return el;
          el = el.parentElement;
        }
        while (!isDragItem || el === document);
        return el;
      }

      /**
       * Returns the parent drag area of the given element.
       *
       * @param {HTMLElement} el - HTML element.
       * @returns {HTMLElement} - The given HTML elements drag area parent.
       */
      function getDragAreaParent (el) {
        let isDragArea;
        let size = scope.dragElement.getAttribute('drag-size');
        do {
          isDragArea = el.hasAttribute('area-size');

          if (
            el.classList.contains('flare-body') || el.parentElement === null
          ) { return null; }

          if (
            isDragArea && (
              el.getAttribute('area-size') === size ||
              _.contains(el.getAttribute('area-size').split(' '), size)
            )
          ) {
            return el;
          }
          el = el.parentElement;
        }
        while (!isDragArea || el === document);
        return null;
      }

      /**
       * Removes .dragImageNoBackgroundColor class from drag items.
       *
       * @returns {Undefined} - Nothing is returned
       */
      function removeBgColorClass () {
        let dragElementsNoBgColor =
          document.querySelectorAll('.dragImageNoBackgroundColor');
        _.each(dragElementsNoBgColor, function (el) {
          el.classList.remove('dragImageNoBackgroundColor');
        });
        scope.noBackgroundColor = false;
      }

      /**
       * Compares the current layout and layout before edit objects then returns
       * a boolean, TRUE if the layout has been changed.
       *
       * @returns {Boolean} - TRUE if the layout has been changed.
       */
      scope.hasLayoutChanged = function () {
        return !_.isEqual(
          previewCtrl.templateScope.content.layout, scope.layoutBeforeEdit
        );
      };

      // //////////////// //
      //  Drag functions  //
      // //////////////// //

      scope.dragstart = function (e) {
        // Store the drag items size as we have no access to the dragdata on
        // dragover.
        if (!e.target.attributes || !e.target.hasAttribute('drag-size')) {
          // It's possible to grab just the content of a drag item e.g. only the
          // text from a paragrap and not the paragraph itself as the drag item.
          // In this case, since it doesn't have the required properties,
          // we select it's parent drag item to set it as the drag element.
          scope.dragElement = getDragItemParent(e.target.parentElement);
          if (!scope.dragElement) return;
          scope.elementSize = scope.dragElement.getAttribute('drag-size');
        } else {
          scope.elementSize = e.target.getAttribute('drag-size');
          scope.dragElement = e.target;
        }

        if (
          scope.layoutBeforeEdit && !scope.layoutBeforeEdit.elements ||
          !Object.keys(scope.layoutBeforeEdit.elements).length
        ) {
          // Elements will be an empty object in layout map on local slides.
          previewCtrl.previewScope.slide.layoutBeforeEdit =
          angular.copy(previewCtrl.templateScope.content.layout);
        }

        scope.dragArea = getDragAreaParent(scope.dragElement);

        let dropParent = scope.getDropParent(scope.dragElement);
        scope.sourceElement = dropParent;

        toggleEmptyOptions(dropParent);

        // Set drag data.
        e.dataTransfer.setData('source', dropParent.getAttribute('drag-id'));
        e.dataTransfer.setData(
          'text/plain', scope.dragElement.getAttribute('drag-id')
        );
        e.dataTransfer.dropEffect = 'move';

        let dragImg = document.querySelector(
          `[drag-id="${scope.dragElement.getAttribute('drag-id')}"]`
        );
        // Drag image position always at (0, 0)
        e.dataTransfer.setDragImage(dragImg, 0, 0);

        scope.$broadcast('pxn-DND:set-drop-zones-opacity', {
          size: scope.elementSize,
          opacity: DROP_AREA
        });
      };

      scope.dragover = function (e) {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';

        // We are animating, return early.
        if (scope.animating) return;

        let dropZone = scope.getDropParent(e.target);
        let sourceZone = scope.sourceElement;

        if (!sourceZone) return;

        scope.elementSize = scope.dragElement.getAttribute('drag-size');

        if (
          dropZone && dropZone !== sourceZone && (
            dropZone.getAttribute('drop-zone-size') === scope.elementSize ||
            _.contains(
              dropZone.getAttribute('drop-zone-size').split(' '),
              scope.elementSize
            )
          )
        ) {
          // Set the drop zones opacity lower if the dragged item will fit there
          setDropZoneOpacity(dropZone, DROP_AREA_HOVER);

          // Animate elements to their new position.
          scope.animateElement(scope.dragElement, sourceZone, dropZone);

          // Check if we should reset scope.dragArea.
          let zoneArea = getDragAreaParent(dropZone);
          if (scope.dragArea && !zoneArea) scope.dragArea = null;

          // Current dropZone now is the new sourceElement of the drag item.
          scope.sourceElement = dropZone;
          scope.elementSize = scope.dragElement.getAttribute('drag-size');

          // Set the slide modified.
          setSlideModified();
        }
      };

      // Only gets called when e.target is a drop zone.
      scope.drop = function (e) {
        e.preventDefault();
        if (!scope.dragElement) return;

        scope.elementSize = scope.dragElement.getAttribute('drag-size');
        toggleEmptyOptions();

        // Remove background color if we added it to the drag element.
        if (scope.noBackgroundColor) removeBgColorClass();

        scope.dragElement = null;
      };

      // ////////////////////////////// //
      //  DND event listener functions  //
      // ////////////////////////////// //

      /**
       * Event listener function for dragover.
       *
       * @param {Object} e - Event object.
       * @returns {Undefined} - Nothing is returned.
       */
      function previewDragover (e) {
        let hoveredArea = getDragAreaParent(e.target);
        if (
          !hoveredArea &&
          (!scope.dragArea || !scope.dragElement || scope.animating)
        ) return;

        if (
          // If we have a drag area set AND
          angular.isElement(scope.dragArea) &&
          // if we are not hovering over a drag area OR
          (!hoveredArea || (
            // the current and the hovered area are different sizes AND
            scope.dragArea.getAttribute('area-size') !==
            hoveredArea.getAttribute('area-size') &&
            // the hovered area supports multiple sizes but doesn't contains the
            // current drag area size AND
            !_.contains(
              hoveredArea.getAttribute('area-size').split(' '),
              scope.dragArea.getAttribute('area-size')
            ) &&
            // the current drag area supports multiple sizes but not the hovered
            // area size.
            !_.contains(
              scope.dragArea.getAttribute('area-size').split(' '),
              hoveredArea.getAttribute('area-size')
            )
          ))
        ) { return; } // The new area doesn't have the same size drop zone in it

        if (
          !scope.dragArea ||
          scope.dragArea.getAttribute('area') !==
          hoveredArea.getAttribute('area')
        ) {
          scope.dragArea = hoveredArea;
          let dropZone = document.querySelector(`[drag-id="${
            scope.dragArea.getAttribute('area')
          }"]`);

          // There should always be a drop zone within an area as we define them
          if (!dropZone) return; // Just in case...

          // The drop zone already has a children.
          if (dropZone.children.length) {
            // Save the children and it's current place in the DOM.
            scope.moveBackIfNotDropped = {
              el: dropZone.children[0],
              parentEl: dropZone
            };
          }
          // Animate drag item.
          scope.animateElement(
            scope.dragElement, scope.sourceElement, dropZone
          );

          setLayoutChanged(scope.hasLayoutChanged());
        }
      }

      /**
       * Event listener function for dragleave.
       *
       * @param {Object} e - Event object.
       * @returns {Undefined} - Nothing is returned.
       */
      function previewDragleave (e) {
        let dropZone = scope.getDropParent(e.target);
        setDropZoneOpacity(dropZone, DROP_AREA);
      }

      /**
       * Event listener function for dragend.
       *
       * @param {Object} e - Event object.
       * @returns {Undefined} - Nothing is returned.
       */
      function previewDragend (e) {
        // Element had no background color we added it for visibility.
        if (scope.noBackgroundColor) removeBgColorClass();

        scope.moveBackIfNotDropped = null;

        if (!scope.dragElement) return;

        // Set scope.elementSize for the following functions
        scope.elementSize = scope.dragElement.getAttribute('drag-size');
        toggleEmptyOptions();

        scope.dragElement = null;
      }

      scope.$on('$destroy', function () {
        _.each(deregisterArr, l => l());
        $document.off('dragover', previewDragover);
        $document.off('dragleave', previewDragleave);
        $document.off('dragend', previewDragend);
        $document.off('mousedown', hasBgColor);
      });
    }
  };
})

.directive('dragDivider', function (
  $window, $document, $rootScope, $log, $timeout, Onboarding
) {
  return {
    restrict: 'A',
    require: '?^^flareTemplatePreview',
    link: function dragDividerLink (scope, element, attributes, previewCtrl) {
      const dividerDirection = {
        row: 'horizontal',
        'row-reverse': 'horizontal',
        column: 'vertical',
        'column-reverse': 'vertical',
      };
      const changeFlexDirection = {
        row: 'column',
        'row-reverse': 'column-reverse',
        column: 'row',
        'column-reverse': 'row-reverse',
      };

      let layout = scope.content.layout;

      registerDivider();

      const defaultPosition = 50;
      const minVal = Number(attributes.minVal) || 0;
      const maxVal = Number(attributes.maxVal) || 100;
      const prevElem = element[0].previousSibling;
      const nextElem = element[0].nextSibling;
      const dividerName = attributes.dragDivider;

      let deregisterArr = [];
      let parentDirection =
        $window.getComputedStyle(element.parent()[0]).flexDirection;
      let dragDirection = dividerDirection[parentDirection];

      // Save current position of divider
      let currentPos;

      /**
       * Initialise the layout dividers object if it isn't already in the
       * content.
       *
       * @returns {Undefined} - Nothing is returned.
      */
      function registerDivider () {
        layout = scope.content.layout;

        // Initialise the layout dividers object if it isn't already in the
        // content.
        if (!layout) {
          layout = scope.content.layout = {
            dividers: {}
          };
        } else if (!layout.dividers) {
          layout.dividers = {};
        }
        if (!_.keys(layout.dividers)) {
          currentPos = getPositionFromDom();
          scope.content.layout.dividers[dividerName] = defaultPosition;
          setNewPosition(currentPos);
        }
      }

      /**
       * Sets the divider siblings flex basis.
       *
       * @param {Object} pos - Percentage of previous sibling flex direction.
       * @returns {Undefined} - Nothing is returned.
      */
      function setPosition (pos) {
        let prevElemBasis = `${pos}%`;
        let nextElemBasis = `${100 - pos}%`;
        prevElem.style.flexBasis = prevElemBasis;
        nextElem.style.flexBasis = nextElemBasis;
      }

      /**
       * Saving current divider position.
       *
       * @param {Object} pos - Percentage of previous sibling flex direction.
       * @returns {Undefined} - Nothing is returned.
      */
      function savePosition (pos) {
        scope.content.layout.dividers[dividerName] = pos;
      }

      /**
       * Calculates divider position.
       *
       * @returns {Number} - Divider position.
      */
      function getPositionFromDom () {
        let prevBasis = $window.getComputedStyle(prevElem).flexBasis;
        let nextBasis = $window.getComputedStyle(nextElem).flexBasis;
        // When flexBasis isn't set return default position.
        if (
          (prevBasis && !prevBasis.endsWith('%')) &&
          (nextBasis && !nextBasis.endsWith('%'))
        ) {
          return defaultPosition;
        }
        prevBasis = parseFloat(prevBasis);
        nextBasis = parseFloat(nextBasis);

        if (prevBasis || nextBasis) {
          return prevBasis || (100 - nextBasis);
        } else {
          return defaultPosition;
        }
      }

      /**
       * Sets divider direction
       *
       * @returns {Undefined} - Nothing is returned.
      */
      function setDividerDirections () {
        parentDirection =
          $window.getComputedStyle(element.parent()[0]).flexDirection;
        dragDirection = dividerDirection[parentDirection];

        element
        .removeClass(`divider--${
          dividerDirection[changeFlexDirection[parentDirection]]
        }`)
        .addClass(`divider--${dragDirection}`)
        .attr('drag-direction', dragDirection);
      }

      if (layout && layout.dividers && typeof layout.dividers[dividerName] === 'number') {
        currentPos = layout.dividers[dividerName];
      } else {
        // Read the position from the DOM.
        let domPos = getPositionFromDom();
        currentPos = domPos;
      }
      setNewPosition(currentPos);
      setMinMax();

      /**
       * Set divider siblings minimum width and height.
       *
       * @returns {Undefined} - Nothing is returned.
      */
      function setMinMax () {
        if (dragDirection === 'horizontal') {
          angular.element(prevElem).css({
            minWidth: `${minVal}%`,
            maxWidth: `${maxVal}%`,
            minHeight: '',
            maxHeight: ''
          });
          angular.element(nextElem).css({
            minWidth: `${100 - maxVal}%`,
            maxWidth: `${100 - minVal}%`,
            minHeight: '',
            maxHeight: ''
          });
        } else if (dragDirection === 'vertical') {
          angular.element(prevElem).css({
            minHeight: `${minVal}%`,
            maxHeight: `${maxVal}%`,
            minWidth: '',
            maxWidth: ''
          });
          angular.element(nextElem).css({
            minHeight: `${100 - maxVal}%`,
            maxHeight: `${100 - minVal}%`,
            minWidth: '',
            maxWidth: ''
          });
        }
      }

      if (!attributes.alwaysVisible) element.css('display', 'none');

      let startX;
      let startY;
      let startPos;

      /**
       * Setting variables on divider mousedown.
       *
       * @param {Object} e - Event object.
       * @returns {Undefined} - Nothing is returned.
      */
      function onDividerMousedown (e) {
        if (!scope._editingLayout) return;
        e.stopImmediatePropagation();
        e.preventDefault();
        startX = e.clientX;
        startY = e.clientY;
        startPos = currentPos;
        element.parent()[0].addEventListener('mousemove', onDividerDrag);
        $document.on('mouseup', onDividerMouseup);
      }

      /**
       * Calculates the percentage value from the pixel amount the divider moved
       *
       * @param {Object} obj.width - Width of mousemove in pixels.
       * @param {Object} obj.height - Height of mousemove in pixels.
       * @returns {Number} - Mousemove in percentage.
      */ // eslint-disable-next-line consistent-return
      function getPercentageFromPixels ({ width, height }) {
        if (!previewCtrl) $log.warn('[Drag divider] only works in preview');
        if (width) {
          return (width / element.parent()[0].clientWidth) * 100;
        } else if (height) {
          return (height / element.parent()[0].clientHeight) * 100;
        }
      }

      /**
       * Updates the slides layout.
       *
       * @param {Object} e - Event object.
       * @returns {Undefined} - Nothing is returned.
       */
      function onDividerDrag (e) {
        let dx = e.clientX - startX;
        let dy = e.clientY - startY;

        if (
          dragDirection === 'vertical' && !dy ||
          dragDirection === 'horizontal' && !dx
        ) { return; } // Nothing to update, return early.

        // Set siblings flex-basis.
        if (dragDirection === 'vertical') {
          if (parentDirection === 'column') {
            currentPos = startPos + getPercentageFromPixels({ height: dy });
          } else if (parentDirection === 'column-reverse') {
            currentPos = startPos - getPercentageFromPixels({ height: dy });
          }
        } else if (dragDirection === 'horizontal') {
          if (parentDirection === 'row') {
            currentPos = startPos + getPercentageFromPixels({ width: dx });
          } else if (parentDirection === 'row-reverse') {
            currentPos = startPos - getPercentageFromPixels({ width: dx });
          }
        }
        setPosition(currentPos);
        previewCtrl.previewScope.slide.setModified(true);
        if (previewCtrl.setEditorDirty) {
          previewCtrl.setEditorDirty();
        }
        previewCtrl.previewScope.slide._layoutChanged = true;
      }

      /**
       * Resetting variables when the divider is released.
       *
       * @param {Object} e - Event object.
       * @returns {Undefined} - Nothing is returned.
       */
      function onDividerMouseup (e) {
        $timeout(Onboarding.triggerOverviewResourceAvailable());
        element.parent()[0].removeEventListener('mousemove', onDividerDrag);
        // If currentPos is out of bounds cap it's value to min or max value.
        if (currentPos > maxVal) {
          currentPos = maxVal;
          setPosition(currentPos);
        } else if (currentPos < minVal) {
          currentPos = minVal;
          setPosition(currentPos);
        }
        savePosition(currentPos);
        $document.off('mouseup', onDividerMouseup);
      }

      /**
       * Set and store new position for divider.
       *
       * @param {Number} pos - The new position for divider.
       * @returns {Undefined} - Nothing is returned.
       */
      function setNewPosition (pos) {
        currentPos = pos;
        setPosition(currentPos);
        savePosition(currentPos);
        setDividerDirections();
        setMinMax();
      }

      deregisterArr.push( // Reset divider
        scope.$on('pxn-DND:reset-dividers', function (e, dividers) {
          if (
            !dividers ||
            !_.includes(Object.keys(dividers), attributes.dragDivider)
          ) {
            setNewPosition(defaultPosition);
            return;
          }
          setNewPosition(dividers[dividerName]);
        }));

      // Set dividers on layout reordered.
      deregisterArr.push($rootScope.$on(
        'pxn-DND:preview-layout-reordered', setDividerDirections));

      // Re-register divider
      deregisterArr.push(scope.$on(
        'pxn-DND:re-register-layout', registerDivider));

      deregisterArr.push( // On rotate layout...
        $rootScope.$on('pxn-DND:rotate-preview-layout', () => {
          setDividerDirections();
          setMinMax();
        }));

      deregisterArr.push(
        $rootScope.$on('pxn-DND:edit-preview-layout', function (
          e, editingState
        ) {
          scope._editingLayout = editingState;
          if (scope._editingLayout) {
            element.addClass('moveable');

            // Add listeners.
            element.on('mousedown', onDividerMousedown);

            if (!element.attr('always-visible')) {
              element.css('display', '');
            }
            setDividerDirections();
          } else {
            element.removeClass('moveable');

            // Remove listeners.
            element.off('mousedown', onDividerMousedown);

            if (!element.attr('always-visible')) {
              element.css('display', 'none');
            }
          }
        }));

      deregisterArr.push( // On revert layout changes...
        $rootScope.$on('pxn-DND:revert-layout-changes', function (e, revertTo) {
          layout = scope.content.layout;
          let hasSavedPosition =
            typeof revertTo.layout.dividers[
              attributes.dragDivider
            ] === 'number';
          setNewPosition(
            hasSavedPosition ?
              revertTo.layout.dividers[attributes.dragDivider] :
              defaultPosition
          );
        }));

      element.on('$destroy', function () {
        _.each(deregisterArr, l => l());
      });

      scope.$on('$destroy', function () {
        _.each(deregisterArr, l => l());
      });
    }
  };
})

.directive('dragSize', function ($rootScope) {
  return {
    restrict: 'A',
    require: '?^^pxnDragNDrop',
    link: function dragItemLink (scope, element, attributes, dndCtrl) {
      if (!dndCtrl) return;
      let layout, parentZone;

      // Returns a boolean whether or not element is registered in the layout.
      // eslint-disable-next-line func-style
      let isRegistered = () => {
        let layoutHasIt = false;
        // eslint-disable-next-line consistent-return
        _.each(Object.keys(layout.elements), el => {
          if (layout.elements[el] === attributes.dragId) {
            layoutHasIt = true;
            // When this drag item has a different parent zone as defined in the
            // layout object move it to it's assigned parent zone.
            if (
              !dndCtrl.templateChange() && dndCtrl.previewLoaded() &&
              el !== parentZone.getAttribute('drag-id')
            ) {
              // Only move elements here when we are not selecting a different
              // template and the preview is loaded.
              document.querySelector(`[drag-id=${el}]`).appendChild(element[0]);
            }
            return false;
          }
        });
        return layoutHasIt;
      };

      /**
       * Initialise the layout elements object if it isn't already in the
       * content.
       *
       * @returns {Undefined} - Nothing is returned.
      */
      function registerDragItem () {
        layout = scope.content.layout;
        if (!layout) {
          layout = scope.content.layout = {
            elements: {}
          };
        } else if (layout && !layout.elements) {
          layout.elements = {};
        }

        parentZone = dndCtrl.getDropParent(element[0]);

        // If drag item isn't mapped add it to the layout.
        if (!isRegistered()) {
          layout.elements[parentZone.getAttribute('drag-id')] = attributes.dragId;
        }
      }

      /**
       * Adds event listeners to drag items if needed, also sets draggable attr.
       *
       * @param {Boolean} state - Editing state.
       * @returns {Undefined} - Nothing is returned.
      */
      function setEventListeners (state) {
        if (state) {
          element.addClass('moveable');
          element.on('dragstart', dndCtrl.dragstart);
        } else {
          element.removeClass('moveable');
          element.off('dragstart', dndCtrl.dragstart);
        }
        element.prop('draggable', state);
      }

      registerDragItem();

      // Add event listeners if needed.
      setEventListeners(scope._editingLayout);

      let deregisterArr = [];

      // Re-register drop xone.
      deregisterArr.push(scope.$on(
        'pxn-DND:re-register-layout', registerDragItem));

      deregisterArr.push($rootScope.$on('pxn-DND:edit-preview-layout', function (
        e, editing
      ) { // Toggle classes, listeners and draggable attribute with editingState
        if (element) setEventListeners(editing);
      }));

      scope.$on('$destroy', ()=> {
        _.each(deregisterArr, l => l());
      });
      element.on('$destroy', ()=> {
        _.each(deregisterArr, l => l());
      });
    }
  };
})

.directive('dropZoneSize', function ($rootScope, $window) {
  return {
    restrict: 'A',
    require: '?^^pxnDragNDrop',
    link: function dropZoneLink (scope, element, attributes, dndCtrl) {
      if (!dndCtrl) return;
      let layout;

      /**
       * Adds event listeners to drop zones if needed.
       *
       * @param {Boolean} state - Editing state.
       * @returns {Undefined} - Nothing is returned.
      */
      function setEventListeners (state) {
        if (!state) {
          element.off('drop', dndCtrl.drop);
          element.off('dragover', dndCtrl.dragover);
        } else {
          element.on('drop', dndCtrl.drop);
          element.on('dragover', dndCtrl.dragover);
        }
      }

      registerZone();

      /**
       * Initialise the layout elements object if it isn't already in the
       * content.
       *
       * @returns {Undefined} - Nothing is returned.
      */
      function registerZone () {
        layout = scope.content.layout;

        if (!layout) {
          layout = scope.content.layout = {
            elements: {}
          };
        } else if (layout && !layout.elements) {
          layout.elements = {};
        }
      }

      // Append child element zone should contain.
      if (layout.elements[attributes.dragId]) {
        setTimeout(function () {
          element.append(document.querySelector(`[drag-id="${
            layout.elements[attributes.dragId]
          }"]`));
        }, 0);
      }

      if (!layout.elements[attributes.dragId]) {
        layout.elements[attributes.dragId] = null; // Register drop zone
      }

      setEventListeners(scope._editingLayout);

      let deregisterArr = [];

      deregisterArr.push( // Toggle event listeners with editing state.
        $rootScope.$on('pxn-DND:edit-preview-layout', function (e, editing) {
          setEventListeners(editing);
        }));

      // Changes the background color of the drop zones where the dragged
      // item can be droppped.
      deregisterArr.push(
        $rootScope.$on('pxn-DND:toggle-empty-options', function (e, rules) {
          if (
            rules && (
              attributes.dropZoneSize !== rules.elementSize &&
              !_.contains(attributes.dropZoneSize.split(' '), rules.elementSize)
            )
          ) {
            return;
          }

          if (rules) {
            if (
              $window.getComputedStyle(element[0])
                .backgroundColor === 'rgba(0, 0, 0, 0)'
            ) {
              // This drop zone doesn't have a background color, set it now to
              // make it visible to the user this is a drop zone for the dragged
              // element.
              element.css('backgroundColor', 'rgba(0, 0, 0, 0.1)');
            }
            let exactMatch = attributes.dropZoneSize === rules.elementSize;
            if (exactMatch) {
              // This drop zone only supports the dragged element's size, set
              // it to be just big enough to accept our drag element.
              element.css({
                width: rules.exactWidth + 'px',
                height: rules.exactHeight + 'px'
              });
            } else {
              // This drop zone supports multiple element sizes, make it to be
              // as big as it could get so no drag item will be distorted during
              // dragging.
              element.css({
                width: Math.max(
                  rules.minWidth, element[0].offsetWidth) + 'px',
                height: Math.max(
                  rules.minHeight, element[0].offsetHeight) + 'px'
              });
            }
          } else {
            element.css({ // Clear css properties after drag has ended.
              backgroundColor: '', width: '', height: '', opacity: ''
            });
          }
        }));

      deregisterArr.push( // Setting possible drop zones opacity.
        scope.$on('pxn-DND:set-drop-zones-opacity', function (e, settingObj) {
          if (
            attributes.dropZoneSize === settingObj.size ||
            _.contains(attributes.dropZoneSize.split(' '), settingObj.size)
          ) {
            element.css('opacity', settingObj.opacity);
          }
        }));

      // Re-register drop xone.
      deregisterArr.push(scope.$on(
        'pxn-DND:re-register-layout', registerZone));

      deregisterArr.push( // On revert layout changes...
        $rootScope.$on('pxn-DND:revert-layout-changes', function (e, revertTo) {
          layout = scope.content.layout;
          if (
            !Object.keys(revertTo.layout.elements).length ||
            revertTo.layout.elements[attributes.dragId] === null
          ) { // Zone has to be empty
            layout.elements[attributes.dragId] = null;
            return;
          }
          let child = document.querySelector(`[drag-id="${
            revertTo.layout.elements[attributes.dragId]}"]`);
          if (!child) {
            layout.elements[attributes.dragId] = null;
            return;
          }
          let fromElement = dndCtrl.getDropParent(child);

          if (child) { // Animate children where it belongs.
            if (revertTo.animate) {
              dndCtrl.animateElement(child, fromElement, element[0]);
            } else {
              element[0].appendChild(child);
            }
            layout.elements[attributes.dragId] = // Update layout.
              revertTo.layout.elements[attributes.dragId];
          }
        }));

      element.on('$destroy', function () {
        _.each(deregisterArr, l => l());
      });

      scope.$on('$destroy', function () {
        _.each(deregisterArr, l => l());
      });
    }
  };
})
.directive('dndContainer', function ($timeout) {
  return {
    restrict: 'A',
    link: function (scope) {
      // Timeout to let ngIf run first
      $timeout(() => {
        scope.$emit('pxn-DND:template-container-changed');
      });
      scope.$on('$destroy', function () {
        scope.$emit('pxn-DND:template-container-removed');
      });
    }
  };
});
