
angular.module('flare.receiver.flowcontrol', [])

/**

  This function exposes an `attempt` method which executes a given function with
  a timeout. If the function fails or times out, it will be retried for a
  specified number of attempts.

  The function returns a promise which is notified of each retry, and will
  eventually resolve or reject with the result.

*/

.factory('FlowControl', function ($timeout, $q, $log) {

  return {

    attempt: function attempt (fn, args, options) {
      // Ensure the args are in array format.
      if (args != null && (args instanceof Array !== true)) {
        args = [args];
      }
      // Set some sensible defaults
      options = _.defaults(options, {
        maxRetries: 3,
        timeoutMs: 10000,
        name: 'Function',
        initialRetryDelay: 5000,
        retryBackoffFactor: 2,
        maxRetryDelay: 60000,
        thisArg: null
      });

      // Init
      var deferred    = $q.defer(),
        success       = false,
        error         = null,
        retries       = 0,
        retryDelay    = options.initialRetryDelay,
        timeout;

      function cancelTimeout () {
        if (timeout) {
          $timeout.cancel(timeout);
        }
      }

      // Rerun the function or reject if we've hit max retries.
      function doRetry () {
        // If it's already succeeded, we don't need to retry.
        if (success === true) return;

        if (++retries < options.maxRetries) {

          deferred.notify(retries);
          $log.debug('[Retry] Retrying ' + options.name);
          attemptFunction();

        } else {

          deferred.reject({
            rejector: 'RetryFactory',
            reason: 'Maximum retries reached',
            data: {
              attempts: retries - 1
            }
          });

        }

      }

      //
      function attemptFunction () {

        // Start a timeout
        timeout = $timeout(function () {
          $log.debug('[FlowControl] ' + options.name + ' timed out.');
          doRetry();
        }, options.timeoutMs);
        var result;
        try {
          result = fn.apply(options.thisArg, args);
        } catch (e) {
          error = e;
        }

        // Handle the case where a the function doesn't return a promise.
        if (result == null || (typeof result.then != 'function')) {
          if (!error) {
            deferred.resolve(result);
          } else {
            deferred.reject(error);
          }
        } else {
          // The function returns a promise
          result
            .then(function taskSucceeded (data) {
              if (success !== true) {
                cancelTimeout();
                success = true;
                deferred.resolve(data);
              }
            })
            .catch(function taskFailed (err) {
              cancelTimeout();
              $log.debug('[Retry] ' + options.name + ' failed, retrying');
              $log.error(
                '[FlowControl] ' +
                  options.name +
                ' task failed, retrying after ' +
                retryDelay + ' ms. Reason: '  +
               (err.message || err)
              );
              $timeout(doRetry, retryDelay);
              retryDelay *= options.retryBackoffFactor;
              retryDelay = Math.min(retryDelay, options.maxRetryDelay);
            });
        }

      }

      attemptFunction();

      deferred.promise.progress =
        _.bind(deferred.promise.then, deferred.promise, null, null);

      return deferred.promise;

    }
  };

});
