/*******************************************/
/*              pxn draggable              */
/*                                         */
/*  A drag and drop module which requires  */
/*  little custom code to be used &        */
/*  supports dragging and dropping between */
/*  collections. Example provided below.   */
/*                                         */
/*******************************************/

/*******************************************/
/*             Example (jade)              */
/*******************************************/
// div(
//   ng-repeat="item in collection"
// )
//   drag-item(
//     drag-data="{\
//       fromIndex: $index,\
//       fromCollection: collection,\
//       item: item,\
//     }"
//   )
//     drop-target(
//       // false represents "after"
//       drop-action="moveItem(dragData, false, $index, collection)"
//     )
//     drop-target(
//       // true represents "after"
//       drop-action="moveItem(dragData, true, $index, collection)"
//     )


/*******************************************/
/*          Example (litcoffee)            */
/*******************************************/
// $ctrl.moveItem = (dragData, after, toIndex, toCollection)->
//   item = dragData.item
//   fromCollection = dragData.fromCollection
//   fromIndex = dragData.fromIndex
//
//   if fromIndex < toIndex then toIndex -= 1
//   if after then toIndex += 1
//
//   fromCollection.splice(fromIndex, 1)
//   toCollection.splice(toIndex, 0, item)


/*******************************************/
/*            Example (stylus)             */
/*******************************************/
// drag-item
//  display flex
//  flex-direction row !important
//  user-select none
//
// drop-target
//   display flex
//   flex 1


angular.module('pxn-draggable', [])

.factory('DragData', function () {
  var data;
  return {
    getData: function () {
      return data;
    },
    setData: function (d) {
      data = d;
    },
    clearData: function () {
      data = null;
    }
  };
})

.component('dragItem', {
  bindings: {
    dragData: '<',
    onDragStart: '&',
    onDragEnd: '&',
  },
  controller: function (
    $scope,
    $element,
    $document,
    DragData,
    $timeout,
    Vibrator
  ) {
    var $ctrl = this;
    var LONG_PRESS_DURATION = 800;
    var VIBRATION_DURATION = 100;
    var touchTimeout;
    var touchDragging = false;
    var touchOverElement;
    var lastTouch;
    var $ghost;
    $ctrl.copy = false;

    function saveLastTouch (e) {
      // Stop page refreshing and expanding the sidebar on drag.
      e.preventDefault();
      e.stopPropagation();
      // Save the last touch location data because touchend doesn't have it.
      lastTouch = e.touches[0];
      $ghost.css({
        top: lastTouch.pageY + 1 + 'px',
        left: lastTouch.pageX + 1 + 'px',
      });

      // This is to enable dragenter & dragexit events on mobile.
      var newTouchOverElement = document.elementFromPoint(
        lastTouch.clientX,
        lastTouch.clientY
      );
      if (touchOverElement == null) {
        touchOverElement = newTouchOverElement;
      }
      angular.element(newTouchOverElement).triggerHandler('dragenter', e);
      if (newTouchOverElement !== touchOverElement) {
        angular.element(touchOverElement).triggerHandler('dragleave', e);
      }
      touchOverElement = newTouchOverElement;
    }

    $element.on('dragstart', function (e) {
      DragData.setData($ctrl.dragData);
      $scope.$apply(function () {
        $ctrl.onDragStart();
      });
    });

    $element.on('dragend', function () {
      DragData.clearData();
      $scope.$apply(function () {
        $ctrl.onDragEnd();
      });
    });

    if ($element.attr('disabled')) {
      $element.attr('draggable', false);
    } else {
      $element.attr('draggable', true);
    }

    // Add mobile support
    $element.on('touchstart', function (e) {
      if (!$element.attr('draggable')) return;
      touchTimeout = $timeout(function () {
        Vibrator.vibrate(VIBRATION_DURATION);
        $element.triggerHandler('dragstart');
        touchDragging = true;
        $ghost = $element.clone();
        var dims = $element[0].getBoundingClientRect();
        $ghost.css({
          position: 'absolute',
          width: dims.width + 'px',
          height: dims.height + 'px',
          top: e.touches[0].pageY + 1 + 'px',
          left: e.touches[0].pageX + 1 + 'px',
          opacity: 0.7,
        });
        $document.find('body').append($ghost);
        $element.on('touchmove', saveLastTouch);
      }, LONG_PRESS_DURATION);
    });

    // $element.on('touchmove', function (e) {
    //   saveLastTouch();
    //   var newTouchOverElement = document.elementFromPoint(
    //     lastTouch.clientX,
    //     lastTouch.clientY
    //   );
    //   if (touchOverElement == null) {
    //     touchOverElement = newTouchOverElement;
    //   }
    //   angular.element(newTouchOverElement).triggerHandler('dragenter', e);
    //   if (newTouchOverElement !== touchOverElement) {
    //     angular.element(touchOverElement).triggerHandler('dragleave', e);
    //   }
    //   touchOverElement = newTouchOverElement;
    // });

    $element.on('touchend', function (e) {
      $timeout.cancel(touchTimeout);
      if (touchDragging) {
        $element.triggerHandler('dragend', e);
        if (lastTouch) {
          Vibrator.vibrate(VIBRATION_DURATION);
          var dropTarget = document.elementFromPoint(
            lastTouch.clientX,
            lastTouch.clientY
          );
          angular.element(dropTarget).triggerHandler('drop', e);
        }
      }
      lastTouch = touchDragging = null;
      $element.off('touchmove', saveLastTouch);
      $ghost.remove();
    });

  },
})

.component('dropTarget', {
  bindings: {
    dropAction: '&',
    onDragEnter: '&',
    onDragExit: '&',
    onDragEnd: '&',
  },
  controller: function ($scope, $element, DragData) {
    var $ctrl = this;

    $element.on('dragenter', function (e) {
      e.preventDefault();
      if ($ctrl.onDragEnter) {
        $scope.$apply(function () {
          $ctrl.onDragEnter();
        });
      }
    });

    $element.on('dragleave dragexit', function () {
      if ($ctrl.onDragExit) {
        $scope.$apply(function () {
          $ctrl.onDragExit();
        });
      }
    });

    $element.on('dragover', function (e) {
      e.preventDefault();
    });

    $element.on('drop', function (e) {
      $scope.$apply(function () {
        $ctrl.dropAction({
          $event: e,
          dragData:  DragData.getData(),
        });
        $ctrl.onDragExit();
        $ctrl.onDragEnd();
      });
    });

  },
})

// This directive allows other elements to be dropped through it by temporarily
// setting their `pointer-events` css property for none when they are dragged
// over, and resetting it when the document detects the drag event has ended.
.directive('dropThrough', function ($document) {
  return {
    restrict: 'A',
    controller: function ($element, $scope) {
      var cursorEventsDisabled = false;
      $element.on('dragover dragmove', function (e) {
        if (cursorEventsDisabled) return;
        $element.css({
          'pointer-events': 'none'
        });
        cursorEventsDisabled = true;
      });

      $document.on('drop dragend', function () {
        if (!cursorEventsDisabled) return;
        $element.css({
          'pointer-events': 'all'
        });
        cursorEventsDisabled = false;
      });

      $scope.$on('$destroy', function () {
        $element.off('dragover dragmove');
        $document.off('drop dragend');
      });
    }
  };
});
