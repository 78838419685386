angular.module('flare.receiver.connectivity', [])
.directive('flareConnectivityIndicator', function (Config) {
  return {
    restrict: 'E',
    templateUrl: 'utility/connectivity-indicator.jade',
    controllerAs: '$ctrl',
    controller: function connIndicatorCtrl (
      $log, $injector, $timeout, Locale
    ) {
      var DISPLAY_DELAY = 180000; // 3 minutes.
      var Events = $injector.get('Events');

      this.visible = false;
      this.enabled = false;

      this.timerPromise = null;

      function showAfterTimer () { // eslint-disable-line
        $log.debug('[Connectivity indiciator] detected disconnect');
        if (this.timerPromise === null) {
          this.timerPromise = $timeout((function () {
            this.visible = true;
          }).bind(this), DISPLAY_DELAY);
        }
      }

      Events.on('disconnect', showAfterTimer.bind(this));

      Events.on('connect', (function onConnected () {
        if (this.timerPromise) {
          $timeout.cancel(this.timerPromise);
          this.timerPromise = null;
        }
        this.visible = false;
        $log.debug('[Connectivity indiciator] detected connect');
      }).bind(this));

      Events.on('config', (function (deviceConfig) {
        // Apply changes to config object.
        _.assign(Config, deviceConfig);
        this.enabled = deviceConfig.offlineIndicators;
        $log.debug('[Connectivity indiciator] Enabled: ' + this.enabled);
        if (deviceConfig.offlineName) {
          this.name = deviceConfig.name;
        } else {
          this.name = null;
        }
        if (deviceConfig.localisation && deviceConfig.localisation.enabled) {
          $log.debug(
            '[Config] Setting device locale to ' + deviceConfig.locale
          );
          Locale.setLocalisation(
            deviceConfig.localisation, deviceConfig.locale
          );
        } else {
          $log.debug(
            '[Config] Localisation disabled, using "All screens" locale (0)'
          );
          Locale.setLocalisation(deviceConfig.localisation, 0);
        }
      }).bind(this));

    }
  };
});
