angular
  .module('pxn-resource-management', [])

  .factory('resourceRequestInterceptor', function (
    $q,
    $timeout,
    $injector,
    Toast,
    HelpText
  ) {
    const REQUEST_TIMEOUT_MS = 30000;
    const INITIAL_BACKOFF_MS = 2500;
    const DUPLICATE_REQUEST_STATUS_CODE = 999;
    const MAX_TRIES = 3;

    // This is an array of matchers (either strings or regexes) that will bypass
    // the 30 second timeout.
    const EXCEPTION_MATCHERS = [
      'api/templates/usage',
      'api/slides/',
      /api\/duplicates\/[A-Za-z0-9]{20}\/metadata/
    ];

    // Keeps a reference to the deferred returned to request originator,
    // and the request config. Keyed by ID
    // { url: { config, deferred, timeoutPromise, isInFlight } } keyed by URL

    // timeout is truthy only if we're in a cooldown before retry
    // isInFlight is true if there is an http request pending.
    const openRequests = {};

    // NOTE: Backoff durations are generated to allow customisation without
    // brain power, change either INITIAL_BACKOFF_MS or MAX_TRIES and the
    // durations will update.
    function generateBackoffDurations () {
      let backoffDurations = [INITIAL_BACKOFF_MS, INITIAL_BACKOFF_MS];
      for (let i = 1; i < MAX_TRIES; i++) {
        backoffDurations.push(backoffDurations[i] + backoffDurations[i - 1]);
        if (i + 1 === MAX_TRIES) {
          backoffDurations.shift();
        }
      }
      return backoffDurations;
    }

    // Returns truthy if the route is `api/` prefixed and the method is `GET`
    function isApiGet (config) {
      let apiRegex = /^\/?api\//g;
      let regexRes = apiRegex.exec(config.url);
      return !!regexRes && config.method === 'GET';
    }

    // Returns truthy if the url requested should be allowed the full 2 minute
    // timeout.
    function isExceptionUrl (url) {
      var isException = false;
      _.each(EXCEPTION_MATCHERS, function (matcher) {
        if (
          matcher instanceof RegExp && matcher.test(url)
          || typeof matcher === 'string' && matcher === url
        ) {
          isException = true;
          return false; // end the loop early.
        }
        return true;
      });
      return isException;
    }

    // Function called from `the final toast`, cancels existing timeouts for
    // failed resources and retries.
    function retryAll () {
      const $http = $injector.get('$http');
      Object.keys(openRequests).forEach(url => {
        const requestDetails = openRequests[url];
        // only retry items that don't currently have a pending request should
        // have a new request sent.
        if (!requestDetails.isInFlight) {
          $timeout.cancel(requestDetails.timeoutPromise);
          // Reset tries as we are about to start over.
          // Resolve existing promise with new promise chain.
          $http.get(url, requestDetails.config);
        }

        // Note that _all_ requests (even if they are in flight) should have
        // their tries reset.
        requestDetails.config.tries = 0;
      });
    }

    // NOTE: ALL requests come through this interceptor not only API requests.
    return {
      request (config) {
        if (isApiGet(config)) {
          const openRequestDetails = openRequests[config.url];
          // Check if a request is already pending for this resource and return
          // the associated promise (will resolve with the resource) if so.
          if (!openRequestDetails) {
            // This is the first time we've made this request
            config.tries = 0;
            config.backoffDurations = generateBackoffDurations();
            // NOTE: Timeout isn't 'used' anywhere but is used as the timeout..
            if (!isExceptionUrl(config.url)) {
              config.timeout = REQUEST_TIMEOUT_MS;
            }
            // Create a deferred to represent this request. It will be
            // resolved after all tries have finished.
            openRequests[config.url] = {
              deferred: $q.defer(),
              config: config,
              isInFlight: true,
              timeoutPromise: null
            };
          } else if (openRequestDetails && !openRequestDetails.isInFlight) {
            // We've seen this before, but we must have just retried.
            openRequestDetails.isInFlight = true;
          } else {
            // We've seen this before, but a response is pending.
            // Cause the request to abort, passing a custom status code that
            // we can check for later.:
            config.status = DUPLICATE_REQUEST_STATUS_CODE;
            return $q.reject({ status: DUPLICATE_REQUEST_STATUS_CODE, config });
          }
        }
        return config;
      },
      response (res) {
        if (isApiGet(res.config)) {
          const requestDetails = openRequests[res.config.url];
          requestDetails.isInFlight = false;
          if (res.status === 200) {
            // We managed to get our resource.
            // First, resolve our promise, we want to tell everybody we won!
            requestDetails.deferred.resolve(res);
            // Remove our promise reference from the request manager.
            delete openRequests[res.config.url];
          }
        }
        // ALWAYS return the response
        // NOTE: You can return a promise if desired.
        return res;
      },
      responseError (res) {
        const { config, status } = res;

        if (isApiGet(config)) {
          const requestDetails = openRequests[config.url];
          if (requestDetails) requestDetails.isInFlight = false;
          if (
            status === DUPLICATE_REQUEST_STATUS_CODE
          ) {
            return requestDetails.deferred.promise;
          } else if (
            status === -1 // NOTE: -1 means timeout ta Ed
          ) {
            if (config.tries === 0) {
              // This is the first rejection we have seen.
              Toast.makeError(HelpText.get('errors.resource.rejected1'));
            } else if (
              config.tries > 0 &&
              config.tries < MAX_TRIES
            ) {
              // This is all other rejections EXCLUDING the last
              Toast.makeError(HelpText.get('errors.resource.rejected2'));
            } else {
              // Show a clickable error allowing the user to manual re-request.
              Toast.makeError({}, {
                safe: true,
                duration: 240000,
                toastTplPath: 'utility/resourceRetryToast.jade',
                retry: retryAll.bind(null, config)
              });
              return requestDetails.deferred.promise;
            }
            const $http = $injector.get('$http');
            // Wait the backoff duration before firing a new request.
            let retryTimeoutPromise = $timeout(function () {
              // This is a loop and will be caught by this interceptor.
              requestDetails.timeoutPromise = null;
              $http.get(config.url, config);
            }, config.backoffDurations[
              config.tries++ || 0
            ]);
            requestDetails.timeoutPromise = retryTimeoutPromise;
            return requestDetails.deferred.promise;
          }
        }
        // Wasn't an API route so reject as normal.
        return $q.reject(res);
      }
    };
  })

  .config($httpProvider =>
    $httpProvider.interceptors.push('resourceRequestInterceptor')
  );
