angular.module('pxn-sharing', [])

.directive('sharingManager', function (
  $templateCache,
  ConfigURLs,
  GenericResource
) {
  return {
    restrict: 'E',
    scope: {
      resource: '=',
    },
    bindToController: true,
    controller: function (
      Modal,
      Groups,
      Toast,
      $scope,
      $attrs,
      $element,
      $timeout,
      Session,
      $q
    ) {
      var vm = this;

      if (Session.hasPermission('groups.read')) {
        vm.groups = Groups.all();
      }

      /**
       * Sets vm.filterdGroups to an array containing only groups we are
       * allowed to share to.
       * @return {undefined}
       */
      vm.filterGroups = function () {
        vm.filteredGroups = _.filter(vm.groups, function (group) {
          var contributorIds = [];
          if (angular.isObject(group.contributors[0])) {
            contributorIds =
              contributorIds.concat(_.map(group.contributors, '_id'));
          } else {
            contributorIds = contributorIds.concat(group.contributors);
          }
          return _.contains(contributorIds, Session.current.account._id);
        });
      };

      vm.castGroups = function () {
        _.each(vm.resource.groups, function (group, index) {
          var realGroup = _.findWhere(vm.groups, group, '_id');
          if (realGroup) {
            vm.resource.groups.splice(index, 1, realGroup);
          }
        });
      };

      function shareModal () {
        var dependencyIds = [];
        vm.castGroups();
        vm.filterGroups();

        if (!vm.filteredGroups.length) {
          Toast.makeError(
            'Your account does not belong to any groups that are eligible ' +
            'for sharing. Please create a new group or contact support for ' +
            'help with this feature.'
          );
          return;
        }

        // Upload item is irregular, so we need to temporarily replace this with
        // something friendlier.
        if (vm.resource.resourceType === 'uploaditem') {
          vm.resource.resourceType = 'media item';
        }
        var modalOpts = {
          templateUrl: 'utility/modal-templates/modal-share-resource.jade',
          scopeData: {
            resource: vm.resource,
            groups: vm.filteredGroups,
            dismissable: {
              backButton: false,
              escape: false,
              backgroundClick: false
            },
          },
        };
        vm.resource
          .getDependencies()
          .then(function (response) {
            vm.resource.dependencies = response.data;
            _.each(vm.resource.dependencies, function (dependency) {
              if (dependency.foreign) {
                modalOpts.scopeData.unshareable = true;
              }

              // Get the dependencies ID and add it to our array of IDs
              dependencyIds.push(dependency._id);
            });
            new Modal(modalOpts)
              .show()
              .then(function (resource) {
                if (vm.resource.resourceType === 'media item') {
                  vm.resource.resourceType = 'upload';
                }
                resource
                  .share()
                  .then(function () {
                    var message = resource.groups.length
                      ? 'Your content has been shared'
                      : 'Your content is no longer shared';
                    Toast.makeSuccess(message);
                    // __v of dependencies will have been updated on the server so
                    // we need to get the latest versions.
                    if (dependencyIds.length) {
                      _.each(dependencyIds, function (id) {
                        var cached = GenericResource.findInCache(id);
                        if (cached !== -1) {
                          cached.reset();
                        }
                      });
                    }
                  })
                  .catch(function (e) {
                    // Share errors
                    Toast.makeError(e);
                  });
              })
              .catch(function (e) {
                Toast.makeError(e);
              });
          })
          .catch(function (err) {
            if (err !== 'cancel') {
              $log.error(err);
            }
          });
      }

      vm.shareResource = shareModal;

      $attrs.$observe('disabled', (n)=> {
        vm.disabled = n;
      })
    },
    controllerAs: 'vm',
    template: function (element, attributes) {
      if (attributes.managerType === 'dropdown') {
        return $templateCache.get(
          'ownership/pxn-sharing-manager__dropdown.jade'
        );
      } else if (attributes.managerType === 'modalButton') {
        return $templateCache.get(
          'ownership/pxn-sharing-manager__modal-button.jade'
        );
      } else {
        return $templateCache.get(
          'ownership/pxn-sharing-manager__button.jade'
        );
      }
    },
  };
})

.directive('shareIndicator', function () {
  return {
    restrict: 'E',
    scope: {
      resource: '=',
    },
    bindToController: true,
    controllerAs: 'vm',
    controller: function ($scope, $element, $q, Groups, Session) {
      var vm = this;
      if (Session.hasPermission('groups.read')) {
        vm.groups = Groups.all();
      }

      function createTooltipText () {
        // Check the resource has groups.
        if (!vm.resource.groups) {
          vm.resource.groups = [];
        }
        // If the item isn't shared, remove it.
        if (vm.resource.groups.length) {
          $element.css('display', '');
        } else {
          $element.css('display', 'none');
          return;
        }

        // Create a deferred to resovle once we are able to generate the tooltip
        var deferred = $q.defer();

        var sharedWith = ['You shouldn\'t be seeing this!'];

        // Check if I own the resource (I have shared it with someone else)
        if (vm.resource.account._id === Session.current.account._id) {
          if (Session.hasPermission('groups.read')) {
            sharedWith = ['**Shared with:**'];
            vm.groups.promise.then(function () {
              if (vm.resource.groups.length) {
                _.each(vm.resource.groups, function (group) {
                  var index = _.findIndex(vm.groups, { _id: group._id });
                  if (index === -1) {
                    return;
                  }
                  var groupName = '\n- ' + vm.groups[index].name;
                  sharedWith.push(groupName);
                });
                deferred.resolve();
              }
            });
          } else {
            sharedWith = ['Shared with other accounts'];
            deferred.resolve();
          }
        } else {
          sharedWith = ['Shared with you by {{vm.resource.account.name}}'];
          deferred.resolve();
        }

        deferred.promise.then(function () {
          vm.resource.sharedWithString = sharedWith.join('');
        });
      }

      $scope.$watch('vm.resource', function (newVal) {
        if (!newVal) return;
        createTooltipText();
      });

      $scope.$watch('vm.resource.groups', function (newVal) {
        if (!newVal) return;
        createTooltipText();
      });
    },
    template: '<div \
        class="entypo-users share-indicator" \
        markdown="true" \
        pxn-tooltip="{{vm.resource.sharedWithString}}"></div>',
  };
})

.component('shareDependencyTree', {
  bindings: {
    items: '=',
    depth: '@'
  },
  templateUrl: 'ownership/shareDependencyTree.jade',
  controller: function ($scope, $attrs) {
    var $ctrl = this;

    $ctrl.$onInit = function () {
      $ctrl.newDepth = (parseInt($ctrl.depth) || 0) + 1;
    };
  }
});
