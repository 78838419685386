angular.module('pxn-recaptcha', [])
.provider('reCAPTCHA', function () {

  var publicKey = '';
  var loaded = false;
  var recaptchaDeferred;

  // Callback to to be run after the reCaptcha script has been loaded.
  window.recaptchaCallback = function () {
    loaded = true;
    // Resolve promise after reCaptcha script has loaded.
    if (recaptchaDeferred){
      recaptchaDeferred.resolve();
    }
  }

  return {

    setPublicKey: function (key) {
      publicKey = key;
    },

    $get: ['$q', function ($q) {

      recaptchaDeferred = $q.defer();
      var recaptchaPromise = recaptchaDeferred.promise;

      // If reCaptcha script has loaded resolve promise.
      if (loaded) {
        recaptchaDeferred.resolve();
      };

      return {
        render: function (element, params) {
          return recaptchaPromise.then( function() {
            return grecaptcha.render(element[0], {
              "sitekey": publicKey,
              "theme": params.theme,
              "type": params.type,
              "size": params.size,
              "tabindex": params.tabindex,
              "callback": params.callback,
              "expired-callback": params.expiredCallback
            })
          });
        }
      }
    }]
  }
})
.directive('recaptcha', function (reCAPTCHA, $interval) {
  return {

    restrict: 'E',
    require: 'ngModel',
    scope: {},

    link: function (scope, element, attributes, ngModel) {
      var id;

      // Sets the ngModal viewValue to the resonse from the reCaptcha.
      var success = function(response){
        ngModel.$setViewValue(response);
      }

      // Set the ngModal viewValue to null.
      var expired = function(){
        ngModel.$setViewValue(null);
      }

      // Call reCaptcha render function with params (defaults if param not set).
      reCAPTCHA.render(element, {
        "theme": attributes.theme,
        "type": attributes.type,
        "size": attributes.size,
        "tabindex": attributes.tabindex,
        "callback": success,
        "expired-callback": expired
      }).then(function (renderedId) {
        // Set id to the id of the reCaptcha.
        id = renderedId;
      })

      // Set default event name for reset if alternative hasn't been specified
      // in element attributes.
      var resetEvent = "resetRecaptcha";
      if (attributes.resetEvent) {
        resetEvent = attributes.resetEvent;
      }

      // Resets reCaptcha box and the ngModal viewValue on reset event.
      scope.$on(resetEvent, function(){
        grecaptcha.reset(id);
        ngModel.$setViewValue(null);
      })
    }
  }
});
