angular.module('sender').directive('enterDirective', function () {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      element.bind('keydown', function (event) {
        if (event.which === 13) {
          scope.$apply(function () {
            scope.$eval(attrs.enterFunc);
          });

          event.preventDefault();
        }
      });
    }
  };
});