angular.module('flare.receiver.time', [])

.factory('Time', function TimeFactory () {

  var utcOffset = 0;

  function updateOffset (o) {
    if (o != null) utcOffset = o;
  }

  function getLocalTime () {
    return Date.now() + utcOffset * 60 * 1000;
  }

  function getUTCOffset () {
    return utcOffset;
  }

  function getUTCTime () {
    return Date.now();
  }

  return {
    updateOffset: updateOffset,
    getLocal: getLocalTime,
    getUTC: getUTCTime,
    getUTCOffset: getUTCOffset
  };
});
