angular.module('flare.devices.js', [])

.factory('DeviceLogs', function (
  $http, ConfigURLs, Toast
) {
  return {
    getAll: function () {
      return $http
        .get(ConfigURLs.deviceLogs)
        .then(function (response) {
          return response.data;
        })
        .catch(function (err) {
          Toast.makeError(err);
        });
    },
    deleteLog: function (file) {
      return $http.delete(ConfigURLs.deviceLogs + '/' + file);
    }
  };
})

.controller('DeviceController', function (
  $log, $q, $timeout, $scope, $rootScope, Devices, Session, Channels, Playlists,
  Modal, Toast, SaveCheck, Searches, Accounts, $filter, $intercom, LocaleTree,
  Breakpoints, $http, ConfigURLs, TTY, Onboarding, $window, Takeovers,
  SortFunctions, Tickers, Licences
) {

  var ctrl = this;
  if (Session.hasPermission('content.tickers.read')) {
    ctrl.tickers = Tickers.all();
  } else {
    ctrl.tickers = [];
    ctrl.tickers.promise = $q.resolvedPromise();
  }
  ctrl.tickers.promise
    .then(() => {
      ctrl.filteredTickers = angular.copy(ctrl.tickers).sort((a, b) => {
        let nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        return nameA.localeCompare(nameB);
      });
      // Create a clone with a no-ticker option for multi select dropdown.
      ctrl.multiFilteredTickers = angular.copy(ctrl.filteredTickers);
      ctrl.multiFilteredTickers.unshift({
        _id: 'no-ticker',
        name: '-- No Ticker --',
      });
    })
    .catch(function (err) {
      Toast.makeError(err);
    });


  const UNKNOWN_PLAYLIST = 'Unknown playlist';

  $rootScope.$emit('getCtrlOf:device-controller', ctrl);

  ctrl.accountUsesLicences = Session.current.account.licensing.licences !== -1;

  ctrl.hasRequiredLicencesAvailable = (numRequired = 1)=> {
    return Licences.getCounts().available >= numRequired;
  }

  ctrl.numberSelectedWithoutLicences = () => {
    return ctrl.devices.all.filter(
      (device) => device._selected && !device.status.licensed
    ).length;
  };

  // Init code
  ctrl.devices = {
    filtered: [],
    online: []
  };
  if (Session.hasPermission('devices.read')) {
    ctrl.devices.all = Devices.all();
  } else {
    ctrl.devices.all = [];
    ctrl.devices.all.promise = $q.resolvedPromise();
  }

  let currentBreakpoint = Breakpoints.current;
  ctrl.breakpoint = Breakpoints.smallerThan('lg') ? 'small' : 'large';
  ctrl.ownAccount =
    Session.current.account._id === Session.current.user.account._id;

  $window.addEventListener('resize', function () {
    if (currentBreakpoint !== Breakpoints.current) {
      currentBreakpoint = Breakpoints.current;
      ctrl.breakpoint = Breakpoints.smallerThan('lg') ? 'small' : 'large';
    }
  });

  if (Session.hasPermission('system')) {
    ctrl.versionRegex = /(?:\d+\.){2}\d+/;
  }

  ctrl.sortObject = {};

  ctrl.activeTakeovers = [];

  let isSystem = Session.hasPermission('system');
  ctrl.accounts = isSystem ? Accounts.all() : [];

  if (isSystem) {
    ctrl.accounts.promise.then(function () {
      ctrl.accounts.sort((a, b) => SortFunctions.stringAsc(a.name, b.name));
    });
  }

  ctrl.selectDeviceLocale = function () {
    let selectedLocale = null;
    new Modal({
      templateUrl: 'utility/modal-templates/locale-manager.jade',
      scopeData: {
        localeSelector: true,
        selected: null,
        device: ctrl.device,
        tree: LocaleTree.getUnflattenedTree(),
        runFilter: function () {
          this.$parent.filterVal = this.filterVal;
        },
        selectLocale: function (locale) {
          selectedLocale = locale.selected ? locale : null;
          this.selected = selectedLocale;
        },
        toggleAll: function (val) {
          this.$broadcast(`angular-ui-tree:${val ? 'collapse' : 'expand'}-all`);
        },
        initFunction: function () {
          // Don't select locale when not all selected devices have the same
          // locale
          if (ctrl.numSelected > 1 && !ctrl.multiLocale) return;
          let id =
            ctrl.numSelected > 1 ? ctrl.multiLocale.id : this.device.locale;
          this.$on('locale-tree-linked', () => {
            this.$broadcast('angular-ui-tree:select-with-id', id);
          });
        }
      },
      dismissable: { backButton: false, escape: true, backgroundClick: true }
    })
    .show()
    .then(function (locale) {
      if (ctrl.numSelected > 1) {
        ctrl.multiLocale = locale;
      } else {
        ctrl.device.locale = locale.id;
        ctrl.device._localePath = LocaleTree.getLocalePath(locale);
        ctrl.device.setModified();
      }
      if (ctrl.contentForm) ctrl.contentForm.$setDirty(true);
    })
    .catch(function (err) {
      if (err !== 'cancel') {
        $log.error(err);
      }
    });
  };

  ctrl.changePin = function (device) {
    new Modal({
      templateUrl: 'utility/modal-templates/modal-generic-message.jade',
      scopeData: {
        title: `Confirm PIN change for ${device.name}`,
        message1: 'Are you sure you want to generate a new PIN for the screen' +
          ` '${device.name}'?`,
        message2: 'Once the PIN has been updated, the old PIN will no longer ' +
          'work, and it will not be possible to revert this change.',
        confirm: 'Continue'
      },
      dismissable: { backButton: false, escape: true, backgroundClick: true }
    })
    .show()
    .then(function () {
      return device.requestPin(true);
    })
    .then(function () {
      Toast.makeSuccess(
        `A new PIN has been successfully generated for ${device.name}.`
      );
    })
    .catch(function (err) {
      if (err !== 'cancel') {
        $log.error(err);
      }
    });
  };

  if (Session.hasPermission('searches.read')) {
    ctrl.savedSearches = Searches.all();
  } else {
    ctrl.savedSearches = [];
  }

  ctrl.devices.all.promise.then(function () {
    ctrl.runFilters();
    getActiveTakeovers()
    .then(setActiveTakeoversOnDevices);
  });

  ctrl.tagSuggestions = Devices.getTags();
  ctrl.registerScreen = function () {
    Devices.register(ctrl.playlistsAndChannels, ctrl.tickers).then((device) => {
      var portraitScreens = _.filter(ctrl.devices.all, function (dev) {
        return dev.status.portrait;
      });
      // Check there is only one and that it's the one you just registered.
      if (portraitScreens.length === 1 && device.status.portrait) {
        $intercom.trackEvent('registered-first-portrait-screen', device);
      }
      if (!Breakpoints.smallerThan('lg')) {
        ctrl.selectDevice(device, 0);
      }
      ctrl.runFilters();
      // If we selected a ticker for our device we need to update it's count
      if (device.ticker) {
        _.each(ctrl.tickers, (ticker, i) => {
          // Only update the ticker we've selected.
          if (ticker._id === device.ticker) {
            ticker.updateDeviceCount();
            // Ticker updated, exit the loop.
            return false;
          }
        });
      }
    }).catch(function (err) {
      Toast.makeError(err);
    });
  };

  ctrl.getVersionString = function (device) {
    if (!device.status.appVersion) return 'Unknown version';
    if (device.platform.name !== 'electron') {
      return `Version ${device.status.appVersion}`;
    } else if (device.platform.build) {
      var prefix = device.platform.build.substr(0, 1).toUpperCase() + 'F/';
      return `Version ${device.status.appVersion} [${prefix}${device.platform.version}]`;
    } else {
      return `Version ${device.status.appVersion} [${device.platform.version}]`;
    }
  };

  ctrl.replaceScreen = function (screenToReplace) {
    var modalOpts = {
      templateUrl: 'utility/modal-templates/replaceScreen.jade',
      scopeData:   {
        name: screenToReplace.name,
        validation: { captcha: false },
        id: screenToReplace._id
      },
    };

    var replaceModal = new Modal(modalOpts);
    var replaceModalScope = replaceModal.getScope();

    replaceModal.setScopeData({
      replace: function (regCode, recaptcha) {
        screenToReplace.replace(screenToReplace._id, screenToReplace.ownedBy, regCode, recaptcha)
        .then(function (device) {
          replaceModal.resolve(device);
        })
        .catch(function (e) {
          replaceModalScope.$broadcast('resetRecaptcha');
          if (e.data.recaptchaRequired) {
            replaceModalScope.validation.captcha = true;
          }
          if (e.status === 400) { // eslint-disable-line no-magic-numbers
            replaceModalScope.device.code = null;
          }
          $log.error('Unable to replace device', e);
        });
      }
    });

    replaceModal
      .show()
      .then(function (device) {
        Toast.makeSuccess('Device successfully replaced.');
        _.assign(screenToReplace, device);
      })
      .catch(function (err) {
        if (err !== 'cancel') {
          $log.error(err);
        }
      });
  };

  ctrl.showCommentModal = function showCommentModal (device, event) {
    event.stopPropagation();

    var modalOpts = {
      templateUrl: 'utility/modal-templates/deviceComments.jade',
      scopeData:   {
        ctrl: ctrl,
        device: device
      },
    };

    var commentModal = new Modal(modalOpts);
    commentModal.show();
  };

  ctrl.showTtyModal = function showTtyModal (device) {
    var modalOpts = {
      templateUrl: 'utility/modal-templates/deviceTerminal.jade',
    };
    var oldTitle = document.title;
    var ttyModal = new Modal(modalOpts);
    var initCtrlPaste = true;
    try {
      initCtrlPaste = TTY.getTerm().prefs_.get('ctrl-c-copy');
    } catch (e) {
      // Probably no term instance, ignore.
    }
    ttyModal.setScopeData({
      ctrlPaste: initCtrlPaste,
      isConnected: TTY.isConnected,
      stop: function () {
        TTY.requestEndSession();
        setTimeout(function () {
          TTY.getTerm().focus();
        });
      },
      modal: {
        maximise: (function (max) {
          let elem = this.modal.$elem[0].querySelector('.modal--content');
          let method = max ? 'add' : 'remove';
          elem.classList[method]('terminalModal__fullscreen');
          this.modal.isMaximised = max;
          setTimeout(function () {
            TTY.getTerm().focus();
          });
        }.bind(ttyModal.getScope())),
      },
      setCtrlPaste: function (newVal) {
        TTY.setCtrlPaste(newVal);
        setTimeout(function () {
          TTY.getTerm().focus();
        });
        return newVal;
      }
    });
    ttyModal.show()
    .catch(function (err) {
      Toast.makeError(err)
    })
    .finally(function () {
      // TTY changes the title whilst a terminal is open, so change it back.
      document.title = oldTitle;
    });
    TTY.startTerminalSession(device);
  };

  // Configure list of playlist and channels
  var readChannel  = Session.hasPermission('content.channels.read');
  var readPlaylist = Session.hasPermission('content.playlists.read');

  if (readChannel) ctrl.channels = Channels.all();
  if (readPlaylist) ctrl.playlists = Playlists.all();
  if (readChannel && readPlaylist) {
    $q.all([
      ctrl.channels.promise,
      ctrl.playlists.promise,
      ctrl.devices.all.promise,
      LocaleTree.getLocalisationData()
    ])
      .then(function () {
        ctrl.localesEnabled = LocaleTree.showLocaleFields();
        _.each(ctrl.devices.all, function (device) {
          if (device.hasOwnProperty('locale')) {
            let locale = LocaleTree.getLocaleById(device.locale);
            device._localeName = locale.name;
            device._localePath = LocaleTree.getLocalePath(locale);
          }

          setPlaylistName(device);
        });
      })
      .catch(function (err) {
        Toast.makeError(err);
      });
  } else if (readChannel) {
    ctrl.playlistsAndChannels = ctrl.channels;
  } else if (readPlaylist) {
    ctrl.playlistsAndChannels = ctrl.playlists;
  }

  ctrl.fromOtherAccounts = [];

  // Clear flag when device content is selected from content dropdown.
  ctrl.clearSelectedFromOtherAccount = function () {
    // Return early if we don't have system permission
    if (!isSystem) return;
    if (ctrl.device._selectedContentFromAnotherAccount) {
      // clear selected from other account flag.
      ctrl.device._selectedContentFromAnotherAccount = null;
    }
  };

  function setPlaylistName (device) {
    // Make _playlist store the name of the playlist or channel that is
    // assigned to the device.
    device._playlist = ctrl.getChannelName(device.channel, device.playlist);

    if (!isSystem) return;

    if (device._playlist === UNKNOWN_PLAYLIST) {
      if (!device.channel && !device.playlist) return;
      // Request the channel or playlist assigned to the device
      let contentPromise = device.channel ?
      Channels.byId(device.channel, false, true, false, true) :
      Playlists.byId(device.playlist, false, true, false, true);

      contentPromise.then((content)=> {
        let isChannel = content.resourceType === 'channel';
        // Ensure channel or playlist isn't from our account
        if (content.account._id !== Session.current.account._id) {
          ctrl.fromOtherAccounts.push(content);
          // Set flag for styling purposes
          device._notOwnContent = true;
        }
        // Set playlist name
        device._playlist = ctrl.getChannelName(
          isChannel ? content._id : null,
          isChannel ? null : content._id
        );
        if (device._notOwnContent) {
          device._selectedContentFromAnotherAccount = device._playlist;
        }
      });
    } else if (
      _.find(ctrl.fromOtherAccounts, { _id: device.channel || device.playlist }
    )) {
      // Set flag when channel or playlist has been selected before
      // (Request has not been made for the resource)
      device._notOwnContent = true;
      device._selectedContentFromAnotherAccount = device._playlist;
    }
  }

  if (readChannel && readPlaylist) {
    $q.all([
      ctrl.channels.promise,
      ctrl.playlists.promise
    ]).then(() => {
      ctrl.playlistsAndChannels =
        ctrl.channels.concat(ctrl.playlists);
      ctrl.playlistsAndChannels.sort((a, b) => SortFunctions.stringAsc(a.name, b.name));
    });
  } else if (readChannel || readPlaylist) {
    ctrl.playlistsAndChannels.promise.then(() => {
      ctrl.playlistsAndChannels.sort((a, b) => SortFunctions.stringAsc(a.name, b.name));
    });
  }

  ctrl.newSearch = function (clearSaved) {
    // Clearsaved is only passed by clicking "all screens"
    if (clearSaved) ctrl.savedSearch = null;
    $rootScope.$emit('hide-sidebar');
    clearLocalSearches();
    if (ctrl.search && ctrl.search.isLocal()) ctrl.search.delete();
    ctrl.search = Searches.create({
      model: 'device',
      filters: {
        name: { entries: [], matchesPartial: true },
        location: { entries: [], matchesPartial: true },
        tags: { entries: [], matchesPartial: false },
        landscape: { entries: [true] },
        portrait: { entries: [true] },
        macAddressOrSerial: { entries: [] },
        isOnline: { entries: [true] },
        isOffline: { entries: [true] },
        isLocked: { entries: [true] },
      }
    });
  };

  // Deletes local searches after navigating away then back to Screens page
  function clearLocalSearches () {
    if (Session.hasPermission('searches.read')) {
      Searches.all().forEach((s)=> {
        if (s.isLocal()) {
          // No need to permission trim here, it's local so created by this user
          s.delete();
        }
      });
    }
  }

  ctrl.newSearch();

  ctrl.getChannelName = function getChannelName (channelId, playlistId) {
    if (!channelId && !playlistId) return 'No content selected';

    var result;
    if (channelId && ctrl.channels) {
      result = _.find(ctrl.channels, { _id: channelId });
    }
    if (playlistId && ctrl.playlists) {
      result = _.find(ctrl.playlists, { _id: playlistId });
    }
    if (!result) {
      // No result, try finding content in fromOtherAccounts array
      result = _.find(ctrl.fromOtherAccounts, { _id: playlistId || channelId });
      // If there is a result prefix the content name with the account name
      if (result) return `(${result.account.name}) ` + result.name;
    }

    if (result) return result.name;
    return UNKNOWN_PLAYLIST;
  };

  ctrl.getTickerName = (device) => {
    if (device.platform.name === 'android' && device.platform.guid !== '0000000000000000') {
      return 'Not supported';
    }
    let result;
    if (device.ticker) {
      result =
        _.find(ctrl.tickers, { _id: device.ticker }) || { name: 'Unknown Ticker' };
    }
    return result ? result.name : 'No ticker selected';
  };

  // Device Selection
  ctrl.deselectAll = function deselectAll (transitioning) {
    _.each(ctrl.devices.all, function (device) {
      device._selected = false;
      delete device._pinDetails;
    });
    ctrl.numSelected = 0;
    if (!transitioning) ctrl.device = null;
  };

  // (init)
  ctrl.deselectAll();

  var lastClicked  = null;

  // numSelected should get updated by any function that changes the
  // selection.
  ctrl.numSelected = 0;

  // This is the currently selected device.
  ctrl.device      = null;

  function countSelected () {

    var selected = _.filter(ctrl.devices.all, { _selected: true });

    ctrl.numSelected = selected.length || 0;
    return selected;

  }

  function getSortedDeviceList () {
    var ordered = $filter('orderBy')(
      ctrl.devices.filtered,
      ctrl.sortObject.property,
      !ctrl.sortObject.ascending
    );

    // Only group by licence status when appropriate
    if (!ctrl.accountUsesLicences) return ordered;

    ordered = ordered.sort((a, b)=> {
      if (a.status.licensed && !b.status.licensed) return -1;
      else if (b.status.licensed && !a.status.licensed) return 1;
      else return 0;
    });

    return ordered;
  }

  $scope.$watchGroup(
    [
      () => JSON.stringify(ctrl.sortObject),
      () =>
        JSON.stringify(
          ctrl.devices.filtered.map(
            (device) => device.name + device.location + device.status.licensed
          )
        ),
    ],
    (newVals) => {
      ctrl.sortedDeviceList = getSortedDeviceList();
    }
  );

  function checkSharedContent () {
    var selected = _.filter(ctrl.devices.all, { _selected: true });
    var one = selected.pop();

    ctrl.multiChannel =
      _.all(selected, { _channelOrPlaylist: one._channelOrPlaylist }) ?
        one._channelOrPlaylist :
        null;

    ctrl.multiTicker =
      _.all(selected, { ticker: one.ticker }) ? one.ticker : null;

    ctrl.multiLocale =
      ctrl.localesEnabled && _.all(selected, { locale: one.locale }) ?
      LocaleTree.getLocaleById(one.locale) : null;
  }

  ctrl.saveChanges = function saveChanges (device) {
    SaveCheck.deregister('device');
    Toast.saveAndNotify(device)
      .then(function () {
        device._playlist = ctrl.getChannelName(device.channel, device.playlist);
        // We may have updated the ticker for our device so we need to update the
        // assigned property of our tickers
        _.each(ctrl.tickers, (ticker, i) => {
          ticker.updateDeviceCount();
        });
        setPlaylistName(device);
      })
      .catch(function (err) {
        Toast.makeError(err);
      });
    // Running filters again after changes made on a device.
    ctrl.runFilters();
  };

  ctrl.revertChanges = function revertChanges (device) {
    SaveCheck.deregister('device');
    Toast.resetAndNotify(device)
      .then(function () {
        // Reassign device._channelOrPlaylist to have the playlist dropdown
        // updated after mutate it's value then revert changes without saving them
        if (device.channel) {
          // If it has a channel assigned to it:
          device._channelOrPlaylist = 'channel:' + device.channel;
        } else if (device.playlist) {
          // If it has a playlist assigned to it:
          device._channelOrPlaylist = 'playlist:' + device.playlist;
        } else {
          // Reset it to null if no channel or playlist were assigned to it
          device._channelOrPlaylist = null;
        }

        setPlaylistName(device);
        device.status.portrait = _.contains(['E', 'W'], device.orientation);
      })
      .catch(function (err) {
        Toast.makeError(err);
      });
    ctrl.deselectAll();
  };

  ctrl.selectSavedSearch = function selectSavedSearch (search) {
    // Add missing fields as empty arrays so our inputs work correctly.
    _.each(['name', 'location', 'macAddressOrSerial'], function (key) {
      if (!_.has(search.filters, key)) {
        search.filters[key] = { entries: [] };
      }
    });
    $rootScope.$emit('hide-sidebar');
    // Create a fresh current search and populate its values with the
    // saved search we're loading.
    ctrl.search = null;
    ctrl.savedSearch = search;
    ctrl.newSearch();
    _.each(search.filters, function (v, k) {
      ctrl.search.filters[k] = angular.copy(v);
    });
  };

  ctrl.selectDevice = function selectDevice (device, i, multi, shift) {

    if (ctrl.device === device) return;

    // Show device detailed view in modal if on a smaller screen.
    if (Breakpoints.smallerThan('lg')) {

      var modalOpts = {
        templateUrl: 'utility/modal-templates/deviceDetailed.jade',
        scopeData:   {
          device: device,
          ctrl: ctrl
        },
      };

      ctrl.deviceDetailedModal = new Modal(modalOpts);
      ctrl.deviceDetailedModal.show()
      .then(function () {
        ctrl.saveChanges(device);
      })
      .catch(function (e) {
        ctrl.revertChanges(device);
      });

    }

    if (ctrl.device) { // Already have a single device connected
      // SaveCheck returns a resolved promise if there are no changes, so avoid
      // an infinite loop caused by our recursive call in the then block below
      // by not doing the check if we know there are no changes.
      if (ctrl.device.isModified()) {
        var originalArgs = arguments;

        SaveCheck.checkNow(true, 'device')
          .then(function () {
            ctrl.device.reset();
            selectDevice.apply(null, originalArgs);
          })
          .catch(function (err) {
            if (err !== 'cancel') {
              $log.error(err);
            }
          });
        return;
      } else {
        SaveCheck.deregister('device');
      }
    }

    if (multi) {
      countSelected();
      ctrl.device = null;
    } else if (ctrl.numSelected > 0) { // Not multi but 1 or more devices.
      ctrl.deselectAll(true);
      var ANIMATION_DELAY = 400;

      // This is to visually indicate a different device has been selected.
      // It will trigger an animation, and also reset the edit device form.
      $timeout(ctrl.selectDevice.bind(null, device, i), ANIMATION_DELAY);
      return;

    }

    if (shift && lastClicked != null) {
      // First we need to get a list of the devices in the same order as they're
      // displayed on the interface
      var ordered = getSortedDeviceList();
      // Iterate from the device we clicked to the one we previously clicked
      // selecting each device as we go.
      for (var j = i; j !== lastClicked; (lastClicked < i ? j-- : j++)) {
        ordered[j]._selected = true;
      }
    } else { // ctrl + click or unmodified click on an unselected device.
      device._selected = !device._selected;
    }
    var selected = countSelected();

    if (ctrl.numSelected > 1) {
      // Check which channel/playlist to display for selected devices.
      checkSharedContent();
      $timeout(function () {
        Onboarding.triggerOverviewResourceAvailable();
      }, 400); // 400ms required due to animation.
    } else {
      ctrl.device = selected[0];

      $timeout(function () {
        Onboarding.triggerOverviewResourceAvailable();
      });
      ctrl.useDefaultsChanged(ctrl.device, 'updates', true);
      SaveCheck.register({
        hasChanges: function hasChanges () {
          if (!ctrl.device) return false;
          return ctrl.device.isModified();
        },
        discardChanges: function reset () {
          ctrl.device.reset().then(()=> {
            // Reset device._channelOrPlaylist
            if (device.channel) {
              device._channelOrPlaylist = 'channel:' + device.channel;
            } else if (device.playlist) {
              device._channelOrPlaylist = 'playlist:' + device.playlist;
            } else {
              device._channelOrPlaylist = null;
            }
            device._ticker = device.ticker
            setPlaylistName(device);
          });
        },
        id: 'device',
        itemType: 'Screen',
        itemName: selected[0].name
      });
    }
    $scope.$emit('remeasure-for-scrollbars');
    lastClicked = i;

  };

  // Device Operations
  ctrl.actionSelected = function doBulkAction (action, skipConf = false) {
    if (action === 'delete' && Breakpoints.smallerThan('lg')) {
      // On a small screen if we delete a device we close the modal, as we can't
      // mutate it after we deleted it. For other actions (refresh, identify...)
      // we keep open the device modal after confirming the action.
      ctrl.deviceDetailedModal.reject('cancel');
    }
    var selected = _.filter(ctrl.devices.all, { _selected: true });
    var un = null; // this is for determining the text (i.e. 'unmute')
    var affected, message;

    if (action === 'lock' || action === 'mute') {
      affected = action === 'lock' ?
                 _.filter(selected, { status: { enabled: true } }) :
                 _.filter(selected, { audio: { enabled: true } });
      if (affected.length === 0) {
        affected = selected;
        un = true;
      } else {
        un = false;
      }
    } else if (action === 'reboot' || action === 'refresh'
      || action === 'turnScreenOn' || action === 'turnScreenOff') {
      affected = _.filter(selected, { status: { online: true } });
    } else {
      affected = selected;
    }

    message = 'This will immediately ' + (un ? 'un' : '') + action + ' ' +
              (affected.length > 1 ? affected.length : 'the') + ' screen' +
              (affected.length > 1 ? 's. ' : '. ') + ' ' +
              'Are you sure you want to continue?';

    if (action === 'displayMessage') {
      message = `This will immediately display the screen name over all other
      content for ${affected.length}
      screen${(affected.length > 1 ? 's. ' : '. ')} Are you sure you want to
      continue?`;
    }

    let confProm;
    if (skipConf) {
      confProm = $q.resolve();
    } else {
      let m = new Modal({
        scopeData: { message: message },
      });
      confProm = m.show();
    }
    return confProm
    .then(function actionConfirmed () {
      var promises = [];

      _.each(affected, function actionItem (device) {

        var args = un == null ? [null] : [!un];

        if (action === 'displayMessage') {
          args = [device.name, 30];
        }

        promises.push(device[action].apply(device, args));

      });
      return $q.all(promises);

    })
    .then(function actionSuccess () {
      if (action === 'displayMessage') action = 'identifi';
      Toast.makeSuccess(
        affected.length +
          " screen" +
          (affected.length > 1 ? "s " : " ") +
          (un ? "un" : "") +
          action +
          (action !== "delete" &&
          action !== "mute" &&
          action !== "rotate" &&
          action !== "license" &&
          action !== "delicense"
            ? "e"
            : "") +
          "d"
      );

      if (action === 'delete') {
        ctrl.deselectAll();
        ctrl.runFilters();
        // We need to update the assisgned count for tickers if we're deleting.
        _.each(ctrl.tickers, (ticker, i) => {
          ticker.updateDeviceCount();
        });
      }
    })
    .catch(function (e) {
      Toast.makeError(e);
      _.each(affected, function (device, index) {
        device.reset();
      });
    });
  };

  ctrl.bulkContentChange = function bulkContentChange () {
    var selected = _.filter(ctrl.devices.all, { _selected: true });
    if (!selected.length) return;

    var devicesUpdating = {
      channelPlaylist: [],
      ticker: [],
      locale: []
    };
    _.each(selected, (device) => {
      if (device._channelOrPlaylist !== ctrl.multiChannel) {
        devicesUpdating.channelPlaylist.push(device);
      }
      if (device.ticker !== ctrl.multiTicker) {
        devicesUpdating.ticker.push(device);
      }
      if (ctrl.multiLocale && device.locale !== ctrl.multiLocale.id) {
        devicesUpdating.locale.push(device);
      }
    })

    function screenScreens (count) {
      if (count !== 1) return ' screens'
      else return ' screen'
    }
    var affectedScreens = _.uniq([
      ...devicesUpdating.channelPlaylist,
      ...devicesUpdating.ticker,
      ...devicesUpdating.locale
    ]);
    var message = "This will immediately change the content of " +
      affectedScreens.length + screenScreens(affectedScreens.length) +
      '. Are you sure you want to continue?';

    new Modal({
      scopeData: { message: message },
    })
      .show()
      .then(function () {
        _.each(selected, function (device) {
          var promises = [];

          if (ctrl.multiChannel) {
            device._channelOrPlaylist = ctrl.multiChannel;
          }
          if (ctrl.multiTicker) {
            if (ctrl.multiTicker === 'no-ticker') {
              device._ticker = null;
            } else {
              device._ticker = ctrl.multiTicker;
            }
          }

          if (ctrl.multiLocale) {
            device.locale = ctrl.multiLocale.id;
          }

          promises.push(device.save());

          return $q.all(promises);
        });
      })
      .then(function channelChangeSuccess() {
        if (ctrl.contentForm) ctrl.contentForm.$setPristine(true);
        Toast.makeSuccess(
          'Content successfully updated for ' +
            selected.length +
            ' screen' +
            (selected.length > 1 ? 's ' : ' ')
        );
      })
      .catch(function (err) {
        Toast.makeError(err);
      });
  };

  var defaults = {};
  if (Session.hasPermission('account.settings')) {
    Accounts.byId(Session.current.account._id, false, true)
    .then(function (account) {
      if (!account) return;
      defaults.reboots = account.deviceReboots;
      defaults.updates = _.omit(account.deviceUpdates,
        ['freezeApp', 'freezeSystem']
      );
      if (!account.deviceConfig) return;
      defaults.offlineIndicators = account.deviceConfig.offlineIndicators;
      defaults.offlineName = account.deviceConfig.offlineName;
    });
  }
  ctrl.useDefaultsChanged =
    function useDefaultsChanged (device, property, init) {
      if (!init) device.setModified(true);
      var oldVal;
      switch (property) {
        case 'reboots':
          if (device.reboots.useDefaults && _.has(defaults, 'reboots')) {
            oldVal = device.reboots.useDefaults;
            device.reboots = angular.copy(defaults.reboots);
            device.reboots.useDefaults = oldVal;
          }
          break;
        case 'updates':
          if (device.updates.useDefaults && _.has(defaults, 'updates')) {
            oldVal = device.updates.useDefaults;
            device.updates = angular.copy(defaults.updates);
            device.updates.useDefaults = oldVal;
          }
          break;

        case 'offlineIndicators':
          if (
            device.config.useDefaults &&
            _.has(defaults, 'offlineIndicators') &&
            _.has(defaults, 'offlineName')
          ) {
            device.config.offlineIndicators = defaults.offlineIndicators;
            device.config.offlineName = defaults.offlineName;
          }
          break;
        default:
          break;
      }
    };

  ctrl.selectedAll = function checkSelectedFor (status) {
    var criteria;

    switch (status) {
      case 'muted':
        criteria = { audio: { enabled: false } };
        break;
      case 'locked':
        criteria = { status: { enabled: false } };
        break;
      case 'offline':
        criteria = { status: { online: false } };
        break;
      case 'licensed':
        criteria = { status: { licensed: true }};
        break;
      case 'unlicensed':
        criteria = { status: { licensed: false }};
        break;
      default:
        return false;
    }

    return _.all(
      _.filter(ctrl.devices.all, { _selected: true })
      , criteria
    );
  };

  ctrl.filters = Searches.getFilters();
  ctrl.getNumFilters = function () { return _.keys(ctrl.filters).length; };

  // Searching and filtering
  ctrl.toggleTagFilter = function (tag, e) {
    // Don't do anything in manage licence mode
    var tags = ctrl.search.filters.tags.entries;
    // Have to manually do this because ngChange doesn't trigger on modelValue
    // change, only viewValue.
    ctrl.search.setModified();
    if (_.contains(tags, tag)) {
      ctrl.search.filters.tags.entries = _.without(tags, tag);
    } else {
      // Must destroy the reference, hence new array and not push.
      ctrl.search.filters.tags.entries = tags.concat([tag]);
      e.stopImmediatePropagation();
    }
  };

  ctrl.runFilters = function () {
    // Deselect all existing devices incase they're filtered out.
    ctrl.deselectAll();
    ctrl.devices.filtered = Searches.runFilters(ctrl.search, ctrl.devices.all);
    ctrl.devices.online = _.where(
      ctrl.devices.filtered,
      { status: { online: true } }
    );
    var filteredOut = _.difference(ctrl.devices.all, ctrl.devices.filtered);
    _.each(filteredOut, function deselect (device) {
      device._selected = false;
    });
    countSelected();
    $timeout(function () {
      Onboarding.triggerOverviewResourceAvailable();
    });
  };

  ctrl.connectTo = function () {
    var modalOpts = {
      templateUrl: 'utility/modal-templates/connect-to-any-channel.jade',
      scopeData:   {
        vm: {
          accounts: ctrl.accounts,
          ownAccountId: Session.current.account._id,
          accountSelected: function () {
            this.playlists = [];
            this.channels = [];
          },
          getContent: function (content) {
            let playlist = content === 'getAllPlaylists';
            $http.get(ConfigURLs[content](this.selectedAccount._id))
            .then((res)=> {
              this[playlist ? 'playlists' : 'channels'] =
              res.data.filter((c)=> c.account._id === this.selectedAccount._id)
              .sort((a, b) => SortFunctions.stringAsc(a.name, b.name));
            })
            .catch((e)=> {
              Toast.makeError(e);
            });
          }
        }
      },
    };

    new Modal(modalOpts).show()
    .then(function (content) {
      let contentType =
        content.hasOwnProperty('channels') ? 'playlist' : 'channel';

      ctrl.fromOtherAccounts.push(content);

      ctrl.device._channelOrPlaylist = `${contentType}:${content._id}`;
      ctrl.device._selectedContentFromAnotherAccount = ctrl.getChannelName(
        contentType === 'channel' ? content._id : null,
        contentType === 'playlist' ? null : content._id
      );
      ctrl.device.setModified();
    })
    .catch(function (e) {
      Toast.makeError(e);
    });
  };

  var stopSearchWatcher = $scope.$watch('ctrl.search', ctrl.runFilters, true);

  $scope.$on('$destroy', stopSearchWatcher);

  ctrl.saveSearch = function createNewSavedSearch () {
    new Modal({
      templateUrl: 'utility/modal-templates/savedSearch.jade',
      scopeData: {
        search: ctrl.search
      }
    })
      .show()
      .then(function (search) {
        search.save().then(function (savedSearch) {
          $timeout(function () {
            Onboarding.triggerOverviewResourceAvailable();
          });
          ctrl.selectSavedSearch(savedSearch);
        });
      })
      .catch(function (err) {
        Toast.makeError(err);
      });
  };

  ctrl.promptToSaveSearch = false;

  ctrl.dismissSaveSearchPrompt = function dismissSavedSearchPrompt () {
    ctrl.promptToSaveSearch = false;
  }

  ctrl.onFilterModified = function () {
    ctrl.search.setModified();
    ctrl.promptToSaveSearch = true;
  }

  ctrl.deleteSearch = function deleteSavedSearch (search) {
    new Modal({
      templateUrl: 'utility/modal-templates/modal-areyousure.jade',
      scopeData: {
        action: 'delete',
        itemType: 'Saved Search'
      },
      dismissable: { backButton: false, escape: true, backgroundClick: false }
    })
      .show()
      .then(function () {
        Toast.deleteAndNotify(search);
        ctrl.savedSearch = null;
        ctrl.newSearch();
      })
      .catch(function (err) {
        Toast.makeError(err);
      });
  };

  function checkForNewScreenshot (now, delay = 0) {
    let url = ConfigURLs.screenshot(ctrl.device);
    return $timeout(function () {
      return $http({
        method: 'HEAD',
        noErrorToast: true,
        url,
      })
        .then(function (response) {
          let lastModified = Date.parse(response.headers()['last-modified']);
          return {
            lastModified,
            fresh: now < lastModified,
          };
        })
        .catch(function (err) {
          if (err.status !== 404) Toast.makeError(err);
        });
    }, delay);
  }

  ctrl.lookForNewScreenshot = (now, attempts = 5) => {
    const SS_FETCH_DELAY_MS = 1000;
    return checkForNewScreenshot(now, SS_FETCH_DELAY_MS).catch(function (e) {
      return null;
    }).then((result) => {
      if ((!result || !result.fresh) && attempts) {
        return ctrl.lookForNewScreenshot(now, --attempts);
      } else if (result) {
        return result.lastModified;
      } else {
        return null;
      }
    });
  };

  // NOTE: This can only be called if the screenshot modal is open, this is
  // because the values returned are only assigned to the modals scope and would
  // otherwise be lost/nothing will happen.
  ctrl.refreshScreenshot = () => {
    if (!ctrl.screenshotModal) {
      // Not much we can do...
      return;
    }
    // DRY function to set the scope data for the screenshot modal
    function setScreenshotModalScopeData(modalScope, updatedAt) {
      if (updatedAt) {
        modalScope.screenshot.url =
          ConfigURLs.screenshot(ctrl.device) + '?d=' + updatedAt;
        modalScope.screenshot.updatedAt = updatedAt;
        modalScope.screenshot.hasLoaded = true;
      } else {
        modalScope = {
          error: 'Unable to get screenshot for device.',
        };
      }
      ctrl.screenshotModal.setScopeData(modalScope);
    }

    // Tell the device to update the screenshot
    // Note: Screenshot modified time is only accurate to the second, so we
    // look for anything newer than 1 second ago to avoid very fast
    // screenshots not being detected (mostly just an issue in local dev).
    const now = Date.now() - 1000;
    ctrl.device.screenshot();

    // Reset the loading indicator
    let modalScope = ctrl.screenshotModal.getScope();
    if (modalScope.error) {
      // We have seen an error, reset to the default scope
      modalScope = {
        screenshot: {
          url: '',
          updatedAt: null,
          hasLoaded: false,
          refresh: ctrl.refreshScreenshot
        },
        device: {
          online: ctrl.device.status.online,
          portrait: ctrl.device.status.portrait
        },
        error: null
      };
    } else {
      // Just reset the image ready for update.
      modalScope.screenshot.url = '';
      modalScope.screenshot.hasLoaded = false;
    }
    // Set the changes.
    ctrl.screenshotModal.setScopeData(modalScope);

    return ctrl
      .lookForNewScreenshot(now)
      .then((updatedAt) => {
        setScreenshotModalScopeData(modalScope, updatedAt);
      })
      .catch(function (e) {
        setScreenshotModalScopeData(modalScope);
      });
  };

  ctrl.viewScreenshot = () => {
    // Build up all the meta we need to display.
    const screenshotModalOpts = {
      templateUrl: 'utility/modal-templates/viewScreenshot.jade',
      scopeData: {
        screenshot: {
          url: '',
          updatedAt: null,
          hasLoaded: false,
          refresh: ctrl.refreshScreenshot
        },
        device: {
          online: ctrl.device.status.online,
          portrait: ctrl.device.status.portrait
        },
        error: null
      },
    };

    ctrl.screenshotModal = new Modal(screenshotModalOpts);
    ctrl.screenshotModal
      .show()
      .catch(function (err) {
        Toast.makeError(err)
      });

    // We always want to show an updated image when opening the preview.
    // This might not have happened though as we may have already had one, now
    // that we're displaying the modal, let's requerst and update.
    ctrl.refreshScreenshot();
  };

  function getActiveTakeovers () {
    if (!Session.hasPermission('takeovers.read')) {
      return $q.resolvedPromise([]);
    }
    return Takeovers.all().promise.then((takeovers) => {
      ctrl.activeTakeovers = takeovers.filter(takeover => {
        return new Date().getTime() < new Date(takeover.lifetime.end).getTime();
      });
    });
  }

  function setActiveTakeoversOnDevices () {
    if (!Session.hasPermission('takeovers.read')) return;
    ctrl.devices.filtered.forEach(device => {
      device.activeTakeovers = [];
      ctrl.activeTakeovers = Takeovers.all();
      ctrl.activeTakeovers.forEach((takeover) => {
        if (takeover.devices.includes(device._id)) {
          device.activeTakeovers.push(takeover._id);
        }
      });
    });
  }

  $rootScope.$on('takeover-expired', setActiveTakeoversOnDevices);
  $rootScope.$on('takeover-triggered', setActiveTakeoversOnDevices);

})


.controller('DeviceLogController', function (
  DeviceLogs, Devices, Toast, $q
) {
  var vm = this;

  vm.reloadLogs = function reloadLogs () {
    vm.logList = [];
    DeviceLogs.getAll().then(function addLogsToScope (logs) {
      vm.logList = logs;
    });
  };

  vm.getFriendlyName = function (log) {
    return moment(parseInt(log.date)).format(
      'YYYY - MMM-DD - HH:mm:ss  · '
    ) + log.device.name || log.device.account + ' | ' + log.device._id;
  };

  // Opens colorised log in new window.
  vm.prettyPrint = function (logName) {
    // We could send the color scheme in the url param...
    window.open('devices/log-viewer?id=' + logName + '&colorscheme=', '_blank');
  };

  vm.deleteLog = function deleteLog (log) {
    DeviceLogs.deleteLog(log.name)
      .then(function () {
        vm.logList = _.without(vm.logList, log);
        Toast.makeSuccess('Log file deleted');
      })
      .catch(function (err) {
        Toast.makeError(err);
      });
  };

  vm.reloadLogs();

});
