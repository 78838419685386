angular.module('pxn-mobile', [])

/* Vibration API */

.factory('Vibrator', function () {

  // Enable vibration
  navigator.vibrate = navigator.vibrate
  || navigator.webkitVibrate
  || navigator.mozVibrate
  || navigator.msVibrate;

  function vibrate (length) {
    if (navigator.vibrate) {
      navigator.vibrate(length);
    }
  }
  return {
    vibrate: vibrate,
  };
})

.factory('Android', function () {

  /**
   * Hides the keyboard by creating a field, focussing it, then hiding it
   * @return {null}
   */
  function hideKeyboard () {
    var field = document.createElement('input');
    field.setAttribute('type', 'text');
    document.body.appendChild(field);

    setTimeout(function () {
      field.focus();
      setTimeout(function () {
        field.setAttribute('style', 'display:none;');
      }, 0);
    }, 0);
  }

  return {
    hideKeyboard: hideKeyboard
  };
})

.constant('IsMobile',
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
  .test(navigator.userAgent)
);
