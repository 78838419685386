angular.module('flare.searches', [])

.factory('Searches', function SearchFactory (
  GenericResource, ResourceItem, ConfigURLs, Session, $http, $log,
  $rootScope
) {

  class Search extends ResourceItem {
    constructor (data) {
      super(data);
    }

    delete () {
      return super.delete().then((successful) => {
        if (successful) {
          // Clear the Takeovers cache to ensure we don't display deleted searches.
          $rootScope.$emit('TakeoverTemplates::CLEAR_CACHE', this);
        }
      });
    }
  }

  Search.prototype.resourceType = 'search';

  Search.prototype._defaults = {
    filters: {},
  };

  var Searches = new GenericResource(ConfigURLs.searches, Search);

  function getFilters () {
    // Create an empty object that we can return instantly and populate when
    // we have the data
    var filters = {};

    if (Session.hasPermission('searches.read')) {
      $http
        .get(ConfigURLs.filters)
        .then(function filterSuccess(response) {
          _.assign(filters, response.data);
        })
        .catch((err) => {
          $log.error(err);
        });
    }

    return filters;
  }

  /* This defines whether array properties require all items in the filter list
    (true) or only one item (false) */
  var ARRAYS_STRICT = false;
  /**
   * Checks if an item matches a search criteria
   * @param {Search object} search The search criteria object
   * @param {object} item   The item to check
   * @return {boolean} `true` if the item matches.
   */
  function itemMatches (search, item) {
    // Keep the item if any of its values appear in the search keys.
    return _.every(search.filters, function (filter, key) {
      var matchVal  = item[key];
      var partial   = filter.matchesPartial !== false;
      var filterVal = filter.entries;

      if (search.model === 'template') {
        if (key === 'friendlyName' && filterVal.length) {
          return fuzzy.match(filterVal[0], matchVal);
        }
        return _.intersection(matchVal, filterVal).length === filterVal.length;
      }

      // If we switch to an OR search in future, then this needs to return false
      if (filterVal.length === 0) return true;

      // We don't currently match against non-array objects.
      if (typeof matchVal == 'object') {
        if (matchVal instanceof Array) {
          matchVal = _.invoke(matchVal, 'toLowerCase');
          filterVal = _.invoke(filterVal, 'toLowerCase');
          if (ARRAYS_STRICT) {
            return _.difference(matchVal, filterVal).length === 0;
          }
          return !!_.intersection(matchVal, filterVal).length;
        } else {
          $log.warn(
            '[Searches] Filtering on object properties not implemented.'
          );
          return false;
        }
      }

      if (typeof matchVal == 'string') {
        matchVal = matchVal.toLowerCase().trim();
        return _.any(filterVal, function stringMatch (filterStr) {
          if (filterStr == null) return true;
          filterStr = filterStr.toLowerCase().trim();
          if (!filterStr.length) return true;
          if (partial) return _.contains(matchVal, filterStr);
          return matchVal === filterStr;
        });
      }

      if (search.model === 'device' && typeof filter.entries[0] == 'boolean') {
        var on = filter.entries[0];
        switch (key) {
          case 'isOnline':
            return (on ||
              (item.status.online === false || item.status.enabled === false));
          case 'isOffline':
            return (on ||
              (item.status.online === true || item.status.enabled === false));
          case 'isLocked':
            return (on || item.status.enabled === true);
          case 'landscape':
            return (on || item.status.portrait === true);
          case 'portrait':
            return (on || item.status.portrait === false);
          default:
            $log.warn('[Searches] Unknown filter key: ' + key);
            return true;
        }
      }

      // Filter devices by wifi/ethernet macAddress or device serial number.
      if (search.model === 'device' && key === 'macAddressOrSerial') {
        // If we have an entry but the device has no macAddress i.e. receiver
        var compare;
        if (typeof filter.entries[0] === 'string') {
          compare = filter.entries[0].toLowerCase();
        }
        if (
          filter.entries[0] &&
          !item.platform.ethernetMac &&
          !item.platform.wifiMac &&
          !item.platform.guid
        ) {
          return false;
        }
        if (
          filter.entries[0] === null ||
          filter.entries[0] === ''
        ) {
          return true;
        }

        if (item.platform.ethernetMac) {
          if (
            _.contains(item.platform.ethernetMac.toLowerCase(), compare) ||
            _.contains( // Match mac address without ':'
              _.without(item.platform.ethernetMac.toLowerCase(), ':').join(''),
                compare
              ) ||
            _.contains(item.platform.guid.toLowerCase(), compare)
          ) {
            return true;
          }
        }

        if (item.platform.wifiMac) {
          if (
            _.contains(item.platform.wifiMac.toLowerCase(), compare) ||
            _.contains( // Match mac address without ':'
              _.without(item.platform.wifiMac.toLowerCase(), ':').join(''),
              compare
            )
          ) {
            return true;
          }
        }

        if (item.platform.guid) {
          if (_.contains(item.platform.guid.toLowerCase(), compare)) {
            return true;
          }
        }
        return false;
      }

      // NOTE: This is a stupid log, if the thing you're filtering is null (i.e.
      // device.location) then this logs, it's not helpful and makes it look
      // like there is a problem.
      // TODO: Uncomment the below POS.
      // $log.warn('[Searches] Invalid filter value: ' +
      //   filter.entries + ' for ' + search.model);
      return false;
    });
  }

  /**
   * Filters items according to the `Search` object given. If provided with a
   * single item, will return true or false if that item matches (for angular
   * filter expressions), otherwise, if an array is provided, a filtered array
   * will be returned.
   *
   * @param {Search object} search   The `Search` object to be used.
   * @param {array | object} toSearch Either an individual object to check, or
   *                                  an array to filter.
   * @return {array | boolean} If an array is passed as `toSearch`, then a
   *                           filtered array is returned, otherwise a boolean
   *                           indicating whether the item matches the search
   *                           criteria is returned.
   */
  function searchFilterFunction (search, toSearch) {

    if (toSearch instanceof Array) {
      if (!_.any(search.filters, ('entries.length'))) return toSearch;
      return _.filter(toSearch, itemMatches.bind(null, search));
    } else {
      if (!_.any(search.filters, ('entries.length'))) return true;
      return itemMatches(search, toSearch);
    }

  }

  Searches.getFilters = getFilters;

  Searches.runFilters = searchFilterFunction;

  return Searches;

})

.filter('searchFilter', function () {
  return function searchFilterFormatter (input, field) {
    if (!angular.isArray(input)) return input;
    let fieldName = _.capitalize(_.startCase(field).toLowerCase());
    if (!input.length || input[0] == null) return 'Any ' + fieldName;
    if (input.length === 1) {
      return `${fieldName} contains '${input[0]}'`;
    }
  };
});
