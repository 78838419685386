angular.module('flare.settings', [])

.controller('SettingsController',
  function (
    $location, $timeout, Integrations, Providers, Accounts, Session, Roles,
    Toast, $q, $http, $log, HelpText, ConfigURLs, $scope, SaveCheck, Modal
  ) {
    var vm = this,
      accountPromise;

    const MIN_LENGTH    = 'minLength';
    const MAX_LENGTH    = 'maxLength';
    const MIN_LOWERCASE = 'minLowercase';
    const MIN_UPPERCASE = 'minUppercase';
    const MIN_NUMERIC   = 'minNumeric';
    const MIN_SPECIAL   = 'minSpecial';
    const MAX_AGE       = 'maxAge';

    // Minumum value for max password age, if set.
    const POLICY_MIN_MAX_AGE_DAYS = 30;
    const POLICY_MIN_LENGTH       = 10;
    const POLICY_MAX_LENGTH       = 40;
    const POLICY_MIN_LOWERCASE    = 1;
    const POLICY_MIN_UPPERCASE    = 1;
    const POLICY_MIN_NUMERIC      = 1;
    const POLICY_MIN_SPECIAL      = 0;

    /* eslint-disable no-multi-spaces */

    vm.sessionTimeoutOptions = [
      { l: '5 minutes',   v: 300000 },
      { l: '30 minutes',  v: 1800000 },
      { l: '1 hour',      v: 3600000 },
      { l: '4 hours',     v: 14400000 },
      { l: '1 day',       v: 86400000 },
      { l: '1 week',      v: 604800000 },
      { l: '1 month',     v: 2630000000 },
      { l: '6 months',    v: 15780000000 }
    ];
    vm.deviceTimeoutOptions = [
      { l: '1 hour',    v: 3600000 },
      { l: '3 hours',   v: 10800000 },
      { l: '6 hours',   v: 21600000 },
      { l: '12 hours',  v: 43200000 },
      { l: '1 day',     v: 86400000 },
      { l: '1 week',    v: 604800000 }
    ];

    // Used only after enabling max lifetime (actual default is null / none).
    const DEFAULT_MAX_LIFETIME_DAYS = 14;

    $scope.$on('$viewContentLoaded', function () {
      if ($location.search().error) {
        // $timeout to register toaster first.
        $timeout(() => Toast.makeError($location.search().error));
      }
    });

    /* eslint-enable no-multi-spaces */

    vm.validateFields = function () {
      $scope.$broadcast('pxn-invalidity-callout:validate-fields');
    }

    function validatePasswordPolicyRequiredTypes () {
      return (
        Math.abs(vm.passwordPolicy.minLowercase) +
        Math.abs(vm.passwordPolicy.minUppercase) +
        Math.abs(vm.passwordPolicy.minNumeric) +
        Math.abs(vm.passwordPolicy.minSpecial)
      ) <= vm.passwordPolicy.minLength;
    }

    vm.validatePasswordPolicyProps = function (prop) {
      if (!vm.passwordPolicy || vm.passwordPolicy.useDefaults) {
        // Account promise hasn't resolved yet or using default password policy
        return true;
      }

      let isValidProp;

      switch (prop) {
        case MIN_LENGTH:
          return vm.passwordPolicy.minLength >= POLICY_MIN_LENGTH &&
            vm.passwordPolicy.minLength <=
            vm.passwordPolicy.maxLength
        case MAX_LENGTH:
          return vm.passwordPolicy.maxLength <= POLICY_MAX_LENGTH &&
            vm.passwordPolicy.minLength <=
            vm.passwordPolicy.maxLength
        case MAX_AGE:
          return vm.passwordPolicy.maxAge >= POLICY_MIN_MAX_AGE_DAYS ||
          vm.passwordPolicy.maxAge === 0;
        case MIN_LOWERCASE:
          isValidProp =
            vm.passwordPolicy.minLowercase >= POLICY_MIN_LOWERCASE;
          break;
        case MIN_UPPERCASE:
          isValidProp =
            vm.passwordPolicy.minUppercase >= POLICY_MIN_UPPERCASE;
          break;
        case MIN_NUMERIC:
          isValidProp =
            vm.passwordPolicy.minNumeric >= POLICY_MIN_NUMERIC;
          break;
        case MIN_SPECIAL:
          isValidProp =
            vm.passwordPolicy.minSpecial >= POLICY_MIN_SPECIAL;
          break;
      }

      return isValidProp && validatePasswordPolicyRequiredTypes();
    }

    if (Session.hasPermission('integrations.read')) {
      vm.integrations = Integrations.all();
    } else {
      vm.integrations = [];
      vm.integrations.promise = $q.resolvedPromise();
    }

    vm.emptyFunction = function () { return; };
    vm.addDeviceUnlockKey = function () {
      $http
        .post(ConfigURLs.addDeviceUnlockKey(Session.current.account._id))
        .then(function (res) {
          Toast.makeSuccess(HelpText.get("settings.addUnlockKey"));
        })
        .catch((err) => {
          $log.error(err);
        });
    };
    vm.unlockKeys = [
      // {
      //   value: 'zGxnme2TF6VqqXK6BwwzySGdbMzX5Htbx6PzTX9HwNcxMpsc8u82B2AurfTH6FaS',
      //   generated: Date.now()
      // }
    ];

    vm.editAzureSettings = function () {
      let deferred = $q.defer();

      if (vm.accountForm.$dirty) {
        new Modal({
          templateUrl: 'utility/modal-templates/modal-pending-changes.jade',
          scopeData: {
            resource: 'account'
          },
          dismissable: {
            backButton: false,
            escape: true,
            backgroundClick: false
          }
        }).show().then(function () {
          Toast.saveAndNotify(vm.account)
          .then(function () {
            vm.accountForm.$setPristine();
            deferred.resolve();
          });
        });
      } else {
        deferred.resolve();
      }

      deferred.promise.then(function () {
        new Modal({
          templateUrl: 'utility/modal-templates/azure-settings.jade',
          scopeData: {
            vm: {
              tenantId: Session.current.account.azure.tenantId,
              groupId: Session.current.account.azure.groupId
            }
          },
          dismissable: {
            backButton: false, escape: true, backgroundClick: true
          }
        })
        .show()
        .then(function (azureIds) {
          Session.current.account.azure.tenantId = azureIds.newTenantId;
          Session.current.account.azure.groupId = azureIds.newGroupId;
          vm.saveAccount();
        });
      });
    };


    vm.roles = Session.hasPermission('account.auth') ? Roles.all() : [];

    let adminRoleId = '';

    $q.all([vm.roles.promise]).then(()=> {
      _.each(vm.roles, (role) => {
        // Hard coding role to account admin as it isn't clear which devices
        // covered by the selected role
        // Saved searches would be a good alternative
        if (role.name === 'Flare - Account Admin') adminRoleId = role._id;
      });
    });

    vm.manageEmailAlertList = function () {
      let q = $q.defer();

      if (vm.accountForm.$dirty) {
        let modalOpts = {
          templateUrl: 'utility/modal-templates/modal-pending-changes.jade',
          scopeData: {
            resource: 'account',
            isResourceInvalid: !vm.accountForm.$valid
          },
          dismissable: {
            backButton: false, escape: true, backgroundClick: false
          }
        };

        new Modal(modalOpts)
          .show()
          .then(function () {
            updatePasswordPolicy();
            Toast.saveAndNotify(vm.account).then(function () {
              vm.accountForm.$setPristine();
              q.resolve();
            });
          })
          .catch((err) => {
            Toast.makeError(err);
          });
      } else {
        q.resolve();
      }

      vm.sortEmailList = (list) =>
        list.sort((a, b) =>
          // eslint-disable-next-line no-nested-ternary
          (a.email > b.email) ? 1 : ((b.email > a.email) ? -1 : 0));

      q.promise.then(()=> {
        new Modal({
          templateUrl: 'utility/modal-templates/manage-email-alert-list.jade',
          scopeData: {
            vm: {
              listInitiated: false,
              initialRepeatDone: function () {
                let self = this;
                $timeout(function () {
                  // Set flag after ngRepeat finished rendering the email list,
                  // so newly added inputs can request focus.
                  self.listInitiated = true;
                });
              },
              emailList:
                vm.sortEmailList(angular.copy(vm.account.emailAlertSubs)),
              delete: function (entry) {
                let index = this.emailList.indexOf(entry);
                this.emailList.splice(index, 1);
                this.emailListForm.$setDirty();
              },
              addNewEntry: function () {
                this.emailList.push({
                  email: '',
                  alerts: { offlineDevice : true },
                  role: adminRoleId
                });
                this.emailListForm.$setDirty();
              }
            }
          },
          dismissable: {
            backButton: false, escape: true, backgroundClick: false
          }
        })
        .show()
        .then((updatedList) => {
          vm.account.emailAlertSubs = updatedList || [];
          Toast.saveAndNotify(vm.account);
        })
        .catch((err) => Toast.makeError(err));
      });
    };

    vm.invalidityMessages = {
      pattern: 'Invalid semver format, please use x.y.z',
      policyPropNumbers: 'Passwords must contain at least 1 lowercase, 1 \
        uppercase and 1 numeric character. Make sure the number of minimum \
        required characters from each type doesn\'t exceed the minimum \
        password length allowed.',
      passwordLength: `Password length must be between ${POLICY_MIN_LENGTH} \
        and ${POLICY_MAX_LENGTH} characters and its minimum length must be \
        less than or equal to its maximum length.`,
      minMaxAge: `Minimum allowed password age should be at least \
        ${POLICY_MIN_MAX_AGE_DAYS} days. \n To not enforce password age set \
        this value to 0.`
    };

    if (Session.hasPermission('system')) {
      vm.versionRegex = /(?:\d+\.){2}\d+/;
    }

    vm.providers = [];
    Providers.all().promise.then((providers) => {
      const provisionedProviders = Providers.getProvisionedProvidersForAccount(
        providers, Session.current.account
      );
      _.each(provisionedProviders, (provider) => {
        if (provider.authentication) vm.providers.push(provider);
      });
    });

    // Account specific settings
    vm.account = {};
    vm.passwordPolicy = {};

    // This function updates the account's password policy on save.
    function updatePasswordPolicy () {
      Object.assign(vm.account.passwordPolicy, vm.passwordPolicy);
    }

    accountPromise =
      Accounts.byId(Session.current.account._id, false, true, true);

    vm.saveAccount = function saveAccount () {
      updatePasswordPolicy();
      Toast.saveAndNotify(vm.account)
      .then(function pristineForm () {
        vm.accountForm.$setPristine();
      });
    };

    vm.resetAccount = function saveAccount () {
      Toast.resetAndNotify(vm.account)
      .then(function pristineForm () {
        vm.passwordPolicy = _.clone(vm.account.passwordPolicy);
        vm.accountForm.$setPristine();
      });
    };

    SaveCheck.register({
      hasChanges: () => {
        return vm.account.isModified() || vm.accountForm.$dirty;
      },
      discardChanges: () => { vm.account.reset(); }
    });
    $scope.$on('$destroy', SaveCheck.deregister);

    /**
     * This function is used to decide whether or not to show the safe hours
     * window. Because we currently only expose app updates and not system
     * updates, we should still hide the window if system updates is enabled
     * but nothing else is.
     * @param {Account} account The account to check.
     * @returns {boolean} true if restrict window should be shown
     */
    vm.hasAnyDownloadRestriction = function (account) {
      let toCheck = ['appUpdates', 'videoDownloads', 'systemUpdates'];
      return _.any(toCheck, (key)=> account.deviceUpdates.restrict[key]);
    };

    accountPromise.then(function (account) {
      vm.account = account;
      // Clone the password policy so editing it won't trigger the user
      // prefernces cog decorator and warning message only after the account has
      // been saved.
      vm.passwordPolicy = _.clone(vm.account.passwordPolicy)
    });

    var oldLifetimeVal;
    $scope.$watch('vm.account._useMaxLifetime', function (enable, prev) {
      // This will trigger on load with `prev` as undefined.
      if (enable === true && prev === false) {
        vm.account.slideCreation.maxLifetimeDays
          = oldLifetimeVal || DEFAULT_MAX_LIFETIME_DAYS;
      } else if (enable === false) {
        oldLifetimeVal = vm.account.slideCreation.maxLifetimeDays;
        vm.account.slideCreation.maxLifetimeDays = null;
      }
    });

    let coloursProgrammaticallyRestricted = false;
    let previousColourRestrictionValue;
    vm.deletePaletteColour = (index) => {
      // Remove the colour.
      vm.account.colours.swatches.splice(index, 1);
      // Dirty the form.
      vm.accountForm.$setDirty();
      // Uncheck `restrict to palette` option if the palette is empty.
      if (!vm.account.colours.swatches.length) {
        previousColourRestrictionValue = vm.account.colours.restrict;
        vm.account.colours.restrict = false;
        coloursProgrammaticallyRestricted = true;
      }
    };

    vm.addColorToPalette = () => {
      // Ensure we have a palette to put colours in.
      if (!vm.account.colours.swatches || !vm.account.colours.swatches.length) {
        vm.account.colours.swatches = [];
        // We didn't, restore the previous value for palette restriction.
        if (coloursProgrammaticallyRestricted) {
          vm.account.colours.restrict = previousColourRestrictionValue;
          coloursProgrammaticallyRestricted = false;
        }
      }
      vm.account.colours.swatches.push({ name: '', value: '#000000' });
      vm.accountForm.$setDirty();
    };

  }
);
