angular.module('onboarding.texts', [])

/* eslint-disable max-len */
/* eslint-disable quotes */

.factory('OnboardingTexts', function ($log) {

  // texts = {
  //   pageName: {
  //     overviewName: {
  //       stepKey: text,
  //       stepKey2: text,
  //       stepKey3: text,
  //     }
  //     overviewName2: {
  //       stepKey: text,
  //       stepKey2: text,
  //       stepKey3: text,
  //     }
  //   }
  // }

  let texts = { // Export date: Mon Feb 12 2024 16:34:01 GMT+0000 (Greenwich Mean Time)
    dashboard: {
      mainOverview: {
        intro: `<strong>Welcome to Flare!</strong>
          <br><br>To get you accustomed with all the features of Flare, we'll automatically show you these in-app guides as you navigate around the interface.
          <br><br>If you don't want these prompts to appear automatically, you can click on <strong>Disable in-app guide prompts</strong> at the top of this hint. You can re-enable them at any time from your user preferences.
          <br><br>You can also select <strong>Skip</strong> to ignore all remaining hints in the current guide, or <strong>Snooze</strong> to disable all guides for a while.
          <br><br>Selecting <strong>Next</strong> will show the next hint in the guide, whereas <strong>Previous</strong> will go back a step.
          <br><br>For convenience, you can also use the <strong>🡐</strong> and <strong>🡒</strong> arrow keys to move backwards and forwards through a guide.`,
        'overview-button': `We'll save your progress for each in-app guide, so you won't need to read the same hint twice. Once you've completed a page overview guide, it won't automatically show the next time you visit that page of the interface.
          <br><br>If you want to refresh your memory, you can manually display the page overview guide again from this button, as well as access any additional guides that are relevant to features of the page.`,
        'profile-image': `This is you!
          <br><br>If you want other users to see what you look like, you can manage your profile image over at <a href="https://gravatar.com" title="Go to Gravatar" target="_blank" style="text-decoration:underline;">Gravatar</a>.`,
        'account-name': `This is the account that you're currently logged in to. Each account has one or more users assigned to it, with all resources shared between them.
          <br><br>For example, if a user on your account uploads a new image, you'll be able to see and use it as well, assuming that you've been assigned the correct user permissions to do so.`,
        'logout-button': `Once you're finished with the interface, you can logout by clicking this button.`,
        'user-preferences': `Use this button to access your user preferences.
          <br><br>From here you can update your email notification settings, manage the status of these in-app guides, and more.`,
        'switch-account': `In addition to your main account, you've been assigned access to one or more sub-accounts.
          <br><br>You can use this button to switch to a sub-account and manage its content. Note that content from one account cannot be seen from another unless it's been shared - see the <strong>Groups</strong> page for more information on sharing.`,
        'switched-account': `After switching to a sub-account, the account name here will be displayed in orange.
          <br><br>You can switch to another sub-account or back to your primary account at any time using the <strong>Switch account</strong> button again.`,
        'account-activity': `This list shows the recent activities on your account. You can use this to review what other users have been doing since you last logged in.`,
        'change-log': `Each change log contains details of a recent update to Flare. You can read about new features and improvements here.`,
        'login-history': `Your recent login attempts (both successful and unsuccessful) will be displayed here.
          <br><br>If any of the entries here look suspicious, please reach out to the team using the <strong>In-app support</strong> button at the bottom of the left navigation menu. `,
        'account-health': `You'll see a summary of your account health here, as well as any relevant advice or actions you can take to keep things running smoothly.`,
        'approvals-center': `If slides have been created or updated by users who do not have permission to approve them, they'll be displayed here until approved by a privileged user on the account.
          <br><br>You can also sign up to receive email notifications when slides have been submitted for approval via your user preferences.`
      }
    },
    devices: {
      mainOverview: {
        intro: `<strong>Keeping track of your displays</strong>
          <br><br>This page lets you register new screens, and remotely manage existing ones.`,
        'register-button': `Click here to register a new screen to your account. You'll be prompted for the 4-digit code displayed on the screen, and asked to supply some additional information to help other users identify it.`,
        'screen-list': `Once you have one or more screens registered to your account, you'll see them listed here. You can click on column names to change the sort order.`,
        'screen-list-entry': `Each screen has a coloured status bar next to it to indicate whether it's currently <strong>online</strong> (green), <strong>offline</strong> (red) or <strong>locked</strong> (orange/green).
          <br><br>You can click on a single screen in the list to update its configuration or perform administrative actions (e.g. reboot). Clicking while holding the <strong>ctrl</strong> or <strong>shift</strong> modifier keys will allow you to highlight multiple screens in the list and perform administrative actions on them in bulk.`,
        'screen-details': `This view shows you details and available actions for a single screen.
          <br><br>From here you can change playlist and volume settings for the screen (this is separate to the display hardware volume), show a snapshot of what the screen is playing, lock, replace or delete the screen from your account. If the screen is currently online, you can also display its name as an overlay on the slideshow (identify) or refresh/reboot the screen, though you should rarely need to use these.
          <br><br>Screen properties can also be updated here, including the name, location and timezone, as well as any update restrictions and CEC adapter power settings.`,
        'screen-comments': `If you wish to keep a log of events for a particular screen (such as physical maintenance or relocation of the hardware), you can add comments here for other users to view.`,
        'screen-bulk-actions': `Certain actions can be performed in bulk when multiple screens have been selected.
          <br><br>Note that some bulk actions will be disabled if none of the selected screens are online. Actions that require a screen to be online will not be performed for any selected screens that are currently offline.`,
        'screen-filters': `Here you can filter your screens based on a range of options such as name, location, orientation, and status.`,
        'save-search': `If you expect to use a set of filter criteria frequently, you can store them against a name and description by clicking <strong>Save search</strong> here.`,
        'saved-searches': `This is the list of saved searches available for filtering your screens.
          <br><br>To reset any active filters and see the full list of screens again, simply select the <strong>All screens</strong> option at the top.`,
        'online-guage': `This gauge shows how many of your screens are currently online.
          <br><br>Note that the values displayed here may not reflect all screens registered to your account, e.g. if you've selected a saved search, the gauge will reflect the total number of screens within the search.`
      }
    },
    'template-browser': {
      mainOverview: {
        intro: `<strong>Browse and manage slide templates</strong>
          <br><br>This page lets you browse and manage the templates that are available to use when creating slides and takeovers.
          <br><br>You'll be notified in the navigation bar when new and updated versions of templates become available.`,
        carousel: `You can use the carousel to browse through all available templates and click on individual ones to see additional information.
          <br><br>New and updated templates will display at the start of the list and will be marked with a banner that disappears after selecting and viewing them.`,
        details: `When a template is selected, this panel will describe its capabilities and provide snapshots of example slides. The name of the template and the supported orientations are displayed at the top.`,
        'details-info': `This area displays a description and icons to indicate the type, features and available feeds for the template.
          <br><br>A list of notable changes may also be displayed here when a new version of the template is available.`,
        'actions-button': `This button allows you to create a new slide or takeover (where available) using the selected template.
          <br><br>If your role allows you to manage templates, and a template isn't already assigned to your account, this button will allow you to add it before using it. Once added, all users on your account will be able to see and use the template.`,
        'remove-button': `If your role allows you to manage templates, you can use this button to remove a template from your account, hiding it from all users on your account that don't have template management permissions.
          <br><br>Note that you cannot disable custom templates, or templates that are currently in use by one or more slides or takeovers.`,
        'template-filters': `Here you can filter templates based on a range of options such as orientation and name.
          <br><br>Note that templates will only be displayed if they meet all of the filter criteria selected. You can reset the filters by clicking on the <strong>Reset</strong> button at the top.`,
        'set-all-seen': `You can click on this button to set all existing new and updated templates as seen so you won't be notified about them next time.
          <br><br>Note that you'll still be notified of any new or updated templates that become available in the future.`
      }
    },
    playlists: {
      mainOverview: {
        intro: `<strong>Combine channels using playlists</strong>
          <br><br>This page lets you create and manage playlists.
          <br><br>Playlists can be assigned to your screens to display content from multiple channels.`,
        'create-button': `Click here to create a new playlist. You'll be asked to give it a name, and may optionally supply additional properties such as a description and tags. `,
        interleave: `When <strong>Interleave</strong> is enabled, any screens that have this playlist assigned will splice each channel's slides together based on the number of slides in each channel. This ensures that an even mix of content is displayed in cases where each channel is dedicated to a specific category of content.
          <br><br>If you'd rather have all slides in each channel display before the next channel, leave this option disabled.`,
        'playlist-list': `Playlists that have been created under your account are listed here.
          <br><br>Each playlist displays its assigned channels in the order they'll be played, and has options for adding channels and updating the playlist properties.
          <br><br>The order of channels inside a playlist can be changed using drag and drop.
          <br><br>You can expand or collapse individual playlists using the chevron button in the top left (<strong>></strong>).`,
        'playlist-filters': `Here you can filter your playlists based on a range of options such as name, owner and tags.`,
        'bulk-options': `If you wish to delete playlists in bulk, you can find options for enabling/disabling multi-select and deleting the selected playlists from this drop-down button. `,
        'playlist-help': `More information on working with playlists and the interleave option can be found here.`
      }
    },
    channels: {
      mainOverview: {
        intro: `<strong>Categorise your content with channels</strong>
          <br><br>This page lets you create and manage channels.
          <br><br>Channels can be directly assigned to your screens to display a selection of content, or indirectly assigned as part of a playlist.`,
        'create-button': `Click here to create a new channel. You'll be asked to give it a name, and may optionally supply additional properties such as a description or tags. `,
        'channel-list': `Channels that have been created under your account are listed here.
          <br><br>Each channel displays its assigned slides in the order they'll be played, with options for adding regular or repeating slides, and updating the channel properties.
          <br><br>The order of slides inside a channel can be changed using drag and drop.
          <br><br>You can expand or collapse individual channels using the chevron button in the top left (<strong>></strong>).`,
        'channel-filters': `Here you can filter your channels based on a range of options such as name, owner and tags.`,
        'bulk-options': `If you wish to delete channels in bulk, you can find options for enabling/disabling multi-select and deleting the selected channels from this drop-down button.`,
        'channel-help': `More information on working with channels and re-ordering slides can be found here.`
      }
    },
    slides: {
      mainOverview: {
        intro: `<strong>All your messages in one place</strong>
          <br><br>This page lets you create new slides and manage your slide library.
          <br><br>Slides are used to display individual content layouts and can be assigned to one or more channels for playback on your screens.`,
        'create-button': `Click here to create a new slide.
          <br><br>This will take you to a new page where you can select from a range of layout templates, add and upload content and configure scheduling options. `,
        'slide-list': `Slides that have been created under your account are listed here.
          <br><br>Each slide shows a thumbnail preview of the content, as well as various slide properties and options.
          <br><br>Clicking on the thumbnail will take you to a new page where you can edit the slide and save changes.`,
        'slide-filters': `Here you can filter your slides based on a range of options such as name, owner, tags and the template used by the slide.`,
        'bulk-options': `If you wish to delete slides in bulk, you can find options for enabling/disabling multi-select and deleting the selected slides from this drop-down button.`,
        'slide-help': `More information on working with slides can be found here.`
      }
    },
    'edit-slide': {
      mainOverview: {
        intro: `<strong>Your message here!</strong>
          <br><br>This page is used to create a new slide (or edit an existing slide) for displaying content on your screens. If you chose to create a slide directly from a channel, the slide will automatically be added to that channel after it's been saved.`,
        'slide-details': `These are the standard fields for a slide, many of which are required.
          <br><br>The first field, <strong>Template</strong>, should be selected first. Choosing a template will determine the layout of the slide and will present you with additional fields depending on the type of content it's able to display. You'll also be able to toggle the <strong>Supported orientations</strong> if the template supports both landscape and portrait layouts.
          <br><br><strong>Display periods</strong> and <strong>Slide lifetime</strong> can be used to determine the times of day, and between which dates, the slide is shown.`,
        'slide-localisation': `You can manage the behaviour of a slide when it's displayed in each locale by clicking on <strong>Manage localisation</strong>. If you choose to localise content for multiple locales, you can use the drop-down list to switch to the locale you want to edit content for.`,
        'slide-manage-locales': `The configured behaviour of a slide for each of your account's available locales is displayed here. Clicking on each locale will provide a summary of the slide's display behaviour for screens assigned to that locale, and will allow you to modify the behaviour.
          <br><br>Unless specific display behaviour has been configured for a locale, it will inherit the behaviour and/or content of the closest parent locale that has been configured.`,
        'slide-manage-locales-behaviour': `These buttons allow you to modify the behaviour of the currently selected locale as follows:
          <br><br><strong>Localise slide</strong>: display a separate set of localised content for the selected locale.
          <br><br><strong>Link content</strong>: display the localised content from a specified target locale that has the <strong>localise slide</strong> behaviour.
          <br><br><strong>Disable slide</strong>: don't display the slide on screens with the selected locale.
          <br><br><strong>Remove custom behaviour</strong>: reset any behaviour configured from the above options and return to a state where the selected locale inherits its behaviour from a parent locale.`,
        'slide-reference-locale': `If you're editing content for a locale other than the default (<strong>All Screens</strong>), you can use this drop-down to select another locale (for which content is also being localised) to view its content values in-line.
          <br><br>If a reference locale has been selected, you can optionally click <strong>Copy reference locale values</strong> to set the content of the current locale to the same values as the reference locale.`,
        'slide-locale-status': `When multiple locales are being used to localise a slide's content, each one will need to be marked as complete before screens assigned to that locale will display the slide. You can also leave (or re-mark) a locale as pending if you wish to finalise its content later.
          <br><br>If the content configured for a locale is not valid (i.e. all required fields haven't been completed), you will not be able to mark it as complete. Although it's possible to save a slide with pending locales, there must be at least one locale marked as complete before you can save it.`,
        preview: `A preview of your slide will be displayed here.
          <br><br>The preview will continually update as you make changes to the slide's options and media. If you're creating a slide for both landscape and portrait screens, you can see how the other orientation will look at any time by clicking the <strong>Switch to portrait/landscape</strong> button in the top right.
          <br><br>Note that some templates (especially those that use feeds) are unable to display a live preview, and may instead use a combination of static imagery and placeholder text.`,
        'animate-button': `This button allows you to see how the template will look as part of a slideshow as it animates out and back in again.
          <br><br>After displaying the exit animation, the button will briefly countdown before animating back in, but you can also click it again to animate in immediately.`,
        'layout-edit-button': `The template you've selected allows you to edit certain aspects of the layout in the preview window.
          <br><br>You can turn the layout editing mode on and off by clicking here.`,
        'layout-edit-mode': `This is the layout editing mode. You can identify moveable elements by moving your mouse pointer over the preview and watching for changes to the mouse icon. Elements can be moved by clicking and dragging with the mouse to the desired position.
          <br><br>A <strong>hand icon</strong> indicates that the element's vertical and/or horizontal position can be changed between different pre-defined locations. A <strong>two-way arrow icon</strong> indicates that the element can be resized in the direction of the arrows. Each template will dictate which elements can be moved in this way, and whether they are subject to any guideline restrictions in the case of custom branded templates.
          <br><br>Note that you cannot move and zoom images within the layout editing mode - this can be done after completing changes to the layout and clicking the <strong>Done editing</strong> button.`,
        'layout-revert-button': `If you don't wish to save the changes you've made to the layout, you can click this button to revert back to the default or saved layout.
          <br><br>Note that any other changes already made to the slide content will not be affected when performing this action.`,
        'display-periods': `The times of day for which this slide should be displayed can be configured here. Simply select the days you wish to configure on the left, then change the display period on the right.
          <br><br>Clicking on <strong>Add new display period</strong> will allow you to configure different or multiple times for each day. If you change your mind later, you can delete individual display periods using the red button to the right of them. Note that if two or more display periods overlap, the slide will continue to show for the combined display period.
          <br><br>This configuration may optionally be used in conjunction with slide lifetime settings.`,
        'slide-lifetime': `These settings allow you to specify both the earliest and the latest time and date that a slide may be shown. If you only wish to restrict one end of the lifetime (i.e. earliest OR latest), you can leave the other time and date values blank.
          <br><br>This configuration may optionally be used in conjunction with display period settings.`,
        'slide-content': `These fields are specific to the selected template and determine the content and display options for the slide.
          <br><br>Some fields may have a padlock button next to them when creating a slide that supports both orientations. This can be clicked to split the field, allowing you to specify a different value for each orientation. If you change your mind later, you can click the padlock button again to merge the fields and specify only one value for both.`,
        'repositionable-image': `The template you've selected allows you to choose an image from your media library, or upload a new one.
          <br><br>You can use the <strong>Cover</strong> and <strong>Contain</strong> buttons to change between two preset image positions. Using <strong>Cover</strong> will ensure that the full screen is covered by the image, however this may result in parts of the image not being visible along the edge. Using <strong>Contain</strong> will ensure that the whole image fits inside the screen, but may result in black "letter-boxing" around the edges. For the best experience, we recommend that full screen images have an aspect ration of 16:9, either 720p (1280x720 pixels) or 1080p (1920x1080 pixels).
          <br><br>You can also click and drag to manually position an image inside the preview window, while using your mouse scroll-wheel to zoom in or out. If you want to reset the position and zoom level of the image, simply select one of the cover or contain buttons again. Note that you will need to have exited the layout editing mode (where available) in order to move or zoom an image.
          <br><br>Finally, if the image you select has a transparency layer, you can select an appropriate background colour in place of the default (black).`,
        'slide-usage': `This section provides details of which channels the slide is currently being used in, displaying the channel name and either the number of instances (for regular slides) or the repeat interval (for repeating slides).
          <br><br>If your user role includes permission to update channels, you can also make bulk changes to the slide's usage at the same time as (or instead of) making changes to the slide.`
      }
    },
    feeds: {
      mainOverview: {
        intro: `<strong>Dynamic data for your slides</strong>
          <br><br>This page lets you create and manage external data feeds.
          <br><br>Feeds allow you to retrieve data from the Internet (e.g. RSS news and social media) and are displayed by assigning the feed to a slide that uses a compatible template.`,
        'manage-button': `If your role allows you to manage feed providers, you can manage the providers that are enabled on your account here.
          <br><br>Note that you cannot disable custom providers, or providers that are currently in use by one or more feeds.`,
        'create-button': `Click here to create a new feed. You'll be asked to provide a name and select the type of feed you wish to create before being able to search for feed data.`,
        'feed-list': `Feeds that have been created under your account are listed here.
          <br><br>Clicking on a feed will allow you to search for new data or update that feed's properties.`,
        'feed-filters': `You can click on this button to expand and collapse the feed filters, allowing you to filter feeds based on properties such as their name and tags.`,
        'feed-help': `More information on working with feeds can be found here.
          <br><br>You can return to this view at any time by clicking on <strong>About feeds</strong> in the top left.`,
        'edit-view': `This is the view used for editing a feed.`,
        'edit-view-left': `<strong>The left side</strong> of this page shows the properties and status of the feed.`,
        'edit-view-properties': `General feed properties can be found and updated here.`,
        'edit-view-curated': `When a feed is in curated mode, any items that you've approved will be listed here. There are two feeds modes available:
          <br><br><strong>Curated</strong>: in this mode, only items that you've pre-approved will be displayed on your screens. This is particularly useful in certain scenarios such as displaying social media posts that originate from people outside of your organisation.
          <br><br><strong>Live</strong>: in this mode, new items will be fetched periodically and displayed on your screens (items can still be approved, but the curated list will be ignored).
          <br><br>You can toggle the <strong>Curated Feed</strong> checkbox at any time to switch between live and curated modes.`,
        'edit-view-right': `<strong>The right side</strong> of this page is used to update search criteria, retrieve new feed items and optionally approve them.`,
        'edit-view-search': `Here you'll find a range of options for querying the feed's external service for data. For some feeds you'll also see options for filtering and modifying the results.
          <br><br>It may not be necessary to provide a value for every option listed. If you need help understanding any of the options, just hover over the <strong>?</strong> tooltip icon above them for more information.
          <br><br>After entering or updating the criteria, just click on <strong>Search</strong> to fetch the latest feed items.`,
        'edit-view-new': `New feed items will be displayed here. This area and those below it can be expanded or collapsed for convenience using the chevron button in the top left (<strong>></strong>).
          <br><br>Note that you'll also see a preview of the currently selected feed item above this area from which you can approve or reject it when using a curated feed.`,
        'edit-view-filtered': `Items that didn't match the search criteria will be displayed here. You can hover over each item to see why it was automatically filtered.`,
        'edit-view-rejected': `Items that you've manually rejected during curation will be displayed here.
          <br><br>If you change your mind later, you can re-approve any item from this list.`
      }
    },
    media: {
      mainOverview: {
        intro: `<strong>Your media library</strong>
          <br><br>This page lets you manage any images and videos that have been uploaded to your account.
          <br><br>You can click on each thumbnail to view the media in more detail.`,
        'media-filters': `Here you can filter uploaded media by entering a name or tags to match by.`,
        'media-sorting': `You can also use these buttons to sort media by the date it was uploaded, its owner, or its name.`,
        'edit-modal': `This view allows you to edit media details such as the name and tags, and also share, download or delete the media from your library.`
      }
    },
    settings: {
      mainOverview: {
        intro: `<strong>Fine-tune your account</strong>
          <br><br>This page provides a range of settings for your account, including security and notification settings, slideshow preferences, and the ability to connect third party service accounts for use with feeds.`,
        'general-settings': `Certain user and screen settings listed here act as defaults for the account, and may be overridden on a per-item basis from the user preferences and screens pages respectively.`,
        'slideshow-preferences': `You can upload or select existing images to be displayed on your screens when they have no other content scheduled.
          <br><br>As with uploading media when creating slides, any images uploaded here will be stored in your media library.`,
        'connect-accounts': `Here you'll find a list of third party services that require authorisation before being used with feeds.
          <br><br>Clicking one of these buttons will redirect you to the relevant third party website, where you'll be asked to log in and authorise Flare to access data on your behalf. After accepting, you'll be redirected back to this page.`,
        'connected-accounts': `Any third party services that you've already authorised for data access will be listed here. Each one provides details of the Flare user that authorised the access and a button that will allow you to disconnect the service if you later change your mind.`
      }
    },
    users: {
      mainOverview: {
        intro: `<strong>People and permissions</strong>
          <br><br>This page allows you to add and manage users under your account, and also set up fine-grained access permissions on a user-by-user basis.`,
        'user-list': `You can see a list of all registered users under your account here.
          <br><br>Each user has their own set of login credentials (email address and password), and a role that determines their access permissions.
          <br><br>You can quickly change a user's role using the drop-down list to the right, or see more user details by clicking on the entry for them.`,
        'user-details': `Here you can view details for the selected user, including their email address, the last time they logged in, and any sub-account access that has been provisioned for them.
          <br><br>You can lock a user account to temporarily stop them from using Flare, or delete them altogether if they no longer require access. If the user has not yet activated their account, you'll also see the option to re-send their activation email in the event that their activation code has expired.`,
        'create-user-button': `Click here to create a new user.
          <br><br>You'll be asked for their name, email address, and the role they should be assigned.
          <br><br>The new user will receive an email asking them to set their password before logging in.`,
        roles: `You can view and edit the permissions of user roles here.
          <br><br>Flare comes with some pre-defined user roles which may already cover your requirements. These roles are prefixed with "<strong>Flare -</strong>" in the list, and cannot be edited.
          <br><br>You can also create new roles that are specific to your account. If you have a specific access requirement and need help with creating a role, please reach out to the team using the <strong>In-app support</strong> button at the bottom of the left navigation menu.`
      }
    },
    localisation: {
      mainOverview: {
        intro: `<strong>Adapting content with localisation</strong>
          <br><br>This page allows you to view the locales used by your account. Each screen can have a single locale assigned to it based on its location or function, which will allow it to display a localised version of each slide based on which locales the content has been adapted for, or not display certain slides at all.
          <br><br>If this is your main company account, any locales listed here will also be inherited by any company sub-accounts. Each sub-account will be able to use these locales in the same way as the main account, but will not be able to modify, add, or remove locales.`,
        'default-locale': `This is the default locale, and the root of the locale tree. If additional locales are added to the tree, the default locale will be targeted when creating a new slide, and users will be given the option to select other locales to customise content for. If additional locales are <strong>NOT</strong> added to the tree, you will only be able to define a single set of content for each slide.
          <br><br>Note that locales are created in this tree structure such that each child locale can inherit content from its parent locales (all the way up to the default locale) if there is no custom behaviour defined for that locale. To ensure content inheritence works correctly, we recommend organising your locale tree by increasing specificity, e.g. <strong>All Screens</strong> > <strong>Europe</strong> > <strong>UK</strong> > <strong>London</strong>.`,
        'locale-buttons': `You can add, remove, and rename locales based on the options shown here. Although there is no restriction on adding new locales, you may only remove an existing locale if it has no child locales and is not currently being used by any screens or slides under your account or its sub-accounts.
          <br><br>The default locale cannot be removed or renamed.`,
        'locale-drag': `Locales can be re-arranged within the tree by clicking on this area and dragging them to the desired position. You can drag locales both vertically and horizontally to update their path of inheritance, but particular attention should be paid to ensure the locale has the correct horizontal indentation (and therefore the correct parent) after moving it; a locale's parent is always the closest locale above itself in the tree that has a smaller indentation.
          <br><br>Note that re-arranging the tree could cause unexpected results if the affected locales are already being used, so any changes should be made with extreme caution.`,
        'locale-search': `You can use this area to search for locales by name, and also expand or collapse all branches of the tree.
          <br><br>If you're able to make changes to locales, you'll be able to save or discard your changes to the tree here.`
      }
    },
    'group-manager': {
      mainOverview: {
        intro: `<strong>Get sharing with groups</strong>
          <br><br>This page allows you to setup and manage sharing groups.
          <br><br>If your account has sub-accounts, you can create groups to share certain types of content between them, such as slides, media, and feeds.`,
        'create-button': `Click here to create a new group.`,
        'group-list': `Groups that you or other users on your account have created are listed here.
          <br><br>Clicking on a group will allow you to update its general configuration, and modify sharing permissions for each account.`,
        'group-help': `More information on working with groups can be found here.`,
        'edit-group': `This view lets you modify a new or existing group.
          <br><br>If included in the group, each account (including your own) can be given either view or publish permissions:
          <br><br><strong>View</strong>: users of the account will be able to see and use any content shared to the group by other accounts, but cannot share their own content with the group.
          <br><br><strong>Publish</strong>: like the View permission, but users of the account will also have the ability to share content to the group.
          <br><br>Note that regardless of the permission set for their account, user's will not be able to modify any content shared with them. Any changes to content after it has been shared will be reflected in the other accounts of the group.`
      }
    },
    takeovers: {
      mainOverview: {
        intro: `<strong>Priority message broadcasting with takeovers</strong>
          <br><br>This page allows you to create takeovers and trigger them to be displayed on your screens.
          <br><br>Takeovers can be used to temporarily override a screen's assigned content for a set period of time with an existing or dynamically-created slide.`,
        'create-button': `Click here to create a new takeover.`,
        'quick-content': `If certain content fields will need to be modified each time the takeover is triggered, you can select them to be displayed by default in the main view from this column. You'll still be able to access other content fields in the main view by clicking the <strong>Show more</strong> arrow.`,
        'takeover-list': `Takeovers that have been created under your account are listed here.
          <br><br>Each takeover displays the assigned slide (or slide content fields if not using an existing slide) as well as the default targeted screens and duration settings.
          <br><br>This main view allows you to make one-off changes to the content, targets and duration of the takeover before triggering it to be displayed on your screens.
          <br><br>You can expand or collapse individual takeovers using the chevron button in the top left (<strong>></strong>).`,
        'takeover-slide-content': `The content assigned to the takeover is displayed here.
          <br><br>When an existing slide is assigned to the takeover, the details of it are shown here. You can click on <strong>Edit slide</strong> to make changes to the slide in your library, however these changes will also be reflected on any screens where the slide is used as part of a channel or playlist.
          <br><br>If a slide is being dynamically created for the takeover, any saved <strong>Quick content</strong> fields will be listed here, allowing one-off changes to be made for each time the takeover is triggered. Additional slide content fields can be displayed and hidden again using the <strong>Show more</strong>/<strong>Show less</strong> arrows respectively.
          <br><br>The <strong>Preview</strong> button can be used to check how the slide will be displayed in each orientation, and to make layout changes when using a dynamically-created slide.`,
        'takeover-target-duration': `The targeted screens and duration of the takeover are displayed here.
          <br><br>Before triggering a takeover, you can make a one-off change to the screens it will be displayed on, either by selecting and de-selecting screens individually, or using one or more saved searches.
          <br><br>The duration fields will be automatically populated based on the takeover's default duration. You can change between the takeover displaying until a certain time, or for a set amount of time, by clicking the <strong>Show until</strong>/<strong>Show for</strong> labels respectively.`,
        'takeover-reset-button': `This button will revert any one-off changes you've made to the takeover content, targets or duration.
          <br><br>Note that any one-off changes you make will only be visible to you, and will reset if you logout or refresh the page.`,
        'takeover-trigger-button': `You can use this button to trigger a takeover using the displayed configuration. The slide (existing or dynamically-created) will be broadcast to the specified screens and an entry added to the <strong>Active takeovers</strong> list to the right.`,
        'takeover-options-button': `Takeover options can be accessed from this drop-down menu, allowing you to update webhook settings or delete the takeover.
          <br><br>You can also select <strong>Edit takeover</strong> from this menu to update the default configuration. This will save new values for the content, target and duration configuration in case you need your changes to be persisted.`,
        'active-takeovers': `Takeovers that have been triggered and are currently being displayed on your screens are listed here.
          <br><br>Each active takeover will show a preview of the slide, how many screens are targeted, and the time at which it will expire. You can cancel a takeover at any time by clicking on <strong>Stop</strong>, removing it from any screens that it's being displayed on.
          <br><br>Note that if multiple takeovers are active on a single screen, the most recently triggered takeover will take priority.`,
        'takeover-webhook': `Enabling webhooks for a takeover allows you to trigger it automatically by issuing an HTTP POST request to the displayed URL from an external system. When a webhook URL is generated, it will only be displayed once - you should ensure that the URL is recorded securely, as anyone with access to it will be able to trigger the takeover.
          <br><br>Webhooks also allow you to partially substitute slide text content with JSON data contained in the body of the POST request. This can be achieved in any of the text-based slide content fields by wrapping a dot-notation variable name in double braces, e.g. 'My variable is {{body.path.to.my.variable}}'.
          <br><br>Note that webhooks cannot currently be used for takeovers that target screens using saved searches.`
      }
    },
    tickers: {
      mainOverview: {
        intro: `<strong>Get the message out with Tickers</strong>
          <br><br>This page allows you to create Tickers that can be used to display simple text messages over the top of content provisioned to your screens.`,
        'create-button': `Click here to create a new ticker.`,
        'display-type': `<strong>Choosing a display style</strong>
          <br><br>The selected display type will determine the behaviour/animation of your ticker.
          <br><br><strong>Individual</strong>
          <br><br>Individual messages slide into view, displaying statically for a fixed period of time. If a message is too long to be displayed in its entirety without scrolling, then it will scroll aross the screen.
          <br><br><strong>Combined</strong>
          <br><br>Combined tickers combine all of their messages, separating them with a divider icon, and scroll them across the screen in one seamless animation.`,
        'continuous-display': `The continuous display option determines whether the ticker will remain on the screen indefinitely, looping back to the start of the configured messages immediately after finishing the last, or whether to hide the ticker for a fixed duration after the last item has been shown before showing and restarting the messages.`,
        'reorder-messages': `You can drag and drop ticker messages to change the order in which they're displayed.`
      }
    }
  };
  return {
    getText: function (path) {
      return _.get(texts, path, 'MISSING TEXT');
    },
  };
});
