angular.module('template.utils.library', ['oc.lazyLoad'])
  .run(function($ocLazyLoad){
    $ocLazyLoad.setModuleConfig({name: 'angular-chartist', files: ['js/angular-chartist.js']});
    $ocLazyLoad.setModuleConfig({name: 'flare.encoding', files: ['js/encoding.js']});
    $ocLazyLoad.setModuleConfig({name: 'flare.receiver.utils.animation', files: ['js/animation.js']});
    $ocLazyLoad.setModuleConfig({name: 'force-aspect-ratio', files: ['js/force-aspect-ratio.js']});
    $ocLazyLoad.setModuleConfig({name: 'flare.receiver.utils.images.preview', files: ['js/images-preview.js']});
    $ocLazyLoad.setModuleConfig({name: 'flare.receiver.utils.asset-preload', files: ['js/preload.js']});
    $ocLazyLoad.setModuleConfig({name: 'flare.receiver.utils.images', files: ['js/images.js', 'css/images.css']});
    $ocLazyLoad.setModuleConfig({name: 'soda.feeds', files: ['js/soda.feeds.js']});
    $ocLazyLoad.setModuleConfig({name: 'flare.receiver.utils.social', files: ['js/social.js']});
    $ocLazyLoad.setModuleConfig({name: 'flare.utils.text', files: ['js/text-utils.js']});
    $ocLazyLoad.setModuleConfig({name: 'pxn-drag-and-drop', files: ['js/pxn-drag-and-drop.js']});
});