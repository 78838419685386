angular.module('pixelnebula.ui.select', [])

.component('pxnSelect', {
  // require: '^ngModel',
  templateUrl: 'utility/inputs/pxn-select.jade',
  controller: 'pxnSelectController',
  bindings: {
    options: '<', // {label, value, icon }
    selected: '<', // value
    event: '<',
    disabled: '<'
  }
})

.controller('pxnSelectController', function ($scope, $document, $element) {
  var $ctrl = this;

  $ctrl.expanded = false;
  $ctrl.selectedLabel = function selectedLabel () {
    return _.find($ctrl.options, function (option) {
      return option.value === $ctrl.selected;
    }).label;
  };

  $ctrl.toggle = function toggle () {
    if ($ctrl.disabled) {
      return;
    }
    $ctrl.expanded = !$ctrl.expanded;
  };

  $ctrl.changeValue = function changeValue (value) {
    $scope.$emit($ctrl.event, value);
    $ctrl.selected = value;
    $ctrl.toggle();
  };

  function isDescendant (parent, child) {
    var node = child.parentNode;
    while (node != null) {
      if (node === parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }

  $document.bind('click', function clickEventListener ($event) {
    var isClickedElementChildOfPopup = isDescendant($element[0], $event.target);
    if (isClickedElementChildOfPopup) {
      return;
    }
    $scope.$apply(function () {
      $ctrl.expanded = false;
    });
  });
});