angular.module('sender').directive('jsonText', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      errorMessage: '=jsonText'
    },
    link: function (scope, element, attr, ngModel) {
      function into (input) {
        try {
          const parsed = JSON.parse(input);
          scope.errorMessage = '';
          return parsed;
        } catch (err) {
          scope.errorMessage = err.toString();
        }
      }
      function out (data) {
        return JSON.stringify(data, function (key, value) {
          if (key === '$$hashKey') {
            return undefined;
          }

          return value;
        }, 2);
      }
      ngModel.$parsers.push(into);
      ngModel.$formatters.push(out);
    }
  };
});