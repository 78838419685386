angular.module('pxn-dropdown-menu', [])
.directive('pxnDropdownMenu', function () {
  return {
    restrict: 'E',
    bindToController: true,
    require: {
      scrollCtrl: '^pxnScrollElement'
    },
    transclude: true,
    template: function ($element, $attrs) {
      return '<pxn-button ' +
          'type="icon" ' +
          'icon-class="entypo-down-open" ' +
          'modifiers="' + $attrs.modifiers + '" ' +
          'background="' + ($attrs.bgcolor ? $attrs.bgcolor : 'accent-1') + '" ' +
          'ng-click="menuClicked()" ' +
        'class="dropdown-menu--button">' + $attrs.buttonText + '</pxn-button>' +
          '<ng-transclude ' +
            'class="dropdown-menu--items' + ($attrs.pointer ? ' dropdown-menu--pointer' : '') + '" ' +
            'ng-class="{ \'dropdown-menu--items__open\': showMenu }"' +
            'ng-click="closeDropdownMenus()"' +
          '></ng-transclude>';
    },
    controller: function ($scope, $element, $log, $document, $timeout) {
      $scope.showMenu = false;
      $scope.buttonText = 'Set button-text attribute';
      $scope.modifiers = 'small';

      var vm = this;

      /**
       * Calculates which way the pxn-dropdown-menu should open.
       * Calls setMenuPosition() function to apply/remove classes
       */
      function getDirectionClasses () {
        /*
        Current options are dropdown-menu__menu-up or dropdown-menu__menu-down
        left and right classes were added for future proofing
        */
        var classChoices = [
          'dropdown-menu__menu-up',
          'dropdown-menu__menu-left',
          'dropdown-menu__menu-down',
          'dropdown-menu__menu-right'
        ];
        // Default opening direction
        var classToAdd = 'dropdown-menu__menu-down';
        var classesToRemove;

        var buttonRect = $element[0].getBoundingClientRect();
        var scrollRect = vm.scrollCtrl.scrollElement.getBoundingClientRect();

        var buttonPosition = buttonRect.y;
        var scrollPosition = scrollRect.y;
        var buttonHeight = buttonRect.height;
        var scrollHeight = scrollRect.height;
        var listHeight = $element.children()[1].clientHeight;

        // Checks if the list wouldn't fit on screen below button
        if (
          ((buttonPosition - scrollPosition) + buttonHeight + listHeight) > scrollHeight
        ) {
          classToAdd = 'dropdown-menu__menu-up';
        }

        classesToRemove = _.without(classChoices, classToAdd);

        setMenuPosition(classToAdd, classesToRemove);
      }

      /**
      * Applies and removes classes given from getDirectionClasses() fn.
      * @param {[string]} className Name of the class to be added
      * @param {[string]} classesToRemove Name of the classes to be removed
      */
      function setMenuPosition (className, classesToRemove) {
        // We're only want to chek this when we open a button
        if ($scope.showMenu) {
          if (!className) {
            // TODO: Decide what's actually best for here
            $log.warn('You didn\'t provide a className');
            return;
          } else {
            $element.addClass(className);
          }
          // Check to see if we have any classes to remove
          if (classesToRemove) {
            if (!angular.isArray(classesToRemove)) {
              classesToRemove = [classesToRemove];
            }
            $element.removeClass(classesToRemove.join(' '));
          }
        }
      }

      function closeMenu (apply = true) {
        $document.off('click', closeMenu);
        if (apply) {
          $scope.$apply(()=> {
            $scope.showMenu = false;
          });
        } else {
          $scope.showMenu = false;
        }
      }

      $scope.menuClicked = function () {
        getDirectionClasses();
        if (!$scope.showMenu) {
          // We're about to open a menu, make sure all others are closed
          $scope.$emit('pxn-dropdown-menu__opened');
        }
        $scope.showMenu = !$scope.showMenu;
        if ($scope.showMenu) {
          $timeout(()=> {
            $document.on('click', closeMenu);
          });
        }
      };

      $scope.$on('$destroy', function () {
        $document.off('click');
      });

      $scope.$on('pxn-dropdown-menu__closeAll', function () {
        closeMenu(false);
      });
    }
  };
})
.directive('pxnDropdownMenuGroup', function () {
  return {
    restrict: 'A',
    controller: function ($scope) {
      $scope.closeDropdownMenus = function () {
        $scope.$broadcast('pxn-dropdown-menu__closeAll');
      };
      $scope.$on('pxn-dropdown-menu__opened', function () {
        $scope.$broadcast('pxn-dropdown-menu__closeAll');
      });
    },
  };
});