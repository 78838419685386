var pxnPeriodInput = {
  bindings: {
    minDuration: '<'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  bindToController: true,
  templateUrl: 'utility/inputs/pxn-period-input/pxn-period-input.jade',
  controller: 'pxnPeriodInputController'
};

angular
  .module('sender')
  .component('pxnPeriodInput', pxnPeriodInput);