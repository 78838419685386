    angular.module 'flare.receiver.utils.social', ['ngSanitize', 'flare.encoding']

This filter adds a `<span>` with class `hashtag` to hashtags and mentions.

    .filter('pxnHashtagHighlight', ($sce, Encoder)->
      (value, color)->
        if !value? then return
        hashtags = /((#|@)([a-z]+)((\.[a-z0-9_])|([a-z0-9_])|&#(159|192|193|194|195|196|199|200|201|202|203|204|205|206|207|209|210|211|212|213|214|217|218|219|220|221|224|225|226|227|228|231|232|233|234|235|236|237|238|239|241|242|243|244|245|246|249|250|251|252|253|255);)*)/igm
        text = Encoder.getTextContent(value)
        if color
          text = text.replace(hashtags, " <span class=\"hashtag\" style=\"color: #{color}\">$1</span>")
        else
          text = text.replace(hashtags, ' <span class="hashtag">$1</span>')
        $sce.trustAsHtml(text)
    )

This factory returns a set of functions that can be used to normalise social
media posts, helping to establish a default format to be used in templates.

    .factory('Normalise', ()->

      normaliseInstagramItem = (item)->
        result =
          _id: item.id
          timestamp: (parseInt(item.created_time) * 1000) or null
          service: 'instagram'
          message: item.caption?.text or ''
          img: item.images?.standard_resolution?.url or item.videos?.standard_resolution?.thumbnail_url
          video: item.videos?.standard_resolution?.url
          user:
            nickname: item.user?.username
            profile_img: item.user?.profile_picture
          hasLikes: !!item.likes?.count
          likes: item.likes?.count or 0
          inResponseTo: null
          service_logo: '../img/service_logos/instagram_logo.png'
        return result

      normaliseTwitterItem = (item)->
        result =
          _id: item.id_str
          timestamp: item.unixCreated or null
          service: 'twitter'
          message: item.text
          img: if item.hasImage then item.entities?.media?[0]?.media_url_https else null
          user:
            nickname: item.user?.screen_name
            profile_img: item.user?.profile_image_url_https
          likes: item.favorite_count
          hasLikes: !!item.favorite_count
          inResponseTo: null
          service_logo: '../img/service_logos/twitter_logo.png'
        return result

      normaliseYammerItems = (item)->
        result =
          _id: item.id
          timestamp: item.created_at
          service: 'yammer'
          message: item.body
          img: item.imageUrl or null
          user:
            nickname: item.sender?.name
            profile_img: item.sender?.mugshotUrl
          likes: item.liked_by?.count
          hasLikes: !!item.liked_by?.count
          inResponseTo: null
          service_logo: '../img/service_logos/yammer_logo.png'

        if item.replied_to
          result.inResponseTo =
            message: item.replied_to.body
            timestamp: item.replied_to.created_at
            user:
              nickname: item.replied_to.sender?.name
              profile_img: item.replied_to.sender?.mugshotUrl

        return result

      normaliseFacebookItem = (item)->
        result =
          _id: item.id
          timestamp: item.created_time
          service: 'facebook'
          message: item.message
          img: if item.image_url then item.image_url else null
          user:
            nickname: item.from.name
            profile_img: item.from.mugshot_url
          likes: item.likes
          hasLikes: item.likes > 0
          inResponseTo: null
          service_logo: '../img/service_logos/facebook_logo.png'

        if item.in_reply_to
          result.inResponseTo =
            message: item.in_reply_to.message
            timestamp: item.in_reply_to.created_time
            user:
              nickname: item.in_reply_to.from?.name
              profile_img: item.in_reply_to.from?.mugshot_url

        return result

      normaliseWorkplaceItem = (item)->
        result = normaliseFacebookItem(item)
        result.service = 'workplace'
        result.service_logo = '../img/service_logos/workplace_logo.png'
        return result


      normalisePinterestItem = (item)->
        result =
          _id: item.id
          timestamp: (new Date(item.created_at)) or null
          service: 'pinterest'
          message: item.note or ''
          img: item.image?.original?.url or ''
          user:
            nickname: item.creator?.first_name + ' ' + item.creator?.last_name
            profile_img: item.creator?.image['60x60'].url or ''
          hasLikes: !!item.counts?.saves
          likes: item.counts?.saves or 0
          inResponseTo: null
          service_logo: '../img/service_logos/pinterest_logo.png'
        return result

      normaliseBeekeeperItem = (item) ->
        divider = if item.title and item.text then ' · ' else ''
        result =
          _id: item.uuid
          timestamp: (new Date(item.created))
          service: 'beekeeper'
          message: item.title + divider + item.text
          img: item.attachment_url
          user:
            nickname: item.display_name
            profile_img: item.avatar_url
          hasLikes: !!item.like_count
          likes: item.like_count or 0
          inResponseTo: null #TODO
          service_logo: '../img/service_logos/beekeeper_logo.png'
        if item.comment_of
          comment = item.comment_of
          divider = if comment.title and comment.text then ' · ' else ''
          result.inResponseTo =
            message: comment.title + divider + comment.text
            timestamp: (new Date(comment.created))
            user:
              nickname: comment.display_name
              profile_img: comment.avatar_url
        return result

      transforms =
        yammer: normaliseYammerItems
        twitter: normaliseTwitterItem
        instagram: normaliseInstagramItem
        facebook: normaliseFacebookItem
        workplace: normaliseWorkplaceItem
        pinterest: normalisePinterestItem
        beekeeper: normaliseBeekeeperItem

      return {
        socialMessage: (domain, item)->
          if !item then return
          if item._transformed?
            return item
          service = domain
          fn = transforms[domain]
          transformedItem = fn(item)
          transformedItem.hasMedia = !!(transformedItem.img || transformedItem.video)
          transformedItem._transformed = true
          if item.collection
            transformedItem.collection = item.collection
          return transformedItem
      }
    )
