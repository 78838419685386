angular
  .module('flare.utils.text', [])

  .factory('TextUtils', function ($log, $timeout, FlareSettings) {
    let logPrefix = '[TextUtils] - ';
    const DEFAULT_STEP = 0.1; // Number of decimal places to round EM values to.
    const TOFIXED_NUM = 4;

    /**
     * Takes the height of the element & it's container and returns the word
     * `up`/`down` indicating the direction to which we should scale text.
     *
     * @param {Number} containerHeight The height of the container element.
     * @param {Number} elemHeight The height of the element to be scaled.
     * @returns {String} Text representation of the scale direction.
     */
    const getScaleDirection = (containerHeight, elemHeight) =>
      elemHeight <= containerHeight ? 'up' : 'down';

    /**
     * This recursive function takes an element and, by setting its font size,
     * attempts to make the content fit as snugly as possible into the target
     * height. It accepts a predefined stepped range of font sizes to try,
     * and uses a binary search approach to find the best fit.
     *
     * Note that this function does change the css of the element directly,
     * thereby mutating it. The final font size is provided as the function
     * should most commonly be used on an invisible clone of a DOM element
     * so that viewers do not see the computation happening.
     *
     * @param {String} direction The direction which we should scale the elem.
     * @param {JQLiteElement} element The element to be scaled.
     * @param {Number} currentScale The current scale value.
     * @param {Array} scaleRange Array of numbers representing valid steps in
     * our range
     * @param {Number} containerSize The height or width of the container element.
     * @param {boolean} isHorizontal Scale horizontally? (default vertical)
     * @returns {Object} A CSS definition object to be used with JQLiteElements
     *                    .css Fn.
     */
    const scaleElem = (
      direction,
      element,
      currentScale,
      scaleRange,
      containerSize,
      isHorizontal
    ) => {
      const providedRange = scaleRange;

      // Last iteration we tried `currentScale`, and chose a direction based
      // on whether the contents fit in the container or not. We can therefore
      // reduce the range by filtering out values either less than, or greater
      // then or equal to the current scale, depending on whether the item fit
      // or didn't fit respectively.
      scaleRange = scaleRange.filter(scale =>
        direction === 'up' ? scale >= currentScale : scale < currentScale
      );

      let nextScale;
      if (scaleRange.length > 1) {
        let i = Math.floor((scaleRange.length - 1) / 2);
        nextScale = scaleRange[i];
        if (nextScale === currentScale) {
          // The only possible way for this to happen is if we've just tried
          // currentScale, it fitted, and we are left with only currentScale and
          // one other value in the array. We should therefore try the other
          // value next
          nextScale = scaleRange[i + 1];
        }
      } else if (scaleRange.length === 1) {
        return {
          fontSize: scaleRange[0].toFixed(TOFIXED_NUM) + 'em'
        };
      } else {
        // This should never happen if the function is being used properly.
        // The only way to get to this point is if we have tried the lowest
        // possible value and it didn't fit, so we filtered out everything else.
        // The logical approach here is therefore to use the lowest allowable
        // value from the original range.
        return {
          fontSize: providedRange[0].toFixed(TOFIXED_NUM) + 'em'
        };
      }
      // Set the new font size so that we can remeasure.
      element.css({ fontSize: nextScale.toFixed(TOFIXED_NUM) + 'em' });
      // Move forwards and try again.
      return scaleElem(
        getScaleDirection(
          containerSize,
          element[0].getBoundingClientRect()[isHorizontal ? 'width' : 'height']
        ),
        element,
        nextScale,
        scaleRange,
        containerSize,
        isHorizontal
      );
    };

    const percentageRange = (min, max, percentageStepString, start = 1) => {
      const range = [start];
      let current = start;
      // convert (e.g.) 5% to 0.95
      const decimalPercentage = (parseFloat(percentageStepString) / 100);
      let decimalDecrementor = 1 - decimalPercentage;
      while (current > min) {
        current *= decimalDecrementor;
        range.unshift(Math.max(current, min));
      }
      current = start;
      let decimalIncremetor = 1 + decimalPercentage;
      while (current < max) {
        current *= decimalIncremetor;
        range.push(Math.min(current, max));
      }
      return range;
    };

    /**
     * Clones the provided elem invisibly within it's parent
     * (setting { position: relative } if required) and recursively scaling to
     * find the best fit within our scaleLimits. The cloned element is then
     * removed and the result (a CSS definition Object) is returned for use by
     * the end user.
     *
     * @param {HTMLElement} element The text element, this should be contained
     * within an element whos size cannot change.
     * @param {Number[]} scaleLimits Array containing a start and end value from
     * which our range will be generated.
     * @param {Number|String} stepSize The size of step the range should be
     * broken into (lower number = more steps). You can also supply a string
     * e.g. '10%' to use percentages for the steps, which gives a smoother
     * scale.
     * @returns {Object|Null} An object (CSS definition object) that can be used
     * with JQLite.css() or null if unsuccessful.
     */
    const getOptimumTextSize = (
      element,
      scaleLimits,
      stepSize,
      isHorizontal
    ) => {
      // Ensure the values we have been provided are up to the job.
      if (!stepSize) {
        stepSize = DEFAULT_STEP;
      }
      if (
        !angular.isElement(element) ||
        !angular.isArray(scaleLimits) ||
        !stepSize
      ) {
        $log.warn(
          logPrefix + 'getOptimumTextSize: A required parameter was missing ' +
          'or not as expected', { element, scaleLimits, stepSize, isHorizontal }
        );
        return null;
      }
      let $element = angular.element(element);
      let computedParentStyles =
        window.getComputedStyle($element.parent()[0]);
      // Our element must be positioned relatively or absolutely as we create
      // an invisible replica and position it absolutely within the element.
      // If we're not using production settings then we'll warn the developer.
      if (
        FlareSettings.compileDebugInfo &&
        (computedParentStyles.position !== 'relative') &&
        (computedParentStyles.position !== 'absolute')
      ) {
        $log.warn(
          logPrefix + 'getOptimumTextSize: Element should be positioned ' +
          'relatively, if autoScale isn\'t working this would be a good place' +
          ' to look.'
        );
      }

      let elemStyles = window.getComputedStyle($element[0]);
      let currentScale = $element[0].style.fontSize || 1;
      // We expect currentScale to be a number representing the EM value of
      // fontSize. It's possible font size will have been set as a PX value, so
      // check and assume a starting size of 1 if we have a non EM value.
      if (typeof currentScale !== 'number' && _.includes(currentScale, 'em')) {
        currentScale = parseFloat(currentScale);
      } else if (typeof currentScale !== 'number') {
        currentScale = 1;
      }

      let scaleRange;

      if (typeof stepSize === 'string' && _.endsWith(stepSize, '%')) {
        // Percentage step size has been specified.
        scaleRange = percentageRange(scaleLimits[0], scaleLimits[1], stepSize);
      } else {
        scaleRange =
          _.range(scaleLimits[0], scaleLimits[1] + stepSize, stepSize);
      }
      scaleRange = _.map(scaleRange, function (i) {
        return parseFloat(i.toFixed(TOFIXED_NUM));
      });

      // Due to potential floating point inaccuracies with _.range, the last
      // value may be above the max.
      if (scaleRange[scaleRange.length -1] > scaleLimits[1]) {
        scaleRange.pop();
      }

      // Create & add an invisible replica to the page.
      let invisibleContainerElem = angular.element(
        document.createElement('div')
      );
      let invisibleScaleElem = angular.copy($element);
      invisibleContainerElem.css({
        visibility: 'hidden',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'block'
      });
      invisibleScaleElem.css({
        visibility: 'hidden',
        // fix height for horizontal measuring, or width otherwise.
        [isHorizontal ? 'height' : 'width']: '100%',
        display: elemStyles.display,
        flexDirection: elemStyles.flexDirection
      });
      invisibleContainerElem.append(invisibleScaleElem);
      $element.parent().append(invisibleContainerElem);

      // If our parent has a max height/width lets make sure we use it.
      let initialMaxValue =
        computedParentStyles[isHorizontal ? 'maxWidth' : 'maxHeight'];
      let forcedValue = false;
      // If a size has been set inline, we'll already be at our maximum
      // possible size as the minimum of height or maxHeight will always be
      // used.
      if (
        initialMaxValue !== 'none' &&
        !$element.css(isHorizontal ? 'width' : 'height')
      ) {
        // This element could expand as text is added, so set the size to max
        // possible to ensure we realise text can grow and still fit.
        $element.parent().css({ [isHorizontal ? 'width' : 'height']: initialMaxValue });
        forcedValue = true;
      }

      // Measure the invisible replica setup.
      let invisibleContainerElemRect =
        invisibleContainerElem[0].getBoundingClientRect();
      let invisibleScaleElemRect =
        invisibleScaleElem[0].getBoundingClientRect();

      // If the parent has padding then we need to remove it from our
      // height/width.
      let axisPaddingPx = 0;
      let paddingProps =
        isHorizontal ?
          ['paddingLeft', 'paddingRight'] :
          ['paddingTop', 'paddingBottom'];
      if (
        computedParentStyles[paddingProps[0]] ||
        computedParentStyles[paddingProps[1]]
      ) {
        axisPaddingPx =
          parseFloat(computedParentStyles[paddingProps[0]]) +
          parseFloat(computedParentStyles[paddingProps[1]]);
      }

      // Perform scale operations and store the result.
      let result = scaleElem(
        getScaleDirection(
          invisibleContainerElemRect[isHorizontal ? 'width' : 'height']
            - axisPaddingPx,
          invisibleScaleElemRect[isHorizontal ? 'width' : 'height']
        ),
        invisibleScaleElem,
        currentScale,
        scaleRange,
        invisibleContainerElemRect[isHorizontal ? 'width' : 'height']
          - axisPaddingPx,
        isHorizontal
      );

      // Remove our invisible replica.
      invisibleContainerElem.remove();
      // Reset the elements size to its original value if we overrode it for
      // measuring.
      if (forcedValue) {
        $element.parent().css({ [isHorizontal ? 'width' : 'height']: '' });
      }

      return result;
    };

    // Create a store and associated utility functions for easy management of
    // results.
    let valueStore = {};

    // Create/Update an entry in our valueStore.
    // NOTE: storeID represents the directive using TextUtils (i.e. autoScale)
    // NOTE: resultID is the unique ID assigned by the developer to the instance
    // this will commonly be a feed items GUID.
    function setStoreValue (storeID, resultID, newVal) {
      // Check that a value has been provided for store and result ID's.
      if (!storeID || !resultID) {
        $log.warn(
          logPrefix +
          `setStoreValue requires a valid storeID (${storeID}) &` +
          ` resultID (${resultID})`
        );
        return null;
      }

      // This may be the first time we're storing a value for a directive, for
      // that reason we need to check if the object exists and init if not.
      if (!valueStore[storeID]) {
        valueStore[storeID] = {};
      }

      // Store the value and return it
      valueStore[storeID][resultID] = newVal;
      return newVal;
    }

    // Get the value from any previous calculations.
    // NOTE: storeID represents the directive using TextUtils (i.e. autoScale)
    // NOTE: resultID is the unique ID assigned by the developer to the instance
    // this will commonly be a feed items GUID.
    function getStoreValue (storeID, resultID) {
      if (!storeID || !resultID) {
        $log.warn(
          logPrefix +
          `getStoreValue requires a valid storeID (${storeID}) &` +
          ` resultID (${resultID})`
        );
        return null;
      }

      if (!valueStore[storeID] || !valueStore[storeID][resultID]) {
        $log.debug(logPrefix + `No value stored in ${storeID} for ${resultID}`);
        return null;
      }

      return valueStore[storeID][resultID];
    }

    // Delete the value stored at the provided endpoint returning a bool to
    // indicate success.
    // NOTE: storeID represents the directive using TextUtils (i.e. autoScale)
    // NOTE: resultID is the unique ID assigned by the developer to the instance
    // this will commonly be a feed items GUID.
    function deleteStoreValue (storeID, resultID) {
      if (!storeID || !resultID) {
        $log.warn(
          logPrefix +
          `deleteStoreValue requires a valid storeID (${storeID}) &` +
          ` resultID (${resultID})`
        );
        return false;
      }

      if (!valueStore[storeID] || !valueStore[storeID][resultID]) {
        $log.debug(logPrefix + `No value stored in ${storeID} for ${resultID}`);
        return false;
      }

      delete valueStore[storeID][resultID];
      return true;
    }

    return {
      getOptimumTextSize: getOptimumTextSize,
      setStoreValue: setStoreValue,
      getStoreValue: getStoreValue,
      deleteStoreValue: deleteStoreValue
    };
  })

  .directive('truncateChildren',
  function truncateChildren ($timeout, TextUtils, $q, $log) {
    return {
      restrict: 'AE',
      require: [
        '?^^flareTemplate',
        '?^^flareTemplatePreview'
      ],
      link: function truncateChildrenLinkFn (scope, elem, attrs, ctrls) {
        // Get reference to available template controllers
        let tplCtrl = ctrls[0] || ctrls[1];
        let isPreview = ctrls[1];
        let storageKey;
        var logPrefix = '[truncateChildren] - ';

        // In some cases the template will already know if truncation is
        // required this means we can set the value here to be false and skip
        // unnecessary measuring.
        if (scope.$eval(attrs.truncateChildren) === false) return;

        function truncate () {
          // Check the storage for a stored value and restore if it exists.
          storageKey = scope.$eval(attrs.truncateChildrenCacheKey);
          let previousValue = attrs.truncateChildrenCacheKey ?
            TextUtils.getStoreValue('truncateChildren', storageKey) :
            null;
          if (previousValue) {
            $log.debug(
              logPrefix +
              'Restoring previous value'
            );
            // Restore the previous value.
            // NOTE: The previous value is an array of words that will need to
            // be joined, if truncation has occured the elipsis charcter will
            // have been added to the array so no further action required.
            elem[0].innerHTML = previousValue.join(' ');
            return;
          } else {
            // Measure & get heights for container & element.
            let containerHeight =
              elem.parent()[0].getBoundingClientRect().height;
            let textElemHeight = elem[0].getBoundingClientRect().height;

            // Break innerHTML into parts, 1 part represents a word possibly
            // wrapped in HTML i.e. `<b>Dave</b>` or `Dave`
            let htmlPartsReqex = /((?=<span)(\S+ \S+)|\S+)/gm;
            let htmlParts = elem[0].innerHTML.match(htmlPartsReqex) || [];
            if (!htmlParts.length) {
              $log.debug(
                logPrefix +
                'I have no words to truncate, this is probably an error.'
              );
            }
            let removed = [];
            let knownFitIndex = 0;

            // NOTE: `knownFitIndex` is updated when we measure and don't
            // overflow. It is the last known number of words that would fit
            // within our element without overflow.
            while (knownFitIndex !== htmlParts.length) {
              if (containerHeight < textElemHeight) {
                // We're overflowing and need to remove text.
                removed = htmlParts.splice(
                  htmlParts.length - ((htmlParts.length - knownFitIndex) / 2)
                );
              } else {
                // We are not overflowing and know that the current number of
                // items fits with the container.
                knownFitIndex = htmlParts.length;
                // If we have previously removed items, add 1/2 back & re-check.
                if (removed.length) {
                  htmlParts = htmlParts.concat(
                    removed.splice(0, removed.length / 2)
                  );
                }
              }

              // Our next action (add/remove text OR nothing) has been decided,
              // apply the result.
              elem[0].innerHTML =
                htmlParts.join(' ') + (removed.length ? ' …' : '');
              // Remeasure to see if performing the action has helped.
              textElemHeight = elem[0].getBoundingClientRect().height;
            }

            // This is our final value, push an elipsis into the words array if
            // we have removed anything.
            if (removed.length) {
              htmlParts.push(' …');
            }

            // Store our new value so we don't have to calculate it every time.
            if (
              attrs.truncateChildrenCacheKey && storageKey && // Store against
              htmlParts.length // Something to store
            ) {
              TextUtils.setStoreValue('truncateChildren', storageKey, htmlParts);
            }
          }
        }

        /** ***********************************************************
         *                                                            *
         * Initialise truncation using the most appropriate method(s) *
         *                                                            *
         **************************************************************/

        // Create a deferred to resolve when we are ready to truncate. We will
        // NEVER truncate before this has been resolved.
        let truncationDeferred = $q.defer();

        // Resolves our truncationDeferred promise when the promise provided to
        // the function is resolved.
        let resolveTruncationDeferredOnCustomPromiseResolved = (promise) => {
          // Check that we have a promise
          if (promise && typeof promise.then === 'function') {
            promise
              .then(function (resVal) {
                // The template is now "ready" resolve the truncation promise.
                truncationDeferred.resolve();
              })
              .catch(function (err) {
                if (err !== "cancel") {
                  $log.error(err);
                }
              });
          } else if (
            promise.promise && typeof promise.promise.then === 'function'
            ) {
            // Account for deferreds being provided (I'm nice like that).
            promise.promise
              .then(function (resVal) {
                // The template is now "ready" resolve the truncation promise.
                truncationDeferred.resolve();
              })
              .catch(function (err) {
                if (err !== "cancel") {
                  $log.error(err);
                }
              });
          }
        };

        // NOTE: Methods are stacking/linked, e.g. if the user has provided a
        // custom promise and custom event, firing the event will cause
        // truncation to take place after the promise has resolved.

        // Method 1 - Custom promise provided
        // NOTE: If a custom promise isn't provided we will hook into the
        // `templateCtrl`/`previewCtrl` and resolve our truncation promise on
        // ready. So we ALWAYS have a truncation promise that will be resolved
        // prior to truncation.
        if (attrs.truncateChildrenReadyPromise) {
          // If we don't have a value then it probably hasn't loaded yet so
          // wait/watch.
          if (!scope.$eval(attrs.truncateChildrenReadyPromise)) {
            scope.$watch(
              attrs.truncateChildrenReadyPromise,
              resolveTruncationDeferredOnCustomPromiseResolved
            );
          } else {
            resolveTruncationDeferredOnCustomPromiseResolved(
              scope.$eval(attrs.truncateChildrenReadyPromise)
            );
          }
        } else if (
          !_.includes(['preshow', 'animate-in', 'show'], (tplCtrl.state))
        ) {
          // We weren't provided a readyPromise so hook into the templateCtrl
          // and attempt to ready our promise when we would first be shown.
          const readyResolver = () => {
            let pspgIndex =
              tplCtrl.preShowPromiseGenerators.indexOf(readyResolver);
            if (pspgIndex !== tplCtrl.preShowPromiseGenerators.length - 1) {
              $log.debug(
                logPrefix +
                'The ready resolver is being run ahead of ' +
                ((tplCtrl.preShowPromiseGenerators.length - 1) - pspgIndex) +
                ' other preShowPromiseGenerator functions. IF you ' +
                'experience issues with truncation be sure that you are not' +
                ' modifying the item after it has been truncated.'
              );
            }
            truncationDeferred.resolve();
            // Remove this from preShowPromiseGenerators after it has been
            // called.
            $timeout(function () {
              _.pull(tplCtrl.preShowPromiseGenerators, readyResolver);
            });
            return $q.resolvedPromise();
          };

          if (isPreview) {
            tplCtrl.preReadyPromises.push(readyResolver());
          } else {
            tplCtrl.preShowPromiseGenerators.push(readyResolver);
          }
        } else {
          truncationDeferred.resolve();
        }

        truncationDeferred.promise
          .then(() => {
            $timeout(truncate);
          })
          .catch(function (err) {
            if (err !== "cancel") {
              $log.error(err);
            }
          });

        // Method 2 - Custom event provided
        // NOTE: Truncation will not occur until truncation deferred promise is
        // resolved even if the event is fired, firing the event ahead of the
        // truncation promise resolving will queue truncation.
        let listenerDeregFn;
        if (attrs.truncateChildrenOnEvent) {
          // NOTE: There is a niche, where kebab-cased/snake-cased text will be
          // evaluated as a number, if we end up with a number we will assume
          // that a string has been provided and use it as the value.
          scope.$watch(attrs.truncateChildrenOnEvent, (nVal, oVal) => {
            // If we have an oVal then we have a listener to remove
            if (listenerDeregFn) {
              listenerDeregFn();
              listenerDeregFn = null;
            }

            let evaluated = scope.$eval(attrs.truncateChildrenOnEvent);
            if (typeof evaluated === 'string') {
              // Use the evaluated value.
              listenerDeregFn = scope.$on(evaluated, () => {
                // We NEVER want to truncate before we're ready.
                truncationDeferred.promise
                  .then(() => {
                    $timeout(truncate);
                  })
                  .catch(function (err) {
                    $log.error(err);
                  });
              });
            } else {
              // We just wish to used the non evaluated text value.
              listenerDeregFn = scope.$on(attrs.truncateChildrenOnEvent, () => {
                // We NEVER want to truncate before we're ready.
                truncationDeferred.promise
                  .then(() => {
                    $timeout(truncate);
                  })
                  .catch(function (err) {
                    $log.error(err);
                  });
              });
            }
          });
        }
      }
    };
  })

  // Can be used within ng-if'd elements to show when loaded/in the DOM.
  // Emits an event with the supplied name when the link function is run. This
  // can be used to inform other directives that action is required, for example
  // with an ng-if'd element to prevent race conditions.
  .directive('emitOnLink', function ($log, $timeout) {
    return {
      restrict: 'A',
      link: function (scope, element, attributes) {
        let logPrefix = '[emitOnLink] - ';
        let id = attributes.emitOnLink;
        let data = { elem: element, id: id };
        $timeout(() => {
          scope.$emit(id, data);
          $log.debug(logPrefix + id + ': ' + JSON.stringify(data));
        });
      }
    };
  })

  // Applied to a text containers parent, this directive measures both the
  // container & child and scales the font size of the text container up or down
  // to help text fit better.
  .directive('autoScale', (TextUtils, $log, $timeout, $interval) => {
    return {
      restrict: 'A',
      require: [
        '?^^flareSlideshow',
        '?^^flareTemplate',
        '?^^flareTemplatePreview'
      ],
      link: (scope, element, attributes, controllers) => {
        const FALLBACK_INTERVAL_DURATION_MS = 500;
        const isPreview = !!controllers[2];
        const logPrefix = '[autoScale] - ';

        // eslint-disable-next-line
        let scaleMin = Number(attributes.scaleMin) || 0.5;
        let scaleMax = Number(attributes.scaleMax) || 2;
        let scaleRange = [scaleMin, scaleMax];

        // Get any stored value from the relevant storage location.
        const checkForValue = () => {
          if (attributes.autoScaleId) {
            // This is a feed based scale, there will never be a value stored
            // for the preview.
            return scope.autoScaleTextData && scope.autoScaleTextData[attributes.autoScaleId];
          } else {
            // This is a user input scale, value will be stored on content.
            return scope.content['f_' + attributes.autoScale];
          }
        };

        // Given a css object will apply styling to the appropriate element and
        // return the applied value.
        const applyValue = value => {
          if (value) {
            $log.debug(
              `${logPrefix} Applying value of ${value.fontSize} for ` +
              `${attributes.autoScale}${attributes.autoScaleId || ''}`
            );
            angular.element(element.children()[0]).css(value);
          }
          scope.$emit('autoScale--cssApplied', { filter: attributes.autoScaleResizeEventFilter });
          return value;
        };

        // Checks for a stored value and applies if found.
        const checkForValueAndApply = () => {
          return applyValue(checkForValue());
        };

        // Measures and returns the next value or null
        const measure = (entries) => {
          // If we have entries then this was called from an observer, we can
          // make a small optimsation here by NOT measuring if the item is being
          // removed from the DOM. After all it may never be re-added, it may be
          // animating and/or not displayed as intended and giveus a bad result.
          if (entries && entries.length) {
            let elemRemoved = false;
            _.each(entries, (entry, i) => {
              if (
                entry.contentRect &&
                !entry.contentRect.height && !entry.contentRect.width
              ) {
                elemRemoved = true;
              }
            });
            if (elemRemoved) return null;
          }
          // We're not being removed so lets measure and return the value
          return TextUtils.getOptimumTextSize(
            element[0].children[0],
            scaleRange,
            // If a step with a percentage has been specified, pass the full
            // string as the getOptimumTextSize func checks for '%'.
            _.endsWith(attributes.step || '','%')
              ? attributes.step : parseFloat(attributes.step),
            attributes.horizontally !== undefined
          );
        };

        // Stores the provided value at the appropriate location.
        const storeValue = (value) => {
          if (value) {
            if (attributes.autoScaleId && !isPreview) {
              // This is a feed based scale and we're in the receiver.
              $log.debug(
                `${logPrefix} Storing value to SCOPE @ ` +
                `${attributes.autoScaleId}`,
                value
              );

              if (!scope.autoScaleTextData) {
                scope.autoScaleTextData = {};
              }
              scope.autoScaleTextData[attributes.autoScaleId] = value;
              return value;
            } else if (!attributes.autoScaleId && isPreview) {
              // This is a user input scale & we're in the preview.
              $log.debug(`${logPrefix} Storing value to SCOPE CONTENT`, value);
              scope.content['f_' + attributes.autoScale] = value;
              return value;
            }
          }
          return null;
        };

        // Measures and applies the value storing where desired.
        const measureAndApplyValue = (entries) => {
          let val = storeValue(applyValue(measure(entries)));
          return val;
        };

        const hasTextValueChanged = newVal => {
          let storedValue =
            TextUtils.getStoreValue(
              'autoScale--textValues', attributes.autoScaleId
            );
          if (storedValue && newVal === storedValue) {
            return false;
          }
          TextUtils.setStoreValue(
            'autoScale--textValues', attributes.autoScaleId, newVal
          );
          return true;
        };

        const whenAppropriate = (entries, observer, cb) => {
          if (
            controllers[1] && !_.includes(
              ['preshow', 'animate-in', 'show'], controllers[1].state
            )
          ) {
            // The slide is currently hidden so add the CB into preShow and
            // remove once run.
            try {
              controllers[1].preShowPromiseGenerators.push(
                function cbOnceAndRemoveSelf () {
                  $timeout(cb.bind(null, entries, observer))
                    .then(function () {
                      _.pull(
                        controllers[1].preShowPromiseGenerators,
                        cbOnceAndRemoveSelf
                      );
                    })
                    .catch(function (err) {
                      $log.error(err);
                    });
                }
              );
            } catch (error) {
              $log.debug(
                `${logPrefix} whenAppropriate - Couldn't find flareTemplateCtrl`
              );
            }
            return null;
          } else {
            // Slide is visible so perform scaling now.
            return cb(entries, observer);
          }
        };

        // Initialise autoScale
        if (attributes.autoScaleId) {
          // Either nothing to do OR we're not expecting the feed item to be in
          // The DOM. We instead wait for an event to be called.
          let deregister = scope.$on('autoScale--resize', (e, d) => {
            $timeout(() => {
              if (isPreview) {
                whenAppropriate(null, null, measureAndApplyValue);
              } else if (
                !attributes.autoScaleResizeEventFilter ||
                attributes.autoScaleResizeEventFilter === d.filter
              ) {
                // check for value
                let value = checkForValue();
                if (hasTextValueChanged(d.textValue) || !value) {
                  whenAppropriate(null, null, measureAndApplyValue);
                } else {
                  applyValue(value);
                }
              }
            });
          });
          scope.$on('$destroy', deregister);
        } else if (isPreview) {
          // Handling user input
          checkForValueAndApply();
        } else if (!checkForValueAndApply()) {
          // !Preview and we didn't have a value stored.
          whenAppropriate(null, null, measureAndApplyValue);
        }

        // Setup observers if required.
        if (isPreview) {
          // We always want to watch resizes in the preview
          let resizeObserver, mutationObserver, useFallback, fallbackInt;
          if ('ResizeObserver' in window) {
            resizeObserver = new ResizeObserver((entries, observer) => {
              whenAppropriate(entries, observer, measureAndApplyValue);
            });
            // NOTE: We observe the elements first child here as it could shrink
            // possibly not triggering the parent elem to change and therefore
            // result in a missed opportunity to scale.
            resizeObserver.observe(element[0]);
            resizeObserver.observe(element.children()[0]);
            scope.$on('$destroy', () => {
              resizeObserver.disconnect();
            });
          } else {
            useFallback = true;
          }

          if (
            !attributes.autoScaleId &&
            'MutationObserver' in window &&
            !useFallback
          ) {
            mutationObserver = new MutationObserver((entries, observer) => {
              whenAppropriate(entries, observer, measureAndApplyValue);
            });
            mutationObserver.observe(element[0], {
              subtree: true, characterData: true
            });
            mutationObserver.observe(element.children()[0], {
              subtree: true, characterData: true
            });
            scope.$on('$destroy', () => {
              mutationObserver.disconnect();
            });
          } else {
            useFallback = true;
          }

          if (useFallback) {
            if (resizeObserver) {
              resizeObserver.disconnect();
            }
            if (mutationObserver) {
              mutationObserver.disconnect();
            }
            $log.debug(
              logPrefix +
              'DOM observers unavailable, using less performant interval' +
              ' approach.'
            );
            fallbackInt =
              $interval(() => {
                whenAppropriate(null, null, measureAndApplyValue);
              }, FALLBACK_INTERVAL_DURATION_MS, 0, true, null);
            scope.$on('$destroy', () => {
              $interval.cancel(fallbackInt);
            });
          }
        }
      }
    };
  })

  // Measures the elements height & font-size, calculates the number of text
  // lines that can be safely displayed (considering rounding) & applies
  // appropriate CSS styles to the element.
  // NOTE: If your element/element content changes this will not trigger a
  // remeasure.
  .directive('truncateToFit', function ($log, $timeout) {
    return {
      restrict: 'A',
      require: ['?^^flareTemplate'],
      link: function (scope, element, attributes, controllers) {
        const ROUNDING_ALLOWANCE = 0.95;
        var templateCtrl = controllers[0];
        var alreadyTruncated = false;

        function truncateText () {
          if (alreadyTruncated) { return; }
          var computedStyle = window.getComputedStyle(element[0]);
          var parentElem = element.parent();
          var parentStyle = window.getComputedStyle(parentElem[0]);
          var showableLines =
            parseFloat(parentStyle.height) /
            parseFloat(computedStyle.lineHeight);
          // When we measure the height of an element defined by css, it's
          // rounded to the nearest pixel, if we get (e.g.) 0.99 lines allowed
          // we want it to fit.
          showableLines = (showableLines % 1 > ROUNDING_ALLOWANCE)
            ? Math.ceil(showableLines) : Math.floor(showableLines);
          if (showableLines) {
            var newMaxHeight =
              showableLines * parseInt(computedStyle.lineHeight) + 'px';
            element.css({
              '-webkit-line-clamp': showableLines.toString(),
              height: newMaxHeight
            });
            $log.debug(
              'Clamping lines to',
              showableLines,
              'for element',
              element[0],
              'and seting its height to',
              newMaxHeight.toString()
            );
          } else {
            element.css('height', '0px');
          }
          alreadyTruncated = true;
        }

        templateCtrl.postShowFns.push(truncateText);
      }
    };
  })


  // Measures the text container & its parent, if found to be overflowing the
  // directive then removes text content (1 word per iteration) from the
  // container (recursive).
  .directive('truncateText', function ($log, $timeout) {
    return {
      restrict: 'A',
      link: function (scope, element, attributes) {

        function isOverFlowing (cHeight, pHeight) {
          return cHeight > pHeight;
        }

        function truncate () {
          var parentHeight, results, truncated, words;
          truncated = false;
          results = [];

          // Measure the parent.
          parentHeight = (element.parent()[0].getBoundingClientRect().height);
          if (parentHeight === 0) {
            // Parent seemed to have no height, wait a cycle and try again.
            return $timeout(truncate, 0);
          }

          // Split the text content & whilst overflowing pop out words
          words = element[0].innerText.split(' ');
          while (isOverFlowing(
            element[0].getBoundingClientRect().height, parentHeight
          ) && words.length) {
            // Remove a word.
            words.pop();
            truncated = true;
            if (words.length && truncated) {
              results.push(element[0].innerText = words.join(' ') + '...');
            } else {
              element.empty();
              results.push(element.parent().css({ margin: 0, padding: 0 }));
            }
          }
          return results;
        }

        // If a value has been provided to the attribute we will watch it and
        // update truncation when it changes.
        if (attributes.truncateText !== 'truncate-text') {
          scope.$watch(attributes.truncateText, (nVal, oVal) => {
            return $timeout(truncate, 0);
          });
        }

        // Try to truncate initially.
        return $timeout(truncate, 0);
      }
    };
  })

  // Measures the text container & its parent, if found to be overflowing the
  // directive then removes text content (1 word per iteration) from the
  // container (recursive).
  // NOTE: If the parentElem has no height for the initial measure then this
  // directive will have no affect.
  // NOTE: This is a non bocking verison of text-truncate (substituted while
  // loop), this is useful if truncation will be used on lots of elements at
  // once as it allow other lines of code to be run between removing words.
  .directive('nonBlockTruncate', function ($timeout) {
    return {
      restrict: 'A',
      link: function (scope, element, attributes) {

        function isOverFlowing (cHeight, pHeight) {
          return cHeight > pHeight;
        }

        return $timeout(function () {
          var parentHeight, removeWord, truncated, words;
          // Measure the parent.
          parentHeight = (element.parent()[0].getBoundingClientRect().height);
          // Split the text content & whilst overflowing pop out words
          words = element[0].innerText.split(' ');
          truncated = false;
          removeWord = function () {
            if (isOverFlowing(
              element[0].getBoundingClientRect().height, parentHeight
            ) && words.length) {
              // Remove a word.
              words.pop();
              truncated = true;
              if (words.length && truncated) {
                element[0].innerText = words.join(' ') + '...';
              } else {
                element.empty();
                element.parent().css({ margin: 0, padding: 0 });
              }
              return $timeout(removeWord, 0);
            }
          };
          return removeWord();
        }, 0);
      }
    };
  });
