angular.module('pxn-ownership', [])

/**
 * Allows a user to select an owner for a particular resource.
 * Note that the directive needs to be able to tally up cached ResourceItems
 * with plain JavaScript objects received from the server.
 */
.directive('ownerInput', function (Users, Session, SortFunctions) {

  return {
    scope: true,
    require: 'ngModel',
    template: function ownerInputTemplateFunction (tElement, tAttrs) {
      return [
        '<select name="ownership"',
        ' ng-model="$parent.', tAttrs.ngModel, '"',
        ' ng-options="user as user.name',
        ' for user in ownerList track by user._id"',
        ' pxn-requires-permission="account.ownership"',
        ' pxn-restrict-method="disable"',
        ' class="width-100"', tAttrs.required ? ' required' : '',
        ' ng-change="', tAttrs.onChange ? tAttrs.onChange : '', '"',
        '></select>'
      ].join('');
    },

    link: function ownerInputLink (scope, element, attributes, ngModelCtrl) {

      scope.ownerList = Users.all();

      var isSwitchedAccount =
        Session.current.user.account._id !== Session.current.account._id;

      // Remove current user from the owner list
      function removeCurrentUserFromOwnerList () {
        scope.ownerList = _.without(scope.ownerList, Session.current.user);
      }

      // Make sure the current user isn't allowed as an owner if we've switched
      // accounts.
      scope.ownerList.promise.then(function () {
        if (isSwitchedAccount) {
          // We switched account, remove our user from the owner list if it's
          // not already in use.
          if (ngModelCtrl.$viewValue) {
            let ownerId = ngModelCtrl.$viewValue._id;
            let userId = Session.current.user._id;
            // We're not the owners of the resource, we can remove our user from
            // the list
            if (userId !== ownerId) removeCurrentUserFromOwnerList();
          } else {
            // No owner yet, remove our user from the list
            removeCurrentUserFromOwnerList();
          }
        }
        // sort the list.
        scope.ownerList.sort((a, b) => SortFunctions.stringAsc(a.name, b.name));
      });

      // Make sure the model value is set when we get a new viewvalue.
      ngModelCtrl.$parsers.push(function (newViewValue) {
        return newViewValue;
      });


      // This formatter has two main goals:
      //    1. Set the default value (if the model is set to 'null')
      //    2. Add unknown owners to the list of potential owners (e.g. if a
      //       shared item has an owner from another account who won't be
      //       returned by Users.all()).
      ngModelCtrl.$formatters.push(function (newModelVal) {
        // New resource case
        if (newModelVal === null) {
          if (isSwitchedAccount) {
            // Switched account
            return null;
          } else {
            // Haven't switched account so the default owner is 'me'!
            ngModelCtrl.$setViewValue(Session.current.user);
            return Session.current.user;
          }
        }
        // Make sure the selected value is available in the list of owners
        if (typeof newModelVal === 'object') {
          var idx = _.findIndex(
            scope.ownerList,
            { _id: newModelVal._id }
          );
          if (idx === -1) {
            // The user who owns this document is unknown to this account,
            // so we just need to dump him in as an option (he's been populated)
            scope.ownerList = scope.ownerList.concat([newModelVal]);
            // sort the list.
            scope.ownerList.sort((a, b) => SortFunctions.stringAsc(a.name, b.name));
          }
        }
        return newModelVal;
      });
    }
  };
})

/* Jade example

  resource-detail-viewer(
    resource="channel"
    ignored-details="ownedBy, updated"
    additional-details="[{ \"key\": \"path.after.resource\", \"label\": \"Resource name\", \"icon\": \"entypo-vcard\" }]"
    wide-view="true"
  )

*/
.directive('resourceDetailViewer', function ($templateCache, $timeout) {
  return {
    restrict: 'E',
    scope: {
      resource: '=',
      filters: '='
    },
    // See: #1274, When issue fixed remove this controller.
    // controller: function () {
    //   this.$onInit = function () {
    //     $timeout(function () {
    //       debugger;
    //     });
    //   };
    // },
    link: function (scope, element, attributes, controller) {
      // Define default shown details.
      let defaultProps = [
        'name',
        'description',
        'tags',
        'ownedBy',
        'updated'
      ];

      let deregister = [];

      // Check if our version number changes.
      deregister.push(scope.$watch('resource.__v', function (newVal) {
        if (newVal === null || newVal === undefined) return;
        // Check if it's tags has to be selected
        $timeout(addClassToSelectedTags); // Timeout to have redrawn element.
      }));

      // When tag filters change
      deregister.push(scope.$watch('filters.tags', function (newVal) {
        if (newVal === null || newVal === undefined) return;
        let resourceTags = element[0].querySelectorAll('.tag-input--tag');
        _.each(resourceTags, function (tag) {
          // Toggle selected classes on resource tags.
          _.includes(scope.filters.tags, tag.innerText) ?
            tag.classList.add('tag-input--tag__selected') :
            tag.classList.remove('tag-input--tag__selected');
        });
      }));

      // On tag click update filters and apply highlighting classes to them.
      scope.tagClick = function (e, tag) {
        if (!scope.filters) return; // Return early if filters not available.
        let resourceTags = element[0].querySelectorAll('.tag-input--tag');
        let filterTagList = scope.filters.tags;
        if (!_.includes(scope.filters.tags, tag)) {
          scope.filters.tags = filterTagList.concat([tag]);
          // eslint-disable-next-line consistent-return
          _.each(resourceTags, function (t) {
            if (t.innerText === tag) {
              t.classList.add('tag-input--tag__selected');
              return false; // Tags are unique.
            }
          });
        } else {
          scope.filters.tags = _.without(filterTagList, tag);
          // eslint-disable-next-line consistent-return
          _.each(resourceTags, function (t) {
            if (t.innerText === tag) {
              t.classList.remove('tag-input--tag__selected');
              return false; // Tags are unique.
            }
          });
        }
      };
      // debugger;
      // Timeout to have the tags added to DOM.
      // Added 100 ms due to pxnAccordion.
      // When Issue fixed remove 100ms delay but keep $timeout
      // See: #1274
      $timeout(function () {
        // debugger;
        addClassToSelectedTags();
      }, 100);

      /**
       * Adds selected class to filtered tags.
       * @returns {Undefined} - Nothing is returned
       */
      function addClassToSelectedTags () {
        if (!scope.filters) return;
        let resourceTags = element[0].querySelectorAll('.tag-input--tag');
        // eslint-disable-next-line consistent-return
        _.each(resourceTags, (tag, index) => {
          if (_.includes(scope.filters.tags, tag.innerText)) {
            tag.classList.add('tag-input--tag__selected');
            return false; // Tags are unique.
          }
        });
      }

      scope.$on('$destroy', function () {
        _.each(deregister, (fn) => fn());
      });


      // Create an array of details to be shown. (configurable by comma
      // separated attributes)
      var toIgnore = [];
      var toAdd = [];
      var detailsToShow = [];

 /**
       * Splits a string at every `,` and pushes all teh parts to a supplied
       * array.
       *
       * @param {String} attr - Comma seperated list.
       * @param {Array} array - Array to push results to.
       * @returns {Array} - Array containing all the string parts.
       */
      function attrToStringToArray (attr, array = []) {
        _.each(attributes[attr].split(','), function (string) {
          string = string.trim();
          array.push(string);
        });
        return array;
      }

      // If we provided anything to ignore we should remove it from our list.
      if (attributes.ignoredDetails) {
        defaultProps = _.xor(defaultProps, attrToStringToArray('ignoredDetails'));
      }
      // NOTE: We probably don't need to do this anymore but testing was limited
      defaultProps = _.unique(defaultProps);

      // If we provided additional fields we should format the string and use
      let nonStandards = [];
      if (attributes.additionalDetails) {
        scope.nonStandardProps = [];
        let addtionals = JSON.parse(attributes.additionalDetails);
        _.each(addtionals, function (additional) {
          // Check an icon is set, otherwise use the default
          if (!additional.icon) {
            additional.icon = 'entypo-plus';
          }
          scope.nonStandardProps.push(additional);
          nonStandards.push(additional.key);
        });
      }

      let allUsedProps =
        nonStandards.length ? defaultProps.concat(nonStandards) : defaultProps;

      scope.canShow = function canShow (prop) {
        // Should only happen if we have ignoredProps.
        if (!_.contains(allUsedProps, prop)) {
          return false;
        }

        // Split the prop if required.
        let val = null;
        if (_.contains(prop, '.')) {
          val = scope.resource;
          _.each(prop.split('.'), function (string) {
            // If we don't have a resource return (this is called again when set)
            if (!val) {
              return false;
            }
            val = val[string];
          });
        } else {
          val = scope.resource[prop];
        }

        if (val !== null) {
          return true;
        }
        return false;
      };

      scope.getPropertyValue = function (prop) {
        // Split the prop if required.
        let val = null;
        if (_.contains(prop, '.')) {
          val = scope.resource;
          _.each(prop.split('.'), function (string) {
            if (!val) {
              return false;
            }
            val = val[string];
          });
        } else {
          val = scope.resource[prop];
        }
        return val;
      };
    },
    template: function (element, attributes) {
      if (attributes.wideView) {
        return $templateCache.get(
          'ownership/pxn-resource-detail-viewer__wide.jade'
        );
      } else {
        return $templateCache.get(
          'ownership/pxn-resource-detail-viewer.jade'
        );
      }
    },
  };
});
