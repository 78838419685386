angular.module('pixelnebula.ui.buttons', [])

.factory('pxnElementClasses', function pxnElementClasses () {
  return {

    /**
     * Produces a list of BEM style classes for a directive
     * @param {string} block - The css "block" (**B**EM)
     * @param {string} [element] - The css "element" (B**E**M)
     * @param {array} [modifiers] - List of modifier classes to add
     * @param {object} [others] - List of other classes either key:val for
        key-val or key:[val1,val2] for key-val1 key-val2
     * @returns {string} A string of space-separated classes for use in a
        class attribute.
    **/

    getClassList: function getClassList (b, e, modifiers, others) {
      var classList = [];
      var base = b;

      if (e) base += '--' + e;
      base += '__';

      if (!angular.isArray(modifiers) && modifiers != null) {
        modifiers = [modifiers];
      }
      _.each(modifiers, function makeModifierClasses (mod) {
        classList.push(base + mod);
      });

      _.each(others, function makeOtherClasses (value, key) {
        // Ignore interpolated classes
        if (/{{.*}}/.exec(value) || value == null) return;

        var root = key + '-';

        if (angular.isArray(value)) {
          _.each(value, function (item) {
            classList.push(root + item);
          });
        } else {
          classList.push(root + value);
        }
      });
      return classList.join(' ');

    },
  };
})

.component('pxnButton', {

  transclude: true,
  bindings: {
    iconClass: '@',
    background: '@',
  },
  template: ['$element', '$attrs', 'pxnElementClasses',
    function pxnButtonTemplate ($element, $attrs, pxnElementClasses) {

      var classList = pxnElementClasses.getClassList(
          'button', // block
          null, // element
          null, // modifiers (we sort this out later)
          { bg: $attrs.background }
        );

      switch ($attrs.type) {

        case 'icon':
          return [
            '<button type="' + ($attrs.buttonType || 'button') +
              '" class="button button__icon ' + classList +
              '">',
            '<div class="button__icon--container">',
            '<section class="button__icon--icon-container">',
            '<icon class="button__icon--icon ' + $attrs.iconClass + '">',
            '</icon>',
            '</section>',
            '<span class="button--shine"></span>',
            '<content class="button__icon--text ' +
              classList + '" ng-transclude></div>',
            '</content>',
            '</button>',
          ].join('');

        case 'naked icon':
          return [
            '<button type="' + ($attrs.buttonType || 'button') +
            '" class="button__naked-icon ', $attrs.iconClass,
            ' ', classList, ' ng-transclude">',
            '</button>',
          ].join('');

        default:
          return [
            '<button type="' + ($attrs.buttonType || 'button') +
              '" class="button ' + classList + '">',
            '<span class="button--shine"></span>',
            '<div class="button--text" ng-transclude>',
            '</div>',
            '</button>',
          ].join('');

      }
    }],

  controller: function ($element, $timeout, $attrs, $scope, $log, $parse,
                        pxnElementClasses) {
    var disabled;
    var $shine  = $element.find('span');
    var $button = $element.find('button');
    // Button may have been removed.
    if (!$button.length) return;

    // Add a class with an animation when we click - this is better than
    // using an animation on :active as we can ensure the whole thing completes.
    if ($attrs.type !== 'naked icon') {
      $element.on('click', function (e) {
        if (disabled) return;
        $shine.removeClass('clicked');
        $timeout($shine.addClass.bind($shine, 'clicked'));
      });
    }

    // Stop clicks on this button propogating further than this element.
    if (!$attrs.enablePropagation) {
      $element.on('click', function (e) {
        e.stopPropagation();
      });
    }

    function defocus () {
      $button[0].blur();
    }

    var deregisterWatch;

    if ($attrs.ngDisabled) {
      var parentScopeExpression = $attrs.ngDisabled;

      $element.removeAttr('ng-disabled');
      deregisterWatch = $scope.$parent.$watch(parentScopeExpression,
        function (newVal) {
          disabled = newVal;
          if (disabled) $button.attr('disabled', 'disabled');
          else $button.removeAttr('disabled');
        }
      );
    }

    function attributeIsInterpolated (attrName) {
      if (!$attrs.hasOwnProperty(attrName)) return false;
      return !!/{{.*}}/.exec($attrs[attrName]);
    }

    function makeClassArray (newVal) {
      var classes = [];
      if (angular.isArray(newVal)) {
        _.each(newVal, function (item) {
          classes = classes.concat(makeClassArray(item));
        });
        return classes;
      } else if (angular.isObject(newVal)) {
        _.each(newVal, function (v, k) {
          if (v) {
            classes = classes.concat(k.split(' '));
          }
        });
        return classes;
      } else if (angular.isString(newVal)) {
        return newVal.split(' ');
      }

      return newVal;
    }

    /* eslint-disable no-magic-numbers */
    var nonModClasses = $button.attr('class').split(' ');
    var bgClassIdx = _.findIndex(nonModClasses, function findBg (item) {
      return item.substr(0, 3) === 'bg-';
    });
    nonModClasses.push(nonModClasses[bgClassIdx] + '__active');

    /* eslint-enable no-magic-numbers */

    this.$onInit = function () {

      var modAttr = $attrs.modifiers;

      if (modAttr) {
        try {
          // Check if modAttr is a valid expression to watch.
          $scope.$eval(modAttr);
          var unwatch = $scope.$parent.$watch(modAttr, function (newVal) {
            var modClasses;
            if (!newVal) {
              // Not an expression (although valid).
              modClasses =
                pxnElementClasses.getClassList('button', null, modAttr);
              $button.addClass(modClasses);
              unwatch();
              return;
            }
            var classes = makeClassArray(newVal);
            modClasses =
              pxnElementClasses
                .getClassList('button', null, classes)
                .split(' ');
            _.each(modClasses, function (cls) {
              if (!$button.hasClass(cls)) $button.addClass(cls);
            });
            _.each($button.attr('class').split(' '), function (cls) {
              if (!_.contains(modClasses.concat(nonModClasses), cls)) {
                $button.removeClass(cls);
              }
            });
          }, true);
          $scope.$on('$destroy', unwatch);
        } catch (e) {
          // Not a valid expression
          $button.addClass(
            pxnElementClasses.getClassList('button', null, modAttr.split(' '))
          );
        }

      }
      // Account for icons that are interpolations
      if ($attrs.type === 'icon' && attributeIsInterpolated('iconClass')) {
        var $icon = $element.find('icon');
        $icon.addClass(this.iconClass);
      }
      // Account for background colours that are interpolations.
      if (attributeIsInterpolated('background')) {
        $button.addClass('bg-' + this.background);
        var $content = $element.find('content');
        $content.addClass('bg-' + this.background);
      }

      // Add inverse background colour to icon type container.
      if ($attrs.type === 'icon') {
        var $iconContainer = $element.find('section');
        $iconContainer.addClass('bg-' + this.background + '-inverse');
      }

      var activeClass = 'bg-' + this.background + '__active';

      function applyActiveClass (on) {
        if (on) $button.addClass(activeClass);
        else $button.removeClass(activeClass);
      }

      // Deal with a toggle button. (toggle="someProp on someObj")
      if ($attrs.toggle) {
        // split into ['someProp', 'someObj']
        var toggleExpressionParts = $attrs.toggle.split(' on ');
        if (toggleExpressionParts.length <= 1) {
          $log.warn('[Buttons] Invalid toggle expression: ' + $attrs.toggle);
        } else {
          var objExpr = toggleExpressionParts[1];
          var propExpr = toggleExpressionParts[0];
          var obj = $scope.$parent.$eval(objExpr);

          var deregisterParentWatch =
            $scope.$parent.$watch(objExpr + '.' + propExpr, applyActiveClass);
          $scope.$on('$destroy', deregisterParentWatch);


          $element.on('click', function () {
            $scope.$apply(function () {
              _.each(obj, function (val, key) {
                obj[key] = false;
              });
              obj[propExpr] = true;
            });
          });
        }
      } else if ($attrs.isActive) {
        var rmwatch = $scope.$parent.$watch($attrs.isActive, applyActiveClass);
        $scope.$on('$destroy', rmwatch);
      }
    };


    // Clicking or moving the mouse out should remove the focus effect
    $button.on('mouseout', defocus);
    $button.on('click', defocus);

    $scope.$on('$destroy', function () {
      $button.off('mouseout click');
      $element.off('click');
      if (deregisterWatch) deregisterWatch();
    });

  },

})

.directive('pxnCheckbox', function (SnakeCase, pxnElementClasses) {
  return {
    restrict:   'E',
    transclude: true,
    require: { ngModel: 'ngModel' },
    bindToController: true,
    template:   function pxnCheckboxTemplate (elem, attrs) {
      var inputAttrs = _.omit(attrs, function (val, key) {
        return key[0] === '$' || _.contains(['inputId',
          'element',
          'label',
          'ngModel',
          // Requiring a checkbox results in unexpected behaviour.
          'required',
          'ngRequired'
        ], key);
      });

      var boxClassList = pxnElementClasses.getClassList(
        'checkbox',
        'box',
        attrs.modifiers
      );

      var inputAttrString = '';

      _.each(inputAttrs, function (val, key) {
        if (key === 'class') {
          inputAttrString += 'class="checkbox--input ' + val + '" ';
        } else if (key === 'onChange') {
          inputAttrString += 'ng-change="' + val + '" ';
        } else {
          inputAttrString += [SnakeCase(key), '="', val, '" '].join('');
        }

      });

      switch (attrs.type) {

        case 'switch':
          return [
            '<label class="checkbox__switch">',
            '<input type="checkbox" ', inputAttrString, ' ng-model="',
            attrs.ngModel ? attrs.ngModel : '',
            '" class="checkbox--input"/>',
            '<div class="checkbox--switch">',
            '<div class="checkbox--toggle"></div>',
            '</div>',
            '<div class="checkbox--label" ng-transclude></div>',
            '</label>',
          ].join('');

        case 'radio':
          return [
            '<label class="checkbox __icon-blob checkbox--radio">',
            '<input type="radio" ', inputAttrString, ' ng-model="',
            attrs.ngModel ? attrs.ngModel : '',
            '" class="checkbox--input"/>',
            '<div class="checkbox--box checkbox--box__radio"></div>',
            '<div class="checkbox--label" ng-transclude></div>',
            '</label>',
          ].join('');

        default:
          // see ../
          return [
            '<label class="checkbox __icon-' + attrs.icon + '">',
            '<input type="checkbox" ', inputAttrString, ' ng-model="',
            attrs.ngModel ? attrs.ngModel : '',
            '" class="checkbox--input"/>',
            '<div class="checkbox--box ', boxClassList, '"></div>',
            '<div class="checkbox--label" ng-transclude></div>',
            '</label>',
          ].join('');

      }
    },
    controller: function pxnCheckboxController ($scope, $attrs) {

      this.$onInit = ()=> {
        if ($attrs.checked) {
          var ngModelCtrl = this.ngModel;
          ngModelCtrl.$setViewValue($attrs.value == null ? true : $attrs.value);
        }
      };

    }
  };
})

.component('pxnColorPicker', {
  template: `
    <div class="pxn-color-picker"></div>
    <div class="color-picker--value"></div>
  `,
  require: {
    ngModel: 'ngModel',
    pxnMediaInput: '?^^pxnMediaInput'
  },
  controller: function pxnColorPickerCtrlFn (
    $scope, $element, Session, $attrs, $timeout
  ) {
    /* eslint-disable no-magic-numbers */
    this.$onInit = () => {
      let emptyValue = '#00000000';
      let defaultValue = $attrs.default || emptyValue;

      let hexfromRgba = (modelValue) => {
        // We'll just leave null or undefined as they are.
        if (modelValue == null) return modelValue;
        // we will receive an RGBA value for new/updated slides but old slides
        // could still have a hex content value.
        if (_.startsWith(modelValue, '#')) {
          // We have a HEX/HEXA value, so we can just provide it back.
          return modelValue;
        }

        // If we're here then we had and RGBA value.
        // Break the value into its four parts.
        let partsRegex = /rgba\((\d+),(\d+),(\d+),(\d+\.?\d*)\)/;
        let match = modelValue.match(partsRegex);
        // This covers "transparent" as well as any malformed data.
        if (!match) return emptyValue;

        let parts = [match[1], match[2], match[3], match[4]];

        // We now have the RGBA parts ready for conversion to HEXA.
        // Start by converting the first 3.
        // NOTE: We prefix with an extra 0 so e.g. "f" will still output "0f".
        parts[0] = ('0' + parseInt(parts[0]).toString(16)).slice(-2);
        parts[1] = ('0' + parseInt(parts[1]).toString(16)).slice(-2);
        parts[2] = ('0' + parseInt(parts[2]).toString(16)).slice(-2);

        // For the alpha value we will have a value between 0-1, we need to
        // convert this to base 16.
        parts[3] = (
          '0' + parseInt(parseFloat(parts[3]) * 255).toString(16)
        ).slice(-2);

        if (parts[3] === 'ff') {
          // `ff` is full opacity and will cause our "X% transparent" text to
          // be displayed if appended, but a message saying "0% transparent"
          // is a bit odd, so we remove it.
          parts[3] = '';
        }

        return '#' + parts.join('').toUpperCase();
      };

      // In order to make this compatible with Android devices (sadly also
      // Electron until we update from the May 2018 version..) we need to
      // format/parse HEXA values to/from RGBA values.

      this.ngModel.$parsers.push((newViewValue) => {
        // Set the model value to be RGBA using the newViewValue (HEX || HEXA).
        // There are 2 possible scenarios; '#000000' & '#00000000'

        // We will never need to use the '#' so lets remove that first.
        newViewValue = newViewValue.split('#')[1];
        // Add `FF` as an alpha if we only have standard HEX. (FF = opacity 1)
        if (newViewValue.length === 6) {
          newViewValue += 'FF';
        }
        // Split the value into it's RGBA parts.
        let parts = [];
        // The first 3 parts will need to be parsed as base 16 [0-9A-F] to give
        // us a value between 0-255 value.
        parts.push(parseInt(newViewValue.substring(0, 2), 16));
        parts.push(parseInt(newViewValue.substring(2, 4), 16));
        parts.push(parseInt(newViewValue.substring(4, 6), 16));
        // Our 4th part is also base 16 but needs to be converted to a value
        // between 0-1 for use as an opacity value.
        parts.push(parseInt(newViewValue.substring(6), 16) / 255);

        // Now we have 4 parts and can build/return our RGBA value.
        return 'rgba(' + parts.join(',') + ')';
      });

      this.ngModel.$formatters.push(hexfromRgba);

      let swatches = [];
      if (Session.current.account.colours.swatches) {
        swatches =
          Session.current.account.colours.swatches.map(swatch => swatch.value);
      }
      let showPallete = true;
      if (Session.current.account.colours.restrict && !$attrs.alwaysShowPalette) {
        showPallete = false;
      }
      // Initialise a Pickr instance
      this.pickr = Pickr.create({
        el: $element[0].querySelector('.pxn-color-picker'),
        theme: 'nano', // or 'monolith', or 'classic' DFT update the css.
        default: emptyValue,
        swatches: swatches,
        components: {
          // Main components
          palette: showPallete,
          preview: showPallete,
          opacity: showPallete,
          hue: showPallete,

          // Input / output Options
          interaction: {
            input: showPallete,
            clear: !Session.current.account.colours.restrict || Session.current.account.colours.allowTransparent,
          }
        }
      });

      let textElem = $element[0].querySelector('.color-picker--value');
      let $textValueElem = angular.element(
        textElem
      );
      $textValueElem.on('click', () => this.pickr.show());

      // Our input has a text value that we will need to update.
      let updateValue = (nVal, isMediaInputClear, isClearButton) => {
        if (nVal == null) {
          let value = isClearButton ? emptyValue : defaultValue
          this.pickr.setColor(value);
          textElem.setAttribute('data-model-value',
            value === emptyValue ? 'transparent' : value
          );
          this.ngModel.$setViewValue(value);
        } else {
          let stringValue =
            typeof nVal === 'string' ? nVal : nVal.toHEXA().toString();
          this.ngModel.$setViewValue(stringValue);

          if (!isMediaInputClear) {
            this.pickr.setColor(stringValue);
          } else {
            this.pickr.setHSVA(...this.pickr._parseLocalColor(nVal).values);
          }

          if (stringValue.length === 9 && stringValue !== emptyValue) {
            // Semi trans
            textElem.setAttribute(
              'data-model-value',
              stringValue.slice(0, 7) + ' (' +
              (
                Math.round(
                  (
                    1 - (parseInt(stringValue.slice(7), 16) / 255)) * 100
                  )
              ) + '% transparent)'
            );
          } else if (stringValue === emptyValue) {
            // tranny
            textElem.setAttribute('data-model-value', 'transparent');
          } else {
            // We got Hex
            textElem.setAttribute('data-model-value', stringValue);
          }
        }
      };

      let closePickrOnEnterKeydown = (e) => {
        var ENTER = 13;
        if (e.keyCode === ENTER && this.pickr.isOpen()) {
          if (
            e.target.type === 'button' && e.target.className === 'pcr-button'
          ) {
            // For some reason $apply won't work if you're only focused on the
            // button.
            $scope.$digest(() => { this.pickr.hide(); });
          } else {
            // The other known cases are "text" which would represent the
            // input inside the picker AND the swatch buttons, $apply works
            // here and should work for any edge case that wasn't picked up in
            // testing so we default to it.
            $scope.$apply(() => { this.pickr.hide(); });
          }
        }
      };

      // Add custom event handlers
      this.pickr.on('init', (pickrInstance) => {
        // TODO: Pickr has an initialised property we could easily replace this?
        pickrInstance.initialisedAt = Date.now();
        // When the users palette is restricted we want to;
          // New template
            // - Initialise with any provided default colours, transparent is
            // valid regardless of "restrict transparent" option state.
            // - Users will not be able to get back to default values upon
            // selecting a new value from the input (unless the colour is in
            // their palette).
            // - Opening the input with "transarent" as the value WILL update
            // the value as would be expected, when this has happened users with
            // allowTransparent enabled will not be able to restore the state.
          // Existing slide
            // - Init with current colours, transparent is valid regardless of
            // "restrict transparent" option state.
            // - Users will not be able to get back to default values upon
            // selecting a new value from the input (unless the colour is in
            // their palette).
            // - Opening the input with "transarent" as the value WILL update
            // the value as would be expected, when this has happened users with
            // allowTransparent enabled will not be able to restore the state.
        // When the users palette isn't restricted we want to;
          // - Initialise with any provided default colours/saved colours,
          // transparent is valid.
        updateValue(this.ngModel.$viewValue, true);
      }).on('show', (pickrInstance) => {
        if (pickrInstance.getColor().toHEXA().toString() === emptyValue) {
          pickrInstance.setColor('#000');
        }
        window.addEventListener('keydown', closePickrOnEnterKeydown);
      }).on('hide', () => {
        window.removeEventListener('keydown', closePickrOnEnterKeydown);
      }).on('clear', (pickrInstance, a) => {
        updateValue(null, false, true);
      }).on('change', (nVal, pickrInstance) => {
        if (pickrInstance.initialisedAt) {
          // If the values are the same then we have only changed the hue, if we
          // update the value here we would lose the new hue value.
          if (nVal.toHEXA().toString() !== this.ngModel.$viewValue) {
            updateValue(nVal);
          }
        }
      });

      if (this.pxnMediaInput) {
        $scope.$on('media-input-reset', (e, nVal) => {
          updateValue(emptyValue, true);
        });
      }

      // Updates the colour of the picker if the provided field name matches
      // the model name (contains).
      $scope.$on('pxnColorPicker:updateColour', (e, fieldKey, nVal) => {
        if (this.ngModel.$name.contains(fieldKey)) {
          updateValue(hexfromRgba(nVal), false);
        }
      });

      // Update string value to display after switching locales
      $scope.$on('update-color-picker-string-value', (e) => {
        $timeout(() => {
          updateValue(this.ngModel.$viewValue, false);
        });
      });

      $scope.$on('$destroy', () => {
        $textValueElem.off('click');
        this.pickr.destroyAndRemove();
      });
    };
    /* eslint-enable no-magic-numbers */
  }
})

/**
 * Labelled input component
 *
 * Creates a labelled input which optionally accepts an element name via the
 * `element` to determine which input element to use.
 *
 */
.directive('pxnLabelledInput', function (SnakeCase, IsMobile) {

  return {
    restrict: 'E',
    scope: true,
    controller: function ($scope, $attrs, $parse, $element) {

      var parsedClearFn = $parse($attrs.onClear);
      $scope.btnCtrl = {
        onClear: function () {
          parsedClearFn($scope.$parent);
          var $input = $element.find($attrs.element);
          $input[0].focus();
          var ngModelCtrl = $input.controller('ngModel');
          if ($scope.contentField) {
            ngModelCtrl.$setViewValue($scope.contentField.getClearValue());
          } else {
            ngModelCtrl.$setViewValue($attrs.onClearValue || '');
          }
          ngModelCtrl.$render();
        },
        viewPassword: function () {
          var $input = $element.find($attrs.element);
          if ($input[0].type === 'password') {
            $input[0].type = 'text';
          } else {
            $input[0].type = 'password';
          }
        }
      };
    },
    // eslint-disable-next-line complexity
    template: function pxnInputTemplate (elem, attrs) {

      attrs.element = attrs.element || 'input';
      var inputAttrs = _.omit(attrs, function (val, key) {
        return key[0] === '$' || _.contains([
          'class',
          'inputId',
          'element',
          'label',
          'model',
          'onChange',
          'placeholder',
          'maxLength',
          'pxnInvalidityCallout'
        ], key);
      });

      var inputAttrString = '';

      _.each(inputAttrs, function (val, key) {
        inputAttrString += [SnakeCase(key), '="', val, '" '].join('');
      });
      return [
        '<span class="labelled-input">',
        '<', attrs.element, ' ', inputAttrString,
        attrs.inputId ? `id="${attrs.inputId}"` : '',
        (attrs.noInvalidity || attrs.pxnInvalidityCallout) ?
          '' : 'pxn-invalidity-callout ',
        'ng-model="', attrs.model ? attrs.model : '', '" ',
        attrs.onChange ? ' ng-change="' + attrs.onChange + '" ' : '',
        'ng-class="{\'filled\': ', attrs.model ? attrs.model : '',
        '.length}" ', 'class="labelled-input--', attrs.element, '"',
        attrs.maxLength ? ' maxlength="' + attrs.maxLength + '"' : '',
        attrs.ngRequired ? ' ng-required="' + attrs.ngRequired + '"' : '',
        attrs.type === 'number' ? ' min="0"' : '',
        // This attribute prevents lastpass populating the input.
        (attrs.type === 'password' || attrs.allowLastPass) ?
          '' :
          'data-lpignore="true"',
        '>',
        '</', attrs.element, '>',
        '<label for="', attrs.inputId, '" class="labelled-input--label' +
        (!attrs.label ? ' labelled-input--label__empty' : '') + '">',
        '<span data-label="', attrs.label,
        '" data-ph="', attrs.placeholder,
        '" class="labelled-input--label-content"></span>',
        '</label>',
        '<span class="labelled-input--clear-button ',
        'labelled-input--clear-button__' + attrs.element + ' ',
        attrs.revert ? ' entypo-ccw"' : 'entypo-cancel"',
        'ng-if="' + !attrs.readonly + ' && ' + attrs.model + '.length && ' +
        (attrs.type !== 'password') + '" ',
        attrs.model ?
        'ng-click="btnCtrl.onClear();' :
        '', '"></span>',


        (attrs.type === 'password') && (attrs.togglePreview || IsMobile) ?
        '<span class="labelled-input--clear-button ' +
        'labelled-input--clear-button__' + attrs.element +
        ' entypo-eye" ng-click="btnCtrl.viewPassword();" ng-if="' +
        attrs.model + '.length"></span>' :
        '',
        '</span>',
      ].join('');
    },
    require: '?ngModel',
    link: function (scope, element, attrs, ngModelCtrl) {
      if (!ngModelCtrl || !attrs.validatorFns) {
        return;
      }
      var validators = scope.$eval(attrs.validatorFns);
      if (angular.isArray(validators)) {
        // Array of Fns
        _.each(validators, function (func, index) {
          ngModelCtrl.$validators[func.name] = func;
        });
      } else {
        // Singular Fn
        ngModelCtrl.$validators[validators.name] = validators;
      }
    }
  };

})

.component('pxnSortCoOrdinator', {

  bindings: {
    sortObj: '<sortObject'
  },

  controller: function ($scope, $attrs, $timeout, $log) {

    var $ctrl = this;

    var sortCtrls = [];

    $ctrl.$onInit = function sortCoOrdinatorInit () {
      if (!$ctrl.sortObj) {
        $log.warn('[pxnSortCoOrdinator] Invalid sort object expression');
      }
      if ($attrs.sortInit) {
        _.assign($ctrl.sortObj, $scope.$eval($attrs.sortInit));
      }
      $timeout($ctrl.setSort);
    };

    $ctrl.registerSortCtrl = function (sortCtrl) {
      sortCtrls.push(sortCtrl);
    };

    $ctrl.setSort = function (sortProp) {
      if (sortProp) {
        if ($ctrl.sortObj.property === sortProp) {
          $ctrl.sortObj.ascending = !$ctrl.sortObj.ascending;
        } else {
          $ctrl.sortObj.property = sortProp;
          $ctrl.sortObj.ascending = true;
        }
      }
      _.invoke(sortCtrls, 'set', $ctrl.sortObj);
    };
  }
})

.component('pxnSortableHeading',
  {

    transclude: true,

    templateUrl: 'utility/inputs/pxn-sortable-heading.jade',

    require: {
      coOrdinator: '^^pxnSortCoOrdinator'
    },

    controller: function ($scope, $element, $attrs) {
      var $ctrl = this;
      var sortProp = $attrs.sortProp;

      $ctrl.$onInit = function sortInit () {
        $ctrl.coOrdinator.registerSortCtrl(this);
      };

      $ctrl.set = function set (newVal) {
        $ctrl.isSorter = newVal.property === sortProp;
        $ctrl.ascending = newVal.ascending;
      };

      $element.on('click', function () {
        $scope.$apply(function () {
          $ctrl.coOrdinator.setSort(sortProp);
        });
      });

    }

  }
)

.directive('pxnSplitInput', function (SnakeCase, $log, $compile, $timeout) {
  return {
    restrict: 'E',
    require: '?^ngModel',
    compile: function (element, attributes) {
      /* eslint-disable max-len */
      var baseTemplate = [
        '<div class="input-container">',
        '<div class="singleInput" ng-show="singleInput" ng-required="singleInput && ' + attributes.required + '"></div>',
        '<div class="landscapeInput" ng-show="!singleInput" ng-required="!singleInput && ' + attributes.required + '"></div>',
        '<div class="portraitInput" ng-show="!singleInput" ng-required="!singleInput && ' + attributes.required + '"></div>',
        '</div>',
        '<span ng-class="{\'entypo-lock\': singleInput, \'entypo-lock-open\': !singleInput}" class="input-splitter" ng-click="toggleSplitInput()" ng-if="enableSplit">',
        '</span>',
      ].join('');

      /* eslint-enable max-len */
      var $template = angular.element(baseTemplate);
      // Clone the transcluded input to add the split versions to the template
      var $transcluded = element.children();
      var $cPortrait   = angular.copy($transcluded);
      var $cLandscape  = angular.copy($transcluded);
      // Change the ng-models based on the expression on the transcluded element
      var regex = /anyOrientation/g;
      var ngModelStr = $cPortrait.attr('ng-model');
      $cPortrait.attr('ng-model', ngModelStr.replace(regex, 'portrait'));
      $cLandscape.attr('ng-model', ngModelStr.replace(regex, 'landscape'));
      // Set the name so we can distinguish between portrait and landscape
      // inputs for validating portrait/landscape previews.
      var nameStr = $cPortrait.attr('field-name');
      $cPortrait.attr('field-name', nameStr.replace('pl', 'p'));
      $cLandscape.attr('field-name', nameStr.replace('pl', 'l'));
      // copy the value (locale original value)
      var valueStr = $cPortrait.children().attr('value');
      $cPortrait.children()
        .attr('value', valueStr.replace(regex, 'portrait'));
      $cLandscape.children()
        .attr('value', valueStr.replace(regex, 'landscape'));

      // // We may have provided a clear value for the input, we need to make sure
      // // to look for orientation specific values here.
      var ocvString = $cPortrait.attr('on-clear-value');
      if (ocvString !== undefined) {
        $transcluded.attr('on-clear-value', ocvString.replace(regex, 'landscape'));
        $cPortrait.attr('on-clear-value', ocvString.replace(regex, 'portrait'));
        $cLandscape.attr('on-clear-value', ocvString.replace(regex, 'landscape'));
      }

      // Insert the copied elements in to the appropriate place in the template
      var htmlElement = $template[0];
      var appends = [
        { selector: '.singleInput', $elem: $transcluded },
        { selector: '.landscapeInput', $elem: $cLandscape },
        { selector: '.portraitInput', $elem: $cPortrait },
      ];
      function appendInput (append) {
        var $parent =
          angular.element(htmlElement.querySelector(append.selector));
        var $pxnContentField =  append.$elem;
        $pxnContentField.attr('ng-required', $parent.attr('ng-required'));

        $parent.append($pxnContentField);
      }
      _.each(appends, appendInput);
      // Replace the contents of the element with the template we've just built
      element.empty();
      element.append($template);
    },
    controller: function ($scope, $element, $attrs, $rootScope) {
      let logPrefix = '[pxnSplitInput] Controller - ';
      let watchExpression;
      if ($scope.$eval('vm.slide.orientations')) {
        watchExpression = 'vm.slide.orientations';
      } else if ($scope.$eval('takeoverTemplate.slide.orientations')) {
        watchExpression = 'takeoverTemplate.slide.orientations';
      }
      var deregisterOrientationWatch =
        $scope.$watch(watchExpression, function (newVal, oldVal) {
          if (!newVal || newVal === oldVal) { return; }
          var $padlock =
            angular.element($element[0].querySelector('.input-splitter'));
          if (!newVal.landscape || !newVal.portrait) {
            $scope.singleInput = true;
            $scope.$eval(
              $attrs.onSplitStateChange,
              { isSplit: !$scope.singleInput }
            );
            $padlock.addClass('input-splitter__hidden');
          } else {
            $padlock.removeClass('input-splitter__hidden');
          }
        }, true);

      $scope.$on('destroy', deregisterOrientationWatch);

      // By default inputs will not be split.
      $scope.singleInput = true;

      // Define placeholder variables.
      var $landscapeInput;
      var $landscapeInputLabel;
      var $portraitInput;
      var $portraitInputLabel;
      var $singleInput;
      var landscapeModel;
      var portraitModel;
      var singleModel;
      var defaultValue = $scope.$eval($attrs.defaultValue);
      // This function will assess the current state of the input and toggle
      // between single and split inputs on click
      $scope.toggleSplitInput = function toggleSplitInput () {
        $rootScope.$broadcast('split-input__toggle');
        $scope.singleInput = !$scope.singleInput;
        $scope.$eval(
          $attrs.onSplitStateChange,
          { isSplit: !$scope.singleInput }
        );
      };

      function initialiseInputs () {
        if (!$singleInput) return;
        // Set the initial state - if either portrait or landscape already have
        // values in them then this input should be split.
        var portraitModelValue = $scope.$eval(portraitModel);
        var landscapeModelValue = $scope.$eval(landscapeModel);
        if (_.isEqual(portraitModelValue, landscapeModelValue)) {
          // Values are the same
          var singleInputModel = $singleInput.controller('ngModel');
          // Model not available
          if (!singleInputModel) return;

          // Only initialise to port/landscape value if undefined. There are
          // cases (inputs in accordions) where we might remove an input then
          // re-init having only edited the merged field, we want to keep that
          // state.
          if (singleInputModel.$modelValue === undefined) {
            singleInputModel.$setViewValue(landscapeModelValue);
          }
          $scope.singleInput = true;
        } else if (
          typeof landscapeModelValue === 'object' &&
          landscapeModelValue.hasOwnProperty('_id') &&
          landscapeModelValue._id === portraitModelValue._id
        ) {
          // Values are objects with the same _id property
          $singleInput.controller('ngModel').$setViewValue(landscapeModelValue);
          $scope.singleInput = true;
        } else {
          // Values did not match
          $scope.singleInput = false;
        }
        $scope.$eval(
          $attrs.onSplitStateChange,
          { isSplit: !$scope.singleInput, ignoreDirty: true }
        );
        // Now after data setup consider slide orientations.
        if (
          !_.get($scope, `${$attrs.slideExpression}.orientations.landscape`) ||
          !_.get($scope, `${$attrs.slideExpression}.orientations.portrait`)
        ) {
          $scope.singleInput = true;
          $scope.$eval(
            $attrs.onSplitStateChange,
            { isSplit: !$scope.singleInput, ignoreDirty: true }
          );
          var $padlock =
            angular.element($element[0].querySelector('.input-splitter'));
          $padlock.addClass('input-splitter__hidden');
        }
        setDefaults();
      }

      $scope.$on('split-input::re-initialise-inputs', initialiseInputs);

      function setDefaults () {
        if (defaultValue) {
          if ($scope.$eval(portraitModel) == null) {
            $portraitInput.controller('ngModel').$setViewValue(
              _.cloneDeep(defaultValue)
            );
          }
          if ($scope.$eval(landscapeModel) == null) {
            $landscapeInput.controller('ngModel').$setViewValue(
              _.cloneDeep(defaultValue)
            );
          }
          if ($scope.$eval(singleModel) == null) {
            $singleInput.controller('ngModel').$setViewValue(
              _.cloneDeep(defaultValue)
            );
          }
          $scope.$broadcast('setContentFieldDefault', _.cloneDeep(
            defaultValue)
          );
        }
      }

      function initSplitInput () {
        // Get references to the 3 inputs as angular elemenets.
        var find = $element[0].querySelector.bind($element[0]);
        $singleInput = angular.element(find('.singleInput'))
          .children().eq(0);
        var $padlock = angular.element(find('.input-splitter'));
        var isMediaInput = !!angular.element(find('pxn-media-input')).length;
        if (isMediaInput) {
          $padlock.addClass('input-splitter__media');
        }

        // Get a ref to our landscape input label.
        $landscapeInput = angular.element(find('.landscapeInput'))
          .children().eq(0);
        $landscapeInputLabel = angular.element(
          $landscapeInput[0].querySelector('.input-label')
        );
        // If we haven't found a element then we should check for less common
        // inputs such as pxn-checkboxes.
        if (!$landscapeInputLabel.length) {
          $landscapeInputLabel = angular.element(
            $landscapeInput[0].querySelector('.checkbox--label')
          );
        }
        // pxn-labelled-inputs.
        if (!$landscapeInputLabel.length) {
          $landscapeInputLabel = angular.element(
            $landscapeInput[0].querySelector('.labelled-input--label-content')
          );
        }

        // By now we should have an elem ref, if we haven't lets debug log to
        // help the developer resolve the problem.
        if (!$landscapeInputLabel.length) {
          $log.debug(logPrefix + 'Unable to get elem ref for input label.');
        }

        // There are currently two ways in which labels are created, basic text
        // labels & fancy data-attributes displayed using ::after. We need to
        // check the used method and update accordingly. Let's start with the
        // very basic text approach.
        if ($landscapeInputLabel[0].innerText.length) {
          // The label has a text value to append our orientation indicator.
          $landscapeInputLabel[0].innerText += ' (landscape)';
        } else if ($landscapeInputLabel[0].attributes.length) { // Data-attribute approach
          // We have attributes, loop over to find correct value.
          _.each($landscapeInputLabel[0].attributes, (attr, index) => {
            if (attr.name === 'data-label') {
              // We have found the correct attr, update the value to include
              // an orientation indicator.
              $landscapeInputLabel[0].attributes[index].value += ' (landscape)';
            }
          });
        }

        // Get a ref to our portrait input label.
        $portraitInput = angular.element(find('.portraitInput'))
          .children().eq(0);
        $portraitInputLabel = angular.element(
          $portraitInput[0].querySelector('.input-label')
        );
        // If we haven't found a element then we should check for less common
        // inputs such as pxn-checkboxes.
        if (!$portraitInputLabel.length) {
          $portraitInputLabel = angular.element(
            $portraitInput[0].querySelector('.checkbox--label')
          );
        }
        // pxn-labelled-inputs.
        if (!$portraitInputLabel.length) {
          $portraitInputLabel = angular.element(
            $portraitInput[0].querySelector('.labelled-input--label-content')
          );
        }

        // By now we should have an elem ref, if we haven't lets debug log to
        // help the developer resolve the problem.
        if (!$portraitInputLabel.length) {
          $log.debug(logPrefix + 'Unable to get elem ref for input label.');
        }

        // There are currently two ways in which labels are created, basic text
        // labels & fancy data-attributes displayed using ::after. We need to
        // check the used method and update accordingly. Let's start with the
        // very basic text approach.
        if ($portraitInputLabel[0].innerText.length) {
          // The label has a text value to append our orientation indicator.
          $portraitInputLabel[0].innerText += ' (portrait)';
        } else if ($portraitInputLabel[0].attributes.length) { // Data-attribute approach
          // We have attributes, loop over to find correct value.
          _.each($portraitInputLabel[0].attributes, (attr, index) => {
            if (attr.name === 'data-label') {
              // We have found the correct attr, update the value to include
              // an orientation indicator.
              $portraitInputLabel[0].attributes[index].value += ' (portrait)';
            }
          });
        }

        singleModel = $singleInput.attr('ng-model');
        landscapeModel = $landscapeInput.attr('ng-model');
        portraitModel = $portraitInput.attr('ng-model');
        initialiseInputs();
      }

      $timeout(initSplitInput, 0);

      $scope.enableSplit = true;

    }
  };
})

.directive(
  'pxnTableInput',
  function ($templateCache, $compile, $rootScope, $q, $log) {
    return {
      restrict: 'E',
      require: ['pxnTableInput', '?^ngModel'],
      controller: function ($scope) {
        let logPrefix = '[pxnTableInput] - ';

        $scope.removeItemFromArray = (pathOfArray, pathOfItemToRemove) => {
          let filterArray = $scope.$eval(pathOfArray);
          let item = $scope.$eval(pathOfItemToRemove);
          // We need both an item and a filter array in order to continue.
          if (filterArray === undefined || item === undefined) {
            $log.warn(
              logPrefix + 'removeItemFromArray: someting was undefined :('
            );
            return;
          }
          // NOTE: remove mutates teh array and that is important, we cannot
          // re-assign here as it will not be reflected on the template.
          _.remove(filterArray, function (fItem) { return fItem === item; });
          $scope.dirtyModel();
        };

        $scope.dirtyModel = () => {
          if (this.ngModelCtrl) {
            this.ngModelCtrl.$setDirty();
          }
        };

      },
      link: function (scope, elem, attrs, controllers) {
        let ownCtrl = controllers[0];
        let ngModelCtrl = controllers[1];
        ownCtrl.ngModelCtrl = ngModelCtrl;
        let logPrefix = '[pxnTableInput] - ';
        scope.label = attrs.label ? attrs.label : false;
        scope.key = attrs.key;
        scope.columns = attrs.columns ? JSON.parse(attrs.columns) : {};

        /**
         * Creates/initialises rows for the table input.
         *
         * @returns {Undefined} - Nothing is returned
         */
        function initRows () {
          scope.rows = JSON.parse(attrs.rows);
          // This is our content:
          scope.rows.rows = scope.contentField.model;
          if (scope.rows.rows === undefined) {
            scope.rows.rows = scope.contentField.model = [];
          }
          if (scope.rows.default >= scope.rows.rows.length && scope.addDefaultRow) {
            for (var i = scope.rows.rows.length; i < scope.rows.default; i++) {
              scope.addDefaultRow(i);
            }
          }
        }

        // Before the locale is changed, we need to clear `rows.rows` to ensure
        // that media inputs and colour pickers within rows are destroyed first,
        // as the model is bound to the locale, and the colour picker is set up
        // such that it will not leave an empty/undefined value alone, but will
        // set the default. This results in rows being created in the new locale
        // as the ngModel binding updates before ng-repeat re-runs.
        scope.$on('prepare-for-locale-change', function () {
          scope.rows.rows = [];
        });

        // Afer the locale has been changed we need to re-run the init logic.
        scope.$on('locale-change', initRows);

        // Ensure col keys have been set
        _.each(scope.columns, function (col) {
          if (!col.key) {
            col.key = col.name.replace(/\s+/g, '-').toLowerCase();
          }
        });

        // Create/initialise rows
        initRows();

        // Initialise the GUID counter by finding the highest existing.
        let guidCounter = 0;
        _.each(scope.rows.rows, function (row) {
          if (!row.guid) return;
          let guid = parseInt(row.guid.split('-').pop());
          if (guid >= guidCounter) guidCounter = guid + 1;
        });

        // Assigns a GUID to the provided row.
        function assignGuid (row) {
          row.guid = scope.key + '-' + ++guidCounter;
        }

        // Ensure any existing rows have/are assigned a guid
        _.each(scope.rows.rows, (row) => {
          if (!row.guid) {
            assignGuid(row);
          }
        });

        // Broadcasts an event on the rootscope that can be picked up by
        // templates when a row is added or removed. This allows templates to
        // perform aditional init / cleanup tasks as required.
        // The emitted event name is always `{tableKey}-row-{added|removed}`.
        function emitRowEvent (row, removed) {
          $rootScope.$broadcast(
            scope.key + '-row-' + (removed ? 'removed' : 'added'),
            row
          );
        }

        scope.addRow = function addRow (heading, description, divider) {
          // Check if we can add another "row".
          if (!scope.rows.max) {
            scope.rows.max = Infinity;
          }
          if (scope.rows.rows.length >= scope.rows.max) {
            return;
          }

          var row = {};
          // Populate row with the required default data.
          if (heading) {
            row.heading = true;
          } else if (description) {
            row.description = true;
          } else if (divider) {
            row.divider = true;
          } else {
            // Populate row defaults if they exist.
            _.each(scope.columns, function (col, index) {
              if (col.default != null) {
                row[col.key] = angular.copy(col.default);
              }
            });
          }

          assignGuid(row);

          scope.rows.rows.push(row);
          ngModelCtrl.$setDirty(true);

          emitRowEvent(row, false);
        };

        scope.addDefaultRow = function addDefaultRow (index) {
          var row = {};
          // Populate row with the required default data.
          var defaultVals =
            (scope.contentField.fieldOptions.default &&
              (scope.contentField.fieldOptions.default[index] ||
                scope.contentField.fieldOptions.default[0])) ||
            {};
          // Populate row defaults if they exist.
          _.each(scope.columns, function (col, i) {
            if (defaultVals[col.key] != null) {
              row[col.key] = _.cloneDeep(defaultVals[col.key]);
            }
          });

          assignGuid(row);

          scope.rows.rows.push(row);

          emitRowEvent(row, false);
        };

        scope.removeRow = function removeLastRow (index) {
          emitRowEvent(scope.rows.rows[index], true);
          scope.rows.rows.splice(index, 1);
          ngModelCtrl.$setDirty(true);
        };

        // Create default rows
        if (scope.rows.default >= scope.rows.rows.length) {
          for (var i = scope.rows.rows.length; i < scope.rows.default; i++) {
            scope.addDefaultRow(i);
          }
        }

        scope.onDrop = function onDrop (dragItem) {
          // Return the drag item and dnd-list will magically add it for us.
          _.pullAt(scope.rows.rows, _.findIndex(scope.rows.rows, dragItem));
          ngModelCtrl.$setDirty(true);
          return dragItem;
        };

        scope.resetImagePosition =
          function resetImagePosition (rowIndex, colKey, position) {
            scope.vm.slideEditor.$setDirty();
            $rootScope.$broadcast(
              'update-image-background-' + scope.key +
              '[' + rowIndex + '].' + colKey, position
            );
          };

        /**
         * Takes a templateString and creates an HTMLelement/DocumentFragment.
         *
         * @param {String} templateString templateString must evaluate to a
         * single HTMLElement (i.e. `<container>...</container>` NOT
         * `<span>...</span><span>...</span>`)
         * @returns {HTMLElement|DocumentFragment} An element generated from the
         * provided templateString.
         */
        function getElementFromCacheString (templateString) {
          // The error code for createContextualFragment being unsupported is 9.
          var UNSUPPORTED = 9;
          var templateElem;
          // Parse the template element from the cached string.
          try {
            // This will run on modern browsers.
            templateElem = document
              .createRange()
              .createContextualFragment(templateString);
          } catch (e) {
            if (e.code === UNSUPPORTED) {
              // This will run on ancient/rubbish browsers.
              var tempContainer = document.createElement('span');
              tempContainer.innerHTML = templateString;
              templateElem = tempContainer;
              if (templateElem.children.length > 1) {
                $log.warn(
                  logPrefix + 'getElementFromCacheString: Template string ' +
                  'must evaluate as a single element, wrapping inside a span' +
                  ', this could cause layout issues...'
                );
              } else {
                templateElem = templateElem.firstChild;
              }
            } else {
              $log.warn(logPrefix + 'Error parsing cached template string');
            }
          }
          return templateElem;
        }

        // Get the main template and create an element from it.
        let tableInputTemplate =
          $templateCache.get('utility/inputs/pxn-table-input.jade');
        let tableInput = getElementFromCacheString(tableInputTemplate);

        // Get the appropriate item template
        // NOTE: This will NOT be in the cache if the user has supplied it via
        // assets, instead it will be added when the template is loaded.
        let cacheReadyDeferred = $q.defer();
        // If we have provided an itemUrl and it's already in the cache then
        // the template must have previously been loaded, so no need to wait
        if (scope.rows.itemUrl && !$templateCache.get(scope.rows.itemUrl)) {
          let dereg = $rootScope.$on('templateLoaded', () => {
            cacheReadyDeferred.resolve();
          });
          scope.$on('$destroy', dereg);
        } else {
          cacheReadyDeferred.resolve();
        }

        cacheReadyDeferred.promise.then(() => {
          // Our item IS in the cache.
          let itemElem = getElementFromCacheString(
            $templateCache.get(scope.rows.itemUrl) ||
            $templateCache.get('utility/inputs/pxn-table-input.jade')
          );
          // Find the item container and add our item template.
          let itemContainer = tableInput.querySelector('.table--column');
          let $itemContainer = angular.element(itemContainer);

          // If we are using a custom template update the row CSS and force to
          // always be full width, this ensures the developer always has all the
          // available space to use in their template.
          if (scope.rows.itemUrl) {
            $itemContainer.css({ flex: '1 1 auto' });
          }

          $itemContainer.append(itemElem);

          elem.html('');
          elem.append($compile(tableInput)(scope));
        });
      },
    };
  }
)
.directive('disabledReason', function () {
  return {
    restrict: 'A',
    transclude: true,
    template: function (tElement, tAttrs) {
      const DISABLED_REASON_TOOLTIP_DELAY = 300;
      const position = tAttrs.disabledTooltipPosition;
      return `
        <div
          ng-transclude
          pxn-tooltip="${tAttrs.disabledReason}"
          tooltip-position=${position || "'{bottom: 0}'"}
          show-delay-ms=${tAttrs.showDelayMs || DISABLED_REASON_TOOLTIP_DELAY}
          watch-for-change="true"
          pxn-disabled-reason="true">
        </div>
      `;
    }
  };
});
