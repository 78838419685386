angular.module("flare.device-stats", [])
  .factory('DeviceStats', function ($http, ConfigURLs) {
    let deviceStatsLoadPromise = null;

    /**
     * Makes an api call for the requested device statistics.
     *
     * @param {object} opts Options to refine the query
     *   - start       : start of the range
     *   - end         : end of the range
     * @returns {object} Returns a promise that will be resolved with the
     * requested device statistics
     */
    function getDeviceStats(opts) {
      if (!deviceStatsLoadPromise) {
        queryString = '';
        if (opts.start || opts.end) {
          queryString = `?start=${opts.start}&end=${opts.end}`
        }
        deviceStatsLoadPromise = $http.get(
          ConfigURLs.getDeviceStats() + queryString
        )
        .then((res) => {
          return res.data;
        })
        .finally(() => {
          deviceStatsLoadPromise = null;
        });
      }
      return deviceStatsLoadPromise;
    }

    return {
      getDeviceStats: getDeviceStats
    }
  })

  .controller("DeviceStatsController", function (
    $scope, $window, Breakpoints, DeviceStats, DisabledMessage, HelpText,
    Session
  ) {
    vm = this;

    vm.isSuper = Session.hasPermission('system');
    // Events added to this array will only be visible for system admins.
    const SUPER_EVENTS = ['connect_touch'];

    vm.deviceStats = [];
    vm.aggregatedDeviceStats = [];

    vm.isLoading = true;
    vm.disabledMessage = '';

    // NOTE: `vm.timezone` defaults to the browser's timezone which then
    // controlled by the timezone select, while `vm.timezoneToParse` is used to
    // display times on the device stats page either in the selected or the
    // users timezone.
    vm.timezone = moment.tz.guess(true);
    vm.timezoneToParse = vm.timezone;

    vm.rangeStart = moment()
      .tz(vm.timezoneToParse)
      .startOf('day')
      .valueOf();
    vm.rangeEnd = moment(vm.rangeStart)
      .tz(vm.timezoneToParse)
      .endOf('day')
      .valueOf();

    /**
     * Sets the timezone for parsing dates on the page. Either the selected or
     * the user's local timezone.
     *
     * @returns {void}
     */
    vm.setTimezoneToParse = () => {
      vm.timezoneToParse =
        vm.useLocalTime ? moment.tz.guess(true) : vm.timezone;
    }

    /**
     * Returns descriptions for the event codes.
     *
     * @returns {string}
     */
    vm.getEventMessage = (e) => {
      const [main, type] = e.split('_');
      switch (main) {
        case 'connect':
          return `${type ? 'Reconnected' : 'Connected'} to server`;
        case 'disconnect':
          return 'Disconnected from server';
        case 'reboot':
          return 'Device rebooted';

        default:
          return 'Unexpected event occurred';
      }
    }

    /**
     * Toggles a flag to show or hide details for individual devices.
     *
     * @param {object} device Device to toggle the flag for
     * @returns {void}
     */
    vm.toggleDeviceDetails = (device) => {
      device._showDetails = !device._showDetails;
    }

    /**
     * Aggregates device statistics to display all data for the requested range.
     * The level of aggregation is based on the current breakpoint.
     *
     * @param {object} deviceStats The received device stats object
     * @returns {void}
     */
    vm.aggregateStats = (deviceStats) => {
      if (!deviceStats.length) return;

      // Clone to not mutate original object
      deviceStats = _.cloneDeep(deviceStats);

      vm.aggregatedDeviceStats = [];

      // Number of received periods
      let currentPeriods = deviceStats[0].periods.length;
      // Number of maximum periods can be displayed
      let maxPeriodsForBreakpoint = getMaxPeriodsForBreakpoint();
      // How many periods needs to be combined into one to display all periods
      // received
      let aggregationLevel = currentPeriods / maxPeriodsForBreakpoint;

      let stats = [];
      // Make every stat to have the maximum displayed periods
      _.each(deviceStats, (device) => {
        let combinedPeriodsForDevice = [];
        let toCombine = [];

        _.each(device.periods, (period) => {
          // Push periods into array that needs to be combined
          toCombine.push(period);
          if (toCombine.length < aggregationLevel) return;

          // Combine current group of periods into one
          let statusArray = [];
          let eventsArray = [];

          _.each(toCombine, (p) => {
            // Make an array of the status of all periods
            statusArray.push(p.status);
            // Combine arrays into one if exist
            if (p.events) eventsArray = eventsArray.concat(p.events);
          });

          // Make status array unique, any combined periods that has future and
          // connected/disconnected status would become mixed. Remove future
          // status (3) to wrongly assign mixed status to the combined periods.
          statusArray = _.uniq(_.without(statusArray, 3));

          // Array will only ever be empty if all periods were future, if we
          // have an empty status array re-add future status (3)
          if (!statusArray.length) statusArray.push(3);

          // This is the aggregated period from all periods to combine
          let combined = {
            // Assign events array
            events: eventsArray,
            // statusArray made unique, if there's one item that'll be the
            // status otherwise 2 (mixed)
            status: statusArray.length === 1 ? statusArray[0] : 2,
            // The first periods time
            time: toCombine[0].time
          }

          combinedPeriodsForDevice.push(combined);
          toCombine = [];
        });
        // Assign combined periods for device
        device.periods = combinedPeriodsForDevice;
        // Push device to stats a
        stats.push(device);
      });
      vm.aggregatedDeviceStats = stats;

      // Set current period length
      setPeriodLength();
    }


    /**
     * Returns the timestamp for the selected date in the required timezone.
     *
     * @param {Number} ms Local timestamp
     * @param {boolean} isEndOfDay Boolean for start or end of day
     *                             end of day if TRUE
     * @returns {Number} Timestamp for local date in the required timezone
     */
    function setDateInSelectedZone (ms, isEndOfDay) {
      // Get local date parts
      const date = moment(ms).date();
      const month = moment(ms).month();
      const year = moment(ms).year();
      // Start or end of day matters for range start or end
      const timeOfDay = isEndOfDay ? 'startOf' : 'endOf';
      // Set exact date in required timezone, return timestamp
      return moment
        .tz(vm.timezoneToParse)
        .set({
          'y': year,
          'M': month,
          'D': date
        })
        [timeOfDay]('day')
        .valueOf();
    }

    /**
     * Request device stats then aggregates the results to be displayed.
     *
     * @returns {void}
     */
    vm.getStats = () => {
      // Set loading flag
      vm.isLoading = true;
      // Empty aggregated stats array
      vm.aggregatedDeviceStats = [];

      // If only range start or end was provided display that day only
      if (!vm.rangeStart) {
        vm.rangeStart = moment(vm.rangeEnd).startOf('day').valueOf();
      }
      if (!vm.rangeEnd) {
        vm.rangeEnd = moment(vm.rangeStart).endOf('day').valueOf();
      }

      // Get correct timestmap for selected timezone if specified
      let rStart = vm.useLocalTime ?
        vm.rangeStart : setDateInSelectedZone(vm.rangeStart, true);
      let rEnd = vm.useLocalTime ?
        vm.rangeEnd : setDateInSelectedZone(vm.rangeEnd, false);

      options = {
        start: rStart,
        end: rEnd
      }

      // Request stats
      DeviceStats.getDeviceStats(options).then((res) => {
        // New stats received, reset flags
        vm.periodLengthMs = null;
        vm.isLoading = false;

        vm.deviceStats = vm.isSuper ? res : removeEvents(res, SUPER_EVENTS);
        // No stats received, return early
        if (!vm.deviceStats.length) return;
        // Set data to display
        vm.aggregateStats(vm.deviceStats);
      });
    }

    /**
     * Returns device statistics stripped from all specified events.
     *
     * @param {object} stats Device statistics
     * @param {array} eventsToRemove Array of event names to be removed from the
     *                               device statistics
     * @returns {object} Returns the statistics without the specified events
     */
    function removeEvents (stats, eventsToRemove) {
      // No events to remove, return early
      if (!eventsToRemove || !eventsToRemove.length) return stats;
      // Clone original object
      stats = _.cloneDeep(stats);
      // Go through all devices
      _.each(stats, (device) => {
        // Check all periods
        _.each(device.periods, (period) => {
          // No events in this period, go to next
          if (!period.events || period.events.length === 0) return;
          // Filter out events to remove
          period.events = _.filter(
            period.events, (e) => eventsToRemove.indexOf(e.event) === -1
          );
        });
      });
      // Return the filtered stats
      return stats;
    }

    // Init
    vm.getStats();

    /**
     * Makes a new request for the range before or after the currently selected
     * one.
     *
     * @param {boolean} next Previous or next range, TRUE for next.
     * @returns {void}
     */
    vm.prevNextRange = (next=false) => {
      const method = next ? 'add' : 'subtract';

      // If only range start or end was provided display that day only
      if (!vm.rangeStart) {
        vm.rangeStart = moment(vm.rangeEnd).startOf('day').valueOf();
      }
      if (!vm.rangeEnd) {
        vm.rangeEnd = moment(vm.rangeStart).endOf('day').valueOf();
      }
      // Number of days to subtract from or add to the current range
      let duration = moment(vm.rangeEnd).diff(vm.rangeStart, 'days') + 1;
      // Shift days
      vm.rangeStart = moment(vm.rangeStart)[method](duration, 'days').valueOf();
      vm.rangeEnd = moment(vm.rangeEnd)[method](duration, 'days').valueOf();
      // Request stats for new range
      vm.getStats();
    }

    /**
     * Validates the selected range. Maximum allowed 7 days.
     *
     * @returns {boolean} Validity of the range
     */
    vm.validateRange = () => {
      // Range start must be before range end
      if (vm.rangeStart && vm.rangeEnd && vm.rangeStart > vm.rangeEnd) {
        // Set error for dirty range fields
        if ($scope.settingsForm.rangeFrom.$dirty) {
          $scope.settingsForm.rangeFrom.$setValidity('rangeStartBeforeEnd', false);
        }
        if ($scope.settingsForm.rangeTo.$dirty) {
          $scope.settingsForm.rangeTo.$setValidity('rangeStartBeforeEnd', false);
        }
      } else if (
        $scope.settingsForm.rangeFrom.$error.rangeStartBeforeEnd ||
        $scope.settingsForm.rangeTo.$error.rangeStartBeforeEnd
      ) {
        // Remove error from range fields.
        $scope.settingsForm.rangeFrom.$setValidity('rangeStartBeforeEnd', true);
        $scope.settingsForm.rangeTo.$setValidity('rangeStartBeforeEnd', true);
      }
      // Selected range is valid if diff is less than 7 days
      // NOTE: Range end is 23:59:59, moment diff ('days') will return 0 if
      // range starts and ends the same day, 7 days difference would be an
      // 8 days range.
      return vm.rangeStart && vm.rangeEnd ?
        moment(vm.rangeEnd).diff(vm.rangeStart, 'days') < 7 : true;
    }

    /**
     * Sets a given period selected for the device to show details of all the
     * events happened in that period.
     *
     * @param {object} device The device to set period selected.
     * @param {object} period The selected period.
     * @param {object} i Index of the selected period.
     * @returns {void}
     */
    vm.selectPeriod = (device, period, i) => {
      // Reset previously selected period if there is one
      if (device.selectedPeriod) {
        // Deselecting if the period is already selected
        let deselect = device.selectedIndex === i;
        // Clear device's selected period
        vm.clearSelectedPeriod(device);
        // Return if deselecting only
        if (deselect) return;
      }
      // Set selected period and index for device
      device.selectedPeriod = period;
      device.selectedIndex = i;
      device.periods[i].selected = true;
    }

    /**
     * Clear selected period for device.
     *
     * @param {object} device The device to clear its selected period.
     * @returns {void}
     */
    vm.clearSelectedPeriod = (device) => {
      delete device.periods[device.selectedIndex].selected;
      device.selectedPeriod = null;
      device.selectedIndex = null;
    }

    /**
     * Sets the disabled reason message.
     *
     * @returns {void}
     */
    vm.setDisabledMessage = () => {
      vm.disabledMessage = '';
      // Get messages for the form's errors
      let message =
        DisabledMessage.getErrorMessageList($scope.settingsForm.$error);

      // No error messages
      if (!message.length) return;

      // Create list why the button is disabled
      message = message.map(m => `<li>${m}</li>`);
      message.push('</ul>');
      message.unshift(
        HelpText.get("forms.disabledReasons.buttonDisabled") + '<ul>'
      );
      vm.disabledMessage = message.join('');
    }

    vm.disabledMessages = {
      'date-format': HelpText.get("forms.disabledReasons['date-format']"),
      rangeTooLong: HelpText.get("forms.disabledReasons.rangeTooLong"),
      required: 'Please select a single date or range',
      rangeStartBeforeEnd: HelpText.get("forms.disabledReasons.rangeStartBeforeEnd")
    }

    $scope.$watchCollection("settingsForm", vm.setDisabledMessage)
    $scope.$watchCollection("settingsForm.$error", vm.setDisabledMessage)

    /**
     * Returns date strings for the given timestamp.
     *
     * @param {Number} timestamp Timestamp.
     * @param {String|Undefined} part Part of date string to return.
     *  - `undefined` Date and time
     *  - `date` Date only
     *  - `time` Time only
     * @param {Boolean} zToken Flag to include timezone abbreviation.
     * @returns {String} The required date time string .
     */
    vm.getFormattedTime = (timestamp, part, zToken) => {
      if (vm.useLocalTime) zToken = false;
      switch (part) {
        case 'date':
          return moment(timestamp)
            .tz(vm.timezoneToParse)
            .format(`DD-MMM-YYYY`);
        case 'time':
          return moment(timestamp)
            .tz(vm.timezoneToParse)
            .format(`HH:mm${zToken ? ' z' : ''}`);
        default:
          return moment(timestamp)
            .tz(vm.timezoneToParse)
            .format(`DD-MMM-YYYY HH:mm${zToken ? ' z' : ''}`);
      }
    }

    /**
     * Assigns position classes for tooltips which way they should open.
     *
     * @param {Event} e Event object.
     * @returns {oid}
     */
    vm.assignPositionClasses = (e) => {
      // Return in case if the event target is not a status indicator.
      if (!e.target.classList.contains('status-indicator')) return;

      // Find tooltip and get its DOMRect
      const tooltip = e.target.querySelector('.info-tooltip');
      const tooltipRect = tooltip.getBoundingClientRect();

      // Get window dimensions
      const windowWidth = $window.innerWidth;
      const windowHeight = $window.innerHeight;

      // Should open to top or bottom?
      tooltip.classList.add(`open--${
        tooltipRect.top + tooltipRect.height > windowHeight ? 'up' : 'down'
      }`);

      // Should open to left or right?
      tooltip.classList.add(`open--${
        tooltipRect.left + tooltipRect.width > windowWidth ? 'left' : 'right'
      }`);
    }

    /**
     * Removes position classes from tooltips.
     *
     * @param {Event} e Event object.
     * @returns {oid}
     */
    vm.removePositionClasses = (e) => {
      // Return in case if the event target is not a status indicator.
      if (!e.target.classList.contains('status-indicator')) return;

      const directions = ['up', 'down', 'left', 'right'];
      const tooltip = e.target.querySelector('.info-tooltip');

      _.each(directions, (direction) => {
        // Remove all classes from tooltip
        tooltip.classList.remove('open--' + direction);
      });
    }

    vm.filters = {
      name: '',
      guid: '',
      macAddress: '',
      ipAddress: '',
      showOnline: true,
      showOffline: true,
      showLocked: true,
      showUnlocked: true
    };


    /**
     * Returns the given string lowercased, without the specified characters.
     *
     * @param {String} string String to process.
     * @param {String[]} charsToTrim Array of characters to remove.
     * @returns {String} The modified string.
     */
    function trimAndLowercaseStringForFilter(string, charsToTrim = []) {
      return _.without(string.toLowerCase(), ...charsToTrim).join('');
    }

    /**
     * Returns if the filter value matches the string.
     *
     * @param {String} string String to match.
     * @param {String} filterVal Filter value.
     * @param {String[]} toTrim Array of characters to remove.
     * @returns {Boolean} TRUE if filter value matches the string.
     */
    function matchStrings (string, filterVal, toTrim=[]) {
      return _.contains(
        trimAndLowercaseStringForFilter(string, toTrim),
        trimAndLowercaseStringForFilter(filterVal, toTrim)
      );
    }

    /**
     * Filter function.
     *
     * @param {object} device The device to filter.
     * @returns {Boolean} Wether the device matches all criteria.
     */
    vm.filterFunction = (device) => {
      let filters = vm.filters;

      // Name filter
      // NOTE: Name filter is the only fuzzy filter
      if (filters.name) {
        if (fuzzy.match(filters.name, device.name) === null) return false
      }

      // MAC address filter
      if (filters.macAddress) {
        // Rule out browser receiver when filter enabled
        if (!device.platform.ethernetMac && !device.platform.wifiMac) {
          return false;
        }

        // Match mac address without spaces or separators
        const charsToRemove = [':', ' '];
        let matches = false;

        // Checking both ethernet and wifi MAC
        _.each([
          device.platform.ethernetMac, device.platform.wifiMac
        ], (address) => {
          // Return if a device has no wifi or mac address
          if (!address) return;
          // Match wifi or ethernet MAC to the filter value
          if (matchStrings(address, filters.macAddress, charsToRemove)) {
            // We have a match, set flag and exit loop
            matches = true;
            return false;
          }
        });
        // Return false if wifi or ethernet MAC doesn't match the filter value
        if (!matches) return false;
      }

      // Serial number filter
      if (filters.guid) {
        // No spaces
        const charsToRemove = [' '];

        if (!device.platform.guid) return false;

        if (!matchStrings(device.platform.guid, filters.guid, charsToRemove)) {
          return false;
        }
      }

      // IP address filter, matches both IPv4 and IPv6 addresses for both wifi
      // and ethernet connections
      if (filters.ipAddress) {
        // Match ip address without spaces or separators
        const charsToRemove = ['.', ':', ' '];

        let matches = false;

        _.each(device.platform.ipAddresses, (connectionType) => {
          _.each(connectionType, (ip) => {
            if (matchStrings(ip, filters.ipAddress, charsToRemove)) {
              matches = true;
              return false; // Have a match, end loop early
            }
          });
          if (matches) return false; // Have a match, end loop early
        })

        if (!matches) return false;
      }

      // Device online/offline status filter
      if (
        // Do not show offline AND device is offline OR
        (!filters.showOffline && !device.status.online) ||
        // Do not show online AND device is online OR
        (!filters.showOnline && device.status.online) ||
        // Do not show online or offline
        (!filters.showOnline && !filters.showOffline)
      ) return false;

      // Device un/locked filter
      if (
        // Do not show locked AND device is locked OR
        !filters.showLocked && !device.status.enabled ||
        // Do not show unlocked AND device is unlocked
        !filters.showUnlocked && device.status.enabled
      ) return false;

      // Device matches all filter criteria
      return true
    }

    /**
     * Determines if any of the filters are enabled.
     *
     * @returns {Boolean} TRUE if any of the filters are enabled.
     */
    vm.filtersEnabled = () => vm.filters.name.length ||
      vm.filters.guid.length ||
      vm.filters.macAddress.length ||
      vm.filters.ipAddress.length ||
      !vm.filters.showOnline ||
      !vm.filters.showOffline ||
      !vm.filters.showLocked ||
      !vm.filters.showUnlocked;

    /**
     * Reset filter function.
     *
     * @returns {void}
     */
    vm.resetFilters = function () {
      vm.filters.name = '';
      vm.filters.guid = '';
      vm.filters.macAddress = '';
      vm.filters.ipAddress = '';
      vm.filters.showOnline = true;
      vm.filters.showOffline = true;
      vm.filters.showLocked = true;
      vm.filters.showUnlocked = true;
    };

    /**
     * This function checks the device connection and returns a boolean whether
     * to display or hide the connection to not show empty details.
     *
     * @param {Object} device The device.
     * @param {String} type Type of connection.
     *  - wifi
     *  - ethernet
     * @returns {Boolean} TRUE if connection can be displayed.
     */
    vm.showConnectionInfo = (device, type) => {
      // Returns a boolean if the connection type has any key value pairs
      return !!_.keys(device.platform.ipAddresses[type]).length;
    }


    /**
     * Returns the number of periods to be displayed for each device based on
     * the current breakpoint.
     *
     * @returns {Number} 24|48|96 based on current breakpoint.
     */
    function getMaxPeriodsForBreakpoint() {
      switch (currentBreakpoint) {
        case 'xs':
          return 24;
        case 'sm': case 'md':
          return 48;
        case 'lg': case 'xl': default:
          return 96;
      }
    }


    /**
     * Set the length of one period in milliseconds to display end of period
     * times on the page.
     *
     * @returns {void}
     */
    function setPeriodLength() {
      vm.periodLengthMs =
        moment(vm.aggregatedDeviceStats[0].periods[1].time)
          .diff(vm.aggregatedDeviceStats[0].periods[0].time);
    }

    $scope.$watch('vm.rangeEnd', (n) => {
      // Make sure that range end is always a timestamp for the end of the day
      vm.rangeEnd = moment(n).endOf('day').valueOf();
    })

    let currentBreakpoint = Breakpoints.current;


    /**
     * Update current breakpoint if changes and aggregates device stats.
     *
     * @returns {void}
     */
    function updateBreakpoint () {
      if (currentBreakpoint !== Breakpoints.current) {
        currentBreakpoint = Breakpoints.current;
        $scope.$apply(() => {
          vm.aggregateStats(vm.deviceStats);
        });
      }
    }

    // Update breakpoint on resize
    $window.addEventListener('resize', updateBreakpoint);

    $scope.$on('$destroy', () => {
      // Remove resize event listener
      $window.removeEventListener('resize', updateBreakpoint);
    })
  });
