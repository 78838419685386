angular.module('flare.fileinputs', [])

.component('fileSelect', {
  bindings: {
    dropText: '@',
    accept: '@',
    onFilesSelected: '&',
  },
  templateUrl: 'utility/inputs/fileInput.jade',
  controller: function ($element, $scope) {
    // First set up drop handlers.
    const dropArea = $element[0].querySelector('.file-input--drop-area');

    // We need to map the pxn button click to the (hidden) file input to trigger
    // the system dialog.
    const fileInput = $element[0].querySelector('input');

    this.$onInit = function () {
      // Add accept if specified - note that this is not dynamic.
      if (this.accept) {
        fileInput.accept = this.accept;
      }
    }

    const button = $element[0].querySelector('pxn-button');
    button.addEventListener('click', ()=> fileInput.click());

    // When files get selected, call the method.
    fileInput.addEventListener('change', _=> {
      $scope.$apply(()=> {
        this.onFilesSelected({
          files: Array.from(fileInput.files)
        });
      });
    });

    // Even if we didn't want to add this class dragover implementation is
    // required to prevent the default behaviour.
    dropArea.addEventListener('dragover', (e)=>{
      e.preventDefault();
      dropArea.classList.add('file-input--drop-area__dragover');
    });

    dropArea.addEventListener('dragleave', (e)=> {
      dropArea.classList.remove('file-input--drop-area__dragover');
    });

    dropArea.addEventListener('drop', (e)=> {
      dropArea.classList.remove('file-input--drop-area__dragover');
      e.preventDefault();
      if (e.dataTransfer.items) {
        // files have been dropped.
        $scope.$apply(()=> {
          this.onFilesSelected({
            files: Array.from(e.dataTransfer.items).map(f => f.getAsFile())
          });
        });
      }
      // Clean up
      e.dataTransfer.items.clear();
    });
  },
});