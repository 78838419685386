angular.module('flare.q.expansion', [])

.config(['$provide', function ($provide) {

  $provide.decorator('$q', [
    '$delegate',
    function ($delegate) {

      // Add method allSettled
      $delegate.allSettled = function (promiseArray) {
        var deferred = $delegate.defer();
        var resolveWith = [];
        var settled = 0;
        _.each(promiseArray, function (promise, index) {
          promise.then(function success (data) {
            // Add the result object to the results array in the same order
            // promises are supplied by maintaining the index.
            resolveWith[index] = {
              state: 'fulfilled',
              value: data
            };
            // Increment the settled promises counter
            settled++;
            // Check if this was the last promise we required and resolve the
            // deferred promise if it was.
            if (settled === promiseArray.length) {
              deferred.resolve(resolveWith);
            }
          }, function failure (error) {
            // Add the result object to the results array in the same order
            // promises are supplied by maintaining the index.
            resolveWith[index] = {
              state: 'rejected',
              reason: error
            };
            // Increment the settled promises counter
            settled++;
            // Check if this was the last promise we required and resolve the
            // // deferred promise if it was.
            if (settled === promiseArray.length) {
              deferred.resolve(resolveWith);
            }
          });
        });
        return deferred.promise;
      };
      return $delegate;
    }
  ]);
}]);
