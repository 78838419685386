angular.module('flare.takeovers.template', ['genericResource'])

.factory('TakeoverTemplates', function (
  GenericResource, ResourceItem, ConfigURLs, Templates, Slides, Searches, $q,
  Devices, $log, Modal, $state, Session, Toast, $http, Takeovers, Orientation,
  $rootScope, Feeds, CustomFields, $timeout
) {
  const logPrefix = '[TakeoverTemplates] - ';
  // Init all our generic resources here.
  let slides, searches, devices;
  let templates = Templates.all();
  if (Session.hasPermission('content.slides.read')) {
    slides = Slides.all();
  } else {
    slides = [];
    slides.promise = $q.resolvedPromise();
  }
  if (Session.hasPermission('searches.read')) {
    searches = Searches.all();
  } else {
    searches = [];
    searches.promise = $q.resolvedPromise();
  }
  if (Session.hasPermission('devices.read')) {
    devices = Devices.all();
  } else {
    devices = [];
    devices.promise = $q.resolvedPromise();
  }

  // There are no permisison check for customisations
  let customisations = CustomFields.getFields().then(() => {
    customisations.all = CustomFields.customFields.all;
  });

  let resourcesReady = $q.all([
    templates.promise,
    slides.promise,
    searches.promise,
    devices.promise
  ]);
  let hasResources = false;
  resourcesReady.then(() => {
    hasResources = true;
  })

  class TakeoverTemplate extends ResourceItem {
    constructor (tt) {
      super(tt);
      this.setTargetDevices();

      this._defaultSplitInputStates = {};

      // Static slides are supplied as an ID.
      if (typeof this.slide === 'string' && this.slide) {
        this.resourceType = 'static';
        resourcesReady.then(() => {
          if (Session.hasPermission('content.slides.read')) {
            this.resource = Slides.byId(this.slide, true);
          }
        });
      } else if (
        this.slide && typeof this.slide === 'object' && this.slide.template
      ) {
        // dynamic with template
        this.resourceType = 'dynamic';
        resourcesReady.then(() => {
          this.resource = Templates.byId(this.slide.template);
          if (!this.isLocal()) {
            this.resetContentToDefault();
          }
          this._updateCustomisation();
        });
        // Decide if we have dynamic fields
        this._hasDynamics = _.any(this.slide.fields, 'dynamic', true);
      } else {
        // We don't have anything to use as a resource
        this.resourceType = 'dynamic';
      }

      // Set time/duration related properties.
      this.updateDuration(null, false);
      this.setMoreToShow();

    }

    /**
     * Sets moreToShow flag on takeover template to display or hide show more /
     * show less buttons.
     * @returns {undefined}
     */
    setMoreToShow () {
      this.moreToShow = false;
      if (this.slide && this.slide.fields) {
        _.each(this.slide.fields, (val, key) => {
          if (!val.dynamic) {
            if (key[1] !== '_') {
              this.moreToShow = true;
              return false;
            }
          }
          return true;
        });
      }
    }

    _updateCustomisation () {
      // Set `customFields` and `contentFields` on the TT (which are
      // used/combined to create the displayed list of fields on a TT), we also
      // update related data stored on the TT, for example
      customisations.then(() => {
        let templateContentFields =
          Object.assign({}, this.resource.contentFields);
        let templateCustomFields = {};
        if (customisations.all[this.resource.name]) {
          // There is a customisation for this template.
          templateCustomFields = Object.assign(
            {}, customisations.all[this.resource.name].contentFields
          );
          _.each(
            customisations.all[this.resource.name].contentFields,
            (customFieldData, customFieldKey) => {
              if (customFieldData.remove) {
                delete templateCustomFields[customFieldKey];
                delete templateContentFields[customFieldKey];
                _.each(this.slide.content, (localeData, localeKey) => {
                  _.each(localeData.data, (orientationData, orientationKey) => {
                    orientationData[customFieldKey] = customFieldData.value;
                  });
                });
                this.slide.fields[customFieldKey].default =
                  customFieldData.value;
              } else {
                this.showCustomFields = true;
                // if there is a value; leave it in place.
                // if there isn't a value; set defaults
                if (!this.slide.fields[customFieldKey]) {
                  this.slide.fields[customFieldKey] =
                    { dynamic: false, default: customFieldData.value };
                }
              }
            }
          );
        }
        this.takeoverContentFields =
          Object.assign({}, templateContentFields, templateCustomFields);
        // Reset _hasDynamics as this could have changed
        this._hasDynamics = _.any(this.slide.fields, 'dynamic', true);
      });
    }

    /**
     * Generates all the data required for showing a preview of the slide the TT
     * will create and then gives it to you.
     *
     * @returns {Object} Data required to generate a preview for the slide.
     * @memberof TakeoverTemplate
     */
    getPreviewData (formCtrl, splitStates) {
      let previewData = {
        takeoverTemplate: this,
        primaryLocale: 0,
        currentLocale: 0,
        formController: formCtrl
      };
      if (this.resourceType === 'static') {
        previewData.slide = this._resource;
        previewData.slideContent = this._resource.content;
        previewData.splitStates = splitStates;
        previewData.positionImages = false;
        return previewData;
      } else {
        // Only make a slide once, otherwise we can just update it.
        if (!this._previewDataSlide) {
          this._previewDataSlide = Slides.create({
            id        : '$TAKEOVER_PREIVEW_SLIDE_' + this._id,
            account   : Session.current.account._id,
            createdBy : Session.current.user._id,
            name      : 'dynamic slide preview',
            uploads   : this.slide.uploads,
            feeds     : this.slide.feeds,
            takeover  : true,
            template  : Templates.byId(this.slide.template),
          }, false);
        }

        this._previewDataSlide.content = this.slide.content;

        previewData.slide = this._previewDataSlide;
        previewData.slideContent = this._previewDataSlide.content;
        previewData.splitStates = splitStates;
        previewData.positionImages = true;
        return previewData;
      }
    }

    savePreviewDataAsDefault (AllContent) {
      // Gather content keys, use all locations to ensure nothing is missed.
      // #theFuture
      const localisedContent = AllContent[0].data;
      let localisedContentKeys =
        Object.keys(localisedContent.landscape)
        .concat(
          Object.keys(localisedContent.portrait),
          Object.keys(localisedContent.anyOrientation)
        );

      // Gather p_ keys (positon).
      let updateKeys =
        _.uniq(localisedContentKeys).filter(k => k.startsWith('p_'));

      // We also check for hidden keys as their values may have been updated.
      Object.keys(this._resource.contentFields).forEach(fieldName => {
        let field = this._resource.contentFields[fieldName];
        if (field.type === 'hidden') {
          updateKeys.push(fieldName);
        }
      });

      updateKeys.forEach((key)=> {
        this.slide.fields[key] = this.slide.fields[key] || {};
        this.slide.fields[key].default = {
          landscape: localisedContent.landscape[key],
          portrait: localisedContent.portrait[key]
        };
      });

      // We only want to save slide.fields and as we know these are always
      // orientation specific we can skip splitstate logic.
      return this.save(true, ['slide.fields'], true);
    }

    /**
     * Additonal tasks to complete pre super save.
     *
     * @returns {TakeoverTemplate} The save TakeoverTemplate.
     */
    save (overwrite, fields, superSaveOnly) {
      // Skips additional logic when we know we don't need it.
      // (saving preview fields)
      if (superSaveOnly) {
        return super.save(overwrite, fields);
      }
      this.updateDuration();
      return resourcesReady.then(()=> {
        if (this.resourceType === 'dynamic') {
          // Remove any ref to anyOrientation.
          _.each(this.slide.fields, (field, fieldName)=> {
            let contentField = this._resource.contentFields[fieldName];

            // Non splittable input case, no orientation values exist.
            if (contentField && !contentField.splittable) {
              return;
            }

            // Unsplit input, copy anyO to p & l.
            if (
              !Orientation.isSpecific(
                this._defaultSplitInputStates, contentField, fieldName
              ) && field.default
            ) {
              field.default.landscape =
                angular.copy(field.default.anyOrientation);
              field.default.portrait =
                angular.copy(field.default.anyOrientation);
            }
            if (field.default) delete field.default.anyOrientation;
          });
          this.resetContentToDefault();
          this.setUploadsAndFeeds();

        }
        return this.setTargetDevices().then(() => {
          if (this.isInvalid()) {
            delete this.slide.content;
          }
          return super.save(overwrite, fields);
        });
      }).finally(()=> {
        this.setMoreToShow();
      });
    }

    reset () {
      if (this.isLocal()) {
        // What are we even resetting here? This is more of a delete?
        return super.reset();
      }

      return super.reset().then((resetTT) => {
        // We always reset previewModified as this is local & will persist
        resetTT.setPreviewModified(false);
        resetTT.updateDuration(null, false);
        resetTT.resetContentToDefault();
        resetTT.setMoreToShow();
        return resetTT.setTargetDevices();
      }).finally((tt) => tt);
    }

    /**
     * Adds an "are you sure" check before deleting the TT.
     *
     * @memberof TakeoverTemplate
     */
    delete () {
      if (this.isLocal()) {
        return super.delete();
      }
      return new Modal({
        scopeData: {
          message: `Are you sure you want to delete "${this.name}" takeover?`,
          positiveButton: 'Delete takeover',
          negativeButton: 'Cancel'
        }
      }).show().then(() => {
        return super.delete();
      }).then(() => {
        Toast.makeSuccess(`Takeover "${this.name}" has been deleted`);
      }).catch((err) => {
        Toast.makeError(err);
      });
    }

    /**
     * Updates the values stored for feeds and uploads on the takeover template.
     *
     * @memberof TakeoverTemplate
     */
    setUploadsAndFeeds () {
      if (this.resourceType === 'static') {
        // These will be set on the slide.
        return;
      } else {
        // Reset any existing values
        this.slide.uploads = [];
        this.slide.feeds = [];
        _.each(this.slide.content, (locale, localeName) => {
          _.each(locale.data, (orientationData, orientation) => {
            _.each(orientationData, (field, fieldName) => {
              if (
                field &&
                typeof field === 'object' &&
                field.hasOwnProperty('_id')
              ) {
                this.slide.uploads.push(field._id);
              }
              if (
                field &&
                typeof field === 'string'
              ) {
                let temp = Feeds.byId(field, true);
                if (temp !== -1) {
                  this.slide.feeds.push(field);
                }
              }
            });
          });
        });
        this.slide.uploads = _.uniq(this.slide.uploads);
        this.slide.feeds = _.uniq(this.slide.feeds);
      }
    }

    /**
     * Checks request validity and vaviagates to the edit slide page passing the
     * ID of the slide to be edited. Upon saving or cancelling users will be
     * returned to the originating page.
     *
     * @memberof TakeoverTemplate
     */
    editSlide () {
      if (this._resource.resourceType === 'slide') {
        $state.goForResult('edit-slide', { id: this._resource._id });
      } else {
        $log.debug(
          logPrefix +
          'User trying to edit a non slide resource with the id: ' +
          this._resource._id
        );
      }
    }

    getNowTs () {
      return this.hardExpiry ?
        new Date().setSeconds(0, 0) :
        new Date().getTime();
    }

    /**
     * Checks for hardExpiry and inits if required based on defaultDuration.
     * Set duration, using either the provided value or defaultDuration.
     *
     * @memberof TakeoverTemplate
     */
    updateDuration (valMs, setModified = true) {
      // Ensure hardExpiry is set/correct.
      this.hardExpiry = this.hardExpiry === null ?
        this.defaultDuration >= 1 : this.hardExpiry;
      // Convert defaultDuration (minutes) to milliseconds.
      let defaultMS = (this.defaultDuration * 60) * 1000;
      // Only set the value if it has changed.
      if (
        this.duration !== (defaultMS / 1000) ||
        this.duration !== (valMs / 1000)
      ) {
        // Duration has changed, finally set the duration.
        this.duration = valMs ? (valMs / 1000) : (defaultMS / 1000);
        if (setModified) {
          this.setModified();
        }
      }
    }

    setSplitState (key, isSplit, ignoreDirty = false) {
      this._defaultSplitInputStates[key] = isSplit;
      if (!ignoreDirty) {
        this.setModified();
      }
    }

    dateInPast () {
      return this.duration <= 0;
    }

    resetDurationIfInvalid () {
      if (this.duration <= 0) {
        this.duration = (this.defaultDuration * 60);
      }
    }

    set _showUntilTs (ts) {
      this.updateDuration(ts - this.getNowTs());
    }
    get _showUntilTs () {
      return this.getNowTs() + (this.duration * 1000);
    }

    set _showForXMinutes (minutes) {
      this.updateDuration((minutes * 60) * 1000);
    }
    get _showForXMinutes () {
      // Convert duration (seconds) to minutes and return.
      return (this.duration / 60);
    }


    /**
     * This function will set values on `targets` & `_targetDevices`
     */
    set _targets (val) {
      this.targets = val;
      this.setTargetDevices();
    }
    get _targets () {
      return this.targets;
    }

    // NOTE: This seems odd and should be _resourceType probably
    set resourceType (val) {
      if (this._resourceType && this._resourceType !== val) {
        // There was a previous value, that was not the same
        this.resource = null;
      }
      this._resourceType = val;
    }
    get resourceType () {
      return this._resourceType ||
        ((typeof this.slide === 'string') ? 'static' : 'dynamic');
    }

    // NOTE: This seems odd and should be _resource probably
    set resource (val) {
      // If val is null then we are trying to reset the resource
      if (val === null) {
        this.slide = val;
        this.takeoverContentFields = val;
        return;
      }
      // We have a resource
      if (val.resourceType === 'template') {
        // We have a new template
        this._resource = val;
        if (!this.slide) {
          this.slide = {
            template: val._id,
            fields: {},
            orientations: val.orientations
          };
        } else {
          this.slide.template = val._id;
        }
        for (let key in val.contentFields) {
          if (!this.slide.fields[key]) {
            this.slide.fields[key] = {
              dynamic: false
            };
          }
        }
        // Apply customisation
        this._updateCustomisation();
      } else if (val.resourceType === 'slide') {
        // We have a new slide
        this.slide = val._id;
        this._resource = val;
      } else if (val === -1) {
        $log.debug(logPrefix + 'Couldn\'t find the resource!');
      }
    }
    get resource () {
      return this._resource;
    }

    /**
     * Checks the validity of the takeover that would be created if triggered.
     * Key checks are; atleast one device, expiry time in the future & if
     * dynamic the content must be dynamic (static slides must be valid when
     * saved so no need to check again).
     *
     * @param {Boolean} isCreationTime Are we creating a takeover? This causes
     * invalid dynamic fields to be ignored (these "should" be set pre trigger)
     * when true.
     * @returns {Boolean} is invalid?
     * @memberof TakeoverTemplate
     */
    isInvalid (isCreationTime) {
      /* DEVICE CHECKS */
      if (!this._targetDevices || !this._targetDevices.length) {
        return true;
      }

      /* DURATION CHECKS */
      if (new Date(this.hardExpiryDate).getTime() < new Date().getTime()) {
        return true;
      }

      /* CONTENT CHECK */
      if (this.resourceType === 'dynamic') {
        let invalidContent = false;
        _.each(this._resource.contentFields, (fieldVal, fieldKey) => {
          // Check that the input is required
          if (fieldVal.required) {
            // Check we have a default value
            // NOTE: if we have a default value we don't care about the value.
            let fieldDef = this.slide.fields[fieldKey];
            if (fieldDef && fieldDef.default == null) {
              if (fieldDef.value == null) {
                // we are invalid
                if (!isCreationTime) {
                  invalidContent = true;
                  // return early from the loop
                  return false;
                }
              }
            }
          }
          // Don't end the loop early.
          return true;
        });
        // NOTE: I could have just returned the val of `invalidContent` but if
        // we add to this in the future we'd have to change it so why.
        if (invalidContent) {
          return true;
        }
      }
      return false;
    }

    requestNewWebhook () {
      return $http
        .post(ConfigURLs.takeoverTemplateWebhook(this))
        .then((res) => {
          // Manually bump version as route does this implicitly.
          this.__v++;
          return res.data;
        })
        .catch((err) => {
          $log.error(err);
        });
    }

    /**
     * Generates a list of unique devices from the list of selected devices and
     * searches. This list is then stored on the TT ready for use.
     *
     * @memberof TakeoverTemplate
     */
    setTargetDevices () {
      let uniqueDevices = [];
      let searchPromises = [];
      let selectedSearches = [];
      return resourcesReady.then(() => {
        _.each(this.targets, (value, resourceType) => {
          if (resourceType === 'devices') {
            uniqueDevices = uniqueDevices.concat(value);
          }
          if (resourceType === 'searches') {
            _.each(value, (searchId, index) => {
              if (typeof searchId === 'object') {
                selectedSearches.push(searchId);
              } else {
                // Do not not query the server for "ALL" Id or expect errors.
                searchPromises.push(
                  searchId === 'ALL' ?
                    $q.resolvedPromise().then(() => {
                      selectedSearches.push({ _id: 'ALL' });
                    }) :
                    $q.resolvedPromise(
                      selectedSearches.push(Searches.byId(searchId, true)
                    ))
                );
              }
            });
          }
        });
        return $q.all(searchPromises).then(() => {
          _.each(selectedSearches, (search, index) => {
            // If this is the "ALL" we want to end early and return everything.
            if (search._id === 'ALL') {
              uniqueDevices = [...devices];
              return false;
            }
            let filteredIds = _.map(
              Searches.runFilters(search, devices),
              (device) => {
                return device._id;
              }
            );
            uniqueDevices = uniqueDevices.concat(filteredIds);
          });
          uniqueDevices = _.uniq(uniqueDevices);
          this._targetDevices = uniqueDevices;
          return this;
        });
      });
    }

    /**
     * Checks the template uses a dynamic webshot (there is no point in showing
     * a staic image, it isn't useful) and that the slide doesn't have a feed
     * (we are unable to subscribe to feeds in the sender and typically webshot
     * reflects that making it useless).
     *
     * @returns {Boolean} Can we show the preview?
     * @memberof TakeoverTemplate
     */
    canShowStaticPreview () {
      if (
        this._resource &&
        this._resource.template &&
        this._resource.template.webshot &&
        this._resource.template.webshot.dynamic &&
        !this._resource.feeds.length
      ) {
        return true;
      }
      return false;
    }

    haveStaticResource () {
      if (
        this._resource
      ) {
        return true;
      }
      return false;
    }

    /**
     * Accepts an object as storage, this object will then have data assigned to
     * it (thus keeping the object reference).
     *
     * @param {Object} storage The object on which you desire preview data to be
     * stored.
     * @memberof TakeoverTemplate
     */
    showPreview (storage, formCtrl, splitStates) {
      if (typeof storage === 'object') {
        _.assign(storage, this.getPreviewData(formCtrl, splitStates));
      } else {
        $log.debug(
          logPrefix +
          'Invalid arg provided, expected "object", received ' +
          typeof storage
        );
      }
    }

    /**
     * Populates the TTs slide content, prioritises all values over default
     * values. (Content is validated so a valid value will be available even if
     * it is `undefined`). Called by constructor, reset, and updateDefaults.
     *
     * @memberof TakeoverTemplate
     */
    resetContentToDefault () {
      // Check we're a dynamic slide and have a template.
      if (this._resourceType !== 'dynamic' || !this.slide.template) return;

      const newContent = {
        landscape: {},
        portrait: {},
        anyOrientation: {}
      };

      // Generate splitState based on template fields & default values
      const initialSplitStates =
        Object.keys(this.slide.fields).reduce((splitStates, fieldName)=> {
          const contentField = this._resource.contentFields[fieldName];
          // No splitstate required for non splittable or non existent fields.
          if (!contentField || (contentField && !contentField.splittable)) {
            return splitStates;
          }
          const defaultVal = this.slide.fields[fieldName].default || {};
          splitStates[fieldName] =
            !angular.equals(defaultVal.landscape, defaultVal.portrait);
          return splitStates;
        }, {});

      _.each(this.slide.fields, (field, fieldName) => {
        let contentField = this._resource.contentFields[fieldName];

        // Hidden fields are ALWAYS orientation specific
        if (
          contentField &&
          (contentField.type !== 'hidden' && !contentField.splittable)
        ) {
          newContent.anyOrientation[fieldName] = angular.copy(field.default);
          return;
        }
        if (!field.default) return;
        if (
          Orientation.isSpecific(initialSplitStates, contentField, fieldName)
        ) {
          newContent.landscape[fieldName] =
          angular.copy(field.default.landscape);
          newContent.portrait[fieldName] =
          angular.copy(field.default.portrait);
        } else if (
          typeof field.default === 'object' &&
          field.default.landscape !== undefined
        ) {
          newContent.anyOrientation[fieldName] =
            angular.copy(field.default.landscape);
          newContent.landscape[fieldName] =
            angular.copy(field.default.landscape);
          newContent.portrait[fieldName] =
            angular.copy(field.default.landscape);
        } else {
          newContent.anyOrientation[fieldName] =
            angular.copy(field.default);
          newContent.landscape[fieldName] =
            angular.copy(field.default);
          newContent.portrait[fieldName] =
            angular.copy(field.default);
        }

      });

      this.slide.content = {
        // Reset any existing data, we always cast from scratch
        0: {
          behaviour: 'primary',
          data: newContent
        }
      };
      this._hasDynamics = _.any(this.slide.fields, 'dynamic', true);
    }

    /**
     * Sets takeover lifetime based on the time now and the set duration.
     *
     * @memberof TakeoverTemplate
     */
    setLifeTime () {
      let nowTs = this.getNowTs();
      this.lifetime = {
        start: nowTs,
        end: nowTs + (this.duration * 1000)
      };
    }

    triggerTakeover (cb, splitStates) {
      if (this.isModified()) {
        // Triggered takeover modified, update uploads and feeds arrays.
        this.setUploadsAndFeeds();
      }
      // Ensure our lifetime has been calculated.
      this.setLifeTime();
      return this.setTargetDevices().then((tt) => {
        // Start populating an object representing our takeover.
        let takeover = {
          takeoverTemplate: {
            _id: tt._id,
            __v: tt.__v
          },
          devices: tt._targetDevices,
          lifetime: tt.lifetime,
          showTickers: tt.showTickers
        };
        if (tt.resourceType === 'static') {
          takeover.slide = tt._resource._id;
        } else {
          takeover.slide = {
            orientations: tt.slide.orientations,
            template: tt._resource._id,
            content: {
              0: {
                behaviour: 'primary',
                data: Orientation.getContentDataToSave(
                  tt.slide.content[0].data,
                  tt._resource.contentFields,
                  splitStates
                )
              }
            },
            uploads: tt.slide.uploads,
            feeds: tt.slide.feeds,
          };
        }
        // If the "All devices" option has been selected then we need to trigger
        // on all the devices the user knows about.
        if (tt.targets.searches.includes('ALL')) {
          takeover.devices = devices.map((device) => device._id);
        }
        return $http.post(ConfigURLs.takeovers, takeover)
        .then((res) => {
          let nTakeover = Takeovers.create(res.data);
          // Disable new takeovers stop button for two seconds to avoid possible
          // race condition where a suddenly cancelled video takeover's sound
          // still plays for the duration of the cancelled takeover.
          nTakeover._disableStopButton = true;
          $timeout(()=> {
            nTakeover._disableStopButton = false
          }, 2000);
          Toast.makeSuccess(`${nTakeover.name} successfully triggered`);
          return cb ? cb(nTakeover) : nTakeover;
        })
        .catch((err) => {
          $log.error(err)
        });
      });

    }

    setPreviewModified (state = true) {
      this.previewModified = state;
    }

    isPreviewModified () {
      return this.previewModified;
    }

    isValid (formCtrl, splitStates) {
      // We must have a device selected to display on.
      if (!this.targets.devices.length && !this.targets.searches.length) {
        return false;
      }
      // If the type of the TT is static then we need to check the user can
      // access the slide and that is has "valid content".
      // NOTE: The reason the user may not be able to access the slide is
      // because it has been deleted, we will not know this however, so we
      // just stop the takeover being triggered.
      // NOTE: The reason content may not be valid is duie to uploads or feeds
      // having been deleted.
      if (this.resourceType === 'static') {
        if (!hasResources) {
          return false;
        }
        if (Session.hasPermission('content.slides.read')) {
          this.resource = Slides.byId(this.slide, true);
        }
        if (!this.resource) return false;
        else return this.resource.hasRequiredContent();
      }
      // If the user doesn't have the acordion open then we don't know what
      // changes have been made (if any) as the form is no longer on the page.
      // We need to force the user to open the accordion (so they can perform
      // a human visual inspection of the content prior to trigger).
      if (!formCtrl) {
        return false;
      }
      // The accordion is open and we're dynamic, use the preview logic to
      // create a slide which can be used to confirm validity. Be sure to
      // delete the slide after we have finished.
      // NOTE: I have concerns for performance here, open to suggestion as
      // this will run loads!
      if (formCtrl && this.slide) {
        previewData = this.getPreviewData(formCtrl, splitStates);
        let valid = previewData.slide.hasRequiredContent(null, splitStates);
        return valid;
      }
      // Not sure what the deal is but presume terrible things
      return false;
    }
  }

  TakeoverTemplate.prototype._castFields = {
    _template: Templates,
  };
  TakeoverTemplate.prototype._locals = [
    '_defaultSplitInputStates',
    '_resourceType',
    '_resource',
    '_template',
    '_hasDynamics',
    '_targetDevices',
    'resourceType', // This is 'static' or 'dynamic'.
    'slide.content', // This is local - we populate it when we instantiate.
    'previewModified',
    'hardExpiry', // Set when instantiated, helps decide the type of cutoff.
    '_showForXMinutes', // Form value of "Show for" input.
    'takeoverContentFields',
    'moreToShow'
  ];
  TakeoverTemplate.prototype._defaults = {
    name: '',
    description: '',
    tags: [],
    slide: null,
    hardExpiry: null,
    duration: null,
    defaultDuration: 3, // In minutes
    targets: {
      devices: [],
      searches: []
    },
    priority: false,
    previewModified: false,
    _$modified: false
  };

  var TakeoverTemplates =
    new GenericResource(ConfigURLs.takeoverTemplates, TakeoverTemplate);

  $rootScope.$on('TakeoverTemplates::CLEAR_CACHE', ()=> {
    TakeoverTemplates.clearCache();
  });

  return TakeoverTemplates;
})

.component('takeoverTemplate', {
  templateUrl: 'takeovers/takeoverTemplate.jade',
  bindings: {
    takeoverTemplate: '=',
    editFn: '=',
    triggerCbFn: '=',
    targetsFn: '=',
    options: '<',
    previewData: '=',
    previewVisible: '=',
    previewHide: '='
  },
  controller: function (
    Modal,
    Toast,
    $rootScope,
    Session,
    $timeout,
    $scope,
    Templates,
    Slides,
    $q,
    Onboarding,
    DisabledMessage,
    LocaleTree
  ) {
    const $ctrl = this;
    $ctrl.locale = 0; // All screens
    $ctrl.localisationEnabled = LocaleTree.isLocalisationEnabled();
    let templates = Templates.all();
    let slides = {};
    if (Session.hasPermission('content.slides.read')) {
      slides = Slides.all();
    } else {
      slides = [];
      slides.promise = $q.resolvedPromise();
    }
    $ctrl.$onInit = function () {
      $q.all([templates.promise, slides.promise]).then(() => {
        $ctrl.resourcesReady = true;
        $ctrl.takeoverTemplate.setMoreToShow();
      });

      $ctrl.resetTakeover = () => {
        // Ensure we don't have an open preview.
        $ctrl.previewHide();
        return $ctrl.takeoverTemplate.reset().then($ctrl.resetSplitInputs);
      };

      $ctrl.resetSplitInputs = ()=> {
        $scope.$broadcast('split-input::re-initialise-inputs');
        $timeout(()=> {
          $ctrl.takeoverTemplate.setModified(false);
        }, 0);
      };

      $ctrl.onlyOpenNewTakeoverTemplates = () => {
        let created = new Date($ctrl.takeoverTemplate.created).getTime();
        let now = new Date().getTime();
        return (now - created) > (1 * 60 * 1000);
      };

      $ctrl.editTakeover = () => {
        $ctrl.editFn($ctrl.takeoverTemplate._id, $ctrl.resetSplitInputs);
      };

      $ctrl.splitStates = {};
      $ctrl.splitStateChange = (key, isSplit = false, ignoreDirty = false) => {
        $ctrl.splitStates[key] = isSplit;
        // Avoid dirtying the form on init.
        if (!ignoreDirty) {
          $ctrl.takeoverTemplate.setModified();
        }
      };

      const setStaticSplitStates = () => {
        let slide = Slides.byId(this.takeoverTemplate.slide, true);
        if (slide && slide.template && !$ctrl.localisationEnabled) {
          if (typeof slide.template === 'string') {
            slide.template = Templates.byId(slide.template, true);
          }
          Object.keys(slide.template.contentFields).reduce(
            (splitStates, fieldName)=> {
              const contentField = slide.template.contentFields[fieldName];
              // No splitstate required for non splittable or non existent fields.
              if (!contentField || (contentField && !contentField.splittable)) {
                return splitStates;
              }
              const localeContent = slide.content[$ctrl.locale].data;
              splitStates[fieldName] =
                !angular.equals(
                  localeContent.landscape[fieldName],
                  localeContent.portrait[fieldName]
                );
              return splitStates;
            },
            $ctrl.splitStates
          );
        }
      };
      if (this.takeoverTemplate._resourceType === 'static') {
        $timeout(setStaticSplitStates, 0);
      }

      $ctrl.showWebhookSettings = function showWebhookSettings(takeoverTemplate) {
        $timeout(Onboarding.triggerOverviewResourceAvailable);

        let conModal = new Modal({
          templateUrl: 'utility/modal-templates/confirmation.jade',
          scopeData: {
            message: `
            This takeover has been modified! Discard these
            changes before editing webhook settings?
            `,
            negativeButton: 'Cancel and return',
            positiveButton: 'Discard and continue'
          },
          dismissable: { backButton:false, escape:false, backgroundClick:false }
        });

        const existingWebhook = takeoverTemplate.webhook;
        const modalScope = {
          usesSavedSearch: !!this.takeoverTemplate.targets.searches.length,
          makeRequest: function makeWebhookRequest () {
            modalScope.webhook.loading = true;
            modalScope.webhook.existing = null;
            takeoverTemplate.requestNewWebhook()
            .then(function onReceiveWebhookResponse ({ triggerUrl }) {
              modalScope.webhook.loading = false;
              modalScope.webhook.newUrl = triggerUrl;
            });
          },
          saveWebhookEnabled: function saveWebhookEnabled (tt) {
            // Specify that we only want to save & update the webhook property.
            // NOTE: this automatically updates and overwrites the __v value.
            tt.save(true, ['webhook'], true)
            .then(function success () {
              // Enabled webhook for the first time.
              if (tt.webhook.enabled && !tt.webhook.generatedOn) {
                // Return the promise so we can let the user try again if
                // generating a key fails.
                return modalScope.makeRequest();
              }
              return true;
            })
            .then(function () {
              Toast.makeSuccess(
                `Webhooks are now ${tt.webhook.enabled ? 'en' : 'dis'}abled.`,
                { dismissOthers: true }
              );
            })
            .catch(function () {
              tt.webhook.enabled = false;
            });
          },
          webhook: {
            loading: false,
            existing: existingWebhook,
            newUrl: null
          },
          takeoverTemplate: takeoverTemplate,
        };

        let webhookModal = new Modal({
          templateUrl: 'utility/modal-templates/takeoverWebhook.jade',
          scopeData: modalScope,
          dismissable: {
            backButton: true,
            escape: true,
            backgroundClick: true
          }
        })

        if (takeoverTemplate.isModified()) {
          conModal.show().then(()=> takeoverTemplate.reset()).then(() => {
            modalScope.usesSavedSearch =
              !!this.takeoverTemplate.targets.searches.length;
            webhookModal.show();
            if (!existingWebhook) modalScope.makeRequest();
          })
          .catch((err) => {
            Toast.makeError(err);
          });
        } else {
          webhookModal
            .show()
            .catch((err) => {
              Toast.makeError(err);
            });
          if (!existingWebhook) modalScope.makeRequest();
        }

      };

      $ctrl.triggerButtonDisabledReason = '';

      let deregisterWatchGroup = null;

      // Sets up a watch group from the received watch expressions array
      let setupWatchGroup = (group) => {
        deregisterWatchGroup = $scope.$watchGroup(group, function () {
          $ctrl.setDisabledMessage();
        });
      };

      // Updates the array of watch expressions for the watch group
      let updateWatchGroup = () => {
        let disabledReasonWatchGroup = [];

        if ($ctrl.takeoverTemplateTriggerForm) {
          _.each($ctrl.takeoverTemplateTriggerForm, function (val, key) {
            if (angular.isObject(val) && val.hasOwnProperty('$modelValue')) {
              disabledReasonWatchGroup.push(
                `$ctrl.takeoverTemplateTriggerForm['${key}'].$valid`
              );
            }
          });
        }

        // These fields are not part of the form, add them always manually
        disabledReasonWatchGroup.push(
          `!$ctrl.takeoverTemplate._targetDevices ||
           !$ctrl.takeoverTemplate._targetDevices.length`,
          '$ctrl.takeoverTemplate._resource',
          '$ctrl.takeoverTemplate.duration <= 0'
        );
        // Deregister previous watch if there was one
        if (deregisterWatchGroup) deregisterWatchGroup();
        // Set up new watch group
        setupWatchGroup(disabledReasonWatchGroup);
      };

      // Watching the takeoverTemplateTriggerForm and it's $error object as
      // $watchCollection only shallow watches the properties of an object
      $scope.$watchCollection(
        '$ctrl.takeoverTemplateTriggerForm', updateWatchGroup
      );
      $scope.$watchCollection(
        '$ctrl.takeoverTemplateTriggerForm.$error', updateWatchGroup
      );

      let onTtContentChanged = (nVal, oVal) => {
        $ctrl.takeoverTemplate._isValid = $ctrl.takeoverTemplate.isValid($ctrl.takeoverTemplateTriggerForm, $ctrl.splitStates);
      };

      $scope.$watchGroup([
        '$ctrl.takeoverTemplate.duration',
        '$ctrl.takeoverTemplate._targetDevices',
        '$ctrl.takeoverTemplate._resource',
      ], onTtContentChanged);

      $scope.$watch(
        '$ctrl.takeoverTemplate.slide.content', onTtContentChanged, true
      );
      $scope.$watch(
        '$ctrl.splitStates', onTtContentChanged, true
      );

      $ctrl.setDisabledMessage = function () {
        let disabledReason = [];

        if (
          $ctrl.takeoverTemplateTriggerForm &&
          _.keys($ctrl.takeoverTemplateTriggerForm.$error).length
        ) {
          disabledReason = DisabledMessage.getErrorMessageList(
            $ctrl.takeoverTemplateTriggerForm.$error
          );
        } else if (
          $ctrl.takeoverTemplate._resourceType === 'dynamic' &&
          !$ctrl.takeoverTemplateTriggerForm
        ) {
          disabledReason.push(
            'You must open the accordion before triggering this takeover'
          );
        }
        if (
          !$ctrl.takeoverTemplate._targetDevices ||
          !$ctrl.takeoverTemplate._targetDevices.length
        ) {
          disabledReason.push('No device selected to display this takeover');
        }
        if (!$ctrl.takeoverTemplate.haveStaticResource()) {
          disabledReason.push('No static resource');
        } else if (
          $ctrl.takeoverTemplate._resourceType === 'static' &&
          !$ctrl.takeoverTemplate._resource.hasRequiredContent()
        ) {
          disabledReason.push('Slide content is invalid');
        }
        if ($ctrl.takeoverTemplate.dateInPast()) {
          disabledReason.push('Show until date is in the past');
        }
        if (
          $ctrl.takeoverTemplate.resourceType === 'dynamic' &&
          $ctrl.takeoverTemplate.resource &&
          !$ctrl.takeoverTemplate.resource.sessionPermitsUse()
        ) {
          disabledReason.push('Your user role does not have permission to ' +
            'create content using the template that has been selected for this ' +
            'dynamic takeover.');
        }

        if (disabledReason.length) {
          disabledReason = disabledReason.map((m)=> `<li>${m}</li>`);
          disabledReason.unshift('You cannot trigger this takeover because:<ul>');
          disabledReason.push('</ul>');
        }
        $ctrl.triggerButtonDisabledReason = disabledReason.join('');
      };

      $ctrl.disablePreview = () => {
        // NOTE: This form includes the trigger date/time. It's hard to have an
        // invalid time so this shouldn't be an issue for more than 2.5s.
        if (!$ctrl.takeoverTemplateTriggerForm) return false;
        return $ctrl.takeoverTemplate._resource === undefined ||
          $ctrl.takeoverTemplateTriggerForm.$valid !== true ||
          $ctrl.previewVisible();
      };

      /**
       * Stores the image position data on the TT, triggers any active previews to
       * be updated and dirties the TT edit form.
       *
       * @param {String} fieldName The name of the original media field
       * @param {Object} position The position object provided by the input
       */
      $ctrl.updateImage = (fieldName, position) => {
        $ctrl.takeoverTemplate.slide.content[
          $ctrl.locale
        ].data.anyOrientation['p_' + fieldName] = position;
        // Update the preview if open.
        $rootScope.$broadcast('update-image-background-' + fieldName, position);
        // Dirty the form
        $ctrl.takeoverTemplate.setPreviewModified(true);
        // persist changes
        if (!$ctrl.takeoverTemplate.slide.fields['p_' + fieldName]) {
          $ctrl.takeoverTemplate.slide.fields['p_' + fieldName] = {};
        }
        $ctrl.takeoverTemplate.slide.fields['p_' + fieldName].default = position;
        $ctrl.takeoverTemplate.slide.fields['c_' + fieldName].default =
          $ctrl.takeoverTemplate.slide.content[
            $ctrl.locale
          ].data.anyOrientation['c_' + fieldName];
      };
    }
  }
});
