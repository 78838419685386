angular.module('bulkActions', [])

/**
 * @Desc: Bulk option button component.
 */
.component('bulkOptionButton', {
  templateUrl: 'utility/bulkOptionButton.jade',
  bindings: {
    resources: '='
  },
  controllerAs: 'vm',
  controller: function bulkOptionButtonCtrl (
    $scope, $element, $attrs, $rootScope, $timeout, Modal, Toast,
    Slides, Channels, Playlists, Feeds, Groups, Searches, $q
  ) {
    var vm = this;

    const CAN_EXPORT_MAX = 3;

    vm.multiSelectEnabled = false;
    vm.noSelected = true;
    vm.minResourceLength = 1;
    vm.allSelected = false;
    vm.selectedCount = 0;
    vm.canExportCount = 0;

    // Map for calling delete method on modal
    var map = {
      slide: Slides,
      channel: Channels,
      playlist: Playlists,
      feed: Feeds,
      group: Groups,
      search: Searches
    };

    let deregisterListeners = [];

    deregisterListeners.push(
      $rootScope.$on('bulk-delete:requestCurrentState', function () {
        $rootScope.$broadcast(
          'bulk-delete:currentState',
          vm.multiSelectEnabled
        );
      })
    );

    // Disable multi select if the escape key is pressed
    function disableOnKeydown (e) {
      if (vm.multiSelectEnabled) {
        $scope.$apply(function () {
          // eslint-disable-next-line no-magic-numbers
          if (e.keyCode === 27) { // 27 = esc key
            disableMultiSelect();
          }
        });
      }
    }
    document.addEventListener('keydown', disableOnKeydown);

    /**
     * Removes event listener 'keydown'.
     *
     * @returns {Undefined} - Nothing is returned.
     */
    function removeEListener () {
      document.removeEventListener('keydown', disableOnKeydown);
    }

    // Updates delete selected button availability after toggling a single item.
    deregisterListeners.push(
      $rootScope.$on('bulk-delete:runCheck', function () {
        countSelected();
      })
    );

    // Toggles multi mode when long press was held on a resource
    deregisterListeners.push(
      $rootScope.$on('bulk-delete:toggle-multi-select', function (e, resource) {
        if (!vm.enoughResources()) {
          $rootScope.$emit('bulk-delete:not-enough-resources');
          return;
        }
        vm.multiSelectEnabled = !vm.multiSelectEnabled;
        // Deselect all resources when mass delete disabled.
        if (!vm.multiSelectEnabled) {
          vm.setAllStatesTo(vm.multiSelectEnabled);
        } else if (resource) {
          $rootScope.$broadcast('bulk-delete:select-resource', resource);
          countSelected();
        }
        messageFn();
      })
    );

    // When any new resource create button clicked disables multi select
    deregisterListeners.push(
      $rootScope.$on('bulk-delete:disableMultiSelect', function () {
        vm.setAllStatesTo(false);
        disableMultiSelect();
      })
    );

    function setResourceType () {
      _.each(vm.resources, function (r) {
        vm.resourceType = r.resourceType;
        return false;
      });

      // Number of resources needs to be more than minResourceLength to enable
      // bulk options button.
      // Due to local search always available on page:
      // when resourceType = search : min resource should be more than 2.
      vm.minResourceLength = vm.resourceType === 'search' ? 2 : 1;
    }

    // Broadcast to the rest of the app toggling "mass state".
    function messageFn () {
      $rootScope.$broadcast(
        'bulk-delete:toggledMultiSelect', vm.multiSelectEnabled
      );
    }

    // Counts the selected resources and sets scope variables
    function countSelected () {
      vm.allSelected = false;
      vm.canExportCount = 0;
      vm.selectedCount = vm.resources.filter((r) => {
        if (r.selected && vm.resourceType === 'slide') {
          // SLIDE specific only - update can export flag
          if (r.template && r.template.webshot.dynamic) {
            vm.canExportCount += 1;
          }
        }
        return r.selected;
      }).length;

      if (vm.selectedCount) vm.multiSelectEnabled = true;

      vm.noSelected = vm.selectedCount === 0;

      // Get the number of shared resources
      let sharedResourceCount =
        vm.resources.filter((r) => r._sharedWithMe).length;

      // If all resources has been selected updates allSelected property to
      // disable select all button in dropdown
      // Note: subtract shared resource count as they shouldn't be selected for
      // deletion
      vm.allSelected =
        vm.selectedCount === vm.resources.length - sharedResourceCount;

      // Notify counter directive the number of items selected.
      $timeout(function () { // $timeout to add counter directive to DOM first
        $rootScope.$broadcast('bulk-delete:selectedCount', {
          all: vm.resources.length,
          selected: vm.selectedCount,
          shared: sharedResourceCount
        });
      });
    }

    // Basic setup, disables multi select state.
    function disableMultiSelect () {
      vm.setAllStatesTo(false);
      vm.multiSelectEnabled = false;
      countSelected();
      messageFn();
    }

    /**
     * Returns a boolean to indicate whether there are enough resources to enter
     * into multi select mode.
     * Also checking for shared resources as they cannot be deleted.
     *
     * @returns {Boolean} - TRUE if multi select can be enabled.
     */
    vm.enoughResources = function () {
      let notShared = vm.resources.filter((r) => !r._sharedWithMe);
      return notShared.length >= vm.minResourceLength + 1;
    };

    // Toggles checkboxes on items
    vm.setMultiSelect = function (value) {
      vm.multiSelectEnabled = value;
      // Sets resources selected property to false after multi select disabled.
      if (!vm.multiSelectEnabled) {
        vm.setAllStatesTo(false);
        return;
      }
      countSelected();
      messageFn();
    };

    // Selects/deselects all resources
    vm.setAllStatesTo = function (select) {
      if (!vm.multiSelectEnabled && select) {
        vm.multiSelectEnabled = true;
      }
      if (!vm.resourceType) setResourceType(); // Make sure resource type is set
      // Toggles all resources selected property to true or false
      if (vm.resourceType !== 'search') {
        _.each(vm.resources, function (resource) {
          // Only select when not shared
          if (!resource._sharedWithMe) resource.selected = select;
          return true;
        });
      } else {
        // There is a local search which gets updated and saved when a user
        // stores a search. It doesn't have created property, this prevents from
        // deleting it.
        _.each(vm.resources, function (resource) {
          if (resource.created) {
            resource.selected = select;
          }
          return true;
        });
      }
      countSelected();
      $rootScope.$broadcast('bulk-delete:toggledAll', select);
      messageFn();
    };

    // SLIDES ONLY - Export multiple slides as images
    vm.exportAsImage = () => {
      const selectedSlides = vm.resources.filter((s) =>
        // Filter non selected slides and those that cannot be exported
        s.selected && s.template && s.template.webshot.dynamic
      );

      let exportModal = null;
      let exportPromises = [];

      exportModal = new Modal({
        templateUrl: 'utility/modal-templates/slides-export-to-image.jade'
      });

      function exportImageFn (orientation) {
        // Disable multi select and return if export modal was cancelled
        if (exportModal.scope.cancelled) {
          disableMultiSelect();
          return;
        }

        exportModal.setScopeData({
          exporting: true,
        });

        // Batch out requests when more than the max allowed slides have been
        // selected
        let tooManySelected = selectedSlides.length > CAN_EXPORT_MAX;

        let batch = tooManySelected
          ? selectedSlides.splice(0, CAN_EXPORT_MAX)
          : selectedSlides;

        batch.forEach((s) => {
          let exportOrientation;

          if (s.orientations[orientation]) {
            exportOrientation = orientation;
          } else {
            // Some slides might only have one orientation enabled which isn't
            // the preferred one, updating it if necessary.
            const orientations = ['landscape', 'portrait'];
            exportOrientation = _.without(orientations, orientation)[0];
          }

          exportPromises.push(
            s.exportAsImage({orientation: exportOrientation})
          );
        });

        $q.all(exportPromises)
        .finally(function () {
          if (tooManySelected && !exportModal.scope.cancelled) {
            exportPromises = [];
            exportImageFn(orientation);
            return
          }
          exportModal.resolve();
          disableMultiSelect();
        });
      }

      const anyHasBothOrientations = selectedSlides.some((s) =>
        s.orientations.landscape && s.orientations.portrait
      );

      exportModal.setScopeData({
        bulk: selectedSlides.length > 1, // Used to pluralize
        export: exportImageFn,
        exporting: !anyHasBothOrientations,
        cancel: function () {
          if (exportPromises.length) {
            // Note that cancel is implemented by slide.exportAsImage
            exportPromises.forEach((p) => p.cancel());
          }
          // Set flag to let batch export know modal was cancelled
          exportModal.setScopeData({
            cancelled: true
          });
          exportModal.reject('cancel');
        }
      });

      if (!anyHasBothOrientations) {
        // No selected slide ha both orientations enabled, start exporting right
        // away
        exportImageFn(
          selectedSlides[0].orientations.landscape ? 'landscape' : 'portrait'
        )
      }

      exportModal
        .show()
        .then(()=> {}) // Nothing to do if everything's fine
        .catch((err)=> {
          Toast.makeError(err);
        })
    }

    // Mass delete fn
    vm.multiDelete = function () {
      let idArray = vm.generateSelectedArray();

      if (!vm.resourceType) setResourceType(); // Make sure resource type is set

      // defines the modal
      let modalOpts = {
        templateUrl: 'utility/modal-templates/modal-massactionconfirm.jade',
        scopeData: {
          action: 'delete',
          itemType: vm.formatResourceType(),
          numItems: idArray.length
        },
        dismissable: {
          backButton: false, escape: true, backgroundClick: false
        }
      };

      new Modal(modalOpts).show().then(function () {
        // When the type of resource defined calls delete method with items.
        return map[vm.resourceType] ?
        map[vm.resourceType].delete(idArray) :
        $q.rejectedPromise();
      }) // success toast
      .then(function () {
        Toast.makeSuccess('Successfully deleted selected items');
        disableMultiSelect();
      }) // error toast
      .catch(function (e) {
        Toast.makeError(e);
      });
    };

    // Generates the array of Ids to be deleted
    vm.generateSelectedArray = function () {
      return vm.resources
        .filter((i)=> i.selected)
        .map((i)=> i._id);
    };

    // Returns resourceType string to display on modal
    vm.formatResourceType = function () {
      let idArray = vm.generateSelectedArray();
      if (!vm.resourceType) setResourceType(); // Make sure resource type is set
      if (vm.resourceType === 'search' && idArray.length > 1) {
        return 'searche';
      } else {
        return vm.resourceType;
      }
    };

    // Deregistering listeners.
    $scope.$on('$destroy', function () {
      disableMultiSelect();
      // When we navigate away remove event listener
      removeEListener();
      _.each(deregisterListeners, function (listener) {
        listener();
      });
    });

    vm.$onInit = function () {
      deregisterListeners.push(
        $scope.$watch('vm.resources.length',
          function (newVal, oldVal) {
            // Update allSelected property to let users select all resources. E.g.
            // if they had none to begin with then created let's say 2 playlists,
            // vm.allSelected would still be true and `Select all` button disabled
            // newValue === 1 => 1 because we don't want to set this variable
            // every time our array changes.
            if (newVal && newVal !== oldVal && newVal === 1) {
              vm.allSelected = false;
            }
          }
        )
      );

      // Basic setup after resources loaded
      vm.resources.promise.then(function () {
        disableMultiSelect();
        setResourceType();
      });
    }
  }
})

/**
 * @Desc: Checkboxes for resource items.
 */
.directive('bulkDeleteCheckbox', function (
  $rootScope, $timeout, Session
) {
  return {
    scope: {
      resource: '='
    },
    controllerAs: 'vm',
    bindToController: true,
    controller: function bulkDeleteCtrl (
      $scope, $element, $attrs
    ) {
      var vm = this;
      var parentEl = $element.parent();

      let deregisterListeners = [];

      let permissionFilters;

      vm.$onInit = function () {
        // Timeout for Slides page, otherwise when we change page via the
        // pagination controls the last item doesn't get the highlighted class and
        // the event listener on any page.
        $timeout(function () {
          // Requesting current state after element added to the DOM
          $rootScope.$broadcast('bulk-delete:requestCurrentState');
        });


        permissionFilters = Session.filtersForPermissions(
          "content." + vm.resource.resourceType + "s.delete"
        );
      }

      // Sets multi mode state, applies classes and listeners
      deregisterListeners.push($rootScope.$on('bulk-delete:currentState',
        function (e, state) {
          vm.multiSelectEnabled = state;
          toggleEventListeners();
          toggleSelectedClass();
        })
      );

      let canUseResource = () => {
        // If we don't have any filters to apply then we can use the resource.
        if (!permissionFilters) {
          return true;
        }

        // Start false and update as required
        let canUse = false;

        // Check inclusion via id
        if (
          permissionFilters._id.length &&
          _.includes(permissionFilters._id, vm.resource._id)
        ) {
          canUse = true;
        }

        // Check inclusion via tag
        if (permissionFilters.tags.length) {
          // We have tags lets see if our resource contains any of them
          // eslint-disable-next-line consistent-return
          _.each(permissionFilters.tags, (filterTag, key) => {
            if (_.contains(vm.resource.tags, filterTag)) {
              canUse = true;
              return false;
            }
          });
        }

        if (permissionFilters.owner && vm.resource.ownedByMe()) {
          canUse = true;
        }

        return canUse;
      };

      // Event listener fn, toggles selected property of resource item when
      // card or list item clicked.
      function selectByClickingCard () {
        if (canUseResource()) {
          if (vm.resource._sharedWithMe) return;
          $scope.$apply(function () {
            vm.resource.selected = !vm.resource.selected;
            $rootScope.$emit('bulk-delete:runCheck', vm.resource);
          });
          toggleSelectedClass();
        }
      }

      // When multiselect has been toggled adds/removes checkboxes to resources
      deregisterListeners.push($rootScope.$on(
        'bulk-delete:toggledMultiSelect', function (event, isEnabled) {
          if (vm.resource._sharedWithMe) return;
          vm.multiSelectEnabled = isEnabled;
          toggleEventListeners();
        }));

      // Toggles selected class on card or list item when all resources toggled
      deregisterListeners.push(
        $rootScope.$on('bulk-delete:toggledAll', function (event, value) {
          if (vm.resource._sharedWithMe) return;
          vm.multiSelectEnabled = true;
          vm.resource.selected = value;
          toggleSelectedClass();
        }));

      deregisterListeners.push(
        $rootScope.$on('bulk-delete:select-resource', function (e, id) {
          if (vm.resource._id !== id || vm.resource._sharedWithMe) return;
          if (canUseResource()) {
            vm.resource.selected = true;
            toggleEventListeners();
            toggleSelectedClass();
          }
        }));

      // Adds/removes event listener to toggle resource.selected via clicking
      // on the card or list item
      function toggleEventListeners () {
        if (vm.multiSelectEnabled) {
          parentEl[0].addEventListener('click', selectByClickingCard);
        } else {
          parentEl[0].removeEventListener('click', selectByClickingCard);
          toggleSelectedClass(false);
        }
      }

      // Toggles selected class
      function toggleSelectedClass (value = true) {
        if (value && vm.resource.selected) {
          parentEl[0].classList.add('selected-resource');
        } else {
          parentEl[0].classList.remove('selected-resource');
        }
      }

      // When individual checkbox has been clicked updates delete selected btn
      vm.toggled = function () {
        $rootScope.$emit('bulk-delete:runCheck', true);
        toggleSelectedClass();
      };

      // Deregistering listeners
      $scope.$on('$destroy', function () {
        _.each(deregisterListeners, function (listener) {
          listener();
        });
      });
    },
    template: `<pxn-checkbox
      ng-if="!vm.resource._sharedWithMe"
      ng-click="$event.stopPropagation()"
      ng-change="vm.toggled()"
      ng-model="vm.resource.selected"
      class="mass-delete center-self-xs"
      icon="check"
      ng-disabled="disableAll"></pxn-checkbox>`,
  };
})

.directive('bulkDeleteCounter', function ($rootScope) {
  return {
    template: `
      <h3>{{count.selected}} of {{count.all - count.shared}} selected</h3>
    `,
    controller: function bulkDeleteCounterCtrl ($scope) {
      // Initial count object
      $scope.count = {
        all: 0,
        selected: 0
      };

      // Update counter
      let deregister = $rootScope.$on('bulk-delete:selectedCount',
        function (e, count) {
          $scope.count = count;
        });

      $scope.$on('$destroy', deregister);
    }
  };
})

.directive('longTouch', function ($rootScope) {
  return {
    scope: {
      longTouch: '&'
    },
    controllerAs: 'ctrl',
    bindToController: true,
    controller: function longTouchCtrl (
      $scope, $element, $attrs, $timeout
    ) {
      let ctrl = this;

      let deregisterListeners = [];

      const LONG_TOUCH = 1000; // milliseconds

      ctrl.multiMode = false;

      // Adding event listeners to every element.
      $element[0].addEventListener('touchstart', touchStart, false);
      $element[0].addEventListener('touchend', cancelTimeout, false);
      $element[0].addEventListener('touchmove', cancelTimeout, false);

      // When multi mode changes we get notified as well.
      deregisterListeners.push(
        $rootScope.$on('bulk-delete:toggledMultiSelect', function (e, v) {
          ctrl.multiMode = v;
        })
      );

      deregisterListeners.push(
        $rootScope.$on('bulk-delete:not-enough-resources', () => {
          ctrl.multiMode = false;
        })
      );

      /**
       * Set timeout on touch start to call long touch function.
       *
       * @param {event} e - Event object.
       * @returns {Undefined} - Nothing is returned.
       */
      function touchStart (e) {
        ctrl.timeoutPromise = $timeout(toggleOnLongTouch, LONG_TOUCH);
      }

      /**
       * Function gets called on touch start.
       *
       * @param {event} e - Event object.
       * @returns {Undefined} - Nothing is returned.
       */
      function cancelTimeout () {
        $timeout.cancel(ctrl.timeoutPromise);
      }

      /**
       * Trigger long touch function.
       *
       * @returns {Undefined} - Nothing is returned.
       */
      function toggleOnLongTouch () {
        ctrl.longTouch();
        ctrl.timeoutPromise = null;
      }

      // Deregister listeners
      $scope.$on('$destroy', () => {
        _.each(deregisterListeners, (listener) => {
          listener();
        });
      });
    }
  };
})

/**
 * @Desc: Directive to listen to multiSelectEnabled value changes and toggle
 * it's state on the given controller.
 */
.directive('setMassDeleteStateOn', function ($rootScope) {
  return {
    scope: {
      setMassDeleteStateOn: '='
    },
    controllerAs: 'ctrl',
    bindToController: true,
    controller: function setMassDeleteStateOnCtrl (
      $scope, $element, $attrs
    ) {
      let ctrl = this;
      let deregisterListeners = [];

      ctrl.$onInit = function () {
        ctrl.setMassDeleteStateOn.multiSelectEnabled = false;
      }

      deregisterListeners.push(
        $rootScope.$on('bulk-delete:toggledMultiSelect', function (e, data) {
          ctrl.setMassDeleteStateOn.multiSelectEnabled = data;
        })
      );

      // Set multiSelectEnabled to false on new resource creation.
      deregisterListeners.push(
        $rootScope.$on('bulk-delete:createResource', function () {
          if (ctrl.setMassDeleteStateOn.multiSelectEnabled) {
            ctrl.setMassDeleteStateOn.multiSelectEnabled = false;
            $rootScope.$broadcast('bulk-delete:disableMultiSelect');
          }
        })
      );

      $scope.$on('$destroy', () => {
        _.each(deregisterListeners, (listener) => {
          listener();
        });
      });
    }
  };
});