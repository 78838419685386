angular.module('pxnScheduling', [])

.factory('Scheduling', function (UtcFunctions) {
  /**
   * Attempts to reduce an array of ranges by combining the range at
   * the given index with another overlapping range.
   * @param {object[]} ranges Array of ranges as: { start: x, end: y }
   * @param {number} i Index of the range to base the reduction on
   * @returns {boolean} True if a reduction was made, false otherwise.
   */
  function makeRangeReduction (ranges, i) {
    const baseRange = ranges[i];
    return _.any(ranges, (compareRange, j) => {
      // Don't compare with self.
      if (i === j) return null;
      if (
        // We can reduce two ranges if the start of the comparative
        // range lies within our base range.
        (compareRange.start || 0) >= (baseRange.start || 0) &&
        (compareRange.start || 0) <= (baseRange.end || Infinity)
      ) {
        // Remove the existing ranges and add a new range bounded
        // by the highest end.
        _.pullAt(ranges, i, j);
        const end = !baseRange.end || !compareRange.end ?
          null : Math.max(baseRange.end, compareRange.end)
        ranges.unshift({
          start: baseRange.start,
          end: end
        });
        return true;
      } else {
        return false;
      }
    });
  }

  /**
   * Recursively reduces an array of ranges to remove all overlaps.
   * @param {object[]} ranges Array of ranges as: { start: x, end: y }
   * @returns {object[]} The reduced array of ranges
   */
  function reduceRanges (ranges) {
    let reduced = false;
    // Attempt to find reductions based on one range at a time.
    for (let i = 0; i < ranges.length; i++) {
      if (reduced) break;
      reduced = makeRangeReduction(ranges, i);
    }
    // If a reduction was made and there are still multiple ranges, we
    // need to start the process again as the array length has changed
    // and there may be further possible reductions.
    if (reduced && ranges.length > 1) {
      return reduceRanges(ranges);
    } else {
      // No more reductions, sort the ranges into order and return.
      return _.sortBy(ranges, 'start');
    }
  }

  /**
   * Gets arrays of recurrence ranges keyed by day for the provided
   * recurrence rules.
   * @param {object} slide - The slide.
   * @returns {[object]} - Reduced recurrence rules array.
   */
  function getRecurrenceRanges (slide) {
    const days = {};
    const negatedUtcOffsetRules =
      negateUtcOffsetRecurrenceRules(slide.recurrence);
    _.each(negatedUtcOffsetRules, (rule) => {
      // Add relevant ranges for each day of each rule.
      _.each(rule.days, (day) => {
        days[day] = days[day] || [];
        if (rule.start < rule.end) {
          // Contained rule - push to the same day.
          days[day].push({ start: rule.start, end: rule.end });
        } else {
          // Spillover rule - if the rule ends at midnight we just push
          // a range to the same day, otherwise we need to split into
          // two ranges covering the same day and the next day.
          days[day].push({ start: rule.start, end: 1440 });
          if (rule.end > 0) {
            let nextDay = (day + 1) % 7;
            days[nextDay] = days[nextDay] || [];
            days[nextDay].push({ start: 0, end: rule.end });
          }
        }
      });
    });
    // Compact and return each day's array of ranges.
    _.each(days, (ranges, day) => {
      days[day] = reduceRanges(ranges);
    });
    return days;
  }

  /**
   * Negates the UTC offset from the recurrence rules, if required then returns
   * the transformed recurrence rules array.
   *
   * @param {object} recurrence - The slide's recurrence object
   * @returns {object[]} - Local recurrence rules
   */
  function negateUtcOffsetRecurrenceRules (recurrence) {
    const negatedUtcOffsetRecurrenceRules = [];
    // Store negated recurrence UTC offset rules to keep it DRY
    if (recurrence.enabled) {
      _.each(recurrence.rules, function (rRule) {
        let rule = _.clone(rRule);
        if (recurrence.isGlobalBasis) {
          rule = negateRecurrenceUtcOffset(rule, recurrence.timezone);
        }
        negatedUtcOffsetRecurrenceRules.push(rule);
      });
    }
    return negatedUtcOffsetRecurrenceRules;
  }

  /**
   * Get array of reduced lifetime ranges for the provided rules.
   * Ranges are ordered by their start, expired rules are only included if
   * `includeExpiredRules` is true.
   *
   * @param {object} slide - The slide
   * @param {boolean} includeExpiredRules - Flag to include expired rules.
   * @returns {object} - Reduced slide lifetime ranges.
   */
  function getLifetimeRanges (slide, includeExpiredRules) {
    // Lifetime not enabled, return empty array
    if (!slide.lifetime.enabled) return [];

    // Get a copy of the slide with UTC lifetime rules converted to local
    slide = convertSlideLifetimeToLocal(slide);

    return (
      _.filter(
        // If lifetime enabled filter out rules that already expired
        _.sortBy(
          // Sort array by rule start, null start rule will be first item in
          // array
          reduceRanges(_.cloneDeep(slide.lifetime.rules)),
          ({start}) => start === null || start
        ),
        // Only return lifetime rules that haven't expired yet
        (rule) => !rule.end || (
          // To include expired rules as well
          includeExpiredRules ? true : moment().isBefore(rule.end)
        )
      )
    );
  }

  /**
   * Returns the last lifetime end for the received slide, null if there is a
   * lifetime with no end.
   *
   * @param {object} slide - The slide
   * @returns {moment | null} - Last lifetime end or null if open ended
   */
  function getLastLifetimeEnd (slide) {
    // Passing true to have expired rules included in our lifetime ranges
    const lifetimeRanges = getLifetimeRanges(slide, true);

    // Just a precaution to avoid any errors thrown by the next line
    if (!lifetimeRanges.length) return;

    // Lifetime ranges are ordered by their start
    let lastLifetimeEnd = lifetimeRanges[lifetimeRanges.length - 1].end;

    // Return value if found, or null if open ended lifetime.
    return lastLifetimeEnd || null;
  }

  /**
   * Returns when was the slide last online.
   *
   * @param {object} slide - Slide
   * @returns {moment} - Moment when the slide went offline, or null if
   * the slide was never online.
   */
  function getLastOnline (slide) {
    const lifetimeRanges = getLifetimeRanges(slide, true);
    const lastLifetimeRange = lifetimeRanges[lifetimeRanges.length - 1];
    // Recurrence not enabled, return last lifetime end
    if (!slide.recurrence.enabled) return lastLifetimeRange.end;

    const recurrenceRanges = getRecurrenceRanges(slide);
    const offlineChanges = [];

    // It is possible the last recurrence happens outside of the last lifetime
    // range, so we get the last recurrence for every lifetime ranges and return
    // the latest change the slide went offline
    _.each(lifetimeRanges, (lRange) => {
      let lRangeEnd = moment(lRange.end);
      let lRangeStart = lRange.start ? moment(lRange.start) : null;

      const lifetimeEndDay = lRangeEnd.day();

      for (i = 0; i < 8; i++) {
        // Add 7 when decrementing days to not go below 0, then get modulus
        const day = (lifetimeEndDay + 7 - i) % 7;

        // Continue loop if we don't have any recurrence rules for the current
        // day
        if (!recurrenceRanges[day] || !recurrenceRanges[day].length) continue;

        _.eachRight(recurrenceRanges[day], (rRange) => {
          // If the recurrence range starts at or after the lifetime end,
          // ignore it.
          var rRangeStart = lRangeEnd
            .clone()
            .startOf('day')
            .subtract(i, 'days')
            .add(rRange.start, 'minutes');
          if (rRangeStart.isSameOrAfter(lRangeEnd)) return;
          // If the recurrence range ends at or before the lifetime start,
          // ignore it.
          var rRangeEnd = lRangeEnd
            .clone()
            .startOf('day')
            .subtract(i, 'days')
            .add(rRange.end, 'minutes');
          if (rRangeEnd.isSameOrBefore(lRangeStart)) return;

          // Get the minimum of the recurrence range end and the lifetime
          // range end.
          offlineChanges.push(moment.min([rRangeEnd, lRangeEnd]));
          return false;
        });
      }
    });
    // Return the latest offline change found, or null.
    return offlineChanges.length ? moment.max(offlineChanges) : null;
  }

  /**
   * Utility function to adjust recurrence rule's start or end time
   * @returns An object containing the new minutes past midnight value and a
   * shiftDays field to indicate whether the recurrence rule's days array need
   * to be adjusted as well.
   * `-1` - Days are shifted 1 day before
   * `0`  - Days aren't affected
   * `1`  - Days are shifted a day ahead
   */
  function getRecurrenceUtcMinsUpdate (mins, offset) {
    let minsPastUtcMidnight = mins - offset;
    let shiftDays = 0;

    if (minsPastUtcMidnight < 0) {
      // Minutes past midnight value is now a day before add the negative value
      // to 1440 (minutes in a day)
      minsPastUtcMidnight = 1440 + minsPastUtcMidnight;
      shiftDays = -1; // Flag to shift rule days 1 day back
    } else if (minsPastUtcMidnight >= 1440) {
      // New value is in the net day now, subtract 1 days worth of minutes
      minsPastUtcMidnight = minsPastUtcMidnight - 1440;
      shiftDays = 1; // Flag to shift rule days 1 day forward
    }

    return {
      minutes: minsPastUtcMidnight,
      shiftDays: shiftDays
    }
  }

  /**
   * Adjusts the given recurrence rules start and end time based on the UTC
   * offset of the timezone provided.
   * @returns The updated recurrence rule.
   */
  function negateRecurrenceUtcOffset (rule, timezone) {
    const targetOffsetMins = moment.tz(timezone).utcOffset();

    const localOffsetMins = moment().utcOffset();

    // Get the update objects
    let startUpdate = getRecurrenceUtcMinsUpdate(
      rule.start, targetOffsetMins - localOffsetMins
    );
    let endUpdate = getRecurrenceUtcMinsUpdate(
      rule.end, targetOffsetMins - localOffsetMins
    );

    // Assign new start/end values
    rule.start = startUpdate.minutes;
    rule.end = endUpdate.minutes;

    // Only need to shift days array once when either or both start/end update
    // objects has a non zero `shiftDays` flag
    if (startUpdate.shiftDays === 1 || endUpdate.shiftDays === 1) {
      rule.days = rule.days.map((day) => day === 6 ? 0 : day + 1);
    } else if (startUpdate.shiftDays === -1 || endUpdate.shiftDays === -1) {
      rule.days = rule.days.map((day) => day === 0 ? 6 : day - 1);
    }

    return {
      start: rule.start,
      end: rule.end,
      days: rule.days
    }
  }

  /**
   * Converts the received slide's lifetime to local time.
   *
   * @param {object} slide - The slide
   * @returns {object} - Clone of the slide with its lifetime rules converted to
   *                     local time
   */
  function convertSlideLifetimeToLocal (slide) {
    // Clone the slide before converting to avoid changing the original
    slide = _.cloneDeep(slide);
    // Does not convert the slide's lifetime if the schedule basis is global
    if (!slide.lifetime.isGlobalBasis) {
      UtcFunctions.convertLifetimeToLocal(slide);
    }
    return slide;
  }

  /**
   * Returns whether the given date is within any of a slide's lifetime rules
   * (defaults to now if date is not provided).
   * @param {object} slide The slide
   * @param {object} when The date to check against
   * @returns {boolean} True if the date is within the slide's lifetime rules
   */
  function isWithinLifetimeRules (slide, when) {
    if (!slide.lifetime.enabled) return true;

    // Get a copy of the slide with UTC lifetime rules converted to local
    slide = convertSlideLifetimeToLocal(slide);

    const nowOrWhen = moment(when);

    return _.any(slide.lifetime.rules, function (rule) {
      const {start, end} = rule;
      return (
        (!start || nowOrWhen.isSameOrAfter(start)) &&
        (!end || nowOrWhen.isBefore(end))
      );
    });
  }

  /**
   * Returns whether the given date is within any of a slide's recurrence rules
   * (defaults to now if date is not provided).
   * @param {object} slide The slide
   * @param {object} when The date to check against
   * @returns {boolean} True if the date is within the slide's recurrence rules
   */
  function isWithinRecurrenceRules (slide, when) {
    if (!slide.recurrence.enabled) return true;

    const nowOrWhen = moment(when);

    return _.any(slide.recurrence.rules, function (rule) {
      let rRule = _.clone(rule);

      if (slide.recurrence.isGlobalBasis) {
        rRule = negateRecurrenceUtcOffset(rRule, slide.recurrence.timezone);
      }

      let now = (nowOrWhen.hours() * 60) + nowOrWhen.minutes();
      let rToday = _.contains(rRule.days, nowOrWhen.day());
      let rYesterday = _.contains(
        rRule.days,
        nowOrWhen.clone().subtract(1, 'days').day()
      );
      if (rRule.start < rRule.end) {
        // Contained recurrence rule.
        if (rToday && now >= rRule.start && now < rRule.end) {
          return true;
        }
      } else {
        // Spillover recurrence rule.
        if (rToday && now >= rRule.start || rYesterday && now < rRule.end) {
          return true
        }
      }
      return false;
    });
  }

  /**
   * Determine whether a slide is online at the given date (defaults to now if
   * date is not provided).
   * @param {object} slide The slide
   * @param {object} when The date to check against
   * @returns {boolean} True if the slide is online
   */
  function isOnline (slide, when) {
    const nowOrWhen = moment(when);
    return (
      isWithinLifetimeRules(slide, nowOrWhen) &&
      isWithinRecurrenceRules(slide, nowOrWhen)
    );
  }

  /**
   * Updates the slide's _timeToNext property.
   *
   * @param {*} newTimeToNext - New time to next change
   * @param {*} slide - Slide
   * @returns {void}
   */
  function updateTimeToNext (newTimeToNext, slide) {
    // Delete slide._timeToNext property to show modal when slide recurrence or
    // lifetime gets updated and the slide would never become online with the
    // selected times
    if (!newTimeToNext && slide._timeToNext) {
      delete slide._timeToNext;
      return;
    }
    if (moment.isMoment(newTimeToNext)) newTimeToNext = newTimeToNext.valueOf();
    if (newTimeToNext && slide._timeToNext !== newTimeToNext) {
      slide._timeToNext = newTimeToNext;
    }
  }

  /**
   * Check's if a given recurrence range is impacted by a transition to DST and
   * adjusts it accordingly.
   * @param {object} range The recurrence range.
   * @param {moment} startOfDay The start of the day the range is for
   * @returns {object} The adjusted range, or null if the range is invalid.
   */
  function adjustRangeForDST (range, startOfDay) {
    // If the recurrence range intersects with the boundary where the timezone
    // transitions into DST, it may need to be adjusted or invalidated. This is
    // because a "dead-zone" is created where a recurrence rule can't be shown,
    // e.g. between 01:00 - 02:00 in the UK.
    // Note: this issue doesn't exist for transitions out of DST, instead an
    // intersecting recurrence rule will be valid twice - this is fixed by the
    // receiver re-evaluating scheduling at the boundary point.
    const endOffset = startOfDay.clone().add(range.end, 'minutes').utcOffset();
    const offsetChange = endOffset - startOfDay.utcOffset();
    // No adjustment needed if offset isn't positive.
    if (offsetChange <= 0) return range;
    // We are transitioning to DST before or during the range.
    // Find the boundary start and end minutes.
    let boundaryStart, boundaryEnd;
    for (let i = 0; i <= 1440; i++) {
      const when = startOfDay.clone().add(i, 'minutes');
      if (when.utcOffset() === endOffset) {
        boundaryStart = i;
        boundaryEnd = i + offsetChange;
        break;
      }
    }
    // Adjust based on how the range does or doesn't overlap the boundary times.
    if (range.start >= boundaryEnd) {
      // Range is after boundary, no change required.
    } else if (range.start < boundaryStart && range.end > boundaryEnd) {
      // Boundary contained within range, no change required
    } else if (range.start >= boundaryStart && range.end <= boundaryEnd) {
      // Range contained with boundary ("dead-zone"), range invalid.
      range = null;
    } else if (range.end <= boundaryEnd) {
      // End is within boundary, shift end to boundary start.
      range.end = boundaryStart;
    } else {
      // Start is within boundary, shift start to boundary end.
      range.start = boundaryEnd;
    }
    return range;
  }


  /**
   * Return the next recurrence change when the slide status changes. Can return
   * null if the slide never go offline again.
   *
   * @param {object} recurrenceRanges - The slide's compacted recurrence ranges.
   * @param {moment} after - Time to search the next change from, defaults to
   *                         now.
   * @param {boolean} goOnline - Flag to indicate the status for the next change
   *                             we're looking for.
   * @returns {moment | undefined} - The next change's moment, can be undefined
   */
  function getNextRecurrenceChange (recurrenceRanges, after, goOnline) {
    const startDay = after.day();
    let minute = ((after.hours() * 60) + after.minutes());
    // Cycle through the days of the week.
    // Note: we add 1 day in case the next change is earlier in the start day,
    // then add another 7 days in case a rule is invalid for the first week due
    // to a DST change.
    for (let i = 0; i < 15; i++) {
      // Checks on any day after the first day should start at day start.
      if (i > 0) minute = 0;
      // Get modulus to stay within range.
      const day = (startDay + i) % 7;
      const dayRanges = recurrenceRanges[day];
      let minuteChange;
      _.each(dayRanges, (range) => {
        // Check if any adjustment to the range is required due to entering DST
        // (will return null if range is no longer valid).
        range = adjustRangeForDST(
          range, after.clone().startOf('day').add(i, 'days')
        )
        if (!range) return;
        // Ignore ranges that have passed.
        // Note: this is only possible for the first day.
        if (minute >= range.end) return;
        if (goOnline) {
          // Looking for next online change - find next range that hasn't
          // started yet.
          // Note: this will check against -1 in place of 0 to catch ranges
          // that start at 0.
          if ((minute || -1) < range.start) {
            minuteChange = range.start;
            return false;
          }
        } else {
          // If the range ends at midnight, check if this is a spillover
          // rule and continue to next day if it is.
          if (range.end === 1440) {
            const nextDayRanges = recurrenceRanges[(day + 1) % 7];
            if (nextDayRanges && nextDayRanges[0].start === 0) {
              // Spillover rule.
              return false;
            }
          }
          // Looking for next offline change - find the range we're in.
          if (minute >= range.start && minute < range.end) {
            minuteChange = range.end;
            return false;
          }
        }
      });
      if (minuteChange != null) {
        // This is the next change.
        // Note: we set the hours and minutes instead of adding the total
        // number of minutes to avoid offset issues at DST boundaries.
        return after.clone().startOf('day')
        .add(i, 'days')
        .hours(Math.floor(minuteChange / 60))
        .minutes(minuteChange % 60);
      }
    }
  }


  /**
   * Returns the next offline change for the given slide. Can be null if the
   * slide remains online e.g. null end lifetime with no recurrence rules.
   *
   * @param {object} slide - The slide.
   * @returns {moment | undefined} - The next offline change, can be undefined
   */
  function getNextScheduledOffline (slide) {
    // When is this slide next going offline?
    // Earliest of either:
    // - The end of the current lifetime
    // - The next offline recurrence change
    let offlineAt;
    if (slide.lifetime.enabled) {
      const lifetimeRanges = getLifetimeRanges(slide);
      const nextEnd = lifetimeRanges[0].end;
      if (nextEnd) offlineAt = moment(nextEnd);
    }
    if (slide.recurrence.enabled) {
      const recurrenceRanges = getRecurrenceRanges(slide);
      const rOfflineAt =
        getNextRecurrenceChange(recurrenceRanges, moment(), false);

      if (!offlineAt || (rOfflineAt && rOfflineAt.isBefore(offlineAt))) {
        offlineAt = rOfflineAt;
      }
    }
    return offlineAt;
  }


  /**
   * Returns the next change the slide will go online.
   *
   * @param {object} slide - The slide
   * @returns {moment | undefined}
   *  - The next change when slide is online.
   *  - Undefined, if the slide will not go online again.
   */
  function getNextScheduledOnline (slide) {
    // The slide passed to this function must be offline.
    // When is this slide next going online?
    // If lifetime not enabled, get next recurrence change online.
    // If lifetime is enabled, check through each lifetime range for:
    // - Range start if recurrence not enabled
    // - OR recurrence rules permit online at upcoming range start
    // - OR next recurrence change to online that's during range
    let onlineAt;
    const recurrenceRanges = getRecurrenceRanges(slide);
    if (!slide.lifetime.enabled) {
      // Offline due to recurrence ranges, get next range start.
      return getNextRecurrenceChange(recurrenceRanges, moment(), true);
    }
    // Offline due to lifetime and/or recurrence.
    const lifetimeRanges = getLifetimeRanges(slide);
    _.each(lifetimeRanges, (lRange) => {
      const lifetimeStarted =
        lRange.start == null || moment().isSameOrAfter(lRange.start);
      if (
        // No recurrence, so must be outside of lifetime, so first
        // lifetime will be upcoming OR
        !slide.recurrence.enabled ||
        // The lifetime start is upcoming, and recurrence allows the slide
        // to be displayed at the lifetime start.
        (
          !lifetimeStarted &&
          isWithinRecurrenceRules(slide, lRange.start)
        )
      ) {
        onlineAt = moment(lRange.start);
        return false;
      } else {
        // Lifetime start passed, or outside of recurrence ranges at lifetime
        // start.
        // Look for a recurrence change online that's within the remaining
        // lifetime range.
        const rOnlineAt = getNextRecurrenceChange(
          recurrenceRanges,
          // After time should never be in the past if we're looking for next
          // online change.
          // Lifetime range could've started a long time ago, only pass lifetime
          // range start if it's in the future.
          moment(!lifetimeStarted ? lRange.start : undefined),
          true
        );
        if (rOnlineAt && (!lRange.end || rOnlineAt.isBefore(lRange.end))) {
          onlineAt = rOnlineAt;
          return false;
        }
      }
    });
    return onlineAt;
  }


  /**
   * Get the next online or offline change (if any).
   *
   * @param {object} slide - The slide
   * @returns {moment | undefined} - The slide's next status change, can be
   *                                 undefined
   */
  function getNextScheduledChange (slide) {
    if (isOnline(slide)) {
      // Currently online, get next offline change (if any).
      return getNextScheduledOffline(slide);
    } else {
      // Currently offline, get next online change (if any).
      return getNextScheduledOnline(slide);
    }
  }

  /**
   * Get's a scheduling message for a slide's banner based on when
   * it's next going online, and sets the next schedule change time
   * on the slide.
   *
   * @param {object} slide The slide
   * @returns {string | undefined} The banner message, or undefined if
   * the slide doesn't have scheduling enabled.
   */
  function getMessage (slide) {
    // Slide has no schedule, return early
    if (!slide.lifetime.enabled && !slide.recurrence.enabled) return;

    // Get a copy of the slide with UTC lifetime rules converted to local
    let slideClone = convertSlideLifetimeToLocal(slide);

    const isOnlineNow = isOnline(slideClone);
    let next = getNextScheduledChange(slideClone);
    let last;

    // If we have no next change and the slide is offline now the slide will
    // remain offline so we need to find the last recurrence for the slide
    if (!next && !isOnlineNow) last = getLastOnline(slideClone);

    // Update the original slide's _timeToNext property to hold the next change
    // value.
    updateTimeToNext(next || last, slide);

    if (!next && !isOnlineNow) {
      // Offline since last change, or never online.
      return last ? 'Offline' : 'Offline indefinitely';
    } else if (!next && isOnlineNow) {
      // Will not go offline.
      return 'Online indefinitely';
    } else {
      // Slide status will change to the opposite on next change.
      return isOnlineNow ? 'Offline' : 'Online';
    }
  }

  return {
    isOnline: isOnline,
    getLastLifetimeEnd: getLastLifetimeEnd,
    getLastOnline: getLastOnline,
    getLifetimeRanges: getLifetimeRanges,
    getMessage: getMessage,
    getNextScheduledChange: getNextScheduledChange,
    getRecurrenceRanges: getRecurrenceRanges
  };

});