var pxnScheduleInput = {
  bindings: {
    schedules: '='
  },
  templateUrl: 'devices/scheduling/pxn-scheduling.jade',
  controller: 'pxnScheduleInputController',
  require: {
    formCtrl: '?^^form'
  }
};

angular
  .module('sender')
  .component('pxnScheduleInput', pxnScheduleInput);