angular.module(
  'flare.branding',
  [
    'genericResource',
    'config',
  ]
)

.factory(
  'Themes',
  function ThemesFactory (
    GenericResource,
    ResourceItem,
    ConfigURLs
  ) {
    class Theme extends ResourceItem {
      constructor (attributes, ignoreDefaults) {
        super(attributes, ignoreDefaults);
      }
    }

    Theme.prototype._defaults = {
      name: '',
      palette: {
        bg1: '#FFFFFF',
        bg2: '#FFFFFF',
        tc1: '#000000',
        tc2: '#000000',
        hc1: '#F00F00'
      }
    };

    var Themes = new GenericResource(ConfigURLs.themes(), Theme, {});
    return Themes;

  }
)

.controller(
  'BrandingAdminController',
  function (
    ConfigURLs,
    Uploads,
    Themes,
    Session,
    $http,
    Toast,
    SaveCheck,
    $log
  ) {
    var IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'gif', 'svg', 'bmp', 'tiff', 'png'];
    var FONT_EXTENSIONS = ['eot', 'otf', 'ttf', 'woff', 'woff2'];
    var VIDEO_EXTENSIONS = ['webm', 'ogg', 'mp4'];

    var vm = this;

    vm.uploading = [];
    vm.uploaded = [];
    vm.enableThemes = Session.current.account.themesEnabled;

    var assetOptions = {
      url: ConfigURLs.customisations()
    };

    var requestOptions = {
      forceoverwrite: true,
      forcestructure: false
    };


    var extensionRegex = /([a-zA-Z0-9]+)$/g;
    function setAssetMeta (asset) {
      var extension = asset.name.match(extensionRegex);
      if (extension) {
        extension = extension[0];
      }
      var fileType = 'other';
      var icon = 'help';
      if (_.contains(IMAGE_EXTENSIONS, extension.toLowerCase())) {
        fileType = 'image';
        icon = 'picture';
      } else if (_.contains(FONT_EXTENSIONS, extension.toLowerCase())) {
        fileType = 'font';
        icon = 'language';
      } else if (_.contains(VIDEO_EXTENSIONS, extension.toLowerCase())) {
        fileType = 'video';
        icon = 'video';
      } else if (extension === 'css') {
        fileType = 'style';
        icon = 'brush';
      }
      var uploadPath = `/${fileType}s/`;
      _.assign(asset, {fileType, extension, uploadPath, icon});
    }

    vm.uploadAssets = function (files) {
      _.each(files, function startUpload (file, i) {
        setAssetMeta(file);
        assetOptions.onSuccess = onUploadSuccess.bind(null, file);
        assetOptions.onError = onUploadFailure.bind(null);
        requestOptions.path = file.uploadPath;
        vm.uploading.push(Uploads.upload(file, requestOptions, assetOptions));
      });
    };

    function onUploadSuccess (file) {
      // remove the file from uploading.
      _.remove(vm.uploading, function (upload) {
        return (file.name === upload.file.name);
      });
      vm.uploaded.push(file);
      vm.viewAssets();
    }

    function onUploadFailure (e) {
      // remove the file from uploading.
      _.remove(vm.uploading, function (upload) {
        return (e.config.file.name === upload.file.name);
      });
    }

    vm.viewAssets = function viewAssets () {
      vm.assets = {
        images: [],
        videos: [],
        fonts: [],
        styles: [],
        others: []
      };
      $http
        .get(ConfigURLs.customisations())
        .then(function (res) {
          _.each(res.data, function (asset, index) {
            setAssetMeta(asset);
            vm.assets[asset.fileType + "s"].push(asset);
          });
        })
        .catch(function (err) {
          Toast.makeError(err);
        });
    };

    vm.deleteAssets = function deleteAssets (assets) {
      if (!angular.isArray(assets)) {
        assets = [assets];
      }
      _.each(assets, function deleteAsset (asset, i) {
        $http.delete(
          ConfigURLs.customisations(asset.path.substring(1))
        )
        .then(vm.viewAssets)
        .catch(function (e) {
          Toast.makeError('Asset ' + e.statusText);
        });
      });
    };

    vm.downloadAssets = function downloadAssets (asset) {
      var link = document.createElement("a");
      link.download = asset.name;
      link.href = 'customisations' + asset.path;
      link.click();
    };

    var themes = [];
    if (vm.enableThemes) {
      themes = Themes.all();
      themes.promise
        .then(function () {
          vm.themes = _.uniq(_.map(themes, "name"));
          // If we don't have themes create the default theme (no styling).
          if (!vm.themes.length) {
            var theme = Themes.create();
            theme.name = "Default Theme";
            theme.palette.bg1 = null;
            theme.palette.bg2 = null;
            theme.palette.tc1 = null;
            theme.palette.tc2 = null;
            theme.palette.hc1 = null;
            theme.save();
          }
        })
        .catch(function (err) {
          $log.error(err);
        });
    }

    vm.createTheme = function createTheme () {
      SaveCheck.checkNow(true)
      .then(function () {
        vm.selectedTheme = Themes.create();
      })
      .catch(function (err) {
        if (err !== 'cancel') {
          $log.error(err);
        }
      })
    };

  }
)

.component('assetArea', {
  templateUrl: 'branding/templates/asset-area.jade',
  bindings: {
    assetDirs: '=',
    refresh: '=refreshFn',
    delete: '=deleteFn',
    download: '=downloadFn',
    uploading: '='
  },
  controller: function () {
    var $ctrl = this;
    $ctrl.$onInit = function onInit () {
      // Populate the viewer when we first init.
      $ctrl.refresh();
    };
  }
})

.component('themeViewer', {
  templateUrl: 'branding/templates/theme-viewer.jade',
  bindings: {
    theme: '=',
    editable: '='
  },
  controller: function ($log, Toast, Themes, $scope, SaveCheck) {
    var $ctrl = this;

    $ctrl.saveChanges = function saveChanges () {
      Toast.saveAndNotify($ctrl.theme);
    };

    $ctrl.deleteTheme = function deleteTheme () {
      Toast.deleteAndNotify($ctrl.theme);
    };

    // Request themes.
    $ctrl.themes = Themes.all();
    // Register a savecheck on each theme.
    $ctrl.themes.promise
      .then(function () {
        SaveCheck.register({
          hasChanges: function () {
            return _.any($ctrl.themes, function (theme, index) {
              return theme.isModified();
            });
          },
          discardChanges: function () {
            _.each($ctrl.themes, function (theme) {
              if (theme.isModified()) {
                theme.reset();
              }
            });
          },
        });
      })
      .catch(function (err) {
        $log.error(err);
      });

    // Validator Fn for theme name.
    $ctrl.isNameUnique = function isNameUnique () {
      var unique = true;
      var update = false;
      _.each($ctrl.themes, function (theme, index) {
        if ($ctrl.theme._id === theme._id) {
          update = true;
          return update;
        }
        if ($ctrl.theme.name === theme.name) {
          unique = false;
        }
      });
      return update ? update : unique;
    };

    // Dirty form when a colour is changed.
    // SaveCheck when switching themes.
    var skip = false;
    $scope.$watch('$ctrl.theme', function (newVal, oldVal) {
      if (!oldVal || skip) {
        skip = false;
        return;
      }
      if (!(newVal._id === oldVal._id)) {
        SaveCheck.checkNow(false)
        .catch(function () {
          $ctrl.theme = oldVal;
          skip = true;
        });
        return;
      }
      if (!(newVal.palette === oldVal.palette)) {
        $ctrl.theme.setModified(true);
      }
    }, true)

  }
});
