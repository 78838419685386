angular.module('templateBrowser')
.directive('pxnCarousel', function () {
  return {
    scope: {
      resources: '=',
      clickFn: '&',
      carouselMod: '@',
      orderBy: '@',
      promise: '=',
      selectedResource: '<',
      scrollToResource: '<',
      autoSelect: '<',
      slideshowStarted: '<',
      justifyContent: '@'
    },
    controllerAs: 'ctrl',
    bindToController: true,
    templateUrl: 'utility/pxn-carousel.jade',
    controller: function pxnCarouselCtrl (
      $scope, $element, $attrs, $window, $timeout, $q, $log, Breakpoints
    ) {
      var ctrl = this;
      var logPrefix = '[pxnCarousel] - ';
      var firstItem = null;

      let isSlideshow, isWebReceiver;

      ctrl.$onInit = function () {
        ctrl.orderPropName = ctrl.orderBy || null;
        ctrl.disableArrow = false;
        ctrl.isDisabled = false;
        ctrl.breakpoint = Breakpoints;
        ctrl.currentItemWidth = 0;
        ctrl.scPos = 0;
        ctrl.scrollLeftSum = 0;

        isSlideshow = ctrl.carouselMod === "slideshow";
        isWebReceiver = ctrl.carouselMod === "webReceiver";

        // Aligning items when the carousel is wider or taller then it needs to be
        // Centered by default
        if (!ctrl.justifyContent) ctrl.justifyContent = "center";

        // Defer a promise to resolve when we have resources.
        var resourcesDeferred = $q.defer();
        if (ctrl.resources.promise) {
          // This is a generic resource.
          ctrl.resources.promise.then(function () {
            resourcesDeferred.resolve();
          });
        } else if (ctrl.promise) {
          resourcesDeferred.promise = ctrl.promise;
        } else if (!ctrl.resources.length) {
          // We have no items so why even continue?
          resourcesDeferred.reject("no-resources");
        } else {
          // We do have items so resolve.
          resourcesDeferred.resolve();
        }

        $q.all([resourcesDeferred.promise, ctrl.promise])
          .then(function () {
            ctrl.resourcesLoaded = true;
            // $timeout because there is a race condition getting the reference.
            $timeout(() => {
              setItemWidth();
              setListJustify();
              if (ctrl.scrollToResource) {
                scrollToIndex(ctrl.index);
              }
            });
          })
          .catch((e) => {
            if (e === "no-resources") {
              $log.warn(logPrefix + "There were no resources provided");
            } else {
              $log.error(e);
            }
          });

        // Reset main image url when a different template is selected.
        $scope.$watch("ctrl.selectedResource", function (newVal, oldVal) {
          if (newVal && newVal !== oldVal) selectThumbnailForMainImage();
        });

        // Set up watches for web receiver
        if (isWebReceiver) {
          $scope.$watch("ctrl.slideshowStarted", function (newVal) {
            // Reset selected index when slideshow restarts
            if (newVal) ctrl.selectedSlideIndex = -1;
          });

          ctrl.selectedSlideIndex = -1;

          $scope.$watch("ctrl.autoSelect", function (newVal) {
            // No resources, nothing to select
            if (!ctrl.resources.length) return;

            if (!ctrl.resources[ctrl.selectedSlideIndex]) {
              // Reset flag if the resource selected previously cannot be found
              ctrl.selectedSlideIndex = -1;
            } else {
              // Deselect previously selected resource
              ctrl.resources[ctrl.selectedSlideIndex].selected = false;
            }
            // Keep a reference of the previous index if there was any
            if (ctrl.selectedSlideIndex !== -1) {
              ctrl.lastSelectedIndex = ctrl.selectedSlideIndex;
            }
            // Get the index of the next resource to select
            ctrl.selectedSlideIndex = getNextResourceIndex(newVal);

            if (ctrl.selectedSlideIndex === -1) {
              $log.debug(logPrefix, "Nothing to select");
              return;
            }
            // Select resource at index
            ctrl.resources[ctrl.selectedSlideIndex].selected = true;
          });
        }

        $scope.$watch("ctrl.resources.length", function (newValue, oldValue) {
          ctrl.lastSelectedIndex = -1;
          // If the resources.length changes sets the list centered if necessary.
          setListJustify();
          if (isSlideshow || isWebReceiver) setCarousel();
          // Sets the scroll position back to zero when filtering.
          ctrl.scrollLeftSum ? moveToBeginning() : angular.noop();
        });

        selectThumbnailForMainImage();
      }

      var animationDurationMs = 350;

      // Set a reference to our carousel item container.
      var listElem = $element[0].querySelector('.list-container');

      let deregister = $scope.$on(
        'pxn-carousel:selected-item-index', (e, index) => {
          if (ctrl.scrollToResource) ctrl.index = index;
          deregister();
        });

      // Set reference to firstItem ready for use in setItemWidth.
      firstItem = $element[0].querySelector('.banner-image');

      // Move carousel to the beginning of the list on reset filters.
      $scope.$on('template-browser:reset-filters', function () {
        moveToBeginning();
      });

      /**
       * Returns the index of the next item to highlight from the resources
       * array when auto selecting resources.
       *
       * @param {Object} nextItem - The partial object to find the corresponding
       * resource by.
       * @returns {Number} - Index of item to select.
       */
      function getNextResourceIndex (nextItem) {
        let index = ctrl.resources.findIndex(
          (s, i) =>
            s._id === nextItem.id &&  // Match id
            s.repeat === nextItem.repeat && // Match repeat count
            // Considering duplicates, always try to find the next element to
            // highlight after the last selected index to avoid selecting always
            // the first instance.
            i > ctrl.lastSelectedIndex
        );

        if (index === -1) {
          // No match, look for the first instance in the resources array
          index = ctrl.resources.findIndex(
            (s) => s._id === nextItem.id && s.repeat === nextItem.repeat
          );
        }
        // Return the index found or -1
        return index;
      }

      /**
       * Sets justify-content property of the list if needed after filtering
       *
       * @returns {Undefined} - Nothing is returned.
       */
      function setListJustify () {
        // Ensure we have an up to date width for items.
        if (!ctrl.currentItemWidth) {
          setItemWidth();
        }

        if (
          ctrl.currentItemWidth * ctrl.resources.length >= listElem.clientWidth
        ) {
          listElem.style.justifyContent = 'flex-start';
          ctrl.isDisabled = false;
        } else {
          listElem.style.justifyContent = ctrl.justifyContent;
          ctrl.isDisabled = true;
        }
      }

      /**
       * Measures the first item of the carousel and sets a scope var.
       *
       * @returns {Undefined} - Nothing to see here.
       */
      function setItemWidth () {
        // Check that we have a reference.
        if (!firstItem) {
          // We didn't, try again.
          firstItem = $element[0].querySelector('.banner-image');
          // If we still don't have a reference then we're in trouble.
          if (!firstItem) {
            $log.debug(
              logPrefix,
              'Couldn\'t get reference to first item, unable to navigate'
            );
          }
        }

        // Update reference for firstItem.
        firstItem = $element[0].querySelector('.banner-image');

        // Calculates full width of element including margins
        if (firstItem) {
          ctrl.itemWidth = firstItem.getBoundingClientRect().width;
          var computedStyle = getComputedStyle(firstItem);
          let marginR = parseFloat(computedStyle.marginRight);
          let marginL = parseFloat(computedStyle.marginLeft);
          ctrl.currentItemWidth = (ctrl.itemWidth + marginR + marginL);
        }

        if (isSlideshow || isWebReceiver) setCarousel(); // Set carousel.
      }

      /**
       * Sets carousel properties according to it's direction, height and it's
       * displayed items width.
       *
       * @returns {Undefined} - Nothing is returned.
       */
      function setCarousel () {
        let arrows =
          $element[0].querySelectorAll('.arrow-container');
        if (isSlideshow || isWebReceiver) {
          let img = isSlideshow ?
            document.querySelector('.template-info--main-image') : null;

          if (!img) { img = { clientHeight: 0 }; }

          // On lg and xl screens slideshow carousel will be vertical by the
          // side of the main image
          if (
            ctrl.breakpoint.current === 'lg' ||
            ctrl.breakpoint.current === 'xl'
          ) {

            if (isSlideshow) {
              // Sets vertical carousels height to the same as main image height
              $element[0].parentElement.style.maxHeight =
                img.clientHeight + 'px';
            }
            $element[0].classList.add('vertical-carousel');
            // When vertical we don't need arrows instead using scroll-element
            _.each(arrows, function (arrow) {
              arrow.style.display = 'none';
            });
          } else {
            // Screen is smaller, not enough room for vertical carousel
            $element[0].classList.remove('vertical-carousel');
            // Make sure to display arrows when needed
            _.each(arrows, function (arrow) {
              arrow.style.display = 'flex';

              if (
                ctrl.currentItemWidth * ctrl.resources.length >=
                listElem.clientWidth
              ) {
                // Arrows needed to navigate in slideshow carousel
                arrow.disabled = false;
                ctrl.isDisabled = false;
                arrow.classList.remove('arrow-container__disabled');
              } else {
                // Arrows not required in slideshow carousel
                arrow.disabled = true;
                ctrl.isDisabled = true;
                arrow.classList.add('arrow-container__disabled');
              }
            });
          }
        }
      }

      // Track expression for ngRepeat to allow duplicates in the carousel
      ctrl.trackBy = (resource, index) => `${index}:${resource.imageUrl}`;

      /**
       * Stores the scroll position for the first visible item.
       * @returns {Undefined} - Nothing is returned.
       */
      function storeFirstItem () {
        ctrl.scPos = ctrl.scrollLeftSum / ctrl.currentItemWidth;
      }

      /**
       * Keeps the lists scroll left ratio the same during resize.
       * @returns {Undefined} - Nothing is returned.
       */
      function keepFirstItem () {
        if (
          ctrl.scPos * ctrl.currentItemWidth <
          listElem.scrollWidth - listElem.clientWidth
        ) { // If scroll position can grow or shrink at breakpoint jumps:
          listElem.scrollLeft = ctrl.scrollLeftSum =
            ctrl.scPos * ctrl.currentItemWidth;
        } else { // If scroll position can't be at the current element:
          // Go to the end of the list.
          listElem.scrollLeft = ctrl.scrollLeftSum =
            listElem.scrollWidth - listElem.clientWidth;
        }
      }

      // Update the itemWidth and carousel when we resize.
      $window.addEventListener('resize', function () {
        setItemWidth();
        setListJustify();
        keepFirstItem();
      });

      // Update scrollLeftSum when the scrollbar is dragged
      listElem.addEventListener('ps-scroll-x', function (e) {
        ctrl.scrollLeftSum = e.target.scrollLeft;
        storeFirstItem();
      });

      /**
       * Marks thumbnail for main image selected.
       *
       * @returns {Undefined} - Nothing is returned.
       */
      function selectThumbnailForMainImage () {
        if (isSlideshow) {
          // Timeout to get correct element
          $timeout(function () {
            let image = document.querySelector('.template-info--main-image');
            if (!image) return;
            const previewImg = image.style.backgroundImage;
            _.each(ctrl.resources, function (resource) {
              resource.selected = resource.imageUrl === previewImg;
            });
          });
        }
      }

      /**
       * Sets main image to be the clicked thumbnail
       *
       * @param {string} resource - thumbnail
       * @returns {Undefined} - Nothing is returned.
       */
      ctrl.setChosenImg = function (resource) {
        if (isSlideshow) {
          const mainImg = document.querySelector('.template-info--main-image');
          mainImg.style.backgroundImage = resource.imageUrl;
          resource.selected = true;
          _.each(ctrl.resources, function (t) {
            t.selected = t === resource;
          });
        }
      };

      /* eslint-disable no-magic-numbers*/
      var EasingFunctions = {
        // no easing, no acceleration
        linear: function (t) { return t; },
        // accelerating from zero velocity
        easeInQuad: function (t) { return t * t; },
        // decelerating to zero velocity
        easeOutQuad: function (t) { return t * (2 - t); },
        // acceleration until halfway, then deceleration
        easeInOutQuad: function (t) {
          return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        },
        // accelerating from zero velocity
        easeInCubic: function (t) { return t * t * t; },
        // decelerating to zero velocity
        easeOutCubic: function (t) { return (--t) * t * t + 1; },
        // acceleration until halfway, then deceleration
        easeInOutCubic: function (t) {
          return t < 0.5 ? 4 * t * t * t :
            (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
        },
        // accelerating from zero velocity
        easeInQuart: function (t) { return t * t * t * t; },
        // decelerating to zero velocity
        easeOutQuart: function (t) { return 1 - (--t) * t * t * t; },
        // acceleration until halfway, then deceleration
        easeInOutQuart: function (t) {
          return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
        },
        // accelerating from zero velocity
        easeInQuint: function (t) { return t * t * t * t * t; },
        // decelerating to zero velocity
        easeOutQuint: function (t) { return 1 + (--t) * t * t * t * t; },
        // acceleration until halfway, then deceleration
        easeInOutQuint: function (t) {
          return t < 0.5 ? 16 * t * t * t * t * t :
          1 + 16 * (--t) * t * t * t * t;
        },
        // Jiggle and bounce around
        elasticOut: function (t) {
          var p = 0.3; return Math.pow(2, -10 * t) *
          Math.sin((t - p / 4) * (2 * Math.PI) / p) + 1;
        }
      };
      /* eslint-enable no-magic-number */

      function setScrollPosition (
        startTime,
        startPos,
        targetPos,
        targetElem,
        animDuration,
        easingFn,
        timeStamp
      ) {
        timeStamp = Date.now();
        var timeFraction = Math.min((timeStamp - startTime) / animDuration, 1);
        var distanceFraction = EasingFunctions[easingFn](timeFraction);
        var totalDistance = Math.ceil(targetPos - startPos);
        var distanceNow = totalDistance * distanceFraction;
        targetElem.scrollLeft = Math.ceil(startPos) + distanceNow;
        if (Math.ceil(targetElem.scrollLeft) !== Math.floor(targetPos)) {
          requestAnimationFrame(setScrollPosition.bind(
            null, startTime, Math.ceil(startPos), Math.ceil(targetPos),
            targetElem, animDuration, easingFn));
        }
      }

      /**
       * Function that moves the carousel to the beginning.
       * @returns {Undefined} - Nothing is returned.
       */
      function moveToBeginning () {
        requestAnimationFrame(
          setScrollPosition.bind(
            null,
            Date.now(),
            ctrl.scrollLeftSum,
            0,
            listElem,
            animationDurationMs,
            'easeInOutCubic'
          )
        );
        listElem.scrollLeft = ctrl.scrollLeftSum = 0;
        storeFirstItem();
      }

      /**
       * Function that moves carousel to the end
       * @returns {Undefined} - Nothing is returned.
       */
      function moveToEnd () {
        requestAnimationFrame(
          setScrollPosition.bind(
            null,
            Date.now(),
            ctrl.scrollLeftSum,
            listElem.scrollWidth - listElem.clientWidth,
            listElem,
            animationDurationMs,
            'easeInOutCubic'
          )
        );
        listElem.scrollLeft = ctrl.scrollLeftSum =
          listElem.scrollWidth - listElem.clientWidth;
        storeFirstItem();
      }

      /**
       * Function that moves carousel to an element index.
       * @param {Number} index - Index of element in the list to scroll to.
       * @returns {Undefined} - Nothing is returned.
       */
      function scrollToIndex (index) {
        let scrollPosition = 0;
        // Don't set scroll position to be less or greater than it can be.
        if (index * ctrl.currentItemWidth < 0) {
          scrollPosition = 0;
        } else if (
          index * ctrl.currentItemWidth >
          listElem.scrollWidth - listElem.clientWidth
        ) {
          scrollPosition = listElem.scrollWidth - listElem.clientWidth;
        } else {
          scrollPosition = index * ctrl.currentItemWidth;
        }
        requestAnimationFrame(
          setScrollPosition.bind(
            null,
            Date.now(),
            0,
            scrollPosition,
            listElem,
            animationDurationMs,
            'easeInOutCubic'
          )
        );
        listElem.scrollLeft = ctrl.scrollLeftSum =
          listElem.scrollWidth - listElem.clientWidth;
        storeFirstItem();
      }

      /**
       * Function that moves carousel only one item to the direction indicated
       *
       * @param {Boolean} direction - True if the direction to move is right.
       * @returns {Undefined} - Nothing is returned.
       */
      function scrollList (direction) {
        const visibleItemCount =
          Math.floor(listElem.clientWidth / ctrl.currentItemWidth);

        let toScroll = visibleItemCount * ctrl.currentItemWidth;

        let toWhere = direction ?
          ctrl.scrollLeftSum + Math.ceil(toScroll) :
          ctrl.scrollLeftSum - Math.ceil(toScroll);
        requestAnimationFrame(
          setScrollPosition.bind(
            null,
            Date.now(),
            ctrl.scrollLeftSum,
            toWhere,
            listElem,
            animationDurationMs,
            'easeInOutCubic'
          )
        );
        listElem.scrollLeft = ctrl.scrollLeftSum = toWhere;
        storeFirstItem();
      }

      /**
       * Moves the carousel in the direction indicated.
       *
       * @param {Boolean} right - True if the direction to move is right.
       * @returns {Undefined} - Nothing is returned.
       */
      ctrl.moveCarousel = _.throttle(function (right) {
        // Ensure we have an up to date width for items.
        if (!ctrl.currentItemWidth) {
          setItemWidth();
        }

        const visibleItemCount =
          Math.floor(listElem.clientWidth / ctrl.currentItemWidth);
        let toScroll = visibleItemCount * ctrl.currentItemWidth;

        ctrl.scrollLeftSum = listElem.scrollLeft;

        // Right arrow clicked
        if (right) {
          // When we are at the end of the list
          if (Math.ceil(listElem.scrollLeft + listElem.clientWidth) ===
          listElem.scrollWidth) {
            moveToBeginning(); // get back to the beginning

          // When we partially show the last resource
          } else if (Math.ceil(listElem.scrollLeft +
              listElem.clientWidth + toScroll) > listElem.scrollWidth
          ) {
            moveToEnd(); // Make sure we fully show the last template

          // Not at the end
          } else {
            scrollList(right); // Scroll list
          }

        // Left arrow clicked
        } else {
          // When we are at the beginning of the list
          // eslint-disable-next-line no-lonely-if
          if (Math.floor(listElem.scrollLeft) === 0) {
            moveToEnd(); // get back to the end

          // When we partially show the first resource
          } else if (Math.ceil(listElem.scrollLeft - toScroll) < 0 &&
              Math.abs(Math.ceil(
                listElem.scrollLeft - toScroll)) < toScroll
            ) {
              // Make sure we get to the beginning of the list
            moveToBeginning();

          // Not at the beginning
          } else {
            scrollList(right); // Scroll list
          }
        }
      }, animationDurationMs, { leading: true, trailing: true });
    },
  };
})
.directive('pxnCarouselSelectedItem', function () {
  return {
    link: function (scope, elem, attrs) {
      if (
        attrs.scrollToResource && scope.$eval(attrs.pxnCarouselSelectedItem)
      ) scope.$emit('pxn-carousel:selected-item-index', scope.$index);
    }
  };
});