angular
  .module('flare.customisations', [])

  .factory('FontTools', function ($log, FlowControl, $q) {
    let FT = {};
    const logPrefix = '[FontTools] - ';

    const propertyPatterns = {
      family: /font-family:\s*['"]?([^'";,}]+)/gm,
      weight: /font-weight:\s*([\w\d]+);?/gm,
      style: /font-style:\s*(\w*)/gm,
      // NOTE: We need to be able to pass a test string from the CSS because we
      // don't  put a fdo in non-customised templates. For this, use the content
      // css property.
      testStrings: /content:\s*['"]([^'"]*)/gm
    };

    /**
     * Accepts a css font definition and returns an object with family, weight and
     * style.
     * @param {string} definitionBlock CSS string defining a font.
     * @return {object} Object with family, weight and style  and testString
     *                  properties (any can be null)
     */
    function getFontDetailsFromDefinitionBlock (definitionBlock) {
      let result = {};
      _.each(propertyPatterns, function (pattern, name) {
        // Reset lastIndex for our regex otherwise we don't start our search
        // from the begining.
        pattern.lastIndex = 0;
        let match = pattern.exec(definitionBlock);
        // match is either null or [fullMatch, firstGroup]
        result[name] = match ? match[1] : null;
      });
      return result;
    }

    /**
     * Extracts font blocks from a CSS file.
     * @param {string} cssString a string containing CSS that may have font defs.
     * @return {[string]} An array of css definition strings.
     */
    function getFontBlocksFromCSS (cssString) {
      let fontBlockRegex = /@font-face\s*{[^}]*}/gm;
      let fontBlocks = cssString.match(fontBlockRegex);
      if (!fontBlocks) return [];
      return fontBlocks;
    }

    /**
     * Makes a string like 'familyName:n1' from a font details object
     * @param {object} fontDetails object with family, style and weight properties
     * @return {string} Returns an font definition string for the family, weight
     *                  and style.
     */
    function makeFDOFamilyString (fontDetails) {
      let { family, weight, style } = fontDetails;
      let parsedWeight;
      let result = `${family}:`;
      // Style can be italic (i) or oblique (o), otherwise normal (n)
      result += style ? style[0] : 'n';
      // Weight could either be something like 'bold', or a number, or nothing.
      switch (weight) {
        case 'bold':
          result += '7';
          break;
        case 'lighter':
          result += '1';
          break;
        default:
          parsedWeight = parseInt(weight);
          // This covers the null case too.
          result += parsedWeight / 100 || '4';
          break;
      }
      return result;
    }

    /**
     * Creates  a list of unique families and styles in the format
     * ['fam1:n1,i4', 'fam2:i4']. Accepts object containing potentialy duplicated
     * family definition strings
     *
     * @param {[string]} condensedList An array of family definition strigns like
     *                                ['fam1:n1', 'fam1:i4', 'fam1:n1', 'fam2:i4']
     * @returns {[string]} Reduced, simplified array.
     */
    function mergeFamilyStyles (condensedList) {
      let familyWeightStyles = _.reduce(
        condensedList,
        function (acc, item) {
          let [family, styleWeight] = item.split(':');
          if (!acc[family]) {
            acc[family] = [styleWeight];
          } else {
            acc[family].push(styleWeight);
          }
          return acc;
        },
        {}
      );
      // We now have: { familyOne: ['n1', 'i4', 'i4'], family2: ['n1']}
      let out = [];
      _.each(familyWeightStyles, function (weightStyles, family) {
        out.push(`${family}:${_.uniq(weightStyles).join(',')}`);
      });
      return out;
    }

    /**
     * Takes a family definition object and outputs a concatenated test string
     * if one has been defined (add it to the content css property).
     *
     * @param {object} familyDetails Font details object.
     * @returns {object} Object defining test strings in the format
     *                   { familyName: 'testString' }
     */
    function getTestStringsForFamilies (familyDetails) {
      let result = {};
      _.each(familyDetails, function (details) {
        let { family, testStrings } = details;
        if (testStrings) {
          let concat = (result[family] || '') + testStrings;
          result[family] = concat;
        }
      });
      return result;
    }

    /**
     * Creates a font definition object using data extracted from a CSS string.
     * @param {string} cssString A string of css (normally from a file)
     * @returns {object} fdo object.
     */
    FT.definitionFromCss = function (cssString) {
      // Find font blocks in the css string.
      const blocks = getFontBlocksFromCSS(cssString);
      if (!blocks) return { families: [] }; // no fonts defined in this css file.
      // details = Array of font details ([{family, weight, style, testStrings}])
      const familyDetails = _.map(blocks, getFontDetailsFromDefinitionBlock);
      // Convert to: ['familyName:n1', 'familyName:i4']
      const familyStrings = _.map(familyDetails, makeFDOFamilyString);
      // Reduce duplication in array so that we end up with: ['familyName:n1,i4']
      const condensedList = mergeFamilyStyles(familyStrings);
      // Generate the test strings object if there is one.
      const combinedTestStrings = getTestStringsForFamilies(familyDetails);
      return {
        families: condensedList,
        testStrings: combinedTestStrings,
        blocks: blocks
      };
    };

    FT.loadFont = function (fdo) {
      if (!fdo.families.length) {
        $log.debug(logPrefix + 'No fonts to load, resolving promise.');
        return $q.resolvedPromise(fdo.families);
      }
      let options = {
        custom: fdo,
        timeout: 20000
      };

      // If we retry, in order to re-attempt loading of the fonts on android,
      // we need to add a unique style block. We use the `currentRetryId` within
      // a comment in the style block to make the contents unique, and this also
      // allows us to remove any existing style block  on failure so that
      // we don't clutter the DOM with failed font load attempts.
      let currentRetryId = null;

      // This boolean keeps a track of whether or not we need to start a new
      // attempt following a failure. It prevents multiple failures
      // (e.g. normal and italic versions of a font face) causing multiple
      // retries. Because we retry all font styles at once (caching means this
      // is not inefficient), we only need one per failure per template.
      let shouldMakeNewAttempt = false;

      function wfl () {
        var deferred = $q.defer();

        options.active = function (font, style) {
          $log.debug(logPrefix + 'Font loaded: ' + fdo.families.join(', '));
          deferred.resolve(fdo.families);
          const fontForceLoadBlock =
            document.getElementById(fdo.families.join('-'));
          if (fontForceLoadBlock) {
            $log.debug(
              logPrefix + 'Removing font force load block with id '
              + fdo.families.join('-')
            );
            fontForceLoadBlock.remove();
          }

        };

        // NOTE: this will be called once per font, but we don't currently work
        // on that resolution.
        options.fontinactive = function (font, style) {

          $log.warn(logPrefix + `Font couldn't be loaded: ${font} (${style})`);
          deferred.reject(font + " didn't load");

          if (currentRetryId) {
            // Remove failed style load block.
            const styleBlock = document.getElementById(currentRetryId);
            if (styleBlock) {
              $log.debug(
                logPrefix + 'Removing style block with id ' + currentRetryId
              );
              shouldMakeNewAttempt = true;
              styleBlock.remove();
            }
          } else {
            shouldMakeNewAttempt = true;
          }
          if (shouldMakeNewAttempt) {
            // Bump the unique ID for next load attempt.
            currentRetryId = Date.now() + '-' + Math.random();
            $log.debug(logPrefix + 'Current retry id is now ' + currentRetryId);
          }

        };

        // If this is true, we're within a retry attempt, so we need to manually
        // add the CSS again to force the browser to re-attempt loading the font
        if (shouldMakeNewAttempt) {
          shouldMakeNewAttempt = false;
          // Font definition blocks from template CSS.
          const newStyleTag = document.createElement('style');

          // Because of the way fonts in customisations are defined, we won't
          // have the font blocks yet, so we have to make them.
          if (!fdo.blocks) {
            const blocksFromCustomCSS =
              getFontBlocksFromCSS(document.getElementById('custom-css').innerHTML);
            const familyNames =
              fdo.families.map((family)=> family.split(':')[0]);
            fdo.blocks = blocksFromCustomCSS.filter(
              (block)=> _.any(familyNames, (name)=> _.contains(block, name))
            );
          }

          newStyleTag.innerHTML =
            `/* Unique content: ${currentRetryId} */\n` +
            (fdo.blocks || []).reduce(
              (output, block) => output + '\n' + block
            ) +
            '\n';
          newStyleTag.id = currentRetryId;
          $log.debug(logPrefix + 'Appending style block with id ' + currentRetryId);
          document.body.appendChild(newStyleTag);

          // An invisible container with a span for each font we need to load
          // (browsers load fonts lazily).
          const forceFontLoadContainer = document.createElement('aside');
          forceFontLoadContainer.style.opacity = '0';
          forceFontLoadContainer.style.position = 'absolute';
          forceFontLoadContainer.style.width = '0';
          forceFontLoadContainer.style.height = '0';
          forceFontLoadContainer.id = fdo.families.join('-');
          _.each(fdo.families, (family) => {
            const [ familyName, styleString ] = family.split(':');
            const styles = styleString.split(',');
            _.each(styles, (style)=> {
              let fontStyle = 'normal';
              if (style[0] === 'i') {
                fontStyle = 'italic';
              } else if (style[0] === 'o') {
                fontStyle = 'oblique';
              }
              let fontWeight = parseInt(style[1] * 100);
              const span = document.createElement('span');
              span.style.fontFamily = familyName;
              span.style.fontStyle = fontStyle;
              span.style.fontWeight = fontWeight;
              span.innerHtml = family;
              forceFontLoadContainer.appendChild(span);
            });
          });
          $log.debug(logPrefix + 'Appending font force load block with id ' + fdo.families.join('-'));
          document.body.appendChild(forceFontLoadContainer);
        }
        WebFont.load(options);
        return deferred.promise;
      }

      return FlowControl.attempt(wfl, [], {
        name: `Load font(s) (${fdo.families.join(', ')})`,
        maxRetries: Infinity
      });
    };
    return FT;
  })

  .factory('CustomFields', function (
    $log,
    $http,
    $q,
    $templateCache,
    FlowControl,
    FontTools
  ) {
    var logPrefix = '[CustomFields] - ';
    var customisations = {
      customFields: {
        promise: null,
        all: null
      },
      customCss: {
        promise: null,
        all: null
      },
      templateCss: {}
    };
    var actionsPerformed = {
      preCompile: {}
    };

    function loadCustomCss (overwrite) {
      let startedAt = Date.now();
      if (!overwrite && customisations.customCss.promise) {
        // If already requested return the promise
        return customisations.customCss.promise;
      }

      customisations.customCss.latestPromiseStartedAt = startedAt;
      customisations.customCss.promise = $http({
        method: 'GET',
        url: '/customisations/styles/custom.css',
        noErrorToast: true
      })
        .then(function (res) {
          if (customisations.customCss.latestPromiseStartedAt !== startedAt) {
            // promise has been superseded by another request, don't do anything.
            return false;
          }
          // Store our CSS for future reference.
          customisations.customCss.all = res.data;
          // Remove any existing CSS.
          let existingCss = document.querySelector('#custom-css');
          if (existingCss) {
            existingCss.parentNode.removeChild(existingCss);
          }
          // Create a new style to add to the page.
          let newStyle = document.createElement('style');
          newStyle.type = 'text/css';
          newStyle.id = 'custom-css';
          // Apply the css to the page.
          newStyle.appendChild(document.createTextNode(res.data));
          document.head.appendChild(newStyle);
          // That's all folks.
          return true;
        })
        .catch(function (err) {
          // This should only ever happen if there is a server problem, otherwise
          // a blank file will be returned.
          throw err;
        });

      return customisations.customCss.promise;
    }

    function loadCustomFieldsJson (overwrite) {
      let startedAt = Date.now();
      if (overwrite && customisations.customFields.promise) {
        customisations.customFields.latestPromiseStartedAt = startedAt;
      } else if (customisations.customFields.promise) {
        // If already requested return the promise (resolves with val or error).
        return customisations.customFields.promise;
      } else {
        // This is the first time
        customisations.customFields.latestPromiseStartedAt = startedAt;
      }

      customisations.customFields.promise = $http({
        method: 'GET',
        url: '/customisations/others/customFields.json',
        noErrorToast: true
      })
        .then(function (res) {
          if (
            customisations.customFields.latestPromiseStartedAt !== startedAt
          ) {
            // promise has been superceded by another request, don't do anything.
            return false;
          }
          customisations.customFields.all = res.data;
          return true;
        })
        .catch(function (err) {
          if (err.status === 404) {
            // There are no custom fields for this account
            customisations.customFields.all = {};
            return {};
          }
          throw err;
        });
      return customisations.customFields.promise;
    }

    function loadTemplateCSS (tpl) {
      // If already requested return the promise (resolves with value or error).
      if (customisations.templateCss[tpl.name].cssPromise) {
        return customisations.templateCss[tpl.name].cssPromise;
      }
      // Init tpl object if not already created.
      if (!customisations.templateCss[tpl.name]) {
        customisations.templateCss[tpl.name] = {
          cssPromise: null,
          fontPromise: null,
          all: {
            fonts: null,
            css: null
          }
        };
      }
      let tplCssPath =
        '/templates/' +
        tpl.domain +
        '/' +
        tpl.name +
        '/' +
        tpl.version +
        '/' +
        tpl.name +
        '.css';
      customisations.templateCss[tpl.name].cssPromise = $http({
        method: 'GET',
        url: tplCssPath,
        noErrorToast: true
      })
      .then(function (res) {
        customisations.templateCss[tpl.name].all.css = res.data;
        return res.data;
      })
      .catch((err)=> {
        throw err
      });
      return customisations.templateCss[tpl.name].cssPromise;
    }

    function loadTemplateFonts (tpl) {
      // If already requested return the promise (resolves with value or error).
      if (
        customisations.templateCss[tpl.name] &&
        customisations.templateCss[tpl.name].fontPromise
      ) {
        return customisations.templateCss[tpl.name].fontPromise;
      }
      // Init tpl object if not already created.
      if (!customisations.templateCss[tpl.name]) {
        customisations.templateCss[tpl.name] = {
          cssPromise: null,
          fontPromise: null,
          all: {
            fonts: null,
            css: null
          }
        };
      }

      return loadTemplateCSS(tpl)
        .then(function (cssString) {
          return FontTools.definitionFromCss(cssString);
        })
        .then(function (fdo) {
          if (!fdo.families.length) {
            $log.debug(
              `${logPrefix}${tpl.name} css contains no font definitions`
            );
            return null;
          }
          $log.debug(
            `${logPrefix}Loading fonts defined in ${tpl.name} css ` +
              `(${fdo.families.join(', ')})`
          );
          // FontTools handles retries.
          return FontTools.loadFont(fdo);
        });
    }

    function loadCustomisationFiles (overwrite) {
      let promises = [];
      let retryOptions = {
        maxRetries: Infinity
      };
      let css = loadCustomCss.bind(null, overwrite);
      let customFields = loadCustomFieldsJson.bind(null, overwrite);
      promises.push(FlowControl.attempt(css, [], retryOptions));
      promises.push(FlowControl.attempt(customFields, [], retryOptions));
      return $q.all(promises);
    }

    /**
     * Checks if any binding directives are used on the provided element. If a
     * directive is found this fn will return the expression used.
     *
     * **NOTE** Only supports `-bind` attribute-based bindings (e.g. ng-bind).
     *
     * @param {Element} elem - The element to check for binding
     * expressions
     * @returns {Object|String} Binding expression to use or null.
     */
    function getBindingExpression (elem) {
      if (!elem || !angular.isElement(elem)) {
        $log.debug(
          logPrefix + 'You must pass an element to getBindingExpression'
        );
        return null;
      }
      // Angular elem does nothing if it's already an angular elem: this is safe
      elem = angular.element(elem);
      var elemAttrs = elem[0].attributes;
      if (!elemAttrs.length) {
        $log.debug(logPrefix + 'Unable to find attributes for element', elem);
        return null;
      }
      // Regex to find binding attr names.
      var bindingRegex = /(-bind)\b/g;
      // Regex to find the model from the binding expression.
      var expressionRegex = /:{0,2}(.+)\s?/g;

      var bindingAttr;
      _.each(elemAttrs, function (attrObj, index) {
        if (bindingRegex.exec(attrObj.nodeName)) {
          bindingAttr = attrObj;
          // We found a binding exit loop early.
          return false;
        }
        return true;
      });

      var expressionParts = expressionRegex.exec(bindingAttr.nodeValue);
      var expression = null;
      if (expressionParts) {
        expression = expressionParts[1];
      } else {
        $log.warn(logPrefix + 'Unable to find binding expression');
        return null;
      }
      return expression;
    }

    /**
     * Checks the template element for the target, if nothing is retuned we then
     * check if the template elem is the target.
     * TODO: Add support for id selectors and more.
     *
     * @param {String} target - Target string to be used with the querySelector.
     * @param {Element} tElem - The template element.
     * @returns {Element|null} Target element or null.
     */
    function getTargetElement (target, tElem) {
      var targetElem = tElem[0].querySelector(target);
      if (!targetElem) {
        if (target.charAt(0) === '.') {
          // Finding a class
          if (tElem.hasClass(target.substr(1))) {
            targetElem = tElem;
          }
        }
      }
      return targetElem;
    }

    /**
     * Sets the text content of the target element using the details defined
     * within the action definition.
     *
     * @param {Object} action - Action definition object.
     * @param {Element} tElem - The template element.
     * @returns {Element/null} - The updated template element or null.
     */
    function setTextContent (action, tElem) {
      if (typeof action !== 'object' || !action.target || !action.text) {
        $log.debug(
          logPrefix + 'Bad action object used with setTextContent',
          action
        );
        return null;
      }
      // If we can't find the target element we might as well all go home.
      var targetElement = getTargetElement(action.target, tElem);
      if (!targetElement) {
        $log.warn(logPrefix + "couldn't find target element " + action.target);
        return null;
      }

      targetElement.textContent = action.text;
      return tElem;
    }

    /**
     * Appends an element to the DOM using the details defined within the
     * action definition.
     *
     * @param {Object} action - Action definition object.
     * @param {Element} tElem - The template element.
     * @param {Boolean} preview - Is this a template preview.
     * @returns {Element|null} - The updated template element or null.
     */
    function appendElem (action, tElem, preview) {
      if (typeof action !== 'object' || !action.element || !action.target) {
        $log.debug(
          logPrefix + 'Bad action object used with appendElem',
          action
        );
        return null;
      }

      // If we can't find the target element we might as well all go home.
      var targetElement = getTargetElement(action.target, tElem);
      if (!targetElement) {
        $log.warn(logPrefix + "couldn't find target element " + action.target);
        return null;
      }

      var $newElement = angular.element(document.createElement(action.element));

      if (action.id) {
        $newElement[0].id = action.id;
      }

      if (action.classList) {
        $newElement.addClass(action.classList);
      }

      var $targetElem = angular.element(targetElement);
      $targetElem.append($newElement);
      return tElem;
    }

    /**
     * Appends an attribute to an existing element in the DOM using the details
     * defined within the action definition.
     *
     * @param {Object} action - Action definition object.
     * @param {Element} tElem - The template element.
     * @param {Object} tpl - The populated template object.
     * @returns {Element|null} - The updated template element or null.
     */
    function addAttribute (action, tElem, tpl) {
      if (typeof action !== 'object' || !action.attribute || !action.target) {
        $log.debug(
          logPrefix + 'Bad action object used with addAttribute',
          action
        );
        return null;
      }
      var targetElement = angular.element(
        getTargetElement(action.target, tElem)
      );
      // If we couldn't reference the target then there's nothing more we can do
      if (!targetElement) {
        $log.warn(logPrefix + "couldn't find target element " + action.target);
        return null;
      }
      var expression;
      if (_.contains(action.value, '{binding}')) {
        // We wish to use a binding in our expression.
        var bindingExp = getBindingExpression(targetElement);
        if (!bindingExp) {
          return null;
        }
        // Update our expression.
        expression = action.value.replace('{binding}', bindingExp);
      } else if (_.contains(action.value, '$TEMPLATE_PATH_BASE')) {
        var tplPathBase = '' + tpl.domain + '/' + tpl.name + '/' + tpl.version;
        expression = action.value.replace('$TEMPLATE_PATH_BASE', tplPathBase);
      } else {
        // We had no special cases, just use what we wrote.
        expression = action.value;
      }
      // Add the attr to our target element.
      targetElement.attr(action.attribute, expression);
      return tElem;
    }

    /**
     * Creates an array of actions marked to run at the actionTrigger for the
     * supplied template.
     *
     * @param {Object} tpl - Template object.
     * @param {any} actionTrigger - The time at which the action should run
     * i.e. preCompile.
     * @returns {Array} Array of action objects.
     */
    function getActions (tpl, actionTrigger) {
      return customisations.customFields.promise.then(function () {
        var actions = [];
        if (
          !customisations.customFields.all[tpl.name] ||
          !customisations.customFields.all[tpl.name].actions
        ) {
          // No actions for this template.
          return actions;
        }
        // We have customFields for our template.
        _.each(customisations.customFields.all[tpl.name].actions, function (
          action,
          index
        ) {
          if (action.trigger === actionTrigger) {
            // We have an `actionTrigger` action.
            actions.push(action);
          }
        });
        return actions;
      });
    }

    /**
     * Get the template string from the templateCache and convert to an
     * element.
     *
     * @param {String} cacheUrl - Url to the template within the templateCache.
     * @returns {Element} - The template element.
     */
    function getTemplateElement (cacheUrl) {
      // The error code for createContextualFragment being unsupported is 9.
      var UNSUPPORTED = 9;
      var templateElem;
      var templateString = $templateCache.get(cacheUrl);
      // Parse the template element from the cached string.
      try {
        // This will run on Electron devices.
        templateElem = document
          .createRange()
          .createContextualFragment(templateString);
      } catch (e) {
        if (e.code === UNSUPPORTED) {
          // This will run on Android devices
          var tempContainer = document.createElement('span');
          tempContainer.innerHTML = templateString;
          templateElem = tempContainer.firstChild;
        } else {
          $log.warn(logPrefix + 'Error parsing cached template string');
        }
      }
      return templateElem;
    }

    /**
     * Converts the provided element to a string a stores in the templateCache
     * at the provided URL.
     *
     * @param {any} cacheUrl - Templates templateCache URL.
     * @param {any} tElem - The template element to be stored.
     * @returns {Undefined} - Nothing to see here.
     */
    function setCacheTemplate (cacheUrl, tElem) {
      var tpl = angular.element(document.createElement('div'));
      // Note that HTML returns `innerHTML` and not the whole thing, so it
      // excludes the <div> we just wrapped it in.
      tpl = tpl.append(tElem).html();

      $templateCache.put(cacheUrl, tpl);
    }

    /**
     * Performs actions for the template in the defined order.
     *
     * @param {Object} tpl - Template definition object.
     * @param {String} actionTrigger - When to perform actions (ONly supports
     * `preCompile` atm).
     * @param {Boolean} preview - Is this a template preview?
     * @returns {Promise} - Resolved when actions have been completed.
     */
    function performActions (tpl, actionTrigger, preview) {
      if (actionsPerformed[actionTrigger][tpl.name]) {
        $log.debug(
          logPrefix +
            'templateCache has already been updated for this template.'
        );
        return actionsPerformed[actionTrigger][tpl.name];
      }
      if (tpl.cacheUrl !== '') {
        actionsPerformed[actionTrigger][tpl.name] = $q
          .all([
            customisations.customCss.promise,
            customisations.customFields.promise
          ])
          .then(function checkResolveValues (values) {
            let newPromisesRequired = false;
            _.each(values, function (value, i) {
              if (!value) {
                // If either promise resolves with false, it means the request
                // has been superseded by another, so we need to wait for that
                // request instead.
                newPromisesRequired = true;
              }
            });
            if (newPromisesRequired) {
              return $q.all([
                customisations.customCss.promise,
                customisations.customFields.promise
              ]);
            }
            return $q.resolvedPromise();
          })

          // At this point customisation files have been loaded.
          .then(function loadCustomisationFonts () {
            let cust = customisations.customFields.all[tpl.name];
            $log.debug(
              `${logPrefix}Customisation definition loaded for ${tpl.name}`
            );
            // Load customisation custom fonts if required.
            if (
              !cust ||
              ((!cust.fontFamilies || !cust.fontFamilies.length) && !cust.fdo)
            ) {
              // No custom fonts were defined, we're not logging here as FontTools
              // already logs to say that.
              $log.debug(
                `${logPrefix}No fonts specified in customistaion for ${
                  tpl.name
                }`
              );
              return $q.resolvedPromise();
            } else {
              let fdo = cust.fdo || { families: cust.fontFamilies };
              $log.debug(
                `${logPrefix}Loading fonts for ${tpl.name} customisation` +
                  ` (${fdo.families.join(', ')})`
              );
              return FontTools.loadFont(fdo);
            }
          })
          .then(function () {
            return loadTemplateFonts(tpl);
          })
          .then(function () {
            return getActions(tpl, actionTrigger);
          })
          .then(function (actions) {
            if (!Object.keys(actions).length) {
              // We don't have any actions so return.
              $log.debug(
                logPrefix + 'No custom actions defined for ' + tpl.name
              );
              return $q.resolvedPromise();
            }
            var tElem = angular.element(getTemplateElement(tpl.cacheUrl));
            var newElem;
            _.each(actions, function (action, index) {
              $log.debug(
                `${logPrefix}Performing ${action.type} ` +
                  `at ${actionTrigger} on ${tpl.name}`
              );
              switch (action.type) {
                case 'addAttribute':
                  addAttribute(action, tElem, tpl);
                  break;
                default:
                  if (
                    typeof customisations.actionFns[action.type] !== 'function'
                  ) {
                    $log.debug(
                      logPrefix +
                        'performActionsForTemplate -> Unknown action type ' +
                        action.type
                    );
                  } else {
                    newElem = customisations.actionFns[action.type](
                      action,
                      tElem,
                      preview
                    );
                  }
              }
              // newElem will be null if action was unsuccessful.
              if (newElem) {
                tElem = newElem;
              }
            });
            // Our tElem should now reflect the made changes so lets pop it back
            // in the templateCache.
            setCacheTemplate(tpl.cacheUrl, tElem);
            $log.debug(
              logPrefix + actionTrigger + ' actions performed on ' + tpl.name
            );
            return $q.resolvedPromise();
          });
      } else {
        $log.warn(
          logPrefix,
          'Template cache URL was blank, unable to perform actions.'
        );
        actionsPerformed[actionTrigger][tpl.name] = $q.resolvedPromise();
      }
      return actionsPerformed[actionTrigger][tpl.name];
    }

    customisations.loadCustomisationFiles = loadCustomisationFiles;
    customisations.getFields = loadCustomFieldsJson;
    customisations.actionFns = {};
    customisations.actionFns.appendElem = appendElem;
    customisations.actionFns.addAttribute = addAttribute;
    customisations.actionFns.setTextContent = setTextContent;
    customisations.performActions = performActions;

    return customisations;
  });
