angular.module('templateBrowser', [])
.factory('FeaturedTemplates', function (
  $http, $log, ConfigURLs, Session, SemVer
) {
  /**
   * Set the given template(s) seen, update server.
   *
   * @param {Object} templateArray - Array of template(s) to set seen.
   * @returns {Undefined} - Nothing is returned.
   */
  function setSeen (templateArray) {
    if (!angular.isArray(templateArray)) {
      templateArray = [templateArray];
    }

    let seenTemplateIds = [];
    _.each(templateArray, (template) => {
      let key = [template.domain, template.name].join('/');
      if (
        Session.current.user.seen.templates[key] &&
        Session.current.user.seen.templates[key] === template.version
      // User seen template at same version
      ) return;

      template.newOrUpdatedTemplate = 'seen';
      seenTemplateIds.push(template._id);
    });

    if (!seenTemplateIds.length) return;

    $http.put(
      ConfigURLs.setSeen(Session.current.user._id),
    { templates: seenTemplateIds }
    )
    .then((res) => {
      Session.current.user.seen.templates = res.data.seen.templates;
      Session.current.user.__v = res.data.__v;
    })
    .catch(err => $log.error(err));
  }

  /**
   * Returns a boolean wether the user seen a version of the given template.
   *
   * @param {Object} template - Template to check.
   * @returns {Boolean} - Wether the user seen any version of template.
   */
  function seenPreviousVersion (template) {
    let key = [template.domain, template.name].join('/');
    return Session.current.user.seen.templates[key];
  }

  /**
   * Returns a boolean wether the user seen the given template or not.
   *
   * @param {Object} template - Template to check.
   * @returns {Boolean} - Wether the user seen the template or not.
   */
  function hasUserSeen (template) {
    let key = [template.domain, template.name].join('/');
    return (
      Session.current.user.seen.templates[key] &&
      Session.current.user.seen.templates[key] === template.version
    );
  }

  /**
   * Returns a boolean if the template has been updated.
   *
   * @param {Object} template - Template to check.
   * @returns {Boolean} - Wether template is a newer version.
   */
  function hasBeenUpdated (template) {
    let key = [template.domain, template.name].join('/');
    // To be able to set template updated user must have seen an earlier version
    if (
      Session.current.user.seen.templates[key] &&
      SemVer.isGreater(
        template.version, Session.current.user.seen.templates[key]
      ) && !isPatchUpdateOnly(template)
    ) return true;

    return false;
  }

  /**
   * Compares template current version with seen version number.
   *
   * @param {Object} template - Template to check.
   * @returns {Boolean} - TRUE if patch update only.
   */
  function isPatchUpdateOnly (template) {
    const key = [template.domain, template.name].join('/');
    return SemVer.patchUpdateOnly(
      template.version, Session.current.user.seen.templates[key]
    );
  }

  return {
    setSeen: setSeen,
    seenPreviousVersion: seenPreviousVersion,
    hasUserSeen: hasUserSeen,
    hasBeenUpdated: hasBeenUpdated,
    isPatchUpdateOnly: isPatchUpdateOnly
  };
})
.controller('templateBrowserCtrl', function (
  $scope, $state, $stateParams, $http, $log, $q, $timeout, ConfigURLs,
  CustomFields, FeaturedTemplates, Feeds, Modal, Onboarding, Providers,
  Searches, Session, Slides, Templates, Toast
) {
  let vm = this;
  let logPrefix = '[Template Browser] - ';

  let firstTemplateSelected = false;

  let canReadFeeds = Session.hasPermission('content.feeds.read');
  let canReadSlides = Session.hasPermission('content.slides.read');

  // Coming from edit slide page $stateParams.templateId holds the id of the
  // template we edited. If we had one, select it here in TB as well.
  let deregister = $scope.$on('$viewContentLoaded', () => {
    if ($stateParams.templateId) {
      let template = Templates.byId($stateParams.templateId);
      if (template.internal) {
        deregister();
        return;
      }
      if (template.deprecated) vm.showDeprecated = true;
      vm.selectTemplate(template);
      vm.scrollToResource = $stateParams.templateId;
      $state.params.templateId = '';
    }
    deregister();
  });

  // Sometimes Drag'n'Drop wants to know the custom fields before we get them.
  // We select a template immediately as we navigate to the edit-slide page
  // when clicking on use template button.
  CustomFields.getFields();

  vm.templates = Templates.all();
  vm.feeds = canReadFeeds ? Feeds.all() : [];
  vm.providers = Providers.all();
  vm.allFeeds = Feeds;
  vm.slides = canReadSlides ? Slides.all() : [];

  vm.selectedTemplate = null;
  vm.noInternalTemplates = [];
  vm.filteredTemplates = [];
  vm.provisionedTemplates = [];
  vm.categories = [];
  vm.features = [];
  vm.allFeedTypes = [];
  vm.showAvailable = true;

  vm.newOrUpdatedTemplates = [];

  vm.loadingPromise = $q.all([
    vm.templates.promise, vm.providers.promise, vm.slides.promise
  ]);

  // Map for icons
  vm.iconClasses = {
    // Orientations
    landscape       : 'entypo-doc-landscape',
    portrait        : 'entypo-doc',
    // Categories
    business        : 'entypo-briefcase',
    local           : 'entypo-location',
    media           : 'entypo-camera',
    menus           : 'entypo-leaf',
    messaging       : 'entypo-chat',
    miscellaneous   : 'entypo-docs',
    news            : 'entypo-newspaper',
    operations      : 'entypo-rocket',
    social          : 'pxn-icon-social',
    travel          : 'entypo-suitcase',
    // Features
    branded         : 'pxn-icon-tm',
    'custom-layout' : 'pxn-icon-layout',
    date            : 'entypo-calendar',
    image           : 'entypo-picture',
    'long-text'     : 'entypo-book-open',
    markdown        : 'pxn-icon-markdown',
    'qr-code'       : 'pxn-icon-qr',
    'short-text'    : 'entypo-doc-text',
    time            : 'entypo-clock',
    uhd             : 'pxn-icon-uhd',
    video           : 'entypo-video',
    'web-content'   : 'entypo-network',
    // Content fields
    color           : 'entypo-palette',
    feed            : 'entypo-rss',
    number          : 'entypo-cc-zero',
    select          : 'entypo-menu',
    table           : 'entypo-layout',
    text            : 'entypo-book',
    textarea        : 'entypo-book-open'
  };

  // Friendly names
  vm.friendlyName = {
    // Categories
    business        : 'Business',
    local           : 'Local',
    media           : 'Media',
    menus           : 'Menus',
    messaging       : 'Messaging',
    miscellaneous   : 'Miscellaneous',
    news            : 'News',
    operations      : 'Operations',
    social          : 'Social',
    travel          : 'Travel',
    // Features
    branded         : 'Branded',
    'custom-layout' : 'Custom layout',
    date            : 'Date',
    image           : 'Image',
    'long-text'     : 'Long text',
    markdown        : 'Markdown',
    'qr-code'       : 'QR code',
    'short-text'    : 'Short text',
    time            : 'Time',
    uhd             : 'Ultra HD',
    video           : 'Video',
    'web-content'   : 'Web content'
  };

  // Array to populate with custom category items
  vm.featuredCategories = [];

  if ($stateParams.tbSearch) {
    vm.search = $stateParams.tbSearch;
    vm.showAvailable = $stateParams.tbSearch._showAvailableTemplates;
    vm.showDeprecated = $stateParams.tbSearch._showDeprecatedTemplates;
  } else {
    vm.search = Searches.create({
      model: 'template',
      filters: {
        landscape:            { entries: [] },
        portrait:             { entries: [] },
        friendlyName:         { entries: [], matchesPartial: true },
        feedDomains:          { entries: [], matchesPartial: true },
        categories:           { entries: [], matchesPartial: true },
        customCategories:     { entries: [], matchesPartial: true },
        features:             { entries: [], matchesPartial: true },
      }
    });
  }

  // Returns TRUE if the selected template is enabled on the user's account
  $scope.isTemplateEnabled = function () {
    return (
      _.contains(vm.provisionedTemplates, vm.selectedTemplate) &&
      _.contains(Session.current.account.templates, vm.selectedTemplate._id) &&
      !vm.selectedTemplate._available
    );
  };

  vm.removeFromAccount = function () {
    let modalOpts = {
      templateUrl: 'utility/modal-templates/confirmation.jade',
      scopeData: {
        message: `Would like to remove ${
            vm.selectedTemplate.friendlyName
          } template from your account?`,
        irreversible: vm.selectedTemplate.deprecated,
        positiveButton: 'Remove from account',
        negativeButton: 'Cancel'
      },
      dismissable: {
        backButton: false, escape: true, backgroundClick: false
      }
    };

    new Modal(modalOpts)
      .show()
      .then(function () {
        $http
          .delete(
            ConfigURLs.toggleTemplateForAccount(
              Session.current.account._id,
              vm.selectedTemplate._id
            )
          )
          .then((res) => {
            Session.current.account.templates = res.data.templates;
            Session.current.account.__v = res.data.__v;
            vm.provisionedTemplates = vm.getProvisionedTemplates();
            if (!vm.selectedTemplate.deprecated) {
              vm.selectedTemplate._available = true;
            }
            Toast.makeSuccess(
              `${vm.selectedTemplate.friendlyName} removed successfully`
            );
          })
          .catch((err) => {
            $log.error(err);
          });
      })
      .catch((err) => {
        Toast.makeError(err);
      });
  };

  // Add template to account
  vm.addToAccount = function (template) {
    let modalOpts = {
      templateUrl: 'utility/modal-templates/confirmation.jade',
      scopeData: {
        message: `Would like to add ${template.friendlyName} to your account?`,
        positiveButton: 'Add to account',
        negativeButton: 'Cancel'
      },
      dismissable: {
        backButton: false, escape: true, backgroundClick: false
      }
    };

    new Modal(modalOpts)
      .show()
      .then(function () {
        $http
          .put(
            ConfigURLs.toggleTemplateForAccount(
              Session.current.account._id,
              template._id
            )
          )
          .then((res) => {
            Session.current.account.templates = res.data.templates;
            Session.current.account.__v = res.data.__v;
            vm.provisionedTemplates = vm.getProvisionedTemplates();
            // Remove available flag from template
            delete template._available;
            Toast.makeSuccess(
              `${vm.selectedTemplate.friendlyName} added to account successfully`
            );
          })
          .catch((err) => $log.error(err));
      })
      .catch((err) => {
        Toast.makeError(err);
      });
  };

  /**
   * Resets selected template to null
   *
   * @returns {Undefined} - Nothing is returned.
   */
  vm.deselectTemplate = function () {
    if (vm.selectedTemplate) {
      vm.selectedTemplate.selected = false;
      vm.selectedTemplate = null;
    }
  };

  /**
   * Selects the provided template and sets image paths for use with
   * pxn-carousel.
   *
   * @param {Object} template - Template to select.
   * @returns {Undefined} - Nothing is returned.
   */
  vm.selectTemplate = function (template) {
    if (!firstTemplateSelected) {
      vm.selectedTemplate = template;
      vm.selectedTemplate.selected = true;
      firstTemplateSelected = true;
      return;
    }

    if (vm.selectedTemplate) {
      // Check we're not selecting the same template again.
      if (vm.selectedTemplate === template && !template.newOrUpdatedTemplate) {
        return;
      } else {
        // We're not -> deselect current template -> call function again instead
        // of looping through every template of the array to deselect our
        // previously selected template
        vm.deselectTemplate();
        vm.selectTemplate(template);
        return;
      }
    } else {
      // Set template seen for user.
      FeaturedTemplates.setSeen([template]);

      // Update newOrUpdatedTemplates array when selecting a template.
      if (vm.newOrUpdatedTemplates.length) {
        vm.newOrUpdatedTemplates =
          _.without(vm.newOrUpdatedTemplates, template._id);
      }

      // We're selecting a new template.
      vm.selectedTemplate = template;
      vm.selectedTemplate.selected = true;
      $timeout(Onboarding.triggerOverviewResourceAvailable);
    }
  };

  /**
   * Sets the appropriate template property to change the displayed image.
   *
   * @param {Object} imageObj - Must have imageUrl prop.
   * @returns {Undefined} - Nothing to see here.
   */
  vm.selectPreviewImage = function (imageObj) {
    // Check we have a selected template.. We do, but still.
    if (vm.selectedTemplate) {
      // Oh look, we had one..
      vm.selectedTemplate.imageUrl = imageObj.imageUrl;
    }
  };

  /**
   * Runs filters, sets filteredTemplates and resets selectedTemplate if needed.
   *
   * @returns {Undefined} - Nothing is returned.
   */
  vm.runFilters = function () {
    let templatesToFilter = [];
    if (Session.hasPermission('account.templates')) {
      // Option for users with permission to see available templates
      templatesToFilter = vm.showAvailable ?
        vm.noInternalTemplates : vm.provisionedTemplates;

    } else {
      // No permission, no available templates
      templatesToFilter = vm.provisionedTemplates;
    }

    if (vm.showDeprecated) {
      _.each(vm.deprecatedTemplates, (template) => {
        if (!_.includes(templatesToFilter, template)) {
          templatesToFilter.push(template);
        }
      });
    } else {
      _.each(vm.deprecatedTemplates, (template) => {
        templatesToFilter = templatesToFilter.filter((t) => {
          if (t._id !== template._id) return t;
        });
      });
    }

    // Run filters
    vm.filteredTemplates = Searches.runFilters(vm.search, templatesToFilter);

    // Select first template in main carousel
    if (!firstTemplateSelected) {
      vm.selectTemplate(
        vm.newOrUpdatedTemplates.length ?
        Templates.byId(vm.newOrUpdatedTemplates[0]) : vm.filteredTemplates[0]
      );
    }

    // If only one template available after filtering automatically select it.
    if (vm.filteredTemplates.length === 1) {
      vm.selectTemplate(vm.filteredTemplates[0]);
    }

    // If we have a template selected and it is not the only one...
    if (vm.selectedTemplate && vm.filteredTemplates.length !== 1) {

      // ... deselect it if we dont have any result.
      if (vm.filteredTemplates.length === 0) { vm.deselectTemplate(); }

      // When filtered array doesn't contain the previously selected template
      // deselect it.
      let containsSelected;
      // eslint-disable-next-line consistent-return
      _.each(vm.filteredTemplates, function (template) {
        if (vm.selectedTemplate === template) {
          containsSelected = true;
          return false;
        } else {
          containsSelected = false;
        }
      });
      if (!containsSelected) {
        vm.deselectTemplate();
      }
    }
  };

  /**
   * Reset filter fields and selected template
   *
   * @returns {Undefined} - Nothing is returned.
   */
  vm.resetFilters = function () {
    vm.search.filters.landscape.entries = [];
    vm.search.filters.portrait.entries = [];
    vm.search.filters.feedDomains.entries = [];
    vm.search.filters.friendlyName.entries = [];
    vm.search.filters.categories.entries = [];
    vm.search.filters.customCategories.entries = [];
    vm.search.filters.features.entries = [];

    // Show available templates by default
    vm.showAvailable = true;

    vm.deselectTemplate();

    // FilteredTemplates only gets a copy of original arr so it can't be mutated
    vm.filteredTemplates = vm.noInternalTemplates.slice();
    $scope.$broadcast('template-browser:reset-filters');
    vm.runFilters();
  };

  /**
   * Navigates away to create a new resource with the selected template.
   *
   * @param {string} id - Id of selected template.
   * @param {string} create - Resource to create with template.
   * @returns {Undefined} - Nothing is returned.
   */
  vm.useTemplate = function (id, create) {
    vm.search._showAvailableTemplates = vm.showAvailable;
    vm.search._showDeprecatedTemplates = vm.showDeprecated;
    switch (create) {
      case 'slide':
        $state.go(
          'new-slide', {
            templateId: id,
            fromState: 'template-browser',
            tbSearch: vm.search,
          }
        );
        break;
      case 'takeover':
        $state.go('takeovers', { dynamicTemplateId: id, tbSearch: vm.search });
        break;
      default:
        break;
    }
  };

  /**
   * Sets all template seen.
   *
   * @returns {Undefined} - Nothing is returned.
   */
  vm.setAllAsSeen = function () {
    FeaturedTemplates.setSeen(Session.hasPermission('account.templates') ?
      vm.noInternalTemplates : vm.provisionedTemplates);
    vm.newOrUpdatedTemplates = [];
  };

  vm.createdFeedTypes = [];
  // Populates used feeds array to display users feeds.
  canReadFeeds && vm.feeds.promise.then(() => {
    _.each(vm.feeds, function (feed) {
      vm.createdFeedTypes.push(feed.domain);
    });
    vm.createdFeedTypes = _.uniq(vm.createdFeedTypes);
  });

  /**
   * Returns all templates provisioned for the current account.
   *
   * @returns {Array} - Provisioned templates for account.
   */
  vm.getProvisionedTemplates = function () {
    return Templates.getProvisionedTemplatesForAccount(
      vm.templates, Session.current.account
    ).filter((t) => !t.internal);
  };

  vm.providersMap = {};

  vm.deprecatedTemplates = [];
  vm.provisionedCategories = [];
  vm.provisionedFeatures = [];
  vm.provisionedFeedTypes = [];

  vm.templateIdsInUse = [];

  $q.all([
    vm.templates.promise,
    vm.providers.promise,
    vm.slides.promise
  ]).then(() => {
    _.each(vm.slides, (slide) => {
      if (slide.template) {
        vm.templateIdsInUse.push(slide.template._id);
      }
    });

    vm.templateIdsInUse = _.uniq(vm.templateIdsInUse);

    vm.provisionedProviders = Providers.getProvisionedProvidersForAccount(
      vm.providers, Session.current.account
    );

    // Create providers map
    _.each(vm.providers, function (p) {
      if (
        !p.private ||
        (p.private && _.find(vm.provisionedProviders, { _id: p._id }))
      ) {
        vm.providersMap[p.domain] = p.name;
      }
    });

    vm.provisionedTemplates = vm.getProvisionedTemplates();

    // eslint-disable-next-line
    _.each(vm.templates, function (template) {
      if (template.internal || template.isLocal()) return true;
      if (template.deprecated) vm.deprecatedTemplates.push(template);

      let isProvisioned = _.includes(vm.provisionedTemplates, template);

      vm.noInternalTemplates.push(template);

      // Set flag on templates available
      template._available = !_.includes(
        Session.current.account.templates, template._id
      ) && !template.deprecated;

      if (template.previewImageCount) {
        // Set imageUrl for featured image
        template.imageUrl =
          'url("/templates/' +
          `${template.domain}/${template.name}/${template.version}` +
          '/assets/preview-images/1.jpg")';
        template.previewImageUrls = [];
        if (template.previewImageCount > 1) {
          // Sets the rest of preview images
          for (let i = 1; i <= template.previewImageCount; i++) {
            const url = 'url("/templates/' +
              `${template.domain}/${template.name}/${template.version}` +
              `/assets/preview-images/${i}.jpg")`;

            template.previewImageUrls.push({ imageUrl: url });
          }
        }
      }

      if (!template.previewImageUrls) {
        // We don't, why not? Tell somebody front-end if you see this log.
        $log.debug(logPrefix + 'No preview image for ', template.name);
      }

      // Add any custom categories to the friendly name and icon classes maps.
      _.each(template.customCategories, function (customCategory) {
        const prefixedKebabCaseCategory =
          'custom-' + _.kebabCase(customCategory);
        vm.friendlyName[prefixedKebabCaseCategory] = customCategory;
        // It is necessary to add an icon to the category to have the pseudo
        // element created. The star icon then becomes the fallback and we can
        // change it via customisation.
        vm.iconClasses[prefixedKebabCaseCategory] =
          prefixedKebabCaseCategory + ' entypo-star';
        vm.featuredCategories.push(prefixedKebabCaseCategory);
      });
      _.each(template.categories, function (category) {
        vm.categories.push(category);
        if (isProvisioned) vm.provisionedCategories.push(category);
      });
      _.each(template.features, function (feature) {
        vm.features.push(feature);
        if (isProvisioned) vm.provisionedFeatures.push(feature);
      });

      template.feedDomains = [];

      if (canReadFeeds) {
        _.each(template.contentFields, function (field) {
          if (field.type === 'feed') {
            // Only add feed type if the provider is enabled on the account
            if (angular.isArray(field.domain)) {
              _.each(field.domain, function (d) {
                if (vm.providersMap[d]) {
                  template.feedDomains.push(d);
                  vm.allFeedTypes.push(d);
                  if (isProvisioned) vm.provisionedFeedTypes.push(d);
                }
              });
            } else if (vm.providersMap[field.domain]) {
              template.feedDomains.push(field.domain);
              vm.allFeedTypes.push(field.domain);
              if (isProvisioned) vm.provisionedFeedTypes.push(field.domain);
            }
          }
        });
      }

      // Creates orientation properties for filters
      template.landscape = [template.orientations.landscape];
      template.portrait = [template.orientations.portrait];

      if (template.landscape[0] && !template.portrait[0]) {
        template.orientationSupport = 'landscapeOnly';
      } else if (!template.landscape[0] && template.portrait[0]) {
        template.orientationSupport = 'portraitOnly';
      } else if (template.landscape[0] && template.portrait[0]) {
        template.orientationSupport = 'bothSupported';
      }

      // Set newTemplate property on template to display ribbons
      if (!FeaturedTemplates.hasUserSeen(template)) {
        if (FeaturedTemplates.seenPreviousVersion(template)) {
          if (FeaturedTemplates.hasBeenUpdated(template)) {
            template.newOrUpdatedTemplate = 'updated';
            template._featured = true;
            if (
              Session.hasPermission('account.templates') ||
              (!Session.hasPermission('account.templates') && isProvisioned)
            ) vm.newOrUpdatedTemplates.push(template._id);
          }
        } else {
          template.newOrUpdatedTemplate = 'new';
          template._featured = true;
          if (
            Session.hasPermission('account.templates') ||
            (!Session.hasPermission('account.templates') && isProvisioned)
          ) vm.newOrUpdatedTemplates.push(template._id);
        }
      }
    });

    // Sorting in js so featured categories can be moved to the front of the
    // array if there's any.
    vm.categories = _.uniq(vm.categories).sort();

    if (vm.featuredCategories.length) {
      // Unique to make sure we don't end up with duplicates
      vm.categories = _.uniq(
        // Also sort featured categories
        vm.featuredCategories.sort().concat(vm.categories)
      );
    }

    vm.features = _.uniq(vm.features);
    vm.allFeedTypes = _.uniq(vm.allFeedTypes);

    vm.provisionedCategories = _.uniq(vm.provisionedCategories);
    vm.provisionedFeatures = _.uniq(vm.provisionedFeatures);
    vm.provisionedFeedTypes = _.uniq(vm.provisionedFeedTypes);

    vm.runFilters();
  });

  /**
   * My feeds filter
   *
   * @param {string} type - Name of feed type.
   * @param {Boolean} option - True if multiple selection allowed.
   * @returns {Undefined} - Nothing is returned.
   */
  vm.myFeedClicked = function (type, option) {
    if (option.multiple) {
      // Future proofing when multiple feed domains will be available to display
      if (_.includes(vm.search.filters.feedDomains.entries, type)) {
        // It was so we remove it from the filters.
        vm.search.filters.feedDomains.entries =
          vm.search.filters.feedDomains.entries.filter(i => i !== type);
      } else {
        // Add field type to filter entries
        vm.search.filters.feedDomains.entries.push(type);
      }
    } else {
      // If we can only select one feed we should be able to toggle it's
      // selected state
      // eslint-disable-next-line no-lonely-if
      if (vm.search.filters.feedDomains.entries[0] === type) {
        vm.search.filters.feedDomains.entries = [];
      } else {
        vm.search.filters.feedDomains.entries = [type];
      }
    }
    vm.runFilters();
  };

  /**
   * Orientation filter
   *
   * @param {Object} $event - $event
   * @returns {Undefined} - Nothing is returned.
   */
  vm.orientationClicked = function ($event) {
    let orientation = $event.target.innerHTML.toLowerCase();

    if (!vm.search.filters[orientation].entries.length) {
      vm.search.filters[orientation].entries = [true];
    } else {
      vm.search.filters[orientation].entries = [];
    }
    // Running filters
    vm.runFilters();
  };

  function toggleFilter (filter, val) {
    // Update correct filters when toggling normal or featured categories
    if (filter === 'categories' && vm.featuredCategories.indexOf(val) !== -1) {
      // Filtering by a custom category
      // update filter and value as the template will only have the friendly
      // name in the template.json as custom category.
      filter = 'customCategories';
      val = vm.friendlyName[val];
    }
    // Check if the filter has been applied already
    if (_.includes(vm.search.filters[filter].entries, val)) {
      // It was so we remove it from the filters.
      vm.search.filters[filter].entries =
        vm.search.filters[filter].entries.filter(i => i !== val);
    } else {
      // Add value to filter entries
      vm.search.filters[filter].entries.push(val);
    }
  }

  vm.filterClicked = function (filter, value) {
    toggleFilter(filter, value);
    vm.runFilters();
  };

  $scope.$on('$stateChangeStart', vm.deselectTemplate);

  $scope.$on('$destroy', function () {
    _.each(vm.templates, (template) => {
      if (template.newOrUpdatedTemplate) delete template.newOrUpdatedTemplate;
      if (template._featured) delete template._featured;
    });
  });
});
