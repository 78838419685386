angular.module('flare.ui', [])

.directive('sticky', function ($document, $timeout, Measure) {
  /**
   * This directive causes an element to stick to the top of the window when
   * a user scrolls. It works by setting the transform: translateY property, so
   * may require a container element if the target already has a trasnform
   * style. An optional `sticky-margin` attribute can be provided which sets
   * a pixel value for (virtual) top margin of the element when it's displaced.
   */
  return {
    restrict: 'A',

    link: function (scope, element, attributes, controller) {
      // Amount we've translated by.
      var translateAmount = 0;
      var margin          = parseInt(attributes.stickyMargin) || 0;
      var throttle        = false;
      var MAX_FREQ        = 4; // Hz

      function checkSticky (event) {
        if (throttle) return;

        var top = Measure.element(element).top;

        if (top !== margin) {
          translateAmount -= (top - margin);
          translateAmount = Math.max(0, translateAmount);
          element.css({
            transform: 'translateY(' + translateAmount + 'px)',
          });
        }

        $timeout(function () {
          throttle = false;
        }, 1000 / MAX_FREQ);

      }
      $document.on('scroll', checkSticky);


      // PS needs a slightly different function and event listener to make this work.
      function checkStickyPsScroll (event) {
        if (throttle) return;

        var top = Measure.element(element).top;
        top = top - 48; // Remove "$contentHeaderHeight" (height of top bar)

        if (top !== margin) {
          translateAmount -= (top - margin);
          translateAmount = Math.max(0, translateAmount);
          element.css({
            transform: 'translateY(' + translateAmount + 'px)',
          });
        }

        $timeout(function () {
          throttle = false;
        }, 1000 / MAX_FREQ);

      }
      // Add the listener to the direct parent as the ps-scroll-y event no
      // longer bubbles to the document.
      element.parent()[0].addEventListener('ps-scroll-y', checkStickyPsScroll);

      // Destroy the event listeners when the scope is destroyed.
      scope.$on('$destroy', function () {
        $document.off('scroll', checkSticky);
        element.parent()[0].removeEventListener('ps-scroll-y', checkStickyPsScroll);
      });

    },
  };
})

.component(
  'pxnAccordion',
  {
    templateUrl: 'utility/pxn-accordion.jade',
    bindings: {
      uniqueKey: '@',
      defaultClosed: '=',
      openIf: '&'
    },
    transclude: {
      title: 'accordionTitle',
      buttons: '?accordionButtons',
      body: 'accordionBody'
    },
    controllerAs: 'vm',
    controller: function (
      $rootScope,
      $localForage,
      $state,
      $scope,
      $attrs,
      $element,
      $timeout,
      $log
    ) {

      var vm = this;
      vm.storageKey = `${$state.current.name}-accordions`; // e.g. devices.
      let preventAnimEl, defValue;

      vm.$onInit = function () {
        defValue =
          typeof vm.defaultClosed === 'boolean'
            ? !vm.defaultClosed
            : !$attrs.defaultClosed;

        // Call to get the initial states if we're using local storage.
        if (!$attrs.ignoreStorage) {
          getAccordionStates()
            .then(function () {
              // Because ng-if
              return $timeout(angular.noop, 0);
            })
            .then(function () {
              preventAnimEl = $element[0].querySelector(
                '[prevent-unsquash-enter]'
              );
              if (preventAnimEl) {
                preventAnimEl.removeAttribute('prevent-unsquash-enter');
              }
            })
            .catch((err)=> {
              $log.error(err);
            });
        } else {
          vm.state = { ['vm.uniqueKey']: defValue };
          preventAnimEl = $element[0].querySelector('[prevent-unsquash-enter]');
          if (preventAnimEl) {
            preventAnimEl.removeAttribute('prevent-unsquash-enter');
          }
        }
      }

      const $accordionHeading =
        angular.element($element[0].querySelector('.accordion--heading'));

      $rootScope.$on('onBoarding:open-accordion', function (e, accordionKey) {
        if (vm.state[accordionKey] === true) return;
        vm.state[accordionKey] = true;
        // Save the new version of the states object to the local storage.
        saveAccordionStates();
      });

      /**
       * Keep accordion open if required.
       * @return {boolean} - TRUE if accordion should be open.
       */
      vm.forceOpen = () => {
        if (!vm.openIf()) {
          $accordionHeading.removeClass('forced-open');
          return false; // Accordion is not forced open, return.
        } else {
          $accordionHeading.addClass('forced-open');
        }

        // Accordion should be open, update state indicator.
        if (vm.state) vm.state[vm.uniqueKey] = true;
        return true;
      };

      /**
       * Saves the current accordion states object to the users local storage.
       * @return {string}      Undefined.
       */
      function saveAccordionStates () {
        $localForage.setItem(vm.storageKey, vm.state);
      }

      /**
       * Retrieves the saved accordion states and store on vm.[vm.storageKey]
       * @return {Promise<undefined>} Promise object that resolves when data is
       *                              returned.
       */
      function getAccordionStates () {
        return $localForage
          .getItem(vm.storageKey)
          .then(function (value) {
            if (value != null && typeof value === 'object') {
              vm.state = value;
            } else {
              vm.state = {};
            }
            // Check we have a value for our accordion, if not default to open.
            if (typeof vm.state[vm.uniqueKey] !== 'boolean') {
              vm.state[vm.uniqueKey] = defValue;
            }
          })
          .catch((err) => {
            $log.error(err);
          });
      }

      /**
       * Switches the clicked accordions state and saves the state of all
       * accordions locally.
       * @return {[undefined]}         Undefined.
       */
      vm.reverseAccordion = function reverseAccordion (e) {
        if (vm.openIf()) return; // The accordion should stay open, return early
        // Check that the click event did not come from a pxn-button or
        // pxn-dropdown-menu, we don't want to honour those.
        var shouldContinue = true;
        _.each(e.path, function (elem, i) {
          if (elem.nodeName === 'PXN-BUTTON') {
            shouldContinue = false;
          } else if (elem.nodeName === 'PXN-DROPDOWN-MENU') {
            shouldContinue = false;
          }
          // Return false and end loop early if we find a pxn-button
          return shouldContinue;
        });
        if (!shouldContinue) {
          // The click came from a button on the heading so ignore it.
          return;
        }

        // If we're not saving states simply switch the existing state and leave
        let newState = vm.state[vm.uniqueKey] = !vm.state[vm.uniqueKey];
        if (!$attrs.ignoreStorage) {
          // Because multiple accordions on a page will each request a copy of
          // the accordian state object, they may have modified it since we
          // initially requested it. As such we need to update our version of
          // the object before modifying and saving our state.
          getAccordionStates()
            .then(function () {
              // Now we have the latest data, modify the accordion we're using.
              vm.state[vm.uniqueKey] = newState;
              // Save the new version of the states object to the local storage.
              saveAccordionStates();
              // Check if we need to remeasure any scroll areas as page layout
              // will have changed.
              $scope.$emit('remeasure-for-scrollbars');
              // Emit event with the changed accordion details so additional
              // code can run if required.
              $scope.$emit('accordion-state-change', {
                accordionKey: vm.uniqueKey,
                isOpen: vm.state[vm.uniqueKey],
              });
            })
            .catch((err) => {
              $log.error(err);
            });
        }
      };

    }
  }
)

.factory('Breakpoints', function breakpointsFactoryFn ($window, $document) {

  function getCurrentBreakpoint () {
    return $window.getComputedStyle(
      $document[0].querySelector('.flare-body'), ':before'
    )
    .getPropertyValue('content')
    .replace(/\"/g, '');
  }

  function greaterOrEqual (breakpoint) {
    var breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];
    var currentBreakpointIndex =
      breakpoints.indexOf(getCurrentBreakpoint());
    var desiredBreakpointIndex =
      breakpoints.indexOf(breakpoint);
    return desiredBreakpointIndex >= currentBreakpointIndex;
  }

  function smallerThan (breakpoint) {
    var breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];
    var currentBreakpointIndex =
      breakpoints.indexOf(getCurrentBreakpoint());
    var desiredBreakpointIndex =
      breakpoints.indexOf(breakpoint);
    return desiredBreakpointIndex > currentBreakpointIndex;
  }

  var factory = {
    getCurrent: getCurrentBreakpoint,
    current: getCurrentBreakpoint(),
    greaterOrEqual: greaterOrEqual,
    smallerThan: smallerThan
  };

  // Update the factory current when the window is resized.
  $window.addEventListener('resize', function () {
    factory.current = getCurrentBreakpoint();
  });

  return factory;
});
