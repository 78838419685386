function dateTimeFormat () {
  return {
    require: 'ngModel',
    link: function ($scope, $elem, $attrs, $ctrl) {
      var ngModelCtrl = $ctrl;

      // worth noting that angular is already parsing and formatting into &
      // from Date objects, saving us the effort

      ngModelCtrl.$formatters.push(function (mins) {
        // model -> view
        var hrs = mins % 60;
        mins -= hrs * 60;
        var date = new Date(1970, 0, 1, hrs, mins);
        return date;
      });

      ngModelCtrl.$parsers.push(function (date) {
        // view -> model
        return date.getMinutes() + date.getHours() * 60;
      });
    }
  };
}

angular
  .module('sender')
  .directive('dateTimeFormat', dateTimeFormat);