angular.module('flare.receiver.progress', [])

.component('pxnProgressWheel', {
  template: `
  <svg targetmlns="http://www.w3.org/2000/svg" version="1.1" viewbox="0 0 100 100"
    class="progress-wheel">
    <circle class="outer" cx="50" cy="50" r="40"
      stroke="#ffffff" stroke-width="12" fill="none" />
    <circle class="inner" cx="50" cy="50" r="40"
      stroke="#7d0079" stroke-width="8" fill="none" />
  </svg>
  `,
  bindings: {
    percentDone: '<',
    numDone: '<',
    totalToDo: '<',
  },
  controller: function progressWheelController ($element) {
    // We animate dashoffset from 100% of the circumference to 200% of the
    // circumference. Because each dash is 1 circumference long, 100% offset
    // means the dash is completely invisible, and 200% means it's completely
    // visible.

    // The wheel's circumference. Unitless relative units, r=40 so this is 80π
    const C = 251.327412287;
    const animDuration = 1000; // time in ms to complete an increment.

    function easingFn (target, time) {
      /* eslint-disable no-magic-numbers */
      // return target * (4 * (Math.pow((time - 0.5), 3)) + 0.5);
      return target * time;
      /* eslint-enable no-magic-numbers */
    }

    let startTime = null;  // the time the current increment started
    let startPoint = C;    // the position
    let currentOffset = C; // Current dash offset
    let currentTargetPercent = null; // Where we're aiming to get to
    let currentTargetOffset = null;
    let currentDiff = null;
    let targetPercent = 0;
    let currentlyAnimating = false;

    let svg;

    function frameAnimation () {
      svg = svg || $element[0].querySelector('svg');
      currentlyAnimating = true;
      if (targetPercent === currentTargetPercent) {
        // frame of an existing animation
        let elapsedTime = Date.now() - startTime;
        let animFractionElapsed = Math.min(1, elapsedTime / animDuration);
        currentOffset = startPoint + easingFn(currentDiff, animFractionElapsed);
        svg.style['stroke-dashoffset'] = currentOffset;
      } else {
        // We have a new target to animate towards
        startTime = Date.now();
        startPoint = currentOffset;
        currentTargetPercent = targetPercent;
        currentTargetOffset = C * (1 + (targetPercent / 100));
        currentDiff = currentTargetOffset - currentOffset;
      }

      if (currentOffset !== currentTargetOffset) {
        requestAnimationFrame(frameAnimation);
      } else {
        currentlyAnimating = false;
      }
    }

    this.$onChanges = function progressChanges (changes) {
      if (changes.percentDone) {
        targetPercent = this.percentDone;
        if (!currentlyAnimating) requestAnimationFrame(frameAnimation);
      }
    };
  }
})

.component('electronDownloadProgress', {
  template:`
  <pxn-progress-wheel
    percent-done="$ctrl.percentDone"
    ng-if="$ctrl.show"
  ></pxn-progress-wheel>
  `,
  controller: class ElectronDownloadProgressController {
    constructor (Player, $interval, $timeout, $scope) {
      this.ipc = Player.ipc;
      this.percentDone = 0;
      this.$interval = $interval;
      this.$timeout = $timeout;
      this.$scope = $scope;
    }

    $onInit () {
      this.ipc.on('downloadProgress', (sender, p)=> {
        this.show = true;
        this.$scope.$apply(()=> {
          this.percentDone = p;
        });
        if (this.percentDone === 100 || this.percentDone === 0) {
          // Wrap this in a timeout, otherwise the ng-if causes the change in
          // percentdone not to get through, so the wheel never gets too 100%;
          this.$timeout(()=> {
            this.show = false;
          }, 0);
        }
      });
    }
  }
});