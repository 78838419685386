angular.module('flare.localisation.components', [])

.component('pxnContentFieldOriginal', {
  bindings: {
    value: '=',
    field: '<fieldOptions'
  },
  controller: function ($element, $compile, $scope, Feeds) {
    var $ctrl = this,
      feeds = Feeds.all(),
      feedsReady;

    // Set flag when feeds promise resolves
    feeds.promise.then(()=> {
      feedsReady = true;
    });

    $ctrl.getKeyByValue =  function getKeyByValue (oPairs, value) {
      var result = 'unknown';
      _.each(oPairs, function (v, key) {
        if (value === v) {
          result = key;
          return false;
        }
      });
      return result;
    };

    $ctrl.getFeedName = function getFeedName (feedId) {
      return feedId && feedsReady ?
        _.findWhere(feeds, { _id: feedId }).name : 'unknown';
    };

    $ctrl.format = function (rowData, key) {
      let value;

      if ($ctrl.selectTypes[key]) {
        // Select - Get the key for the select's value
        value = $ctrl.getKeyByValue($ctrl.selectTypes[key], rowData[key]);
      } else if (_.includes($ctrl.mediaTypes, key)) {
        // Media input - We need to display the name of the image
        value = rowData[key].name;
      } else if (typeof rowData[key] === 'boolean') {
        // Boolean values
        value = rowData[key] ? 'Active' : 'Inactive';
      } else {
        // Simply display primitive type values
        value = rowData[key];
      }

      return value ? value : 'unknown';
    };

    let defaultValue = '#00000000';
    /* eslint-disable no-magic-numbers */
    $ctrl.hexFromRgba = (value) => {
      // We'll just leave null or undefined as they are.
      if (value == null) return 'transparent';
      // we will receive an RGBA value for new/updated slides but old slides
      // could still have a hex content value.
      if (_.startsWith(value, '#')) {
        // We have a HEX/HEXA value, so we can just provide it back.
        return value;
      }

      // If we're here then we had and RGBA value.
      // Break the value into its four parts.
      let partsRegex = /rgba\((\d+),(\d+),(\d+),(\d+\.?\d*)\)/;
      let match = value.match(partsRegex);
      // This covers "transparent" as well as any malformed data.
      if (!match) return defaultValue || '#000000';

      let parts = [match[1], match[2], match[3], match[4]];

      // We now have the RGBA parts ready for conversion to HEXA.
      // Start by converting the first 3.
      // NOTE: We prefix with an extra 0 so e.g. "f" will still output "0f".
      parts[0] =
        ('0' + parseInt(parts[0]).toString(16)).slice(-2).toUpperCase();
      parts[1] =
        ('0' + parseInt(parts[1]).toString(16)).slice(-2).toUpperCase();
      parts[2] =
        ('0' + parseInt(parts[2]).toString(16)).slice(-2).toUpperCase();

      // For the alpha value we will have a value between 0-1, we need to
      // convert this to base 16.
      parts[3] = (
        '0' + parseInt(parseFloat(parts[3]) * 255).toString(16)
      ).slice(-2);

      if (parts[3] === 'ff') {
        // `ff` is full opacity and will cause our "X% transparent" text to
        // be displayed if appended, but a message saying "0% transparent"
        // is a bit odd, so we remove it.
        parts[3] = '';
      } else if (parts[3] === '00') {
        // The rgb values doesn't matter if the alpha is 0, return transparent
        return 'transparent';
      } else {
        parts[3] = ` (${
          Math.round((1 - (parseInt(parts[3], 16) / 255)) * 100)
        }% transparent)`;
      }
      return '#' + parts.join('');
    };
    /* eslint-enable no-magic-numbers */

    $ctrl.$onInit = function onInit () {
      var templateString = '';
      switch ($ctrl.field.type) {
        case 'text':
          templateString = '<blockquote><span><strong>\
            {{$ctrl.value}}\
            </strong></span></blockquote>';
          break;
        case 'color':
          templateString = '<blockquote>\
              <div class="pxn-content-field-original--color" >\
                <div class="checkered-background">\
                  <div class="watch" ng-style="{backgroundColor: $ctrl.value}">\
                  </div>\
                </div>\
                <strong>{{$ctrl.hexFromRgba($ctrl.value)}}</strong>\
              </div>\
            </blockquote>';
          break;
        case 'image':
          templateString = '<blockquote>\
              <span><strong>{{$ctrl.value.name}}</strong></span>\
              <div class="pxn-content-field-original--thumbnail"\
                ng-attr-pxn-background-blank-color="!$ctrl.value"\
                pxn-background="$ctrl.value.path">\
            </blockquote>';
          break;
        case 'video':
          templateString = '<blockquote>\
              <span><strong>{{$ctrl.value.name}}</strong></span>\
              <div class="pxn-content-field-original--thumbnail"\
                pxn-background="\'/uploads/\'+ $ctrl.value._id +\'/thumbnail\'">\
            </blockquote>';
          break;
        case 'checkbox':
          templateString = '<blockquote><span><strong>\
              {{$ctrl.value ? "Active" : "Inactive"}}\
            </strong></span></blockquote>';
          break;
        case 'feed':
          templateString = '<blockquote><span><strong>\
              {{$ctrl.getFeedName($ctrl.value)}}\
            </strong></span></blockquote>';
          break;
        case 'select':
          templateString = '<blockquote><span><strong>\
              {{$ctrl.getKeyByValue($ctrl.field.values, $ctrl.value)}}\
            </strong></span></blockquote>';
          break;
        case 'date':
          templateString = '<blockquote><span><strong>\
              {{$ctrl.value | date:\'dd-MM-yyyy\'}}</strong>\
            </span></blockquote>';
          break;
        case 'date-time':
          templateString = '<blockquote><span><strong>\
              {{$ctrl.value | date:\'dd-MM-yyyy, HH:mm\'}}</strong>\
            </span></blockquote>';
          break;
        case 'table':
          // No cols must be a custom table input, return
          if (!$ctrl.field.cols) return;

          $ctrl.fieldsToDisplay = [];
          $ctrl.selectTypes = {};
          $ctrl.mediaTypes = [];

          _.each($ctrl.field.cols, (colVal) => {
            // Check we don't have a hidden field
            if (colVal.name && colVal.type !== 'hidden') {
              // Type is not hidden, add field to `fieldsToDisplay` array
              $ctrl.fieldsToDisplay.push(_.kebabCase(colVal.name));
              if (colVal.type === 'select') {
                // We need to display key for value, store field's values object
                $ctrl.selectTypes[_.kebabCase(colVal.name)] = colVal.values;
              } else if (colVal.type === 'media') {
                // Media input, add field name to array
                $ctrl.mediaTypes.push(colVal.name);
              }
            }
          });

          // // HARD CODED
          // // Keys to display for wessex water dashboard's table input
          // if (!_.keys($ctrl.field.cols).length) {
          //   $ctrl.fieldsToDisplay = [
          //     'blockDefinition', 'title', 'displayCount',
          //     'body', 'backgroundImage'
          //   ];
          //   $ctrl.mediaTypes.push('backgroundImage');
          // }

          templateString = '<span><strong>\
            <ul class="vgu-bottom-margin" ng-repeat="value in $ctrl.value">\
            <blockquote><div class="grid">\
            <div class="grid-row" ng-if="value.hasOwnProperty(key)" \
              ng-repeat="key in $ctrl.fieldsToDisplay">\
              <div class="vgu-right-padding">\
                <h5 class="input-label no-bottom-margin">\
                  {{key.split("-").join(" ")}}\
                </h5></div>\
            <span class="col-value">\
            {{$ctrl.format(value, key)}}\
            <div ng-if="value[key].path" \
              class="pxn-content-field-original--thumbnail"\
              ng-attr-pxn-background-blank-color="!value"\
              pxn-background="value[key].path"></div>\
            </span></div></div></blockquote></ul></span>';
          break;
        case 'number':
        default:
          templateString = '<blockquote><span><strong>\
              {{$ctrl.value}}\
            </strong></span></blockquote>';
          break;
      }
      var $compiledContent = $compile(angular.element(templateString))($scope);
      $element.append($compiledContent);
    };
  }
});
