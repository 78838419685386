function pxnPeriodInputController ($scope, $attrs) {
  var $ctrl = this;

  $ctrl.$onInit = function () {
    $ctrl.ngModelCtrl.$formatters.push(function (schedule) {
      $ctrl.duration = {
        start: schedule.start,
        end: schedule.end,
        days: schedule.days,
        enabled: schedule.enabled
      };
      return schedule;
    });
    $ctrl.ngModelCtrl.$parsers.push(function (schedule) {
      // need to use value for days from scope as .days is being edited
      // concurrently by a different input (day-selector)
      var scopeVal = $scope.$parent.$eval($attrs.ngModel);
      return {
        start: schedule.start,
        end: schedule.end,
        days: scopeVal.days,
        enabled: scopeVal.enabled
      };
    });
    $ctrl.ngModelCtrl.$validators.duration = function (modelValue, viewValue) {
      if (viewValue.end > viewValue.start) {
        var bool = !!(viewValue.end - viewValue.start >= $ctrl.minDuration);
        return bool;
      } else {
        bool = !!(24 * 60 + viewValue.end - viewValue.start >= $ctrl.minDuration);
        return bool;
      }
    };
  };

  $scope.$watch('$ctrl.duration', function (newObj, oldObj) {
    if (newObj !== oldObj) {
      $ctrl.ngModelCtrl.$setViewValue(angular.copy($ctrl.duration));
    }
  }, true);
}

angular
  .module('sender')
  .controller('pxnPeriodInputController', pxnPeriodInputController);