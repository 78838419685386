angular.module('flare.takeovers.takeover', ['genericResource'])

.factory('Takeovers', function (
  GenericResource, ResourceItem, ConfigURLs, Slides, $timeout, Toast
) {

  class Takeover extends ResourceItem {
    constructor () {
      super(...arguments);
    }

    /**
     * Sets a timeout to delete the takeover after expiry. The timeout promise
     * is both returned and stored on the takeover for easy cancellation if
     * required.
     *
     * @returns {Promise} A promise that will resolve after the CB has run.
     * @memberof Takeover
     */
    setRemovalTimeout () {
      this._removalTimeout = $timeout(() => {
        angular.noop();
      }, new Date(this.lifetime.end).getTime() - new Date().getTime());
      return this._removalTimeout;
    }

    /**
     * Deletes the takeover which will stop it being displayed on devices.
     *
     * @memberof Takeover
     */
    stop () {
      $timeout.cancel(this._removalTimeout);
      Toast.deleteAndNotify(this);
    }
  }

  Takeover.prototype._locals = [
    '_removalTimeout'
  ];

  Takeover.prototype._castFields = {
    slide: Slides
  };

  return new GenericResource(ConfigURLs.takeovers, Takeover);
});