/* eslint-disable no-inner-declarations */
angular
  .module('flare.receiver.animations', [])

  .directive('toasterLineAnimation', function ($log, $sce, $state) {
    return {
      restrict: 'A',
      require: ['?^^flareTemplate', '?^^flareTemplatePreview'],
      link: function (scope, element, attributes, controllers) {
        if (controllers[1] || $state.current.name === 'webshot') {
          // not currently supporting animating in the preview, and don't want
          // to in webshot!
          return;
        }

        var templateCtrl = controllers[0];

        // Available options
        var duration = parseInt(attributes.duration) || 500;
        var staggerDelay = parseInt(attributes.stagger) || 0;
        var postShowDelay = (parseInt(attributes.delay) || 0) + 500;
        var easingString = attributes.easing || 'ease-out';
        // This is used to make the line container (with hidden overflow)
        // slightly larger than the height of a span containing a word, because
        // some glyphs on fonts overflow their bounding boxes (e.g. lowercase g)
        var lineHeightBufferPercent =
        // eslint-disable-next-line no-magic-numbers
          parseFloat(attributes.lineHeightBuffer) || 0.05;

        var lines = [];
        var lineBottoms = [];
        var animations = [];

        // To prevent having to register and run postShow functions to trigger
        // the animations (which can introduce an unintentional stagger), we
        // look for an 'orchestrator' array on the scope to push our animations
        // into. If there isn't one already then we initialise it, and this
        // instance of the directive claims the orchestrator role (which is
        // simply the one that will call 'play' on all the animations by
        // registering a single postShow function). The orchestrator also resets
        // the animations after hide.
        var orchestrator = scope.orchestrator;
        var thisInstanceIsOrchestrator = false;
        if (orchestrator == null) {
          orchestrator = scope.orchestrator = [];
          thisInstanceIsOrchestrator = true;
        }

        function createGhostElement (referenceElementComputedStyle) {
          var ghostElement = document.createElement('div');
          ghostElement.classList.add('ghost-element');
          ghostElement.style.position = 'absolute';
          ghostElement.style.top = '0';
          ghostElement.style.right = '0';
          ghostElement.style.bottom = '0';
          ghostElement.style.left = '0';
          ghostElement.style.paddingLeft =
            referenceElementComputedStyle.paddingLeft;
          ghostElement.style.paddingRight =
            referenceElementComputedStyle.paddingRight;
          ghostElement.style.paddingTop =
            referenceElementComputedStyle.paddingTop;
          ghostElement.style.paddingBottom =
            referenceElementComputedStyle.paddingBottom;
          ghostElement.style.overflow = 'hidden';
          return ghostElement;
        }


        function prepare () {
          var referenceElement = element[0];
          // Element needs position relative so line containers can be
          // positioned within it easily.
          referenceElement.style.position = 'relative';
          var referenceElementComputedStyle = getComputedStyle(
            referenceElement
          );

          // This is safe (i.e. bindings will have been rendered) because we're
          // calling `prepare()` within a preShowPromiseGenerator function.
          var message = element[0].innerHTML;

          // wrap the message in spans
          /**
           * Regex explainer:
           *  Group $1: match any below 1+ times & capture matches as 1 group
           *    Start of line (^)
           *    Whitespace (\s)
           *    Closing HTML tag (<\/\w+>)
           *    Opening HTML tag (can include attrs) (<.*?>)
           *  Group $2
           *    Words and punctuaction, but captured by capturing as much as
           *    possible that isn't a space or the start of an html tag (<)
           *    Group $2 is made optional as the inner html string could end
           *    with a comment left by angular (?)
           *
           * This splits out words, capturing whatever was before them (either a
           * space or an html tag). We then replace with whatever came before,
           * followed by the captured word wrapped in a spam.
           */
          var wrappedMessage = message.replace(
            /((?:^|\s|<\/\w+>|<.*?>)+)([^<\s]+)?/g,
            '$1<span class="animation-word">$2</span>'
          );

          // We need to replace the DOM elements because we' want to make the
          // words invisible having wrapped them in spans. This is so they
          // still take up space, but we can animate copies into view later.
          var trustedMessage = $sce.trustAsHtml(wrappedMessage);
          element.html(trustedMessage);

          // Taking care of hiding existing children to make sure they are not
          // visible in their actual position whilst animating
          // (e.g. lloyds' red square in title)
          _.each(element[0].children, function (child) {
            child.style.visibility = 'hidden';
          });

          // Measure the position of each word so that we can determine where
          // the line breaks occur
          var wordElements = Array.from(
            referenceElement.querySelectorAll('.animation-word')
          );
          var wordBottoms = wordElements.map(
            (el) => el.getBoundingClientRect().bottom
          );

          // Make each word invisible.
          wordElements.forEach((el) => (el.style.visibility = 'hidden'));

          // If the position of the bottom of a word isn't the same as the
          // position of the bottom of the previous word, we assume it's on
          // a new line.
          var lastWordBottom;
          for (var i = 0; i < wordBottoms.length; i++) {
            var wordBottom = wordBottoms[i];
            if (lastWordBottom !== wordBottom) {
              lineBottoms.push(wordBottom);
              lines.push([i]);
              lastWordBottom = wordBottom;
            } else {
              lines[lines.length - 1].push(i);
            }
          }

          // Adds an animation to the element and returns the animation.
          function addLineAnimation (contentElement, lineHeight, staggerI) {
            contentElement.style.willChange = 'transform';
            var startPos = lineHeight ? lineHeight + 'px' : '100%';
            try {
              var animation = contentElement.animate(
                [
                  { opacity: 1, transform: 'translateY(' + startPos + ')' },
                  { opacity: 1, transform: 'translateY(0)' },
                ],
                {
                  fill: 'both',
                  easing: easingString,
                  duration: duration,
                  delay:
                    postShowDelay +
                    (staggerDelay ? staggerI * staggerDelay : 0),
                }
              );
              animation.pause();
              return animation;
            } catch (e) {
              $log.error(
                '[Toaster Line Animation] Error creating animation: '
                + e.message
              );
              return null;
            }
          }

          // Create a ghost element for each line.
          var numGhostElements = lines.length;
          var ghostBottom = null;
          var lineHeight =
            lineBottoms.length > 1 ? lineBottoms[1] - lineBottoms[0] : null;
          for (var j = 0; j < numGhostElements; j++) {
            var forLine = lines[j];
            var newGhostElement = createGhostElement(
              referenceElementComputedStyle
            );

            // We wrap the whole of the content within the ghost element in
            // an enclosing div so we can translate everything down all at once
            newGhostElement.innerHTML =
              '<div class="animation-content">' + trustedMessage + '</div>';
            var allWords = newGhostElement.querySelectorAll('.animation-word');

            // Hide words that aren't part of the line this ghost element
            // represents.
            // eslint-disable-next-line no-loop-func
            allWords.forEach((wordSpan, k) => {
              if (!forLine.includes(k)) {
                wordSpan.style.visibility = 'hidden';
              } else {
                wordSpan.style.visibility = 'visible';
              }
            });
            referenceElement.appendChild(newGhostElement);

            animations.push(
              addLineAnimation(
                newGhostElement.querySelector('.animation-content'),
                lineHeight ? lineHeight * (1 + lineHeightBufferPercent) : null,
                j
              )
            );
            orchestrator.push(animations);

            // When there are mutliple lines, set the bottom if the ghost
            // element to the bottom of the line of text it's animating. We do
            // this by subtracting the bottom of each line from the bottom of
            // the reference element (which we calculate and cache the first)
            // time using the ghost element with `bottom: 0` to measure.
            if (lineBottoms.length > 1) {
              if (ghostBottom === null) {
                ghostBottom = newGhostElement.getBoundingClientRect().bottom;
              }
              newGhostElement.style.bottom =
                ghostBottom -
                (lineBottoms[j] + lineHeight * lineHeightBufferPercent) +
                'px';
            }
          }

        }

        // Only prepare once. Note this isn't a preReady because the slide
        // needs to be visible.
        templateCtrl.preShowPromiseGenerators.push(prepare);
        templateCtrl.postShowFns.push(function () {
          _.pull(templateCtrl.preShowPromiseGenerators, prepare);
        });

        if (thisInstanceIsOrchestrator) {
          // We're the first one to register any animations, so we're claiming
          // the orchestrator role. This just means we have to kick off all
          // animations in the orchestrator array after the slide has shown, and
          // reset them all after it's hidden.
          function animate () {
            var slideAnimations = _.flatten(orchestrator);
            slideAnimations.forEach((animation) => animation.play());
          }
          function resetAnimations () {
            var slideAnimations = _.flatten(orchestrator);
            slideAnimations.forEach((animation) => {
              animation.currentTime = 0;
              animation.pause();
            });
          }
          templateCtrl.postShowFns.push(animate);
          templateCtrl.postHideFns.push(resetAnimations);
        }
      },
    };
  });
