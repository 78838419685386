function pxnDurationInputController ($scope, $element) {
  const $ctrl = this;
  const $container = angular.element(
    $element[0].querySelector('.duration-input--container'));

  function setUpWatchers (input) {
    const el = $element.find('input');
    el.on('focus', () => $container.addClass('duration-input__focussed'));
    el.on('blur', () => $container.removeClass('duration-input__focussed'));
  }

  const oneSecond = 1;
  const oneMinute = oneSecond * 60;
  const oneHour = oneMinute * 60;
  const oneDay = oneHour * 24;

  function extractUnitTimeFromDuration (duration, unit) {
    const out = Math.floor(duration / unit);
    duration -= out * unit;
    return [duration, out];
  }

  $ctrl.$onInit = function () {
    setUpWatchers();
    $ctrl.ngModelCtrl.$formatters.push(function formatDuration (duration) {
      $ctrl.duration = {};
      [duration, $ctrl.duration.days] =
        extractUnitTimeFromDuration(duration, oneDay);
      [duration, $ctrl.duration.hours] =
        extractUnitTimeFromDuration(duration, oneHour);
      [duration, $ctrl.duration.minutes] =
        extractUnitTimeFromDuration(duration, oneMinute);
      [duration, $ctrl.duration.seconds] =
        extractUnitTimeFromDuration(duration, oneSecond);
      return $ctrl.duration;
    });

    $ctrl.ngModelCtrl.$parsers.push(function parseDurationObject (durationObj) {
      return $ctrl.duration.days * oneDay + $ctrl.duration.hours * oneHour +
      $ctrl.duration.minutes * oneMinute + $ctrl.duration.seconds * oneSecond;
    });
  };

  // necessary as nested object looks the same to old vs new comp
  $scope.$watch('$ctrl.duration', function (newObj, oldObj) {
    if (newObj !== oldObj) {
      $ctrl.ngModelCtrl.$setViewValue(angular.copy($ctrl.duration));
    }
  }, true);
}

angular
  .module('sender')
  .controller('pxnDurationInputController', pxnDurationInputController);