angular.module('sender')

.factory('TTY', function ($log, $http) {

  var term;
  var termReady = false;
  var socket;
  var buffer;
  var firstOutput = false;

  var deviceId;
  var init = false;

  var states = {
    NO_SESSION   : 0,
    CONNECTING   : 1,
    CONNECTED    : 2,
    DISCONNECTED : 3,
    RECONNECTING : 4,
  };

  var state = states.NO_SESSION;

  var isReconnect;
  var isContinue;
  var isReset;

  var connectTimeoutMs = 15000;
  var connectTimeout;

  var ack = 0;

  function _toTerminal (data) {
    if (!term || !termReady) buffer += data;
    else term.io.writeUTF16(data);
  }

  function _isConnecting () {
    return state === states.CONNECTING;
  }

  function _isConnected () {
    return state === states.CONNECTED;
  }

  function _setConnectTimeout (enabled) {
    if (connectTimeout) clearTimeout(connectTimeout);
    if (enabled) {
      connectTimeout = setTimeout(function () {
        $log.debug('[hterm] Socket.io failed to connect');
        endSession('server unreachable');
      }, connectTimeoutMs);
    }
  }

  function _syncServer () {
    // Make a request to the device sync-server route - this
    // will sync the browser's server cookie to the server that
    // the device is connected to.
    var headers = {};
    var cookies = '; ' + document.cookie;
    var parts = cookies.split('; flare-xsrf=');
    if (parts.length === 2) {
      headers['X-XSRF-Token'] =
        decodeURIComponent(parts.pop().split(';').shift());
    }
    $log.debug('[hterm] Syncing to device server');
    return $http.get(`/api/devices/${deviceId}/sync-server`)
    .catch(function () {
      $log.warn('[hterm] WARN: Failed to sync to device server');
    });
  }

  function _onTerminalReady () {
    // Set an additional timeout to avoid rapid resizing.
    setTimeout(function () {
      termReady = true;
      // Send terminal size to server.
      FlareTTY.prototype
        .onResize();
      // Release buffered output.
      if (buffer.length) {
        _toTerminal(buffer);
        buffer = '';
      }
    }, 100);
  }

  function _getTerminal () {
    // Get a new or exiting terminal if not existing.
    if (!term) {
      $log.debug('[hterm] Creating new terminal instance');
      term = new hterm.Terminal();
      termReady = false;
      term.onTerminalReady = _onTerminalReady;
    } else {
      $log.debug('[hterm] Using existing terminal instance');
      FlareTTY.prototype
        .onResize();
    }
    // Add the terminal to our element.
    if (!isReconnect) term.decorate(document.getElementById('tty'));
    // Make cursor visible and reset position.
    term.setCursorVisible(state === states.CONNECTED);
    if (isReset) term.setCursorPosition(0, 0);
    // Set preferences.
    term.prefs_.set('ctrl-c-copy', true);
    term.prefs_.set('ctrl-v-paste', true);
    term.prefs_.set('use-default-window-copy', true);
    term.prefs_.set('background-color', '#333');
    term.prefs_.set('font-size', 14); // eslint-disable-line
    // Set up our interaction class.
    term.runCommandClass(FlareTTY, document.location.hash.substr(1));
  }

  function _onReady () {
    state = states.CONNECTED;
    // Send start event to server.
    socket.emit('tty', {
      event: 'start',
      deviceId: deviceId,
      ack: ack
    });
    // Initialise if necessary.
    if (!init) {
      $log.debug('[hterm] Initialising');
      init = true;
      lib.init(function () {
        hterm.defaultStorage = new lib.Storage.Memory();
        _getTerminal();
      });
    } else {
      _getTerminal();
    }
  }

  function startTerminalSession (device) {
    // Track the type of session to start.
    // - Reconnect: a reconnect was requested (the device won't
    //   be supplied to this function).
    // - Continue: there was a previous session for the same
    // - device (it may have ended or still be active, but the
    //   terminal must have been instantiated).
    // - Reset: the device does not match the last session
    //   (no possible continuance).
    isReconnect = state === states.RECONNECTING;
    isContinue = !isReconnect && deviceId === device._id && term;
    isReset = !isContinue && !isReconnect;
    // Update tracking details and wipe terminal if it's a reset.
    if (isReset) {
      deviceId = device._id;
      buffer = '';
      firstOutput = false;
      if (term) {
        $log.debug('[hterm] Wiping terminal');
        term.wipeContents();
      }
    }
    // Reset the acknowledgement counter if it's definitely
    // a clean session.
    if (isReset || isReconnect) ack = 0;
    // Skip to setting up the terminal if this is a continuance.
    if (isContinue) return _getTerminal();
    // Sync server.
    return _syncServer()
    .then(function () {
      // Connect to server.
      state = states.CONNECTING;
      // Reuse existing socket if available.
      if (socket) {
        $log.debug('[hterm] Using existing socket');
        if (socket.connected) {
          $log.debug('[hterm] Socket.io already connected');
          return _onReady();
        } else {
          $log.debug('[hterm] Re-connecting to socket.io');
          socket.connect();
          _setConnectTimeout(true);
          return null;
        }
      }
      // Create a new socket.
      $log.debug('[hterm] Connecting to socket.io');
      socket = io(location.origin + '/tty', {
        path: '/socket.io'
      });
      _setConnectTimeout(true);
      // Handle socket connection.
      socket.on('connect', function () {
        $log.debug('[hterm] Socket.io connected');
        _setConnectTimeout(false);
        _onReady();
      });
      // Handle incoming messages.
      socket.on('tty', function (msg) {
        if (msg.event === 'output' && msg.deviceId === deviceId) {
          // Ignore 'last' output if we've already received something.
          if (msg.isLast && firstOutput) return;
          firstOutput = true;
          ack = msg.seq;
          _toTerminal(msg.output);
        } else if (msg.event === 'stop') {
          endSession(msg.reason);
        }
      });
      // Handle socket disconnection.
      socket.on('disconnect', function () {
        $log.debug('[hterm] Socket.io disconnected');
        endSession('client socket disconnected');
      });

      return null;
    });
  }

  function endSession (reason) {
    if (!_isConnecting() && !_isConnected()) return;
    state = states.DISCONNECTED;
    $log.debug('[hterm] Ending session (' + reason + ')');
    socket.disconnect();
    var text = '\r\n** Remote session terminated ';
    if (term && term.getCursorColumn() !== 0) text = '\r\n' + text;
    if (reason) text += '(' + reason + ') ';
    text += '**\r\n   ⤷ Press \'r\' to reconnect';
    _toTerminal(text);
    if (term) term.setCursorVisible(false);
    firstOutput = false;
  }

  function requestEndSession () {
    // Ask the device to stop the session.
    if (!_isConnected()) return;
    socket.emit('tty', {
      event    : 'stop',
      deviceId : deviceId,
      reason   : 'session closed by client'
    });
  }

  function onResize () {
    $log.debug('OnResize called');
    if (!_isConnected() || !termReady) return;
    if (!term) {
      $log.warn('[hterm] Resize called with no terminal');
      return;
    }
    // Send resize event to server.
    socket.emit('tty', {
      event    : 'resize',
      deviceId : deviceId,
      cols     : term.screenSize.width,
      rows     : term.screenSize.height
    });
  }

  function setCtrlPaste (on) {
    if (term) {
      term.prefs_.set('ctrl-c-copy', on);
      term.prefs_.set('ctrl-v-paste', on);
    }
  }

  function FlareTTY (argv) {
    this.argv_ = argv;
    this.io = null;
    this.pid_ = -1;
  }

  function getTerm () {
    return term;
  }

  FlareTTY.prototype.run = function () {
    this.io = this.argv_.io.push();
    this.io.onVTKeystroke = this.onInput.bind(this);
    this.io.sendString = this.onInput.bind(this);
    this.io.onTerminalResize = this.onResize.bind(this);
  };

  FlareTTY.prototype.onInput = function (input) {
    // Check for reconnect request.
    if (state === states.DISCONNECTED && input === 'r') {
      state = states.RECONNECTING;
      _toTerminal('\r\n   ⤷ Reconnecting...\r\n\r\n');
      startTerminalSession();
    }
    // Skip if not connected.
    if (!_isConnected()) return;
    // Send input to server.
    socket.emit('tty', {
      event    : 'input',
      deviceId : deviceId,
      input    : input
    });
  };

  FlareTTY.prototype.onResize = _.debounce(onResize, 1000);

  return {
    startTerminalSession, onResize, setCtrlPaste, getTerm, requestEndSession
  };
});
