angular.module('flare.browser', [])

.directive('pxnBrowserDetection', function () {
  return {
    restrict: 'A',
    link: function (scope, element, attrs, ctrl) {
      if (_.contains(navigator.userAgent, 'Edge')) {
        element.addClass('edge');
      } else if (_.contains(navigator.userAgent, 'Firefox')) {
        element.addClass('firefox');
      } else if (_.contains(navigator.userAgent, 'Safari') && !_.contains(navigator.userAgent, 'Chrome')) {
        element.addClass('safari');
      }
    }
  };
});
