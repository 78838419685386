angular.module('flare.comments', [])

.factory('Comments', function (
  $log, GenericResource, ResourceItem, ConfigURLs
) {

  function Comment () {
    ResourceItem.apply(this, arguments);
  }

  Comment.prototype = Object.create(ResourceItem.prototype);

  var Comments = new GenericResource(ConfigURLs.comments, Comment);

  return Comments;
})

.component('commentList', {
  templateUrl: 'comments/comments.jade',
  controller: 'CommentListController',
  controllerAs: 'vm',
  bindings: {
    comments: '<',
    targetModel: '@',
    targetId: '<'
  }
})

.controller('CommentListController', function (
  Comments,
  Modal,
  Toast,
  Session
) {
  var vm = this,
    MIN_CHARACTERS = 1;

  var defaultCommentAttr;

  vm.$onInit = function () {
    defaultCommentAttr = {
      targetId: vm.targetId,
      targetModel: vm.targetModel
    };
    vm.newComment = Comments.create(defaultCommentAttr);
  }

  vm.loading = false;
  vm.MAX_COMMENT_SIZE = 1000;


  // If our resourceItem is new it won't have any comments so initialise
  if (!vm.comments) {
    vm.comments = [];
  }

  /**
   * Submit disabling logic
   *
   * @returns {boolean} Whether submit button should be enabled or not
   */
  vm.disabledSubmitButton = () => {
    if (!this.newComment.message) {
      return true;
    }
    if (this.newComment.message.length < MIN_CHARACTERS) {
      return true;
    }
    return false;
  };

  /**
   * Persists the newComment to de backend
   *
   * @returns {void}
   */
  vm.postComment = () => {
    vm.loading =  true;
    vm.newComment.save()
    .then(function (comment) {
      vm.comments.push(comment);
      vm.newComment = Comments.create(defaultCommentAttr);
    })
    .finally(function () {
      vm.loading =  false;
    });
  };

  /**
   * Deletes the comment from commentList
   *
   * @param {comment} comment you want to be removed
   * @returns {void}
   */
  vm.deleteComment = (comment) => {
    new Modal({
      templateUrl: 'utility/modal-templates/modal-areyousure.jade',
      scopeData: {
        action: 'delete',
        itemType: 'comment'
      },
      dismissable: { backButton: false, escape: true, backgroundClick: false }
    })
    .show()
    .then(function () {
      return comment.delete();
    })
    .then(function () {
      _.pull(vm.comments, comment);
    })
    .catch(function (err) {
      Toast.makeError(err);
    });
  };

  /**
   * Check whether the comment was updated since creation.
   *
   * @param {any} comment
   * @returns {boolean}
   */
  vm.isUpdated = (comment) => {
    return comment.updated !== comment.created;
  };

  /**
   * It checks wether the comment account is the same as the viewers account
   *
   * @param {comment} comment to be checked
   * @returns {void}
   */
  vm.isViewerAccount = (comment) => {
    return Session.current.account._id === comment.account._id;
  };
});
