angular.module('flare.modals', [])

.provider('Modal', function () {

  var modalContainerElement = 'flare-modal-container';
  var animationClass        = 'animate__fadeIn-fadeOut';
  var KEYCODE_ESCAPE        = 27;
  var openModals = [];

  this.setModalContainer    = function (v) { modalContainerElement = v; };
  this.setAnimationClass    = function (v) { animationClass        = v; };
  this.$get = function ($document, $compile, $templateCache, $log, $rootScope,
                        $animate, $q, $window, $timeout) {
    var $container = $document.find(modalContainerElement).eq(0);
    var modalDefaults = {
      dismissable: {
        backgroundClick: true,
        escape: true,
        backButton: true
      },
      scope: null,
      scopeData: { message: 'Are you sure you want to do that?' },
      parentScope: null,
      isolate: true,
      templateUrl: 'utility/modal-templates/confirmation.jade',
      template: null,
    };

    /**
    * Capitalises a string
    * @param  {string} str The string to capitalise.
    * @return {string}     The capitalised string value.
    */
    function capitalise (str) {
      str += '';
      if (!str.length) return '';
      str = str.split('');
      str[0] = str[0].toUpperCase();
      return str.join('');
    }

    /**
     * This is the modal constructor that is returned from the factory. It
     * accepts an object hash which is populated with defaults if the options
     * are incomplete.
     * @param {object} options An options object, see `modalDefaults` for
     *                         details
     * @returns {null} nothing.
     */
    function ModalBuilder (options) {
      // Assign defaults
      this.options = _.defaultsDeep(options || {}, modalDefaults);

      // Generate chainable setters for options.
      _.each(modalDefaults, function buildSetter (val, propertyName) {
        var setterName = 'set' + capitalise(propertyName);

        // Only if prototype doesn't have the method defined already.
        if (typeof this[setterName] != 'undefined') return;

        Object.defineProperty(this, setterName, {
          configurable: false,
          enumerable: false,
          value: function setModalOption (value) {
            this.options[propertyName] = value;
            return this;
          },
          writeable: false,
        });
      }, this);

      this.deregisterFns = [];
    }

    /**
     * This function creates the modal element and associated scope if it
     * doesn't already exist, then animates the modal into view.
     * @return {promise} Returns a promise which can be resolved or rejected
     *                   by the modal.
     */
    ModalBuilder.prototype.show = function compileModal () {
      if (!this.$elem) {
        var opts = this.options;
        var template = opts.template || $templateCache.get(opts.templateUrl);

        // Get a scope if we don't already have one.
        this.getScope();
        this.setScopeData(opts.scopeData, true);
        this.$elem = $compile(angular.element(template))(this.scope);
        this.scope.modal.$elem = this.$elem;
      }
      $animate.addClass($container, 'modal__visible ' + animationClass);
      $animate.enter(this.$elem, $container);
      this.deferred = $q.defer();

      ModalBuilder.modalShowing = true;

      openModals.unshift(this);

      this.bindEvents();

      return this.deferred.promise;
    };

    /**
     * Returns the scope for the modal, generating it if it doesn't already
     * exist. Exact behaviour depends on the options passed.
     * @return {Scope} The modal scope.
     */
    ModalBuilder.prototype.getScope = function getModalScope () {
      var opts = this.options;

      this.scope = this.scope || opts.scope ||
                   $rootScope.$new(opts.isolate, opts.parentScope);

      return this.scope;
    };

    /**
     * Sets the scope data for the modal. Also initialises some modal specific
     * data. Is called during show with init set.
     * @param {object} data   The data to assign to the modal scope.
     * @param {boolean} init  Flag to indicate whether or not this function is
     *                        being called to initialise modal properties. Only
                              `Modalbuilder.prototype.show` should set this.
     * @return {ModalBuilder} retuns self for chainability.
     */
    ModalBuilder.prototype.setScopeData = function setScopeData (data, init) {
      // Prevent initialising scope data twice.
      if (init && this.scopeInit) return this;
      if (!this.scope) this.getScope();
      if (init === true) {
        this.scopeInit = true;
        this.scope.modal = _.assign(this.scope.modal || {}, {
          $elem: this.$elem,
          hide: this.hide.bind(this),
          resolve: this.resolve.bind(this),
          reject: this.reject.bind(this),
          dismissable: this.options.dismissable.backgroundClick
        });
      }
      _.assign(this.scope, data);

      return this;
    };

    /**
     * Hides the modal
     * @param  {value} resolve If set, the modal will be resolved with the
     *                         provided value
     * @param  {value} reject  If set, the modal will be rejected with the
     *                         provided value.
     * @return {undefined}
     */
    ModalBuilder.prototype.hide = function hideModal (resolve, reject) {
      // This setTimeout ensures all of the regsistered event handlers run
      // before the list of open modals is updated. Without this, the first
      // event handler could handle the event and close the modal, and other
      // modals would then handle it and think they are the top one.
      var animDeferred = $q.defer();
      $timeout(() => {
        _.pull(openModals, this);
        $animate.leave(this.$elem).then(animDeferred.resolve);
        this.unbindEvents();
        if (!openModals.length) {
          $animate.removeClass($container, `modal__visible ${animationClass}`);
          ModalBuilder.modalShowing = false;
        }
      }, 0);

      animDeferred.promise.then((function () {
        if (reject) this.deferred.reject(reject);
        else this.deferred.resolve(resolve);
        this.scope.$destroy();
      }).bind(this));

      return this.deferred.promise;
    };

    ModalBuilder.prototype.resolve = ModalBuilder.prototype.hide;
    ModalBuilder.prototype.reject = function (reject) {
      return this.hide(null, reject);
    };

    ModalBuilder.prototype.closeOnKeyUp = function closeOnKeyUp (e) {
      if (!this.isTopModal()) return;
      if (e.keyCode !== KEYCODE_ESCAPE) return;
      this.scope.$apply(this.reject.bind(this, 'cancel'));
    };

    ModalBuilder.prototype.closeOnBgClick = function closeOnBgClick () {
      if (!this.isTopModal()) return;
      this.scope.$apply(this.reject.bind(this, 'cancel'));
    };

    ModalBuilder.prototype.stopPropagate = function stopPropagate (e) {
      if (!this.isTopModal()) return;
      e.stopPropagation();
      // e.preventDefault();
    };

    ModalBuilder.prototype.bindEvents = function bindEvents () {
      if (this.options.dismissable.escape) {
        // We need to keep a reference to this function so that we can cancel
        // the event handler for it later. (We don't with the others because
        // we can just remove all of the handlers on those particular elements,
        // but this one is $document.
        this.escapeFn = this.closeOnKeyUp.bind(this);
        $document.on('keyup', this.escapeFn);
      }
      if (this.options.dismissable.backgroundClick) {
        this.bgClickFn = this.closeOnBgClick.bind(this);
        $container.on('click', this.bgClickFn);
      }
      // Stop content box clicks propagating to bg and closing modal.
      // Also stops clicks on this modal triggering other modals dismiss
      // listeners.
      this.$elem.on('click', this.stopPropagate.bind(this));

      // Stop state from changing (browser back and forward buttons)
      this.deregisterFns.push(
        $rootScope.$on('$stateChangeStart', (function (e) {
          e.preventDefault();
          // Chances are user will be clicking back on a mobile device.
          if (this.options.dismissable.backButton) {
            this.reject('cancel');
          }
        }).bind(this))
      );
    };

    ModalBuilder.prototype.isTopModal = function isTopModal () {
      return _.first(openModals) === this;
    };

    ModalBuilder.prototype.closeAll = function closeAll () {
      let rejectDeferred = [];
      // Close all modals
      openModals.forEach((modal)=> rejectDeferred.push(modal.reject('cancel')));
      return $q.all(rejectDeferred);
    };

    ModalBuilder.prototype.unbindEvents = function unbindEvents () {
      $document.off('keyup', this.escapeFn);
      $container.off('click', this.bgClickFn);
      this.$elem.off('click');
      _.each(this.deregisterFns, function (fn) { fn(); });
    };

    // Static methods

    /**
     * Determine if an element is inside the modal container
     * @param {HTMLElement} el The element to check for
     * @returns {boolean} True if the element is inside the modal container.
    */
    ModalBuilder.hasElement = function (el) {
      // Starting from element, check up the tree to see if any of its parents
      // are the modal container. If not, it's not in a modal.
      let node = el.parentNode;
      while (node != null) {
        if (node === $container[0]) {
          return true;
        }
        node = node.parentNode;
      }
      return false;
    };

    $window.addEventListener('beforeunload', function confirmModalOpenNav (e) {
      if (_.any(openModals, 'options.allowUnload', true)) {
        return null;
      }
      if (ModalBuilder.modalShowing) {
        var message = 'Are you sure you want to navigate away from Flare?';
        e.returnValue = message;
        return message;
      }
      return null;
    });

    return ModalBuilder;
  };
  return this;
})


// FIXME: this should absolutely not be in this file.
.directive('expandedMediaViewer', function ($log, $sce, Validator, Modal) {
  return {
    restrict: 'A',
    scope: true,
    controller: function ($scope, $element, $attrs) {
      // Get the URL/expression/interpolation from the elements attributes.
      var attributeValue = '';
      if ($attrs.pxnBackground) {
        attributeValue = $attrs.pxnBackground;
      } else if ($attrs.flareSrc) {
        attributeValue = $attrs.flareSrc;
      } else if ($attrs.ngSrc) {
        attributeValue = $attrs.ngSrc;
      } else if ($attrs.src) {
        attributeValue = $attrs.src;
      } else {
        $log.warn('[Expanded Media Viewer] - Unable to find media URL');
        return;
      }

      // First check for interpolation.
      if (Validator.isInterpolation(attributeValue)) {
        attributeValue = attributeValue.replace('{{', '');
        attributeValue = attributeValue.replace('}}', '');
        attributeValue = $scope.$eval(attributeValue);
      }

      // Check for a valid video or image URL.
      if (Validator.isImageUrl(attributeValue)) {
        $scope.imageURL = attributeValue;
      } else if (Validator.isVideoUrl(attributeValue)) {
        $scope.videoURL = $sce.trustAsResourceUrl(attributeValue);
      }

    },
    link: function (scope, element) {
      if (!scope.imageURL && !scope.videoURL) {
        $log.warn('[Expanded Media Viewer] - No image or video URL found');
        return;
      }
      element.on('click', function () {
        var modalOpts = {
          templateUrl: 'utility/modal-templates/enlarged-view.jade',
          scopeData:   {
            url: scope.imageURL || scope.videoURL,
            isVideo: !!scope.videoURL
          },
        };
        // Use scope.$apply to trigger a digest as angular doesn't know that we
        // need one.
        scope.$apply(function () {
          new Modal(modalOpts).show();
        });
      });
    },
  };
});
