angular.module('pixelnebula.mediainputs', [])

.component('pxnMediaUpload', {
  bindings: {
    category: '@uploadCategory',
    medium: '@uploadMedium',
    allowUploads: '@allowUploads'
  },
  templateUrl: 'utility/inputs/pxn-media-upload.jade',
  controllerAs: 'vm',
  controller: function mediaInputController (
    $q, $rootScope, Modal, $filter, Toast, Uploads, ScrollState, Session, $log
  ) {
    var $ctrl = this;

    var modalVm;

    $ctrl.$onInit = function mediaInit() {
      $ctrl.notOwnAccount =
        Session.current.account.name !== Session.current.user.account.name;

      switch ($ctrl.medium) {
        // Allow all - will be rejected by server if not valid, but
        // without wasting too much time uploading.
        case 'image': {
          $ctrl.accept = 'image/*';
          break;
        }
        case 'video': {
          $ctrl.accept = Session.current.account.usesElectron ?
            'video/webm,video/ogg,video/mp4' :
            'video/webm,video/ogg,video/mp4,.avi,.mov,.wmv';
          break;
        }
        case 'all':
        case 'media': {
          $ctrl.accept = Session.current.account.usesElectron ?
            'video/webm,video/ogg,video/mp4' :
            'video/webm,video/ogg,video/mp4,.avi,.mov,.wmv';
          $ctrl.accept += ',image/*';
          break;
        }
        default: {
          $ctrl.accept = '';
        }
      }

      modalVm = {
        allowUploads: $ctrl.allowUploads !== 'false',
        multipleUploads: $ctrl.allowUploads !== 'single',
        closePreview: closePreview,
        uploadFile: uploadFile,
        updateUploads: updateUploads,
        uploadsFinished: uploadsFinished,
        medium: $ctrl.medium,
      };
    };

    function closePreview () {
      ScrollState.restore('mediaScroll');
    }

    function uploadsFinished () {
      return !_.any(modalVm.uploading, function (item) {
        return item.status === 'uploading' || item.status === 'pending';
      });
    }

    function uploadFile (files) {
      // If we're switched account then we need to ask for a user.
      let uploadMetadata = {};
      let userDeferred = $q.defer();
      if ($ctrl.notOwnAccount) {
        // Open modal, ask for a user, store user value, resolve userDeferred
        let modalOpts = {
          templateUrl: 'utility/modal-templates/selectUser.jade',
          scopeData: {

          },
          dismissable: {
            escape: false,
            backgroundClick: false,
            backButton: false,
          }
        };
        new Modal(modalOpts).show().then((selectedUser) => {
          uploadMetadata = { ownedBy: selectedUser._id };
          userDeferred.resolve();
        }).catch((reason) => {
          userDeferred.reject();
          if (reason !== 'cancel') {
            $log.error(reason);
          }
        });
      } else {
        // We are the user.
        userDeferred.resolve();
      }
      // Once we have a user we can begin uploading.
      return userDeferred.promise
        .then(() => {
          modalVm.uploaded = [];
          modalVm.uploading = [];
          _.each(files, function startUpload(file, i) {
            var opts = {
              onSuccess: onUploadSuccess.bind(null, i),
              collection: Uploads,
            };
            modalVm.uploading.push(Uploads.upload(file, uploadMetadata, opts));
          });
        })
        .catch((err) => {
          $log.error(err);
        });
    }

    function onUploadSuccess (i, upload) {
      $rootScope.$broadcast('media__library-add', upload);
      modalVm.uploaded.push(upload);
    }

    function updateUploads () {
      var promises = [];
      _.each(modalVm.uploaded, function saveIfModifed (upload, i) {
        if (modalVm.uploading[i].details.changed) {
          _.assign(upload, modalVm.uploading[i].details);
          promises.push(upload.save());
        }
      });
      if (promises.length) {
        $q.all(promises).then(function (results) {
          Toast.makeSuccess(`Added metadata to ${promises.length} uploads.'`);
          // First letter may have changed, delete it and re-make it!
          _.each(results, function (u) { delete u.$firstLetter; });
          $filter('addFilterProps')(results, ['$firstLetter']);
        });
      }
      modalVm.uploading = null;
      $rootScope.$broadcast('media__reset-search');
    }

    $ctrl.openLibrary = function openLibrary (files) {
      if (!files.length) {
        return $q.resolvedPromise();
      }
      return uploadFile(files)
        .then(() => {
          var modalOpts = {
            templateUrl: 'utility/modal-templates/uploadMedia.jade',
            scopeData: {
              vm: modalVm,
            },
            dismissable: {
              escape: false,
              backgroundClick: false,
              backButton: false,
            },
          };
          return new Modal(modalOpts).show();
        })
        .catch((err) => {
          Toast.makeError(err);
        });
    };
  }
})

.component('pxnMediaInput', {

  bindings: {
    category: '@uploadCategory',
    medium: '@uploadMedium',
    allowUploads: '@allowUploads',
    positionButtons: '<',
    coverFn: '&',
    containFn: '&',
    onChange: '&',
    position: '<',
    backgroundColour: '=',
    modifier: '@',
    defaultTags: '@'
  },
  require: {
    ngModel: 'ngModel',
  },

  templateUrl: 'utility/inputs/pxn-media-input.jade',

  controller: function mediaInputController (
    $element, $attrs, $q, $scope, $rootScope, $filter, Modal,
    ResourceItem, Toast, Uploads, ScrollState, Session, $timeout
  ) {
    var $ctrl = this;
    var filter = {};

    $ctrl.hasColour = $attrs.backgroundColour || false;

    $ctrl.defaultPositioning = function defaultPositioning (contain) {
      var legacyPos = $ctrl.ngModel.$modelValue.position;
      var position = $ctrl.position;
      if (legacyPos) {
        _.assign(position, legacyPos);
        delete $ctrl.ngModel.$modelValue.position;
      }
      if (position) {
        position.x = position.y = position.z = undefined;
        position.contain = contain;
        position.cover = !contain;
      } else {
        position = {
          x: undefined,
          y: undefined,
          z: undefined,
          contain: contain,
          cover: !contain
        };
      }

      if (contain) {
        $ctrl.containFn({
          name: $ctrl.ngModel.$name.match(/\w+$/g)[0],
          position: position
        });
      } else {
        $ctrl.coverFn({
          name: $ctrl.ngModel.$name.match(/\w+$/g)[0],
          position: position
        });
      }
    };


    $ctrl.$onInit = function mediaInit() {
      if ($ctrl.medium) {
        filter.medium = $ctrl.medium;
      }

      let accept;
      switch ($ctrl.medium) {
        case 'image': {
          accept = 'image/*';
          break;
        }
        case 'video': {
          accept = Session.current.account.appTypes.electron ?
            'video/webm,video/ogg,video/mp4' :
            'video/webm,video/ogg,video/mp4,.avi,.mov,.wmv';
          break;
        }
        case 'all': {
          accept = Session.current.account.appTypes.electron ?
            'video/webm,video/ogg,video/mp4' :
            'video/webm,video/ogg,video/mp4,.avi,.mov,.wmv';
          accept += ',image/*';
          break;
        }
        default: {
          accept = '';
        }
      }

      $ctrl.defaultTags = $ctrl.defaultTags ? JSON.parse($ctrl.defaultTags): [];

      modalVm = {
        allowUploads: $ctrl.allowUploads !== 'false',
        multipleUploads: $ctrl.allowUploads !== 'single',
        selected: null,
        previewing: null,
        previewUpload: previewUpload,
        selectUpload: selectUpload,
        closePreview: closePreview,
        uploadFile: uploadFile,
        updateUploads: updateUploads,
        uploadsFinished: uploadsFinished,
        medium: $ctrl.medium,
        accept: accept,
        validNameRegex: validNameRegex,
        defaultTags: $ctrl.defaultTags,
      };




      var mediaObject;

      modalVm.notOwnAccount =
        Session.current.account.name !== Session.current.user.account.name;

      // This function is required because we may have a partial (non-casted)
      // media object (e.g. from slide content). We create a temporary object
      // that we can bind to, then get the server version and update our
      // temporary object with its values.
      $ctrl.ngModel.$formatters.push(function (newVal) {
        if (
          newVal != null &&
          typeof newVal == 'object' &&
          newVal._id &&
          !(newVal instanceof ResourceItem)
        ) {
          if (Uploads._cache.all) return Uploads.byId(newVal._id);
          if (Session.hasPermission('content.uploads.create')) {
            mediaObject = Uploads.create(newVal, false);
          } else {
            mediaObject = {
              name: newVal.name,
            };
          }
          if (Session.hasPermission('content.uploads.read')) {
            Uploads.all(function populatePendingMediaObject(uploads) {
              var serverObject = _.find(uploads, { _id: newVal._id });
              if (serverObject) mediaObject.updateValues(serverObject);
              else mediaObject.delete();
            });
          }
          return mediaObject;
        } else if (newVal instanceof ResourceItem) {
          return newVal;
        } else if (mediaObject) {
          return mediaObject;
        }
        return newVal;
      });

      $ctrl.ngModel.$isEmpty = function (val) {
        return !(val != null && typeof val === 'object' && val._id);
      };

      // Add input validation.

      const imageRegex = /\.(bmp|gif|jpg|jpeg|png|webp)$/i;
      const videoRegex = Session.current.account.appTypes.electron ?
        /\.(webm|ogv|mp4)$/i : // Electron formats
        /\.(webm|ogv|mp4|avi|mov|wmv)$/i; // Android formats.
      const multiMediaRegex = Session.current.account.appTypes.electron ?
        // Electron formats
        /\.(bmp|gif|jpg|jpeg|png|webp|webm|ogv|mp4)$/i :
        // Android formats
        /\.(bmp|gif|jpg|jpeg|png|webp|webm|ogv|mp4|avi|mov|wmv)$/i;

      $ctrl.ngModel.$validators.fileType = function (modelVal) {
        if (!accept || !modelVal) return true;
        // NOTE: This has been added by Goddak for takeovers as splitable image
        // inputs are populated as empty objects and I spent a heck of a long
        // time trying to figure out how to stop it. Essentially this was the
        // lazy fix and could break other things that I haven't been able to
        // spot. Not even I like having done this!
        if (_.isEmpty(modelVal)) return true;
        // If the input doesn't specify a type, anything goes.
        if (!modelVal.path) return false;
        if ($ctrl.medium === 'all') {
          return multiMediaRegex.test(modelVal.path); // Multi media input
        } else if (accept.startsWith('video')) {
          return videoRegex.test(modelVal.path); // Video input
        } else if (accept.startsWith('image')) {
          return imageRegex.test(modelVal.path); // Image input
        } else {
          // Shouldn't happen, but validate anyway if we don't know what the
          // extension should look like.
          return true;
        }
      };
    };

    let validNameRegex = /^[A-Za-z\xAA\xBA\xC0-\xD6\xD8-\xF6\xF8-\u02B8\u02E0-\u02E4\u1D00-\u1D25\u1D2C-\u1D5C\u1D62-\u1D65\u1D6B-\u1D77\u1D79-\u1DBE\u1E00-\u1EFF\u2071\u207F\u2090-\u209C\u212A\u212B\u2132\u214E\u2160-\u2188\u2C60-\u2C7F\uA722-\uA787\uA78B-\uA7AE\uA7B0-\uA7B7\uA7F7-\uA7FF\uAB30-\uAB5A\uAB5C-\uAB64\uFB00-\uFB06\uFF21-\uFF3A\uFF41-\uFF5A0-9 #$%&@~*^"'‘’:;.,?!/\\\[\]()_·•+—–-]+$/;
    // MODAL FUNCTIONS
    var modalVm;

    function selectUpload (upload) {
      modalVm.selected = upload;
    }

    function previewUpload (upload) {
      modalVm.previewing = upload;
      modalVm.selected = upload;
      ScrollState.triggerSave('mediaScroll');
    }

    function closePreview () {
      modalVm.previewing = null;
      ScrollState.restore('mediaScroll');
    }

    function uploadsFinished () {
      return !_.any(modalVm.uploading, function (item) {
        return item.status === 'uploading' || item.status === 'pending';
      });
    }

    function uploadFile (files) {
      modalVm.uploaded = [];
      modalVm.uploading = [];
      _.each(files, function startUpload (file, i) {
        // normalise the name before upload, thsi will ensure.
        let invalidCharacterRegex = /[^A-Za-z\xAA\xBA\xC0-\xD6\xD8-\xF6\xF8-\u02B8\u02E0-\u02E4\u1D00-\u1D25\u1D2C-\u1D5C\u1D62-\u1D65\u1D6B-\u1D77\u1D79-\u1DBE\u1E00-\u1EFF\u2071\u207F\u2090-\u209C\u212A\u212B\u2132\u214E\u2160-\u2188\u2C60-\u2C7F\uA722-\uA787\uA78B-\uA7AE\uA7B0-\uA7B7\uA7F7-\uA7FF\uAB30-\uAB5A\uAB5C-\uAB64\uFB00-\uFB06\uFF21-\uFF3A\uFF41-\uFF5A0-9 #$%&@~*^"'‘’:;.,?!/\\\[\]()_·•+—–-]/g;
        let normalisedName = file.name.replace(invalidCharacterRegex, '_');

        let newFile;
        if (normalisedName !== file.name) {
          let blob = file.slice(0, file.size, file.type);
          newFile = new File([blob], normalisedName, { type: file.type });
        } else {
          newFile = file;
        }

        var opts = {
          onSuccess: onUploadSuccess.bind(null, i),
          collection: Uploads
        };
        var uploadMetadata =
          modalVm.notOwnAccount ? { ownedBy: modalVm.uploadOwner._id } : {};
        modalVm.uploading.push(Uploads.upload(newFile, uploadMetadata, opts));
        if ($ctrl.defaultTags.length) {
          modalVm.uploading[i].details = {
            // Assign default tags to upload
            tags: JSON.parse($ctrl.defaultTags),
            // Set changed flag so metadata will be added to the new upload
            changed: true
          };
        }
      });
    }

    function onUploadSuccess (i, upload) {
      $rootScope.$broadcast('media__add', upload);
      modalVm.uploaded[i] = upload;
      modalVm.selected = upload;
    }

    function updateUploads () {
      var promises = [];
      _.each(modalVm.uploaded, function saveIfModifed (upload, i) {
        if (modalVm.uploading[i].details.changed) {
          _.assign(upload, modalVm.uploading[i].details);
          promises.push(upload.save());
        }
      });
      if (promises.length) {
        $q.all(promises).then(function (results) {
          Toast.makeSuccess(
            `Added metadata to ${promises.length}
            upload${promises.length > 1 ? 's' : ''}.`
          );
          // First letter may have changed, delete it and re-make it!
          _.each(results, function (u) { delete u.$firstLetter; });
          $filter('addFilterProps')(results, ['$firstLetter']);
        });
      }
      modalVm.uploading = null;

      $rootScope.$broadcast('media__reset-search');
    }

    function strip$keyVals (object) {
      _.each(object, function (val, key) {
        if (key.startsWith('$')) {
          delete object[key];
        }
      });
      return object;
    }

    $ctrl.selectMedia = function selectMedia () {
      $rootScope.$broadcast('media__library-open');
      $ctrl.openLibrary()
      .then(function onMediaSelected (media) {
        if (media == null || typeof media != 'object') return;
        media = angular.copy(media);
        if ($ctrl.modifier === 'table-input') {
          media = strip$keyVals(media);
        }
        $ctrl.ngModel.$setViewValue(media);
      })
      .finally(function removePreviewing () {
        modalVm.previewing = null;
        if (modalVm.selected == null || typeof modalVm.selected != 'object') {
          return;
        }
        if (modalVm.selected.medium === 'video') {
          $ctrl.repositionable = false;
        } else {
          $ctrl.repositionable = true;
        }
        $rootScope.$broadcast('media__library-close');
      });
    };

    $ctrl.repositionable = true;

    /**
     * Clears the selection
     * @return {void} undefined
     */
    $ctrl.clear = function clear () {
      $ctrl.ngModel.$setViewValue(null);
      $scope.$broadcast('media-input-reset');
    };

    $ctrl.openLibrary = function openLibrary () {
      modalVm.previewing = modalVm.uploading = null;
      var modalOpts = {
        templateUrl: 'utility/modal-templates/selectMedia.jade',
        scopeData:   {
          vm: modalVm
        },
        dismissable: {
          escape: false,
          backgroundClick: false,
          backButton: false,
        }
      };
      return new Modal(modalOpts)
      .show()
      // NOTE: our close broadcast isn't a `finally` because we still want to
      // return the resolved data to anyone else further down the chain.
      .then((data)=> {
        return data;
      })
      .catch(()=> $rootScope.$broadcast('media__library-close'));
    };

    // Determines whether we set the image to cover or contain.
    $ctrl.shouldCover = $attrs.imageSize === 'cover';

  }
})


.controller('mediaLibraryController', function mediaLibController (
  $filter, $scope, Uploads, Session
) {
  var vm = this;
  // --- Constants
  var INITIAL_ITEM_LIMIT = 25;
  var ITEM_LIMIT_INCREMENT = 25;

  // --- Init code.

  // Flag to show/hide the default tags callout for new uploads.
  vm.tagsCleared = false;
  vm.userUpdated = false;
  // This is the filter we'll pass to `Uploads.where` to get our library data.
  var criteria = {};
  // Set if this controller is a part of a component.
  try {
    criteria.medium = vm.medium || $scope.$parent.vm.medium;
  } catch (e) { // No vm on parent.
    criteria.medium = null;
  }

  if (criteria.medium === 'all') {
    criteria.medium = null;
  }

  // Init a list of names for autosuggest and a library to populate when we have
  // all uploads.
  vm.suggestions = {
    names: [],
    tags: Uploads.getTags()
  };

  vm.library = criteria.medium ? [] : Uploads.all();
  var promise = criteria.medium ?
    Uploads.where(criteria, true) :
    vm.library.promise;

  let uploadedRecentlyByCurrentUserFilter = (u) => {
    if (new Date(u.created).getTime() > Date.now() -
    (1000 * 60 * 60 * 24 * 14)) { // Uploaded in last 14 days.
      return u.createdBy._id === Session.current.user._id;
    }
    return false;
  };

  let setRecentlyUploaded = () => {
    // Limit recent files to 5 to ensure one line no matter resolution.
    vm.recentlyUploaded = _.take(
      // eslint-disable-next-line no-magic-numbers
      vm.filteredLibrary.filter(uploadedRecentlyByCurrentUserFilter), 5
    );
  };

  promise.then(function populateMediaLibrary (uploads) {
    if (criteria.medium) {
      _.each(uploads, function (upload) { vm.library.push(upload); });
    }
    _.each(_.map(uploads, 'name'), function addNameToArray (name) {
      vm.suggestions.names.push(name);
    });

    // Add the filter properties for later
    $filter('addFilterProps')(uploads, ['$firstLetter', '$createdMonth']);

    // Update filters first when we have default tags OR run filters if we don't
    vm.defaultTags.length ? vm.updateFilters(true) : vm.runFilters();
  });

  // Default tags callout banner config for media filter
  vm.mediaSelectBannerConfig = {
    type: 'info',
    message: 'Filtering by suggested tags',
    actionButton: {
      text: 'Clear filters',
      background: 'negative-1',
      iconClass: 'entypo-cancel',
      clickFn: () => vm.resetFilters()
    }
  };

  // Default tags callout banner config for new uploads
  vm.uploadBannerConfig = {
    type: 'info',
    message: 'Using suggested tags',
    actionButton: {
      text: 'Clear tags',
      background: 'negative-1',
      iconClass: 'entypo-cancel',
      clickFn: () => vm.removeTags()
    }
  };

  // --- Filters
  vm.groupBy = {};
  vm.itemLimit = INITIAL_ITEM_LIMIT;

  // When we sort groups we also need to sort the contents - this object
  // provides the mappings between the groupings and the corresponding
  // individual item properties.
  vm.secondaryOrderProperties = {
    $createdMonth: 'createdTimestamp',
    $firstLetter: 'name',
    'createdBy.name': 'createdBy.name'
  };

  vm.isVideo = function isVideo (upload) {
    if (!upload) return false;
    return _.startsWith(upload.type, 'video');
  };

  $scope.$on('media__reset-search', function (e) {
    vm.groupBy.prop = '$createdMonth';
    vm.groupBy.direction = 'asc';
    vm.groupBy.orderBy = 'created';
    vm.updateFilters();
    vm.itemLimit = INITIAL_ITEM_LIMIT;
  });

  // Allow things to be added to the library after they upload.
  $scope.$on('media__add', function (e, data) {
    // New uploads are picked up by the library automatically as it holds a
    // reference to the cache so we only have to add new uploads to the library
    // manually when a medium is set.
    if (criteria.medium) vm.library.push(data);
    $filter('addFilterProps')([data], ['$firstLetter', '$createdMonth']);
    vm.updateFilters();
  });

  // Allow things to be added to the library via the library.
  $scope.$on('media__library-add', function (e, data) {
    $filter('addFilterProps')([data], ['$firstLetter', '$createdMonth']);
    vm.updateFilters();
  });

  $scope.$on('media__removed', function (e, data) {
    vm.runFilters();
  });

  // This is a tag-like array of filters like "tag: someTag" or just "someName"
  // We convert them into an array of `tagFilters` and `nameFilters`.
  vm.filters = [];
  vm.filteredLibrary = [];
  var tagFilters = [];
  var nameFilters = [];
  vm.shareFilters = {
    showShared: true,
    showHidden: false
  };

  // Set from pxnMediaInput component only.
  try {
    vm.defaultTags = $scope.$parent.vm.defaultTags || [];
  } catch (e) {
    vm.defaultTags = [];
  }

  // Add default tags to filters
  vm.filters = _.map(vm.defaultTags, (tag) => `tag: ${tag}`);

  /**
   * Updating the filters by grouping name and tag filters separately.
   *
   * @param {boolean} initDefaultTags - Flag to pass onto the filter function
   * whether the filters are updated due to default tags
   * @return {undefined} undefined
   */
  vm.updateFilters = function updateFilters (initDefaultTags = false) {
    tagFilters = [];
    nameFilters = [];
    _.each(vm.filters, function (term) {
      var filterParts = term.split(':');
      if (filterParts.length > 1) {
        var type = filterParts[0].trim();
        if (type === 'tag') tagFilters.push(filterParts[1].trim());
      } else {
        nameFilters.push(filterParts[0].trim());
      }
    });
    vm.runFilters(initDefaultTags);
  };

  /**
   * Run filter functions and set vm.filteredLibrary to the result
   *
   * @param {boolean} initDefaultTags - Flag to set visibility of default tags
   * callout banner
   * @return {undefined} undefined
   */
  vm.runFilters = function runFilters (initDefaultTags = false) {
    var filtered = $filter('filter')(vm.library, vm.matchesFilters);
    vm.totalAvailable = filtered.length;
    // order before we set the limit.
    filtered = $filter('orderBy')(
        filtered,
        vm.secondaryOrderProperties[vm.groupBy.orderBy],
        vm.groupBy.direction === 'asc'
    );
    vm.itemsFiltered = vm.library.length - filtered.length;
    filtered = $filter('limitTo')(filtered, vm.itemLimit);
    vm.filteredLibrary = filtered;

    vm.initDefaultTags = initDefaultTags;

    vm.makeGroups(filtered);
    setRecentlyUploaded();
  };

  vm.groups = {};
  vm.makeGroups = function () {
    vm.groups = $filter('groupBy')(vm.filteredLibrary, vm.groupBy.property);
    vm.orderGroups();
  };
  vm.orderedGroups = [];
  vm.orderGroups = function () {
    vm.orderedGroups = $filter('orderGroup')(vm.groups, vm.groupBy.direction);
  };

  vm.groupingChanged = function ($event) {
    if ($event.property) vm.makeGroups();
    else if ($event.direction) vm.orderGroups();
  };

  vm.increaseLimit = function increaseItemLimit () {
    vm.itemLimit += ITEM_LIMIT_INCREMENT;
    vm.runFilters();
    $scope.$emit('remeasure-for-scrollbars');
  };

  /**
   * Check if an item matches a filterParts
   * @param {object} value The item to check
   * @return {boolean} `true` if the item matches the filters.
   */
  vm.matchesFilters = function matchesFilters (value) {
    if (tagFilters.length) {
      if (value.hasOwnProperty('tags') && value.tags.length) {
        if (!_.every(tagFilters, _.contains.bind(null, value.tags))) {
          return false;
        }
      } else {
        return false;
      }
    }
    if (nameFilters.length && value.hasOwnProperty('name')) {
      if (!_.every(nameFilters, function (text) {
        return fuzzy.match(text, value.name);
      })) {
        return false;
      }
    }
    if (!vm.shareFilters.showShared && value._sharedWithMe) {
      return false;
    }
    if ((!vm.shareFilters.showShared || !vm.shareFilters.showHidden) &&
        value.hidden) {
      return false;
    }
    return true;
  };

  /**
   * Clears all tags from newly uploaded media.
   *
   * @return {undefined} undefined
   */
  vm.removeTags = function () {
    let newUploads;
    try {
      newUploads = $scope.$parent.vm.uploading;
    } catch (e) {
      newUploads = [];
    }
    _.each(newUploads, function (upload) {
      upload.details.tags = [];
    });
    // Set flag to hide callout banner.
    vm.tagsCleared = true;
  };

  /**
   * Reset default tag related flags for media select filter and new uploads.
   *
   * @return {undefined} undefined
   */
  vm.resetFlags = function () {
    vm.tagsCleared = false;
    vm.userUpdated = false;
  };

  vm.resetFilters = function () {
    vm.filters = [];
    vm.shareFilters.showShared = true;
    vm.shareFilters.showHidden = false;
    vm.updateFilters();
  };
})

.controller('ManageMediaController', function ManageMediaController (
  Modal, Toast, $timeout, $filter, $rootScope, Onboarding
) {
  var vm = this;

  vm.editUpload = function editUpload (upload) {
    let editModal = new Modal({
      templateUrl: 'utility/modal-templates/editMedia.jade',
      scopeData: {
        upload: upload,
        delete: function () {
          // Open a new modal to confirm deletion.
          let deleteModal = new Modal({
            templateUrl: 'utility/modal-templates/modal-areyousure.jade',
            scopeData: {
              action: 'delete',
              itemType: 'file'
            },
            dismissable: {
              backButton: false,
              escape: true,
              backgroundClick: false
            }
          });
          // Close the modal beneath.
          editModal.reject('cancel');
          deleteModal
            .show()
            .then(function () {
              return Toast.deleteAndNotify(upload);
            })
            .then(function () {
              $rootScope.$broadcast('media__removed');
            })
            .catch((err) => {
              Toast.makeError(err);
            });
        }
      }
    });

    editModal.show()
    .then(function () {
      return Toast.saveAndNotify(upload);
    })
    .then(function () {
      $filter('addFilterProps')([upload], ['$firstLetter', '$createdMonth']);
    })
    .catch(function (e) {
      // We can't edit shared resources so there's never a need to reset them.
      if (!upload._sharedWithMe) {
        upload.reset();
      }
    });
    $timeout(function () {
      Onboarding.triggerOverviewResourceAvailable();
    });
  };

  vm.selectUpload = function selectUpload (upload) {
    vm.selected = upload;
  };

})

/**
 * @Desc: Directive for labels to prevent their click event when the element it
 * is intended for is disabled.
 */
.directive('preventClickIfDisabled', function () {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      element.on('click', function (e) {
        if (document.getElementById(attrs.for).disabled) e.preventDefault();
      });
    }
  };
});
