angular.module('flare.tags', [])

.component('pxnTagInput', {

  templateUrl: 'utility/inputs/tag-input.jade',
  bindings: {
    editableAttr: '@editable',
    suggestions : '<',
    resource    : '<',
    selected    : '<selectedTags',
    saveFn      : '&',
    resetFn     : '&',
    tagAction   : '&',
    disabled    : '=ngDisabled'
  },
  require: {
    ngModel: '?ngModel',
    requiresPermission: '?pxnRequiresPermission',
  },
  controller: function tagInputController (
      $scope,
      $element,
      $attrs,
      $compile,
      $log,
      $animate,
      $timeout,
      $templateCache,
      $document,
      Encoder,
      Measure,
      IsMobile,
      $vgu
  ) {

    var $ctrl         = this;
    var bannedRegex   = /(&(nbsp|#(160))*?;)/gmi;
    var $tagList      = $element.find('ul');
    var $input;

    function tagTemplate (content) {
      return '<li class="tag-input--tag">' +
          '<button ng-if="$ctrl.editable" type="button"' +
          ' class="tag-input--delete-button"' +
          'ng-click="$ctrl.deleteTag(\'' + content + '\')"' +
          '></button>' +
        '</li>';
    }

    var ELEMENTS_IN_EMPTY_LIST;

    /**
     * Deletes a tag from the DOM
     * @param {number|string} which    The tag to delete (either the value or
     *                                 its index)
     * @param {object} triggeringEvent The event that triggered the deletion.
     * @return {undefined}
     */
    function deleteTag (which, triggeringEvent) {
      if (typeof which != 'number') {
        which = _.indexOf($ctrl.ngModel.$viewValue, which);
        if (which === -1) {
          $log.warn('[Tag input] Attempted to remove non-existant tag');
          return;
        }
      }
      if (which === -1) {
        if ($ctrl.ngModel.$viewValue && $ctrl.ngModel.$viewValue.length) {
          which += $ctrl.ngModel.$viewValue.length;
        } else {
          return;
        }
      }

      var value = angular.copy($ctrl.ngModel.$viewValue);

      value.splice(which, 1);

      // Update the model
      $ctrl.ngModel.$setViewValue(
          value,
          triggeringEvent
      );

      // Remove the tag from the DOM.
      $animate.leave($tagList.children()[which]);
    }

    /**
     * Creates a tag element with the given content.
     * @param {string} tagContent The text content of the tagContent
     * @return {object} A JQLite tag object.
     */
    function createTagElement (tagContent) {
      var $tag = angular.element(tagTemplate(tagContent));
      var $content;
      if ($attrs.tagAction) {
        $content = angular.element(
          '<span class="tag-input--content tag-input__actionable">' +
          tagContent +
          '</span>'
        );
      } else {
        $content = angular.element(
          '<span class="tag-input--content">' + tagContent + '</span>'
        );
      }

      // Use ng-class to highlight tags that are selected.
      if ($attrs.selectedTags != null) {
        $tag.attr(
          'ng-class',
          '{\'tag-input--tag__selected\': ' +
          '$ctrl.isSelected(\'' + tagContent + '\')}'
        );
      }

      $compile($tag)($scope);
      var $deleteButton = $tag.find('button');

      $tag.prepend($content);
      $deleteButton.on('click', function deleteSelf (clickEvent) {
        clickEvent.stopPropagation();
        clickEvent.preventDefault();
        $scope.$apply(deleteTag.bind($scope.$ctrl, tagContent, clickEvent));
      });

      if ($attrs.tagAction) {
        $tag.on('click', function clickTag (e) {
          $scope.$apply($ctrl.tagAction({ tag: tagContent, $event: e }));
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
        });
      }

      return $tag;
    }

    /**
     * Creates a tag with the current value of the input and inserts it into the
     * tag list.
     * @param {object} triggeringEvent The event triggering the commit.
     * @return {undefined}
     */
    function commitTag (triggeringEvent) {
      var currentValue = Encoder.getTextContent($input.val());

      currentValue = Encoder.decodeHTMLEntities(currentValue);

      // Set the input to nothing
      currentValue = currentValue.replace(bannedRegex, '').trim();
      $input.val('');

      // Check for duplicates.
      if (!currentValue.length ||
        _.contains($ctrl.ngModel.$viewValue, currentValue)) return;

      var $tag = createTagElement(currentValue);
      var existingTags = $tagList.children();
      var afterElement = existingTags.length > ELEMENTS_IN_EMPTY_LIST ?
          existingTags.eq($ctrl.ngModel.$viewValue.length - 1) :
          null;

      $animate.enter(
          $tag, // element
          $tagList, // parent
          afterElement // after
      );

      // we copy the array to change the reference and trigger the watcher.
      var value = angular.copy($ctrl.ngModel.$viewValue);

      if (value == null) value = [];

      value.push(currentValue);
      $ctrl.ngModel.$setViewValue(
          value,
          triggeringEvent
      );

    }

    function saveChanges () {

      if ($ctrl.resource) {
        $ctrl.resource.save().catch($ctrl.resource.reset.bind($ctrl.resource));
      } else if ($ctrl.saveFn) {
        $ctrl.saveFn();
      }

      if ($attrs.editable !== 'true') {
        $ctrl.editable = false;
      }
      $element.triggerHandler('save');

    }

    function revertChanges () {
      if ($ctrl.resource) $ctrl.resource.reset();
      else if ($ctrl.resetFn) $ctrl.resetFn();
      $ctrl.ngModel.$rollbackViewValue();
      if ($attrs.editable !== 'true') { $ctrl.editable = false; }
    }

    $ctrl.saveChanges   = saveChanges;
    $ctrl.revertChanges = revertChanges;

    $ctrl.$onInit = function initTagCtrl () {
      if (!$ctrl.ngModel) {
        $log.warn("[Tag Input] No model specified");
        return;
      }

      /* eslint-disable no-magic-numbers */
      ELEMENTS_IN_EMPTY_LIST = $ctrl.editableAttr === "true" ? 1 : 2;
      /* eslint-enable no-magic-numbers */

      $ctrl.ngModel.$render = function renderTagInput() {
        $input = $element.find("input");
        var tags = $tagList.children();
        var limit = tags.length - $input.length;

        // Remove all tags, but don't remove the input and save/cancel buttons.
        for (var i = 0; i < limit; i++) {
          tags.eq(i).remove();
        }

        var previous = null;

        // Put tags into the DOM.
        _.each($ctrl.ngModel.$modelValue, function (tagValue) {
          var $newTagElem = createTagElement(tagValue);

          if (previous) previous.after($newTagElem);
          else $tagList.prepend($newTagElem);

          previous = $newTagElem;
        });
      };

      $ctrl.ngModel.$isEmpty = function tagEmptyCheck(value) {
        return !value || !value.length;
      };

      var inputsBound = false;

      $scope.$watch("$ctrl.editable", function (nowEditable, wasEditable) {
        if (nowEditable && wasEditable === undefined) {
          $timeout(bindInputEvents);
          inputsBound = true;
        } else if (nowEditable === false && wasEditable === true) {
          inputsBound = false;
        } else if (!inputsBound && nowEditable) {
          $timeout(bindInputEvents);
          inputsBound = true;
        }
      });

      if (notDisabledByPermissions()) {
        if ($ctrl.editableAttr === "true") {
          $ctrl.editable = true;
        } else if ($ctrl.editableAttr === "toggle") {
          $ctrl.editable = false;
          bindEditIconEvents();
        }
      } else {
        $ctrl.editable = false;
      }
    };

    function bindInputEvents () {
      $input = $element.find('input');
      if ($attrs.suggestions) {
        $input.attr('pxn-auto-complete', '$ctrl.suggestions');
        $compile($input)($scope);
      }
      // On a mobile, all of the keydown events are the same character, so we
      // need to use a different approach:
      if (IsMobile) {
        $input.on('textInput', function tagFieldKeyDown (e) {
          var textIn = e.data;
          if (_.contains([',', ';'], textIn)) {
            // var val = $input.val();
            // $input.val(val.substring(0, val.length - 1));
            $scope.$apply(commitTag.bind($ctrl, e));
            e.preventDefault();
          }
        });
      } else {
        $input.on('keydown', function tagFieldKeyDown (event) {
          switch (event.key) {
            case 'Enter':
            case ',':
              $scope.$apply(commitTag.bind($ctrl, event));
              event.preventDefault();
              break;

            case ';':
              if (!event.shiftKey) {
                $scope.$apply(commitTag.bind($ctrl, event));
                event.preventDefault();
              }
              break;

            case 'Backspace':
              if (!$input.val().length) {
                event.preventDefault();
                $scope.$apply(deleteTag.bind($ctrl, -1));
              }
              break;
            default: break;
          }
        });
      }
      $input.on('blur', function commitOnBlur (blurEvent) {
        $scope.$apply(commitTag.bind($ctrl, blurEvent));
        $element.children().eq(0).removeClass('tag-input__focussed');
      });
      $input.on('focus', function commitOnBlur (blurEvent) {
        $element.children().eq(0).addClass('tag-input__focussed');
      });
    }

    var EDIT_ICON_TIMER = 2500;
    var editIcon = {
      timer: null,
      visible: false,
      $element: angular.element(
        $templateCache.get('utility/tooltip-templates/edit-icon.jade')
      ),
    };

    function hideEditIcon () {
      $animate.leave(editIcon.$element);
      editIcon.visible = false;
      editIcon.timer = null;
    }

    function triggerHideEditIcon () {

      if (!editIcon.visible || editIcon.timer) return;

      editIcon.timer = $timeout(hideEditIcon, EDIT_ICON_TIMER);
    }

    function showEditIcon () {

      if ($ctrl.editable) return;

      if (editIcon.visible && editIcon.timer) {
        $timeout.cancel(editIcon.timer);
        editIcon.timer = null;
        return;
      }
      $scope.$apply(function () {
        Measure.place(editIcon.$element, $element,
                      { position: { right: -2 * $vgu }, addToDOM: true });
      });
      editIcon.visible = true;
      editIcon.$element.on('click', function () {
        $scope.$apply(function () {
          $ctrl.editable = true;
          hideEditIcon();
        });
      });
      $document.on('scroll', Measure.place.bind(
          null,
          editIcon.$element,
          $element,
          'right',
          false
        )
      );
    }

    function bindEditIconEvents () {
      $element.on('mouseenter', showEditIcon);
      $element.on('mouseleave', triggerHideEditIcon);
    }

    function notDisabledByPermissions () {
      return !$ctrl.requiresPermission || !$ctrl.requiresPermission.isDisabled;
    }

    $ctrl.deleteTag  = deleteTag;
    $ctrl.commitTag  = commitTag;
    $ctrl.getTags    = function () { return $ctrl.ngModel.$viewValue; };
    $ctrl.isSelected = function (t) {
      return _.contains(
        _.invoke($ctrl.selected, 'toLowerCase'),
        t.toLowerCase());
    };
  },
});
