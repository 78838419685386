angular.module('flare.timeoutPromises', [])

.factory('TimeoutPromise', function ($q, $timeout) {

  return function (promise, timeoutMs, rejectionValue) {
    var deferred = $q.defer();
    var isRejected = false;

    rejectionValue = rejectionValue || 'timeout';

    var timeout = $timeout(function () {
      isRejected = true;
      deferred.reject(rejectionValue);
    }, timeoutMs);

    promise.then(function (val) {
      if (isRejected) {
        // do nothing - too late.
      } else {
        $timeout.cancel(timeout);
        deferred.resolve(val);
      }
    });

    return {
      promise: deferred.promise,
      // Expose timeout promise to make it cancellable.
      timeout: timeout
    }
  }
});
