angular.module('flare.preferences', [])

.controller('FlarePreferencesController', function (
  Session, Onboarding, Modal, $http, Toast, $state
) {
  const loggedInUser = Session.current.user;
  this.prefs = angular.copy(loggedInUser.preferences);
  this.resetProgress = () => Onboarding.reset(loggedInUser._id);

  this.goToChangePasswordPage = ()=> {
    Modal.prototype.closeAll().finally(function () {
      $state.go('change-password')
    });
  }

  this.enable2fa = ()=> {

    // Request the URL of the secret QR code from the server.
    $http.post(`api/users/${loggedInUser._id}/otp-secret`)
    .then(function (res) {
      const modal = new Modal({
        templateUrl: 'utility/modal-templates/enable2fa.jade',
      });

      const submit2faToken = (token) => {
        $http({
          method: 'PUT',
          url: `api/users/${loggedInUser._id}/otp-status`,
          data: {
            enabled: true,
            token: token,
          }
        }).then(function () {
          modal.resolve();
        }).catch(function (e) {
          if (e.data && e.data.otpIncorrect) {
            modal.getScope().enable2faForm.token = '';
          }
        });
      };

      modal.setScopeData({
        submit2faToken, qrCodeUrl: res.data.url,
      });

      return modal.show();
    })
    .then(()=>{
      this.prefs.twoFactorAuth = true;
      Toast.makeSuccess('Two factor authentication was successfully enabled.');
    })
    .catch(()=>{
      this.prefs.twoFactorAuth = false;
    });
  };

  this.disable2fa = ()=> {
    const modal = new Modal({
      templateUrl: 'utility/modal-templates/disable2fa.jade',
    });

    const submit2faToken = (token) => {
      $http({
        method: 'PUT',
        url: `api/users/${loggedInUser._id}/otp-status`,
        data: {
          enabled: false,
          token: token,
        }
      }).then(function () {
        modal.resolve();
      }).catch(function (e) {
        if (e.data && e.data.otpIncorrect) {
          modal.getScope().disable2faForm.token = '';
        }
      });
    };

    modal.setScopeData({ submit2faToken });

    return modal.show()
    .then(()=>{
      this.prefs.twoFactorAuth = false;
      Toast.makeSuccess('Two factor authentication was successfully disabled.');
    })
    .catch(()=>{
      this.prefs.twoFactorAuth = true;
    });
  };

  this.connectAzureAccount = () => {
    const cookies = document.cookie.split(';');
    let cookie = null;
    _.each(cookies, (c) => {
      const [cName, cVal] = c.split('=');
      if (cName.trim() === 'flare-xsrf') cookie = cVal;
    });
    window.open(`/api/auth/openid/link?xsrf=${cookie}`, '_self');
    return;
  };

  this.isAzureManaged = loggedInUser.azure.managed;

});
