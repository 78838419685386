(function () {

  var module = angular.module('pixelnebula.clicks', []);

  /**
   * Creates directives (e.g. pxnShiftClick) that execute an expression on
   * a modified click.
   * @param {any} directiveName The name of the directive to create.
   * @param {any} modifier The key modifier, must have modifierClick property
   *                       on event to work (e.g. altKey).
   * @returns {object} Directive definition.
   */
  function modClickDirective (directiveName, modifier) {
    return ['$parse', function ($parse) {
      return {
        restrict: 'A',
        compile: function (elem, attr) {
          const fn = $parse(attr[directiveName], null, true);
          const keyProp = `${modifier}Key`;
          // Link function
          return function (scope, element, attrs) {
            element.on('click', function (event) {
              if (!event[keyProp]) return;
              scope.$apply(function () {
                fn(scope, { $event: event });
                if (!attrs.pxnClickPropagate) {
                  event.stopImmediatePropagation();
                }
              });
            });
          };
        }
      };
    }];
  }

  var modifiers = ['shift', 'ctrl', 'alt', 'meta'];

  for (var i = 0; i < modifiers.length; i++) {
    var modifier = modifiers[i];
    var directiveName = 'pxn' + _.capitalize(modifier) + 'Click';
    module.directive(directiveName, modClickDirective(directiveName, modifier));
  }

  module.directive('passThroughClicks', function () {
    return {
      restrict: 'A',
      controller: function ($element, $log, $attrs) {
        let logPrefix = '[passThroughClicks] - ';

        $element.on('click', function (e) {
          // Stop the event bubbling.
          e.stopPropagation();
          let displayState = $element.css('display');
          // Hide the element so we can get the one below it.
          $element.css('display', 'none');
          let elemBelow = document.elementFromPoint(e.x, e.y);
          if (elemBelow.click && typeof elemBelow.click === 'function') {
            elemBelow.click();
            $log.debug(
              logPrefix + 'Click passed through to',
              elemBelow
            );
          } else {
            $log.warn(
              logPrefix + 'The below element doesn\'t support clicks',
              elemBelow
            );
          }
          $element.css('display', displayState);
        });

      }
    };
  });

})();
