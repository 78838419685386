angular.module('localisation', ['ui.tree'])

// TODO: D'n'D is broken on mobile devices since 2.19.0
// Issue: https://github.com/angular-ui-tree/angular-ui-tree/issues/829

.config(function (treeConfig) {
  treeConfig.defaultCollapsed = false; // collapse nodes by default
  treeConfig.appendChildOnHover = false; // append nodes as children on drag
  treeConfig.dragMoveSensitivity = 15; // Horizontal drag sensitivity in pixels
})

.factory('LocaleTree', function ($http, $log, ConfigURLs, Session) {
  let localisationData = null;
  let nextId;

  let localisationDataLoadPromise = null;

  const getLocalisationData = () => {
    if (!localisationDataLoadPromise) {
      localisationDataLoadPromise = $http.get(
        ConfigURLs.getLocalisationData(Session.current.account._id)
      ).then((res)=> {
        localisationData = res.data.localisation;
        nextId = localisationData.nextId;
        return res.data;
      })
      .catch((err) => {
        $log.error(err);
      });
    }
    return localisationDataLoadPromise;
  };

  const setLocalisationData = (updated) => {
    return $http
      .put(
        ConfigURLs.setLocalisationData(Session.current.account._id),
        { localisation: updated },
        { noErrorToast: true }
      )
      .then((res) => {
        localisationData = res.data.localisation;
        return res.data;
      })
      .catch((err) => {
        $log.error(err);
      });
  };

  getLocalisationData();

  /**
   * This function recursively calls itself to find the nearest node where
   * content defined.
   *
   * @param {Array} tree - The locale tree
   * @param {Number} id - Node id
   * @returns {Undefined} - Nothing is returned
   */
  function getContentLocale (tree, id) {
    let node = _.find(tree, { id: id });
    if (
      node.behaviour === 'disabled' ||
      node.behaviour === 'localised' ||
      node.behaviour === 'primary'
    ) {
      return node;
    } else if (node.hasOwnProperty('linkTo')) {
      // Copy node to not mutate original object
      let contentFrom = angular.copy(_.find(tree, { id: node.linkTo }));
      // Set the id of the locale as a flag where the content was linked
      contentFrom.inheritingLinkedContentVia = node;
      return contentFrom;
    } else if (node.hasOwnProperty('parent')) {
      return getContentLocale(tree, node.parent);
    }
    return null;
  }

  /**
   * Creates the flattened array from the built locale tree.
   *
   * @param {Array} tree - The built locale tree
   * @returns {Array} - The flattened locale tree
   */
  const getFlattenedTree = (tree) => {
    let flattenedTree = [];

    // Recursive function to create the flattened locale tree.
    function flatten (localeTree) {
      for (const child in localeTree) {
        let node = localeTree[child];
        flattenedTree.push(node);
        flatten(localeTree[child].children);
      }
      return flattenedTree;
    }

    return tree ? flatten(tree) : angular.copy(localisationData.locales);
  };

  /**
   * Iterates over the flattened locale tree, set `disabled` and `linkedBy`
   * flags for every locale.
   *
   * @param {Array} tree - The locale tree
   * @returns {Undefined} - Nothing is returned
   */
  const setLocaleFlags = (tree) => {
    let flat = getFlattenedTree(tree);
    _.each(flat, (locale)=> {
      let contentLocale = getContentLocale(flat, locale.id);

      locale.disabled =
        !contentLocale ||
        contentLocale.behaviour === 'disabled' ||
        contentLocale.pending;

      locale.linkedBy = _.where(flat, { linkTo: locale.id });
    });
  };

  /**
   * Building the locale tree from the flat array.
   *
   * @param {Array} tree - The flat locale tree
   * @returns {Array} - The built locale tree
   */
  const getUnflattenedTree = (tree) => {
    /**
     * This function recursively calls itself to build the locale tree from the
     * flat array.
     *
     * @param {Array} array - The array of nodes that are on the same level
     * @param {Array} parent - The parent of the current node
     * @param {Array} localeTree - The locale tree
     * @returns {Array} - The built locale tree
     */
    function unflatten (array, parent, localeTree) {
      localeTree = typeof localeTree !== 'undefined' ?
        localeTree : [_.find(array, { id: 0 })];
      parent = typeof parent !== 'undefined' ? parent : localeTree[0];

      let children = _.filter(array, function (child) {
        child.children = child.children ? child.children : [];
        return child.parent === parent.id;
      });

      if (!_.isEmpty(children)) {
        parent.children =
          _.sortBy(children, (node) => node.name.toLowerCase(), 'name');
        _.each(children, function (child) {
          unflatten(array, child);
        });
      }

      return localeTree;
    }

    let unflattenedTree = unflatten(
      tree ? tree : angular.copy(localisationData.locales)
    );
    setLocaleFlags(unflattenedTree);

    return unflattenedTree;
  };

  /**
   * Returns the locale object for the given id.
   *
   * @param {Number} id - Node id
   * @returns {Object} - Locale object
   */
  const getLocaleById = (id) => _.find(
    angular.copy(localisationData.locales),
    { id: parseInt(id) }
  );

  /**
   * Returns the path for a locale.
   *
   * @param {Object} node - The locale object
   * @returns {String} - The path of the locale
   */
  const getLocalePath = (node) => {
    let path = [` ${node.name}`];
    // If node is All screens or a direct children of All screens just return
    // the name of the node.
    if (!node.parent) return node.name;

    // Build path for the node until we get to the first level in the tree
    do {
      node = _.find(localisationData.locales, { id: node.parent });
      path.unshift(`${node.name} / `);
    } while (node.parent);

    return path.join('');
  };

  /**
   * Returns the id to assign to new locales.
   *
   * @returns {Number} - The current id to use for new locale
   */
  const getNextId = ()=> nextId;

  /**
   * Reset locally incremented next id.
   *
   * @returns {Undefined} - Nothing is returned
   */
  const resetNextId = ()=> {
    nextId = localisationData.nextId;
  };

  /**
   * Increment next id for newly created locales.
   *
   * @returns {Undefined} - Nothing is returned
   */
  const incrementNextId = ()=> { nextId++; };

  /**
   * Returns TRUE when localisation is enabled.
   *
   * @returns {Boolean} - TRUE if localisation is enabled
   */
  const isLocalisationEnabled = () =>
    localisationData && localisationData.enabled;

  /**
   * Returns TRUE when localisation related fields can be displayed.
   *
   * @returns {Boolean} - TRUE if localisation fields can be shown
   */
  const showLocaleFields = () =>
    isLocalisationEnabled() && localisationData.locales.length > 1;

  /**
   * Returns an array with the count of the pending locales, locales affected
   * by pending locales and valid localisation count in this order.
   *
   * @param {Array} tree - Locale tree merged with slide content
   * @returns {Array} - [pending, pending affected, valid localisation count]
   */
  const getLocalisationInfo = (tree) => {
    let counters = {
      pendingLocales: 0,
      pendingAffectedLocales: 0,
      completedLocalisations: 0
    };

    /**
     * Increments counter for pending and completed locales. Also calling
     * `countInheritingLocalesFrom` function to set the affected locales count.
     *
     * @param {Array} branch - Locale tree merged with slide content
     * @returns {undefined} - Nothing is returned
     */
    function countPendingAndCompletedLocales (branch) {
      _.each(branch, (child) => {
        if (child.pending) {
          // Locale is pending, increment counter
          counters.pendingLocales++;
          // Count the locales inheriting from this locale
          countInheritingLocalesFrom(child, counters, 'pendingAffectedLocales');
          // Also count locales that are linking to this locale
          _.each(child.linkedBy, (locale) => {
            counters.pendingAffectedLocales++;
            countInheritingLocalesFrom(
              locale, counters, 'pendingAffectedLocales'
            );
          });
        } else if (
          child.id !== 0 && child.hasOwnProperty('pending') && !child.pending
        ) {
          // We don't count 0 (All screens) as localised even if completed
          counters.completedLocalisations++;
        }
        countPendingAndCompletedLocales(child.children);
      });
    }

    /**
     * Increments counter for locales that are inheriting content from the given
     * locale.
     *
     * @param {Array} locale - Locale for counting children inheriting from
     * @param {Array} counterObj - The counter object
     * @param {Array} key - Key for the counter
     * @returns {undefined} - Nothing is returned
     */
    function countInheritingLocalesFrom (locale, counterObj, key) {
      _.each(locale.children, (child) => {
        if (!child.behaviour) {
          counterObj[key]++;
          countInheritingLocalesFrom(child, counterObj, key);
        }
      });
    }

    countPendingAndCompletedLocales(tree);

    return [
      counters.pendingLocales,
      counters.pendingAffectedLocales,
      counters.completedLocalisations
    ];
  };

  return {
    getLocalisationData: getLocalisationData,
    setLocalisationData: setLocalisationData,
    getUnflattenedTree: getUnflattenedTree,
    getFlattenedTree: getFlattenedTree,
    getLocaleById: getLocaleById,
    getLocalePath: getLocalePath,
    getContentLocale: getContentLocale,
    setLocaleFlags: setLocaleFlags,
    getNextId: getNextId,
    resetNextId: resetNextId,
    incrementNextId: incrementNextId,
    isLocalisationEnabled: isLocalisationEnabled,
    showLocaleFields: showLocaleFields,
    getLocalisationInfo: getLocalisationInfo
  };
})

.controller('LocalisationController', function (
  $q, $scope, $log, LocaleTree, Modal, SaveCheck, Toast
) {
  let vm = this;
  vm.treeModified = false;
  vm.localisationData = LocaleTree.getLocalisationData();

  vm.localisationData.then((data)=> {
    vm.localisationData = data.localisation;
    // Set flags for locales in use to disable their delete button and display
    // a message why they cannot be deleted
    _.each(vm.localisationData.locales, (locale) => {
      if (_.contains(vm.localisationData.localeIdsInUse.devices, locale.id)) {
        locale.usedByDevices = true;
      }
      if (_.contains(vm.localisationData.localeIdsInUse.slides, locale.id)) {
        locale.usedBySlides = true;
      }
    });

    vm.localeTree = LocaleTree.getUnflattenedTree();
  });

  /**
   * Broadcasts a message to toggle the state of all locales (expand/collapse).
   *
   * @param {Boolean} val - TRUE when collapsing nodes
   * @returns {Undefined} - Nothing is returned
   */
  vm.toggleAll = (val) => {
    $scope.$broadcast(`angular-ui-tree:${val ? 'collapse' : 'expand'}-all`);
  };

  /**
   * Compares two locales array to check if any of the locales parents have
   * changed.
   *
   * @param {Array} old - Locales before update
   * @param {Array} updated - TRUE when collapsing nodes
   * @returns {Boolean} - TRUE if none of the already existing locales parent
   * have changed
   */
  const hasParentsChanged = (old, updated) =>
    _.any(old, (locale) => {
      let updatedLocale = _.find(updated, { id: locale.id });
      return updatedLocale ? locale.parent !== updatedLocale.parent : false;
    });

  /**
   * Save the changes made to the locale tree.
   *
   * @returns {Undefined} - Nothing is returned
   */
  vm.saveChanges = () => {
    let deferred = $q.defer();

    let updatedLocales = LocaleTree.getFlattenedTree(vm.localeTree);

    if (hasParentsChanged(vm.localisationData.locales, updatedLocales)) {
      let modalOpts = {
        templateUrl: 'utility/modal-templates/confirmation.jade',
        scopeData: {
          title: 'Unsafe change detected',
          message: `Rearranging the localisation tree may cause unintended
            consequences, e.g. displaying slides in locales that were
            previously disabled (or no longer displaying slides in locales
            that were enabled) due to a change in locale inheritance.
          `,
          positiveButton: 'Confirm and save'
        },
        dismissable: {
          backButton: false, escape: true, backgroundClick: false
        }
      };

      new Modal(modalOpts)
        .show()
        .then(function () {
          deferred.resolve();
        })
        .catch((err) => {
          Toast.makeError(err);
        });
    } else {
      deferred.resolve();
    }

    deferred.promise.then(()=> {
      let updatedLocalisationData = _.cloneDeep(vm.localisationData);
      updatedLocalisationData.locales =
        LocaleTree.getFlattenedTree(vm.localeTree);
      LocaleTree.setLocalisationData(updatedLocalisationData)
      .then((res)=> {
        vm.localisationData = res.localisation;
        vm.treeModified = false;
        Toast.makeSuccess('Localisation tree updated successfully');
      })
      .catch((e)=> {
        let message1 = `An unexpected error occurred processing the request.
          Please refresh the page and try again, or contact support if the
          error persists.`;
        let message2 = null;
        if (e.data && e.data.message) {
          message1 = e.data.message;
        }
        if (e.status === 400) {
          // TODO: Update with actual article link.
          message2 = `Please refer to the help article on
            [Managing Locales](https://pixelnebula.com), or contact support
            for assistance.`;
        }
        let modalOpts = {
          templateUrl: 'utility/modal-templates/locale-update-failure.jade',
          scopeData: {
            message1: message1,
            message2: message2
          },
          dismissable: {
            backButton: false, escape: true, backgroundClick: false
          }
        };

        new Modal(modalOpts)
          .show()
          .then(null)
          .catch((err) => {
            Toast.makeError(err);
          });
      });
    });
  };

  /**
   * Revert changes made to the locale tree.
   *
   * @returns {Undefined} - Nothing is returned
   */
  vm.revertChanges = () => {
    vm.localeTree = LocaleTree.getUnflattenedTree(
      angular.copy(vm.localisationData.locales)
    );
    LocaleTree.resetNextId();
    Toast.makeSuccess('Changes reverted successfully');
    vm.treeModified = false;
  };

  SaveCheck.register({
    hasChanges: function () {
      return vm.treeModified;
    },
    discardChanges: function () {
      vm.revertChanges();
    },
  });
})

.directive('pxnLocaleTree', function (LocaleTree) {
  return {
    restrict: 'E',
    scope: {
      tree: '=',
      filterValue: '<',
      treeModified: '=',
      treeInherited: '<',
      selectCallback: '&',
      setBehaviourCallback: '&'
    },
    templateUrl: 'localisation/pxnLocaleTree.jade',
    link: function (scope, el, attrs) {
      // Behaviours
      const LINKED = 'linked';
      const LOCALISED = 'localised';
      const DISABLED = 'disabled';
      const PRIMARY = 'primary';

      scope.selectedNode = null;
      scope.localeManager = attrs.localeManager;
      scope.localeSelector = attrs.localeSelector;
      scope.infoMessage = '';

      let vm = this;

      // Apply filter when value changes.
      let dereg = scope.$watch('filterValue', function (filterVal) {
        scope.filter(scope.tree[0], filterVal);
      });

      /**
       * This function creates a dynamic message for the selected locales in the
       * locale manager.
       *
       * @param {Object} node - The selected locale object
       * @returns {Undefined} - Nothing is returned
       */
      scope.setMessage = (node) => { // eslint-disable-line complexity
        scope.selectedNode = node || scope.selectedNode;
        if (node === null) {
          scope.infoMessage = '';
          return;
        }

        if (node.id === 0) {
          // Disabled message for All screens
          if (node.behaviour === DISABLED) {
            scope.infoMessage = `This slide will not be displayed on screens
              that have been assigned to the global content locale, or any
              locales that inherit content from here.`;
          } else {
            scope.infoMessage = node.pending ?
              // Pending content message:
              'Global content has not been set for this slide yet.' :
              // All screens has content message
              'Global content has been set for this slide.';
          }
          return;
        }
        // This is where the content is set for the selected node
        vm.contentFrom =
          node.data && (node.data.landscape || node.data.portrait) ?
            node : // Self, if the selected node has content
            // Lookup where node inherits content from
            LocaleTree.getContentLocale(
              LocaleTree.getFlattenedTree(scope.tree), node.id
            );

        switch (node.behaviour) {
          // Wordy message when behaviour is set for the selected locale
          case DISABLED: {
            scope.infoMessage = `This slide will not be displayed on screens
              that have been assigned to this locale${
                node.children.length ?
                  ' or any locales that inherit content from here' :
                  ''
              }.`;
            break;
          }
          case LOCALISED: {
            if (node.pending) {
              scope.infoMessage = `This slide will not be displayed on screens
                that have been assigned to this locale until content has been
                localised and marked as complete.`;
            } else {
              scope.infoMessage = `Screens assigned to this locale will display
                localised content.`;
            }
            break;
          }
          case LINKED: {
            if (node.hasOwnProperty('linkTo')) {
              if (vm.contentFrom.pending) {
                scope.infoMessage = `This slide will not be displayed on screens
                  that have been assigned to this locale until content for the
                  locale that it links to has been localised and marked as
                  complete.`;
              } else {
                scope.infoMessage = `Screens assigned to this locale will
                  display localised content from the locale that it links to.`;
              }
            } else {
              scope.infoMessage = `Please select one of the highlighted locales
                to link to and inherit content from.`;
            }
            break;
          }
          default: {
            let via = vm.contentFrom.inheritingLinkedContentVia;
            switch (vm.contentFrom.behaviour) {
              // Behaviour isn't set for the selected locale,
              // Set info message from the locale where content inherited from
              case DISABLED: {
                scope.infoMessage = `This slide will not be displayed on screens
                  that have been assigned to this locale as it inherits content
                  from a disabled parent locale, "${vm.contentFrom.name}".`;
                break;
              }
              case PRIMARY: {
                scope.infoMessage = `Screens assigned to this locale will
                  display content from the global content locale
                  "${vm.contentFrom.name}"${via ?
                    ` (via the link inherited from one of its parent locales,
                    "${via.name}")` : ''
                  }${vm.contentFrom.pending ?
                    ' when that locale has been marked as complete' : ''
                  }.`;
                break;
              }
              case LOCALISED: {
                scope.infoMessage = `Screens assigned to this locale will
                  display localised content from locale
                  "${vm.contentFrom.name}"${via ?
                    ` (via the link inherited from one of its parent locales,
                    "${via.name}")` : ''
                  }${vm.contentFrom.pending ?
                    ' when that locale has been marked as complete' : ''
                  }.`;
                break;
              }
              default: {
                // This shouldn't be possible as at minimum the global content
                // locale must have either 'primary' or 'disabled' status.
                scope.infoMessage = `Screens assigned to this locale may display
                  content if it can be inherited from the parent locale
                  "${vm.contentFrom.name}".`;
              }
            }
          }
        }
      };

      /**
       * Sets the tree modified.
       *
       * @returns {Undefined} - Nothing is returned
       */
      scope.setTreeModified = () => {
        if (!scope.treeModified) scope.treeModified = true;
      };

      /**
       * Calls setBehaviourCallback and updates the info message.
       *
       * @param {String} behaviour - Selected behaviour for the locale
       * @returns {Undefined} - Nothing is returned
       */
      scope.setBehaviour = (behaviour) => {
        scope.setBehaviourCallback({ behaviour: behaviour });
        scope.setMessage(scope.selectedNode);
      };

      /**
       * Filter function that recursively calls itself on every branch of the
       * tree to set the match flag on nodes to apply relevant styling to them.
       *
       * @param {Array} tree - The locale tree
       * @param {String} filterString - The string to filter by
       * @returns {Undefined} - Nothing is returned
       */
      scope.filter = (tree, filterString) => {
        // Delete the match property when not filtering;
        if (!filterString && tree.hasOwnProperty('match')) delete tree.match;
        tree.match =
          filterString &&
          _.contains(tree.name.toLowerCase(), filterString.toLowerCase());
        _.each(tree.children, function (children) {
          scope.filter(children, filterString);
        });
      };

      scope.treeOptions = {
        // Every locale should be nested under All screens
        beforeDrag: (sourceNodeScope) => sourceNodeScope.node.id !== 0,
        //
        accept: (sourceNodeScope, destNodesScope, destIndex) => {
          // Prevent dragging nodes out of All screens
          if (!destNodesScope.$nodeScope) return false;
          if ( // Parent is the same, nothing to update
            sourceNodeScope.node.parent === destNodesScope.$nodeScope.node.id
          ) { return true; }
          // Update parent id for the dragged node
          sourceNodeScope.node.parent = destNodesScope.$nodeScope.node.id;
          scope.setTreeModified();
          return true;
        },
      };

      scope.$on('$destroy', dereg());
    }
  };
})

.directive('nodesRenderer', function (
  $timeout, LocaleTree, Modal, Onboarding, Toast
) {
  return {
    restrict: 'A',
    require: ['^ngModel', '^^ngModel'],
    templateUrl: 'localisation/nodes-renderer.jade',
    link: function (scope, el, attrs, controllers) {
      const model = controllers[0];
      const parentModel = controllers[1];

      let usedByResource = '';

      if (scope.node.usedBySlides && !scope.node.usedByDevices) {
        usedByResource = 'slides';
      } else if (!scope.node.usedBySlides && scope.node.usedByDevices) {
        usedByResource = 'devices';
      } else if (scope.node.usedBySlides && scope.node.usedByDevices) {
        usedByResource = 'slides and devices';
      }

      scope.disabledMessage = `
        This locale is used by one or more ${usedByResource}.
        Deleting a locale is only possible when no slide or device is
        referencing it.
      `;

      scope.$on('angular-ui-tree:collapse-all', function (e) {
        if (scope.node.id === 0) scope.expand();
      });

      // Set nodes selected property
      scope.$on('angular-ui-tree:select-with-id', function (e, id) {
        scope.node.selected = typeof id === 'number' && scope.node.id === id;
      });

      // Deselect every selected node
      scope.$on('angular-ui-tree:deselect-selected', function () {
        if (scope.node.selected) {
          delete scope.node.selected;
        }
      });

      // Highlight all locales where the selected locale can be linked to
      scope.$on('angular-ui-tree:highlight-linkable-locales', function (
        e, linkingId
      ) {
        if (linkingId) scope.linkContent = true;
        if (!linkingId) {
          scope.linkContent = false;
          delete scope.node.highlighted;
          return;
        }
        if (
          ( // Locale is localised or 'All screens' (which isn't disabled)
            scope.node.behaviour === 'localised' ||
            (scope.node.id === 0 && scope.node.behaviour !== 'disabled')
          ) && scope.node.id !== linkingId // Can't link to self
        ) {
          scope.node.highlighted = true;
        }
      });

      /**
       * Returns a boolean to determine if the node has content defined.
       *
       * @returns {Boolean} - TRUE if node has content
       */
      scope.hasContent = () =>
        scope.localeManager &&
        _.keys(scope.node.data).length &&
        !scope.node.pending;

      /**
       * Set node selected
       *
       * @returns {Undefined} - Nothing is returned
       */
      scope.select = () => {
        // When linking
        if (scope.linkContent) {
          // Locale can only be selected if highlighted
          if (scope.node.highlighted) {
            // Call selectCallback with locale and update message
            scope.selectCallback({ locale: scope.node });
            scope.setMessage(scope.$treeScope.selectedNode);
          }
          return; // Not highlighted, return early
        }
        // If we get here we're not linking
        if (scope.node.selected) {
          // Locale was already selected, deselect it
          delete scope.node.selected;
          scope.selectedNode = null;
        } else {
          // Deselect other selected nodes
          scope.$treeScope.$broadcast('angular-ui-tree:deselect-selected');
          // Select this locale
          scope.node.selected = true;
          scope.selectedNode = scope.node;
        }
        scope.selectCallback({ locale: scope.node }); // Call callback
        if (scope.localeManager) {
          scope.setMessage(scope.node); // Update message
        }
      };

      /**
       * Returns the name of the locale for the given id.
       *
       * @param {Number} id - Node id
       * @returns {String} - Name of locale with id
       */
      scope.getNameOfLink = (id) => LocaleTree.getLocaleById(id).name;

      /**
       * Toggles the collapsed state of the locale.
       *
       * @param {Event} e - The event Object
       * @returns {Undefined} - Nothing is returned
       */
      scope.toggleNode = (e) => {
        e.stopImmediatePropagation();
        scope.collapsed ? scope.expand() : scope.collapse();
      };

      /**
       * Edit locale handler.
       *
       * @returns {Undefined} - Nothing is returned
       */
      scope.editLocale = () => {
        const modalOpts = {
          templateUrl: 'utility/modal-templates/create-edit-locales.jade',
          scopeData: {
            isNew: false,
            locale: angular.copy(scope.node) // Only use a copy in the modal
          },
          dismissable: {
            backButton: false, escape: true, backgroundClick: false
          }
        };
        new Modal(modalOpts)
          .show()
          .then(function (nodeName) {
            scope.node.name = nodeName;
            scope.setTreeModified();
          })
          .catch((err) => {
            Toast.makeError(err);
          });
      };

      /**
       * Create sub locale handler.
       *
       * @returns {Undefined} - Nothing is returned
       */
      scope.addSubLocale = () => {
        let newLocale = {
          id: LocaleTree.getNextId(),
          parent: scope.node.id,
          children: []
        };
        if (!scope.node.children) scope.node.children = [];

        const modalOpts = {
          templateUrl: 'utility/modal-templates/create-edit-locales.jade',
          scopeData: {
            isNew: true,
            locale: newLocale
          },
          dismissable: {
            backButton: false, escape: true, backgroundClick: false
          }
        };

        new Modal(modalOpts)
          .show()
          .then(function () {
            LocaleTree.incrementNextId();
            // Insert locale
            scope.$childNodesScope.insertNode(
              scope.$modelValue.children.length,
              newLocale
            );
            scope.collapsed = false;
            scope.setTreeModified();
            $timeout(Onboarding.triggerOverviewResourceAvailable);
          })
          .catch((err) => {
            Toast.makeError(err);
          });
      };

      /**
       * Delete locale handler.
       *
       * @returns {Undefined} - Nothing is returned
       */
      scope.deleteLocale = () => {
        // Remove locale
        scope.remove();
        scope.setTreeModified();
      };

      scope.$on('$destroy', () => {
        delete scope.node.selected;
        delete scope.node.match;
      });
    }
  };
})

.directive('localisationInfo', function (LocaleTree) {
  return {
    restrict: 'E',
    scope: {
      content: '<',
      locales: '<',
      localeTree: '<'
    },
    templateUrl: 'localisation/localisation-info.jade',
    link: function (scope) {
      function setInfo () {
        let flatTree = [];

        if (scope.locales) {
          flatTree = _.cloneDeep(scope.locales);
          _.each(flatTree, (locale)=> {
            if (scope.content[locale.id]) {
              locale = Object.assign(locale, scope.content[locale.id]);
            }
          });
        }

        [scope.pendingCount, scope.affectedCount, scope.validLocalisations] =
          LocaleTree.getLocalisationInfo(
            scope.locales ?
              LocaleTree.getUnflattenedTree(flatTree) : scope.localeTree
          );
      }

      setInfo();

      scope.$on('localisation-info:update', setInfo);

      let deregFunction = angular.noop();

      if (scope.locales) {
        deregFunction = scope.$watch('content', (newVal, oldVal) => {
          if (newVal && oldVal && newVal !== oldVal) {
            setInfo();
            deregFunction();
          }
        });
      } else {
        deregFunction = scope.$watch('localeTree', (newVal) => {
          if (newVal) {
            setInfo();
          }
        });
      }

      scope.$on('$destroy', ()=> {
        deregFunction();
      });
    }
  };
});
