angular.module('flare.receiver.video', ['flare.receiver.player'])

.provider('MediaService', function MediaServiceProvider ($injector) {
  // Decide if we're using an electron device.
  var isElectron =
    localStorage.isElectron ||
    (window.androidSignageApp == null) && window.process
      && window.process.versions && window.process.versions.electron;

  // Android handles videos differently.
  if (!isElectron) {
    this.$get = angular.noop;
    return;
  }

  // Define useful provider vars.
  var ipc = window.require('electron').ipcRenderer;

  var mediaList = {};

  // Define helper methods.
  function addMediaToList (sender, media) {
    if (!angular.isArray(media)) {
      media = [media];
    }
    _.each(media, function (newItem, index) {
      var { id, path } = newItem;
      if (id && path) {
        mediaList[id] = path;
      } else {
        // If the item didn't have an id and/or path then something pretty bad
        // has happened and we need to make sure we get a log of it!
        ipc.send(
          'receiverLog',
          {
            level: 'error',
            message: `Unable to add media to list, media must have a valid id
            (${id}) & path (${path})`
          }
        );
      }
    });
  }

  /**
   * This function is used by `getVideos` to populate the object that has been
   * returned to the requester, by adding an event listener to
   * `'newMediaAvailable'`. It also resolves the deferred passed to it.
   * @param {string} id The id of the media whose availability we want to be
   *                    informed about.
   * @param {*} deferred A deferred object to resolve once the media becomes
   *                     available.
   * @param {*} videosObj The object to populate with the id and path of the
   *                      media once available.
   * @returns {undefined} Nothing.
   */
  function addMediaWhilstWaiting (id, deferred, videosObj, logProvider) {
    function callback (sender, media) {
      var path = media.path;
      var availableId = media.id;
      if (id === availableId) {
        logProvider.debug(`[Videos] Video ${id} now available.`);
        videosObj.videos[availableId] = path;
        if (deferred) deferred.resolve();
        ipc.removeListener('newMediaAvailable', callback);
      }
    }
    ipc.on('newMediaAvailable', callback);
  }

  // Register ipc listeners, these will always be on and never get cancelled.
  ipc.on('newMediaAvailable', addMediaToList);
  // Note that at present listMediaAvailable is triggered by the `reqeustSlides`
  // emission, which is emitted when the Events service is instantiated.
  ipc.on('listMediaAvailable', addMediaToList);

  this.$get = ['$q', '$log', function MediaServiceFactory ($q, $log) {
    var factory = {};

    /**
     * Returns an object that will be populated with the path to vavailableIdeos
     * once they become available. Also has a `videosReady` promise which will
     * resolve when all videos are ready to play.
     *
     * @param {[string]|string} ids An array of video ids required.
     * @returns {object} Object with keys for ids of each requested video, and
     *                   value corresponding to video path, plus `videosReady`
     *                   promise property.
     */
    factory.getVideos = function getVideos (ids) {
      if (typeof ids === 'string') {
        ids = [ids];
      }
      $log.debug(`[Videos] Slideshow requesting videos: ${ids.join(',')}`);

      var videosObj = {
        videos: {},
        promises : {}
      };
      var videoPromises = [];

      _.each(ids, function (id, index) {
        var deferred = $q.defer();
        videoPromises.push(deferred.promise);
        videosObj.promises[id] = deferred.promise;
        if (mediaList[id]) {
          $log.debug(`[Videos] Video ${id} already available`);
          videosObj.videos[id] = mediaList[id];
          deferred.resolve();
        } else {
          addMediaWhilstWaiting(id, deferred, videosObj, $log);
        }
      });

      videosObj.videosReady = $q.all(videoPromises);

      return videosObj;
    };

    return factory;
  }];
})

// There is never a time we want video to continue playing in the background.
.directive('video', function ($q, Player) {
  return {
    restrict: 'E',
    scope: false,
    require: '?^^flareTemplate',
    link: function videoLink (scope, element, attributes, templateCtrl) {
      if (!templateCtrl || Player.isWebReceiver || attributes.addedDynamically)
        return;
      let videoElement = element[0];

      let preShowDeferred;
      let slideActive = false;
      let src;

      attributes.$observe('src', function (value) {
        let tValue = value.trim();
        // prevent anything setting this video's source until it's ready to be
        // played (`slideActive` is set just before showing the slide), but
        // cache any 'requests' to change the source.
        if (tValue !== undefined) src = tValue;

        if (!slideActive && tValue) {
          videoElement.removeAttribute('src');
          videoElement.load();
        }
      });

      function onLoadedData () {
        // Check the video is ready to play after a 'loadeddata' event.
        if (videoElement.readyState >= 2) { // most likely will jump from 1->4.
          preShowDeferred.resolve();
        }
      }

      templateCtrl.postHideFns.push(function () {
        // Pause the video, remove `src` attribute and reload - this should
        // uncache the video: https://stackoverflow.com/a/28060352/3261808
        slideActive = false;
        videoElement.pause();
        videoElement.removeAttribute('src');
        videoElement.load();
      });

      templateCtrl.preShowPromiseGenerators.push(function () {
        // Reload the video before the slide is shown.
        slideActive = true;
        preShowDeferred = $q.defer();

        videoElement.src = src;
        videoElement.load();
        videoElement.addEventListener('loadeddata', onLoadedData, {
          passive: true, once: true
        });
        return preShowDeferred.promise;
      });
    }
  };
})

// Plays the video via postShowFns
.directive('playOnShow', function playOnShowDirectiveFn (Player) {
  return {
    restrict: 'A',
    require: '?^^flareTemplate',
    link: function playOnShowLinkFn (scope, element, attributes, templateCtrl) {
      if (!templateCtrl || Player.isWebReceiver) return;
      let videoElement = element[0];

      templateCtrl.postShowFns.push(function play () {
        videoElement.play();
      });

    }
  };
})

/**
 * This directive hides the slide when the video finishes playing unless in
 * single slide mode.
 */
.directive('hideOnEnd', function hideOnEndDirectiveFn (Player, $log) {
  return {
    restrict: 'A',
    require: ['?^^flareSlideshow', '?^^flareTemplate'],
    link: function hideOnEndLinkFn (scope, element, attributes, controllers) {
      // We don't play videos in web receiver.
      if (Player.isWebReceiver) return;

      const slideshowCtrl = controllers[0];
      const templateCtrl = controllers[1];
      let videoElem = element[0];

      let lastCurrentTime;
      let watchdogInterval = null;
      /**
       * This function checks that the `currentTime` for the video is increasing
       * - in some cases videos have been found to end without firing the onEnd
       * listener, which would cause a slideshow relying on this directive to
       * stall.
       */
      function startWatchdog () {
        lastCurrentTime = videoElem.currentTime;
        watchdogInterval = setInterval(function () {
          if (
            lastCurrentTime !== null &&
            videoElem.currentTime == lastCurrentTime
          ) {
            $log.debug('[Hide On End] - Detected video stalled, invalidating.');
            stopWatchdog();
            scope.$apply(templateCtrl.invalidate);
          } else {
            lastCurrentTime = videoElem.currentTime;
          }
        }, 5000);
      }

      function stopWatchdog () {
        lastCurrentTime = null;
        clearInterval(watchdogInterval);
      }

      templateCtrl.postShowFns.push(startWatchdog);

      function configureLooping () {
        // Configure whether the video will loop or not.
        if (templateCtrl.isTakeover) {
          $log.debug('[Hide On End] - Slide is a takeover, video will loop.');
          videoElem.setAttribute('loop', '');
        } else if (slideshowCtrl.singleSlide) {
          $log.debug('[Hide On End] - In singleSlide mode, video will loop.');
          videoElem.setAttribute('loop', '');
        } else {
          // Remove possible attribute.
          $log.debug('[Hide On End] - Slide will unload on video end.');
          videoElem.removeAttribute('loop', '');
        }
      }

      videoElem.addEventListener('ended', function () {
        // Trigger the slide to unload when video ends.
        // Note: the 'ended' event shouldn't fire if the video is looping,
        // but we might as well check for single-slide mode anyway.
        if (!slideshowCtrl.singleSlide) {
          stopWatchdog();
          scope.$emit('flare-slide-unload');
        }
      });

      scope.$on('flare-single-slide-mode', configureLooping);

      scope.$on('$destroy', function () {
        stopWatchdog();
      });

      configureLooping();
    }
  };
})

// Invalidates the slide if there are any playback errors (or the video isn't
// available).
.directive('invalidateOnError', function ($log, Player) {
  return {
    restrict: 'A',
    require: '?^^flareTemplate',
    link: function invalidateOnErrLink (
      scope, element, attributes, templateCtrl
    ) {
      if (Player.isWebReceiver) return;

      element[0].onerror = function (e) {
        templateCtrl.invalidate();
        $log.error(
          'Video playback failed, invalidating.' +
          `Src: ${element[0].getAttribute('src')}`
        );
      };
    }
  };
})

// Runs an expression (with a digest) when the video ends.
.directive('onEnded', function () {
  return {
    scope: {
      onEnded: '&'
    },
    link: function (scope, element) {
      element.on('ended', scope.onEnded);
    }
  };
});
