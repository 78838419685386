angular.module('pxn-scroll-utils', [])

.factory('ScrollState', function ($rootScope, $log, $timeout) {
  var scrollStates = {};

  function save (id, data) {
    scrollStates[id] = data;
  }

  function triggerSave (id) {
    $rootScope.$broadcast('pxnSaveScrollState', { id: id });
  }

  function restore (id, isLast) {
    var scrollData = scrollStates[id];
    if (scrollData == null) {
      $log.warn('[Scroll Utils] No scroll data for id ' + id);
      return;
    }
    // This timeout allows us to restore the scroll after (e.g.) an ng-if
    // causes the element to be re-shown.
    $timeout(function () {
      $rootScope.$broadcast('pxnRestoreScrollState', {
        id: id,
        scrollTop: scrollData.top,
        scrollLeft: scrollData.left,
        isLast: isLast
      });
    });
  }

  return {
    save: save,
    restore: restore,
    triggerSave: triggerSave
  };
})

.directive('saveScrollState', function () {

  function saveScrollStateController ($scope, $element, ScrollState) {

    var scrollId = this.scrollId || Date.now();

    $scope.$on('pxnSaveScrollState', function saveListener (e, data) {
      if (data.id === scrollId) {
        ScrollState.save(scrollId, {
          top: $element[0].scrollTop,
          left: $element[0].scrollLeft
        });
      }
    });

    $scope.$on('pxnRestoreScrollState', function restoreListener (e, data) {
      if (data.id !== scrollId) return;
      $element[0].scrollTop = data.scrollTop;
      $element[0].scrollLeft = data.scrollLeft;
    });
  }

  return {
    restrict: 'A',
    scope: {
      scrollId: '@saveScrollState'
    },
    controllerAs: 'vm',
    bindToController: true,
    controller: saveScrollStateController,
  };
})

.directive('saveScrollState2', function () {

  function saveScrollStateController2 (
    $scope, ScrollState, $attrs, $document, $element, $timeout
  ) {
    var scrollId = $attrs.saveScrollState2 || Date.now();

    var scrollElem =
      $element[0].querySelector('[save-scroll-state2] > [pxn-scroll-element]')
      || $document[0].scrollingElement;

    $scope.$on('pxnSaveScrollState', function saveListener (e, data) {
      if (data.id === scrollId) {
        ScrollState.save(scrollId, {
          top: scrollElem.scrollTop,
          left: scrollElem.scrollLeft
        });
      }
    });

    const maxFramesToWait = 60;
    function updateScrollOnAnim (elem, targetTop, isLast, i = 0) {
      requestAnimationFrame(function updateScroll () {
        if ((elem.scrollHeight < (targetTop + elem.clientHeight)) &&
          i++ < maxFramesToWait
        ) {
          requestAnimationFrame(
            updateScrollOnAnim.bind(null, elem, targetTop, isLast, i)
          );
        } else {
          elem.scrollTop = !isLast ? targetTop : elem.scrollHeight;
        }
      });
    }

    $scope.$on('pxnRestoreScrollState', function restoreListener (e, data) {
      if (data.id !== scrollId) return;
      updateScrollOnAnim(scrollElem, data.scrollTop, data.isLast);
    });
  }

  return {
    restrict: 'A',
    scope: {
      scrollId: '@saveScrollState2'
    },
    controllerAs: 'vm',
    bindToController: true,
    controller: saveScrollStateController2,
  };
})

.directive('scrollIntoViewOn', function () {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      scope.$on(attrs.scrollIntoViewOn, function () {
        element[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
      });
    }
  };
})

.directive(
  'pxnScrollElement',
  function pxnScrollElementDirectiveFn ($window, $timeout) {
    return {
      restrict: 'A',
      controller: function pxnScrollElementControllerFn ($element) {
        // We will set a couple of variables onto this controller in the linkFn
        // these variables will be useful for directive to directive
        // communication.
        this.scrollElement = $element[0];
      },
      link: function pxnScrollElementLinkFn (
        scope,
        element,
        attributes,
        controllers
      ) {
        const UPDATE_DEBOUNCE_MS = 300;

        var options = {
          suppressScrollX: true,
          wheelPropagation: true,
        };

        if (attributes.scrollX) options.suppressScrollX = false;

        // broadcast events children may be interested in (currently used for
        // invalidity callouts). NOTE: these events don't trigger a digest.
        const BROADCAST_EVENTS = [
          'ps-scroll-x', 'ps-scroll-y'
        ];

        BROADCAST_EVENTS.forEach(function (event) {
          element[0].addEventListener(event, function (e) {
            scope.$broadcast(event, e);
          });
        });

        requestAnimationFrame(function () {

          let ps = new PerfectScrollbar(element[0], options);
          // Add scrollbar active classes immediately if the scrollbar is active
          updateActiveClass();

          function updateActiveClass () {
            if (ps.scrollbarYActive && ps.containerHeight) {
              element.addClass('scrollbar__active');
            } else {
              element.removeClass('scrollbar__active');
            }
          }

          function updateOnAnimFrame () {
            requestAnimationFrame(ps.update.bind(ps, element[0]));
            requestAnimationFrame(updateActiveClass);
          }

          function scrollToTop (e, elem) {
            elem.scrollTop = 0;
          }

          let update = _.debounce(updateOnAnimFrame, UPDATE_DEBOUNCE_MS);

          let domListener = new MutationObserver(update);

          domListener.observe(element[0], { childList: true, subtree: true });

          // We can now also watch for container size changes without Angular
          // madness.
          let resizeObserver
          try {
            resizeObserver = new ResizeObserver((entries) => {
              for (let entry of entries) {
                if (entry.target === element[0]) {
                  update();
                }
              }
            });
            resizeObserver.observe(element[0]);
          } catch (error) {
            $log.warn(
              '[pxnScrollElement] -  ResizeObserver not availbale on window.'
            );
          }

          scope.$on('remeasure-for-scrollbars', update);
          scope.$on('scroll-to-top', scrollToTop);

          scope.$on('$destroy', function () {
            domListener.disconnect();
            $window.removeEventListener('resize', update);
          });
        });
      }
    };
  }
)

.directive(
  'loadWhenVisible',
  function loadWhenVisibleDirectiveFn () {
    return {
      restrict: 'EA',
      require: ['^^pxnScrollElement', '?pxnImagePreload'],
      link: function loadWhenVisibleLinkFn (
        scope,
        element,
        attributes,
        controllers
      ) {
        var scrollElemCtrl = controllers[0];
        var preloadCtrl = controllers[1];
        scope.bgImage = scope.$eval(attributes.loadWhenVisible)

        // Define variables for measuring/calculating position.
        var scrollParentBottom =
          scrollElemCtrl.scrollElement.scrollTop +
          scrollElemCtrl.scrollElement.offsetHeight;
        var scrollChildTop = element[0].offsetTop;

        // Check if the element is in view and load/set if we are.
        function initLoadIfVisible () {
          // Update our measurements.
          scrollParentBottom =
            scrollElemCtrl.scrollElement.scrollTop +
            scrollElemCtrl.scrollElement.offsetHeight;
          scrollChildTop = element[0].offsetTop;
          // Check if we're visible.
          if (scrollParentBottom >= scrollChildTop) {
            // Element is in view, load and set image.
            preloadCtrl.doPreload(scope.bgImage).then(function (img) {
              element.css({ backgroundImage: 'url(' + scope.bgImage + ')' });
            }).catch(function (e) {
              element.css({ backgroundImage: 'url(/img/thumb-unavailable.png)' });
            });
            scrollElemCtrl.scrollElement.removeEventListener(
              'ps-scroll-y', initLoadIfVisible
            );
            return true;
          }
          return false;
        }

        // Check if we're already visible, add a listener otherwise.
        if (!initLoadIfVisible()) {
          // We weren't visible, create out listener and bind our fn.
          // Listen for a scroll on the next scrolling parent.
          scrollElemCtrl.scrollElement.addEventListener(
            'ps-scroll-y', initLoadIfVisible
          );
        }

      }
    };
  }
);
