angular.module('force-aspect-ratio', [])

/**
 * @Desc: `forceAspectRatio` directive is forcing the aspect ratio of the
 * element to be the given value by setting its width and height.
 * The element will occupy the maximum space available inside it's parent.
 * Valid values are:
 * - `X`: A number that is used as the desired aspect ratio (0.5625 for 16:9)
 * - `X:Y`: Width and height ratios separated with a colon symbol (16:9, 4:3)
 */
.directive('forceAspectRatio', function ($log) {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      let ratio = attrs.forceAspectRatio;
      let desiredAspectRatio = 0;
      let resizeObserver = null;

      attrs.$observe('forceAspectRatio', function (nVal) {
        let ar = nVal.trim();
        if (ar) {
          ratio = ar;
          init();
        }
      });

      if (!ratio) {
        $log.info(
          '[forceAspectRatio] Aspect ratio value not provided, ' +
          'watching for changes'
        );
        return;
      }

      let lastRaf;

      // Sets inline styles for the element
      function resizeElementToBeContained () {
        // Get the parent element's width and height
        const parentWidth = element[0].parentElement.clientWidth;
        const parentHeight = element[0].parentElement.clientHeight;
        // Calculate the parent aspect ratio
        const parentAspectRatio = parentHeight / parentWidth;

        let requiredWidth, requiredHeight;

        if (desiredAspectRatio <= parentAspectRatio) {
          // If our desired aspect ratio is below the parent's ratio our
          // element can occupy all the available width without overflowing
          // vertically
          requiredWidth = parentWidth;
          // Calculate the height based on the aspect ratio and width
          requiredHeight = desiredAspectRatio * parentWidth;
        } else {
          // Calculate the width based on the height and aspect ratio
          requiredWidth = parentHeight / desiredAspectRatio;
          // We know we can use the full height without reaching the sides of
          // the parent element
          requiredHeight = parentHeight;
        }

        if (lastRaf) window.cancelAnimationFrame(lastRaf);

        lastRaf = window.requestAnimationFrame(() => {
          lastRaf = null;
          // Apply calculated width and height
          element.css({
            width: `${Math.ceil(requiredWidth)}px`,
            height: `${Math.ceil(requiredHeight)}px`
          });

          window.requestAnimationFrame(() => {
            scope.$broadcast('slide-resized');
          });
        });
      }

      function init () {
        if (ratio.indexOf(':') !== -1) {
          let [width, height] = ratio.split(':');
          if (
            !width || !height ||
            _.isNaN(Number(width)) || _.isNaN(Number(height))
          ) {
            $log.error(`[forceAspectRatio] Invalid value provided: '${ratio}'`);
            return;
          }
          // When a ratio is provided calculate the desired aspect ratio value
          desiredAspectRatio = height / width;
        } else {
          if (_.isNaN(Number(attrs.forceAspectRatio))) {
            $log.error(`[forceAspectRatio] Invalid value provided: '${ratio}'`);
            return;
          }
          // Use the given number as the desired aspect ratio
          desiredAspectRatio = attrs.forceAspectRatio;
        }

        // Size the element when added to the DOM
        resizeElementToBeContained();

        cleanup();
        // Set up a ResizeObserver if available or fall back to `resize` event
        // listener
        if (window.ResizeObserver) {

          resizeObserver = new ResizeObserver((entries) => {
            resizeElementToBeContained();
          });
          resizeObserver.observe(element[0].parentNode);
        } else {
          window.addEventListener('resize', resizeElementToBeContained);
        }
      }

      function cleanup () {
        // Disconnect observer or remove listener when the scope gets destroyed
        if (window.ResizeObserver && resizeObserver !== null) {
          resizeObserver.disconnect();
        } else {
          window.removeEventListener('resize', resizeElementToBeContained);
        }
      }

      scope.$on('$destroy', cleanup);
    }
  };
});