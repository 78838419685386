angular.module('webReceiver', [])
.factory('Fullscreen', function ($log, $rootScope) {
  let isFullscreen = false;

  // Calls the browser supported fullscreen method if theres one.
  const requestFullscreen = (elem) => {
    const fullscreenRequestMethod = isFullscreenable(elem, true);

    if (!fullscreenRequestMethod) {
      $log.debug(`
        [Fullscreen] The Fullscreen API is not supported in this browser
      `);
      return;
    }

    fullscreenRequestMethod();

    isFullscreen = true;
  }

  // Exit from fullscreen mode.
  const exitFullscreen = () => {
    if (!isFullscreen) return;

    const exitFullscreenMethod =
      document.exitFullscreen ||
      document.mozCancelFullScreen ||
      document.webkitExitFullscreen;

      exitFullscreenMethod.call(document);
      isFullscreen = false;
  }

  /**
   * Determine whether the browser supports the Fullscreen API. By default
   * returns a boolean but with an optional second parameter it returns the
   * fullscreen request method bound with the element.
   */
  const isFullscreenable = (elem, returnMethod=false) => {
    const fullscreenRequestMethod =
      elem.requestFullScreen ||
      elem.webkitRequestFullScreen ||
      elem.mozRequestFullScreen;

    return returnMethod ?
      fullscreenRequestMethod.bind(elem) :
      !!fullscreenRequestMethod;
  }

  // Exit fullscreen event handler in case the user exit from fullscreen mode
  // using the `esc` key.
  function exitHandler () {
    // When the user exits fullscreen mode via the `esc` key we need to update
    // our `isFullscreen` variable as it happens out of the scope of angular
    if (
      isFullscreen &&
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen
    ) {
      // Exiting fullscreen mode
      isFullscreen = false;
      $rootScope.$broadcast('Fullscreen:exit-fullscreen');
    }
  }

  // Set up event listeners for close fullscreen events triggered via esc button
  document.addEventListener('fullscreenchange', exitHandler);
  document.addEventListener('webkitfullscreenchange', exitHandler);
  document.addEventListener('mozfullscreenchange', exitHandler);

  return {
    requestFullscreen: requestFullscreen,
    exitFullscreen: exitFullscreen,
    isFullscreenable: isFullscreenable
  }
})
.controller('webReceiverCtrl', function (
  $scope, $http, $q, $rootScope, $timeout, Channels, Fullscreen, HelpText,
  LocaleTree, Modal, Playlists, Session, SortFunctions, Tickers, Toast
) {
  let vm = this;

  // Get reference for element to fullscreen
  let fullscreenElement = document.getElementById('fullscreenElement');

  let slideshowDeferred = $q.defer();

  vm.fullscreenSupported = Fullscreen.isFullscreenable(fullscreenElement);
  vm.isFullscreen = false;

  vm.localTimezone = moment.tz.guess();

  // Initiate preview orientation by checking the current accounts default
  // settings
  vm.previewPortrait =
    Session.current.account.slideCreation.enablePortrait &&
    !Session.current.account.slideCreation.enableLandscape;

  vm.deviceRegistered = false;
  vm.slideshowStarted = false;

  vm.repeaters = [];
  vm.nonRepeaters = [];
  // Device config to send to the server
  vm.deviceConfig = {
    channelOrPlaylist: null,
    locale: 0,
    ticker: null,
    timezone: vm.localTimezone
  };

  let readChannel  = Session.hasPermission('content.channels.read');
  let readPlaylist = Session.hasPermission('content.playlists.read');
  let readTickers  = Session.hasPermission('content.tickers.read');

  vm.channels = readChannel ? Channels.all() : [];
  vm.playlists = readPlaylist ? Playlists.all() : [];
  vm.tickers = readTickers ? Tickers.all() : [];

  /**
   * Selects locale for device config.
   *
   * @returns {Undefined} - Nothing is returned.
   */
  vm.selectLocale = () => {
    new Modal({
      templateUrl: 'utility/modal-templates/locale-manager.jade',
      scopeData: {
        localeSelector: true,
        selected: null,
        tree: LocaleTree.getUnflattenedTree(),
        runFilter: function () {
          this.$parent.filterVal = this.filterVal;
        },
        selectLocale: function (locale) {
          vm.selectedLocale = locale.selected ? locale : null;
          this.selected = vm.selectedLocale;
        },
        toggleAll: function (val) {
          this.$broadcast(`angular-ui-tree:${val ? 'collapse' : 'expand'}-all`);
        },
        initFunction: function () {
          this.$on('locale-tree-linked', () => {
            this.$broadcast(
              'angular-ui-tree:select-with-id',
              vm.deviceConfig.locale
            );
          });
        },
      },
      dismissable: {
        backButton: false,
        backgroundClick: false,
        escape: true,
      },
    })
      .show()
      .then(function (locale) {
        vm.deviceConfig.locale = locale.id;
      })
      .catch((err) => {
        Toast.makeError(err);
      });
  };

  $q.all([
    vm.channels.promise,
    vm.playlists.promise,
    vm.tickers.promise,
    LocaleTree.getLocalisationData()
  ]).then(() => {
    // Initially select All screens locale
    vm.selectedLocale = LocaleTree.getLocaleById(0);

    vm.playlistsAndChannels =
      vm.channels.concat(vm.playlists);
    vm.playlistsAndChannels.sort((a, b) =>
      SortFunctions.stringAsc(a.name, b.name));
    vm.tickers.sort((a, b) =>
      SortFunctions.stringAsc(a.name, b.name));
  });

  /**
   * Resets the device config form.
   *
   * @returns {Undefined} - Nothing is returned.
   */
  vm.resetConfig = () => {
    vm.deviceConfig = {
      channelOrPlaylist: null,
      locale: 0,
      ticker: null,
      timezone: vm.localTimezone
    };
    vm.configForm.$setPristine();
    vm.configForm.$setUntouched();
  };

  let abusePreventionTimer = null;
  // eslint-disable-next-line no-magic-numbers
  const ABUSE_PREVENTION_TIME = 1000 * 60 * 30; // 30 minutes

  /**
   * Sends a request with the device config to the server to start the slideshow
   * preview.
   *
   * @returns {Undefined} - Nothing is returned.
   */
  vm.startSlideshow = () => {
    const [resourceType, resourceId] =
      vm.deviceConfig.channelOrPlaylist.split(':');
    // Set device config's channel or playlist property, reset the other
    vm.deviceConfig[resourceType] = resourceId;
    vm.deviceConfig[_.without(['channel', 'playlist'], resourceType)[0]] = null;

    $http.post('/api/virtual-devices/web-preview', vm.deviceConfig)
      .then(() => {
        vm.deviceRegistered = true;
        vm.setDisabledReasonMessage();
        // Stop slideshow after the maximum allowed time
        abusePreventionTimer = $timeout(()=> {
          abusePreventionTimer = null;
          vm.stopSlideshow();
        }, ABUSE_PREVENTION_TIME);
      })
      .catch((e) => Toast.makeError(e));
  };

  /**
   * Stops the slideshow and resets variables.
   *
   * @returns {Undefined} - Nothing is returned.
   */
  vm.stopSlideshow = () => {
    // Cancel abuse prevention timer
    if (abusePreventionTimer) $timeout.cancel(abusePreventionTimer);
    // Reset flags and empty arrays when slideshow stopped
    vm.deviceRegistered = false;
    vm.slideshowStarted = false;
    vm.repeaters = [];
    vm.nonRepeaters = [];
    slideshowDeferred = $q.defer();
    vm.setDisabledReasonMessage();
  };

  vm.setDisabledReasonMessage = () => {
    vm.disabledReason = '';
    if (vm.deviceRegistered) {
      vm.disabledReason = HelpText.get('forms.disabledReasons.alreadyRunning');
    } else if (vm.configForm.$invalid) {
      vm.disabledReason = HelpText.get(
        'forms.disabledReasons.webReceiverRequiredChannelOrPlaylist'
      );
    }
  };

  $scope.$watch('vm.configForm.$invalid', ()=> vm.setDisabledReasonMessage());

  /**
   * Calls the fullscreen request method for the element to fullscreen.
   *
   * @returns {Undefined} - Nothing is returned.
   */
  vm.requestFullscreen = () => {
    Fullscreen.requestFullscreen(fullscreenElement);
    vm.isFullscreen = true;
  };

  /**
   * Calls the close fullscreen method on the document.
   *
   * @returns {Undefined} - Nothing is returned.
   */
  vm.exitFullscreen = () => {
    Fullscreen.exitFullscreen();
    vm.isFullscreen = false;
  };

  let deregisterExitFullscreenListener =
    $rootScope.$on('Fullscreen:exit-fullscreen', () => {
      vm.isFullscreen = false;
    });

  /**
   * This function receives messages from the slideshow with the slides it
   * received at the beginning of the slideshow and a partial object with info
   * about the next slide that will show.
   * Populates carousels and notifies them which slide to highlight.
   *
   * @param {Event} event - Event object.
   * @returns {Undefined} - Nothing is returned.
   */
  function slideshowEventsHandler (event) {
    // Do we trust the sender of this message?
    if (event.origin !== window.location.origin) return;

    switch (event.data.type) {
      case 'slide-changed':
        // Select the next slide in the carousel
        slideshowDeferred.promise.then(()=> {
          vm.nextSlide = {
            id: event.data.nextId,
            repeat: event.data.repeat || 0
          };
        });
        return; // `nextId` object is updated, return

      case 'slide-payload-received':
        vm.receivedSlides = event.data.slides;

        vm.repeaters = [];
        vm.nonRepeaters = [];

        _.each(vm.receivedSlides, (s) => {
          // Set thumbnail image urls for the carousel to display
          s.imageUrl = `url(/slides/${s._id}/thumbnail?v=${s.__v})`;
          // Set a flag for slides with no duration, currently only used to display the
          // messages `Duration of the video`/`Dynamic duration` instead of the slide
          // duration as its always a hard coded 20 seconds.
          if (s.template.behaviour && s.template.behaviour.noDuration) {
            if (s.template.behaviour.hasVideoFile) s.videoDuration = true;
            else s.dynamicDuration = true;
          }
          // Sort slides for carousels
          s.repeat ? vm.repeaters.push(s) : vm.nonRepeaters.push(s);
        });

        // Make array unique by the repeater's ids and their repeat frequency
        vm.repeaters = _.unique(vm.repeaters, (r) => r._id + r.repeat);

        vm.slideshowStarted = true;
        slideshowDeferred.resolve();
        return;
      case 'stop-web-receiver':
        $scope.$apply(() => {
          vm.stopSlideshow();
          vm.resetConfig();
        });
        return;

      default:
        return;
    }
  }

  // Add listener for receiving messages from the slideshow
  window.addEventListener('message', slideshowEventsHandler, false);

  $scope.$on('$destroy', () => {
    window.removeEventListener('message', slideshowEventsHandler);
    deregisterExitFullscreenListener();
    if (abusePreventionTimer) $timeout.cancel(abusePreventionTimer);
  });
})

.directive('slideHoverOverlay', function ($timeout) {
  return {
    transclude: true,
    template: `<div class="slide-hover-overlay--buttons" ng-transclude></div>`,
    link: function (scope, element, attributes) {
      let timer = null;

      $buttonContainer =
        element[0].querySelector('.slide-hover-overlay--buttons');

      Array.from($buttonContainer.children).forEach(button => {
        button.classList.add('slide-hover-overlay--buttons__button');
      });

      /**
      * Mouse move handler to toggle the `show` class for the slideshow overlay.
      *
      * @returns {Undefined} - Nothing is returned.
      */
      function onMousemove () {
        // Make the overlay visisble
        element.addClass('show');
        // Cancel previous timer
        if (timer) $timeout.cancel(timer);
        // Hide overlay after timeout
        timer = $timeout(() => {
          element.removeClass('show');
        }, 1000);
      }

      element.on('mousemove', onMousemove);
      scope.$on('$destroy', ()=> $timeout.cancel(timer));
    }
  };
});