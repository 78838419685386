angular.module('flare.takeovers.list', [])

.controller('TakeoverListController', function (
  $scope, Toast, Modal, TakeoverTemplates, Takeovers, $q, Devices, Templates,
  $timeout, Slides, Searches, $state, Session, $rootScope, Onboarding,
  $stateParams, SaveCheck
) {
  const vm = this;

  $scope.$on('$stateChangeStart', (event, toState, toParams) => {
    if ($stateParams.tbSearch) toParams.tbSearch = $stateParams.tbSearch;
  });

  $scope.$on('$viewContentLoaded', function () {
    if ($state.params.dynamicTemplateId) vm.createEditTakeoverTemplate();
  });

  vm.toggleEditLayout = (state) => {
    $rootScope.$emit('pxn-DND:edit-preview-layout', state);
  };

  vm.editLayout = (val) => {
    vm.previewData.slide._editLayout = val;
    vm.toggleEditLayout(vm.previewData.slide._editLayout);
  };

  vm.revertLayoutChanges = () => {
    let modalOpts = {
      templateUrl: 'utility/modal-templates/modal-areyousure.jade',
      scopeData: {
        action: 'revert layout changes on',
        itemType: 'slide'
      },
      dismissable: { backButton: false, escape: true, backgroundClick: false }
    };

    new Modal(modalOpts)
      .show()
      .then(() => {
        vm.previewData.slide._editLayout = false;
        vm.toggleEditLayout(vm.previewData.slide._editLayout);
        let revertObj = {
          layout: vm.previewData.slide.layoutBeforeEdit,
          animate: true,
        };
        $rootScope.$emit('pxn-DND:revert-layout-changes', revertObj);
        vm.previewData.takeoverTemplate.setPreviewModified(false);
      })
      .catch(function (err) {
        Toast.makeError(err);
      });
  };

  vm.rotateLayout = ()=> {
    $rootScope.$emit('pxn-DND:rotate-preview-layout');
  };

  // Request all the resources we will need to use throughout the page &
  // populate start up data.
  vm.resources = {
    templates: Templates.all(),
  };
  if (Session.hasPermission('takeovers.read')) {
    vm.resources.takeoverTemplates = TakeoverTemplates.all();
    vm.resources.takeovers = Takeovers.all();
  } else {
    vm.resources.takeoverTemplates = [];
    vm.resources.takeoverTemplates.promise = $q.resolvedPromise();
    vm.resources.takeovers = [];
    vm.resources.takeovers.promise = $q.resolvedPromise();
  }
  if (Session.hasPermission('devices.read')) {
    vm.resources.devices = Devices.all();
  } else {
    vm.resources.devices = [];
    vm.resources.devices.promise = $q.resolvedPromise();
  }
  if (Session.hasPermission('searches.read')) {
    vm.resources.searches = Searches.all();
  } else {
    vm.resources.searches = [];
    vm.resources.searches.promise = $q.resolvedPromise();
  }
  if (Session.hasPermission('content.slides.read')) {
    vm.resources.slides = Slides.all();
  } else {
    vm.resources.slides = [];
    vm.resources.slides.promise = $q.resolvedPromise();
  }

  vm.resources.ready = $q.all([
    vm.resources.takeoverTemplates.promise,
    vm.resources.takeovers.promise,
    vm.resources.devices.promise,
    vm.resources.templates.promise,
    vm.resources.searches.promise,
    vm.resources.slides.promise
  ]);

  // Init variables to populate when data is available
  this.potentialSlides = [];
  this.options = {
    resourceTypes: [
      { value: 'static', label: 'Existing slide' },
      { value: 'dynamic', label: 'Dynamic slide' }
    ],
    searches: vm.resources.searches,
    devices: vm.resources.devices,
  };

  /**
   * Sets a timeout on the takeover to resolve when the takeover expires and
   * updates the takeover list upon completion.
   *
   * @param {Object} takeover A takeover object.
   * @returns {Undefined}
   */
  let updateListOnTakeoverExpiry = (takeover) => {
    takeover
      .setRemovalTimeout()
      .then(() => {
        Takeovers.clearCache();
        vm.resources.takeovers = Takeovers.all();
        $rootScope.$emit("takeover-expired");
      })
      .catch((err) => {
        Toast.makeError(err);
      });
  };

  /**
   * Loops over all takeovers and cancels existing timeouts.
   * NOTE: It's not possible to have a takeover without a removalTimeout.
   *
   * @returns {Undefined}
   */
  let cancelPendingTakeoverTimeouts = () => {
    vm.resources.ready.then(() => {
      vm.resources.takeovers.forEach((takeover) => {
        $timeout.cancel(takeover._removalTimeout);
      });
    });
  };

  // Wait till we have all the required data and populate more useful things.
  vm.resources.ready.then(() => {
    vm.resourcesReady = true;
    vm.resources.templates = Templates.getProvisionedTemplatesForAccount(
      vm.resources.templates, Session.current.account
    );
    // Update our takeover list when any existing takeovers expire.
    vm.resources.takeovers.forEach((takeover) => {
      // Existing takeover, stop button doesn't need to be disabled.
      takeover._disableStopButton = false;
      updateListOnTakeoverExpiry(takeover);
    });

    vm.options = Object.assign(vm.options, {
      staticResources: vm.resources.slides.filter((ps) => {
        // NOTE: In production a slide will ALWAYS have a template.
        if (ps.template && ps.template.takeover) {
          return ps.template.takeover.static;
        } else if (ps.template && typeof ps.template === 'string') {
          let tmp = Templates.byId(ps.template, true);
          return tmp.takeover.static;
        } else {
          return false;
        }
      }),
      templates: vm.resources.templates.filter((t) => {
        // NOTE: Templates without a definition are sent false by the server.
        return t.takeover.dynamic;
      })
    });

    vm.suggestedTags = TakeoverTemplates.getTags();

    SaveCheck.register({
      hasChanges: function () {
        return _.any(vm.resources.takeoverTemplates, function (value, index) {
          if (value.isLocal()) {
            return true;
          }
          return value.isModified();
        });
      },
      discardChanges: function () {
        _.each(vm.resources.takeoverTemplates, function (feed) {
          if (feed.isModified()) {
            feed.reset();
          }
        });
      },
    });
  });


  /**
   * Returns the number of props stored on the `previewData` obj, this is used
   * to decide if the preview should be visible as this object is kept empty
   * when we are not showing a preview.
   *
   * @returns {Number} The number of keys stored on `previewData`
   */
  vm.previewData = {};
  this.previewVisible = () => {
    return !!Object.keys(vm.previewData).length;
  };

  this.savePreviewData = (tt) => {
    // Copy non preview related data.
    let contentModified = tt.isModified();
    let contentCopy;
    if (contentModified) {
      contentCopy = angular.copy(tt.slide.content);
    }

    // Save the TT with only the preview data.
    tt.savePreviewDataAsDefault(tt.slide.content).then((updatedTt) => {
      // Close the preview.
      this.hidePreview();
      // We no longer have unsaved preview modifications.
      updatedTt.setPreviewModified(false);

      // Add back any non preview data we stored.
      if (contentModified) {
        updatedTt.slide.content = contentCopy;
        // Our TT will be in the modified state still.
      } else {
        // We have no content so cast from our defaults.
        updatedTt.resetContentToDefault();
        // We have no modified content.
        updatedTt.setModified(false);
      }
      // Tell the user we won.
      Toast.makeSuccess('Takeover layout change(s) saved');
    }).catch((e) => {
      Toast.makeError(e);
    });
  };

  /**
   * Updates content values from the slide (e.g. image position) and assigns to
   * the TTs slideContent. Emptys the previewData object, hiding the preview &
   * sets the TTs modified value tobe truthy.
   *
   * @returns {Undefined} No return value
   */
  this.hidePreview = () => {
    // Delete the slide, we don't want it popping up around the interface.
    if (
      vm.previewData.takeoverTemplate &&
      vm.previewData.takeoverTemplate.resourceType === 'dynamic'
    ) {
      vm.previewData.slide.delete();
    }
    // Empty the preview data object (which hides the preview).
    vm.previewData = {};
  };

  /**
   * Takes the device and search ids, evaluates and generates a "friendly"
   * string to represent the number of devices selected.
   *
   * NOTE: Values coming form the server will be IDs, values coming from the
   * interface will be resourceItems (except "janky ALL").
   * @returns {Promise} resolves with the "friendly" string.
   */
  this.getTargetText = (value) => {
    // value will be an object with `devices` and `searches` props as arrays
    if (!value.devices.length && !value.searches.length) {
      // Nothing selected
      return $q.resolvedPromise('-- Select --');
    }

    if (
      (
        typeof value.searches[0] === 'string' &&
        value.searches.includes('ALL')
      ) ||
      (typeof value.searches[0] === 'object' && value.searches[0]._id === 'ALL')
    ) {
      return $q.resolvedPromise('All screens via saved search');
    }

    // Beyond here we will need some resources in order to calculate values.
    return vm.resources.ready.then(() => {
      // // Not every user has access to ALL devices, create a lost of IDs the
      // user knows about to filter against.
      let devicesUserKnowsAbout = [];
      _.each(vm.resources.devices, (device, index) => {
        devicesUserKnowsAbout.push(device._id);
      });

      // Targets could contain devices the user isn't able to see so filter
      // them from the count.
      let devicesToTriggerOn = [];
      _.each(value.devices, (deviceId) => {
        if (devicesUserKnowsAbout.includes(
          typeof deviceId === 'string' ? deviceId : deviceId._id
        )) {
          devicesToTriggerOn.push(
            typeof deviceId === 'string' ? deviceId : deviceId._id
          );
        }
      });

      if (value.devices.length && value.searches.length) {
        // Combination selected
        let uniqueDevices = [];
        _.each(value.searches, (search, index) => {
          if (typeof search === 'object') {
            uniqueDevices =
              uniqueDevices.concat(
                _.map(
                  Searches.runFilters(
                    Searches.byId(search._id, true), vm.resources.devices
                  ),
                  (d) => { return d._id; }
                )
              );
          } else {
            uniqueDevices =
              uniqueDevices.concat(
                _.map(
                  Searches.runFilters(
                    Searches.byId(search, true), vm.resources.devices
                  ),
                  (d) => { return d._id; }
                )
              );
          }
        });
        // Search devices sorted now lets add our filtered device list.
        uniqueDevices = uniqueDevices.concat(
          devicesToTriggerOn
        );
        uniqueDevices = _.uniq(uniqueDevices);
        return devicesToTriggerOn.length +
          ' ' +
          (devicesToTriggerOn.length === 1 ? 'screen' : 'screens') +
          ' & ' +
          value.searches.length +
          ' ' +
          (value.searches.length === 1 ? 'search' : 'searches') +
          ' (' +
          uniqueDevices.length +
          ' ' +
          (uniqueDevices.length === 1 ? 'screen' : 'screens') +
          ' total)';
      } else if (value.devices.length && !value.searches.length) {
        // Devices only
        // Check that after filtering we still have devices to show the user.
        if (!devicesToTriggerOn.length) {
          // We filtered out all the devices and have nothing to show.
          return '-- Select --';
        } else {
          return devicesToTriggerOn.length +
            ' ' +
            (devicesToTriggerOn.length === 1 ? 'screen' : 'screens');
        }
      } else if (!value.devices.length && value.searches.length) {
        // Searches only
        let searchDevices = [];
        _.each(value.searches, (searchId, index) => {
          if (typeof searchId === 'object') {
            searchDevices =
              _.uniq(searchDevices.concat(
                Searches.runFilters(searchId, vm.resources.devices)
              ));
          } else {
            let search = Searches.byId(searchId, true);
            if (search !== -1) {
              searchDevices =
                _.uniq(searchDevices.concat(
                  Searches.runFilters(search, vm.resources.devices)
                ));
            }
          }
        });
        return searchDevices.length +
          ' ' +
          (searchDevices.length === 1 ? 'screen' : 'screens') +
          ' via searches';
      }
    });
  };

  /**
   * Creates or edits and existing takeover template based on the existence of
   * the provided ID; if no ID is provided OR the ID did not match an existing
   * takeover template we create a new takeover template OTHERWISE we modify the
   * existing takeover template.
   *
   * @param {String} id - A takeover template ID or omitted.
   * @returns {Promise} - Resolves with the TT.
   */
  this.createEditTakeoverTemplate = async (id, resetSplitInputs) => {
    let tt, ttId, action, deregisterTemplateWatchFn;
    if (id) {
      // If we have been provided an ID we are editing & will look up the
      // existing TT, wait for the resources to have loaded, we already
      // requested these when the page loaded.
      await vm.resources.ready;
      action = 'Edit';
      tt = _.find(vm.resources.takeoverTemplates, '_id', id);
      // We will create a new TT to use in the modal (so nothing is changing in
      // the BG), if we provide a partial with an `_id` to the create Fn it will
      // find & use that TT, so strip it out but keep a reference.
      ttId = tt._id;
      delete tt._id;
      // Check for deprecated templates & mark.
      if (tt.resourceType === 'dynamic' && this.slide) {
        let resource = Templates.byId(tt.slide.template, true);
        if (resource && resource.deprecated) {
          tt._deprecatedId = tt.slide.template;
        }
      }
      // Clone the TT for use in the modal (on scope so we can $watch later).
      this.clonedTT = TakeoverTemplates.create(tt, false);
      // Now we've cloned add our removed ID back to our TT.
      tt._id = ttId;
      // Watch for template changes so we can update supported orientations.
      deregisterTemplateWatchFn =
        $scope.$watch('vm.clonedTT.slide.template', (nVal, oVal) => {
          if (!nVal || nVal === oVal) {
            return;
          }
          let template = Templates.byId(nVal, true);
          this.clonedTT.slide.orientations = angular.copy(template.orientations);
        });
      // If the user has made changes show a confirmation modal.
      if (tt.isModified()) {
        let conModal = new Modal({
          templateUrl: 'utility/modal-templates/confirmation.jade',
          scopeData: {
            message: `
              This takeover has been modified! Discard these
              changes and continue to edit the defaults?
            `,
            negativeButton: 'Cancel and return',
            positiveButton: 'Discard and continue'
          },
          dismissable: { backButton:false, escape:false, backgroundClick:false }
        });

        let conModalCancel = false;
        await conModal.show().then(
          () => tt.reset()
        ).then(()=> {
          Object.assign(this.clonedTT, tt);
          // Force split inputs to change state.
          if (resetSplitInputs) resetSplitInputs();
        }).catch((reason) => {
          switch (reason) {
            case 'cancel':
              conModalCancel = true;
              return;
            default:
              conModalCancel = true;
              tt.reset();
              return false;
          }
        })
        if (conModalCancel) return;
      }
    } else {
      // We are creating a new TT
      action = 'Create';
      this.clonedTT = TakeoverTemplates.create({}, false);
      // Update template if coming from template browser.
      if ($state.params.dynamicTemplateId) {
        this.clonedTT.resource =
          Templates.byId($state.params.dynamicTemplateId, true);
        // Reset param so template will not be populated on create new takeover
        // just for the first time.
        $state.params.dynamicTemplateId = null;
      }
    }
    // Now we get to show the create/edit modal
    var ceModal = new Modal({
      templateUrl:
        'utility/modal-templates/create-edit-takeover-template.jade',
      scopeData: {
        removeWebhook: (e) => {
          this.clonedTT.webhook.enabled = false;
          this.clonedTT.setModified();
        },
        action: action,
        takeoverTemplate: this.clonedTT,
        options: this.options,
        textFunction: this.getTargetText,
        locale: 0,
        suggestedTags: this.suggestedTags,
        onboardingQuickContent: function () {
          $timeout(function () {
            Onboarding.triggerOverviewResourceAvailable();
            // 800 ms delay if scrollbar is added when creating a new takeover
            // eslint-disable-next-line no-magic-numbers
          }, !tt ? 800 : 0);
          return true;
        }
      },
      dismissable: { backButton: false, escape: false, backgroundClick: false }
    });
    await ceModal
      .show()
      .then((returnedTT) => {
        if (deregisterTemplateWatchFn) deregisterTemplateWatchFn();
        // There are two routes here, one to create, one to edit
        switch (action) {
          case 'Edit':
            // Strip the new returnedTT's ID and assign edits to our TT.
            delete returnedTT._id;
            Object.assign(tt, returnedTT);
            // Save the updated TT
            return Toast.saveAndNotify(tt).then(() => {
              tt.resetContentToDefault();
              // Force split inputs to change state.
              if (resetSplitInputs) resetSplitInputs();
            }).catch((reason) => {
              if (reason === 'cancel') return;
              tt.reset();
            });
          case 'Create':
            return Toast.saveAndNotify(returnedTT).catch((reason) => {
              Toast.makeError(reason);
            });
          default:
            return;
        }
      })
      .catch((err) => {
        Toast.makeError(err);
      });
  };

  /**
   * This Fn is passed as the CB when takeovers are triggered, perform any post
   * creation modifications here.
   *
   * @param {Object} takeover A takeover object.
   */
  this.triggerTakeoverCb = (takeover) => {
    // We need to update the takeover list when this takeover expires.
    updateListOnTakeoverExpiry(takeover);
    $rootScope.$emit('takeover-triggered');
  };

  vm.setPreviewOrientation = () => {
    vm.portraitPreview =
      typeof vm.previewData.takeoverTemplate.slide === 'string' ?
        !vm.previewData.takeoverTemplate._resource.orientations.landscape :
        !vm.previewData.takeoverTemplate.slide.orientations.landscape;
  };

  // Make sure we tidy up when leaving the page.
  $scope.$on('$destroy', () => {
    cancelPendingTakeoverTimeouts();
    if (vm.previewData.slide && vm.previewData.slide._id[0] === '$') {
      vm.previewData.slide.delete();
    }
  });
});
